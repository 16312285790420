import { FaqTooltipComponent } from './components/faq-tooltip/faq-tooltip.component';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { LeftMenuComponent } from './components/left-menu/left-menu.component';
import { TreeComponent } from './components/tree/tree.component';
import { SortPipe } from './pipes/sort.pipe';
import { SearchLocationComponent } from './components/search-location/search-location.component';
import { ClockWidgetComponent } from './components/clock-widget/clock-widget.component';
import { CalendarWidgetComponent } from './components/calendar-widget/calendar-widget.component';
import { StatusBarComponent } from './components/status-bar/status-bar.component';
import { ModalWindowComponent } from './components/modal-window/modal-window.component';
import { OrderModule } from 'ngx-order-pipe';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { AutosizeDirective } from './directives/autosize.directive';
import { HelpModalComponent } from './components/help-modal/help-modal.component';
import { SearchTextComponent } from './components/search-text/search-text.component';
import { RangeNumberFilterComponent } from './components/range-number-filter/range-number-filter.component';
import { DoubleDatepickerFilterComponent } from './components/double-datepicker-filter/double-datepicker-filter.component';
import { DatePickerModule } from './components/ng2-datepicker/ng2-datepicker.module';
import { MultiselectFilterComponent } from './components/multiselect-filter/multiselect-filter.component';
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { DatepickerFilterComponent } from './components/datepicker-filter/datepicker-filter.component';
import { TextTransferPipe } from './pipes/text-transfer.pipe';
import { BrToSpacePipe } from './pipes/br-to-space.pipe';
import { ClickElsewhereDirective } from './directives/click-elsewhere.directive';
import { OnlyIntegerDirective } from './directives/only-integer.directive';
import { MinValueDirective } from './directives/min-value.directive';
import { MaxValueDirective } from './directives/max-value.directive';
import { TradeCurrencyPipe } from './pipes/trade-currency.pipe';
import { MinDateValueDirective } from './directives/min-date-value.directive';
import { MaxDateValueDirective } from './directives/max-date-value.directive';
import { TextSeparatorPipe } from './pipes/text-separator.pipe';
import { CriteriaNumberDirective } from './directives/criteria-number.directive';
import { NgxMultiLineEllipsisDirective } from './directives/ngx-multi-line-ellipsis.directive';
import { CompanyNamePipe } from './pipes/company-name.pipe';
import { RatingValuePipe } from './pipes/rating-value.pipe';
import { FaqFilesFieldModule } from '../+faq/shared/components/faq-files-field/faq-files-field.module';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { CompetenciesFilterComponent } from './components/competencies-filter/competencies-filter.component';
import { DateTimeFormatPipe } from './pipes/date-time-format.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TitleFromPipe } from '@app/shared/pipes/title-from.pipe';
import { LinkifyPipe } from '@app/shared/pipes/linkify.pipe';
import { FilterPipe } from '@app/shared/pipes/filter.pipe';
import { FilterSimplePipe } from '@app/shared/pipes/filter-simple.pipe';
import { CompetenciesPipe } from './pipes/competencies.pipe';
import { TitleRolePipe } from './pipes/title-role.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { IsNegativePipe } from './pipes/is-negative.pipe';
import { StatusCircleComponent } from './components/status-circle/status-circle.component';
import { BooleanFilterComponent } from './components/boolean-filter/boolean-filter.component';
import { CompanyTooltipComponent } from './components/company-tooltip/company-tooltip.component';
import { CompanyTooltipDirective } from './components/company-tooltip/company-tooltip.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { RoleNamePipe } from './pipes/role-name.pipe';
import { MonthPipe } from './pipes/month.pipe';
import * as moment from 'moment';
import { AmountPipe } from './pipes/amount.pipe';
import localeRu from '@angular/common/locales/ru';
import { PopoverService } from '@app/shared/services/popover.service';
import { PopoverComponent } from '@app/shared/components/popover/popover.component';
import { UserItemComponent } from '@app/shared/components/user-item/user-item.component';
import { TableService } from '@app/shared/components/table/services/table.service';
import { FlagFilterComponent } from '@app/shared/components/flag-filter/flag-filter.component';
import { FilesService } from '@app/shared/services/files.service';
import { UsersInitialsFilterPipe } from '@app/shared/pipes/users/users-initials-filter.pipe';
import { InitialsPipe } from '@app/shared/pipes/users/initials.pipe';
import { UsersFieldPipe } from './pipes/users/users-field.pipe';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DateTimeShortFormatPipe } from '@app/shared/pipes/date-time-short-format.pipe';
import { RichModalComponent } from '@app/shared/components/rich-modal/rich-modal.component';
import { RadioFilterComponent } from '@app/shared/components/radio-filter/radio-filter.component';
import { MouseoverTimeDirective } from '@app/shared/directives/mouseover-time.directive';
import { DateTimeShortFormatLineBreakPipe } from '@app/shared/pipes/date-time-short-format-line-break.pipe';
import { SvgModalCloseComponent } from './components/svg-modal-close/svg-modal-close.component';
import { MovePinComponent } from '@app/shared/components/move-pin/move-pin.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { SVGSpriteModule } from '@app/svg-sprite/svg-sprite.module';
import { AddEventComponent } from '@app/shared/components/calendar-widget/add-event/add-event.component';
import { InfoForDayComponent } from '@app/shared/components/calendar-widget/info-for-day/info-for-day.component';
import { DatepickerComponent } from '@app/shared/components/calendar-widget/datepicker/datepicker.component';
import { InfoEventComponent } from '@app/shared/components/calendar-widget/info-event/info-event.component';
import { SelectDateComponent } from '@app/shared/components/calendar-widget/select-date/select-date.component';
import { DeleteEventComponent } from '@app/shared/components/calendar-widget/delete-event/delete-event.component';
import { FilesTooltipComponent } from './components/files-tooltip/files-tooltip.component';

import { FormLabelComponent } from '@app/shared/components/form/form-label/form-label.component';
import { FormFieldWrapperComponent } from '@app/shared/components/form/form-field-wrapper/form-field-wrapper.component';
import { FormInputComponent } from '@app/shared/components/form/form-input/form-input.component';
import { TimepickerComponent } from '@app/shared/components/calendar-widget/timepicker/timepicker.component';
import { SafePipe } from '@app/shared/pipes/safe.pipe';
import { FormFieldDirective } from '@app/shared/directives/form-field.directive';
import { NgxMaskModule } from 'ngx-mask';
import { LivesearchSelectComponent } from '@app/shared/components/livesearch-select/livesearch-select.component';
import { DoubleClickDirective } from '@app/shared/directives/double-click.directive';
import { StartPageDialogComponent } from '@app/shared/components/start-page-dialog/start-page-dialog.component';
import { ChangeStatusComponent } from '@app/shared/components/change-status/change-status.component';
import { StatusViewerComponent } from './components/status-viewer/status-viewer.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DateTableFormatPipe } from '@app/shared/pipes/date-table-format.pipe';
import { UserCardComponent } from './components/user-card/user-card.component';
import { ScrollerDirective } from '@app/shared/directives/scrolled-div.directiove';
import { OnlyLetterDirective } from '@app/shared/directives/only-letter.directive';
import { ChatLinkDirective } from '@app/shared/directives/chat-link.directive';
import { TextUpperCasePipe } from './pipes/text-upper-case.pipe';
import { CompanyAuthorizedPersonPipe } from '@app/shared/pipes/company-authorized-person.pipe';
import { FormRangeSliderComponent } from './components/form/form-range-slider/form-range-slider.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { FormCheckboxSwitchComponent } from './components/form/form-checkbox-switch/form-checkbox-switch.component';
import { TableHeaderCellComponent } from '@app/shared/components/table-header-cell/table-header-cell.component';
import { TreeModule } from '@circlon/angular-tree-component';
import { DigitOnlyDirective } from '@app/shared/directives/digit-only.directive';
import { NotFoundComponent } from '@app/shared/components/not-found/not-found.comnponent';
import { TestLocatorModule } from './modules/test-locator/test-locator.module';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { FullTitleRolePipe } from '@app/shared/pipes/full-title-role.pipe';
import { ShortNamePipe } from '@app/shared/pipes/users/short-name.pipe';
import { FormRangeInputComponent } from './components/form/form-range-input/form-range-input.component';
import { BlockCopyPasteDirective } from '@app/shared/directives/block-copy-paste.directive';
import { DragDropDirective } from '@app/shared/directives/drag-drop.directive';
import { DateShortFormatPipe } from './pipes/date-short-format.pipe';
import { FlaskApiMapperPipe } from '@app/shared/pipes/flask-api-mapper.pipe';
import { StopPreventDirective } from '@app/shared/directives/stop-prevent.directive';

moment.locale('ru');
registerLocaleData(localeRu, 'ru-RU');

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TreeModule,
    OrderModule,
    DatePickerModule,
    OverlayModule,
    DatePickerModule,
    VirtualScrollerModule,
    DatePickerModule,
    FaqFilesFieldModule,
    NgbModule,
    ClickOutsideModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    SVGSpriteModule,
    NgxMaskModule,
    NgSelectModule,
    NgxSliderModule,
    TestLocatorModule,
    StopPreventDirective,
  ],
  exports: [
    LeftMenuComponent,
    StatusBarComponent,
    SearchLocationComponent,
    TreeComponent,
    FaqTooltipComponent,
    AddEventComponent,
    SortPipe,
    SafePipe,
    ModalWindowComponent,
    RichModalComponent,
    OrderModule,
    OnlyNumberDirective,
    AutosizeDirective,
    HelpModalComponent,
    SearchTextComponent,
    RangeNumberFilterComponent,
    DoubleDatepickerFilterComponent,
    DatePickerModule,
    MultiselectFilterComponent,
    FlagFilterComponent,
    DatepickerFilterComponent,
    TimeFormatPipe,
    TextTransferPipe,
    BrToSpacePipe,
    ClickElsewhereDirective,
    DoubleClickDirective,
    OnlyIntegerDirective,
    ScrollerDirective,
    MinValueDirective,
    MaxValueDirective,
    TradeCurrencyPipe,
    MinDateValueDirective,
    MaxDateValueDirective,
    MouseoverTimeDirective,
    TextSeparatorPipe,
    CriteriaNumberDirective,
    NgxMultiLineEllipsisDirective,
    CompanyNamePipe,
    LivesearchSelectComponent,
    RatingValuePipe,
    FaqFilesFieldModule,
    RatingValuePipe,
    VirtualScrollerModule,
    CompetenciesFilterComponent,
    DateTimeFormatPipe,
    DateTimeShortFormatPipe,
    DateTimeShortFormatLineBreakPipe,
    TitleFromPipe,
    LinkifyPipe,
    FilterPipe,
    FilterSimplePipe,
    CompetenciesPipe,
    TitleRolePipe,
    RatingValuePipe,
    IsNegativePipe,
    StatusCircleComponent,
    BooleanFilterComponent,
    CompanyTooltipDirective,
    RoleNamePipe,
    MonthPipe,
    AmountPipe,
    UserItemComponent,
    UsersInitialsFilterPipe,
    InitialsPipe,
    UsersFieldPipe,
    CalendarModule,
    RadioFilterComponent,
    SvgModalCloseComponent,
    CalendarWidgetComponent,
    DeleteEventComponent,
    SelectDateComponent,
    InfoForDayComponent,
    DatepickerComponent,
    TimepickerComponent,
    InfoEventComponent,
    MovePinComponent,
    FormLabelComponent,
    FormFieldWrapperComponent,
    FormInputComponent,
    FormFieldDirective,
    ChangeStatusComponent,
    StatusViewerComponent,
    DateTableFormatPipe,
    UserCardComponent,
    OnlyLetterDirective,
    TextUpperCasePipe,
    CompanyAuthorizedPersonPipe,
    FormRangeSliderComponent,
    FormCheckboxSwitchComponent,
    TableHeaderCellComponent,
    ChatLinkDirective,
    DigitOnlyDirective,
    NotFoundComponent,
    TestLocatorModule,
    ConfirmationModalComponent,
    FullTitleRolePipe,
    ShortNamePipe,
    FormRangeInputComponent,
    BlockCopyPasteDirective,
    DateShortFormatPipe,
    FlaskApiMapperPipe,
  ],
  declarations: [
    LeftMenuComponent,
    StatusBarComponent,
    SearchLocationComponent,
    TreeComponent,
    ClockWidgetComponent,
    CalendarWidgetComponent,
    DeleteEventComponent,
    SelectDateComponent,
    InfoForDayComponent,
    DatepickerComponent,
    TimepickerComponent,
    InfoEventComponent,
    FaqTooltipComponent,
    AddEventComponent,
    SortPipe,
    SafePipe,
    ModalWindowComponent,
    RichModalComponent,
    OnlyNumberDirective,
    AutosizeDirective,
    HelpModalComponent,
    SearchTextComponent,
    RangeNumberFilterComponent,
    DoubleDatepickerFilterComponent,
    TimeFormatPipe,
    MultiselectFilterComponent,
    FlagFilterComponent,
    DatepickerFilterComponent,
    TextTransferPipe,
    BrToSpacePipe,
    ClickElsewhereDirective,
    DoubleClickDirective,
    OnlyIntegerDirective,
    ScrollerDirective,
    MinValueDirective,
    MaxValueDirective,
    TradeCurrencyPipe,
    MinDateValueDirective,
    MaxDateValueDirective,
    MouseoverTimeDirective,
    TextSeparatorPipe,
    CriteriaNumberDirective,
    NgxMultiLineEllipsisDirective,
    InitialsPipe,
    CompanyNamePipe,
    LivesearchSelectComponent,
    RatingValuePipe,
    CompetenciesFilterComponent,
    DateTimeFormatPipe,
    DateTimeShortFormatPipe,
    DateTimeShortFormatLineBreakPipe,
    TitleFromPipe,
    LinkifyPipe,
    FilterPipe,
    FilterSimplePipe,
    CompetenciesPipe,
    TitleRolePipe,
    RatingValuePipe,
    IsNegativePipe,
    StatusCircleComponent,
    BooleanFilterComponent,
    CompanyTooltipComponent,
    CompanyTooltipDirective,
    RoleNamePipe,
    MonthPipe,
    AmountPipe,
    PopoverComponent,
    UserItemComponent,
    UsersInitialsFilterPipe,
    UsersFieldPipe,
    RadioFilterComponent,
    SvgModalCloseComponent,
    MovePinComponent,
    FormLabelComponent,
    FormFieldWrapperComponent,
    FormInputComponent,
    FormFieldDirective,
    StartPageDialogComponent,
    ChangeStatusComponent,
    StatusViewerComponent,
    DateTableFormatPipe,
    UserCardComponent,
    OnlyLetterDirective,
    TextUpperCasePipe,
    CompanyAuthorizedPersonPipe,
    FormRangeSliderComponent,
    FormCheckboxSwitchComponent,
    TableHeaderCellComponent,
    ChatLinkDirective,
    DigitOnlyDirective,
    NotFoundComponent,
    FilesTooltipComponent,
    ConfirmationModalComponent,
    FullTitleRolePipe,
    ShortNamePipe,
    FormRangeInputComponent,
    BlockCopyPasteDirective,
    DragDropDirective,
    DateShortFormatPipe,
    FlaskApiMapperPipe,
  ],
  providers: [
    InitialsPipe,
    OrderByPipe,
    CompanyNamePipe,
    PopoverService,
    TableService,
    FilesService,
    UsersInitialsFilterPipe,
  ],
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far);
  }
}
