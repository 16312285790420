import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CalendarMonthViewDay } from 'angular-calendar';
import * as moment from 'moment';
import { CalendarDutyComponent } from '../calendar-duty/calendar-duty.component';
import { Week } from '../../models/calendar-widget-data';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  standalone: true,
  imports: [CalendarDutyComponent],
})
export class ScheduleComponent {
  @Input() viewDate: Date = moment().toDate();
  @Input() selectedMonthViewDay: CalendarMonthViewDay;
  @Input() showMyDuty = false;
  @Input() isAgent = false;

  @Output() onDayClicked = new EventEmitter();
  @Output() onBeforeMonthViewRender = new EventEmitter();

  @ViewChild(CalendarDutyComponent) calendarRef: CalendarDutyComponent;

  dayClicked(day: CalendarMonthViewDay): void {
    this.onDayClicked.emit(day);
  }

  beforeViewRender(data: Week[]): void {
    let result = [];
    if (!!data.length) {
      data.forEach((week) => {
        result = [...result, ...week.days];
      });
    }

    this.onBeforeMonthViewRender.emit({ body: result });
  }

  updateCalendar(): void {
    this.calendarRef.updateCalendar();
  }
}
