<div class="section-header">
  <div>
    <div class="section-header-row" appTestLocator="add-word__status">
      <div class="section-header-label">Статус:</div>
      <div class="section-header-value d-flex align-items-center">
        {{ status }}&nbsp;&nbsp;
        <app-svg-icon name="change" width="16" class="color-brand-light"></app-svg-icon>
      </div>
    </div>
    <div class="section-header-row" appTestLocator="add-word__author">
      <div class="section-header-label">Автор:</div>
      <div class="section-header-value">{{ userAuthor | roleName }}</div>
    </div>
    <div class="section-header-row" appTestLocator="add-word__date">
      <div class="section-header-label">Дата:</div>
      <div class="section-header-value">{{ date | dateFormat }}</div>
    </div>
  </div>
  <div>
    <div class="section-header-text" appTestLocator="add-word__title">Добавить новое слово</div>
  </div>
  <div></div>
</div>
<div class="modal-body" [formGroup]="form">
  <div class="array-sections" formArrayName="wordName">
    <div *ngFor="let name of wordName.controls; let i = index" appTestLocator="add-word__item-{{ i }}">
      <div class="d-flex align-items-center mb-4">
        <dc-form-field-wrapper [style]="'material'" [vertically]="true" [required]="true" class="mr-1">
          <dc-form-input [maxLength]="30" [isShowLength]="true">
            <input
              formInput
              placeholder="Введите новое слово или словосочетание"
              [formControlName]="i"
              appTestLocator="add-word__item-{{ i }}_input"
            />
          </dc-form-input>
        </dc-form-field-wrapper>
        <div
          (click)="remove(i)"
          *ngIf="i > 0"
          class="color-brand-light cursor-pointer"
          appTestLocator="add-word__item-{{ i }}_remove-button"
        >
          <app-svg-icon name="close" width="20" height="20"></app-svg-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="dc-icon-button" (click)="addWord()" appTestLocator="add-word__add-button">
    <app-svg-icon name="plus" width="14"></app-svg-icon>
    <span>Добавить еще слово/словосочетание</span>
  </div>
</div>
<div class="modal-footer">
  <div class="dc-footer-buttons">
    <button
      class="btn btn-dc-outline"
      (click)="cancel()"
      appTestLocator="add-word__cancel-button"
      [disabled]="isButtonsDisabled()"
    >
      Отменить
    </button>
    <button
      class="btn btn-dc-primary"
      (click)="save()"
      type="submit"
      appTestLocator="add-word__save-button"
      [disabled]="isButtonsDisabled()"
    >
      Сохранить
    </button>
  </div>
</div>
