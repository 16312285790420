<div *ngIf="type === criteriaTypesEnum.YES_NO" class="value-boolean" [class.disabled]="readonly">
  <div class="value-boolean-item margin-t-2" [class.active]="isControlFilled() && !control.value">
    <span (click)="setValue(false)">Нет</span>
  </div>
  <div class="value-boolean-item margin-t-2" [class.active]="isControlFilled() && control.value">
    <span (click)="setValue(true)">Да</span>
  </div>
</div>

<input
  *ngIf="type === criteriaTypesEnum.NUMBERS"
  type="text"
  placeholder="0.0000"
  criteriaNumber
  class="kt-input"
  [class.disabled]="readonly"
  [formControl]="control"
  [minValue]="min"
  [maxValue]="max"
  (focus)="oldValue = control.value"
  (change)="change()"
/>
<input
  *ngIf="type === criteriaTypesEnum.DATES"
  type="text"
  placeholder="--.--.----"
  mask="00.00.0000"
  class="kt-input"
  [class.disabled]="readonly"
  [formControl]="control"
  [showMaskTyped]="true"
  [dropSpecialCharacters]="false"
  (focus)="oldValue = control.value"
  (change)="change()"
/>

<ng-container *ngIf="!readonly && withCopy">
  <app-svg-icon
    name="copy"
    height="16"
    class="copy"
    [class.disabled]="isDisableCopy"
    (click)="showCopy($event)"
  ></app-svg-icon>
  <div *ngIf="showCopyMenu" class="copy-menu" appClickElsewhere (appClickElsewhere)="showCopyMenu = null">
    <span (click)="onCopyClick()">Скопировать на нижние строки</span>
  </div>
</ng-container>
