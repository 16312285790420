<div class="profile-form">
  <div class="profile-form-header">
    <h1 class="header-primary">Личные данные</h1>
    <div class="d-flex mod-align-center header-secondary">
      Реквизиты пользователя
      <button type="button" class="icon-button">
        <app-faq-tooltip tooltipName="auth__auth_signin" class="hint-right"></app-faq-tooltip>
      </button>
    </div>
  </div>

  <div class="profile-form-contract profile-form-content">
    <div class="form-body">
      <div class="form-section top-form-section">
        <div class="form-section-header d-flex mod-align-center">
          Резидентство и юридический статус
          <button type="button" class="icon-button">
            <app-faq-tooltip tooltipName="auth__auth_signin" class="hint-right"></app-faq-tooltip>
          </button>
        </div>
        <div class="form-section-content">
          <div class="radio-group required">
            <div class="radio-group-title">Резидентство:</div>
            <ul class="list-unstyled">
              <li class="dc-radio-input" *ngFor="let residence of RESIDENCES; let i = index">
                <input
                  [id]="'residence-' + residence.value"
                  type="radio"
                  name="residence"
                  [value]="residence.value"
                  [attr.disabled]="!residence.roles.includes(user.type) || null"
                  [(ngModel)]="currentResidency"
                  (ngModelChange)="changeResidence()"
                />
                <label class="radio-label" [ngClass]="residence.value" [for]="'residence-' + residence.value">
                  {{ residence.label }}
                </label>
              </li>
            </ul>
          </div>

          <div class="radio-group required">
            <div class="radio-group-title">Юридический статус:</div>
            <ul class="list-unstyled">
              <li class="dc-radio-input" *ngFor="let legal_status of LEGAL_STATUSES; let i = index">
                <input
                  [id]="'legal_status-' + legal_status.value"
                  type="radio"
                  name="legal_status"
                  [value]="legal_status.value"
                  [attr.disabled]="!legal_status.roles.includes(user.type) || null"
                  [(ngModel)]="currentLegalStatus"
                  (ngModelChange)="changeLegalStatus()"
                />
                <label class="radio-label" [ngClass]="legal_status.value" [for]="'legal_status-' + legal_status.value">
                  {{ legal_status.label }}
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <form [formGroup]="form" (ngSubmit)="updateUser()" class="form-body profile-form-content">
      <ng-container [ngSwitch]="userType">
        <!-- region Эксперт-->
        <ng-container *ngSwitchCase="roles.EXPERT">
          <ng-container *ngTemplateOutlet="REGISTRY_SECTION"></ng-container>
          <ng-container
            [ngTemplateOutlet]="ADDRESS_SECTION"
            [ngTemplateOutletContext]="{
              addressTitles: { registration: 'Адрес регистрации', postal: 'Почтовый адрес' }
            }"
          >
          </ng-container>
          <ng-container *ngTemplateOutlet="BANK_CARD_SECTION"></ng-container>
          <ng-container *ngTemplateOutlet="CHECK_RULES_SECTION"></ng-container>
          <ng-container *ngTemplateOutlet="CHECK_CONTRACT_SECTION"></ng-container>
        </ng-container>
        <!-- endregion -->

        <!-- region Агент-->
        <ng-container *ngSwitchCase="roles.PARTNER">
          <ng-container *ngTemplateOutlet="REGISTRY_SECTION"></ng-container>
          <ng-container
            [ngTemplateOutlet]="ADDRESS_SECTION"
            [ngTemplateOutletContext]="{
              addressTitles: { registration: 'Адрес регистрации', postal: 'Почтовый адрес' }
            }"
          >
          </ng-container>
          <ng-container *ngTemplateOutlet="BANK_CARD_SECTION"></ng-container>
          <ng-container *ngTemplateOutlet="CHECK_RULES_SECTION"></ng-container>
          <ng-container *ngTemplateOutlet="CHECK_CONTRACT_SECTION"></ng-container>
        </ng-container>
        <!-- endregion -->

        <!-- region Бухгалтер -->
        <ng-container *ngSwitchCase="roles.ACCOUNTANT">
          <ng-container *ngTemplateOutlet="REGISTRY_SECTION"></ng-container>
          <ng-container
            [ngTemplateOutlet]="ADDRESS_SECTION"
            [ngTemplateOutletContext]="{
              addressTitles: { registration: 'Адрес регистрации', postal: 'Почтовый адрес' }
            }"
          >
          </ng-container>
          <ng-container *ngTemplateOutlet="BANK_CARD_SECTION"></ng-container>
          <ng-container *ngTemplateOutlet="CHECK_RULES_SECTION"></ng-container>
        </ng-container>
        <!-- endregion -->

        <!-- region Админ. тех.поддержки -->
        <ng-container *ngSwitchCase="roles.SUPERUSER">
          <ng-container *ngTemplateOutlet="REGISTRY_SECTION"></ng-container>
          <ng-container
            [ngTemplateOutlet]="ADDRESS_SECTION"
            [ngTemplateOutletContext]="{
              addressTitles: { registration: 'Адрес регистрации', postal: 'Почтовый адрес' }
            }"
          >
          </ng-container>
          <ng-container *ngTemplateOutlet="BANK_CARD_SECTION"></ng-container>
          <ng-container *ngTemplateOutlet="CHECK_RULES_SECTION"></ng-container>
        </ng-container>
        <!-- endregion -->

        <!-- region Админ. пользователя-->
        <ng-container *ngSwitchCase="roles.ADMIN_OF_USER">
          <ng-container [ngSwitch]="currentResidency">
            <ng-container *ngSwitchCase="residencesEnum.RUSSIAN_FEDERATION">
              <ng-container [ngSwitch]="currentLegalStatus">
                <ng-container *ngSwitchCase="legalStatusesEnum.NATURAL_PERSON">
                  <ng-container *ngTemplateOutlet="CHECK_RULES_SECTION"></ng-container>
                  <ng-container *ngTemplateOutlet="CHECK_CONTRACT_SECTION"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="legalStatusesEnum.INDIVIDUAL_ENTREPRENEUR">
                  <ng-container *ngTemplateOutlet="PASSPORT_SECTION"></ng-container>
                  <ng-container *ngTemplateOutlet="REGISTRATION_IP_SECTION"></ng-container>
                  <ng-container
                    [ngTemplateOutlet]="ADDRESS_SECTION"
                    [ngTemplateOutletContext]="{
                      addressTitles: {
                        registration: 'Адрес регистрации индивидуального предпринимателя',
                        postal: 'Почтовый адрес индивидуального предпринимателя'
                      }
                    }"
                  >
                  </ng-container>
                  <ng-container *ngTemplateOutlet="BANK_REQUISITES_IP_SECTION"></ng-container>
                  <ng-container *ngTemplateOutlet="CHECK_RULES_SECTION"></ng-container>
                  <ng-container *ngTemplateOutlet="CHECK_CONTRACT_SECTION"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="legalStatusesEnum.LEGAL_ENTITY">
                  <ng-container *ngTemplateOutlet="REGISTRATION_UL_SECTION"></ng-container>
                  <ng-container *ngTemplateOutlet="AUTHORIZED_PERSON_SECTION"></ng-container>
                  <ng-container
                    [ngTemplateOutlet]="ADDRESS_SECTION"
                    [ngTemplateOutletContext]="{
                      addressTitles: {
                        registration: 'Юридический адрес',
                        postal: 'Почтовый адрес'
                      }
                    }"
                  >
                  </ng-container>
                  <ng-container
                    [ngTemplateOutlet]="BANK_REQUISITES_APN_SECTION"
                    [ngTemplateOutletContext]="{
                      options: {
                        header: 'Платёжные реквизиты',
                        bank_name: 'Название банка:',
                        bank_bic: 'БИК банка:',
                        bank_corresponding: 'Корреспондентский счёт:',
                        bank_current: 'Расчётный счёт:'
                      }
                    }"
                  >
                  </ng-container>
                  <ng-container *ngTemplateOutlet="CHECK_RULES_SECTION"></ng-container>
                  <ng-container *ngTemplateOutlet="CHECK_CONTRACT_SECTION"></ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="residencesEnum.OTHER">
              <ng-container [ngSwitch]="currentLegalStatus">
                <ng-container *ngSwitchCase="legalStatusesEnum.NATURAL_PERSON">
                  <ng-container *ngTemplateOutlet="CHECK_RULES_SECTION"></ng-container>
                  <ng-container *ngTemplateOutlet="CHECK_CONTRACT_SECTION"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="legalStatusesEnum.LEGAL_ENTITY">
                  <ng-container *ngTemplateOutlet="REGISTRATION_OTHER_UL_SECTION"></ng-container>
                  <ng-container *ngTemplateOutlet="AUTHORIZED_PERSON_SECTION"></ng-container>
                  <ng-container
                    [ngTemplateOutlet]="ADDRESS_SECTION"
                    [ngTemplateOutletContext]="{
                      addressTitles: {
                        registration: 'Юридический адрес',
                        postal: 'Почтовый адрес'
                      }
                    }"
                  >
                  </ng-container>
                  <ng-container
                    [ngTemplateOutlet]="BANK_REQUISITES_APN_SECTION_OTHER"
                    [ngTemplateOutletContext]="{
                      options: {
                        header: 'Платёжные реквизиты',
                        bank_name: 'Название банка:',
                        bank_bic: 'SWIFT:',
                        bank_corresponding: 'IBAN:',
                        bank_current: 'Расчётный счёт:'
                      }
                    }"
                  >
                  </ng-container>
                  <ng-container *ngTemplateOutlet="CHECK_RULES_SECTION"></ng-container>
                  <ng-container *ngTemplateOutlet="CHECK_CONTRACT_SECTION"></ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
        <!-- endregion -->

        <!-- region Админ. направления-->
        <ng-container *ngSwitchCase="roles.ADMIN_OF_DIRECTION">
          <ng-container [ngSwitch]="currentResidency">
            <ng-container *ngSwitchCase="residencesEnum.RUSSIAN_FEDERATION">
              <ng-container [ngSwitch]="currentLegalStatus">
                <ng-container *ngSwitchCase="legalStatusesEnum.NATURAL_PERSON">
                  <ng-container *ngTemplateOutlet="CHECK_RULES_SECTION"></ng-container>
                  <ng-container *ngTemplateOutlet="CHECK_CONTRACT_SECTION"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="legalStatusesEnum.INDIVIDUAL_ENTREPRENEUR">
                  <ng-container *ngTemplateOutlet="PASSPORT_SECTION"></ng-container>
                  <ng-container *ngTemplateOutlet="REGISTRATION_IP_SECTION"></ng-container>
                  <ng-container
                    [ngTemplateOutlet]="ADDRESS_SECTION"
                    [ngTemplateOutletContext]="{
                      addressTitles: {
                        registration: 'Адрес регистрации индивидуального предпринимателя',
                        postal: 'Почтовый адрес индивидуального предпринимателя'
                      }
                    }"
                  >
                  </ng-container>
                  <ng-container *ngTemplateOutlet="BANK_REQUISITES_IP_SECTION"></ng-container>
                  <ng-container *ngTemplateOutlet="CHECK_RULES_SECTION"></ng-container>
                  <ng-container *ngIf="isCanBePayer">
                    <ng-container *ngTemplateOutlet="CHECK_CONTRACT_SECTION"></ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="legalStatusesEnum.LEGAL_ENTITY">
                  <ng-container *ngTemplateOutlet="REGISTRATION_UL_SECTION"></ng-container>
                  <ng-container *ngTemplateOutlet="AUTHORIZED_PERSON_SECTION"></ng-container>
                  <ng-container
                    [ngTemplateOutlet]="ADDRESS_SECTION"
                    [ngTemplateOutletContext]="{
                      addressTitles: {
                        registration: 'Юридический адрес',
                        postal: 'Почтовый адрес'
                      }
                    }"
                  >
                  </ng-container>
                  <ng-container
                    [ngTemplateOutlet]="BANK_REQUISITES_APN_SECTION"
                    [ngTemplateOutletContext]="{
                      options: {
                        header: 'Платёжные реквизиты',
                        bank_name: 'Название банка:',
                        bank_bic: 'БИК банка:',
                        bank_corresponding: 'Корреспондентский счёт:',
                        bank_current: 'Расчётный счёт:'
                      }
                    }"
                  >
                  </ng-container>
                  <ng-container *ngTemplateOutlet="CHECK_RULES_SECTION"></ng-container>
                  <ng-container *ngTemplateOutlet="CHECK_CONTRACT_SECTION"></ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="residencesEnum.OTHER">
              <ng-container [ngSwitch]="currentLegalStatus">
                <ng-container *ngSwitchCase="legalStatusesEnum.NATURAL_PERSON">
                  <ng-container *ngTemplateOutlet="CHECK_RULES_SECTION"></ng-container>
                  <ng-container *ngTemplateOutlet="CHECK_CONTRACT_SECTION"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="legalStatusesEnum.LEGAL_ENTITY">
                  <ng-container *ngTemplateOutlet="REGISTRATION_OTHER_UL_SECTION"></ng-container>
                  <ng-container *ngTemplateOutlet="AUTHORIZED_PERSON_SECTION"></ng-container>
                  <ng-container
                    [ngTemplateOutlet]="ADDRESS_SECTION"
                    [ngTemplateOutletContext]="{
                      addressTitles: {
                        registration: 'Юридический адрес',
                        postal: 'Почтовый адрес'
                      }
                    }"
                  >
                  </ng-container>
                  <ng-container
                    [ngTemplateOutlet]="BANK_REQUISITES_APN_SECTION_OTHER"
                    [ngTemplateOutletContext]="{
                      options: {
                        header: 'Платёжные реквизиты',
                        bank_name: 'Название банка:',
                        bank_bic: 'SWIFT:',
                        bank_corresponding: 'IBAN:',
                        bank_current: 'Расчётный счёт:'
                      }
                    }"
                  >
                  </ng-container>
                  <ng-container *ngTemplateOutlet="CHECK_RULES_SECTION"></ng-container>
                  <ng-container *ngTemplateOutlet="CHECK_CONTRACT_SECTION"></ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
        <!-- endregion -->
      </ng-container>

      <div class="form-actions margin-t-30">
        <button type="button" class="btn btn-dc-outline" (click)="cancel()">Отменить</button>
        <button type="submit" class="btn btn-dc-primary">Сохранить</button>
      </div>

      <!-- region fields and sections -->
      <ng-template #PASSPORT_SECTION>
        <div class="form-section">
          <div class="form-section-header d-flex mod-align-center">
            Паспорт и личные регистрационные данные
            <button type="button" class="icon-button">
              <app-faq-tooltip tooltipName="auth__auth_signin" class="hint-right"></app-faq-tooltip>
            </button>
          </div>
          <div class="form-section-content">
            <ng-container *ngTemplateOutlet="REGISTRY_PASSPORT_FIELDS"></ng-container>
          </div>
        </div>
      </ng-template>

      <ng-template #REGISTRY_SECTION>
        <div class="form-section">
          <div class="form-section-header d-flex mod-align-center">
            Паспорт и личные регистрационные данные
            <button type="button" class="icon-button">
              <app-faq-tooltip tooltipName="auth__auth_signin" class="hint-right"></app-faq-tooltip>
            </button>
          </div>
          <div class="form-section-content">
            <ng-container *ngTemplateOutlet="REGISTRY_PASSPORT_FIELDS"></ng-container>
            <ng-container *ngTemplateOutlet="INN_FIELD"></ng-container>
            <ng-container *ngTemplateOutlet="SNILS_FIELD"></ng-container>
            <ng-container *ngTemplateOutlet="BIRTHDAY_FIELD"></ng-container>
          </div>
        </div>
      </ng-template>

      <ng-template #REGISTRATION_IP_SECTION>
        <div class="form-section">
          <div class="form-section-header d-flex mod-align-center">
            Данные о регистрации
            <button type="button" class="icon-button">
              <app-faq-tooltip tooltipName="auth__auth_signin" class="hint-right"></app-faq-tooltip>
            </button>
          </div>
          <div class="form-section-content">
            <ng-container *ngTemplateOutlet="COUNTRY_REGISTRY_FIELDS"></ng-container>
            <ng-container *ngTemplateOutlet="INN_FIELD"></ng-container>
            <ng-container *ngTemplateOutlet="PSRNSP_FIELD"></ng-container>
          </div>
        </div>
      </ng-template>

      <ng-template #REGISTRATION_UL_SECTION>
        <div class="form-section">
          <div class="form-section-header d-flex mod-align-center">
            Данные о регистрации
            <button type="button" class="icon-button">
              <app-faq-tooltip tooltipName="auth__auth_signin" class="hint-right"></app-faq-tooltip>
            </button>
          </div>
          <div class="form-section-content">
            <ng-container *ngTemplateOutlet="OPF_FIELD"></ng-container>
            <ng-container *ngTemplateOutlet="ORGANIZATION_NAME_FIELD"></ng-container>
            <ng-container *ngTemplateOutlet="COUNTRY_REGISTRY_FIELDS"></ng-container>
            <ng-container *ngTemplateOutlet="INN_FIELD"></ng-container>
            <ng-container *ngTemplateOutlet="KPP_FIELD"></ng-container>
            <ng-container *ngTemplateOutlet="PSRN_FIELD"></ng-container>
          </div>
        </div>
      </ng-template>

      <ng-template #REGISTRATION_OTHER_UL_SECTION>
        <div class="form-section">
          <div class="form-section-header d-flex mod-align-center">
            Данные о регистрации
            <button type="button" class="icon-button">
              <app-faq-tooltip tooltipName="auth__auth_signin" class="hint-right"></app-faq-tooltip>
            </button>
          </div>
          <div class="form-section-content">
            <ng-container *ngTemplateOutlet="OPF_FIELD"></ng-container>
            <ng-container *ngTemplateOutlet="ORGANIZATION_NAME_FIELD"></ng-container>
            <ng-container *ngTemplateOutlet="COUNTRY_REGISTRY_FIELDS"></ng-container>
            <ng-container *ngTemplateOutlet="INN_OTHER_FIELD"></ng-container>
          </div>
        </div>
      </ng-template>

      <ng-template #ADDRESS_SECTION let-addressTitles="addressTitles">
        <div class="form-section">
          <div class="form-section-header d-flex mod-align-center">
            {{ addressTitles.registration }}
            <button type="button" class="icon-button">
              <app-faq-tooltip tooltipName="auth__auth_signin" class="hint-right"></app-faq-tooltip>
            </button>
          </div>
          <div class="form-section-content">
            <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
              <dc-form-label>Индекс:</dc-form-label>
              <ng-container formGroupName="company">
                <input
                  formInput
                  class="address_postcode"
                  mask="000000"
                  formControlName="registration_address_postcode"
                />
              </ng-container>
              <div
                *ngIf="
                  form.get('company.registration_address_postcode').touched &&
                  form.get('company.registration_address_postcode').errors?.required
                "
                class="form-field-error"
              >
                {{ messages.required }}
              </div>
              <div
                *ngIf="
                  form.get('company.registration_address_postcode').touched &&
                  form.get('company.registration_address_postcode').errors?.mask
                "
                class="form-field-error"
              >
                Индекс состоит из 6 цифр
              </div>
            </dc-form-field-wrapper>
            <dc-form-field-wrapper [style]="'bootstrap'" [required]="true" [vertically]="true">
              <dc-form-label>Адрес:</dc-form-label>
              <div class="custom-form-input">
                <ng-container formGroupName="company">
                  <app-location-select [userId]="user.id" formControlName="registration_address"></app-location-select>
                </ng-container>
              </div>
            </dc-form-field-wrapper>
          </div>
        </div>
        <div class="form-section">
          <div class="form-section-header d-flex mod-align-center">
            <div class="d-flex address-copy-section">
              {{ addressTitles.postal }}
              <button type="button" class="icon-button">
                <app-faq-tooltip tooltipName="auth__auth_signin" class="hint-right"></app-faq-tooltip>
              </button>
            </div>
            <div
              class="d-flex copy-address-label"
              [ngClass]="{ 'margin-l-36': currentLegalStatus === 'individual_entrepreneur' }"
            >
              <input
                id="copy-address"
                class="cbx-input"
                type="checkbox"
                #copyAddress
                (change)="copyAddresses(copyAddress.checked)"
              />
              <label class="cbx-label" for="copy-address">
                <span class="cbx-label-text">Совпадает с адресом регистрации</span>
              </label>
            </div>
          </div>
          <div class="form-section-content">
            <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
              <dc-form-label>Индекс:</dc-form-label>
              <ng-container formGroupName="company">
                <input formInput class="address_postcode" mask="000000" formControlName="postal_address_postcode" />
              </ng-container>
              <div
                *ngIf="
                  form.get('company.postal_address_postcode').touched &&
                  form.get('company.postal_address_postcode').errors?.required
                "
                class="form-field-error"
              >
                {{ messages.required }}
              </div>
              <div
                *ngIf="
                  form.get('company.postal_address_postcode').touched &&
                  form.get('company.postal_address_postcode').errors?.mask
                "
                class="form-field-error"
              >
                Индекс состоит из 6 цифр
              </div>
            </dc-form-field-wrapper>
            <dc-form-field-wrapper [style]="'bootstrap'" [required]="true" [vertically]="true">
              <dc-form-label>Адрес:</dc-form-label>
              <div class="custom-form-input">
                <ng-container formGroupName="company">
                  <app-location-select [userId]="user.id" formControlName="postal_address"></app-location-select>
                </ng-container>
              </div>
            </dc-form-field-wrapper>
          </div>
        </div>
      </ng-template>

      <ng-template #BANK_CARD_SECTION>
        <div class="form-section">
          <div class="form-section-header d-flex mod-align-center">
            Банковские реквизиты
            <button type="button" class="icon-button">
              <app-faq-tooltip tooltipName="auth__auth_signin" class="hint-right"></app-faq-tooltip>
            </button>
          </div>
          <div class="form-section-content">
            <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
              <dc-form-label>Номер банковской карты:</dc-form-label>
              <ng-container formGroupName="company">
                <input formInput class="bankcard_number" [mask]="cardMask" formControlName="bankcard_number" />
              </ng-container>
              <div
                *ngIf="
                  form.get('company.bankcard_number').touched && form.get('company.bankcard_number').errors?.required
                "
                class="form-field-error"
              >
                {{ messages.required }}
              </div>
            </dc-form-field-wrapper>
            <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
              <dc-form-label>Банк-эмитент:</dc-form-label>
              <ng-container formGroupName="company">
                <input formInput [maxLength]="200" class="bankcard_bank_name" formControlName="bankcard_bank_name" />
              </ng-container>
              <div
                *ngIf="
                  form.get('company.bankcard_bank_name').touched &&
                  form.get('company.bankcard_bank_name').errors?.required
                "
                class="form-field-error"
              >
                {{ messages.required }}
              </div>
            </dc-form-field-wrapper>
          </div>
        </div>
      </ng-template>

      <ng-template #BANK_REQUISITES_IP_SECTION>
        <div class="form-section">
          <div class="form-section-header d-flex mod-align-center">
            Платёжные реквизиты
            <button type="button" class="icon-button">
              <app-faq-tooltip tooltipName="auth__auth_signin" class="hint-right"></app-faq-tooltip>
            </button>
          </div>
          <div class="form-section-content">
            <div class="ip-bank-tabs">
              <ng-container *ngFor="let bankTab of bankTabs">
                <button
                  type="button"
                  class=""
                  [ngClass]="{ active: activeBankTab === bankTab.value }"
                  (click)="changeBankTab(bankTab.value)"
                >
                  <span class="dc-toolbar-btn-title">{{ bankTab.title }}</span>
                </button>
              </ng-container>
            </div>

            <ng-container [ngSwitch]="activeBankTab">
              <div class="ip-bank-content-tab" *ngSwitchCase="'bank_account'" @fadeIn>
                <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
                  <dc-form-label>Название банка:</dc-form-label>
                  <ng-container formGroupName="company">
                    <input formInput [maxLength]="200" class="bank_name" formControlName="bank_name" />
                  </ng-container>
                  <div
                    *ngIf="form.get('company.bank_name').touched && form.get('company.bank_name').errors?.required"
                    class="form-field-error"
                  >
                    {{ messages.required }}
                  </div>
                </dc-form-field-wrapper>
                <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
                  <dc-form-label>БИК банка:</dc-form-label>
                  <ng-container formGroupName="company">
                    <input formInput mask="000000000" class="bank_bic" formControlName="bank_bic" />
                  </ng-container>
                  <div
                    *ngIf="form.get('company.bank_bic').touched && form.get('company.bank_bic').errors?.required"
                    class="form-field-error"
                  >
                    {{ messages.required }}
                  </div>
                  <div
                    *ngIf="form.get('company.bank_bic').touched && form.get('company.bank_bic').errors?.mask"
                    class="form-field-error"
                  >
                    БИК банка состоит из 9 цифр
                  </div>
                </dc-form-field-wrapper>
                <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
                  <dc-form-label>Корреспондентский счёт:</dc-form-label>
                  <ng-container formGroupName="company">
                    <input
                      formInput
                      mask="00000000000000000000"
                      class="bank_corresponding_account"
                      formControlName="bank_corresponding_account"
                    />
                  </ng-container>
                  <div
                    *ngIf="
                      form.get('company.bank_corresponding_account').touched &&
                      form.get('company.bank_corresponding_account').errors?.required
                    "
                    class="form-field-error"
                  >
                    {{ messages.required }}
                  </div>
                  <div
                    *ngIf="
                      form.get('company.bank_corresponding_account').touched &&
                      form.get('company.bank_corresponding_account').errors?.mask
                    "
                    class="form-field-error"
                  >
                    Корреспондентский счёт состоит из 20 цифр
                  </div>
                </dc-form-field-wrapper>
                <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
                  <dc-form-label>Расчётный счёт:</dc-form-label>
                  <ng-container formGroupName="company">
                    <input
                      formInput
                      mask="00000000000000000000"
                      class="bank_current_account"
                      formControlName="bank_current_account"
                    />
                  </ng-container>
                  <div
                    *ngIf="
                      form.get('company.bank_current_account').touched &&
                      form.get('company.bank_current_account').errors?.required
                    "
                    class="form-field-error"
                  >
                    {{ messages.required }}
                  </div>
                  <div
                    *ngIf="
                      form.get('company.bank_current_account').touched &&
                      form.get('company.bank_current_account').errors?.mask
                    "
                    class="form-field-error"
                  >
                    Расчётный счёт состоит из 20 цифр
                  </div>
                </dc-form-field-wrapper>
              </div>

              <div class="ip-bank-content-tab" *ngSwitchCase="'bank_card'" @fadeIn>
                <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
                  <dc-form-label>Номер банковской карты:</dc-form-label>
                  <ng-container formGroupName="company">
                    <input
                      formInput
                      class="bankcard_number"
                      [mask]="cardMask"
                      #card
                      (input)="moveCaretToEnd(card)"
                      formControlName="bankcard_number"
                    />
                  </ng-container>
                  <div
                    *ngIf="
                      form.get('company.bankcard_number').touched &&
                      form.get('company.bankcard_number').errors?.required
                    "
                    class="form-field-error"
                  >
                    {{ messages.required }}
                  </div>
                </dc-form-field-wrapper>
                <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
                  <dc-form-label>Банк-эмитент:</dc-form-label>
                  <ng-container formGroupName="company">
                    <input
                      formInput
                      [maxLength]="200"
                      class="bankcard_bank_name"
                      formControlName="bankcard_bank_name"
                    />
                  </ng-container>
                  <div
                    *ngIf="
                      form.get('company.bankcard_bank_name')?.touched &&
                      form.get('company.bankcard_bank_name')?.errors?.required
                    "
                    class="form-field-error"
                  >
                    {{ messages.required }}
                  </div>
                </dc-form-field-wrapper>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-template>

      <ng-template #BANK_REQUISITES_APN_SECTION let-options="options">
        <div class="form-section">
          <div class="form-section-header d-flex mod-align-center">
            {{ options?.header }}
            <button type="button" class="icon-button">
              <app-faq-tooltip tooltipName="auth__auth_signin" class="hint-right"></app-faq-tooltip>
            </button>
          </div>
          <div class="form-section-content">
            <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
              <dc-form-label>{{ options?.bank_name }}</dc-form-label>
              <ng-container formGroupName="company">
                <input formInput [maxLength]="200" class="bank_name" formControlName="bank_name" />
              </ng-container>
              <div
                *ngIf="form.get('company.bank_name').touched && form.get('company.bank_name').errors?.required"
                class="form-field-error"
              >
                {{ messages.required }}
              </div>
            </dc-form-field-wrapper>
            <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
              <dc-form-label>{{ options?.bank_bic }}</dc-form-label>
              <ng-container formGroupName="company">
                <input formInput mask="000000000" class="bank_bic" formControlName="bank_bic" />
              </ng-container>
              <div
                *ngIf="form.get('company.bank_bic').touched && form.get('company.bank_bic').errors?.required"
                class="form-field-error"
              >
                {{ messages.required }}
              </div>
              <div
                *ngIf="form.get('company.bank_bic').touched && form.get('company.bank_bic').errors?.mask"
                class="form-field-error"
              >
                БИК банка состоит из 9 цифр
              </div>
            </dc-form-field-wrapper>
            <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
              <dc-form-label>{{ options?.bank_corresponding }}</dc-form-label>
              <ng-container formGroupName="company">
                <input
                  formInput
                  mask="00000000000000000000"
                  class="bank_corresponding_account"
                  formControlName="bank_corresponding_account"
                />
              </ng-container>
              <div
                *ngIf="
                  form.get('company.bank_corresponding_account').touched &&
                  form.get('company.bank_corresponding_account').errors?.required
                "
                class="form-field-error"
              >
                {{ messages.required }}
              </div>
              <div
                *ngIf="
                  form.get('company.bank_corresponding_account').touched &&
                  form.get('company.bank_corresponding_account').errors?.mask
                "
                class="form-field-error"
              >
                Корреспондентский счёт состоит из 20 цифр
              </div>
            </dc-form-field-wrapper>
            <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
              <dc-form-label>{{ options?.bank_current }}</dc-form-label>
              <ng-container formGroupName="company">
                <input
                  formInput
                  mask="00000000000000000000"
                  class="bank_current_account"
                  formControlName="bank_current_account"
                />
              </ng-container>
              <div
                *ngIf="
                  form.get('company.bank_current_account').touched &&
                  form.get('company.bank_current_account').errors?.required
                "
                class="form-field-error"
              >
                {{ messages.required }}
              </div>
              <div
                *ngIf="
                  form.get('company.bank_current_account').touched &&
                  form.get('company.bank_current_account').errors?.mask
                "
                class="form-field-error"
              >
                Расчётный счёт состоит из 20 цифр
              </div>
            </dc-form-field-wrapper>
          </div>
        </div>
      </ng-template>

      <ng-template #BANK_REQUISITES_APN_SECTION_OTHER let-options="options">
        <div class="form-section">
          <div class="form-section-header d-flex mod-align-center">
            {{ options?.header }}
            <button type="button" class="icon-button">
              <app-faq-tooltip tooltipName="auth__auth_signin" class="hint-right"></app-faq-tooltip>
            </button>
          </div>
          <div class="form-section-content">
            <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
              <dc-form-label>{{ options?.bank_name }}</dc-form-label>
              <ng-container formGroupName="company">
                <input formInput [maxLength]="200" class="bank_name" formControlName="bank_name" />
              </ng-container>
              <div
                *ngIf="form.get('company.bank_name').touched && form.get('company.bank_name').errors?.required"
                class="form-field-error"
              >
                {{ messages.required }}
              </div>
            </dc-form-field-wrapper>
            <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
              <dc-form-label>{{ options?.bank_bic }}</dc-form-label>
              <ng-container formGroupName="company">
                <input formInput [maxLength]="11" [onlyLetter]="false" class="bank_swift" formControlName="swift" />
              </ng-container>
              <div
                *ngIf="form.get('company.swift').touched && form.get('company.swift').invalid"
                class="form-field-error"
              >
                {{ messages.required }}
              </div>
            </dc-form-field-wrapper>
            <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
              <dc-form-label>{{ options?.bank_corresponding }}</dc-form-label>
              <ng-container formGroupName="company">
                <input
                  formInput
                  [maxLength]="34"
                  [onlyLetter]="false"
                  class="bank_corresponding_account_other"
                  formControlName="iban"
                />
              </ng-container>
              <div
                *ngIf="form.get('company.iban').touched && form.get('company.iban').errors?.required"
                class="form-field-error"
              >
                {{ messages.required }}
              </div>
            </dc-form-field-wrapper>
            <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
              <dc-form-label>{{ options?.bank_current }}</dc-form-label>
              <ng-container formGroupName="company">
                <input
                  formInput
                  mask="0000000000000000000000"
                  class="bank_current_account_other"
                  formControlName="bank_current_account"
                />
              </ng-container>
              <div
                *ngIf="
                  form.get('company.bank_current_account').touched &&
                  form.get('company.bank_current_account').errors?.required
                "
                class="form-field-error"
              >
                {{ messages.required }}
              </div>
              <div
                *ngIf="
                  form.get('company.bank_current_account').touched &&
                  form.get('company.bank_current_account').errors?.mask
                "
                class="form-field-error"
              >
                Расчётный счёт состоит из 22 цифр
              </div>
            </dc-form-field-wrapper>
          </div>
        </div>
      </ng-template>

      <ng-template #AUTHORIZED_PERSON_SECTION>
        <div class="form-section">
          <div class="form-section-header d-flex mod-align-center">
            Лицо, уполномоченное на подписание договора
            <button type="button" class="icon-button">
              <app-faq-tooltip tooltipName="auth__auth_signin" class="hint-right"></app-faq-tooltip>
            </button>
            <div class="d-flex margin-l-36 copy-person-label">
              <input
                id="copy-person"
                class="cbx-input"
                type="checkbox"
                #copyPerson
                (change)="copyPersonData(copyPerson.checked)"
              />
              <label class="cbx-label" for="copy-person">
                <span class="cbx-label-text">Совпадает с контактным лицом</span>
              </label>
            </div>
          </div>
          <div class="form-section-content">
            <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
              <dc-form-label>Фамилия:</dc-form-label>
              <ng-container formGroupName="company">
                <input
                  formInput
                  [maxLength]="20"
                  [onlyLetter]="true"
                  class="authorized_person"
                  formControlName="authorized_person_second_name"
                />
              </ng-container>
              <div
                *ngIf="
                  form.get('company.authorized_person_second_name').touched &&
                  form.get('company.authorized_person_second_name').errors?.required
                "
                class="form-field-error"
              >
                {{ messages.required }}
              </div>
            </dc-form-field-wrapper>
            <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
              <dc-form-label>Имя:</dc-form-label>
              <ng-container formGroupName="company">
                <input
                  formInput
                  [maxLength]="20"
                  [onlyLetter]="true"
                  class="authorized_person"
                  formControlName="authorized_person_first_name"
                />
              </ng-container>
              <div
                *ngIf="
                  form.get('company.authorized_person_first_name').touched &&
                  form.get('company.authorized_person_first_name').errors?.required
                "
                class="form-field-error"
              >
                {{ messages.required }}
              </div>
            </dc-form-field-wrapper>
            <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
              <dc-form-label>Отчество:</dc-form-label>
              <ng-container formGroupName="company">
                <input
                  formInput
                  [maxLength]="20"
                  [onlyLetter]="true"
                  class="authorized_person"
                  formControlName="authorized_person_patronymic"
                />
              </ng-container>
            </dc-form-field-wrapper>
            <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
              <dc-form-label>Должность:</dc-form-label>
              <ng-container formGroupName="company">
                <input formInput [maxLength]="100" class="authorized_person" formControlName="position" />
              </ng-container>
              <div
                *ngIf="form.get('company.position').touched && form.get('company.position').errors?.required"
                class="form-field-error"
              >
                {{ messages.required }}
              </div>
            </dc-form-field-wrapper>
            <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
              <dc-form-label>Основание полномочий:</dc-form-label>
              <ng-container formGroupName="company">
                <input formInput [maxLength]="100" class="authorized_person" formControlName="authority_basis" />
              </ng-container>
              <div
                *ngIf="
                  form.get('company.authority_basis').touched && form.get('company.authority_basis').errors?.required
                "
                class="form-field-error"
              >
                {{ messages.required }}
              </div>
            </dc-form-field-wrapper>
          </div>
        </div>
      </ng-template>

      <ng-template #CHECK_RULES_SECTION>
        <div class="form-section margin-b-0">
          <div class="form-section-content">
            <div class="d-flex contract-rule">
              <input id="checkRules" class="cbx-input" type="checkbox" formControlName="checkRules" />
              <label class="cbx-label" for="checkRules">
                <span class="cbx-label-text">Я согласен с</span>
              </label>
              <a class="cursor-pointer" (click)="getUserRules()">Правилами</a>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #CHECK_CONTRACT_SECTION>
        <div class="form-section margin-t-20 margin-b-0">
          <div class="form-section-content">
            <div class="d-flex contract-rule">
              <input id="checkContract" class="cbx-input" type="checkbox" formControlName="checkContract" />
              <label class="cbx-label" for="checkContract">
                <span class="cbx-label-text">Я согласен с условиями</span>
              </label>
              <a class="cursor-pointer" (click)="getContractRules()">Договора оферты</a>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #REGISTRY_PASSPORT_FIELDS>
        <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
          <dc-form-label>Серия и номер:</dc-form-label>
          <ng-container formGroupName="company">
            <input formInput mask="0000" class="passport_series" formControlName="passport_series" />
            <input formInput mask="000000" class="passport_number" formControlName="passport_number" />
          </ng-container>
          <div
            *ngIf="
              (form.get('company.passport_series').touched && form.get('company.passport_series').errors?.required) ||
              (form.get('company.passport_number').touched && form.get('company.passport_number').errors?.required)
            "
            class="form-field-error"
          >
            {{ messages.required }}
          </div>
          <div
            *ngIf="
              (form.get('company.passport_series').touched && form.get('company.passport_series').errors?.mask) ||
              (form.get('company.passport_number').touched && form.get('company.passport_number').errors?.mask)
            "
            class="form-field-error"
          >
            <ng-container *ngIf="form.get('company.passport_series').errors?.mask">
              Серия паспорта состоит из 4 цифр
            </ng-container>
            <ng-container *ngIf="form.get('company.passport_number').errors?.mask">
              &nbsp;Номер паспорта состоит из 6 цифр
            </ng-container>
          </div>
        </dc-form-field-wrapper>
        <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
          <dc-form-label>Код подразделения:</dc-form-label>
          <ng-container formGroupName="company">
            <input formInput class="passport_division_code" mask="000 - 000" formControlName="passport_division_code" />
          </ng-container>
          <div
            *ngIf="
              form.get('company.passport_division_code').touched &&
              form.get('company.passport_division_code').errors?.required
            "
            class="form-field-error"
          >
            {{ messages.required }}
          </div>
          <div
            *ngIf="
              form.get('company.passport_division_code').touched &&
              form.get('company.passport_division_code').errors?.mask
            "
            class="form-field-error"
          >
            Код подразделения состоит из 6 цифр
          </div>
        </dc-form-field-wrapper>

        <app-datepicker
          class="d-flex"
          controlNameDate="company.passport_date"
          [form]="form"
          [maxDate]="maxDate"
          [fieldParams]="{ dateLabel: 'Дата выдачи:' }"
        ></app-datepicker>

        <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
          <dc-form-label>Кем выдан:</dc-form-label>
          <ng-container formGroupName="company">
            <textarea formInput formControlName="passport_issued_by"></textarea>
            <div
              *ngIf="form.get('company.passport_issued_by').touched && form.get('company.passport_issued_by').invalid"
              class="form-field-error"
            >
              {{ messages.required }}
            </div>
          </ng-container>
        </dc-form-field-wrapper>
      </ng-template>

      <ng-template #COUNTRY_REGISTRY_FIELDS>
        <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
          <dc-form-label>Номер свидетельства<br />о гос. регистрации:</dc-form-label>
          <ng-container formGroupName="company">
            <input formInput [maxLength]="20" class="certificate_number" formControlName="certificate_number" />
          </ng-container>
          <div
            *ngIf="form.get('company.certificate_number').touched && form.get('company.certificate_number').invalid"
            class="form-field-error"
          >
            {{ messages.required }}
          </div>
        </dc-form-field-wrapper>

        <app-datepicker
          class="d-flex"
          [controlNameDate]="'company.certificate_date'"
          [form]="form"
          [maxDate]="maxDate"
          [fieldParams]="{ dateLabel: 'Дата гос. регистрации:' }"
        ></app-datepicker>
      </ng-template>

      <ng-template #INN_FIELD>
        <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
          <dc-form-label>ИНН:</dc-form-label>
          <ng-container formGroupName="company">
            <input formInput class="itn" mask="000000000000" formControlName="itn" />
          </ng-container>
          <div
            *ngIf="form.get('company.itn').touched && form.get('company.itn').errors?.required"
            class="form-field-error"
          >
            {{ messages.required }}
          </div>
          <div *ngIf="form.get('company.itn').touched && form.get('company.itn').errors?.mask" class="form-field-error">
            ИНН состоит из 12 цифр
          </div>
        </dc-form-field-wrapper>
      </ng-template>

      <ng-template #INN_OTHER_FIELD>
        <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
          <dc-form-label>Номер налогоплательщика:</dc-form-label>
          <ng-container formGroupName="company">
            <input formInput [maxLength]="20" class="itn" formControlName="itn" />
          </ng-container>
          <div
            *ngIf="form.get('company.itn').touched && form.get('company.itn').errors?.required"
            class="form-field-error"
          >
            {{ messages.required }}
          </div>
        </dc-form-field-wrapper>
      </ng-template>

      <ng-template #KPP_FIELD>
        <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
          <dc-form-label>КПП:</dc-form-label>
          <ng-container formGroupName="company">
            <input formInput [maxLength]="9" mask="000000000" class="iec" formControlName="iec" />
          </ng-container>
          <div
            *ngIf="form.get('company.iec').touched && form.get('company.iec').errors?.required"
            class="form-field-error"
          >
            {{ messages.required }}
          </div>
          <div *ngIf="form.get('company.iec').touched && form.get('company.iec').errors?.mask" class="form-field-error">
            КПП состоит из 9 цифр
          </div>
        </dc-form-field-wrapper>
      </ng-template>

      <ng-template #PSRN_FIELD>
        <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
          <dc-form-label>ОГРН:</dc-form-label>
          <ng-container formGroupName="company">
            <input [maxLength]="13" mask="0000000000000" formInput class="psrn" formControlName="psrn" />
          </ng-container>
          <div
            *ngIf="form.get('company.psrn').touched && form.get('company.psrn').errors?.required"
            class="form-field-error"
          >
            {{ messages.required }}
          </div>
          <div
            *ngIf="form.get('company.psrn').touched && form.get('company.psrn').errors?.mask"
            class="form-field-error"
          >
            ОГРН состоит из 13 цифр
          </div>
        </dc-form-field-wrapper>
      </ng-template>

      <ng-template #SNILS_FIELD>
        <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
          <dc-form-label>СНИЛС:</dc-form-label>
          <ng-container formGroupName="company">
            <input formInput class="snils" mask="000 - 000 - 000 00" formControlName="snils" />
          </ng-container>
          <div
            *ngIf="form.get('company.snils').touched && form.get('company.snils').errors?.required"
            class="form-field-error"
          >
            {{ messages.required }}
          </div>
          <div
            *ngIf="form.get('company.snils').touched && form.get('company.snils').errors?.mask"
            class="form-field-error"
          >
            СНИЛС состоит из 11 цифр
          </div>
        </dc-form-field-wrapper>
      </ng-template>

      <ng-template #PSRNSP_FIELD>
        <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
          <dc-form-label>ОГРНИП:</dc-form-label>
          <ng-container formGroupName="company">
            <input formInput mask="000000000000000" class="psrnsp" formControlName="psrnsp" />
          </ng-container>
          <div
            *ngIf="form.get('company.psrnsp').touched && form.get('company.psrnsp').errors?.required"
            class="form-field-error"
          >
            {{ messages.required }}
          </div>
          <div
            *ngIf="form.get('company.psrnsp').touched && form.get('company.psrnsp').errors?.mask"
            class="form-field-error"
          >
            ОГРНИП состоит из 15 цифр
          </div>
        </dc-form-field-wrapper>
      </ng-template>

      <ng-template #OPF_FIELD>
        <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
          <dc-form-label>ОПФ:</dc-form-label>
          <ng-container formGroupName="company">
            <input
              formInput
              [maxLength]="50"
              [onlyLetter]="true"
              [isSpace]="true"
              class="organization_type"
              formControlName="organization_type"
            />
          </ng-container>
          <div
            *ngIf="
              form.get('company.organization_type').touched && form.get('company.organization_type').errors?.required
            "
            class="form-field-error"
          >
            {{ messages.required }}
          </div>
        </dc-form-field-wrapper>
      </ng-template>

      <ng-template #ORGANIZATION_NAME_FIELD>
        <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
          <dc-form-label>Название организации:</dc-form-label>
          <ng-container formGroupName="company">
            <textarea [maxLength]="100" formInput formControlName="organization_name"></textarea>
          </ng-container>
          <div
            *ngIf="form.get('company.organization_name').touched && form.get('company.organization_name').invalid"
            class="form-field-error"
          >
            {{ messages.required }}
          </div>
        </dc-form-field-wrapper>
      </ng-template>

      <ng-template #BIRTHDAY_FIELD>
        <app-datepicker
          class="d-flex"
          [controlNameDate]="'birthday'"
          [form]="form"
          [maxDate]="maxDate"
          [fieldParams]="{ dateLabel: 'Дата рождения:' }"
        ></app-datepicker>
      </ng-template>

      <!-- endregion-->
    </form>
  </div>
</div>
