<table class="accessed-users">
  <thead>
    <tr>
      <th>ФИО</th>
      <th>Организация</th>
      <th>Специализация</th>
      <th>Телефон</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let user of users">
      <tr>
        <td>{{ user | initials }}</td>
        <td class="organization">{{ user.company.name }}</td>
        <td class="specialization">
          <app-competencies-cell
            class="specialization__cell"
            [visibleCount]="2"
            [parentCompetencies]="user.competence_sections"
            [isStaticData]="isStaticData"
          ></app-competencies-cell>
        </td>
        <td>
          <ng-container *ngFor="let phone of user.phones">{{ phone | textSeparator : '-' : 3 }}</ng-container>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
