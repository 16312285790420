<div class="mb-4">
  <dc-form-field-wrapper [style]="'material'" [vertically]="true" [required]="false">
    <dc-form-input [isShowClear]="true">
      <input
        formInput
        [onlyInteger]="false"
        [formControl]="searchControl"
        [placeholder]="placeholder"
        [required]="false"
        appTestLocator="multiple-livesearch-select__search"
      />
    </dc-form-input>
  </dc-form-field-wrapper>
</div>

<div class="multiple-search-text-all" *ngIf="!isChangeAllHidden">
  <input class="cbx-input" id="all" name="all" type="checkbox" [ngModel]="allFiltersSelected" (change)="changeAll()" />
  <label
    class="cbx-label"
    for="all"
    [class.cbx-label-interminate]="partiallySelected"
    appTestLocator="multiple-livesearch-select__select-all"
  >
    <span class="cbx-label-text">Выделить всё/снять выделение</span>
  </label>
</div>

<div class="toggles" *ngIf="showDoubleCarets && !isChangeAllHidden">
  <button class="dc-icon-button" (click)="toggleByLevel(catalogTree, 1)">
    <app-svg-icon [name]="!isCatalogTreeExpandedL1 ? 'caret_down' : 'caret_up'" height="12" width="10"></app-svg-icon>
  </button>
  <button class="dc-icon-button" (click)="toggleByLevel(catalogTree, 2)">
    <app-svg-icon [name]="!isCatalogTreeExpandedL2 ? 'caret_down' : 'caret_up'" height="12" width="10"></app-svg-icon>
  </button>
</div>

<div class="tree-list">
  <tree-root
    #catalogTree
    (toggleExpanded)="keepCaretState('editorCarets', $event)"
    [nodes]="treeNodes"
    [options]="treeOptions"
    class="dc-tree dc-tree--my-competencies my-competences-container"
  >
    <ng-template #treeNodeTemplate let-node let-index="index">
      <app-my-catalog-tree-item
        [node]="node"
        [selected]="isSelected(node.data.id)"
        [searchValue]="searchControl.value"
        [isShowDoubleArrow]="false"
        (onToggle)="expandItem(catalogTree, node, $event)"
        (onCheckboxChanges)="checkboxSelectionChanged($event)"
        [readonly]="readonlyFunction && readonlyFunction(node)"
      >
      </app-my-catalog-tree-item>
    </ng-template>
  </tree-root>

  <div *ngIf="isChangeAllHidden">Ничего не найдено</div>
</div>

<div class="d-flex justify-content-end button-wrapper" *ngIf="showSubmitButton">
  <button
    class="btn btn-dc-primary"
    appTestLocator="multiple-search__confirm"
    [disabled]="isApplyDisable"
    (click)="apply()"
  >
    Применить
  </button>
</div>
