import { Component, OnDestroy } from '@angular/core';
import { ChatSectionService } from '@app/chat/services/chat-section.service';
import { MessagesSelectService } from '@app/chat/services/messages-select.service';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { ChatSectionsEnum } from '@app/chat/constants/chat-sections.constants';
import { ChatRoom, ChatSection } from '@app/chat/models/chat.model';
import { ChatService } from '@app/chat/services/chat.service';
import { DestroyService } from '@app/services/destroy.service';
import { SocketDataService } from '@app/services/socket-data.service';
import { AuthService } from '@app/shared/services/auth.service';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { PersonalDataService } from '@app/shared/services/personal-data.service';
import { ChatSortingService } from '@app/chat/services/chat-sorting.service';
import { tap } from 'rxjs/internal/operators';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  providers: [DestroyService],
})
export class ChatComponent implements OnDestroy {
  isEditingGroupOrTheme: boolean;
  isEditingContactsOnlyFilter = this.chatService.contactsOnlyFiltersState$.pipe(
    map((filters) => filters[this.contactsFilterName])
  );
  isContactsHidden: boolean = false;
  isMessageSelected: boolean = false;
  contactSelected: ChatRoom = {} as ChatRoom;
  chatSectionSelected: ChatSection = {} as ChatSection;
  chatSectionsEnum = ChatSectionsEnum;
  groupSelected: ChatRoom;
  rolesEnum = RolesEnum;
  isTradeGroup = this.chatService.isTradeGroup;

  chatLoading$ = this.chatService.chatLoading.asObservable();
  isCollapsed$ = this.chatService.isCollapsed$.asObservable();

  contactsFilterName = 'allContacts';

  get totalUnreadMessages() {
    return this.chatService.widgetMessageCounter;
  }

  constructor(
    private chatService: ChatService,
    private authService: AuthService,
    private readonly destroy$: DestroyService,
    private socketDataService: SocketDataService,
    private chatSectionService: ChatSectionService,
    private messagesSelectService: MessagesSelectService,
    private personalDataService: PersonalDataService,
    private chatSortingService: ChatSortingService
  ) {
    this.socketDataService
      .getRootFolderId()
      .pipe(takeUntil(this.destroy$))
      .subscribe((id) => (this.chatService.userRootFolderId = id));

    this.chatService.isEditingGroupOrThemeChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe((isEditingGroupOrTheme) => (this.isEditingGroupOrTheme = isEditingGroupOrTheme));

    this.chatSectionService.chatSectionSelectedChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe((chatSectionSelected) => {
        this.chatSectionSelected = chatSectionSelected;
        this.contactsFilterName = chatSectionSelected.name;
      });

    this.chatService.contactSelectedChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe((contactSelected) => (this.contactSelected = contactSelected));

    this.chatService.groupSelectedChanged.pipe(takeUntil(this.destroy$)).subscribe((groupSelected) => {
      this.groupSelected = groupSelected;
    });

    this.messagesSelectService.messageSelectedChanged.pipe(takeUntil(this.destroy$)).subscribe((messageSelected) => {
      this.isMessageSelected = !!messageSelected.length;
    });

    this.authService.userStream
      .pipe(
        tap(() => {
          this.isEditingGroupOrTheme = this.chatService.isEditingGroupOrTheme;
          this.contactSelected = this.chatService.getContactSelected();
          this.chatSectionSelected = this.chatSectionService.chatSectionSelected;
        }),
        switchMap(() => this.personalDataService.getUserSettings()),
        takeUntil(this.destroy$)
      )
      .subscribe((userSettings) => {
        this.chatSortingService.setSettings(userSettings);
      });
  }

  ngOnDestroy() {
    this.chatService.chatDestroyed$.next();
  }

  closeEditingGroupOrTheme() {
    this.chatService.resetEditingGroupOrThemeObject();
    this.chatService.isEditingGroupOrThemeChanged.next(false);
    this.chatService.toggleContactsOnlyFilter(false, this.contactsFilterName);
  }

  onChatClose() {
    this.chatService.filterContactsGroupsThemes('');
    this.chatService.closeChat();
  }

  toggleContactsHidden() {
    this.isContactsHidden = !this.isContactsHidden;
  }
}
