<div class="contacts">
  <div class="d-flex align-items-center contacts-left">
    <button [class.disabled]="!contacts.length" class="icon-button chat-expansion__caret" (click)="onContactsExpand()">
      <app-svg-icon [name]="isContactsExpanded ? 'caret_up' : 'caret_down'" width="12" height="12"></app-svg-icon>
    </button>
    <div class="contact-title">
      <span>Контакты</span>
    </div>
    <div
      class="contacts__filters"
      *ngIf="
        (chatSectionSelected.name === chatSectionsEnum.ADMIN ||
          chatSectionSelected.name === chatSectionsEnum.HOLDING ||
          chatSectionSelected.name === chatSectionsEnum.TRADE ||
          (chatSectionSelected.name === chatSectionsEnum.TECH && techType !== techTypeEnum.MY_TECH)) &&
        !isModalView
      "
      [class.invisible]="
        chatSectionSelected.name === chatSectionsEnum.TRADE ||
        (chatSectionSelected.name === chatSectionsEnum.ADMIN && userType !== rolesEnum.SUPERUSER)
      "
    >
      <button
        *ngIf="chatSectionSelected.name !== chatSectionsEnum.TECH"
        class="icon-button d-flex align-items-center"
        (click)="toggleSortModal()"
      >
        <div class="sort__button mr-1">
          <app-svg-icon name="sort-amount-down" width="12" height="12"></app-svg-icon>
        </div>
      </button>

      <app-chat-groups-sorting
        [isOpen]="isSortModalOpen"
        title="Сортировка контактов"
        [sortOptions]="sortOptions"
        [sortType]="sortType"
        (close)="sortPopupClose()"
        (save)="changeSortType($event)"
      ></app-chat-groups-sorting>
    </div>
    <button
      *ngIf="techType !== techTypeEnum.MY_TECH && !isModalView && shouldShowFilterButton"
      (click)="onFilterToggle()"
      [class.active]="contactsOnlyFilter"
      class="icon-button"
      id="filter"
    >
      <app-svg-icon name="filter-table" width="12" height="12"></app-svg-icon>
    </button>

    <button *ngIf="contactsOnlyFilter && shouldShowFilterButton" (click)="onClearFilter()" class="icon-button">
      <app-svg-icon name="times" width="12" height="12"></app-svg-icon>
    </button>
  </div>

  <div class="d-flex align-items-center contacts-icon" *ngIf="!isModalView">
    <button class="icon-button" [style.visibility]="techType ? 'hidden' : 'visible'">
      <app-svg-icon name="question-circle-color" width="16" height="16"></app-svg-icon>
    </button>
    <span
      class="chat-number"
      [style.visibility]="
        totalUnreadCounter &&
        !(chatSectionSelected.name === chatSectionsEnum.ADMIN && authService.user_type !== rolesEnum.SUPERUSER)
          ? 'visible'
          : 'hidden'
      "
      [class.chat-number__10]="totalUnreadCounter === '10+'"
    >
      {{ totalUnreadCounter }}
    </span>
  </div>
</div>

<ng-container *ngIf="isContactsExpanded">
  <ng-container *ngIf="chatAdminView && chatSectionSelected.name !== chatSectionsEnum.TECH">
    <ng-container
      class="contacts-list"
      *ngFor="let contact of contacts | contactsFilter : contactsOnlyFilter; trackBy: trackByRoomId"
    >
      <app-chat-contact
        *ngIf="contact.status !== 'in_archive' && contact.id"
        [sortType]="sortType"
        [contact]="contact"
        [isModalView]="isModalView"
      ></app-chat-contact>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!chatAdminView && treeContacts.length && chatSectionSelected.name !== chatSectionsEnum.TECH">
    <ng-container
      class="contacts-list"
      *ngFor="let contact of treeContacts | contactsFilter : contactsOnlyFilter; trackBy: trackByRoomId"
    >
      <app-chat-contact
        *ngIf="contact.status !== 'in_archive' && contact.id"
        [sortType]="sortType"
        [isTree]="true"
        [contact]="contact"
        [isModalView]="isModalView"
      ></app-chat-contact>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="chatSectionSelected.name === chatSectionsEnum.TECH">
    <ng-container
      class="contacts-list"
      *ngFor="let contact of contacts | contactsFilter : contactsOnlyFilter; trackBy: trackByRoomId"
    >
      <app-chat-contact
        *ngIf="contact.status !== 'in_archive' && contact.id"
        [sortType]="sortType"
        [contact]="contact"
        [chatSectionName]="chatSectionSelected.name"
        [techType]="techType"
        [isModalView]="isModalView"
      ></app-chat-contact>
    </ng-container>
  </ng-container>
</ng-container>
