import { CriteriaType, CriteriaVector } from '../types/trades.types';
import { CRITERIA_TYPES, DEFAULT_NOMENCLATURE_UNIT } from '../constants/lots.constants';
import { Unit } from '@app/shared/models/user-settings.model';

// интерфейс предназначен для хранения дополнительных данных, которые не нужны беку, но нужны фронту для упрощения построения интерфейса

export interface LotExtra {
  id?: number;
  criteria: CriteriaShort[];
  number: number;
  title: string;
  weight: number;
  generalNomenclaturesWeight?: number;

  nomenclatures: ExtraNomenclature[];
  expanded: boolean;
  nomenclaturesSelected?: boolean;
  criteriaExpanded: boolean;
  unit_id?: number;
  date_update?: string;
}

export interface Lot {
  id?: number;
  number: number;
  title: string;
  weight: number;
  date_update?: string;
  nomenclatures: Nomenclature[];
  extra?: LotExtra;

  // для работы с reactive forms
  value?: LotExtra;
}

export interface CriteriaShort {
  id?: number;
  title: string;
  best_direction: CriteriaVector;
  type: CriteriaType;
  weight?: number;
  unit_id?: number;
  expanded?: boolean;

  // для работы с reactive forms
  value?: CriteriaShort;
}

export interface Criteria {
  id?: number;
  min?: any;
  max?: any;
  weight: number;
  best_direction: CriteriaVector;
  comment?: string;
  type: CriteriaType;
  title: string;
  unit_id?: number;
  unit?: Unit;
  sort: number;
}

export interface ExtraCriteria {
  id?: number;
  min?: any;
  max?: any;
  weight: number;
  best_direction: CriteriaVector;
  comment?: string;
  value?: string | boolean;
  unit_id?: number;
  sort?: number;
}

export interface Nomenclature {
  id?: number;
  title: string;
  unit_id?: number;
  weight: number;
  criteria?: Criteria[];
  unit?: Unit;
}

export interface ExtraNomenclature {
  id?: number;
  title: string;
  unit_id?: number;
  weight: number;

  criteria: ExtraCriteria[];
  generalCriteriaWeight?: number;
  selected?: boolean;
  expanded?: boolean;

  // для работы с reactive forms
  value?: ExtraNomenclature;
}

export class Lot implements Lot {
  constructor() {
    // this.id = id;
    this.number = null;
    this.nomenclatures = [];
    this.title = null;
    this.weight = null;
    this.extra = new LotExtra();
  }
}

export class LotExtra implements LotExtra {
  constructor() {
    // this.id = id;
    this.criteria = [];
    this.nomenclatures = [];
    this.expanded = false;
    this.weight = null;
    this.title = null;
    this.number = null;
    this.criteriaExpanded = false;
    this.unit_id = DEFAULT_NOMENCLATURE_UNIT;
  }
}

export class Nomenclature implements Nomenclature {
  constructor(criteria: Criteria[] = []) {
    this.title = '';
    this.criteria = criteria;
    this.weight = null;
  }
}

export class ExtraNomenclature implements ExtraNomenclature {
  constructor() {
    this.title = '';
    this.weight = null;
    this.selected = false;
    this.expanded = true;
    this.criteria = [];
    this.unit_id = null;
  }
}

export class Criteria implements Criteria {
  constructor() {
    this.weight = null;
    this.unit_id = null;
  }
}

export class ExtraCriteria implements ExtraCriteria {
  constructor() {
    this.weight = null;
    this.best_direction = null;
  }
}

export class CriteriaShort implements CriteriaShort {
  constructor() {
    this.title = '';
    this.weight = null;
    this.type = CRITERIA_TYPES[0].id;
    this.best_direction = '';
    this.unit_id = null;
  }
}

export interface TradeProviderLotsMap {
  lots: Map<string, Omit<TradeProviderLot, 'nomenclatures_provider'>>;
  nomenclatures: Map<string, Omit<TradeProviderNomenclature, 'criteria_provider'>>;
  criteria: Map<string, TradeProviderCriterion>;
}

export interface TradeProviderLot {
  date_create?: string;
  date_update?: string;
  deleted?: boolean;
  id?: number;
  trade_lot_id: number;
  nomenclatures_provider: TradeProviderNomenclature[];
}

export interface TradeProviderNomenclature {
  date_create?: string;
  date_update?: string;
  id?: number;
  trade_nomenclature_id: number;
  criteria_provider: TradeProviderCriterion[];
}

export interface TradeProviderCriterion {
  date_create?: string;
  date_update?: string;
  id?: number;
  trade_criterion_id: number;
  value: string | boolean;
}

export interface HandlePastEvent {
  event: ClipboardEvent;
  name: string;
  index: number;
  criteriaIndex?: number;
}
