<div class="dc-modal">
  <div
    class="modal-content rich-modal"
    id="rich-modal"
    [style.top.px]="y"
    [style.left.px]="x"
    [style.width.px]="width"
    [style.height.px]="height"
    [class.active]="isActive"
    appClickElsewhere
    (mousedown)="onMouseDown()"
    (appClickElsewhere)="onClickOutside()"
  >
    <div (click)="onClose()" *ngIf="closable" class="move-pin__close-button"></div>

    <div *ngIf="movable || pinable" class="rich-modal__move">
      <button
        *ngIf="movable"
        class="icon-button movable"
        (mousedown)="onWindowPress($event)"
        (mousemove)="onWindowDrag($event)"
      >
        <app-svg-icon [width]="16" [height]="16" name="move"></app-svg-icon>
      </button>
      <button *ngIf="pinable" class="icon-button" [class.active]="isPinableActive" (click)="togglePinable()">
        <app-svg-icon [width]="16" [height]="16" name="pin"></app-svg-icon>
      </button>
    </div>

    <ng-content></ng-content>
    <div
      (mousedown)="onCornerClick($event, topLeftResize)"
      id="rich-modal-top-left-resize"
      [class.resizable]="resizable"
      class="rich-modal-corner-resize rich-modal-corner-resize__nw"
    ></div>
    <div
      (mousedown)="onCornerClick($event, topRightResize)"
      id="rich-modal-top-right-resize"
      [class.resizable]="resizable"
      class="rich-modal-corner-resize rich-modal-corner-resize__ne"
    ></div>
    <div
      (mousedown)="onCornerClick($event, bottomLeftResize)"
      id="rich-modal-bottom-left-resize"
      [class.resizable]="resizable"
      class="rich-modal-corner-resize rich-modal-corner-resize__sw"
    ></div>
    <div
      (mousedown)="onCornerClick($event, bottomRightResize)"
      id="rich-modal-bottom-right-resize"
      [class.resizable]="resizable"
      class="rich-modal-corner-resize rich-modal-corner-resize__se"
    >
      <app-svg-icon [width]="8" [height]="8" name="resize" [hidden]="!resizable"></app-svg-icon>
    </div>
  </div>
</div>
