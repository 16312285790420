import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-trade-card-controls',
  templateUrl: './trade-card-controls.component.html',
  styleUrls: ['./trade-card-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TradeCardControlsComponent {
  @Input() canSave: boolean = false;

  @Output() onSaveClick = new EventEmitter<void>();
  @Output() onCancelClick = new EventEmitter<void>();

  cancel() {
    this.onCancelClick.emit();
  }

  save() {
    this.onSaveClick.emit();
  }
}
