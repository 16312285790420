import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { SupplierTradeCard } from '@app/+trades/models/suppliers.model';
import { CriteriaTypesEnum, CriteriaVectorEnum } from '@app/+trades/constants/lots.constants';
import { CriteriaShort, ExtraCriteria } from '@app/+trades/models/lots.model';
import { NgForm, NgModel } from '@angular/forms';

@Component({
  selector: 'app-criteria-view',
  templateUrl: './criteria-view.component.html',
  styleUrls: ['./criteria-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CriteriaViewComponent implements OnInit, AfterViewInit {
  @Input() criteria: CriteriaShort;
  @Input() lotIndex: number;
  @Input() cIndex: number;
  @Input() lot;

  @Input() card: SupplierTradeCard;

  @Input() readonly;
  @Input() isTrades: boolean;
  @Input() form: NgForm;

  @ViewChild('criteriaContainer') criteriaContainer;
  @ViewChild('rateCell') rateCell;
  @ViewChild('valueCell') valueCell;

  @ViewChild('value') value: NgModel;

  @Output() valueChanged = new EventEmitter();

  criteriaTypes = CriteriaTypesEnum;
  criteriaVectors = CriteriaVectorEnum;

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.isTrades) {
      if (!this.criteria.expanded) {
        this.collapse();
      } else {
        this.expand();
      }
    }
  }

  valueChange(value: string, criteria: ExtraCriteria) {
    if (this.readonly) {
      return;
    }

    criteria.value = value;

    this.valueChanged.emit();
  }

  setValue(value: string, criteria: ExtraCriteria) {
    if (this.readonly) {
      return;
    }
    criteria.value = value;
    this.valueChanged.emit();
  }

  collapse() {
    const widthRateCell = this.rateCell.nativeElement.clientWidth;
    const widthValueCell = this.valueCell.nativeElement.clientWidth;
    this.renderer.setStyle(this.criteriaContainer.nativeElement, 'width', `${widthRateCell + widthValueCell + 30}px`);
  }

  expand() {
    this.renderer.setStyle(this.criteriaContainer.nativeElement, 'width', `auto`);
  }

  toggleCriteriaTrade(criteria: CriteriaShort) {
    criteria.expanded = !criteria.expanded;
    if (!criteria.expanded) {
      this.collapse();
    } else {
      this.expand();
    }
  }
}
