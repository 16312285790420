import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DestroyService } from '@app/services/destroy.service';
import { ScheduleComponent } from '@app/shared/components/schedule/schedule.component';
import { BACKEND_DATE_FORMAT, BACKEND_DATE_TIME_FORMAT } from '@app/shared/constants/date.constants';
import { DutyStatus } from '@app/shared/constants/duty.constants';
import { PersonForApproval } from '@app/shared/enums/employee-duty';
import { DatesHelper } from '@app/shared/helpers/dates.helper';
import { DutyHelper } from '@app/shared/helpers/duty.helper';
import {
  DutyData,
  DutyListItem,
  DutyOffers,
  DutyUser,
  TsoSchedule,
  TsoScheduleTimeItem,
} from '@app/shared/models/duty.model';
import { AuthService } from '@app/shared/services/auth.service';
import { CalendarDataService } from '@app/shared/services/calendar/calendar-data.service';
import { EmployeesService } from '@app/shared/services/employers.service';
import { CalendarMonthViewDay } from 'angular-calendar';
import { NotificationsService } from 'angular2-notifications';
import moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-chat-duty-tso-options',
  templateUrl: './chat-duty-tso-options.component.html',
  styleUrls: ['./chat-duty-tso-options.component.scss'],
})
export class ChatDutyTsoOptionsComponent implements OnInit {
  @Output() closeModal = new EventEmitter();

  viewDate: Date = moment().toDate();
  selectedMonthViewDay: CalendarMonthViewDay;

  selectedClass = 'cal-day-selected';

  proccessingAll: boolean = false;
  activePartiallySelected = false;
  dutyStatus = DutyStatus;

  isLoading: boolean;
  isSendLoading: boolean;
  showMyDuty = false;

  schedules: {
    [key: string]: TsoSchedule;
  } = {};

  startDate: string;
  endDate: string;
  userId: number;
  currentDateFormat: string;
  isTimelineItemLate = DutyHelper.isTimelineItemLateInTimezone;
  isTimelineItemMore90 = DutyHelper.isTimelineItemMore90;

  dutyOffersFromAtp: DutyOffers[] = [];
  dateOffSet = 0;

  @ViewChild(ScheduleComponent) scheduleRef: ScheduleComponent;

  constructor(
    private employeesService: EmployeesService,
    private authService: AuthService,
    private notify: NotificationsService,
    private destroy: DestroyService,
    private calendarDataService: CalendarDataService
  ) {
    this.userId = +this.authService.user_id;
    this.dateOffSet = this.calendarDataService.timeZoneOffset;
  }

  get schedule(): TsoSchedule {
    return (
      this.schedules[this.currentDateFormat] ||
      ({
        timeline: [],
      } as TsoSchedule)
    );
  }

  ngOnInit() {
    this.getAllDutyList();
  }

  private getAllDutyList() {
    const from_time = moment()
      .add(this.dateOffSet - moment().utcOffset(), 'minutes')
      .format(BACKEND_DATE_TIME_FORMAT);
    const to_time = moment()
      .add(3, 'months')
      .add(this.dateOffSet - moment().utcOffset(), 'minutes')
      .format(BACKEND_DATE_TIME_FORMAT);
    this.isLoading = true;
    this.dutyOffersFromAtp = [];

    this.getDutyList(from_time, to_time)
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntil(this.destroy)
      )
      .subscribe((dutyData) => {
        //this.isLoading = false;
        dutyData.duty_schedule_list.forEach((dutyItem) => {
          if (
            this.userId === dutyItem.user_id &&
            dutyItem.who_confirms_duty === PersonForApproval.AGENT &&
            dutyItem.status === DutyStatus.PROCESS &&
            moment(dutyItem.start_date).add(this.dateOffSet, 'minutes').valueOf() >
              moment()
                .add(this.dateOffSet - moment().utcOffset(), 'minutes')
                .valueOf()
          ) {
            dutyItem.start_date = moment(dutyItem.start_date)
              .add(this.dateOffSet, 'minutes')
              .format(BACKEND_DATE_TIME_FORMAT);
            //new Date(DatesHelper.getDateWithOffset(dutyItem.start_date)).toString();
            dutyItem.end_date = moment(dutyItem.end_date)
              .add(this.dateOffSet, 'minutes')
              .format(BACKEND_DATE_TIME_FORMAT);
            //new Date(DatesHelper.getDateWithOffset(dutyItem.end_date)).toString();
            this.dutyOffersFromAtp.push({
              ...dutyItem,
              acceptCheck: null,
              removeCheck: null,
            });
          }
        });
        this.dutyOffersFromAtp.sort((a, b) => +new Date(a.start_date) - +new Date(b.start_date));
      });
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }) {
    body.forEach((day) => {
      if (!this.selectedMonthViewDay) {
        if (day.isToday) {
          this.updateCurrentSchedule(day);
        } else {
          this.updateCellsHighlight(day);
        }
      } else {
        if (this.isSelectedDay(day)) {
          this.updateCurrentSchedule(day);
        } else {
          this.updateCellsHighlight(day);
        }
      }
    });
  }

  updateCellsHighlight(day: CalendarMonthViewDay) {
    const date = day.date;
    const schedule = DutyHelper.generateSchedule(date);
    const startDate = DutyHelper.formatStartDateForBackend(date);
    const endDate = DutyHelper.formatEndDateForBackend(date);

    const currentDateFormat = moment(date).format(BACKEND_DATE_FORMAT);

    if (!this.schedules[currentDateFormat]) {
      this.schedules[currentDateFormat] = schedule;

      this.getDutyList(startDate, endDate)
        .pipe(takeUntil(this.destroy))
        .subscribe((dutyData) => {
          this.fillDutyToSchedule(this.schedules[currentDateFormat], dutyData.duty_schedule_list);

          const list = this.getFilteredDuties(this.schedules[currentDateFormat]);
          day.cssClass = this.getDayClass(list, day);
        });
    } else {
      const list = this.getFilteredDuties(this.schedules[currentDateFormat]);

      day.cssClass = this.getDayClass(list, day);
    }
  }

  getFilteredDuties(schedule: TsoSchedule) {
    return schedule.timeline
      .map((item) => item.duty)
      .reduce((a, b) => [...a, ...b])
      .filter((item) => item.status !== DutyStatus.INACTIVE);
  }

  private isSelectedDay(day: CalendarMonthViewDay) {
    return this.selectedMonthViewDay && this.selectedMonthViewDay.date.getTime() === day.date.getTime();
  }

  updateCurrentSchedule(day: CalendarMonthViewDay) {
    const date = day.date;
    this.currentDateFormat = moment(date).format(BACKEND_DATE_FORMAT);

    if (!this.schedules[this.currentDateFormat]) {
      this.schedules[this.currentDateFormat] = DutyHelper.generateSchedule(date);
    }

    this.startDate = DutyHelper.formatStartDateForBackend(date);
    this.endDate = DutyHelper.formatEndDateForBackend(date);

    this.loadDuty(this.schedule, this.startDate, this.endDate, day);
  }

  private getDayClass(dutyList: DutyListItem[], day: CalendarMonthViewDay): string {
    let className = '';

    if (dutyList.some((item) => item.status === DutyStatus.ACTIVE && item.user_id === this.userId)) {
      className = 'cal-day-active';
    }

    if (
      dutyList.every((item) => item.user_id !== this.userId) &&
      dutyList.some((item) => item.status === DutyStatus.ACTIVE)
    ) {
      className = 'cal-day-secondary';
    }

    if (dutyList.some((item) => item.status === DutyStatus.PROCESS && item.user_id === this.userId)) {
      className = 'cal-day-progress';
    }

    if (this.isSelectedDay(day) || (day.isToday && !this.selectedMonthViewDay)) {
      className += ` ${this.selectedClass}`;
    }
    return className;
  }

  dayClicked(day: CalendarMonthViewDay): void {
    this.viewDate = day.date;
    this.selectedMonthViewDay = day;
    this.scheduleRef.updateCalendar();
    this.getAllDutyList();
    this.clearTimelinesCheck();
    this.resetRadioButtons();
  }

  close() {
    //this.closeModal.emit();
    this.clearTimelinesCheck();
    this.resetRadioButtons();
    this.updateAllTimeline();
  }

  sendProcess() {
    const checkedForProcess: TsoScheduleTimeItem[] = [];
    const forDelete: DutyListItem[] = [];
    const takeDutyAtp: DutyOffers[] = this.dutyOffersFromAtp.filter((duty) => duty.acceptCheck);
    const refuseDutyAtp: DutyOffers[] = this.dutyOffersFromAtp.filter((duty) => duty.removeCheck);

    this.schedule.timeline.forEach((item) => {
      const myDuty = item.duty.find((dutyItem) => dutyItem.user_id === this.userId);
      if (!myDuty && item.processTimeChecked) {
        checkedForProcess.push(item);
      }

      if (
        myDuty &&
        myDuty.status === DutyStatus.PROCESS &&
        !item.processTimeChecked &&
        myDuty?.who_confirms_duty !== PersonForApproval.AGENT
      ) {
        forDelete.push(myDuty);
      }
    });

    this.isSendLoading = !!checkedForProcess.length || !!forDelete.length;

    const queries = [];

    checkedForProcess.forEach((item) => {
      queries.push(
        this.employeesService.setDuty({
          start_date: moment(item.startDate).subtract(this.dateOffSet, 'minutes').format(BACKEND_DATE_TIME_FORMAT),
          end_date: moment(item.endDate).subtract(this.dateOffSet, 'minutes').format(BACKEND_DATE_TIME_FORMAT),
          user_id: this.userId,
        })
      );
    });

    forDelete.forEach((item) => {
      queries.push(this.employeesService.deleteDuty(item.id));
    });

    takeDutyAtp.forEach((item) => {
      queries.push(this.employeesService.acceptDuty(item.id));
    });

    refuseDutyAtp.forEach((item) => {
      queries.push(this.employeesService.deleteDuty(item.id));
    });

    forkJoin(queries)
      .pipe(
        takeUntil(this.destroy),
        finalize(() => {
          this.scheduleRef.updateCalendar();
          this.getAllDutyList();
          //this.isLoading = false;
          //this.loadDuty(this.schedule, this.startDate, this.endDate);
        })
      )
      .subscribe(
        () => {
          Object.keys(this.schedules).forEach((date) => {
            const startDate = DutyHelper.formatStartDateForBackend(new Date(date));
            const endDate = DutyHelper.formatEndDateForBackend(new Date(date));
            this.schedules[date].isLoading = true;
            this.loadDuty(this.schedules[date], startDate, endDate);
          });
        },
        (err) => {
          this.notify.error('Ошибка! Ошибка сохранения данных. Перезагрузите страницу.');
          //this.authService.getErrorMessage(err, 'Ошибка отправки согласования!')

          this.isLoading = false;
        }
      );
    // this.scheduleRef.updateCalendar();
    // this.getAllDutyList();
  }

  loadDuty(
    schedule: TsoSchedule,
    startDate: string,
    endDate: string,
    day: CalendarMonthViewDay = this.selectedMonthViewDay
  ) {
    this.isLoading = true;

    this.getDutyList(startDate, endDate)
      .pipe(
        takeUntil(this.destroy),
        finalize(() => {
          // this.scheduleRef.updateCalendar();
          // this.getAllDutyList();
          schedule.isLoading = false;
          this.isLoading = false;
        })
      )
      .subscribe((dutyData) => {
        this.fillDutyToSchedule(schedule, dutyData.duty_schedule_list);
        const list = this.getFilteredDuties(schedule);
        if (day) {
          day.cssClass = this.getDayClass(list, day);
        }
      });
  }

  fillDutyToSchedule(schedule: TsoSchedule, dutyList: DutyListItem[]) {
    schedule.timeline.forEach((timeItem) => {
      timeItem.duty = [];
      timeItem.dutyInactive = [];
      timeItem.countProcessUsers = 0;
      timeItem.countActiveUsers = 0;
      dutyList.forEach((dutyItem) => {
        if (
          moment(timeItem.startDate).valueOf() ===
            moment(dutyItem.start_date).add(this.dateOffSet, 'minutes').valueOf() &&
          moment(timeItem.endDate).valueOf() === moment(dutyItem.end_date).add(this.dateOffSet, 'minutes').valueOf()
        ) {
          if (dutyItem.status !== DutyStatus.INACTIVE && this.userId === dutyItem.user_id) {
            timeItem.duty.push(dutyItem);
          }
          if (dutyItem.status === DutyStatus.INACTIVE && this.userId === dutyItem.user_id) {
            timeItem.dutyInactive.push(dutyItem);
          }

          if (dutyItem.status === DutyStatus.ACTIVE) {
            timeItem.countActiveUsers += 1;
          }

          if (dutyItem.status === DutyStatus.PROCESS) {
            timeItem.countProcessUsers += 1;
          }
        }
      });

      timeItem.processTimeChecked = timeItem.duty.some(
        (dutyItem) =>
          dutyItem.status === DutyStatus.PROCESS && dutyItem.who_confirms_duty === PersonForApproval.SUPERUSER
      );

      timeItem.timeChecked = timeItem.duty.some((dutyItem) => dutyItem.status === DutyStatus.ACTIVE);
    });

    this.updateAllTimeline();
  }

  getDutyList(startDate: string, endDate: string): Observable<DutyData> {
    const daily_schedule = moment(endDate).valueOf() - moment(startDate).valueOf() > 80000000; // period of time per day
    return this.employeesService.getDuty(
      Object.assign({
        from_time: moment(startDate).subtract(this.dateOffSet, 'minutes').format(BACKEND_DATE_TIME_FORMAT),
        to_time: moment(endDate).subtract(this.dateOffSet, 'minutes').format(BACKEND_DATE_TIME_FORMAT),
        //user_id: this.userId,
        daily_schedule,
      })
    );
  }

  private clearTimelinesCheck() {
    this.schedule.timeline.forEach((item) => {
      if (
        item.duty.some(
          (dutyItem) =>
            dutyItem.user_id === this.userId &&
            dutyItem.status === DutyStatus.PROCESS &&
            dutyItem.who_confirms_duty === PersonForApproval.SUPERUSER
        )
      ) {
        item.processTimeChecked = true;
      }

      if (!item.duty.some((dutyItem) => dutyItem.user_id === this.userId) && item.processTimeChecked) {
        item.processTimeChecked = false;
      }
    });
  }

  private resetRadioButtons() {
    this.dutyOffersFromAtp.forEach((dutyOffers) => {
      dutyOffers.acceptCheck = null;
      dutyOffers.removeCheck = null;
    });
  }

  toggleAllProccessing() {
    const allChecked = this.schedule.timeline
      .filter(
        (item) =>
          !DutyHelper.isTimelineItemLateInTimezone(item, this.dateOffSet) &&
          !DutyHelper.isTimelineItemMore90(item) &&
          !item.timeChecked
      )
      .every((item) => item.processTimeChecked || this.isDisabled(item));

    this.schedule.timeline
      .filter(
        (item) =>
          !DutyHelper.isTimelineItemLateInTimezone(item, this.dateOffSet) &&
          !DutyHelper.isTimelineItemMore90(item) &&
          !item.timeChecked
      )
      .forEach((item) => {
        item.processTimeChecked = !allChecked;
      });

    //this.activePartiallySelected = false;
    this.activePartiallySelected =
      this.schedule.timeline.some((item) => item.processTimeChecked || this.isCheckboxAgent(item)) &&
      !this.proccessingAll;
  }

  updateAllTimeline() {
    const filteredItems = this.schedule.timeline.filter(
      (item) =>
        !DutyHelper.isTimelineItemLateInTimezone(item, this.dateOffSet) &&
        !DutyHelper.isTimelineItemMore90(item) &&
        !item.timeChecked
    );

    this.proccessingAll =
      filteredItems.length > 0 && filteredItems.every((item) => item.processTimeChecked || this.isCheckboxAgent(item));

    this.activePartiallySelected =
      this.schedule.timeline.some((item) => item.processTimeChecked || this.isCheckboxAgent(item)) &&
      !this.proccessingAll;
  }

  isAllTimelineItemsLate(): boolean {
    return this.schedule.timeline.every(
      (item) =>
        DutyHelper.isTimelineItemLateInTimezone(item, this.dateOffSet) || item.timeChecked || this.isDisabled(item)
    );
  }

  isAllTimelineItemsMore90(): boolean {
    return this.schedule.timeline.every((item) => DutyHelper.isTimelineItemMore90(item));
  }

  isCheckboxAgent(item: TsoScheduleTimeItem) {
    const duty = item.duty.find((dutyItem) => dutyItem.user_id === this.userId);
    const dutyInactive = item.dutyInactive.find((dutyItem) => dutyItem.user_id === this.userId);
    return (
      duty &&
      //!dutyInactive &&
      duty.status === DutyStatus.PROCESS &&
      duty.who_confirms_duty === PersonForApproval.AGENT //&&
      //!DutyHelper.isTimelineItemLate(item)
    );
  }

  isDisabled(item: TsoScheduleTimeItem) {
    const duty = item.duty.find((dutyItem) => dutyItem.user_id === this.userId);
    // Доработать блокировку при отказе от АТП
    const dutyInactive = item.dutyInactive.find(
      (dutyItem) => dutyItem.user_id === this.userId && dutyItem.who_confirms_duty === PersonForApproval.SUPERUSER
    );
    return (
      (duty && duty.status === DutyStatus.PROCESS && duty.who_confirms_duty === PersonForApproval.AGENT) ||
      (dutyInactive && dutyInactive.who_confirms_duty === PersonForApproval.SUPERUSER)
    );
  }

  getDutyText(item: TsoScheduleTimeItem) {
    const duty = item.duty.find((dutyItem) => dutyItem.user_id === this.userId);
    const dutyInactive = item.dutyInactive.find((dutyItem) => dutyItem.user_id === this.userId);
    switch (true) {
      case duty &&
        //!dutyInactive &&
        duty.status === DutyStatus.PROCESS &&
        duty.who_confirms_duty === PersonForApproval.SUPERUSER &&
        !DutyHelper.isTimelineItemLateInTimezone(item, this.dateOffSet):
        return 'На согласовании АТП';
      case duty &&
        //!dutyInactive &&
        duty.status === DutyStatus.PROCESS &&
        duty.who_confirms_duty === PersonForApproval.SUPERUSER &&
        DutyHelper.isTimelineItemLateInTimezone(item, this.dateOffSet):
        return 'Не согласовано АТП';
      case duty &&
        //!dutyInactive &&
        duty.status === DutyStatus.PROCESS &&
        duty.who_confirms_duty === PersonForApproval.AGENT &&
        !DutyHelper.isTimelineItemLateInTimezone(item, this.dateOffSet):
        return 'На согласовании агента';
      case duty &&
        //!dutyInactive &&
        duty.status === DutyStatus.PROCESS &&
        duty.who_confirms_duty === PersonForApproval.AGENT &&
        DutyHelper.isTimelineItemLateInTimezone(item, this.dateOffSet):
        return 'Не согласовано агентом';
      case !duty && dutyInactive && dutyInactive.who_confirms_duty === PersonForApproval.SUPERUSER:
        return 'Отказано АТП';
      default:
        return '';
    }
  }

  hasTimelineProcess(item: TsoScheduleTimeItem) {
    return item.countProcessUsers > 0 && item.countActiveUsers === 0;
  }

  hasTimelineAgreed(item: TsoScheduleTimeItem) {
    return item.countActiveUsers > 0;
  }

  hasProcessChecked() {
    return (
      this.schedule.timeline.some((item) => this.hasProcessChange(item)) ||
      this.dutyOffersFromAtp.some((item) => item.acceptCheck || item.removeCheck)
    );
  }

  private hasProcessChange(item: TsoScheduleTimeItem): boolean {
    const myDuty = item.duty.find((dutyItem) => dutyItem.user_id === this.userId);

    return (
      (!myDuty && item.processTimeChecked) ||
      (myDuty &&
        myDuty.status === DutyStatus.PROCESS &&
        !item.processTimeChecked &&
        myDuty.who_confirms_duty === PersonForApproval.SUPERUSER)
    );
  }

  hasSomeLoading() {
    return Object.keys(this.schedules).some((key) => this.schedules[key].isLoading);
  }
}
