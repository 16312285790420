import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalInjectorService } from '@app/modal-injector/services/modal-injector.service';
import * as moment from 'moment';
import { CalendarDataService } from '@app/shared/services/calendar/calendar-data.service';
import { ModalInjectorConstants } from '@app/modal-injector/constants/modal-injector.constants';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BACKEND_DATE_FORMAT, DATE_FORMAT } from '@app/shared/constants/date.constants';
import { FORM_VALIDATOR_MESSAGES } from '@app/shared/constants/form.constants';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit {
  @Input() controlNameDate;
  @Input() controlNameTime;
  @Input() timeRequire = true;
  @Input() dateRequire = true;
  @Input() form;
  @Input() validate = true;
  @Input() required: boolean;
  @Input() showError = true;
  @Input() fieldParams?: any;

  @Input() minDate: moment.Moment | null = null;
  @Input() maxDate: moment.Moment | null = null;

  controlInputNameDate = '';
  controlInputNameTime = '';
  formParent;
  BACKEND_DATE_FORMAT = BACKEND_DATE_FORMAT;

  // tslint:disable-next-line:no-bitwise
  id = (~~(Math.random() * 1e8)).toString(16);

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @ViewChild('buttonDate') private buttonDate: ElementRef;
  @ViewChild('inputTime') private inputTime: ElementRef;

  constructor(
    private cdRef: ChangeDetectorRef,
    private modalInjectorService: ModalInjectorService,
    private calendarDataService: CalendarDataService
  ) {}

  getValueFromForm(key: string) {
    const arrayKey = key?.split('.');
    return arrayKey?.reduce((sum, cur) => sum.get(cur), this.form);
  }

  changeDate(event) {
    this.getValueFromForm(this.controlNameDate).status = 'INVALID';
    this.getValueFromForm(this.controlNameDate).patchValue(event.substr(0, 10));
    this.getValueFromForm(this.controlNameDate).updateValueAndValidity();
  }

  changeTime(event) {
    this.getValueFromForm(this.controlNameTime).patchValue(event.substr(0, 5));
    this.getValueFromForm(this.controlNameDate)?.updateValueAndValidity();
  }

  onCalendarWidgetOpen() {
    const parent = this.buttonDate.nativeElement.getBoundingClientRect();
    const startValue = this.getValueFromForm(this.controlNameDate)?.value || '';

    this.modalInjectorService.addModal(ModalInjectorConstants.DATEPICKER_WIDGET, {
      startValue,
      dpID: this.id,
      minDate: this.minDate,
      maxDate: this.maxDate,
      parent,
      classForChildren: 'for_datepicker',
    });
  }

  onTimeWidgetOpen() {
    const parent = this.inputTime.nativeElement.getBoundingClientRect();
    const startValue = this.getValueFromForm(this.controlNameTime)?.value || '';

    this.modalInjectorService.addModal(ModalInjectorConstants.TIMEPICKER_WIDGET, {
      startValue,
      dpID: this.id,
      parent,
      classForChildren: 'for_timepicker',
      margin: 0,
    });
  }

  validDateRequire(formValue) {
    const value = formValue.value;
    if (!value) {
      return { error: FORM_VALIDATOR_MESSAGES.required };
    }
    if (!moment(value, DATE_FORMAT).isValid() || value.length !== 10) {
      return { error: 'Дата должна быть в формате ДД.ММ.ГГГГ' };
    }
    return null;
  }

  validDate(formValue) {
    const value = formValue.value;
    if (!value) {
      return null;
    }
    if (!moment(value, DATE_FORMAT).isValid() || value.length !== 10) {
      return { error: 'Дата должна быть в формате ДД.ММ.ГГГГ' };
    }
    return null;
  }

  validTimeRequire(formValue) {
    const value = formValue.value;
    if (!value) {
      return { error: FORM_VALIDATOR_MESSAGES.required };
    }
    if (value.length === 5) {
      const parseStartTime = value.split(':');
      if (
        Number(parseStartTime[0]) > 23 ||
        Number(parseStartTime[0]) < 0 ||
        Number(parseStartTime[1]) > 59 ||
        Number(parseStartTime[1]) < 0
      ) {
        return { error: 'Время должно быть в формате чч:мм' };
      }
    } else {
      return { error: 'Время должно быть в формате чч:мм' };
    }
    return null;
  }

  validTime(formValue) {
    const value = formValue.value;
    if (!value) {
      return null;
    }
    if (value.length === 5) {
      const parseStartTime = value.split(':');
      if (
        Number(parseStartTime[0]) > 23 ||
        Number(parseStartTime[0]) < 0 ||
        Number(parseStartTime[1]) > 59 ||
        Number(parseStartTime[1]) < 0
      ) {
        return { error: 'Время должно быть в формате чч:мм' };
      }
    } else {
      return { error: 'Время должно быть в формате чч:мм' };
    }
    return null;
  }

  showErrorFlag() {
    return (
      (this.controlNameDate &&
        this.getValueFromForm(this.controlNameDate).touched &&
        this.getValueFromForm(this.controlNameDate).status !== 'VALID') ||
      (this.controlNameTime &&
        this.getValueFromForm(this.controlNameTime).touched &&
        this.getValueFromForm(this.controlNameTime).status !== 'VALID')
    );
  }

  textError() {
    const error = [];
    if (
      this.controlNameDate &&
      this.getValueFromForm(this.controlNameDate).touched &&
      this.getValueFromForm(this.controlNameDate).errors?.error === FORM_VALIDATOR_MESSAGES.required &&
      this.controlNameTime &&
      this.getValueFromForm(this.controlNameTime).touched &&
      this.getValueFromForm(this.controlNameTime).errors?.error === FORM_VALIDATOR_MESSAGES.required
    ) {
      error.push(FORM_VALIDATOR_MESSAGES.required);
    } else {
      if (
        this.controlNameDate &&
        this.getValueFromForm(this.controlNameDate).touched &&
        this.getValueFromForm(this.controlNameDate).errors?.error
      )
        error.push(this.getValueFromForm(this.controlNameDate).errors?.error);
      if (
        this.controlNameTime &&
        this.getValueFromForm(this.controlNameTime).touched &&
        this.getValueFromForm(this.controlNameTime).errors?.error
      )
        error.push(this.getValueFromForm(this.controlNameTime).errors?.error);
    }
    return error.join('<br>');
  }

  reformatDate() {
    const inputDate = this.getValueFromForm(this.controlNameDate)?.value?.split('-');
    if (inputDate?.length === 3) {
      this.getValueFromForm(this.controlNameDate).patchValue(inputDate.reverse().join('.'));
    }
  }

  ngOnInit() {
    if (this.controlNameDate) {
      const arrayKey = this.controlNameDate.split('.');
      this.controlInputNameDate = arrayKey.splice(arrayKey.length - 1);
      this.formParent = arrayKey.length ? arrayKey.reduce((sum, cur) => sum.get(cur), this.form) : this.form;
      this.getValueFromForm(this.controlNameDate).valueChanges.subscribe(() => this.reformatDate());
      this.reformatDate();
    }

    if (this.controlNameTime) {
      const arrayKey = this.controlNameTime.split('.');
      this.controlInputNameTime = arrayKey.splice(arrayKey.length - 1);
      if (!this.formParent) {
        this.formParent = arrayKey.length ? arrayKey.reduce((sum, cur) => sum.get(cur), this.form) : this.form;
      }
    }

    if (this.validate) {
      setTimeout(() => {
        if (this.controlNameDate) {
          if (this.dateRequire) {
            this.getValueFromForm(this.controlNameDate).setValidators(this.validDateRequire);
          } else {
            this.getValueFromForm(this.controlNameDate).setValidators(this.validDate);
          }
          this.getValueFromForm(this.controlNameDate).updateValueAndValidity();
        }
        if (this.controlNameTime) {
          if (this.timeRequire) {
            this.getValueFromForm(this.controlNameTime).setValidators(this.validTimeRequire);
          } else {
            this.getValueFromForm(this.controlNameTime).setValidators(this.validTime);
          }
          this.getValueFromForm(this.controlNameTime).updateValueAndValidity();
        }
      }, 0);
    }

    this.calendarDataService.updateDatePickerEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
      if (this.id === event.id) {
        if (event.value?.length === 10) {
          this.changeDate(event.value);
        } else if (event.value?.length === 5) {
          this.changeTime(event.value);
        }
      }
    });
  }
}
