<div class="chat-message-editor">
  <button
    type="button"
    class="icon-button icon-button_mb"
    [disabled]="!contactSelected.id"
    [ngbPopover]="paperclipMenu"
    popoverClass="paperclip-popover"
    placement="top"
    container="body"
  >
    <app-svg-icon name="paperclip" width="16" height="16"></app-svg-icon>
  </button>

  <button type="button" class="icon-button icon-button_mb">
    <app-svg-icon name="wall-clock" width="16" height="16"></app-svg-icon>
  </button>

  <div class="message-wrapper">
    <div class="quote" *ngIf="quotedMessage">
      <div class="quote__message">
        <span class="quote__message--limited">
          {{ quotedMessage?.content ? quotedMessage?.content : 'Прикреплены файлы.' }}
        </span>
      </div>

      <button type="button" class="icon-button" (click)="removeQuotedMessage()">
        <app-svg-icon name="trash" width="16" height="16"></app-svg-icon>
      </button>
    </div>
    <form [formGroup]="messageForm">
      <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="false" [required]="false">
        <textarea
          #messageInput
          (keydown.enter)="$event.preventDefault(); onMessageFormSubmit()"
          (input)="changeTextareaHeight()"
          formControlName="message"
          type="text"
          rows="1"
          maxlength="10000"
          [placeholder]="placeholder"
          (paste)="onPaste($event)"
        ></textarea>
      </dc-form-field-wrapper>
    </form>
  </div>

  <button
    type="button"
    class="icon-button icon-button_mb"
    [class.disabled]="
      !(messageForm.controls.message.value || userFiles.length || attachedFiles.length) || !contactSelected.id
    "
    (click)="onMessageFormSubmit()"
  >
    <app-svg-icon name="paper-plane-send" width="24" height="24"></app-svg-icon>
  </button>
</div>
<div class="mt-3 attached-file-list" *ngIf="userFiles.length || attachedFiles.length">
  <app-chat-attached-file
    *ngFor="let file of userFiles; let i = index"
    [attachedFiles]="userFiles"
    [clickedIndex]="i"
    (removeFile)="removeUserFile($event)"
    [enableRemoveButton]="true"
  ></app-chat-attached-file>
  <app-chat-attached-file
    *ngFor="let file of attachedFiles; let i = index"
    [attachedFiles]="attachedFiles"
    [clickedIndex]="i"
    (removeFile)="removeAttachedFile($event)"
    [enableRemoveButton]="true"
  ></app-chat-attached-file>
</div>

<ng-template #paperclipMenu>
  <div class="paperclip-file-menu">
    <button (click)="openFileManager()" class="blue-links-button">Прикрепить из файлового менеджера сервиса</button>
    <button (click)="openUploadManager()" class="blue-links-button">Прикрепить с компьютера</button>
  </div>
</ng-template>
