<div class="faq-files-field" [class.files-exist]="oldImages.length + newImages.length > 0" [class.readonly]="readonly">
  <div class="spinner-border" role="status" *ngIf="isLoading">
    <span class="sr-only">Loading...</span>
  </div>

  <div class="file-panel" *ngIf="!readonly">
    <input type="file" hidden multiple #file (change)="addImage($event)" accept=".jpg, .png" />
    <div class="faq-files-upload" (click)="handleFileClick($event)">
      <app-svg-icon name="paperclip" width="14" height="14"></app-svg-icon>
      Прикрепить изображение
    </div>
    <div class="file-panel-description">Файлы должны быть менее 10 МБ. Доступные расширения: png, jpg.</div>
  </div>

  <ng-container *ngIf="!editMode">
    <div class="faq-images" *ngIf="!isLoading">
      <div class="preview-image">
        <img [src]="selectedImage.src" alt="" class="img-responsive" *ngIf="selectedImage && selectedImage.src" />
      </div>

      <div class="image-list">
        <div class="image-item" *ngFor="let oldFile of oldImages; let oldImgIndex = index">
          <img
            [src]="oldFile.src"
            alt=""
            class="img-responsive"
            [class.active]="selectedImage.name === oldFile.name"
            (click)="selectImageFile(oldFile)"
            *ngIf="oldFile.src"
          />
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="editMode">
    <div class="faq-files-list" [class.removable]="!readonly" *ngIf="!isLoading">
      <div class="file-item" *ngFor="let oldFile of oldImages; let oldImgIndex = index">
        <img
          [src]="oldFile.src"
          alt=""
          class="img-responsive"
          *ngIf="oldFile.src"
          (click)="previewImage(oldFile.src)"
        />
        <button
          type="button"
          class="blue-links-button remove"
          aria-label="Close"
          *ngIf="!readonly"
          (click)="removeOldImage(oldImgIndex)"
        >
          <app-svg-icon name="times" width="14" height="14"></app-svg-icon>
        </button>
      </div>

      <ng-container *ngIf="!readonly">
        <div class="file-item" *ngFor="let newFile of newImages; let newImgIndex = index">
          <img [src]="newFile" alt="" class="img-responsive" />
          <button
            type="button"
            class="blue-links-button remove"
            aria-label="Close"
            (click)="removeNewImage(newImgIndex)"
          >
            <app-svg-icon name="times" width="14" height="14"></app-svg-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
