import { Pipe, PipeTransform } from '@angular/core';
import { ChatMessage, ChatRoom } from '@app/chat/models/chat.model';

@Pipe({
  name: 'messageInitials',
  standalone: true,
})
export class MessageInitialsPipe implements PipeTransform {
  transform(message: ChatMessage, tradeGroup: ChatRoom, userId: number, isTech?: boolean): string {
    if (!message.author) {
      return '';
    }

    let second_name = message.author.second_name;

    if (tradeGroup) {
      if (message.flags && message.flags.trade_provider && !tradeGroup.providers.includes(userId)) {
        second_name = 'Поставщик';
      }

      if (message.flags && message.flags.trade_customer && !tradeGroup.customers.includes(userId)) {
        second_name = 'Заказчик';
      }
    }

    if (isTech) {
      second_name = `${message.author.id}, ${message.author.second_name}`;
    }

    return `${second_name || ''} ${this.getFirstChar(message.author.first_name)}${this.getFirstChar(
      message.author.patronymic
    )}`;
  }

  getFirstChar(value: string) {
    return value ? `${value.charAt(0)}.` : '';
  }
}
