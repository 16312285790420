import { Injectable } from '@angular/core';
import { throwError as observableThrowError } from 'rxjs';
import { MY_USERS, NON_ARCHVE_USERS } from '../constants/tso-clients-params.constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { User } from '@app/shared/models/user.model';
import { environment } from '@app/environments/environment';
import { ChatUserTreeWithNode } from '../models/chat.model';

@Injectable({
  providedIn: 'root',
})
export class TsoClientsService {
  constructor(private http: HttpClient) {}

  // Для редактировния групп в чатах у дОТП
  getUsersForTechGroup(id?: number | null) {
    MY_USERS.val = id;
    const newRequestConfig = id ? [MY_USERS, NON_ARCHVE_USERS] : [NON_ARCHVE_USERS];
    const params = new HttpParams({
      fromObject: {
        filter: JSON.stringify(newRequestConfig),
      },
    });
    return this.http
      .get<ChatUserTreeWithNode[]>(`${environment.api_url}/users`, {
        headers: {
          Accept: 'application/vnd.api+json',
        },
        params,
      })
      .pipe(
        catchError((e) => {
          return observableThrowError(e);
        })
      );
  }
}
