export interface RatingProviderTrade {
  lots: RatingProviderLot[];
  place_trade: number;
  points_leader_trade: number;
  points_trade: number;
  id_trade: number;
}

export interface RatingProviderLot {
  place_lot: number;
  points_leader_lot: number;
  points_lot: number;
  nomenclatures: RatingProviderNomenclature[];
  id_lot: number;
}

export interface RatingProviderNomenclature {
  place_rating_nomenclature: number;
  points_leader_rating_nomenclature: number;
  points_rating_nomenclature: number;
  criteria: RatingProviderCriterion[];
  id_nomenclature: number;
}

export interface RatingProviderCriterion {
  place_rating_criterion: number;
  points_leader_rating_criterion: number;
  points_rating_criterion: number;
  value_leader_rating_criterion: number;
  value_rating_criterion: number;
  id_criterion: number;
}
