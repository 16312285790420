import { Component, Input } from '@angular/core';
import { UserCompetenceMapModalComponent } from '@app/+competence-map/competence-shared/user-competence-map/user-competence-map-modal/user-competence-map-modal.component';
import { TRADE_ACCESS_RIGHTS_ICONS_COLLECTION } from '@app/+trades/constants/trades.constants';
import { TradeCustomer } from '@app/+trades/models/customers.model';
import { FileManagerModalUserInfoComponent } from '@app/file-manager/components/file-manager-modal-user-info/file-manager-modal-user-info.component';
import { User } from '@app/shared/models/user.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-customers-cell',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent {
  @Input() customers: TradeCustomer[];

  isShowMoreDetails = false;

  constructor(private modalService: NgbModal) {}

  getAccessIconByKey(key: string) {
    return TRADE_ACCESS_RIGHTS_ICONS_COLLECTION[key];
  }

  showMoreDetails(event: MouseEvent) {
    event.stopPropagation();
    this.isShowMoreDetails = true;
  }

  showUserContacts(event: MouseEvent, user: User) {
    event.stopPropagation();
    const modal = this.modalService.open(FileManagerModalUserInfoComponent, {
      centered: true,
      windowClass: 'dc-modal modal-window dc-modal-user-card',
      animation: true,
    });
    modal.componentInstance.user = user;
    modal.componentInstance.title = 'Контакты';
  }

  showUserCompetencies(event: MouseEvent, user: User): void {
    event.stopPropagation();
    const modal = this.modalService.open(UserCompetenceMapModalComponent, {
      centered: true,
      animation: true,
      windowClass: 'dc-modal modal-window',
      size: 'lg',
    });
    modal.componentInstance.user = user;
  }
}
