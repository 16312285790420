<div class="chat-section-select">
  <div class="row">
    <button
      *ngFor="let section of chatSections; trackBy: trackByName"
      (click)="onSectionSelect(section)"
      [ngClass]="section.name === chatSectionSelected.name ? 'active' : ''"
      title="{{ section.title }}"
      class="icon-button text-center chat__section-select chat__section-select"
    >
      <app-svg-icon
        [name]="section.icon"
        width="18"
        height="18"
        *ngIf="section.icon"
        class="mr-1 icon-button"
      ></app-svg-icon>
      <!-- <span *ngIf="section.name === chatSectionsEnum.DUTY_TSO">ОТП</span> -->
      <span *ngIf="section.counter && !isModalView" class="chat-number" [class.chat-number__10]="section.counter > 10">
        {{ section.counter > 10 ? '10+' : section.counter }}
      </span>
    </button>
  </div>
</div>
