import { Pipe, PipeTransform } from '@angular/core';
import { RolesEnum } from '@app/shared/constants/roles.constants';

@Pipe({
  name: 'toLowerCaseUserRole',
})
export class ToLowerCaseUserRolePipe implements PipeTransform {
  transform(userRole: string): string {
    if (userRole.length > 3 && userRole !== RolesEnum.DUTY_TSO) {
      return userRole.toLowerCase();
    }
    return userRole;
  }
}
