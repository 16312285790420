<div class="blue-links">
  <app-livesearch-select
    [value]="localValue.name"
    placeholder="Поиск по пользователям"
    [list]="filterSuggestions"
    (searchEvent)="search($event)"
    (selectEvent)="select($event)"
  >
  </app-livesearch-select>
  <div class="mb-2">
    <div>
      <a>
        <fa-icon [icon]="['fas', 'crown']"></fa-icon>
      </a>
    </div>
    <div>
      <input id="my_trade" class="cbx-input" [(ngModel)]="localValue.isMyTrade" type="checkbox" />
      <label class="cbx-label" for="my_trade">
        <span class="cbx-label-text">Только мои</span>
      </label>
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <button class="btn btn-dc-primary" (click)="apply()">Фильтровать</button>
  </div>
</div>
