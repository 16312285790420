<dc-form-field-wrapper style="material" [vertically]="true">
  <dc-form-input [isShowLength]="false">
    <input
      formInput
      [formControl]="searchQuery"
      class="search-input"
      placeholder="Введите ФИО, название группы или темы"
    />
    <button *ngIf="isSearchQuery" (click)="resetSearch()" class="reset-search">
      <app-svg-icon [width]="14" [height]="14" name="times"></app-svg-icon>
    </button>
  </dc-form-input>
</dc-form-field-wrapper>
