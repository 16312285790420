import { isUndefined } from 'lodash-es';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import moment from 'moment';
import { DATE_FORMAT } from '@app/shared/constants/date.constants';

export function maxDateValidator(value?: string): ValidatorFn {
  return (control: AbstractControl) => {
    const maxDateValue = value ?? control.parent.get('max')?.value;

    if (!control.value || !maxDateValue) {
      return null;
    }

    const momentDate = moment(control.value, DATE_FORMAT);
    const momentMaxDate = moment(maxDateValue, DATE_FORMAT);

    if (momentDate.isValid() && momentMaxDate.isValid() && momentDate.isAfter(momentMaxDate)) {
      return { maxValue: true };
    }

    return null;
  };
}

export function minDateValidator(value?: string): ValidatorFn {
  return (control: AbstractControl) => {
    const minDateValue = value ?? control.parent.get('min')?.value;

    if (!control.value || !minDateValue) {
      return null;
    }

    const momentDate = moment(control.value, DATE_FORMAT);
    const momentMinDate = moment(minDateValue, DATE_FORMAT);

    if (momentDate.isValid() && momentMinDate.isValid() && momentDate.isBefore(momentMinDate)) {
      return { minValue: true };
    }

    return null;
  };
}

export function dateValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    if (!control.value) {
      return null;
    }

    if (!moment(control.value, DATE_FORMAT).isValid()) {
      return { invalidDate: true };
    }

    return null;
  };
}
