import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { LocalStorageService } from '@app/services/local-storage.service';
import { AuthService } from '@app/shared/services/auth.service';

const PINABLE_STORAGE_KEY = 'pinable';
const SIZE_STORAGE_KEY = 'size';

interface IPinableConfig {
  x: number;
  y: number;
  px: number;
  py: number;
  width: number;
  height: number;
  minWidth: number;
  minHeight: number;
  draggingCorner: boolean;
  draggingWindow: boolean;
}

@Component({
  selector: 'app-rich-modal',
  templateUrl: './rich-modal.component.html',
  styleUrls: ['./rich-modal.component.scss'],
})
export class RichModalComponent implements OnInit {
  @Input() movable: boolean = false;
  @Input() pinable: boolean = false;
  @Input() resizable: boolean = false;
  @Input() closable: boolean = true;

  @Input() minWidth: number = 800;
  @Input() minHeight: number = 600;

  @Input() maxWidth: number = 1100;
  @Input() maxHeight: number = 850;

  @Input() modalType: string = '';

  @Output() close = new EventEmitter();

  x: number;
  y: number;
  px: number;
  py: number;
  width: number;
  height: number;
  draggingCorner: boolean;
  draggingWindow: boolean;
  resizer: Function;
  isPinableActive = false;
  isActive = true;

  constructor(private readonly localStorageService: LocalStorageService, private readonly authService: AuthService) {
    this.x = 300;
    this.y = 50;
    this.px = 0;
    this.py = 0;
    this.draggingCorner = false;
    this.draggingWindow = false;

    window.onmousemove = this.onWindowDrag;
  }

  ngOnInit(): void {
    const sizeConfig = this.localStorageService.getItem<{ width: number; height: number }>(
      `${SIZE_STORAGE_KEY}_${this.modalType}_${this.authService.user_id}`
    );
    if (sizeConfig) {
      this.width = sizeConfig.width;
      this.height = sizeConfig.height;
    } else {
      this.width = Math.min(window.innerWidth * 0.8, this.maxWidth);
      this.height = Math.min(window.innerHeight * 0.8, this.maxHeight);
    }

    const pinableConfig = this.localStorageService.getItem<IPinableConfig>(
      `${PINABLE_STORAGE_KEY}_${this.authService.user_id}`
    );
    if (pinableConfig && this.pinable) {
      this.isPinableActive = true;

      this.x = pinableConfig.x;
      this.y = pinableConfig.y;
      this.px = pinableConfig.px;
      this.py = pinableConfig.py;
      this.minWidth = pinableConfig.minWidth;
      this.minHeight = pinableConfig.minHeight;
    }
  }

  onWindowPress(event: MouseEvent) {
    this.draggingWindow = this.movable;
    this.px = event.clientX;
    this.py = event.clientY;
  }

  onWindowDrag(event: MouseEvent) {
    if (!this.draggingWindow) {
      return;
    }
    const offsetX = event.clientX - this.px;
    const offsetY = event.clientY - this.py;
    this.x += offsetX;
    this.y += offsetY;
    this.px = event.clientX;
    this.py = event.clientY;
  }

  topLeftResize(offsetX: number, offsetY: number) {
    this.x += offsetX;
    this.y += offsetY;
    this.width -= offsetX;
    this.height -= offsetY;
  }

  topRightResize(offsetX: number, offsetY: number) {
    this.y += offsetY;
    this.width += offsetX;
    this.height -= offsetY;
  }

  bottomLeftResize(offsetX: number, offsetY: number) {
    this.x += offsetX;
    this.width -= offsetX;
    this.height += offsetY;
  }

  bottomRightResize(offsetX: number, offsetY: number) {
    this.width += offsetX;
    this.height += offsetY;
  }

  onCornerClick(event: MouseEvent, resizer?: Function) {
    if (!this.resizable) {
      return;
    }

    this.draggingCorner = true;
    this.px = event.clientX;
    this.py = event.clientY;
    this.resizer = resizer;
    event.preventDefault();
    event.stopPropagation();
  }

  private updatePinableState(): void {
    const sizeConfig = {
      width: this.width,
      height: this.height,
    };

    this.localStorageService.setItem(`${SIZE_STORAGE_KEY}_${this.modalType}_${this.authService.user_id}`, sizeConfig);

    const pinableConfig: IPinableConfig = {
      x: this.x,
      y: this.y,
      px: this.px,
      py: this.py,
      width: this.width,
      height: this.height,
      draggingCorner: this.draggingCorner,
      draggingWindow: this.draggingWindow,
      minWidth: this.minWidth,
      minHeight: this.minHeight,
    };

    if (!this.isPinableActive) {
      this.localStorageService.removeItem(`${PINABLE_STORAGE_KEY}_${this.authService.user_id}`);
    } else {
      this.localStorageService.setItem(`${PINABLE_STORAGE_KEY}_${this.authService.user_id}`, pinableConfig);
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onCornerMove(event: MouseEvent) {
    this.onWindowDrag(event);
    if (!this.draggingCorner) {
      return;
    } else {
      const offsetX = event.clientX - this.px;
      const offsetY = event.clientY - this.py;

      this.resizer(offsetX, offsetY);

      this.width = Math.min(Math.max(this.width, this.minWidth), this.maxWidth);
      this.height = Math.min(Math.max(this.height, this.minHeight), this.maxHeight);

      this.px = event.clientX;
      this.py = event.clientY;
      this.updatePinableState();
    }
  }

  onClose() {
    this.close.emit();
  }

  @HostListener('document:mouseup', ['$event'])
  onCornerRelease() {
    this.draggingWindow = false;
    this.draggingCorner = false;
    this.updatePinableState();
  }

  togglePinable(): void {
    this.isPinableActive = !this.isPinableActive;

    this.updatePinableState();
  }

  onClickOutside() {
    this.isActive = false;
  }

  onMouseDown() {
    this.isActive = true;
  }
}
