<ng-container *ngIf="competenciesNames.length">
  <ul class="competencies">
    <li *ngFor="let competency of competenciesNames">
      <app-line-clamp [content]="competency"></app-line-clamp>
    </li>
  </ul>

  <a class="trade-cell-arrow" (click)="showMoreDetails($event)">
    <app-svg-icon width="20" height="10" name="down"></app-svg-icon>
  </a>
</ng-container>

<span *ngIf="!competenciesNames.length">-</span>

<app-modal-window [isShowed]="isShowDetails" (closeEvent)="isShowDetails = false" customClass="competencies-modal">
  <div class="modal-header">
    <h6>Выбранные компетенции</h6>
  </div>

  <ng-container *ngIf="loaded">
    <app-competence-map-view [competencies]="competencies" [isStaticData]="isStaticData"></app-competence-map-view>
  </ng-container>
</app-modal-window>
