<div
  (click)="onTabSelect()"
  class="chat__tab"
  [title]="getTabTitle()"
  [ngClass]="{ active: active, collapsed: isCollapsed$ | async }"
  [style.cursor]="isDragging ? 'grabbing' : active ? 'default' : 'pointer'"
>
  <div class="chat__tab-title">
    {{ getTabTitle() }}
  </div>

  <div class="chat__tab-actions">
    <button
      *ngIf="active || isPinned"
      type="button"
      class="icon-button"
      [class.pinned]="isPinned"
      (click)="onTabPin($event)"
    >
      <app-svg-icon name="pin" width="12" height="12"></app-svg-icon>
    </button>

    <div *ngIf="tab.counter" [class.chat-number__10]="tab.counter > 10" class="chat-number">
      {{ tab.counter <= 10 ? tab.counter : '10+' }}
    </div>

    <div (click)="onTabRemove($event)" *ngIf="active" class="chat__tab-times" [ngClass]="{ dragging: isDragging }">
      <app-svg-icon [name]="'close'" width="20" height="20" class="close-icon"></app-svg-icon>
    </div>
  </div>
</div>
