<div class="trade-table blue-links">
  <div class="grid-layout grid-layout-head">
    <ng-container *ngFor="let column of columns">
      <div
        class="grid-container p-10"
        *ngIf="!column.hidden"
        [style.width.px]="column.width"
        [style.minWidth.px]="column.width"
      >
        <app-trade-grid-header-cell
          [column]="column"
          [filteredRows]="filteredRows"
          [goods]="goods"
          [services]="services"
          [userId]="userId"
          (changeFilterEvent)="saveFilter($event)"
        ></app-trade-grid-header-cell>
      </div>
    </ng-container>
  </div>
  <div class="grid-layout-body" *ngIf="!isLoading && filteredRows.length" #body>
    <div
      *ngFor="let row of filteredRows"
      class="grid-layout"
      [class.trade-accent]="isRowAccent(row.date_start_trade, row.date_end_trade, row.status_trade)"
    >
      <ng-container *ngFor="let column of columns">
        <div
          class="grid-container"
          *ngIf="!column.hidden"
          [class.p-10]="column.id !== tradeColumnKeys.TRADE_DATE && column.id !== tradeColumnKeys.ARCHIVE_DATE"
          [style.width.px]="column.width"
          [style.minWidth.px]="column.width"
        >
          <app-trade-grid-cell
            [value]="row[column.id]"
            [column]="column"
            [timeZoneOffset]="timeZoneOffset"
            [timeZoneName]="timeZoneName"
            (onClickCell)="clickCell($event)"
            (onSaveIconClick)="saveIconClick($event)"
            (onToggleRowHeight)="toggleRowHeight($event)"
            [container]="container"
            [body]="body"
            [row]="row"
          >
          </app-trade-grid-cell>
        </div>
      </ng-container>
    </div>
  </div>
  <p class="p-10" *ngIf="isLoading">Загрузка...</p>
  <p class="p-10" *ngIf="!isLoading && !filteredRows.length">Нет торгов</p>
</div>
