<div [ngSwitch]="column.id" (click)="clickCell()">
  <ng-container *ngSwitchCase="tradeColumnKeys.ID">
    <div class="trade-link">{{ value || '-' }}</div>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.SUPPLIER_TRADE_ID">
    <div class="trade-link">{{ value || '-' }}</div>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.TITLE">
    <span class="pointer trade-name trade-link">{{ value || row.trade.title || '-' }}</span>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.COMPETENCIES">
    <app-competencies-cell [childCompetencies]="row.competence_sections"></app-competencies-cell>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.PROJECT_DATE">
    <ng-container
      [ngTemplateOutlet]="dateWithTimeZone"
      [ngTemplateOutletContext]="{ date: row.date_create_trade, value: value }"
    ></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.CUSTOMERS">
    <app-customers-cell
      *ngIf="isArray(value)"
      [customers]="value | tradeUsersSort : 'second_name'"
    ></app-customers-cell>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.STATUS">
    <div class="d-flex justify-content-between save-button-wrapper">
      <span class="mr-1">{{ tradeStatusLabels[value] || tradeStatusLabels[row.status_trade] || '-' }}</span>
      &nbsp;
      <a *ngIf="tradeStatusLabels[value]" class="save-button mr-0" (click)="clickSaveIcon($event)">
        <app-svg-icon name="save-template" width="16" height="16"></app-svg-icon>
      </a>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.PRETRADE_DATE">
    <app-line-clamp
      *ngIf="row.user_who_invite_provider"
      [content]="
        (row.status !== TradeCardStatuses.ARCHIVE ? row.user_who_invite_provider : row.user_who_invite_provider_archive)
          | initials
      "
    ></app-line-clamp>
    <span *ngIf="!value">-</span>

    <ng-container *ngIf="row.date_invitations_are_sent">
      <ng-container
        [ngTemplateOutlet]="dateWithTimeZone"
        [ngTemplateOutletContext]="{ date: row.date_invitations_are_sent, value: value }"
      ></ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.POTENTIAL_SUPPLIERS">
    <span>{{ row[column.id] || 0 }}</span>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.SENDED_INVITATIONS">
    <span>{{ row[column.id] || 0 }}</span>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.ACCEPTED_INVITATIONS">
    <span>{{ row[column.id] || 0 }}</span>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.TRADE_SUPPLIER_PLAYERS">
    <span>{{ row[column.id] || 0 }}</span>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.TRADE_DATE">
    <div class="p-10" [class.bg-important]="isHighlightDateCell(value)">
      <ng-container
        [ngTemplateOutlet]="dateWithTimeZone"
        [ngTemplateOutletContext]="{ date: row.date_start_trade, value: value }"
      ></ng-container>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.ARCHIVE_DATE">
    <div class="p-10" [class.bg-important]="isHighlightDateCell(value)">
      <ng-container
        [ngTemplateOutlet]="dateWithTimeZone"
        [ngTemplateOutletContext]="{ date: row.date_end_trade, value: value }"
      ></ng-container>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.REASON_END_TRADE">
    <span>{{ tradeCancelLabels[value] || '-' }}</span>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.WINNERS_PROVIDERS">
    <app-winners-providers-cell
      *ngIf="
        (row.status === tradeCardStatuses.TRADE || row.status === tradeCardStatuses.ARCHIVE) && value?.length;
        else empty
      "
      [providers]="value"
    ></app-winners-providers-cell>
    <ng-template #empty>-</ng-template>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.TARIFF">
    <span>{{ row?.payment || 0 }} &#8381;</span>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.PAYER">
    <ng-container *ngIf="value">
      <div class="d-flex align-items-center">
        <span class="user-info-status">
          <div [ngClass]="value.status"></div>
        </span>
        <app-line-clamp [content]="value | initials"></app-line-clamp>
      </div>
      <div class="margin-l-26">
        <app-line-clamp [content]="value.company | companyName"></app-line-clamp>
      </div>
    </ng-container>

    <span *ngIf="!value">-</span>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.REQ_DATA">
    <span>{{ row.date_registration_payer | dateFormat }}</span>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.REQ_DATA_STATE">
    <span>{{ value || '-' }}</span>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.SUPPLIERS">
    <app-customers-cell
      *ngIf="isArray(value) && row.status !== inviteSupplierStatuses.INVITED; else empty"
      [customers]="value | tradeUsersSort : 'second_name'"
    ></app-customers-cell>
    <ng-template #empty>-</ng-template>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.ACCESS">
    <div>
      {{ inviteSuppliersLabels[row.selected_provider_in_trade ? inviteSupplierStatuses.SELECTED : row.status] || '-' }}
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.CUSTOMER_COMPANY">
    <div>{{ row.owner_trade.company | companyName }}</div>
    <div>ИНН:&nbsp;{{ row.owner_trade.company.itn || '-' }}</div>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.CUSTOMER_NAME">
    <div class="d-flex justify-content-between align-items-center">
      <app-line-clamp [content]="row.owner_trade | initials"></app-line-clamp>
      <app-svg-icon
        class="blue-links ml-1"
        name="comment"
        width="16"
        height="14"
        [chatLink]="row.owner_trade.id"
      ></app-svg-icon>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.TRADE_RESULTS">
    <ng-container
      *ngIf="row.status_trade === tradeCardStatuses.TRADE || row.status_trade === tradeCardStatuses.ARCHIVE"
    >
      <div>
        Место в торгах:&nbsp;{{
          row.made_offer_in_trade && isAcceptedTradeInvitation(row) ? row.number_place_in_ranking || 0 : '-'
        }}
      </div>
      <div>
        Балл по торгам:&nbsp;{{
          row.made_offer_in_trade && isAcceptedTradeInvitation(row) ? (+row.points_for_trading).toFixed(2) || 0 : '-'
        }}
      </div>
      <div>
        Балл лидера:&nbsp;{{
          isAcceptedTradeInvitation(row) && row.reason_end_trade !== reasonsEndTrade.EARLY_LACK_PROVIDERS
            ? row.leader_points !== -1
              ? (+row.leader_points).toFixed(2)
              : '-'
            : '-'
        }}
      </div>
    </ng-container>
    <span *ngIf="row.status_trade !== tradeCardStatuses.TRADE && row.status_trade !== tradeCardStatuses.ARCHIVE">
      -
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="tradeColumnKeys.SELECTED_PROVIDER_IN_TRADE">
    <ng-container
      *ngIf="
        (row.status_trade === tradeCardStatuses.TRADE || row.status_trade === tradeCardStatuses.ARCHIVE) &&
        row.status === inviteSupplierStatuses.ACCEPTED
      "
    >
      <div>{{ row.selected_provider_in_trade ? 'Да' : 'Нет' }}</div>
    </ng-container>
    <span
      *ngIf="
        (row.status_trade !== tradeCardStatuses.TRADE && row.status_trade !== tradeCardStatuses.ARCHIVE) ||
        row.status !== inviteSupplierStatuses.ACCEPTED
      "
    >
      -
    </span>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <span>{{ value || '-' }}</span>
  </ng-container>
</div>

<ng-template #dateWithTimeZone let-date="date" let-value="value">
  <span [style.display]="'table-caption'">{{ date | dateTimeShortFormat : timeZoneOffset }}</span>
  <ng-container *ngIf="value">
    <span>Ваш часовой пояс:</span><br />
    <app-line-clamp [content]="timeZoneName"></app-line-clamp>
  </ng-container>
</ng-template>
