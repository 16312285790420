<app-tech-chat-title
  [title]="'Моя тех. поддержка'"
  [myTso]="!!myTso"
  [isExpanded]="true"
  iconName="tools"
  tooltipText="Выбор оператора тех. поддержки"
  [disabledTechButton]="isDisabledChangeTsoButton"
  [totalUnreadCounter]="(totalUnreadyCounts$ | async)[techTypeEnum.MY_TECH]"
  (toggled)="onToggleExpanded('isMyTechExpanded')"
  (edit)="openTsoEdit()"
  [isModalView]="isModalView"
></app-tech-chat-title>

<div class="tech-content" *ngIf="isMyTechExpanded">
  <app-chat-groups [techType]="techTypeEnum.MY_TECH" [isModalView]="isModalView"></app-chat-groups>
  <app-chat-contacts [techType]="techTypeEnum.MY_TECH" [isModalView]="isModalView"></app-chat-contacts>
</div>

<div class="tech-border" *ngIf="user.type === rolesEnum.PARTNER"></div>

<app-tech-chat-title
  *ngIf="user.type === rolesEnum.PARTNER"
  [title]="'Мои пользователи'"
  [disabled]="!isTSO || !isReadyTso"
  iconName="life_ring"
  tooltipText="Управление функциями ОТП"
  [isExpanded]="isMyUsersExpanded"
  [disabledTechButton]="!isReadyTso || !isStatusActive"
  [totalUnreadCounter]="(totalUnreadyCounts$ | async)[techTypeEnum.MY_USERS]"
  (toggled)="onToggleExpanded('isMyUsersExpanded')"
  (edit)="openActivatedTso()"
  [isModalView]="isModalView"
></app-tech-chat-title>

<div class="tech-content" *ngIf="isMyUsersExpanded">
  <app-chat-groups [techType]="techTypeEnum.MY_USERS" [isModalView]="isModalView"></app-chat-groups>
  <app-chat-contacts [techType]="techTypeEnum.MY_USERS" [isModalView]="isModalView"></app-chat-contacts>
</div>

<div class="tech-border" *ngIf="user.type === rolesEnum.PARTNER"></div>

<app-tech-chat-title
  *ngIf="user.type === rolesEnum.PARTNER"
  [title]="'Все пользователи'"
  [disabled]="!isDutyTSO || !isReadyTso"
  iconName="calendar-check-dark"
  tooltipText="Управление графиком дежурств"
  [isExpanded]="isAllUsersExpanded"
  [disabledTechButton]="!isReadyTso || !isStatusActive"
  [totalUnreadCounter]="(totalUnreadyCounts$ | async)[techTypeEnum.ALL_USERS]"
  (toggled)="onToggleExpanded('isAllUsersExpanded')"
  (edit)="openDutyEdit()"
  [isModalView]="isModalView"
></app-tech-chat-title>

<div class="tech-content" *ngIf="isAllUsersExpanded">
  <app-chat-groups [techType]="techTypeEnum.ALL_USERS" [isModalView]="isModalView"></app-chat-groups>
  <app-chat-contacts [techType]="techTypeEnum.ALL_USERS" [isModalView]="isModalView"></app-chat-contacts>
</div>

<app-modal-window
  [isShowed]="isShowedTsoEditor"
  (closeEvent)="closeTsoEdit()"
  [centered]="true"
  customClass="change-tso-modal"
>
  <app-my-tso-modal
    [myCurrentTso]="myTso"
    [userId]="user.id"
    *ngIf="isShowedTsoEditor"
    (onChangeEvent)="changeSelection($event)"
  ></app-my-tso-modal>
</app-modal-window>

<app-modal-window
  [isShowed]="isShowDutyTsoEditor"
  (closeEvent)="closeDutyTsoEdit()"
  centered="true"
  customClass="chat-duty-tso-modal"
>
  <app-chat-duty-tso-options *ngIf="isShowDutyTsoEditor" (closeModal)="closeDutyTsoEdit()"></app-chat-duty-tso-options>
</app-modal-window>

<app-modal-window
  [isShowed]="isShowedTsoOptionsEditor"
  (closeEvent)="closeActivatedTso()"
  centered="true"
  customClass="chat-tso-options-modal"
>
  <app-chat-tso-options *ngIf="isShowedTsoOptionsEditor" [flags]="flags"></app-chat-tso-options>
</app-modal-window>
