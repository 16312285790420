import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@app/environments/environment';
import {
  GeneralAccrualAggregation,
  PaymentsCreatePatams,
  PaymentsFromFileModel,
  PaymentsFromFileParams,
  TransactionsFromFile,
} from '../models/payment-accrual.model';
import { filterToParams } from '@app/shared/utils';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PaymentAccrualsUploadPaymentsService {
  constructor(private http: HttpClient) {}

  // TODO: deprecated
  loadFileTransactionFile(body: PaymentsFromFileParams) {
    return this.http.post<PaymentsFromFileModel>(`${environment.api_url}/transaction/from-file`, body);
  }

  loadTransactionFile(body: FormData) {
    const headers: HttpHeaders = new HttpHeaders()
      .set('Enctype', 'multipart/form-data')
      .set('Accept', 'application/json');

    return this.http
      .post(`${environment.api_url}/transaction/from-file`, body, {
        headers: headers,
        reportProgress: true,
        observe: 'events',
      })
      .pipe(catchError((err) => throwError(err)));
  }

  deleteTransaction(body: string[]) {
    return this.http
      .delete<void>(`${environment.api_url}/transaction/create-temporary`, {
        body: { transactions: body },
      })
      .pipe(catchError((err) => throwError(err)));
  }

  deleteLock() {
    return this.http.delete<any>(`${environment.api_url}/transaction/from-file`);
  }

  deleteTransactionFromCache(id: string) {
    return this.http.delete<any>(`${environment.api_url}/transaction/create-temporary/${id}`);
  }

  createTransaction(filter: Partial<PaymentsCreatePatams>) {
    const params: { [param: string]: string | string[] } = filterToParams<PaymentsCreatePatams>(filter);
    return this.http.post<any>(`${environment.api_url}/transaction/create-temporary`, params);
  }

  compareTransactionsForDuplicates(body: string[]) {
    return this.http
      .post<TransactionsFromFile[]>(`${environment.api_url}/transaction/checking_duplicates`, {
        transactions: body,
      })
      .pipe(catchError((err) => throwError(err)));
  }

  addTransactions(body: string[]) {
    return this.http
      .post<GeneralAccrualAggregation[]>(`${environment.api_url}/transaction/create-temporary`, {
        transactions: body,
      })
      .pipe(catchError((err) => throwError(err)));
  }
}
