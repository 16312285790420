import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import { DestroyService } from '@app/services/destroy.service';
import { ChatService } from '@app/chat/services/chat.service';
import { ChatRoom } from '@app/chat/models/chat.model';
import { getScrollOffset } from '@app/chat/helpers';

@Component({
  selector: 'app-chat-tabs',
  templateUrl: './chat-tabs.component.html',
  styleUrls: ['./chat-tabs.component.scss'],
  providers: [DestroyService],
})
export class ChatTabsComponent implements OnInit, AfterViewInit {
  @ViewChild('scrollMe', { static: true }) scrollMe: ElementRef;

  contactSelected: ChatRoom;
  tabs: ChatRoom[] = [];

  private isMouseDown = false;
  private startX = 0;
  private scrollLeft = 0;
  protected isDragging = false;

  isCollapsed$ = this.chatService.isCollapsed$.asObservable();

  constructor(
    private readonly chatService: ChatService,
    private readonly destroy$: DestroyService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.tabs = this.filterTabs(this.chatService.getTabs());

    this.contactSelected = this.chatService.getContactSelected();

    combineLatest([this.chatService.tabsChanged, this.chatService.pinnedTabs$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([allTabs]) => {
        const filteredTabs = this.filterTabs(allTabs);

        this.tabs = this.getUniqueChats([...this.chatService.pinnedTabs, ...filteredTabs]);

        this.cdr.detectChanges();
      });

    this.chatService.contactSelectedChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe((contactSelected) => (this.contactSelected = contactSelected));
  }

  filterTabs(tabs: ChatRoom[]) {
    return tabs.filter((tab) => !this.chatService.isTradeGroup(tab));
  }

  ngAfterViewInit() {
    const filteredTabs = this.filterTabs(this.chatService.getTabs());

    this.tabs = this.getUniqueChats([...this.chatService.pinnedTabs, ...filteredTabs]);

    this.chatService.scrollToTabChanged.pipe(takeUntil(this.destroy$)).subscribe((elem) => {
      setTimeout(() => {
        this.scrollMe.nativeElement.scrollTo({
          left: getScrollOffset(this.scrollMe, elem),
          behavior: 'smooth',
        });
      }, 0);
    });
  }

  scroll(size: number) {
    this.scrollMe.nativeElement.scrollLeft += size;
  }

  canScrollR(): boolean {
    return (
      this.scrollMe.nativeElement.scrollLeft <
      this.scrollMe.nativeElement.scrollWidth - this.scrollMe.nativeElement.clientWidth
    );
  }

  canScrollL(): boolean {
    return this.scrollMe.nativeElement.scrollLeft > 0;
  }

  onMouseDown($event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();

    this.isMouseDown = true;
    this.isDragging = false;

    this.startX = $event.pageX - this.scrollMe.nativeElement.offsetLeft;
    this.scrollLeft = this.scrollMe.nativeElement.scrollLeft;
  }

  onMouseMove($event: MouseEvent) {
    if (!this.isMouseDown) return;

    $event.stopPropagation();
    $event.preventDefault();

    const x = $event.pageX - this.scrollMe.nativeElement.offsetLeft;
    const dragOffset = x - this.startX;

    if (Math.abs(dragOffset) >= 10) {
      this.isDragging = true;
    }

    this.scrollMe.nativeElement.scrollLeft = this.scrollLeft - dragOffset;
  }

  onMouseOut($event: MouseEvent) {
    $event.stopPropagation();
    this.isMouseDown = false;
  }

  onCollapseClick() {
    this.chatService.toggleCollapse();
  }

  getUniqueChats(chats: ChatRoom[]): ChatRoom[] {
    const uniqueChatsMap = new Map<string, ChatRoom>();

    chats.forEach((chat) => {
      if (!uniqueChatsMap.has(chat.room_id)) {
        uniqueChatsMap.set(chat.room_id, chat);
      }
    });

    return Array.from(uniqueChatsMap.values());
  }
}
