<div (click)="cancel()" class="dc-fileman-modal-x-button">
  <app-svg-icon [width]="20" [height]="20" name="close"></app-svg-icon>
</div>
<div class="modal-header center">
  <div class="modal-title">{{ title }}</div>
</div>
<div class="modal-body">
  <p class="text-center" [innerHTML]="text"></p>
</div>
<div class="modal-footer">
  <div *ngIf="!isHiddenButton" class="dc-footer-buttons">
    <button class="btn btn-dc-outline" (click)="cancel()">{{ cancelEdit }}</button>
    <button class="btn btn-dc-primary" (click)="submit()">{{ applyEdit }}</button>
  </div>
</div>
