import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomerCompanyInfoComponent } from './components/customer-company-info/customer-company-info.component';
import { TradeCardDataProtectorGuard } from './guards/trade-card-data-protector.guard';
import { TradeUsersSortPipe } from './pipes/trade-users-sort.pipe';
import { TradeCustomersSortPipe } from './pipes/trade-customers-sort.pipe';
import { DiffDatesPipe } from './pipes/diff-dates.pipe';
import { NotesListComponent } from './components/notes-list/notes-list.component';
import { TradeCardGridComponent } from './components/trade-card-grid/trade-card-grid.component';
import { DualListUsersComponent } from './components/dual-list-users/dual-list-users.component';
import { TradeCardTabItemComponent } from './components/trade-card-tab-item/trade-card-tab-item.component';
import { TradeCardHeaderComponent } from './components/trade-card-header/trade-card-header.component';
import { TradeCardControlsComponent } from './components/trade-card-controls/trade-card-controls.component';
import { TradesStatusLineLineComponent } from './components/trades-status-line/trades-status-line.component';
import { TradeGridOperationsComponent } from './components/trade-grid-operations/trade-grid-operations.component';
import { TradeGridHeaderCellComponent } from './components/trade-card-grid/trade-grid-header-cell/trade-grid-header-cell.component';
import { TradeGridCellComponent } from './components/trade-card-grid/trade-grid-cell/trade-grid-cell.component';
import { SupplierLotsComponent } from './components/supplier-lots/supplier-lots.component';
import { SupplierLotComponent } from './components/supplier-lots/supplier-lot/supplier-lot.component';
import { SupplierLotCriteriaComponent } from './components/supplier-lots/supplier-lot-criteria/supplier-lot-criteria.component';
import { SupplierLotCriteriaViewComponent } from './components/supplier-lots/supplier-lot-criteria-view/supplier-lot-criteria-view.component';
import { LogoUploadComponent } from './components/logo-upload/logo-upload.component';
import { SupplierCompanyInfoComponent } from './components/supplier-company-info/supplier-company-info.component';
import { DocumentListComponent } from './components/document-list/document-list.component';
import { CustomersFilterComponent } from './components/customers-filter/customers-filter.component';
import { InviteStatusesFilterComponent } from './components/invite-statuses-filter/invite-statuses-filter.component';
import { ReportNotesComponent } from './components/report-notes/report-notes.component';
import { ReportReputationFiltersComponent } from './components/report-reputation-filters/report-reputation-filters.component';
import { ReportAccessedUsersComponent } from './components/report-accessed-users/report-accessed-users.component';
import { SharedModule } from '@app/shared/shared.module';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowUp,
  faCheckSquare,
  faClipboard,
  faCommentSlash,
  faCopy,
  faCrown,
  faEnvelope,
  faExpandArrowsAlt,
  faFileAlt,
  faFileExcel,
  faFilePdf,
  faLevelDownAlt,
  faListOl,
  faLock,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faShoppingCart,
  faToggleOff,
  faUndoAlt,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { TreeModule } from '@circlon/angular-tree-component';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TextMaskModule } from 'angular2-text-mask';
import { RouterModule } from '@angular/router';
import { CriteriaDateFormatPipe } from './pipes/criteria-date-format.pipe';
import { CriteriaValueComponent } from './components/criteria-value/criteria-value.component';
import { CriteriaRangeValueComponent } from './components/criteria-range-value/criteria-range-value.component';
import { CriteriaViewComponent } from './components/criteria-view/criteria-view.component';
import { SelectProviderFilterComponent } from './components/select-provider-filter/select-provider-filter.component';
import { CriteriaViewValueEditComponent } from './components/criteria-view-value-edit/criteria-view-value-edit.component';
import { TradeUserItemComponent } from '@app/+trades/shared/components/trade-user-item/trade-user-item.component';
import { CustomerFilterListPipe } from '@app/+trades/shared/pipes/customer-filter-list.pipe';
import { far } from '@fortawesome/free-regular-svg-icons';
import { SVGSpriteModule } from '@app/svg-sprite/svg-sprite.module';
import { CompetenciesComponent } from './components/trade-grid-cell-types/competencies/competencies.component';
import { CustomersComponent } from './components/trade-grid-cell-types/customers/customers.component';
import { WinnersProvidersComponent } from './components/trade-grid-cell-types/winners-providers/winners-providers.component';
import { FileManagerSearchModule } from '@app/file-manager/components/file-manager-search/file-manager-search.module';
import { FileManagerModalUserInfoModule } from '@app/file-manager/components/file-manager-modal-user-info/file-manager-modal-user-info.module';
import { LineClampModule } from '@app/shared/modules/line-clamp/line-clamp.module';
import { TemplateCreateModalComponent } from './components/template-create-modal/template-create-modal.component';
import { CompetenceMapViewModule } from '@app/+competence-map/competence-shared/competence-map/competence-map-view.module';
import { TreeCheckboxListComponent } from '@app/+trades/shared/components/trade-card-grid/header-cell-filters/tree-checkbox-list/tree-checkbox-list.component';
import { SearchModule } from '@app/shared/modules/search/search.module';
import { DoubleDateTimePickerFilterComponent } from '@app/+trades/shared/components/trade-card-grid/header-cell-filters/double-date-time-picker-filter/double-date-time-picker-filter.component';
import { CompetenceMapModule } from '@app/+competence-map/competence-map.module';
import { HighlightSearchModule } from '@app/shared/modules/highlight/highlight-search.module';
import { CircularProgressComponent } from './components/circular-progress/circular-progress.component';
import { ProfileModule } from '@app/profile/profile.module';
import { AdditionalFiltersTemplateComponent } from './components/additional-filters-template/additional-filters-template.component';
import { WeightInputDirective } from '@app/+trades/shared/directives/weight-input.directive';
import { NgxMaskModule } from 'ngx-mask';
import { DateFormatPipe } from '@app/shared/pipes/date-format.pipe';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    SharedModule,
    FontAwesomeModule,
    TreeModule,
    ClickOutsideModule,
    NgbModule,
    TextMaskModule,
    RouterModule,
    SVGSpriteModule,
    FileManagerSearchModule,
    FileManagerModalUserInfoModule,
    LineClampModule,
    CompetenceMapViewModule,
    SearchModule,
    CompetenceMapModule,
    HighlightSearchModule,
    ProfileModule,
    NgxMaskModule,
    DateFormatPipe,
    ReactiveFormsModule,
  ],
  declarations: [
    CustomerCompanyInfoComponent,
    ReportAccessedUsersComponent,
    ReportReputationFiltersComponent,
    ReportNotesComponent,
    InviteStatusesFilterComponent,
    CustomersFilterComponent,
    DocumentListComponent,
    SupplierCompanyInfoComponent,
    LogoUploadComponent,
    SupplierLotsComponent,
    SupplierLotComponent,
    SupplierLotCriteriaViewComponent,
    TradeGridCellComponent,
    TradeGridHeaderCellComponent,
    TradeGridOperationsComponent,
    TradesStatusLineLineComponent,
    TradeCardTabItemComponent,
    TradeCardHeaderComponent,
    TradeCardControlsComponent,
    DualListUsersComponent,
    TradeCardGridComponent,
    NotesListComponent,
    TradeUserItemComponent,
    DiffDatesPipe,
    TradeUsersSortPipe,
    TradeCustomersSortPipe,
    CustomerFilterListPipe,
    CriteriaDateFormatPipe,
    CriteriaValueComponent,
    CriteriaRangeValueComponent,
    CriteriaViewComponent,
    SelectProviderFilterComponent,
    CriteriaViewValueEditComponent,
    CompetenciesComponent,
    CustomersComponent,
    WinnersProvidersComponent,
    TemplateCreateModalComponent,
    TreeCheckboxListComponent,
    DoubleDateTimePickerFilterComponent,
    CircularProgressComponent,
    SupplierLotCriteriaComponent,
    WeightInputDirective,
    AdditionalFiltersTemplateComponent,
    TreeCheckboxListComponent,
    DoubleDateTimePickerFilterComponent,
  ],
  providers: [TradeCardDataProtectorGuard, TradeUsersSortPipe, TradeCustomersSortPipe],
  exports: [
    CustomerCompanyInfoComponent,
    ReportAccessedUsersComponent,
    ReportReputationFiltersComponent,
    ReportNotesComponent,
    InviteStatusesFilterComponent,
    CustomersFilterComponent,
    DocumentListComponent,
    SupplierCompanyInfoComponent,
    LogoUploadComponent,
    SupplierLotsComponent,
    SupplierLotComponent,
    SupplierLotCriteriaViewComponent,
    TradeGridCellComponent,
    TradeGridHeaderCellComponent,
    TradeGridOperationsComponent,
    TradesStatusLineLineComponent,
    TradeCardTabItemComponent,
    TradeCardHeaderComponent,
    TradeCardControlsComponent,
    DualListUsersComponent,
    TradeCardGridComponent,
    NotesListComponent,
    TradeUserItemComponent,
    DiffDatesPipe,
    TradeUsersSortPipe,
    TradeCustomersSortPipe,
    CustomerFilterListPipe,
    SharedModule,
    FontAwesomeModule,
    TreeModule,
    ClickOutsideModule,
    NgbModule,
    TextMaskModule,
    NgSelectModule,
    SelectProviderFilterComponent,
    CriteriaViewValueEditComponent,
    FileManagerSearchModule,
    FileManagerModalUserInfoModule,
    CompetenciesComponent,
    CustomersComponent,
    TemplateCreateModalComponent,
    CircularProgressComponent,
    WeightInputDirective,
    AdditionalFiltersTemplateComponent,
    TreeCheckboxListComponent,
    DoubleDateTimePickerFilterComponent,
  ],
})
export class TradesSharedModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(far);
    library.addIcons(
      faCrown,
      faArrowUp,
      faArrowDown,
      faUsers,
      faShoppingCart,
      faAngleLeft,
      faAngleRight,
      faAngleDown,
      faFileAlt,
      faListOl,
      faUndoAlt,
      faFileExcel,
      faLevelDownAlt,
      faCheckSquare,
      faLongArrowAltDown,
      faLongArrowAltUp,
      faExpandArrowsAlt,
      faCopy,
      faClipboard,
      faFilePdf,
      faEnvelope,
      faCommentSlash,
      faToggleOff,
      faLock
    );
  }
}
