<div *ngIf="card" class="trade-card-header mt-5">
  <div class="trade-card-header__title">
    {{ tab.title }}
  </div>
  <div class="trade-card-header__controls">
    <ng-container *ngIf="tab.url !== TradeCardTabsRoutes.REPORT; else reportIcons">
      <button class="icon-button" (click)="openHelp()">
        <app-svg-icon [width]="16" [height]="16" name="note"></app-svg-icon>
      </button>

      <a (click)="navigateToChat()">
        <app-svg-icon name="comment" width="18"></app-svg-icon>
      </a>
    </ng-container>

    <ng-template #reportIcons>
      <a (click)="saveAsPdf()">
        <app-svg-icon name="save" [width]="18"></app-svg-icon>
      </a>

      <a (click)="print()">
        <app-svg-icon name="print" [width]="18"></app-svg-icon>
      </a>

      <a (click)="navigateToChat()">
        <app-svg-icon name="comment" width="18"></app-svg-icon>
      </a>

      <button class="icon-button" (click)="openHelp()">
        <app-svg-icon [width]="18" name="note"></app-svg-icon>
      </button>
    </ng-template>

    <ng-container *ngIf="tab.hasOperations">
      <a *ngIf="canEdit && !tab.isEdit" [class.disabled]="isDisableEditing" (click)="edit()">
        <app-svg-icon name="pencil_alt" width="18"></app-svg-icon>
      </a>

      <ng-container *ngIf="isPaleLockIcon; else lockWithTooltip">
        <a>
          <app-svg-icon class="disabled" name="lock" width="14" height="23"></app-svg-icon>
        </a>
      </ng-container>

      <ng-template #lockWithTooltip>
        <a
          *ngIf="!canEdit && tab.id !== TradeCardTabsRoutes.INVITE_SUPPLIERS"
          ngbTooltip="Раздел редактируется {{ (tab.isLocked ? tab.lockedUserInfo : card?.owner) | initials }}"
        >
          <app-svg-icon name="lock" width="14" height="23"></app-svg-icon>
        </a>
      </ng-template>
    </ng-container>

    <a
      *ngIf="tab.id === TradeCardTabsRoutes.SEARCH_SUPPLIERS"
      class="margin-l-10"
      container="body"
      placement="top"
      ngbTooltip="Количество потенциальных поставщиков"
    >
      {{ isSearchSuppliersDisabled ? tradeCard.number_potential_providers : (potentialSuppliersCount$ | async) }}
    </a>
  </div>
</div>
