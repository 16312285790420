<div class="kt-sbets-prop-right-inset"
     #criteriaContainer
     [ngClass]="{'no-trade': !isTrades, 'expanded': criteria.expanded}">

  <div class="kt-sbets-prop-right-h1"
       [class.kt-bets-trades]="isTrades">
    <b [ngbTooltip]="criteria.title" [placement]="'bottom'">{{criteria.title}}</b>
  </div>
  <div *ngIf="!isTrades"><b>Вес</b></div>
  <div *ngIf="!isTrades"><b>Комментарий</b></div>
  <div class="new-line" *ngIf="isTrades"><b>Б. (лид) - место</b></div>
  <div *ngIf="isTrades" class="text-center"><b>Знач. (лид)</b></div>
  <div *ngIf="!isTrades"><b>{{lot.nomenclatures[0]?.criteria[cIndex]?.unit?.name || '&nbsp;'}}</b></div>
  <div>
    <b *ngIf="isTrades">Вес</b>
  </div>
  <div>
    <b *ngIf="isTrades">Комментарий</b>
  </div>

  <ng-container *ngFor="let nomenclature of lot.extra.nomenclatures; index as cnIndex">
    <div *ngIf="isTrades" class="kt-sbets-prop-new-line kt-sbets-cell" #rateCell>
      {{card.rating.lots[lotIndex]?.nomenclatures[cnIndex].criteria[cIndex].points_rating_criterion | ratingValue}}
      &nbsp;
      ({{card.rating.lots[lotIndex]?.nomenclatures[cnIndex].criteria[cIndex].points_leader_rating_criterion | ratingValue}})
      &nbsp;-&nbsp;
      {{card.rating.lots[lotIndex]?.nomenclatures[cnIndex].criteria[cIndex].place_rating_criterion | ratingValue}}.
    </div>
    <div *ngIf="criteria.type === criteriaTypes.NUMBERS || criteria.type === criteriaTypes.DATES"
         class="d-flex" #valueCell>
      <app-criteria-range-value
        [vector]="criteriaVectors.LESS_BETTER"
        [direction]="nomenclature.criteria[cIndex].best_direction"
        [value]="nomenclature.criteria[cIndex].min"
        [type]="criteria.type"
      >
      </app-criteria-range-value>
      <app-criteria-view-value-edit
        [form]="form"
        *ngIf="!readonly && criteria.type === criteriaTypes.NUMBERS"
        [type]="criteriaTypes.NUMBERS"
        [min]="nomenclature.criteria[cIndex].min"
        [max]="nomenclature.criteria[cIndex].max"
        [value]="nomenclature.criteria[cIndex].value"
        name="value_{{lotIndex}}{{cnIndex}}{{cIndex}}"
        (changeEvent)="valueChange($event, nomenclature.criteria[cIndex])"
      >

      </app-criteria-view-value-edit>
      <app-criteria-view-value-edit
        [form]="form"
        *ngIf="!readonly && criteria.type === criteriaTypes.DATES"
        [type]="criteriaTypes.DATES"
        [min]="nomenclature.criteria[cIndex].min"
        [max]="nomenclature.criteria[cIndex].max"
        [value]="nomenclature.criteria[cIndex].value"
        name="value_{{lotIndex}}{{cnIndex}}{{cIndex}}"
        (changeEvent)="valueChange($event, nomenclature.criteria[cIndex])"
      ></app-criteria-view-value-edit>
      <ng-container *ngIf="readonly">
        <b>
          <app-criteria-value [type]="criteria.type" [value]="nomenclature.criteria[cIndex].value "></app-criteria-value>
        </b>
        &nbsp;
      </ng-container>
      <ng-container *ngIf="isTrades">
        (
        <app-criteria-value
          [type]="criteria.type"
          [value]="card.rating.lots[lotIndex]?.nomenclatures[cnIndex].criteria[cIndex]?.value_leader_rating_criterion">
        </app-criteria-value>
        )
        &nbsp;
      </ng-container>
      <app-criteria-range-value
        [vector]="criteriaVectors.MORE_BETTER"
        [direction]="nomenclature.criteria[cIndex].best_direction"
        [value]="nomenclature.criteria[cIndex].max"
        [type]="criteria.type"
      >
      </app-criteria-range-value>
    </div>

    <div *ngIf="criteria.type === criteriaTypes.YES_NO" #valueCell
    >
      <app-criteria-view-value-edit
        [form]="form"
        *ngIf="!readonly"
        [type]="criteriaTypes.YES_NO"
        [value]="nomenclature.criteria[cIndex].value"
        name="value_{{lotIndex}}{{cnIndex}}{{cIndex}}"
        (changeEvent)="valueChange($event, nomenclature.criteria[cIndex])"
      ></app-criteria-view-value-edit>
      <sup>
        <fa-icon
          [icon]="['fas', 'arrow-down']"
          [ngClass]="{'color-grey': nomenclature.criteria[cIndex].best_direction !== criteriaVectors.LESS_BETTER}">
        </fa-icon>
      </sup>
      &nbsp;
      <span class="trigger-text color-grey"
            [ngClass]="{'active': nomenclature.criteria[cIndex].value === 'false', readonly: readonly}"
            (click)="setValue('false', nomenclature.criteria[cIndex])">Нет</span>
      &nbsp;
      <span class="trigger-text color-grey"
            [ngClass]="{'active': nomenclature.criteria[cIndex].value === 'true', readonly: readonly}"
            (click)="setValue('true', nomenclature.criteria[cIndex])">Да</span>
      &nbsp;
      <sup>
        <fa-icon
          [icon]="['fas', 'arrow-up']"
          [ngClass]="{'color-grey': nomenclature.criteria[cIndex].best_direction !== criteriaVectors.MORE_BETTER}">
        </fa-icon>
      </sup>
    </div>
    <div class="kt-sbets-cell">{{nomenclature.criteria[cIndex].weight | number: '1.0-2'}}</div>
    <div class="criteria-comment kt-sbets-cell" [ngbTooltip]="nomenclature.criteria[cIndex].comment">
      {{nomenclature.criteria[cIndex].comment || '-'}}</div>
  </ng-container>
</div>
<a class="criteria-expander" (click)="toggleCriteriaTrade(criteria)" *ngIf="isTrades">
  <fa-icon [icon]="['fas', criteria.expanded ? 'angle-double-left' : 'angle-double-right']"></fa-icon>
</a>
