import { Directive, ElementRef, HostListener, Input, OnChanges } from '@angular/core';
import { FormControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[onlyLetter]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: OnlyLetterDirective,
      multi: true,
    },
  ],
})
export class OnlyLetterDirective implements OnChanges {
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'Control',
    'v',
    'ArrowLeft',
    'ArrowRight',
    'Delete',
  ];

  @Input() onlyLetter: boolean = true;
  @Input() isSpace: boolean = false;

  private regexPreventInput: RegExp;
  private rgxValidation: RegExp;

  constructor(private el: ElementRef) {
    this.regexPreventInput = this.getReqExp();
    this.rgxValidation = this.getReqExp();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    const selected = this.getSelectedText(event.target);

    if (next && !this.regexPreventInput.test(next) && !selected) {
      event.preventDefault();
      return;
    }
  }

  ngOnChanges() {
    this.regexPreventInput = this.getReqExp();
    this.rgxValidation = this.getReqExp();
  }

  validate(c: FormControl): { [key: string]: { valid: boolean } } {
    const v = c.value;
    const invalid = { onlyLetter: { valid: false } };

    if (v && !this.rgxValidation.test(v)) {
      return invalid;
    }

    return null;
  }

  private getReqExp(): RegExp {
    let baseChars = 'a-zA-Zа-яА-Я';
    if (!this.onlyLetter) {
      baseChars += '0-9';
    }
    if (this.isSpace) {
      baseChars += ' ';
    }
    return new RegExp(`^[${baseChars}]*$`);
  }

  private getSelectedText(elem: any): string | null {
    if (elem.tagName === 'TEXTAREA' || (elem.tagName === 'INPUT' && elem.type === 'text')) {
      return elem.value.substring(elem.selectionStart, elem.selectionEnd);
    }
    return null;
  }
}
