import { ChatSectionService } from '@app/chat/services/chat-section.service';
import { ChatService } from '@app/chat/services/chat.service';
import { takeUntil } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ChatSection } from '@app/chat/models/chat.model';
import { ChatSectionsEnum } from '@app/chat/constants/chat-sections.constants';
import { User } from '@app/shared/models/user.model';

@Component({
  selector: 'app-chat-section-select',
  templateUrl: './chat-section-select.component.html',
  styleUrls: ['./chat-section-select.component.scss'],
})
export class ChatSectionSelectComponent implements OnInit, OnDestroy {
  @Input() isModalView: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public chatSections: ChatSection[] = [];
  user: User;
  chatSectionsEnum = ChatSectionsEnum;
  protected chatSectionSelected: ChatSection;

  constructor(private chatSectionService: ChatSectionService, private chatService: ChatService) {}

  ngOnInit() {
    this.chatSections = Object.values(this.chatSectionService.chatSections);

    this.chatSectionService.chatSectionSelectedChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((chatSection) => {
      this.chatSectionSelected = chatSection;
    });

    this.chatService.widgetMessageCounterChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.chatSections = Object.values(this.chatSectionService.chatSections);
    });
  }

  onSectionSelect(chatSection: ChatSection) {
    this.chatSectionService.setChatSectionSelected(chatSection);
  }

  trackByName(_: number, section: ChatSection) {
    return section.name;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
