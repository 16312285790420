import { Pipe, PipeTransform } from '@angular/core';
import { BACKEND_DATE_FORMAT } from '@app/shared/constants/date.constants';
import * as moment from 'moment';

@Pipe({
  name: 'criteriaDateFormat',
})
export class CriteriaDateFormatPipe implements PipeTransform {
  transform(value: Date | string, args?: any): any {
    if (!value) {
      return '-';
    }
    return moment(value).format(BACKEND_DATE_FORMAT);
  }
}
