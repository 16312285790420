import { Component, OnInit } from '@angular/core';

import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';

import { ViewModeEnum } from '@app/file-manager/models/view-mode.enum';
import { BufferInterface, BufferTypeEnum } from '@app/file-manager/models/buffer.model';
import { FileExtensionInterface } from '@app/file-manager/models/file-extension.interface';
import { FileManagerSpaceInterface } from '@app/file-manager/models/file-manager-space.interface';
import { UserFile } from '@app/file-manager/models/user-file.model';

import { DestroyService } from '@app/services/destroy.service';
import { AuthService } from '@app/shared/services/auth.service';
import { FileManagerService } from '@app/file-manager/services/file-manager.service';
import { FileManagerDataService } from '@app/file-manager/services/file-manager-data.service';

import { FileManagerModalFilePreviewComponent } from '../file-manager-modal-file-preview/file-manager-modal-file-preview.component';
import { OPEN_DELAY_TOOLTIP } from '@app/file-manager/constants/file-manager-base.constants';
import { FileManagerModalUserInfoComponent } from '@app/file-manager/components/file-manager-modal-user-info/file-manager-modal-user-info.component';
import { FileManagerModalHelpComponent } from '@app/file-manager/components/file-manager-modal-help/file-manager-modal-help.component';

@Component({
  template: '',
  providers: [DestroyService],
})
export abstract class FileManagerBaseComponent implements OnInit {
  fileManagerSpace$: Observable<FileManagerSpaceInterface>;
  viewMode: ViewModeEnum = ViewModeEnum.GRID;

  files: UserFile[] = [];
  searchAreaFiles: UserFile[] = [];

  baseFileExtensions: FileExtensionInterface;
  current: UserFile;
  selectedFiles: UserFile[] = [];
  bufferFiles: Partial<BufferInterface> | null = null;

  currentFolderSelected: UserFile;
  currentSelected: UserFile;
  currentOpened: UserFile;
  currentRootFile: UserFile;

  breadcrumbsTreeFiles: UserFile[] = [];

  fileManagerInitialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _searchQuery: string = '';
  private _isSetFileExtension: string[] = [];

  get openDelayTooltip(): number {
    return OPEN_DELAY_TOOLTIP;
  }

  get searchQuery(): string {
    return this._searchQuery;
  }

  get isSearch(): boolean {
    return !!this._searchQuery?.length || !!this._isSetFileExtension?.length;
  }

  get authUserId(): number {
    return +this.user?.user_id;
  }

  constructor(
    readonly user: AuthService,
    readonly modalService: NgbModal,
    readonly destroy$: DestroyService,
    readonly notify: NotificationsService,
    readonly fileManagerService: FileManagerService,
    readonly fileManagerDataService: FileManagerDataService
  ) {}

  ngOnInit(): void {
    this.updateSpaceInfo();
    this.initialize();
  }

  /**
   * Переключить вид отображения файлов
   * @private
   */
  changeView(): void {
    if (this.viewMode === ViewModeEnum.LIST) {
      this.viewMode = ViewModeEnum.GRID;
    } else {
      this.viewMode = ViewModeEnum.LIST;
    }
  }

  /**
   * Развернуть папку без выделения
   * @param file
   */
  toggleFolder(file: UserFile): void {
    const currentFile = this.findFile(file.id);
    if (currentFile?.hasFile()) {
      return;
    }
    currentFile.virtualOpened = !currentFile.virtualOpened;

    if (currentFile.virtualOpened && !this.isSearch) {
      this.getChildrenFiles(currentFile).pipe(takeUntil(this.destroy$)).subscribe();
    }
  }

  /**
   * @param searchString
   * @param extList
   */
  search(searchString: string = '', extList?: string[]): void {
    this._searchQuery = searchString;
    if (extList) {
      this._isSetFileExtension = extList;
    }

    if (this.isSearch) {
      this.fileManagerService
        .searchFiles(this.authUserId, searchString, this._isSetFileExtension)
        .pipe(
          tap(() => {
            this.searchAreaFiles = [];
            this.files = [];
          }),
          takeUntil(this.destroy$)
        )
        .subscribe((searchFiles) => {
          this.searchAreaFiles = this.parseSearchAreaResult(searchFiles);
          this.files = searchFiles;
        });
    } else {
      this.initialize();
    }
  }

  /**
   * Выделить файл подложкой
   * @param file
   */
  selectFile(file: UserFile): void {
    if (this.isSearch) {
      if (!file?.search_result) {
        this.notify.warn('Внимание', 'Данной папки (файла) нет среди результатов поиска');
      } else {
        this.currentSelected = file;
      }
      return;
    }
    this.currentSelected = file;

    if (!file?.hasFile()) {
      this.currentFolderSelected = file;
    }

    if (this.currentSelected?.hasRoot()) {
      // Переключение рутовой папки: открытие и смена рута в breadcrumbs
      this.currentOpened = file;
      this.breadcrumbsTreeFiles = this.treeItemsFromBottomToTop(this.currentOpened, false);
    }

    if (!this.currentSelected?.hasRoot() && this.currentSelected?.virtualParentLink !== this.currentOpened?.id) {
      const fileRoot = this.findFile(file?.virtualParentLink);
      if (!fileRoot?.hasFile() && !this.isSearch) {
        this.currentOpened = fileRoot;
        this.breadcrumbsTreeFiles = this.treeItemsFromBottomToTop(this.currentOpened, false);
      }
    }
  }

  /**
   * Выделить и открыть
   * @param file
   * @param isUpdate
   * @param selectedFile
   */
  openSelectFile(file: UserFile, isUpdate: boolean = true, selectedFile?: UserFile): void {
    if (this.isSearch && !file?.hasFile()) {
      return;
    }

    if (selectedFile) {
      this.currentSelected = selectedFile;
    } else {
      this.currentSelected = file;
    }
    if (!file?.hasFile()) {
      this.currentOpened = file;
      this.currentOpened.virtualOpened = true;
      this.breadcrumbsTreeFiles = this.treeItemsFromBottomToTop(this.currentOpened, false);
      if (file.virtualOpened && isUpdate) {
        this.getChildrenFiles(file).pipe(takeUntil(this.destroy$)).subscribe();
      }
      this.currentFolderSelected = file;
    } else {
      this.previewDocument(file);
    }
  }

  /**
   * Установка списка выделенных файлов/папок
   * @param list
   */
  setSelectFiles(list: UserFile[]): void {
    if (!list?.length || this.selectedFiles?.length !== this.bufferFiles?.files?.length) {
      this.resetBufferedFiles();
    }
    this.selectedFiles = list;
  }

  /**
   * Сбросить выбранные файлы
   * @private
   */
  resetSelectedFiles(): void {
    this.resetBufferedFiles();
    this.selectedFiles = [];
  }

  /**
   * Добавить выбранные файлы в буфер обмена
   */
  setBufferedFiles(bufferType: BufferTypeEnum): void {
    this.resetBufferedFiles();
    this.bufferFiles = {};
    this.bufferFiles.type = bufferType;
    this.bufferFiles.files = [...this.selectedFiles];
  }

  /**
   * Сбросить/очистить буфер обмена
   */
  resetBufferedFiles(): void {
    this.bufferFiles = null;
  }

  /**
   * Обновление информации о занятом пространстве
   * @private
   */
  updateSpaceInfo(): void {
    this.fileManagerSpace$ = this.fileManagerService.getFileManagerSpace(this.authUserId);
  }

  /**
   * Инициализация файлового менеджера
   * @private
   */
  initialize(): void {
    this.files = [];
    this.fileManagerInitialized$.next(false);
    forkJoin([
      this.fileManagerService.getFilesRoot(this.authUserId),
      this.fileManagerService.getFileManagerFileExtensions(),
    ])
      .pipe(
        tap(([rootFiles, _]) => {
          this.currentRootFile = rootFiles[0];
        }),
        switchMap(([rootFiles, fileExtensions]) => {
          this.files = rootFiles;
          this.baseFileExtensions = fileExtensions;
          return forkJoin(
            rootFiles.map((rootFile) => this.fileManagerService.getChildrenFiles(rootFile.id, rootFile.virtualShared))
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((firstLevelFiles) => {
        this.files.forEach((rootFile, index) => {
          if (rootFile.id > 0) {
            this.openSelectFile(rootFile, false);
          }
          rootFile.setChildren(firstLevelFiles[index]);
          rootFile.virtualOpened = true;
        });
        this.fileManagerInitialized$.next(true);
      });
  }

  /**
   * Дерево поиска в линейную структуру без рекурсии для отображения в области файлов
   * @param files
   * @private
   */
  parseSearchAreaResult(files: UserFile[]): UserFile[] {
    const flattenUserFiles = files.reduce((userFiles, currentFile) => {
      return userFiles
        .concat(currentFile)
        .concat(currentFile.virtualChildren ? this.parseSearchAreaResult(currentFile.virtualChildren) : []);
    }, []);

    const result: UserFile[] = [];

    for (const file of flattenUserFiles) {
      if (file.search_result) {
        result.push(file);
      }
    }

    return result;
  }

  /**
   * Получить структуру вложений снизу ввехр и при наличии флага
   * TODO: обновить дочерние элементы в цепочке вложенности - протестировать надо ли!!!
   * @param file
   * @param list
   * @param isUpdateChildren
   */
  treeItemsFromBottomToTop(file: UserFile, isUpdateChildren: boolean = false, list: UserFile[] = []): UserFile[] {
    list.unshift(file);
    if (file?.hasRoot()) {
      if (isUpdateChildren) {
        this.getChildrenFiles(file).pipe(takeUntil(this.destroy$)).subscribe();
      }
      return list;
    } else {
      const parentFile = this.findFile(file?.virtualParentLink);
      if (isUpdateChildren) {
        this.getChildrenFiles(file).pipe(takeUntil(this.destroy$)).subscribe();
      }
      return this.treeItemsFromBottomToTop(parentFile, isUpdateChildren, list);
    }
  }

  /**
   * Получить вложенные файлы/папки
   * @param file
   * @param isSetCurrent
   * @private
   */
  getChildrenFiles(file: UserFile, isSetCurrent?: boolean): Observable<void> {
    return this.fileManagerService.getChildrenFiles(file.id, file.virtualShared).pipe(
      tap((childrenFiles: UserFile[]) => {
        if (isSetCurrent) {
          this.currentOpened = file;
        }
      }),
      map((childrenFiles: UserFile[]) => {
        const fileCurrent = this.findFile(file.id);
        fileCurrent.setChildren(childrenFiles);
      }),
      takeUntil(this.destroy$)
    );
  }

  /**
   * Открытие окна просмотра файла
   * @param file
   * @private
   */
  previewDocument(file: UserFile): void {
    const modal = this.modalService.open(FileManagerModalFilePreviewComponent, {
      centered: true,
      animation: true,
      windowClass: 'dc-modal modal-window',
      size: 'xl',
    });

    modal.componentInstance.files = [file];
  }

  /**
   * Найти файл и вернуть его по ссылке
   * из-за особенностей структуры класса UserFile используоется стек вместо рекурсии и всегда ищем по всему дереву
   * @param fileId
   * @private
   */
  findFile(fileId: number): UserFile {
    const nodes = this.files.slice();
    while (nodes.length) {
      const node = nodes.shift();
      if (node.id === fileId) {
        return node;
      }
      if (node.virtualChildren.length) {
        nodes.push(...node.virtualChildren);
      }
    }
  }

  /**
   * Открыть диалоговое окно с информцией о пользователе
   * @param file
   */
  showUserInfo(file: UserFile): void {
    const modal = this.modalService.open(FileManagerModalUserInfoComponent, {
      centered: true,
      windowClass: 'dc-modal modal-window dc-modal-user-card',
      animation: true,
    });
    modal.componentInstance.user = file.author;
  }

  /**
   * Открыть модальное окно помощи
   * TODO: нужно либо id либо строковую константу
   */
  openHelp(file?: UserFile): void {
    this.modalService.open(FileManagerModalHelpComponent, {
      centered: true,
      windowClass: 'dc-modal modal-window',
      animation: true,
      size: 'lg',
    });
  }

  /**
   * Получить структуру каталогов в которые вложен файл
   * если isRootDir - то путь будет возвращает до Root.папка первого уровня иначе до Root
   * @param file
   * @param isRootDir
   */
  getRootDirs(file: UserFile, isRootDir = true): UserFile[] {
    const result: UserFile[] = [];
    const findRootDir = (current: UserFile) => {
      if (current.virtualParentLink) {
        result.push(current);
        return findRootDir(this.findFile(current.virtualParentLink));
      } else {
        if (isRootDir) {
          result.push(current);
        }
        return;
      }
    };
    findRootDir(file);
    result.shift();
    return result;
  }
}
