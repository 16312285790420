import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { DCTreeItem } from '@app/+competence-map/models/competence-map.models';
import { Company } from '@app/shared/models/company.model';
import { Role } from '@app/shared/models/user.model';

export interface ChatContactsOnlyFilterData {
  roles: Role[];
  companies: (Company & { value?: boolean })[];
  goods: DCTreeItem[];
  services: DCTreeItem[];
  isUserHasTSO?: boolean;
  isUserNotHasTSO?: boolean;
}

@Injectable({
  providedIn: null,
})
export class ChatContactsOnlyFilterService {
  private readonly _selectedItems$ = new BehaviorSubject<ChatContactsOnlyFilterData>({
    roles: [],
    companies: [],
    goods: [],
    services: [],
  });

  readonly selectedItems$ = this._selectedItems$.asObservable();

  private readonly _temporarySelectedItems$ = new BehaviorSubject<ChatContactsOnlyFilterData>({
    roles: [],
    companies: [],
    goods: [],
    services: [],
  });

  readonly temporarySelectedItems$ = this._temporarySelectedItems$.asObservable();

  get selectedItems() {
    return this._selectedItems$.value;
  }

  set selectedItems(value: ChatContactsOnlyFilterData) {
    this._selectedItems$.next(value);
  }

  get temporarySelectedItems() {
    return this._temporarySelectedItems$.value;
  }

  set temporarySelectedItems(value: ChatContactsOnlyFilterData) {
    this._temporarySelectedItems$.next(value);
  }
}
