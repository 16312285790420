<ng-container *ngIf="files?.length || searchAreaFiles?.length; else SHOW_EMPTY_TEMPLATE">
  <ng-container [ngSwitch]="viewMode">
    <ng-container *ngSwitchCase="viewModeType.GRID">
      <ng-container *ngIf="!isSearchQuery; else SHOW_SEARCH_FILE_MANAGER_GRID">
        <div class="file-manager-area__grid">
          <app-file-manager-grid-item
            *ngFor="let file of files"
            [file]="file"
            [placeCall]="placeCall"
            [selectCurrentSelected]="isSelectCurrentSelected(file)"
            [selectCheckStatus]="isSelectedFileStatus(file)"
            [isCutFileStatus]="isCutFileStatus(file)"
            (onSelected)="selectUserFile(file, $event)"
            (onShareFile)="shareFile(file)"
            (onShowUserInfo)="showUserInfo(file)"
            (selectFile.single)="selectViewFile(file)"
            (selectFile.double)="openSelectFile(file)"
            [canSelect]="canSelectFile(file)"
            appTestLocator="area__file-{{ file?.id }}"
            class="file-manager-area__grid-item"
          ></app-file-manager-grid-item>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="viewModeType.LIST">
      <ng-container *ngIf="!isSearchQuery; else SHOW_SEARCH_FILE_MANAGER_LIST">
        <div class="file-manager-area__list">
          <app-file-manager-list-item
            *ngFor="let file of files"
            [file]="file"
            [placeCall]="placeCall"
            [folderCheck]="folderCheck"
            [selectCurrentSelected]="isSelectCurrentSelected(file)"
            [selectCheckStatus]="isSelectedFileStatus(file)"
            [isCutFileStatus]="isCutFileStatus(file)"
            (onSelected)="selectUserFile(file, $event)"
            (onShareFile)="shareFile(file)"
            (onShowUserInfo)="showUserInfo(file)"
            (selectFile.single)="selectViewFile(file)"
            (selectFile.double)="openSelectFile(file)"
            [canSelect]="canSelectFile(file)"
            appTestLocator="area__file-{{ file?.id }}"
            class="file-manager-area__list-item"
          ></app-file-manager-list-item>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #SHOW_EMPTY_TEMPLATE>
  <h4>Папка пуста</h4>
</ng-template>

<ng-template #SHOW_SEARCH_FILE_MANAGER_GRID>
  <div class="file-manager-area__search-wrapper">
    <div class="file-manager-area__title">Мои файлы</div>
    <div class="file-manager-area__grid">
      <app-file-manager-grid-item
        *ngFor="let file of searchMyAreaFiles"
        [file]="file"
        [placeCall]="placeCall"
        [searchQuery]="searchQuery"
        [selectCurrentSelected]="isSelectCurrentSelected(file)"
        [selectCheckStatus]="isSelectedFileStatus(file)"
        [isCutFileStatus]="isCutFileStatus(file)"
        (onSelected)="selectUserFile(file, $event)"
        (onShareFile)="shareFile(file)"
        (onShowUserInfo)="showUserInfo(file)"
        (selectFile.single)="selectViewFile(file)"
        (selectFile.double)="openSelectFile(file)"
        [canSelect]="canSelectFile(file)"
        appTestLocator="area__file-{{ file?.id }}"
        class="file-manager-area__grid-item"
      ></app-file-manager-grid-item>
    </div>
    <div class="file-manager-area__title">Файлы с открытым доступом</div>
    <div class="file-manager-area__grid">
      <app-file-manager-grid-item
        *ngFor="let file of searchSharedAreaFiles"
        [file]="file"
        [placeCall]="placeCall"
        [searchQuery]="searchQuery"
        [selectCurrentSelected]="isSelectCurrentSelected(file)"
        [selectCheckStatus]="isSelectedFileStatus(file)"
        [isCutFileStatus]="isCutFileStatus(file)"
        [openDelayTooltip]="openDelayTooltip"
        (onSelected)="selectUserFile(file, $event)"
        (onShareFile)="shareFile(file)"
        (onShowUserInfo)="showUserInfo(file)"
        (selectFile.single)="selectViewFile(file)"
        (selectFile.double)="openSelectFile(file)"
        [canSelect]="canSelectFile(file)"
        appTestLocator="area__file-{{ file?.id }}"
        class="file-manager-area__grid-item"
      ></app-file-manager-grid-item>
    </div>
  </div>
</ng-template>

<ng-template #SHOW_SEARCH_FILE_MANAGER_LIST>
  <div class="file-manager-area__search-wrapper">
    <div class="file-manager-area__title">Мои файлы</div>
    <div class="file-manager-area__list">
      <app-file-manager-list-item
        *ngFor="let file of searchMyAreaFiles"
        [file]="file"
        [placeCall]="placeCall"
        [searchQuery]="searchQuery"
        [selectCurrentSelected]="isSelectCurrentSelected(file)"
        [selectCheckStatus]="isSelectedFileStatus(file)"
        [isCutFileStatus]="isCutFileStatus(file)"
        (onSelected)="selectUserFile(file, $event)"
        (onShareFile)="shareFile(file)"
        (onShowUserInfo)="showUserInfo(file)"
        (selectFile.single)="selectViewFile(file)"
        (selectFile.double)="openSelectFile(file)"
        [canSelect]="canSelectFile(file)"
        appTestLocator="area__file-{{ file?.id }}"
        class="file-manager-area__list-item"
      ></app-file-manager-list-item>
    </div>
    <div class="file-manager-area__title">Файлы с открытым доступом</div>
    <div class="file-manager-area__list">
      <app-file-manager-list-item
        *ngFor="let file of searchSharedAreaFiles"
        [file]="file"
        [placeCall]="placeCall"
        [searchQuery]="searchQuery"
        [selectCurrentSelected]="isSelectCurrentSelected(file)"
        [selectCheckStatus]="isSelectedFileStatus(file)"
        [isCutFileStatus]="isCutFileStatus(file)"
        (onSelected)="selectUserFile(file, $event)"
        (onShareFile)="shareFile(file)"
        (onShowUserInfo)="showUserInfo(file)"
        (selectFile.single)="selectViewFile(file)"
        (selectFile.double)="openSelectFile(file)"
        [canSelect]="canSelectFile(file)"
        appTestLocator="area__file-{{ file?.id }}"
        class="file-manager-area__list-item"
      ></app-file-manager-list-item>
    </div>
  </div>
</ng-template>
