<form [formGroup]="form">
  <ng-container *ngIf="!isOnlyDate">
    <div class="date-wrapper">
      <div class="name-style">Начало периода</div>
      <div class="picker-wrapper">
        <app-datepicker
          controlNameDate="fromDate"
          [showError]="false"
          [dateRequire]="false"
          [form]="form"
        ></app-datepicker>
        <app-datepicker
          controlNameTime="fromTime"
          [showError]="false"
          [timeRequire]="false"
          [form]="form"
        ></app-datepicker>
      </div>
    </div>
    <div class="date-wrapper">
      <div class="name-style">Завершение периода</div>
      <div class="picker-wrapper">
        <app-datepicker
          controlNameDate="toDate"
          [showError]="false"
          [dateRequire]="false"
          [form]="form"
        ></app-datepicker>
        <app-datepicker
          controlNameTime="toTime"
          [showError]="false"
          [timeRequire]="false"
          [form]="form"
        ></app-datepicker>
      </div>
    </div>
  </ng-container>

  <div *ngIf="isOnlyDate" class="inline">
    <app-datepicker controlNameDate="fromDate" [showError]="false" [dateRequire]="false" [form]="form"></app-datepicker>
    <app-datepicker controlNameDate="toDate" [showError]="false" [dateRequire]="false" [form]="form"></app-datepicker>
  </div>
</form>

<ng-container *ngIf="withName">
  <app-multiple-livesearch-select
    #livesearchSelect
    [placeholder]="column.searchPlaceholder"
    [list]="filterSuggestions"
    title="Выделить все/снять выделение"
    (selectEvent)="select($event)"
  >
  </app-multiple-livesearch-select>
</ng-container>

<div class="action-btns">
  <button
    type="button"
    class="btn btn-dc-outline margin-r-30 btn-sm"
    appTestLocator="double-datepicker-filter__cancel-button"
    (click)="cancel()"
  >
    Отменить
  </button>
  <button
    type="button"
    class="btn btn-dc-primary btn-sm"
    appTestLocator="double-datepicker-filter__confirm-button"
    [disabled]="isApplyDisable"
    (click)="apply()"
  >
    Применить
  </button>
</div>
