import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DestroyService } from '@app/services/destroy.service';
import { StatusesEnum } from '@app/shared/constants/statuses.constants';
import { User } from '@app/shared/models/user.model';
import { AuthService } from '@app/shared/services/auth.service';
import { PersonalDataService } from '@app/shared/services/personal-data.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-my-tso-modal',
  templateUrl: './my-tso-modal.component.html',
  styleUrls: ['./my-tso-modal.component.scss'],
})
export class MyTsoModalComponent {
  @Input() myCurrentTso: User | null = null;
  @Input() userId: number | null = null;

  isShowedChangedTso: boolean = false;
  isReallyRemoving: boolean = false;
  dateOffSet = 0;

  @Output() onChangeEvent = new EventEmitter<User | null>();

  constructor(
    private personalDataService: PersonalDataService,
    private destroy: DestroyService,
    private authService: AuthService
  ) {
    this.dateOffSet = this.authService.getTimezoneOffset();
  }

  closeChangedTso() {
    this.isShowedChangedTso = false;
  }

  openChangedTso() {
    this.isShowedChangedTso = true;
  }

  changeSelection(tso: User) {
    this.myCurrentTso = tso;
    this.onChangeEvent.emit(tso);
  }

  removeTso() {
    this.personalDataService
      .bindTsoForUser(null)
      .pipe(takeUntil(this.destroy))
      .subscribe((user) => {
        this.onChangeEvent.emit(user.tso);
        this.isReallyRemoving = false;
      });
  }

  openConfirmationModal() {
    this.isReallyRemoving = true;
  }
}
