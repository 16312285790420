import { TradesService } from '@app/+trades/services/trades.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TradeCardTabConfig } from '@app/+trades/models/trade-card-tabs.model';
import { AccessRight } from '@app/+trades/types/trades.types';
import { TradeCardStatuses } from '@app/+trades/constants/trades.constants';
import { TradeCard } from '@app/+trades/models/trades.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileManagerModalHelpComponent } from '@app/file-manager/components/file-manager-modal-help/file-manager-modal-help.component';
import { TradeCardTabsRoutes } from '@app/+trades/constants/trade-tabs.constants';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { TradesHelper } from '@app/+trades/helpers/trades.helper';
import { Observable, of } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { DestroyService } from '@app/services/destroy.service';
import { SupplierTradeCard } from '@app/+trades/models/suppliers.model';
import { InviteSupplierStatuses } from '@app/+trades/constants/suppliers.constants';
import { ChatService } from '@app/chat/services/chat.service';

@Component({
  selector: 'app-trade-card-header',
  templateUrl: './trade-card-header.component.html',
  styleUrls: ['./trade-card-header.component.scss'],
  providers: [DestroyService],
})
export class TradeCardHeaderComponent {
  @Input() tab: TradeCardTabConfig = <TradeCardTabConfig>{};
  @Input() length: number;
  @Input() index: number;
  @Input() accessRight: AccessRight;
  @Input() card: TradeCard | SupplierTradeCard;
  @Input() canEdit: boolean;
  @Input() roomId: string;

  @Output() onEditClick = new EventEmitter<TradeCardTabConfig>();
  @Output() savePdf = new EventEmitter();
  @Output() printPdf = new EventEmitter();

  potentialSuppliersCount$: Observable<number> = this.tradesService.haveChanges$.pipe(
    switchMap(() => {
      if (this.tradeCard?.providers_filter?.data?.length) {
        const nodes = TradesHelper.convertTreeCompetenciesToTradeCompetencies(
          this.tradeCard.providers_filter.data,
          this.card.id
        );
        return this.competenceService
          .getProviders(TradesHelper.buildPotentialProvidersPayload(this.tradeCard, nodes))
          .pipe(map((users) => users.length));
      } else {
        return of(0);
      }
    })
  );
  protected readonly TradeCardTabsRoutes = TradeCardTabsRoutes;

  get tradeCard() {
    return this.isSupplierTradeCard(this.card) ? this.card.trade : this.card;
  }

  get isDisableEditing() {
    return (
      [TradeCardStatuses.ARCHIVE, TradeCardStatuses.PRE_TRADE, TradeCardStatuses.TRADE].includes(
        this.tradeCard.status
      ) && this.tab.id === TradeCardTabsRoutes.TERM_OF_BIDDING
    );
  }

  get isPaleLockIcon(): boolean {
    if (this.isSupplierTradeCard(this.card) && this.card.status !== InviteSupplierStatuses.ACCEPTED) {
      return true;
    }

    switch (this.tradeCard.status) {
      case TradeCardStatuses.ARCHIVE:
        switch (this.tab.id) {
          case TradeCardTabsRoutes.EMPLOYEES_OPTIONS:
          case TradeCardTabsRoutes.SEARCH_SUPPLIERS:
          case TradeCardTabsRoutes.NOTES:
          case TradeCardTabsRoutes.PURCHASE_SYSTEM:
          case TradeCardTabsRoutes.TERM_OF_BIDDING:
            return true;
        }
        break;

      case TradeCardStatuses.PRE_TRADE:
        switch (this.tab.id) {
          case TradeCardTabsRoutes.SEARCH_SUPPLIERS:
          case TradeCardTabsRoutes.INVITE_SUPPLIERS:
          case TradeCardTabsRoutes.TERM_OF_BIDDING:
            return true;
        }
        break;

      case TradeCardStatuses.TRADE:
        switch (this.tab.id) {
          case TradeCardTabsRoutes.SEARCH_SUPPLIERS:
          case TradeCardTabsRoutes.NOTES:
            return true;
          case TradeCardTabsRoutes.PURCHASE_SYSTEM:
            return !this.isSupplierTradeCard(this.card);
        }
        break;
      default:
        return false;
    }
  }

  get isSearchSuppliersDisabled() {
    return [TradeCardStatuses.ARCHIVE, TradeCardStatuses.PRE_TRADE, TradeCardStatuses.TRADE].includes(
      this.tradeCard.status
    );
  }

  constructor(
    private chatService: ChatService,
    private modalService: NgbModal,
    private competenceService: CompetenceService,
    private tradesService: TradesService
  ) {}

  edit() {
    this.onEditClick.emit(this.tab);
  }

  navigateToChat() {
    this.chatService.openChat(this.roomId);
  }

  print() {
    this.printPdf.emit();
  }

  saveAsPdf() {
    this.savePdf.emit();
  }

  /**
   * Открыть модальное окно помощи
   * TODO: нужно либо id либо строковую константу
   */
  openHelp(): void {
    this.modalService.open(FileManagerModalHelpComponent, {
      centered: true,
      windowClass: 'dc-modal modal-window',
      animation: true,
      size: 'lg',
    });
  }

  private isSupplierTradeCard(card: TradeCard | SupplierTradeCard): card is SupplierTradeCard {
    return 'trade' in this.card;
  }
}
