import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  TRADE_CARD_DATES,
  TradeCardDate,
  TradeCardStatuses,
  TradeDate,
  TradeStatus,
} from '@app/+trades/constants/trades.constants';
import { Column } from '@app/shared/models/table';
import { PrintHelper } from '@app/+trades/helpers/print.helper';
import { TradeColumnsKeys } from '@app/+trades/constants/trades-table.constants';
import { UserTradeSettings } from '@app/shared/models/user-settings.model';
import * as _ from 'lodash';
import { mapOrder } from '@app/shared/utils';
import { TradesRequestConfig } from '@app/+trades/models/trades.model';
import { FormControl } from '@angular/forms';

enum ChangeKey {
  Columns,
  TradeStatusesSort,
  InvitedStatusesSort,
}

enum DefaultChangeKey {
  Trade,
  Invite,
}

@Component({
  selector: 'app-trade-grid-operations',
  templateUrl: './trade-grid-operations.component.html',
  styleUrls: ['./trade-grid-operations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TradeGridOperationsComponent {
  private _isSupplier = false;
  get isSupplier(): boolean {
    return this._isSupplier;
  }
  @Input() set isSupplier(value: boolean) {
    this._isSupplier = value;
    this.tradeCardDates = value
      ? TRADE_CARD_DATES.filter((item) => [TradeDate.Start, TradeDate.End].includes(item.date))
      : TRADE_CARD_DATES;
  }

  private _initialColumns: Column<TradesRequestConfig>[] = [];
  private _columns: Column<TradesRequestConfig>[] = [];
  get columns(): Column<TradesRequestConfig>[] {
    return this._columns;
  }
  @Input() set columns(value: Column<TradesRequestConfig>[]) {
    this._initialColumns = value;
    this.setInitialColumnsState();
  }

  private _tradeStatuses: TradeStatus[] = [];
  private _initialTradeStatuses: TradeStatus[] = [];
  private _defaultTradeStatuses: TradeStatus[] = [];
  get tradeStatuses() {
    return this._tradeStatuses;
  }
  @Input() set tradeStatuses(value: TradeStatus[]) {
    this._defaultTradeStatuses = value;
  }

  private _inviteStatuses: any[] = [];
  private _initialInviteStatuses: any[] = [];
  private _defaultInviteStatuses: any[] = [];
  get inviteStatuses(): any[] {
    return this._inviteStatuses;
  }
  @Input() set inviteStatuses(value) {
    this._defaultInviteStatuses = value;
  }

  private _settings: UserTradeSettings;
  @Input() set tradeSettings(value: UserTradeSettings) {
    this._settings = value;
    if (value) {
      this.updateTradeStatusesAccordingToSettings(value);
      this.updateInvitedStatusesAccordingToSettings(value);
      this.updateSortFlagsAccordingToSettings(value);
      this.updateTradeCardDateAccordingToSettings(value);

      this.checkDefaultChanged(this._defaultInviteStatuses, this._initialInviteStatuses, DefaultChangeKey.Invite);
      this.checkDefaultChanged(this._defaultTradeStatuses, this._initialTradeStatuses, DefaultChangeKey.Trade);
    }
  }

  @Output() onPrintClick = new EventEmitter();
  @Output() onDownloadFileClick = new EventEmitter();
  @Output() onOptionsSaveClick = new EventEmitter<UserTradeSettings>();

  isTradeGridOptionsModalShowed = false;
  isTableHelperOpen = false;
  isSettingsHelperOpen = false;

  tradeCardDates: TradeCardDate[] = TRADE_CARD_DATES;
  columnKeys = TradeColumnsKeys;
  tradeCardStatusesEnum = TradeCardStatuses;
  selectedTradeCardDate: TradeCardDate = <TradeCardDate>{};
  isTradeStatusSortingEnabled = new FormControl(false);
  isInviteStatusSortingEnabled = new FormControl(false);

  readonly ChangeKey = ChangeKey;
  changeSet: Set<ChangeKey> = new Set();
  defaultChangeSet: Set<DefaultChangeKey> = new Set();

  get isAllColumnsChecked(): boolean {
    return this.columns.every((c) => !c.hidden);
  }

  get isTradeStatusesDefault(): boolean {
    return !this.defaultChangeSet.has(DefaultChangeKey.Trade);
  }

  get isInviteStatusesDefault(): boolean {
    return !this.defaultChangeSet.has(DefaultChangeKey.Invite);
  }

  get isFormChanged(): boolean {
    return (
      this.changeSet.has(ChangeKey.Columns) ||
      this.selectedTradeCardDate.date !== this._settings?.sortingByDate ||
      this.isTradeStatusesChanged ||
      this.isInviteStatusesChanged
    );
  }

  get isInviteStatusesChanged(): boolean {
    return (
      this.isInviteStatusSortingEnabled.value !== this._settings?.inviteStatusesSorting?.enabled ||
      (this.isInviteStatusSortingEnabled.value && this.changeSet.has(ChangeKey.InvitedStatusesSort))
    );
  }

  get isTradeStatusesChanged(): boolean {
    return (
      this.isTradeStatusSortingEnabled.value !== this._settings?.tradeStatusesSorting?.enabled ||
      (this.isTradeStatusSortingEnabled.value && this.changeSet.has(ChangeKey.TradeStatusesSort))
    );
  }

  showFilterModal() {
    this.isTradeGridOptionsModalShowed = true;
  }

  closeOptionsModal() {
    this.isTradeGridOptionsModalShowed = false;
    this.cancelChanges();
  }

  saveOptions() {
    this.changeSet.clear();
    this.onOptionsSaveClick.emit({
      columns: this._columns.filter((column) => !column.hidden).map((column) => column.id),
      tradeStatusesSorting: {
        enabled: this.isTradeStatusSortingEnabled.value,
        statuses: this._tradeStatuses.map((status) => status.id),
      },
      inviteStatusesSorting: {
        enabled: this.isInviteStatusSortingEnabled.value,
        statuses: this._inviteStatuses.map((status) => status.id),
      },
      sortingByDate: this.selectedTradeCardDate.date,
    });
  }

  print() {
    this.onPrintClick.emit();
  }

  moveInvitedStatus(event: MouseEvent, index: number, direction: 'down' | 'up') {
    if (event) {
      event.stopPropagation();
    }

    this.moveStatus(this._inviteStatuses, index, direction);
    this.checkDefaultChanged(this._defaultInviteStatuses, this._inviteStatuses, DefaultChangeKey.Invite);
    this.checkChanged(this._initialInviteStatuses, this._inviteStatuses, ChangeKey.InvitedStatusesSort);
  }

  moveTradeStatus(event: MouseEvent, index: number, direction: 'down' | 'up') {
    if (event) {
      event.stopPropagation();
    }

    this.moveStatus(this._tradeStatuses, index, direction);
    this.checkDefaultChanged(this._defaultTradeStatuses, this._tradeStatuses, DefaultChangeKey.Trade);
    this.checkChanged(this._initialTradeStatuses, this._tradeStatuses, ChangeKey.TradeStatusesSort);
  }

  moveStatus<T>(statuses: T[], index: number, direction: 'down' | 'up') {
    const canMove = direction === 'up' ? index > 0 : index < statuses.length - 1;

    if (canMove) {
      const newStatus = statuses.splice(index, 1)[0];
      const startIndex = index + (direction === 'up' ? -1 : 1);
      statuses.splice(startIndex, 0, newStatus);
    }
  }

  changeTradeDate(tradeDate: TradeCardDate) {
    this.selectedTradeCardDate = tradeDate;
  }

  resetTradeStatuses() {
    this._tradeStatuses = JSON.parse(JSON.stringify(this._defaultTradeStatuses));
    this.defaultChangeSet.delete(DefaultChangeKey.Trade);
    this.checkChanged(this._initialTradeStatuses, this._tradeStatuses, ChangeKey.TradeStatusesSort);
  }

  resetInviteStatuses() {
    this._inviteStatuses = JSON.parse(JSON.stringify(this._defaultInviteStatuses));
    this.defaultChangeSet.delete(DefaultChangeKey.Invite);
    this.checkChanged(this._initialInviteStatuses, this._inviteStatuses, ChangeKey.InvitedStatusesSort);
  }

  resetTradeCardDate() {
    this.selectedTradeCardDate = _.merge({}, this.tradeCardDates[0]);
  }

  clickDownloadPdf() {
    this.onDownloadFileClick.emit();
  }

  columnStateChange(column: Column<TradesRequestConfig>) {
    column.hidden = !column.hidden;
    this.checkChanged(this._initialColumns, this._columns, ChangeKey.Columns);
  }

  toggleAllColumns(checked: boolean): void {
    this._columns.forEach((column) => {
      if (!this.isColumnDisabled(column.id)) {
        column.hidden = !checked;
      }
    });

    this.checkChanged(this._initialColumns, this._columns, ChangeKey.Columns);
  }

  isColumnDisabled(id: string): boolean {
    return ([this.columnKeys.ID, this.columnKeys.SUPPLIER_TRADE_ID, this.columnKeys.TITLE] as string[]).includes(id);
  }

  resetColumns() {
    this._columns.forEach((column) => (column.hidden = false));
    this.checkChanged(this._initialColumns, this._columns, ChangeKey.Columns);
  }

  cancelChanges() {
    this.updateSortFlagsAccordingToSettings(this._settings);
    this.updateTradeCardDateAccordingToSettings(this._settings);

    if (this.changeSet.has(ChangeKey.Columns)) {
      this.setInitialColumnsState();
    }

    if (this.isTradeStatusesChanged) {
      this.updateTradeStatusesAccordingToSettings(this._settings);
    }

    if (this.isInviteStatusesChanged) {
      this.updateInvitedStatusesAccordingToSettings(this._settings);
    }

    this.checkDefaultChanged(this._defaultInviteStatuses, this._initialInviteStatuses, DefaultChangeKey.Invite);
    this.checkDefaultChanged(this._defaultTradeStatuses, this._initialTradeStatuses, DefaultChangeKey.Trade);

    this.changeSet.clear();
  }

  private setInitialColumnsState() {
    if (this._initialColumns?.length) {
      this._columns = this._initialColumns.map((column) => _.merge({}, column));
    }
  }

  private checkDefaultChanged<T>(defaultValue: T, localValue: T, changeDefaultKey: DefaultChangeKey) {
    _.isEqual(defaultValue, localValue)
      ? this.defaultChangeSet.delete(changeDefaultKey)
      : this.defaultChangeSet.add(changeDefaultKey);
  }

  private checkChanged<T>(initial: T, local: T, changeKey: ChangeKey) {
    _.isEqual(initial, local) ? this.changeSet.delete(changeKey) : this.changeSet.add(changeKey);
  }

  private updateTradeStatusesAccordingToSettings(settings: UserTradeSettings) {
    if (this._defaultTradeStatuses.length && settings.tradeStatusesSorting?.statuses) {
      const tradeStatuses = mapOrder(
        JSON.parse(JSON.stringify(this._defaultTradeStatuses)),
        settings.tradeStatusesSorting.statuses,
        'id'
      );
      this._initialTradeStatuses = JSON.parse(JSON.stringify(tradeStatuses));
      this._tradeStatuses = JSON.parse(JSON.stringify(tradeStatuses));
    }
  }

  private updateInvitedStatusesAccordingToSettings(settings: UserTradeSettings) {
    if (this._defaultInviteStatuses.length && settings.inviteStatusesSorting?.statuses) {
      const inviteStatuses = mapOrder(
        JSON.parse(JSON.stringify(this._defaultInviteStatuses)),
        settings.inviteStatusesSorting.statuses,
        'id'
      );
      this._initialInviteStatuses = JSON.parse(JSON.stringify(inviteStatuses));
      this._inviteStatuses = JSON.parse(JSON.stringify(inviteStatuses));
    }
  }

  private updateSortFlagsAccordingToSettings(settings: UserTradeSettings) {
    if (this.isTradeStatusSortingEnabled.value !== !!settings.tradeStatusesSorting?.enabled) {
      this.isTradeStatusSortingEnabled.setValue(!!settings.tradeStatusesSorting.enabled);
    }

    if (this.isInviteStatusSortingEnabled.value !== !!settings.inviteStatusesSorting?.enabled) {
      this.isInviteStatusSortingEnabled.setValue(!!settings.inviteStatusesSorting.enabled);
    }
  }

  private updateTradeCardDateAccordingToSettings(settings: UserTradeSettings) {
    if (settings?.sortingByDate && this.selectedTradeCardDate.date !== settings.sortingByDate) {
      this.selectedTradeCardDate =
        this.tradeCardDates.find((item) => item.date === settings.sortingByDate) || this.tradeCardDates[0];
    }
  }
}
