<div class="kt-report-sec6 blue-links">
  <h6>Фильтры по репутации</h6>
  <p>В разработке</p>
  <!--<p>ЧБС компании поставщика - "Моего АН",
    <fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon> <fa-icon class="small-equal" [icon]="['fas', 'greater-than-equal']"></fa-icon>5,
    <fa-icon [icon]="['fas', 'thumbs-down']"></fa-icon> <fa-icon class="small-equal" [icon]="['fas', 'less-than-equal']"></fa-icon> 2
  </p>
  <p>Оценки компании поставщика - "Моего АН",
    качество <fa-icon class="small-equal" [icon]="['fas', 'less-than-equal']"></fa-icon> 2,
    бюджет <fa-icon class="small-equal" [icon]="['fas', 'less-than-equal']"></fa-icon> 3,
    выполнение в срок <fa-icon class="small-equal" [icon]="['fas', 'less-than-equal']"></fa-icon> 1,
    оперативность <fa-icon class="small-equal" [icon]="['fas', 'less-than-equal']"></fa-icon> 1,
    вежливость <fa-icon class="small-equal" [icon]="['fas', 'less-than-equal']"></fa-icon> 1,
    общая оценка <fa-icon class="small-equal" [icon]="['fas', 'less-than-equal']"></fa-icon> 1
  </p>
  <p>Дата гос. регистрации организации - Начальная дата: 01.01.2006</p>
  <p>Дата гос. регистрации в ПК - Начальная дата: 01.01.2018</p>
  <p>ЧБС ответственного представителя поставщика - "Моего АН",
    <fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon> <fa-icon class="small-equal" [icon]="['fas', 'greater-than-equal']"></fa-icon> 5,
    <fa-icon [icon]="['fas', 'thumbs-down']"></fa-icon> <fa-icon class="small-equal" [icon]="['fas', 'less-than-equal']"></fa-icon> 2
  </p>-->
</div>
