<div
  class="file-preview"
  [ngbPopover]="attachMenu"
  popoverClass="preview-file-popover"
  placement="bottom"
  container="body"
>
  <!-- Превью для изображений -->
  <img *ngIf="isImageFile" [src]="file.url" alt="{{ file.name }}" class="file-preview__item file-preview__item-image" />

  <!-- Превью для PDF -->
  <div *ngIf="isPdfFile" class="file-preview__item file-preview__item-pdf">
    <iframe [src]="safeUrl"></iframe>
    <div class="iframe-overlay"></div>
  </div>

  <!-- Иконка для других файлов -->
  <ng-container *ngIf="!isImageFile && !isPdfFile" class="file-preview__item file-preview__item-others">
    <app-svg-icon
      [width]="file.hasFile() ? 60 : 80"
      [height]="file.hasFile() ? 80 : 60"
      [name]="file.hasFile() ? file.fileExtType().icon : 'folder'"
      class="blue-links"
    ></app-svg-icon>
  </ng-container>

  <p class="file-preview__name" title="{{ file.name }}">{{ file.name }}</p>
</div>

<button *ngIf="enableRemoveButton" (click)="removeFile.emit(file)" class="remove-button blue-links-button ml-1">
  <app-svg-icon name="times" width="14" height="14"></app-svg-icon>
</button>

<ng-template #attachMenu>
  <div class="attach-file-menu">
    <button (click)="onPreview()" class="blue-links-button">Просмотреть файл</button>
    <button (click)="onSave()" class="blue-links-button">Сохранить на компьютер</button>
    <button (click)="onSaveToFM()" class="blue-links-button">Сохранить в ФМ сервиса</button>
  </div>
</ng-template>
