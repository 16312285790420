import { Component, Input, OnInit } from '@angular/core';
import { UserCompetenceMapModalComponent } from '@app/+competence-map/competence-shared/user-competence-map/user-competence-map-modal/user-competence-map-modal.component';
import { ChatSectionsEnum, ContactsSortingNames, TechTypeEnum } from '@app/chat/constants/chat-sections.constants';
import { ChatRoom } from '@app/chat/models/chat.model';
import { ChatService } from '@app/chat/services/chat.service';
import { MessagesSelectService } from '@app/chat/services/messages-select.service';
import { DestroyService } from '@app/services/destroy.service';
import { UserCardComponent } from '@app/shared/components/user-card/user-card.component';
import { ROLES, RolesEnum } from '@app/shared/constants/roles.constants';
import { User } from '@app/shared/models/user.model';
import { AuthService } from '@app/shared/services/auth.service';
import { UserTypes } from '@app/shared/types/user.types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-chat-contact',
  templateUrl: './chat-contact.component.html',
  styleUrls: ['./chat-contact.component.scss'],
})
export class ChatContactComponent implements OnInit {
  @Input() isModalView: boolean = false;
  @Input() contact: ChatRoom;
  /* -------------------------------------------------------------------------- */
  // для дерева
  @Input() isTree = false;
  @Input() treeLevel: number = 1;
  /* -------------------------------------------------------------------------- */

  @Input() sortType: ContactsSortingNames;
  @Input() chatSectionName: ChatSectionsEnum | null = null;
  // Для загрузки тех групп и контактов
  @Input() techType: TechTypeEnum | null = null;
  techTypeEnum = TechTypeEnum;
  ChatSectionsEnum = ChatSectionsEnum;
  filterString: string;
  isExpanded = false;
  treeLastLevel = 0;
  listWidth = 310;

  roles = ROLES;
  rolesEnum = RolesEnum;

  userType: UserTypes;

  contactSelected: ChatRoom;

  isContactTooltipDisabled(name: string, role: string) {
    if (this.chatSectionName === ChatSectionsEnum.TECH) {
      return false;
    }
    return name.length + role.length < 30 || this.isTree;
  }

  onContactSelect(contact: ChatRoom) {
    if (this.isModalView) return;
    if (contact.filterDisabled) {
      this.notify.warn('Внимание', 'Данного пользователя нет среди результатов поиска');
      return;
    }
    this.chatService.setRoomSelected(contact);
    this.chatService.focusChanged.next(true);
    this.chatService.setNewCurrentTechType(contact.tech_section_type || null);
    this.messagesSelectService.clearMessageSelected();

    this.chatService.openChat(this.contact?.room_id);
  }

  constructor(
    private chatService: ChatService,
    private modalService: NgbModal,
    private destroy$: DestroyService,
    private authService: AuthService,
    private readonly notify: NotificationsService,
    private messagesSelectService: MessagesSelectService
  ) {}

  get isActiveClass() {
    if (this.isModalView) return false;
    return (
      this.contactSelected?.room_id === this.contact.room_id &&
      // используем не строгое сравнение так как в тех разделе нужно сравнивать
      // со строкой, а в остальных разделах значение могу быть null и undefined
      this.contactSelected?.tech_section_type == this.techType
    );
  }

  ngOnInit() {
    if (this.isTree) {
      this.isExpanded = this.chatService.isUserExpanded(+this.contact?.id);
    }

    this.contactSelected = this.chatService.getContactSelected();
    this.chatService.contactSelectedChanged.pipe(takeUntil(this.destroy$)).subscribe((contactSelected) => {
      this.contactSelected = contactSelected;
    });

    this.chatService.contactsFilterChanged.pipe(takeUntil(this.destroy$)).subscribe((filterString) => {
      this.filterString = filterString;
    });

    this.userType = this.authService.user_type;
  }

  public toggleRoomSelected(contact: ChatRoom) {
    this.messagesSelectService.toggleRoomSelected(contact);
  }

  public isRoomSelected(contact: ChatRoom) {
    return this.messagesSelectService.isRoomSelected(contact);
  }

  showCard(user: User): void {
    const modal = this.modalService.open(UserCardComponent, {
      windowClass: 'dc-modal modal-window dc-modal-user-card',
      animation: true,
    });
    (<UserCardComponent>modal.componentInstance).user = user;
    (<UserCardComponent>modal.componentInstance).closeButton = true;
  }

  showCompetence(user: User): void {
    const modal = this.modalService.open(UserCompetenceMapModalComponent, {
      centered: true,
      animation: true,
      windowClass: 'dc-modal modal-window',
      size: 'md',
    });

    (<UserCompetenceMapModalComponent>modal.componentInstance).user = user;
  }

  onContactsExpand() {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      this.chatService.addUserExpanded(Number(this.contact?.id));
    } else {
      this.chatService.removeUserExpanded(Number(this.contact?.id));
    }
  }

  trackByRoomId(index: number, room: ChatRoom): string {
    return room.room_id;
  }
}
