<ul class="list-unstyled">
  <li *ngFor="let status of statuses">
    <a>
      <input
        [id]="status.id"
        class="cbx-input"
        [(ngModel)]="status.checked"
        (ngModelChange)="change()"
        type="checkbox"
      />
      <label class="cbx-label" [for]="status.id">
        <span class="cbx-label-text">{{ status.label || inviteSuppliersLabels[status.id] }}</span>
      </label>
    </a>
  </li>
</ul>
