import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ChatSectionService } from '@app/chat/services/chat-section.service';
import { SectionUser } from '@app/shared/models/section-user.model';
import { cloneDeep } from 'lodash';
import { combineLatest, forkJoin, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { CMCatalogTypes } from '@app/+competence-map/constants/sections.constants';
import { onlyActiveItems } from '@app/+competence-map/helpers/competence-map.helpers';
import { DCTreeItem } from '@app/+competence-map/models/competence-map.models';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { DestroyService } from '@app/services/destroy.service';
import { ROLES, RolesEnum } from '@app/shared/constants/roles.constants';
import { Company } from '@app/shared/models/company.model';
import { Role, User } from '@app/shared/models/user.model';
import { UserTypes } from '@app/shared/types/user.types';
import { deepCopy } from '@app/shared/utils';
import { ChatSectionsEnum } from '../constants/chat-sections.constants';
import { ChatSection, ChatUserTree } from '../models/chat.model';
import { ChatService } from '../services/chat.service';
import { ChatContactsOnlyFilterTab } from './enums/chat-contacts-only-filter-tab';
import {
  ChatContactsOnlyFilterData,
  ChatContactsOnlyFilterService,
} from './services/chat-contacts-only-filter.service';
import { ContactsFilterPipe } from '@app/shared/pipes/contacts-filter.pipe';

@Component({
  selector: 'app-chat-contacts-only-filter',
  templateUrl: './chat-contacts-only-filter.component.html',
  styleUrls: ['./chat-contacts-only-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ChatContactsOnlyFilterService, ContactsFilterPipe],
})
export class ChatContactsOnlyFilterComponent implements OnInit {
  @Input() filterKey: string = 'allContacts';
  @Input() isGroupEditing: boolean = false;
  @Input() hiddenFilters: (keyof ChatContactsOnlyFilterData)[] = [];

  goods: DCTreeItem[];
  services: DCTreeItem[];

  Allgoods: DCTreeItem[];
  Allservices: DCTreeItem[];

  tabValuesByKey: Record<ChatContactsOnlyFilterTab, keyof ChatContactsOnlyFilterData> = {
    [ChatContactsOnlyFilterTab.Roles]: 'roles',
    [ChatContactsOnlyFilterTab.Company]: 'companies',
    [ChatContactsOnlyFilterTab.GoodsCompetence]: 'goods',
    [ChatContactsOnlyFilterTab.ServicesCompetence]: 'services',
  };

  readonly ChatContactsOnlyFilterTab = ChatContactsOnlyFilterTab;

  readonly TABS = Object.values(this.ChatContactsOnlyFilterTab);

  selectedTab = this.ChatContactsOnlyFilterTab.Roles;
  roles: {
    fullTitle?: string;
    roleValue: UserTypes;
    shortTitle: string;
    label: string;
    id: number;
    title: string;
    value: boolean;
    selected?: boolean;
  }[] = [];
  companies = Object.values(this.chatService.companies).map((company) => ({
    ...company,
    label: company.name,
    value: false,
  }));

  isUserHasTSO: boolean = true;
  isUserNotHasTSO: boolean = false;

  isOpened$ = this.chatService.contactsOnlyFiltersState$.pipe(map((state) => state[this.filterKey]));

  tabValues: Record<keyof ChatContactsOnlyFilterData, number> = {
    roles: 0,
    goods: 0,
    companies: 0,
    services: 0,
    isUserHasTSO: undefined,
    isUserNotHasTSO: undefined,
  };

  readonly isTechSection = this.chatSectionService.chatSectionSelectedChanged.pipe(
    map((currentSection) => {
      const isTechSection = currentSection.name === ChatSectionsEnum.TECH;

      if (isTechSection && this.isGroupEditing) {
        this.onUserHasTSOChanged(true);
      }

      return isTechSection;
    })
  );

  private currentUsers: User[];
  findedItemsInFilter: Record<keyof ChatContactsOnlyFilterData, Set<string | number> | boolean> = {
    roles: new Set(),
    companies: new Set(),
    goods: new Set(),
    services: new Set(),
    isUserHasTSO: undefined,
    isUserNotHasTSO: undefined,
  };

  private readonly reloadData$ = new Subject();

  get valuesByTabs() {
    return {
      [ChatContactsOnlyFilterTab.Roles]: this.tabValues.roles,
      [ChatContactsOnlyFilterTab.Company]: this.tabValues.companies,
      [ChatContactsOnlyFilterTab.GoodsCompetence]: this.tabValues.goods,
      [ChatContactsOnlyFilterTab.ServicesCompetence]: this.tabValues.services,
    };
  }

  constructor(
    private readonly destroy$: DestroyService,
    private readonly chatService: ChatService,
    private readonly competenceService: CompetenceService,
    private readonly chatContactsOnlyFilterService: ChatContactsOnlyFilterService,
    private readonly cdr: ChangeDetectorRef,
    private readonly contactsFilterPipe: ContactsFilterPipe,
    private chatSectionService: ChatSectionService
  ) {
    forkJoin([
      this.competenceService.getTree(CMCatalogTypes.GOODS),
      this.competenceService.getTree(CMCatalogTypes.SERVICES),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([goods, services]: DCTreeItem[][]) => {
        this.Allgoods = onlyActiveItems(deepCopy(goods));
        this.Allservices = onlyActiveItems(deepCopy(services));

        this.setAvailableData();

        this.setTabValues();

        this.reloadData$.next();
      });

    combineLatest([
      this.chatSectionService.chatSectionSelectedChanged.pipe(startWith('')),
      this.chatService.usersForContactsFilter$,
      this.reloadData$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([section, usersForContactsFilter]) => {
        let users: User[] | ChatUserTree;

        const currentSection =
          section && typeof section === 'object' ? section : this.chatSectionService.chatSectionSelected;

        if (usersForContactsFilter) {
          users = usersForContactsFilter as User[];
        } else {
          users =
            currentSection.name === ChatSectionsEnum.TECH
              ? this.parseUsersObjectInArray(this.chatService.getContacts())
              : this.chatService.getContacts();
        }

        const parsedUsers = Array.isArray(users) ? users : this.parseUsersObjectInArray(users);

        const usersIds = [...new Set(parsedUsers.map((user) => +user.id))];

        let tempUsers: User[] = [];

        usersIds.forEach((id) => {
          tempUsers.push(this.chatService.users[id]);
        });

        if (currentSection.name === ChatSectionsEnum.TECH) {
          tempUsers = users as User[];
        }

        this.currentUsers = tempUsers.filter(Boolean);

        if (usersIds.length) {
          const mapSections = new Map<'goods' | 'services', Set<number>>();
          const allSections = this.currentUsers
            .map((user) => user.competence_sections)
            .flat()
            .filter(Boolean);

          allSections.forEach((sectionItem) => {
            if (!mapSections.has(sectionItem.catalog)) {
              mapSections.set(sectionItem.catalog, new Set());
            }

            mapSections.get(sectionItem.catalog).add(sectionItem.id);
          });

          const availableSectionsMap = new Map<'goods' | 'services', SectionUser[]>();

          mapSections.forEach((value, key) => {
            value.forEach((id) => {
              if (!availableSectionsMap.has(key)) {
                availableSectionsMap.set(key, []);
              }

              const sectionItem = allSections.find((sec) => sec.id === id);

              if (section) {
                availableSectionsMap.get(key).push(sectionItem);
              }
            });
          });

          this.setAvailableData(currentSection, availableSectionsMap);
          this.clearFilter();
        } else {
          const cleanMap = new Map();

          cleanMap.set('goods', []);
          cleanMap.set('services', []);

          this.setAvailableData(currentSection, cleanMap);
          this.clearFilter();
        }
      });

    this.chatService.contactsOnlyFilterChanged.pipe(takeUntil(this.destroy$)).subscribe((filters) => {
      if (filters && !filters[this.filterKey]) {
        this.clearFilter();
      }
    });

    this.chatService.contactsOnlyFiltersState$.pipe(takeUntil(this.destroy$)).subscribe((filtersState) => {
      const currentFilters = this.chatService.contactsOnlyFilterChanged.value;
      const openedFilterKey = Object.keys(filtersState).filter((key) => filtersState[key])?.[0];

      if (currentFilters && openedFilterKey && currentFilters[openedFilterKey]) {
        this.setCheckboxesActive(currentFilters[openedFilterKey]);
        this.chatContactsOnlyFilterService.temporarySelectedItems = currentFilters[openedFilterKey];
      }
    });
  }

  ngOnInit() {
    if (this.hiddenFilters.length) {
      const tabs = this.TABS.filter((tab) => !this.hiddenFilters.includes(this.tabValuesByKey[tab]));
      this.onTabSelected(tabs[0]);
    }

    this.setAvailableData();
  }

  private setAvailableData(section?: ChatSection, availableSectionsMap?: Map<'goods' | 'services', SectionUser[]>) {
    const users = this.chatService.getContacts();

    const usersArray = this.parseUsersObjectInArray(users);

    this.setAvailableRoles(section);
    this.setAvailableCompetencies(availableSectionsMap);
    this.setAvailableCompanies(usersArray);

    this.cdr.markForCheck();
  }

  private setAvailableCompetencies(availableSectionsMap?: Map<'goods' | 'services', SectionUser[]>) {
    if (!availableSectionsMap || !this.Allgoods || !this.Allservices) {
      return;
    }

    const goods = new Map();
    const services = new Map();

    availableSectionsMap.forEach((competencies, catalog) => {
      const competenceMap = catalog === 'goods' ? goods : services;

      competencies.forEach((competence) => {
        if (!competenceMap.has(competence.id)) {
          competenceMap.set(competence.id, competence);
        }
      });
    });

    this.goods = this.filterItems(
      this.Allgoods,
      new Set(availableSectionsMap.get(CMCatalogTypes.GOODS)?.map((c) => c.id) || [])
    );

    this.services = this.filterItems(
      this.Allservices,
      new Set(availableSectionsMap.get(CMCatalogTypes.SERVICES)?.map((c) => c.id) || [])
    );
  }

  private filterItems<T extends { id: number; children?: T[] }>(items: T[], availableIds: Set<number>): T[] {
    return items
      .map((item) => ({
        ...item,
        children:
          item.children && item.children.length > 1
            ? this.filterItems(item.children, availableIds)
            : item.children?.length === 1 && availableIds.has(item.children[0].id)
            ? item.children
            : [],
      }))
      .filter((item) => {
        return availableIds.has(item.id) || item.children.length > 0;
      });
  }

  private parseUsersObjectInArray(users: ChatUserTree) {
    const flattenUsersArray: User[] = [];

    for (const key in users) {
      if ('id' in users[key]) {
        flattenUsersArray.push(users[key]);
      } else {
        for (const userKey in users[key]) {
          flattenUsersArray.push(users[key][userKey]);
        }
      }
    }

    return [...new Set(flattenUsersArray)];
  }

  private clearFilter() {
    ['roles', 'goods', 'companies', 'services'].forEach((item) => {
      if (this[item]) {
        this[item] = this[item].map((data: []) => ({ ...data, value: false, selected: false }));
      }
    });

    this.chatContactsOnlyFilterService.temporarySelectedItems = {
      roles: [],
      companies: [],
      goods: [],
      services: [],
      isUserHasTSO: undefined,
      isUserNotHasTSO: undefined,
    };

    this.isUserHasTSO = true;
    this.isUserNotHasTSO = false;

    this.setTabValues();
    this.calculateDisabledCheckboxes(true);
  }

  private setAvailableRoles(section?: ChatSection): void {
    section = section ? section : this.chatSectionService.chatSectionSelected;

    let ordering = [];

    if (section.name === ChatSectionsEnum.ADMIN) {
      ordering = [RolesEnum.ACCOUNTANT, RolesEnum.EXPERT, RolesEnum.PARTNER, RolesEnum.TSO];

      if (this.isGroupEditing) {
        this.hiddenFilters = [...this.hiddenFilters, 'companies'];
      }
    } else if (section.name === ChatSectionsEnum.HOLDING) {
      ordering = [RolesEnum.ADMIN_OF_USER, RolesEnum.ADMIN_OF_DIRECTION, RolesEnum.OPERATOR];
    } else if (section.name === ChatSectionsEnum.TECH) {
      ordering = [
        RolesEnum.PARTNER,
        RolesEnum.ADMIN_OF_DIRECTION,
        RolesEnum.ADMIN_OF_USER,
        RolesEnum.SUPERUSER,
        RolesEnum.ACCOUNTANT,
        RolesEnum.OPERATOR,
        RolesEnum.EXPERT,
        RolesEnum.TSO,
      ];
    } else {
      ordering = [RolesEnum.ADMIN_OF_DIRECTION, RolesEnum.ACCOUNTANT, RolesEnum.EXPERT, RolesEnum.PARTNER];
    }

    this.roles = ordering.map((roleValue, index) => {
      const role = ROLES.find((roleItem) => roleItem.value === roleValue);

      return {
        ...role,
        label: role.fullTitle || role.title,
        value: false,
        roleValue: role.value,
        id: index,
      };
    });
  }

  private setAvailableCompanies(usersArray: User[]) {
    if (!this.chatService.companies) {
      return;
    }

    const allCompanies = Object.values(this.chatService.companies).map((company) => ({
      ...company,
      label: company.name,
      value: false,
    }));

    const companiesIds = new Set(usersArray.map((user) => user?.company?.id).filter(Boolean));

    this.companies = allCompanies.filter((company) => companiesIds.has(company.id));
  }

  onTabSelected(tab: ChatContactsOnlyFilterTab) {
    this.selectedTab = tab;
  }

  onItemsSelected(items: Role[] | Company[] | DCTreeItem[], key: keyof ChatContactsOnlyFilterData) {
    let currentItemsTemp = cloneDeep(this.chatContactsOnlyFilterService.temporarySelectedItems);

    if (key === 'roles' || key === 'companies') {
      items = (items as (Role & { roleValue: UserTypes })[]).filter((item) => item.value);

      if (key === 'roles') {
        items = (items as (Role & { roleValue: UserTypes })[]).map((item) => ({ ...item, value: item.roleValue }));
      }
    } else {
      const selectedItems = this.getAllNodes(items as DCTreeItem[]);
      items = selectedItems.filter((item) => item.selected);
    }

    currentItemsTemp = { ...currentItemsTemp, [key]: items };

    this.chatContactsOnlyFilterService.temporarySelectedItems = currentItemsTemp;

    this.setTabValues();
    this.calculateDisabledCheckboxes();
  }

  private setTabValues() {
    const selected = this.chatContactsOnlyFilterService.temporarySelectedItems;

    this.tabValues = {
      roles: selected.roles.length,
      companies: selected.companies.length,
      services: selected.services.length,
      goods: selected.goods.length,
      isUserHasTSO: undefined,
      isUserNotHasTSO: undefined,
    };

    this.cdr.markForCheck();
  }

  close(saveSelectedItems: boolean) {
    if (saveSelectedItems) {
      const tempSelectedItems = cloneDeep(this.chatContactsOnlyFilterService.temporarySelectedItems);
      this.chatContactsOnlyFilterService.selectedItems = tempSelectedItems;

      this.chatService.setContactsOnlyFilter(tempSelectedItems, this.filterKey);
    }

    this.chatService.toggleContactsOnlyFilter(false, this.filterKey);
  }

  onUserHasTSOChanged(hasTSO: boolean) {
    if (hasTSO) {
      this.isUserHasTSO = !this.isUserHasTSO;
    } else {
      this.isUserNotHasTSO = !this.isUserNotHasTSO;
    }

    const currentItemsTemp = { ...this.chatContactsOnlyFilterService.temporarySelectedItems };

    currentItemsTemp.isUserHasTSO = this.isUserHasTSO;
    currentItemsTemp.isUserNotHasTSO = this.isUserNotHasTSO;

    this.chatContactsOnlyFilterService.temporarySelectedItems = currentItemsTemp;

    this.calculateDisabledCheckboxes();
  }

  readonlyFunction = (
    value:
      | { data: (Role & { roleValue: RolesEnum; disabled: boolean }) | DCTreeItem | (Company & { disabled: boolean }) }
      | (Role & { roleValue: RolesEnum; disabled: boolean })
      | DCTreeItem
      | (Company & { disabled: boolean })
  ) => {
    const { services, goods, companies, roles } = this.findedItemsInFilter as Record<
      keyof ChatContactsOnlyFilterData,
      Set<string | number>
    >;

    const item = 'data' in value ? value.data : value;

    if (services.size === 0 && goods.size === 0 && companies.size === 0 && roles.size === 0) {
      if ('disabled' in item) {
        item.disabled = false;
      }

      return false;
    }

    const findedItems = this.findedItemsInFilter as Record<keyof ChatContactsOnlyFilterData, Set<string | number>>;

    if (!item || !findedItems) {
      return;
    }

    if (typeof item === 'object' && 'catalog' in item) {
      return !findedItems[item.catalog].has(item.id);
    }
    if (typeof item === 'object' && 'roleValue' in item) {
      return !findedItems.roles.has(item.roleValue);
    }

    return !findedItems.companies.has((item as Company).id);
  };

  private getAllNodes(items: DCTreeItem[] = []): DCTreeItem[] {
    const res: DCTreeItem[] = [];

    const getChildren = (children: DCTreeItem[]) => {
      res.push(...children);
      children.forEach((child) => {
        if (child.children?.length) {
          getChildren(child.children);
        }
      });
    };

    items.forEach((item) => {
      res.push(item);
      if (item.children?.length) {
        getChildren(item.children);
      }
    });

    return res;
  }

  private calculateDisabledCheckboxes(firstInit = false) {
    const resultOfFilter: User[] = this.contactsFilterPipe.transform(
      this.currentUsers,
      this.chatContactsOnlyFilterService.temporarySelectedItems
    );

    if (!resultOfFilter) {
      return;
    }

    const newData: Record<keyof ChatContactsOnlyFilterData, Set<string | number>> = {
      roles: new Set(),
      companies: new Set(),
      goods: new Set(),
      services: new Set(),
      isUserHasTSO: undefined,
      isUserNotHasTSO: undefined,
    };

    const currentData = { ...this.findedItemsInFilter }[this.tabValuesByKey[this.selectedTab]];

    resultOfFilter.forEach((user) => {
      const { type, company, competence_sections, flags } = user;

      if (user.type === RolesEnum.PARTNER && flags.is_tso) {
        newData.roles.add(RolesEnum.TSO);
        newData.roles.add(user.type);
      } else {
        newData.roles.add(user.type);
      }

      if (company) {
        newData.companies.add(company.id);
      }

      competence_sections?.forEach((competenceSection: SectionUser & { parent: { id: number } }) => {
        newData[competenceSection.catalog].add(competenceSection.id);

        if (competenceSection.parent) {
          newData[competenceSection.catalog].add(competenceSection.parent.id);
        }
      });
    });

    if (!firstInit) {
      newData[this.tabValuesByKey[this.selectedTab]] = currentData as Set<string | number>;
    }

    this.findedItemsInFilter = newData;

    return resultOfFilter;
  }

  private setCheckboxesActive(filter: ChatContactsOnlyFilterData) {
    this.chatContactsOnlyFilterService.temporarySelectedItems = filter;

    this.roles = [...this.roles].map((role) => {
      const foundItemInFilter = filter.roles.find(
        (filterRole) => filterRole.fullTitle === role.fullTitle && filterRole.value
      );

      if (foundItemInFilter) {
        role.value = true;
      }

      return role;
    });

    this.companies = [...this.companies].map((company) => {
      const foundItemInFilter = filter.companies.find(
        (filterCompany) => filterCompany.id === company.id && filterCompany.value
      );

      if (foundItemInFilter) {
        company.value = true;
      }

      return company;
    });

    this.goods = [...this.goods].map((good) => {
      const foundItemInFilter = filter.goods.find((filterGood) => filterGood.id === good.id && filterGood.selected);

      if (foundItemInFilter) {
        good = foundItemInFilter;
      }

      return good;
    });

    this.services = [...this.services].map((service) => {
      const foundItemInFilter = filter.services.find(
        (filterGood) => filterGood.id === service.id && filterGood.selected
      );

      if (foundItemInFilter) {
        service = foundItemInFilter;
      }

      return service;
    });

    this.setTabValues();
    this.calculateDisabledCheckboxes(true);
  }
}
