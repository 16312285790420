import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { competenceMapRoutes } from './competence-map.routes';
import { MyCompetencesComponent } from './my-competences/my-competences.component';
import { MyCatalogComponent } from './my-competences/my-catalog/my-catalog.component';
import { MyCatalogFiltersComponent } from './my-competences/my-catalog/my-catalog-filters/my-catalog-filters.component';
import { MyCatalogSectionsComponent } from './my-competences/my-catalog/my-catalog-sections/my-catalog-sections.component';
import { MyCatalogTreeItemComponent } from './my-competences/my-catalog/my-catalog-tree-item/my-catalog-tree-item.component';
import { SliderDirective } from '@app/shared/directives/slider.directive';
import { SuppliersSelectionComponent } from './suppliers-selection/suppliers-selection.component';
import { PotencialSuppliersComponent } from './suppliers-selection/potencial-suppliers/potencial-suppliers.component';
import { SearchBarModule } from './search-bar/search-bar.module';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SVGSpriteModule } from '@app/svg-sprite/svg-sprite.module';
import { SharedModule } from '@app/shared/shared.module';
import { MyCatalogFilterTreeItemComponent } from './my-competences/my-catalog/my-catalog-filter-tree-item/my-catalog-filter-tree-item.component';
import { CompetenceSharedModule } from './competence-shared/competence-shared.module';
import { ExpertCompetencesComponent } from './my-competences/expert-competences/expert-competences.component';
import { UnsavedConfirmLeaveModule } from '@app/+competence-map/competence-shared/unsaved-confirm-leave/unsaved-confirm-leave.module';
import { CompetenceMapViewModule } from './competence-shared/competence-map/competence-map-view.module';
import {
  SERVICES_COMPETENCE_STATE,
  CompetenceDataService,
  GOODS_COMPETENCE_STATE,
} from './services/competence-data.service';
import { AdditionalFiltersComponent } from './suppliers-selection/additioanl-filters/additional-filters.component';
import { HighlightSearchModule } from '@app/shared/modules/highlight/highlight-search.module';
import { DateFormatPipe } from '@app/shared/pipes/date-format.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(competenceMapRoutes),
    NgbModule,
    SearchBarModule,
    SVGSpriteModule,
    SharedModule,
    NgxSliderModule,
    CompetenceSharedModule,
    UnsavedConfirmLeaveModule,
    CompetenceMapViewModule,
    HighlightSearchModule,
    DateFormatPipe,
  ],
  declarations: [
    MyCompetencesComponent,
    MyCatalogComponent,
    MyCatalogFiltersComponent,
    MyCatalogSectionsComponent,
    MyCatalogTreeItemComponent,
    PotencialSuppliersComponent,
    SliderDirective,
    AdditionalFiltersComponent,
    SuppliersSelectionComponent,
    MyCatalogFilterTreeItemComponent,
    ExpertCompetencesComponent,
  ],
  exports: [
    MyCatalogComponent,
    MyCatalogFiltersComponent,
    MyCatalogSectionsComponent,
    MyCatalogTreeItemComponent,
    PotencialSuppliersComponent,
    SuppliersSelectionComponent,
    SearchBarModule,
  ],
  providers: [
    {
      provide: SERVICES_COMPETENCE_STATE,
      useClass: CompetenceDataService,
    },
    {
      provide: GOODS_COMPETENCE_STATE,
      useClass: CompetenceDataService,
    },
  ],
})
export class CompetenceMapModule {}
