import { TradeProviderLot } from './lots.model';
import { User } from '@app/shared/models/user.model';
import { TradeCustomer } from './customers.model';
import { AccessRight, InviteSupplierStatus, ReasonEndTrade, TradeCardStatus } from '../types/trades.types';
import { LockUserTabTrade, TradeCard } from './trades.model';
import { RatingProviderTrade } from './trades-rating.model';
import { UserFile } from '@app/file-manager/models/user-file.model';

export interface SupplierTradeResponse {
  id: number;
  invitation_accept_date: string;
  leader_points: number;
  made_offer_in_trade: boolean;
  number_place_in_ranking: number;
  points_for_trading: number;
  owner: User;
  providers: TradeCustomer[];
  status: InviteSupplierStatus;
  trade: TradeCard;
}

export interface SupplierTradeCard {
  id?: number;
  id_trade: number;
  id_provider_trade?: number;
  number_place_in_ranking?: number;
  place_in_ranking?: number;
  leader_points?: number;
  lots?: TradeProviderLot[];
  owner_trade: User;
  owner: User;
  providers: TradeCustomer[];
  status: InviteSupplierStatus;
  status_trade: TradeCardStatus;
  expanded?: boolean;
  hasSuggestion?: boolean;
  isChatAccess?: boolean;
  date_start_trade: string | Date;
  date_end_trade: string | Date;
  date_registration_payer: string;
  date_state_registration: string;
  company: any;
  points_for_trading: number;
  reason_end_trade: ReasonEndTrade;
  trade?: TradeCard;
  invitation_accept_date?: string;
  rating?: RatingProviderTrade;
  competence_sections?: any[];
  competenciesString?: string;
  height?: number;
  toggled?: boolean;
  selected_provider_in_trade?: boolean;
  made_offer_in_trade?: boolean;
  extra_front?: any;
  datetime_update_lots?: string;
  flags?: Flags;
  temp_data?: Partial<SupplierTradeCard>;
  owner_archive?: User;
  providers_archive?: ProviderArchive[];
  trade_archive?: TradeCard;
  trade_id?: number;
  locking: LockUserTabTrade[];
  files_archive?: UserFile[];
}

export interface Flags {
  is_inactive?: boolean;
  is_declined?: boolean;
  has_chat_access?: boolean;
}

export class SupplierTradeCard implements SupplierTradeCard {
  status: InviteSupplierStatus;
  status_trade: TradeCardStatus;
  trade?: TradeCard;
  constructor(status: TradeCardStatus, inviteStatus: InviteSupplierStatus, date) {
    this.status = inviteStatus;
    this.status_trade = status;
    this.trade = <TradeCard>{};
    this.trade.project_date = date;
  }
}

export interface SupplierInvitationState {
  checked: boolean;
  status?: InviteSupplierStatus;
}

export interface PotentialProviderInfo {
  provider_info: {
    status: InviteSupplierStatus;
    id: number;
  };
  user_info: PotentialProvider;
}

export interface PotentialProvider extends User {
  root_ap: User;
}

export interface ProviderArchive {
  user: User;
  access_rights: AccessRight;
  provider_trade: TradeCard;
}
