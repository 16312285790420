import { Company } from '@app/shared/models/company.model';
import { User } from '@app/shared/models/user.model';
import { TypeTransactionEnum } from '@app/shared/constants/type-transaction.constants';
import { CommentsStatus } from '@app/+tariffs/shared/enums/comments-status';
import { OperatorType } from '@app/shared/constants/operator-type.constants';
import { Meta } from '@app/shared/constants/meta.constants';

export interface GeneralAccrualAggregation {
  amount: number;
  approval_date: string;
  comment: string;
  comments: AccrualsComment[];
  company?: Company;
  company_id: number;
  created_at: string;
  extra: {
    itn: string;
    payment_order_id: string;
    payment_type: string;
    trade_id: number;
    reverse_transaction_to_id: string; // этого поля нет на беке, но используется в компонете. Проверить его актуальность
    reversed_transaction_id: string; // этого поля нет на беке, но используется в компонете. Проверить его актуальность
  };
  flags: AccrualsFlag;
  id: number;
  type: string;
  user: User;
  user_created: User;
  user_created_id: number;
  user_id: number;
  checked?: boolean;
}

export interface AccrualsComment {
  author?: User;
  date_create?: string;
  date_update?: string;
  author_id?: number;
  description?: string;
  action?: CommentsStatus;
}

export interface AddAccrualModel {
  transactions: Array<OneTransactionModel>;
}

export interface OneTransactionModel {
  type: TypeTransactionEnum | string; // тип операции (поле для создания через ручной ввод)
  user_id: number; // ИД пользователя (поле для создания через ручной ввод)
  extra: {
    itn?: string; // ИНН пользователя (поле для создания через ручной ввод)
    payment_type?: string; // Форма оплаты
    payment_order_id?: string; // № п/п
    trade_id?: number; // ИД торгов за которые сформированна транзакция (ИД торгов) (поле для создания через ручной ввод)
    reverse_transaction_to_id?: number; // ИД транзацеии которая отменяет данную
    reversed_transaction_id?: number; // ИД отмененной транзакции
  };
  amount: number; // Сумма операции в рублях (поле для создания через ручной ввод)
  approval_date?: string; // Для ручного не нужно
  comments?: AccrualsComment[]; // (поле для создания через ручной ввод)
}

export interface ReverseAccrualModel {
  ids: Array<number>;
}

export interface CommentAccrualModel {
  id: number;
  comment: string;
}

export interface FileLogFilterAccrualModel {
  transaction_id: number;
  user_full_name: string;
  file_name: string;
  from_created_at: string;
  to_created_at: string;
}

export interface FileLogAccrualModel {
  created_at: string;
  extra: { transaction_ids: number[] };
  file_id: number;
  file_name: string;
  id: number;
  first_name: string;
  patronymic: string;
  second_name: string;
  user_id: number;
}

export interface PaymentsFromFileParams {
  file_id: number;
  file_type: string;
}

export interface PaymentsCreatePatams {
  force: boolean;
  transaction_id: string;
}

export interface PaymentsFromFileModel {
  added: number;
  errors: string[];
  total: number;
  result: OnePaymentFromFileModel[];
  forceData: OnePaymentFromFileModel[];
  errorData: OnePaymentFromFileModel[];
}

export interface OnePaymentFromFileModel {
  amount: number;
  approval_date: string;
  comment: string;
  itn: string;
  payment_type: string;
  payment_order_id: string;
  temporary_id: string;
  checked: boolean;
}
export interface AccrualsFlag {
  reversed: boolean;
  to_reverse: boolean;
  created_manually: boolean;
}

export interface UploadFileResponse {
  added: number;
  errors: any;
  result: TransactionsFromFile[];
  total: number;
}

export interface TransactionsFromFile {
  amount: number;
  approval_date: string;
  file_hash: string;
  file_name: string;
  is_duplicate: boolean;
  is_exist_itn?: boolean;
  itn: string;
  payment_order_id: string;
  payment_type: string;
  temporary_id: string;
}
