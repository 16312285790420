<div (click)="cancelSaveTemplate()" class="dc-modal-x-button">
  <app-svg-icon [width]="20" [height]="20" name="close"></app-svg-icon>
</div>

<div class="modal-header margin-b-30">
  <h5 class="margin-b-0">Сохранить карточку в виде шаблона</h5>
</div>

<div class="modal-body">
  <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="false" [required]="true">
    <dc-form-label>Название</dc-form-label>
    <dc-form-input [maxLength]="MAX_VALUE" [isShowLength]="true">
      <textarea formInput [formControl]="newCardTemplateTitleControl" [maxLength]="MAX_VALUE"></textarea>
    </dc-form-input>
  </dc-form-field-wrapper>
</div>

<div class="modal-footer">
  <div class="dc-footer-buttons">
    <button class="btn btn-dc-outline" (click)="cancelSaveTemplate()">Отменить</button>

    <button
      class="btn btn-dc-primary"
      [disabled]="
        isSaveTemplateLoading || newCardTemplateTitleControl.invalid || !newCardTemplateTitleControl.value?.trim()
      "
      (click)="saveTemplate()"
    >
      Сохранить
    </button>
  </div>
</div>
