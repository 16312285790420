import { Pipe, PipeTransform } from '@angular/core';
import { TradeCustomer } from '../../models/customers.model';
import { AccessRights } from '../../constants/trades.constants';

@Pipe({
  name: 'tradeUsersSort',
  pure: false,
})
export class TradeUsersSortPipe implements PipeTransform {
  transform(array: TradeCustomer[], field: string): TradeCustomer[] {
    return array.sort((a, b) => {
      if (a.access_rights === AccessRights.OWNER && b.access_rights !== AccessRights.OWNER) {
        return -1;
      }

      if (a.access_rights !== AccessRights.OWNER && b.access_rights === AccessRights.OWNER) {
        return 1;
      }

      return a.user[field].localeCompare(b.user[field], 'ru', { sensitivity: 'base' });
    });
  }
}
