<form [formGroup]="form">
  <app-supplier-lot
    *ngFor="let lot of lots | orderBy : 'data.id'; index as lotIndex"
    [lot]="lot"
    [readonly]="readonly"
    [isTrades]="isTrades"
    [formGroupName]="lotIndex"
    [rating]="getLotRating(lot.id)"
    [cardId]="card.id"
    [hideLeaders]="card.trade.hide_leaders"
    (reset)="resetedLotIndex = lotIndex"
  ></app-supplier-lot>
</form>

<app-modal-window [isShowed]="resetedLotIndex !== null" (closeEvent)="resetedLotIndex = null">
  <p class="margin-b-50">Вы действительно хотите удалить все внесённые ранее значения?</p>
  <p>Отменить это действие будет невозможно!</p>
  <div class="d-flex justify-content-between">
    <button class="btn btn-dc-outline" (click)="resetedLotIndex = null">Нет, отменить</button>
    <button class="btn btn-dc-primary" (click)="resetLotValues()">Да, удалить</button>
  </div>
</app-modal-window>
