export enum OPERATOR {
  BETWEEN = 'between',
  IN = 'in_',
  GE = 'ge', // Больше или равно (>=)
  LE = 'le', // Меньше или равно (<=)
  EQUALS = 'eq',
  EXIST_FLAG = 'exist_flag',
  NOT_EXIST_FLAG = 'not_exist_flag',
  JSON_IN = 'jsonb_in',
  NOTIN = 'notin_',
}
