import { ChatGroupOrThemeRemoveComponent } from '@app/chat/chat-groups/chat-group-or-theme-remove/chat-group-or-theme-remove.component';
import { ChatGroupThemeNameFormComponent } from '@app/chat/chat-groups/chat-group-theme-name-form/chat-group-theme-name-form.component';
import { ChatSectionService } from '@app/chat/services/chat-section.service';
import { MessagesSelectService } from '@app/chat/services/messages-select.service';
import { ModalService } from '@app/services/modal.service';
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { ROLES, RolesEnum } from '@app/shared/constants/roles.constants';

import { ChatService } from '@app/chat/services/chat.service';
import { SocketDataService } from '@app/services/socket-data.service';
import { ChatRoom, ChatSection } from '@app/chat/models/chat.model';
import { Role } from '@app/shared/models/user.model';
import {
  ChatSectionsEnum,
  GroupSortingNames,
  TechTypeEnum,
  TradeRoomsTypes,
} from '@app/chat/constants/chat-sections.constants';
import { AuthService } from '@app/shared/services/auth.service';
import { UserStatusesTypes } from '@app/shared/types/user-statuses.types';
import { StatusesEnum } from '@app/shared/constants/statuses.constants';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-chat-theme',
  templateUrl: './chat-theme.component.html',
  styleUrls: ['./chat-theme.component.scss'],
})
export class ChatThemeComponent implements OnInit, OnDestroy {
  @Input() isModalView: boolean = false;
  @Input() userId: number | string;
  @Input() group: ChatRoom;
  @Input() theme: ChatRoom;
  @Input() contactSelected: ChatRoom;
  @Input() userType: RolesEnum;
  @Input() isNested = false;
  @Input() techType: TechTypeEnum | null = null;
  @Input() sortType: GroupSortingNames;
  @Input() isGroupExpanded: boolean = false;

  TechTypeEnum = TechTypeEnum;
  userStatus: UserStatusesTypes;
  themes: ChatRoom[] = [];
  roles: Role[] = ROLES;
  chatSectionSelected: ChatSection;
  chatSectionsEnum = ChatSectionsEnum;
  statusesEnum = StatusesEnum;
  tradeRoomsTypes = TradeRoomsTypes;
  themeSelected: ChatRoom;
  filterString: string;
  isExpanded = false;

  isProvider = false;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  protected totalUnreadMessagesCounter: number | '10+';

  isCurrentUserDutyTso = false;

  constructor(
    private chatService: ChatService,
    private chatDataService: SocketDataService,
    private socketDataService: SocketDataService,
    private authService: AuthService,
    private readonly notify: NotificationsService,
    private chatSectionService: ChatSectionService,
    private messagesSelectService: MessagesSelectService,
    private readonly modalService: ModalService
  ) {}

  get isActiveClass(): boolean {
    if (this.isModalView) return false;
    return (
      (this.contactSelected?.room_id === this.theme.room_id || this.themeSelected?.room_id === this.theme.room_id) &&
      // используем не строгое сравнение так как в тех разделе нужно сравнивать
      // со строкой, а в остальных разделах значение могу быть null и undefined
      (this.contactSelected?.tech_section_type == this.techType ||
        this.themeSelected?.tech_section_type == this.techType)
    );
  }

  ngOnInit() {
    this.filterString = this.chatService.contactsFilter;

    this.isExpanded = this.chatService.isTradeExpanded(this.theme?.room_id);

    this.chatSectionSelected = this.chatSectionService.chatSectionSelected;
    this.chatSectionService.chatSectionSelectedChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((chatSectionSelected) => (this.chatSectionSelected = chatSectionSelected));

    this.chatService.themeSelectedChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((themeSelected) => {
      this.themeSelected = themeSelected;
    });

    if (this.chatSectionSelected.name === this.chatSectionsEnum.TRADE) {
      this.chatService.contactsFilterChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((filterString) => {
        this.filterString = filterString;
        this.filterThemes(this.chatService.getGroupsThemes(this.chatSectionSelected.name)['themes']);
      });
      this.userId = +this.authService.user_id;
      this.filterThemes(this.chatService.getGroupsThemes(this.chatSectionSelected.name)['themes']);

      this.chatService.themeSelectedChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((themeSelected) => {
        this.themeSelected = themeSelected;
      });
    }
    this.chatService.themesChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.filterThemes(this.chatService.getGroupsThemes(this.chatSectionSelected.name)['themes']);
      this.totalUnreadMessagesCounter = this.chatService.totalUnreadCount([this.theme]);
    });

    this.totalUnreadMessagesCounter = this.chatService.totalUnreadCount([this.theme]);

    if (this.theme) {
      this.isProvider = !!this.theme?.providers?.find((provider) => provider === +this.authService.user_id);
    }
    this.authService.userStatus.pipe(takeUntil(this.ngUnsubscribe)).subscribe((userStatus) => {
      this.userStatus = userStatus;
    });

    this.chatService.isDutyTso$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => (this.isCurrentUserDutyTso = res));
  }

  public toggleRoomSelected(theme: ChatRoom) {
    this.messagesSelectService.toggleRoomSelected(theme);
  }

  public isRoomSelected(theme: ChatRoom) {
    return this.messagesSelectService.isRoomSelected(theme);
  }

  onThemeSelect(theme: ChatRoom) {
    if (this.isModalView) return;
    // console.log('SELECTED THEME', theme);
    if (theme.filterDisabled) {
      this.notify.warn('Внимание', 'Данной группы (темы) нет среди результатов поиска');
      return;
    }
    this.chatService.setRoomSelected(theme);
    this.chatService.setNewCurrentTechType(theme.tech_section_type);
    this.chatService.focusChanged.next(true);
    this.messagesSelectService.clearMessageSelected();
    this.chatService.openChat(this.theme.room_id);
  }

  onThemeUsersToggle() {
    this.chatService.toggleThemeOpen(this.theme, this.chatSectionService.chatSectionSelected.name);
  }

  // Актуализировать или удалить
  onThemeEdit() {
    this.chatService.setEditingGroupOrThemeObject({
      ...this.theme,
      group: this.group,
    });

    this.chatService.isEditingGroupOrThemeChanged.next(true);
  }

  openRenameThemeModal() {
    const modalInstance = this.modalService.open(
      ChatGroupThemeNameFormComponent,
      { centered: true },
      {
        name: this.theme.title,
        placeholder: 'Введите название темы',
        title: 'Переименовать тему',
      }
    );

    const componentInstance = modalInstance.componentInstance;

    componentInstance.cancelEdit
      .pipe(takeUntil(this.chatService.chatDestroyed$))
      .subscribe(() => modalInstance.close());

    componentInstance.saveName.pipe(takeUntil(this.chatService.chatDestroyed$)).subscribe((name: string) => {
      this.renameTheme(name);
      modalInstance.close();
    });
  }

  openCreateModal() {
    const modalInstance = this.modalService.open(
      ChatGroupThemeNameFormComponent,
      { centered: true },
      {
        placeholder: 'Введите название темы',
        title: 'Создать тему',
      }
    );

    const componentInstance = modalInstance.componentInstance;

    componentInstance.cancelEdit
      .pipe(takeUntil(this.chatService.chatDestroyed$))
      .subscribe(() => modalInstance.close());

    componentInstance.saveName.pipe(takeUntil(this.chatService.chatDestroyed$)).subscribe((name: string) => {
      this.createTradeTopic(name);
      modalInstance.close();
    });
  }

  renameTheme(title: string) {
    switch (this.chatSectionSelected.name) {
      case ChatSectionsEnum.ADMIN:
      case ChatSectionsEnum.HOLDING:
        this.chatDataService.updateTopic({
          section: this.chatSectionSelected.name,
          id: this.theme.id,
          title,
        });
        break;

      case ChatSectionsEnum.TRADE:
        this.chatDataService.updateTradeTopic({
          title,
          id: this.theme.id,
          users: this.theme.users,
        });
        break;

      case ChatSectionsEnum.TECH:
        this.chatDataService.updateTechTopic({
          id: this.theme.id,
          title,
          is_duty_tso: this.isCurrentUserDutyTso && this.techType === TechTypeEnum.ALL_USERS,
          is_tso: this.techType === TechTypeEnum.MY_USERS,
        });
        break;
    }
  }

  onRemoveThemeAsk() {
    const modalInstance = this.modalService.open(
      ChatGroupOrThemeRemoveComponent,
      { centered: true },
      {
        isGroup: false,
      }
    );

    const componentInstance = modalInstance.componentInstance;

    componentInstance.approved.pipe(takeUntil(this.chatService.chatDestroyed$)).subscribe((isApproved: boolean) => {
      if (isApproved) {
        this.onRemoveTheme();
      }

      modalInstance.close();
    });
  }

  onRemoveTheme() {
    switch (this.chatSectionSelected.name) {
      case ChatSectionsEnum.ADMIN:
      case ChatSectionsEnum.HOLDING:
        this.chatDataService.removeTopic({
          topic_id: this.theme.id,
          section: this.chatSectionService.chatSectionSelected,
          room_id: this.theme.room_id,
        });
        break;

      case ChatSectionsEnum.TRADE:
        this.chatDataService.removeTradeTopic(this.theme.id);
        break;

      case ChatSectionsEnum.TECH:
        this.chatDataService.removeTechTopic({
          topic_id: this.theme.id,
          room_id: this.theme.room_id,
          is_duty_tso: this.isCurrentUserDutyTso && this.theme.tech_section_type === TechTypeEnum.ALL_USERS,
          is_tso: this.theme.tech_section_type === TechTypeEnum.MY_USERS,
        });
        break;
    }

    this.chatService.removeTheme(this.theme, this.chatSectionSelected.name);
  }

  // Актализировать или удалить метод
  canEditTheme() {
    return (
      (this.userId === this.group.owner_id &&
        ((this.chatSectionSelected.name === this.chatSectionsEnum.TRADE &&
          (this.theme.type === this.tradeRoomsTypes.CUSTOMER_OTHER ||
            this.theme.type === this.tradeRoomsTypes.PROVIDER_OTHER)) ||
          this.chatSectionSelected.name === this.chatSectionsEnum.ADMIN ||
          this.chatSectionSelected.name === this.chatSectionsEnum.HOLDING)) ||
      (this.chatSectionSelected.name === this.chatSectionsEnum.TECH && this.userId === this.group.owner_id)
    );
  }

  get isOwner() {
    return !(
      this.userId === this.group.owner_id ||
      this.userId === this.group.partner_owner_id ||
      (this.userType === RolesEnum.SUPERUSER && this.chatSectionSelected.name !== ChatSectionsEnum.TECH) ||
      (this.chatSectionSelected.name === ChatSectionsEnum.TECH &&
        this.isCurrentUserDutyTso &&
        this.techType === TechTypeEnum.ALL_USERS)
    );
  }

  createTradeTopic(event: string) {
    this.socketDataService.addTradeTopic(
      { title: event, trade_id: this.theme.trade_id, owner_id: 11, users: [] },
      this.theme.id
    );
  }

  filterThemes(themes: ChatRoom) {
    this.themes = [];
    Object.keys(themes).forEach((room_id) => {
      const theme = themes[room_id];
      if (theme.group_id === this.theme.id) {
        this.themes.push(theme);
      }
    });
  }

  onToggleExpanded() {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      this.chatService.addTradeExpanded(this.theme?.room_id);
    } else {
      this.chatService.removeTradeExpanded(this.theme?.room_id);
    }
  }

  get isTradeChatFirstLevel() {
    return this.chatSectionSelected.name === this.chatSectionsEnum.TRADE && !this.isNested;
  }

  get isTradeChatEndLevel() {
    return this.chatSectionSelected.name === this.chatSectionsEnum.TRADE && this.isNested;
  }

  trackByRoomId(index: number, room: ChatRoom): string {
    return room.room_id;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
