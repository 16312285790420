import { ChatSectionService } from '@app/chat/services/chat-section.service';
import { map, takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ROLES, RolesEnum } from '@app/shared/constants/roles.constants';
import { AuthService } from '@app/shared/services/auth.service';
import { ChatService } from '@app/chat/services/chat.service';
import { SocketDataService } from '@app/services/socket-data.service';
import { ChatRoom, ChatSection, ChatUserTreeWithNode } from '@app/chat/models/chat.model';
import { ChatSectionsEnum, TechTypeEnum } from '@app/chat/constants/chat-sections.constants';
import { User } from '@app/shared/models/user.model';
import { ITreeOptions, TREE_ACTIONS, TreeComponent, TreeModel, TreeNode } from '@circlon/angular-tree-component';
import { StatusesEnum } from '@app/shared/constants/statuses.constants';
import { TreeHelper } from '@app/shared/helpers/tree.helper';
import { DestroyService } from '@app/services/destroy.service';
import _, { cloneDeep } from 'lodash';
import { TsoClientsService } from '@app/chat/services/tso-clients.service';
import { ChatContactsOnlyFilterData } from '@app/chat/chat-contacts-only-filter/services';

@Component({
  selector: 'app-chat-group-or-theme-edit',
  templateUrl: './chat-group-or-theme-edit.component.html',
  styleUrls: ['./chat-group-or-theme-edit.component.scss'],
})
export class ChatGroupOrThemeEditComponent implements OnInit, OnDestroy {
  contacts: ChatUserTreeWithNode[] = [];
  groupContacts = [];
  roles = ROLES;
  editingObject: ChatRoom;
  chatSectionSelected: ChatSection = {} as ChatSection;

  leftContactsOnlyFilter: ChatContactsOnlyFilterData;
  rightContactsOnlyFilter: ChatContactsOnlyFilterData;

  chatSectionsEnum = ChatSectionsEnum;
  userId: number;
  rolesEnum = RolesEnum;

  userTreeList: ChatUserTreeWithNode[] = [];

  customTemplateStringOptions: ITreeOptions = {};
  statuses = StatusesEnum;

  contactsValue: string = '';
  membersValue = '';

  selectedContacts: ChatUserTreeWithNode[] = [];
  contactsPartiallySelected: boolean;
  allContactsCheckbox: boolean;

  selectedMembers: ChatUserTreeWithNode[] = [];
  allMembersCheckbox: boolean;

  leftUserList: User[] | ChatUserTreeWithNode[];
  haveSelectableContacts: boolean = true;
  isChanged: boolean = true;

  isCurrentUserDutyTso: boolean = false;

  isFilterForLeftSideOpen$ = this.chatService.contactsOnlyFiltersState$.pipe(map((data) => data['leftEditGroup']));
  isFilterForRightSideOpen$ = this.chatService.contactsOnlyFiltersState$.pipe(map((data) => data['rightEditGroup']));

  private readonly hiddenItemsInList = new Map<'left' | 'right', Set<number>>();

  private currentUser: ChatUserTreeWithNode;
  private isSaved: boolean = true;

  private archiveUsers = new Set<number>();

  private techList: ChatUserTreeWithNode[] = [];

  private initialChatUsersForFilter: User[] | ChatRoom[] = [];

  @ViewChild('tree') private tree: TreeComponent;

  constructor(
    private authService: AuthService,
    private chatService: ChatService,
    private chatDataService: SocketDataService,
    private destroy: DestroyService,
    private tsoClientService: TsoClientsService,
    private chatSectionService: ChatSectionService
  ) {
    this.initialChatUsersForFilter = this.chatService.usersForContactsFilter$.value;
  }

  get isGroup() {
    return !this.editingObject.group_id;
  }

  get disableRightAllCheckBox() {
    return (
      this.groupContacts.length <= 1 ||
      (this.groupContacts.length === 2 &&
        this.groupContacts.filter(
          (contact) => contact.type === RolesEnum.SUPERUSER || contact.id === this.editingObject.owner_id
        ).length === 2) ||
      (this.chatSectionSelected.name !== this.chatSectionsEnum.TECH &&
        this.groupContacts.length === 3 &&
        this.groupContacts.filter(
          (contact) =>
            contact.type === RolesEnum.SUPERUSER ||
            contact.id === this.userId ||
            contact.id === this.editingObject.owner_id
        ).length === 3) ||
      (this.chatSectionSelected.name === this.chatSectionsEnum.TECH &&
        this.groupContacts.every(
          (el) => el.id === this.editingObject.owner_id || el.type === RolesEnum.DUTY_TSO || el.type === RolesEnum.TSO
        ))
    );
  }

  get membersPartiallySelected() {
    const members = this.groupContacts.filter(
      (contact) => contact.type !== RolesEnum.SUPERUSER && contact.id !== this.editingObject.owner_id
    );
    return members.length && members.some((item) => item.selected) && !members.every((item) => item.selected);
  }

  get isHoldingGroup() {
    return this.chatSectionSelected.name === this.chatSectionsEnum.HOLDING && !this.editingObject.group_id;
  }

  get isTechGroup() {
    return this.chatSectionSelected.name === this.chatSectionsEnum.TECH && !this.editingObject.group_id;
  }

  get isTechChat() {
    return this.chatSectionSelected.name === this.chatSectionsEnum.TECH;
  }

  ngOnInit() {
    this.chatSectionSelected = this.chatSectionService.chatSectionSelected;
    this.editingObject = this.chatService.getEditingGroupOrThemeObject();
    this.groupContacts = cloneDeep(
      this.editingObject.userItems.filter((contact) => contact.status !== this.statuses.IN_ARCHIVE)
    ).map((item) => {
      return {
        ...item,
        id: +item.id,
      };
    });

    this.leftContactsOnlyFilter = this.chatService.getContactsOnlyFilter('leftEditGroup');
    this.rightContactsOnlyFilter = this.chatService.getContactsOnlyFilter('rightEditGroup');

    this.userId = +this.authService.user_id;

    if (this.isTechGroup) {
      const dutyTsoId = !this.editingObject?.flags?.is_owner_duty_tso ? this.editingObject.partner_owner_id : null;

      this.tsoClientService
        .getUsersForTechGroup(dutyTsoId)
        .pipe(takeUntil(this.destroy))
        .subscribe((users) => {
          this.techList = users.filter((el) => el.type !== RolesEnum.DUTY_TSO && el.type !== RolesEnum.TSO);
          this.fillLeftUserList();
          this.updateAllContactsCheckbox();
        });
    }

    this.chatService.isDutyTso$.pipe(takeUntil(this.destroy)).subscribe((res) => (this.isCurrentUserDutyTso = res));

    this.fillUserLists(this.chatService.users as unknown as { [key: number]: ChatUserTreeWithNode });

    this.authService.userStream.pipe(takeUntil(this.destroy)).subscribe((user) => {
      this.currentUser = user;
    });

    this.chatService.usersChanged.pipe(takeUntil(this.destroy)).subscribe((users) => {
      this.fillUserLists(users);

      this.fillLeftUserList();

      this.groupContacts = this.groupContacts.filter((item) => !this.archiveUsers.has(+item.id));
    });

    this.chatService.userTreeChanged.pipe(takeUntil(this.destroy)).subscribe(() => {
      this.fillUseTreeList();
    });

    this.chatService.contactsOnlyFilterChanged.pipe(takeUntil(this.destroy)).subscribe(() => {
      this.leftContactsOnlyFilter = this.chatService.getContactsOnlyFilter('leftEditGroup');
      this.rightContactsOnlyFilter = this.chatService.getContactsOnlyFilter('rightEditGroup');
    });

    this.fillUseTreeList();
    this.fillLeftUserList();
    this.updateAllMembersCheckbox();
    this.updateAllContactsCheckbox();

    if (!this.groupContacts.length) {
      this.groupContacts = [this.currentUser];
    } else {
      this.groupContacts.forEach((contact) => {
        contact.selected = false;
        contact.disabled = false;
      });
    }
  }

  ngAfterViewInit(): void {
    this.tree?.treeModel.expandAll();
  }

  ngOnDestroy(): void {
    this.onClearFilter('leftEditGroup');
    this.onClearFilter('leftEditGroup');

    this.chatService.usersForContactsFilter$.next(this.initialChatUsersForFilter);
  }

  private fillLeftUserList() {
    if (this.isHoldingGroup) {
      return;
    }

    if (this.chatSectionSelected.name === this.chatSectionsEnum.ADMIN && !this.editingObject.group_id) {
      this.leftUserList = [...this.contacts];
    }

    if (this.chatSectionSelected.name === this.chatSectionsEnum.TECH && !this.editingObject.group_id) {
      this.leftUserList = [...this.techList];
    }

    if (this.editingObject.group_id && this.chatSectionSelected.name !== ChatSectionsEnum.TECH) {
      this.leftUserList = [...this.editingObject.group.userItems.filter((item) => !this.archiveUsers.has(item.id))];
    }

    if (this.editingObject.group_id && this.chatSectionSelected.name === ChatSectionsEnum.TECH) {
      this.leftUserList = [
        ...this.editingObject.group.userItems.filter(
          (item) => !this.archiveUsers.has(item.id) && item.type !== RolesEnum.DUTY_TSO && item.type !== RolesEnum.TSO
        ),
      ];
    }

    this.checkLeftUserList();
  }

  private fillUseTreeList() {
    const userContactTree = this.chatService.getUserTree();
    if (!userContactTree || !this.isHoldingGroup) {
      return;
    }
    const cloneUserTree = cloneDeep(userContactTree);
    // const MAX_LEVEL = 10;
    // limitMaxTreeLevel(cloneUserTree, MAX_LEVEL);

    this.userTreeList = [cloneUserTree, ...cloneUserTree.children];

    this.userTreeList[0].children = [];
    this.userTreeList.forEach((item) => {
      this.checkUserTreeList(item);
    });
  }

  private checkUserTreeList(node: ChatUserTreeWithNode, level: number = 0): void {
    if (!this.isHoldingGroup) {
      return;
    }
    const groupIds = this.groupContacts.map((contact) => +contact.id);
    const selectedIds = this.selectedContacts.map((contact) => +contact.id);
    if (groupIds?.includes(+node.id)) {
      node.disabled = true;
    } else {
      node.disabled = false;
    }
    if (selectedIds.includes(+node.id)) {
      node.selected = true;
    } else {
      node.selected = false;
    }
    if (node.children) {
      for (const child of node.children) {
        this.checkUserTreeList(child, level + 1);
      }
    }
  }

  private checkLeftUserList() {
    if (this.isHoldingGroup) {
      return;
    }
    const groupIds = this.groupContacts.map((contact) => +contact.id);
    const selectedIds = this.selectedContacts.map((contact) => +contact.id);
    this.leftUserList.forEach((item) => {
      if (groupIds.includes(+item.id)) {
        item.disabled = true;
      } else {
        item.disabled = false;
      }

      if (selectedIds.includes(+item.id)) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
  }

  private fillUserLists(contacts: { [key: number]: ChatUserTreeWithNode }) {
    this.contacts = [];

    Object.keys(contacts).forEach((room_id) => {
      contacts[room_id].selected = false;
      contacts[room_id].id = +contacts[room_id].id;

      if (contacts[room_id].status === this.statuses.IN_ARCHIVE) {
        // Проверяем не перевили ли владельца группы в архив - применимо только если текущий пользователь суперадмин
        //  В остальных кейсах может вкладку открыть может только владелец группы
        if (
          this.chatSectionSelected.name === this.chatSectionsEnum.ADMIN &&
          +this.editingObject.owner_id === +contacts[room_id].id &&
          this.currentUser.type === this.rolesEnum.SUPERUSER
        ) {
          this.editingObject.owner_id = this.currentUser.id;
        }
        this.archiveUsers.add(+contacts[room_id].id);
      }

      if (!this.editingObject.group) {
        this.contacts.push(contacts[room_id]);
        return;
      }
    });
  }

  expandItem(tree: TreeModel, node: TreeNode, $event: any): void {
    TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
  }

  edit() {
    // Позже добавить кейсы для торгового и тех раздела.
    const users: number[] = this.groupContacts.map((contact) => contact.id);
    if (this.editingObject.group_id) {
      switch (this.chatSectionSelected.name) {
        case ChatSectionsEnum.ADMIN:
        case ChatSectionsEnum.HOLDING:
          this.chatDataService.updateTopic({
            section: this.chatSectionSelected.name,
            id: this.editingObject.id,
            users,
          });
          break;
        case ChatSectionsEnum.TECH:
          this.chatDataService.updateTopic({
            section: this.chatSectionSelected.name,
            id: this.editingObject.id,
            is_duty_tso: this.isCurrentUserDutyTso && this.editingObject.tech_section_type === TechTypeEnum.ALL_USERS,
            is_tso: this.editingObject.tech_section_type === TechTypeEnum.MY_USERS,
            users,
          });
          break;
        case ChatSectionsEnum.TRADE:
          this.chatDataService.updateTradeTopic({
            id: this.editingObject.id,
            title: this.editingObject.title,
            users: users,
          });
          break;
      }
    } else {
      switch (this.chatSectionSelected.name) {
        case ChatSectionsEnum.ADMIN:
        case ChatSectionsEnum.HOLDING:
          this.chatDataService.updateGroup({
            section: this.chatSectionSelected.name,
            group_id: this.editingObject.id,
            owner_id: this.editingObject.owner_id,
            users,
          });
          break;

        case ChatSectionsEnum.TECH:
          this.chatDataService.updateGroup({
            section: this.chatSectionSelected.name,
            group_id: this.editingObject.id,
            is_duty_tso: this.isCurrentUserDutyTso && this.editingObject.tech_section_type === TechTypeEnum.ALL_USERS,
            is_tso: this.editingObject.tech_section_type === TechTypeEnum.MY_USERS,
            owner_id: this.editingObject.owner_id,
            users,
          });
          break;
      }
    }
    this.chatService.resetEditingGroupOrThemeObject();
    this.chatService.isEditingGroupOrThemeChanged.next(false);
    this.isSaved = true;
  }

  changeOwner(event: ChatUserTreeWithNode) {
    if (this.chatSectionSelected.name === this.chatSectionsEnum.TRADE) {
      return;
    }
    this.editingObject.owner_id = event.id;
    event.selected = false;
    this.selectMember(event);
    this.isSaved = false;
    this.isChanged = false;
  }

  onCancelGroupEdit() {
    this.chatService.resetEditingGroupOrThemeObject();
    this.chatService.isEditingGroupOrThemeChanged.next(false);
    this.chatService.toggleContactsOnlyFilter(false);
    this.isSaved = true;
  }

  onContactClick(contact: ChatUserTreeWithNode) {
    if (
      (this.editingObject.id &&
        // Проверить !this.isCurrentUserDutyTso
        this.editingObject.owner_id !== this.userId &&
        this.editingObject.partner_owner_id !== this.userId &&
        !this.isCurrentUserDutyTso &&
        this.isSaved &&
        this.currentUser.type !== RolesEnum.SUPERUSER) ||
      (contact.id === this.userId && this.chatSectionSelected.name !== this.chatSectionsEnum.TECH) ||
      this.groupContacts.some((el) => el.id === contact.id) ||
      (this.chatSectionSelected.name === this.chatSectionsEnum.TRADE && !this.editingObject.group)
    ) {
      return;
    }
    contact.disabled = false;
    contact.selected = false;
    this.selectedContacts = this.selectedContacts.filter((item) => item.id !== contact.id);

    this.groupContacts.push({ ...contact });
    this.userTreeList.forEach((item) => {
      this.checkUserTreeList(item);
    });
    this.isChanged = false;
    this.checkLeftUserList();
    this.updateAllContactsCheckbox();
  }

  onGroupContactClick(contact: User) {
    if (
      (this.editingObject.id &&
        // Проверить !this.isCurrentUserDutyTso
        this.editingObject.owner_id !== this.userId &&
        this.editingObject.partner_owner_id !== this.userId &&
        !this.isCurrentUserDutyTso &&
        this.isSaved &&
        this.currentUser.type !== RolesEnum.SUPERUSER) ||
      (contact.id === this.userId && this.chatSectionSelected.name !== ChatSectionsEnum.TECH) ||
      contact.id === this.editingObject.owner_id ||
      (contact.type === RolesEnum.SUPERUSER && this.chatSectionSelected.name !== ChatSectionsEnum.TECH) ||
      (this.chatSectionSelected.name === this.chatSectionsEnum.TRADE && !this.editingObject.group) ||
      (this.chatSectionSelected.name === this.chatSectionsEnum.TECH &&
        (contact.type === RolesEnum.DUTY_TSO || contact.type === RolesEnum.TSO))
    ) {
      return;
    }

    this.groupContacts = this.groupContacts.filter((item) => contact.id !== item.id);
    this.selectedMembers = this.selectedMembers.filter((item) => item.id !== contact.id);
    this.isChanged = false;
    this.userTreeList.forEach((item) => {
      this.checkUserTreeList(item);
    });
    this.checkLeftUserList();
    this.updateAllContactsCheckbox();
    this.updateAllMembersCheckbox();
  }

  onFilterToggle(value: boolean, filterName: 'rightEditGroup' | 'leftEditGroup') {
    if (filterName === 'rightEditGroup') {
      this.chatService.usersForContactsFilter$.next(this.groupContacts);
    }

    if (filterName === 'leftEditGroup') {
      this.chatService.usersForContactsFilter$.next(
        (this.leftUserList || TreeHelper.treeToArray(this.userTreeList)) as User[]
      );
    }

    this.chatService.toggleContactsOnlyFilter(value, filterName);
  }

  onFilterEdit($event: InputEvent) {
    if (!this.tree) {
      return;
    }

    const searchKeys = ['second_name', 'first_name', 'patronymic'];
    $event.stopPropagation();

    // Для корпоративного раздела чата здесь дописать условия фильтрациии после реализации фильтров
    // и вызывать этот метод приприменении фильтров и инициализации компоненты
    this.tree.treeModel.filterNodes((node: { data: ChatUserTreeWithNode }) => {
      if (!this.contactsValue) {
        node.data.searchedInactive = false;
        return true;
      }
      const value = this.contactsValue.toLowerCase();
      node.data.searchedInactive = true;
      return searchKeys.some((a) => {
        if (node.data[a] && node.data[a]?.toLowerCase().startsWith(value)) {
          node.data.searchedInactive = false;
          return true;
        }
      });
    });
  }

  onClearFilter(filterName: string) {
    this.chatService.toggleContactsOnlyFilter(false, filterName);
    this.chatService.setContactsOnlyFilter(false, filterName);
  }

  moveToGroup() {
    this.selectedContacts.forEach((item) => (item.selected = false));
    this.groupContacts.push(...this.selectedContacts);
    this.selectedContacts = [];
    this.allContactsCheckbox = false;
    this.userTreeList.forEach((item) => {
      this.checkUserTreeList(item);
    });
    this.isChanged = false;
    this.checkLeftUserList();
    this.updateAllContactsCheckbox();
  }

  moveFromGroup() {
    this.groupContacts.forEach((contact) => (contact.selected = false));
    this.groupContacts = this.groupContacts.filter(
      (contact) => !this.selectedMembers.some((member) => member.id === contact.id)
    );
    this.isChanged = false;
    this.selectedMembers = [];

    this.userTreeList.forEach((item) => {
      this.checkUserTreeList(item);
    });
    this.checkLeftUserList();
    this.updateAllMembersCheckbox();
    this.updateAllContactsCheckbox();
  }

  selectMember(event: ChatUserTreeWithNode) {
    if (event.selected) {
      this.selectedMembers.push({ ...event });
    } else {
      this.selectedMembers = this.selectedMembers.filter((item) => item.id !== event.id);
    }
    this.updateAllMembersCheckbox();
  }

  selectContact(event: ChatUserTreeWithNode) {
    if (event.selected) {
      this.selectedContacts.push({ ...event });
    } else {
      this.selectedContacts = this.selectedContacts.filter((item) => item.id !== event.id);
    }
    this.updateAllContactsCheckbox();
  }

  selectAllMembers(event: boolean) {
    this.groupContacts.forEach((item) => {
      if (
        !item.disabled &&
        item.id !== this.editingObject.owner_id &&
        this.userId !== item.id &&
        !(
          this.chatSectionSelected.name === this.chatSectionsEnum.TECH &&
          (item.type === RolesEnum.DUTY_TSO || item.type === RolesEnum.TSO)
        ) &&
        (this.chatSectionSelected.name !== this.chatSectionsEnum.ADMIN ||
          (this.chatSectionSelected.name === this.chatSectionsEnum.ADMIN && item.type !== RolesEnum.SUPERUSER)) &&
        !this.hiddenItemsInList.get('right').has(item.id)
      ) {
        item.selected = event;
        if (event) {
          this.selectedMembers.push({ ...item });
          this.selectedMembers = _.uniqBy(this.selectedMembers, 'id');
        } else {
          this.selectedMembers = [];
        }
      }
    });
  }

  selectAllContacts(event: boolean) {
    this.userTreeList.forEach((item) => {
      this.selectAllContactsInTree(event, item);
    });
    this.selectAllContactsInList(event);
    // Вызываем его для кареток
    this.updateAllContactsCheckbox();
  }

  onNodeVisibleChanged(visible: boolean, item, side: 'left' | 'right') {
    if (!this.hiddenItemsInList.get(side)) {
      this.hiddenItemsInList.set(side, new Set());
    }

    if (visible) {
      this.hiddenItemsInList.get(side).delete(item.id);
    } else {
      this.hiddenItemsInList.get(side).add(item.id);
    }
  }

  private selectAllContactsInTree(isConactSelected: boolean, node: ChatUserTreeWithNode, level: number = 0) {
    if (!this.isHoldingGroup || this.hiddenItemsInList.get('left').has(node.id)) {
      return;
    }
    if (!node.disabled) {
      node.selected = isConactSelected;
      if (isConactSelected) {
        this.selectedContacts.push({ ...node });
        this.selectedContacts = _.uniqBy(this.selectedContacts, 'id');
      } else {
        this.selectedContacts = [];
      }
    }
    if (node.children) {
      for (const child of node.children) {
        this.selectAllContactsInTree(isConactSelected, child, level + 1);
      }
    }
  }

  private selectAllContactsInList(isConactSelected: boolean) {
    if (this.isHoldingGroup) {
      return;
    }

    this.leftUserList.forEach((item) => {
      if (!item.disabled && !this.hiddenItemsInList.get('left').has(item.id)) {
        item.selected = isConactSelected;
        if (isConactSelected) {
          this.selectedContacts.push({ ...item });
          this.selectedContacts = _.uniqBy(this.selectedContacts, 'id');
        } else {
          this.selectedContacts = [];
        }
      }
    });
  }

  private updateAllMembersCheckbox() {
    const members = this.groupContacts.filter(
      (contact) =>
        contact.type !== RolesEnum.SUPERUSER &&
        this.chatSectionSelected.name !== this.chatSectionsEnum.TECH &&
        contact.id !== this.editingObject.owner_id
    );
    this.allMembersCheckbox = members.length && members.every((item) => item.selected);
  }

  private updateAllContactsCheckbox() {
    let contacts: ChatUserTreeWithNode[];

    if (this.isHoldingGroup) {
      contacts = TreeHelper.treeToArray(this.userTreeList).filter((contact) => !contact.disabled);
    } else {
      contacts = (this.leftUserList as ChatUserTreeWithNode[]).filter(
        (contact: ChatUserTreeWithNode) => !contact.disabled
      );
    }

    this.contactsPartiallySelected =
      contacts.length && contacts.some((item) => item.selected) && !contacts.every((item) => item.selected);
    this.allContactsCheckbox = contacts.length && contacts.every((item) => item.selected);
    this.haveSelectableContacts =
      contacts.filter((contact) => !contact.disabled && contact.status !== this.statuses.IN_ARCHIVE).length > 0;
  }
}
