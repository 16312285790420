import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeStatusWarningComponent } from './change-status-warning/change-status-warning.component';
import { OperationModalFooterComponent } from './operation-modal-footer/operation-modal-footer.component';
import { LogsLabelComponent } from './logs-label/logs-label.component';
import { ProjectLogsComponent } from './project-logs/project-logs.component';
import { ProjectLogsHeaderCellComponent } from './project-logs/project-logs-header-cell/project-logs-header-cell.component';
import { ProjectStatusViewComponent } from './project-status-view/project-status-view.component';
import { ProjectCommentModalComponent } from './project-comment-modal/project-comment-modal.component';
import { CmProjectStatusPipe } from './pipes/cm-project-status.pipe';
import { TemplateFilterValuePipe } from './pipes/template-filter-value.pipe';
import { UnitFormatPipe } from './pipes/unit-format.pipe';
import { ProviderFilterBooleanPipe, ProviderFilterRangePipe } from './pipes/provider-filter-value.pipe';
import { CmMapperPipe } from './pipes/mapper.pipe';
import { UserFiltersCellRangeComponent } from './user-filters-cell-range/user-filters-cell-range.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { SVGSpriteModule } from '@app/svg-sprite/svg-sprite.module';
import { TreeModule } from '@circlon/angular-tree-component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { NgSelectModule } from '@ng-select/ng-select';
import { SectionAccessDirective } from './directives/section-access.directive';
import { SearchModule } from '@app/shared/modules/search/search.module';
import { LineClampModule } from '@app/shared/modules/line-clamp/line-clamp.module';
import { TestLocatorModule } from '@app/shared/modules/test-locator/test-locator.module';
import { DateFormatPipe } from '@app/shared/pipes/date-format.pipe';

const components = [
  ChangeStatusWarningComponent,
  OperationModalFooterComponent,
  LogsLabelComponent,
  ProjectLogsComponent,
  ProjectLogsHeaderCellComponent,
  ProjectStatusViewComponent,
  ProjectCommentModalComponent,
  CmProjectStatusPipe,
  TemplateFilterValuePipe,
  UserFiltersCellRangeComponent,
  SectionAccessDirective,
  UnitFormatPipe,
  ProviderFilterBooleanPipe,
  ProviderFilterRangePipe,
  CmMapperPipe,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SVGSpriteModule,
    MatAutocompleteModule,
    SearchModule,
    TestLocatorModule,
    LineClampModule,
    DateFormatPipe,
  ],
  declarations: components,
  exports: [
    ...components,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SVGSpriteModule,
    TreeModule,
    NgSelectModule,
    MatInputModule,
    MatOptionModule,
    MatAutocompleteModule,
    SearchModule,
    TestLocatorModule,
    LineClampModule,
  ],
})
export class CompetenceSharedModule {}
