import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { INVITE_SUPPLIERS_LABELS } from '@app/+trades/constants/suppliers.constants';
import {
  INVITE_STATUS_FILTERS,
  InviteStatusFilter,
  InviteSupplierFilterStatus,
} from '../../models/invite-statuses-filter.model';

@Component({
  selector: 'app-invite-statuses-filter',
  templateUrl: './invite-statuses-filter.component.html',
  styleUrls: ['./invite-statuses-filter.component.scss'],
})
export class InviteStatusesFilterComponent implements OnInit, OnChanges {
  @Input() selected: InviteSupplierFilterStatus[] = [];
  statuses: InviteStatusFilter[] = INVITE_STATUS_FILTERS;

  @Output() onChange = new EventEmitter<InviteSupplierFilterStatus[]>();

  inviteSuppliersLabels = INVITE_SUPPLIERS_LABELS;

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    this.statuses.forEach((status) => (status.checked = false));

    if (Array.isArray(this.selected)) {
      this.statuses.forEach((status) => {
        status.checked = this.selected.some((statusId) => status.id === statusId);
      });
    }
  }

  change() {
    const result = this.statuses.filter((status) => status.checked).map((status) => status.id);

    this.onChange.emit(result);
  }
}
