import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ChatSectionsEnum } from '@app/chat/constants/chat-sections.constants';
import { ChatService } from '@app/chat/services/chat.service';
import { DestroyService } from '@app/services/destroy.service';
import { User } from '@app/shared/models/user.model';
import { PersonalDataService } from '@app/shared/services/personal-data.service';
import { NotificationsService } from 'angular2-notifications';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-choose-tso-modal',
  templateUrl: './choose-tso-modal.component.html',
  styleUrls: ['./choose-tso-modal.component.scss'],
})
export class ChooseTsoModalComponent implements OnInit, OnDestroy {
  @Input() myTsoId: number | null = null;
  @Input() dateOffSet: number = 0;

  @Output() newTso = new EventEmitter<User>();
  @Output() closeModal = new EventEmitter();

  availableTso: User[] = [];
  tsoForChange: User | null = null;
  tsoNameValue: string = '';
  readonly filterName = 'filterTSO';

  isFilterApplied = this.chatService.contactsOnlyFilters[this.filterName];
  isEditingContactsOnlyFilter = this.chatService.contactsOnlyFiltersState$.pipe(
    map((filters) => {
      this.isFilterApplied = this.chatService.contactsOnlyFilters[this.filterName];

      return filters[this.filterName];
    })
  );

  protected readonly chatSectionsEnum = ChatSectionsEnum;

  get disabledChangeButton() {
    return !this.tsoForChange || this.tsoForChange.id === this.myTsoId;
  }

  constructor(
    private personalDataService: PersonalDataService,
    private destroy: DestroyService,
    private chatService: ChatService,
    private notify: NotificationsService
  ) {}

  closeChangedTso() {
    this.closeModal.emit();
  }

  ngOnInit(): void {
    this.personalDataService
      .getReadyTso({ is_tso: true, with_my_tso: true })
      .pipe(takeUntil(this.destroy))
      .subscribe((data) => {
        this.availableTso = data;
        this.chatService.usersForContactsFilter$.next(data);
        // .filter((user) => user.id !== this.userId);
      });
  }

  ngOnDestroy(): void {
    this.chatService.usersForContactsFilter$.next(null);
  }

  changeTso() {
    this.personalDataService
      .bindTsoForUser(this.tsoForChange.id)
      .pipe(
        // switchMap(()=> this.personalDataService.getSelfTso()),
        takeUntil(this.destroy)
      )
      .subscribe(
        (user) => {
          this.recalculateCounter();
          this.newTso.emit(user.tso);
          this.tsoForChange = null;
          this.closeModal.emit();
        },
        () => {
          this.notify.error(`Данного ОТП нет в списке, Пожалуйста, выберете другого ОТП`);
          this.closeModal.emit();
        }
      );
  }

  setTsoForChange(tso: User) {
    this.tsoForChange = tso;
  }

  recalculateCounter() {
    this.availableTso.map((tso) => {
      if (tso.id === this.myTsoId) {
        tso.number_of_assigned_user -= 1;
      }
      if (tso.id === this.tsoForChange.id) {
        tso.number_of_assigned_user += 1;
      }
    });
  }

  openFilter() {
    this.chatService.toggleContactsOnlyFilter(true, this.filterName);
  }

  onCloseFilter() {
    this.chatService.toggleContactsOnlyFilter(false, this.filterName);
  }

  onClearFilter() {
    this.chatService.setContactsOnlyFilter(null, this.filterName);
    this.isFilterApplied = null;
  }
}
