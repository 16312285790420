import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-chat-group-or-theme-remove',
  standalone: true,
  templateUrl: './chat-group-or-theme-remove.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatGroupOrThemeRemoveComponent {
  @Input() isGroup: boolean = true;

  @Output() approved = new EventEmitter<boolean>();

  approve(approved: boolean) {
    this.approved.emit(approved);
  }
}
