<div *ngFor="let provider of providers; index as i" class="margin-b-10">
  <div class="provider-company">
    <span class="d-flex">
      <span class="company-id">{{ i + 1 }}. ID {{ provider.id }},&nbsp;</span>
      <app-line-clamp [content]="provider.company | companyName"></app-line-clamp>
    </span>

    <app-svg-icon
      class="blue-links"
      name="id-card"
      height="16"
      width="18"
      (click)="providerInfoForContactsModal = provider"
    ></app-svg-icon>
  </div>
</div>

<app-modal-window [isShowed]="!!providerInfoForContactsModal" (closeEvent)="providerInfoForContactsModal = null">
  <div class="modal-header">
    <h6>Контакты</h6>
  </div>

  <div class="user-contacts" *ngIf="providerInfoForContactsModal">
    <div *ngIf="providerInfoForContactsModal.company" class="user-contacts-row">
      <span class="company-id">ID {{ providerInfoForContactsModal.id }}</span
      >&nbsp;
      <app-line-clamp [content]="getCompanyName(providerInfoForContactsModal)"></app-line-clamp>
    </div>
    <div class="user-contacts-row">
      ИНН&nbsp;<app-line-clamp [content]="providerInfoForContactsModal.company.itn || '-'"></app-line-clamp>
    </div>
    <div class="user-contacts-row">
      Ответственное лицо:&nbsp; <app-line-clamp [content]="getFullName(providerInfoForContactsModal)"></app-line-clamp>
    </div>
    <div *ngFor="let phone of providerInfoForContactsModal?.phones" class="user-contacts-row">
      <app-svg-icon name="phone" width="16"></app-svg-icon>
      {{ phone | textSeparator : '-' : 3 }}
    </div>
    <div *ngIf="!providerInfoForContactsModal?.phones.length" class="user-contacts-row">
      <app-svg-icon name="phone" width="16"></app-svg-icon>
      <span>Телефон не задан!</span>
    </div>
    <div class="user-contacts-row">
      <app-svg-icon name="email" width="16"></app-svg-icon>
      <app-line-clamp [content]="providerInfoForContactsModal?.email || '-'"></app-line-clamp>
    </div>
  </div>
</app-modal-window>
