import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BACKEND_DATE_FORMAT } from '@app/shared/constants/date.constants';
import * as moment from 'moment';

export interface DoubleDatePickerParams {
  fromDate: string | Date;
  toDate: string | Date;
  fromTime?: string | Date;
  toTime?: string | Date;
  name?: string;
}

@Component({
  selector: 'app-double-datepicker-filter',
  templateUrl: './double-datepicker-filter.component.html',
  styleUrls: ['./double-datepicker-filter.component.scss'],
})
export class DoubleDatepickerFilterComponent implements OnInit {
  private _value: DoubleDatePickerParams;

  @Input() placeholder: string;
  @Input() fromDatePlaceholder: string = '';
  @Input() toDatePlaceholder: string = '';
  @Input() withName: boolean;
  @Input() filterSuggestions: string[];
  @Input() externalDateFormat = BACKEND_DATE_FORMAT;
  @Input() minDate: moment.Moment | null = null;
  @Input() maxDate: moment.Moment | null = null;

  form: FormGroup = new FormGroup({
    fromDate: new FormControl(''),
    toDate: new FormControl(''),
    name: new FormControl(''),
  });

  localValue: DoubleDatePickerParams = {
    fromDate: null,
    toDate: null,
    name: null,
  };

  @Output() submitEvent = new EventEmitter<DoubleDatePickerParams>();
  @Output() closeEvent = new EventEmitter<unknown>();
  @Output() searchEvent = new EventEmitter<string>();

  isInvalidRange: boolean = false;
  isInvalidValue = false;

  @Input() set value(data: DoubleDatePickerParams) {
    if (!data) return;
    const [fromDate, fromTime] = data[0] ? data[0].split(' ') : '';
    const [toDate, toTime] = data[1] ? data[1]?.split(' ') : '';

    this._value = {
      fromDate,
      toDate,
    };

    this.form.patchValue(this._value);
    this.validateDateRange();
  }

  get value() {
    return this._value;
  }

  ngOnInit() {
    this.form.valueChanges.subscribe(() => {
      this.validateDateRange();
    });
  }

  validateDateRange(): void {
    const fromDateControl = this.form.get('fromDate');
    const toDateControl = this.form.get('toDate');

    const fromDateValue = fromDateControl?.value;
    const toDateValue = toDateControl?.value;

    // Сброс ошибок
    this.isInvalidRange = false;
    fromDateControl?.setErrors(null);
    toDateControl?.setErrors(null);

    // Проверка, что день, месяц и год введены (например, "20.20.2020" не пройдет)
    const isCompleteDate = (date: string | undefined): boolean => {
      if (!date) return false;
      const parts = date.split('.');
      return parts.length === 3 && parts.every((part) => part.length > 0); // Проверка, что все части (день, месяц, год) введены
    };

    this.isInvalidValue =
      (fromDateValue && !isCompleteDate(fromDateValue)) ||
      (toDateValue && !isCompleteDate(toDateValue)) ||
      (!fromDateControl.value && !toDateControl.value);

    if (fromDateValue && isCompleteDate(fromDateValue)) {
      const fromDate = moment(fromDateValue, 'DD.MM.YYYY', true); // 'true' включает строгую валидацию

      if (!fromDate.isValid()) {
        this.isInvalidRange = true;
        fromDateControl?.setErrors({ invalidDate: true });
      }
    }

    if (toDateValue && isCompleteDate(toDateValue)) {
      const toDate = moment(toDateValue, 'DD.MM.YYYY', true);

      if (!toDate.isValid()) {
        this.isInvalidRange = true;
        toDateControl?.setErrors({ invalidDate: true });
      }
    }

    // Преобразуем minDate и maxDate в локальное время для сравнения
    const minDateLocal = this.minDate ? this.minDate.local() : null;
    const maxDateLocal = this.maxDate ? this.maxDate.local() : null;

    // Проверка minDate
    if (minDateLocal && fromDateValue && moment(fromDateValue, 'DD.MM.YYYY', true).isBefore(minDateLocal, 'day')) {
      this.isInvalidRange = true;
      fromDateControl?.setErrors({ invalidRange: true });
    }

    // Проверка maxDate
    if (maxDateLocal && toDateValue && moment(toDateValue, 'DD.MM.YYYY', true).isAfter(maxDateLocal, 'day')) {
      this.isInvalidRange = true;
      toDateControl?.setErrors({ invalidRange: true });
    }

    // Проверка правильности диапазона
    if (fromDateValue && toDateValue) {
      const fromDate = moment(fromDateValue, 'DD.MM.YYYY', true);
      const toDate = moment(toDateValue, 'DD.MM.YYYY', true);

      if (fromDate.isValid() && toDate.isValid() && fromDate.isAfter(toDate)) {
        this.isInvalidRange = true;
        fromDateControl?.setErrors({ invalidRange: true });
        toDateControl?.setErrors({ invalidRange: true });
      }
    }
  }

  apply(): void {
    if (this.isInvalidRange) {
      return;
    }

    const { value } = this.form;
    this.submitEvent.emit(value);
  }

  search(value: string): void {
    this.localValue.name = value;
    this.searchEvent.emit(value);
  }

  select(value: string): void {
    this.localValue.name = value;
  }

  cancel(): void {
    this.closeEvent.emit();
  }
}
