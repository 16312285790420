<table class="customer-info">
  <thead>
    <tr>
      <th class="th-id">ID</th>
      <th>Заказчик</th>
      <th>ИНН заказчика</th>
      <th>Владелец КТ</th>
      <th>Телефон</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="td-id">{{ owner.id || '-' }}</td>
      <td>{{ owner.company.legal_status | legalStatus }} {{ owner | initials }}</td>
      <td>{{ owner.company.itn || '-' }}</td>
      <td>{{ owner | initials }}</td>
      <td>
        <div *ngFor="let phone of owner.phones">{{ phone | textSeparator : '-' : 3 }}</div>
      </td>
    </tr>
  </tbody>
</table>

<table class="supplier-info">
  <thead>
    <tr>
      <th class="th-id">ID</th>
      <th>Поставщик</th>
      <th>ИНН поставщика</th>
      <th>Ответственный в КТ</th>
      <th>Телефон</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="td-id">{{ supplier.id || '-' }}</td>
      <td>{{ supplier.company.legal_status | legalStatus }} {{ supplier | initials }}</td>
      <td>{{ supplier.company.itn || '-' }}</td>
      <td>{{ supplier | initials }}</td>
      <td>
        <div *ngFor="let phone of supplier?.phones">{{ phone | textSeparator : '-' : 3 }}</div>
      </td>
    </tr>
  </tbody>
</table>
