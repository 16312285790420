// Маски для модуля: https://www.npmjs.com/package/angular2-text-mask
export const MASKS = {
  // 8 923 600 11 12
  phone: [
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
  ],
  passport_division_code: [/[\d]/, /[\d]/, /[\d]/, '-', /[\d]/, /[\d]/, /[\d]/],
  only_number: [
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
  ],
  only_number_non_residency: [
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
  ],
  card: [
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    '-',
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    '-',
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    '-',
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
  ],
  number_start: [
    /[*\d]/,
    /[*\d]/,
    /[*\d]/,
    /[*\d]/,
    /[*\d]/,
    /[*\d]/,
    /[*\d]/,
    /[*\d]/,
    /[*\d]/,
    /[*\d]/,
    /[*\d]/,
    /[*\d]/,
    /[*\d]/,
    /[*\d]/,
    /[*\d]/,
    /[*\d]/,
    /[*\d]/,
    /[*\d]/,
    /[*\d]/,
    /[*\d]/,
  ],
  dateMask: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/],
  timeMask: [/\d/, /\d/, ':', /\d/, /\d/],
  criteriaDateMask: [/\d/, /\d/, /\d/, /\d/, '-', /[0-1]/, /[0-9]/, '-', /[0-3]/, /\d/],
  dateTimeSeparatorMask: [' ', '-', ' '],
};

export const getCriteriaDateMask = (val: string) => {
  const mask = [...MASKS.criteriaDateMask];
  const firstDateNumberPosition = 8;
  const firstDateNumber = getNumber(val, firstDateNumberPosition);

  const firstMonthNumberPosition = 5;
  const firstMonthNumber = getNumber(val, firstMonthNumberPosition);

  if (firstDateNumber && +firstDateNumber > 2) {
    mask[firstDateNumberPosition + 1] = /[0-1]/;
  } else {
    mask[firstDateNumberPosition + 1] = /\d/;
  }

  if (firstMonthNumber && +firstMonthNumber > 0) {
    mask[firstMonthNumberPosition + 1] = /[0-2]/;
  } else {
    mask[firstMonthNumberPosition + 1] = /[1-9]/;
  }

  function getNumber(value: string, position: number) {
    return value ? value[position] : null;
  }

  return {
    mask: mask,
    keepCharPositions: true,
  };
};

export const getTimeMask = (val: string) => {
  return [/[0-2]/, val && val[0] && +val[0] > 1 ? /[0-3]/ : /\d/, ':', /[0-5]/, /\d/];
};

export function getDateTimeMask(value: string): {
  mask: Array<string | RegExp>;
  keepCharPositions: boolean;
} {
  const valueForDate = value ? value.slice(0, [...MASKS.criteriaDateMask].length) : null;
  const valueForTime = value
    ? value.slice([...MASKS.criteriaDateMask].length + MASKS.dateTimeSeparatorMask.length)
    : null;
  const dateMask = getCriteriaDateMask(valueForDate).mask;
  const timeMask = getTimeMask(valueForTime);

  return {
    mask: [].concat(dateMask, MASKS.dateTimeSeparatorMask, timeMask),
    keepCharPositions: true,
  };
}
