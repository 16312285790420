import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ViewModeEnum } from '@app/file-manager/models/view-mode.enum';
import { BufferInterface, BufferTypeEnum } from '@app/file-manager/models/buffer.model';
import { UserFile } from '@app/file-manager/models/user-file.model';
import { MAX_ATTACH_FILES_SIZE } from '@app/file-manager/constants/file-manager-base.constants';
import { FileManagerAreaService } from '@app/file-manager/services/file-manager-area.service';
import { FileManagerAreaPlaceCallType } from '@app/file-manager/models/file-manager-area.enum';

@Component({
  selector: 'app-file-manager-area',
  templateUrl: './file-manager-area.component.html',
  styleUrls: ['./file-manager-area.component.scss'],
})
export class FileManagerAreaComponent {
  @Input() selectedFiles: UserFile[] = [];

  @Input() files: UserFile[] = [];
  @Input() folderCheck: boolean = true;
  @Input() currentFileSelected: UserFile | null = null;
  @Input() bufferFiles: Partial<BufferInterface> | null = null;
  @Input() viewMode: ViewModeEnum;
  @Input() isSearchQuery: boolean = false;
  @Input() searchQuery: string = '';
  @Input() searchAreaFiles: UserFile[] = [];
  @Input() openDelayTooltip?: number;
  @Input() isFileSizeLimit?: boolean = false;

  @Output() readonly onSelected = new EventEmitter<UserFile[]>();
  @Output() readonly onSelectedViewFile = new EventEmitter<UserFile>();
  @Output() readonly onOpenSelectedFile = new EventEmitter<UserFile>();
  @Output() readonly onShowUserInfo = new EventEmitter<UserFile>();
  @Output() readonly onShareFile = new EventEmitter<UserFile>();

  get viewModeType(): typeof ViewModeEnum {
    return ViewModeEnum;
  }

  get searchMyAreaFiles(): UserFile[] {
    return this.searchAreaFiles?.filter((item) => !item?.in_shared_dir);
  }

  get searchSharedAreaFiles(): UserFile[] {
    return this.searchAreaFiles?.filter((item) => item?.in_shared_dir);
  }

  get placeCall(): FileManagerAreaPlaceCallType {
    return this.fileManagerAreaService.placeCall;
  }

  constructor(private readonly fileManagerAreaService: FileManagerAreaService) {}

  /**
   * Флаг выбранного файла
   * @param file
   */
  isSelectedFileStatus(file: UserFile): boolean {
    const findFile = this.selectedFiles.find((item) => item?.id === file?.id);
    return !!findFile;
  }

  /**
   * Флаг выбранного файла
   * @param file
   */
  isCutFileStatus(file: UserFile): boolean {
    const findFile = this.bufferFiles?.files.find((item) => item.id === file.id);
    return this.bufferFiles?.type === BufferTypeEnum.CUT && !!findFile;
  }

  /**
   * Гребаная бесполезная подложка
   * @param file
   */
  isSelectCurrentSelected(file: UserFile) {
    if (!this.currentFileSelected) {
      return false;
    }
    return file.id === this.currentFileSelected?.id;
  }

  /**
   * Выбор/снятие выбора файла/папки
   * @param file
   * @param isSelected
   */
  selectUserFile(file: UserFile, isSelected: boolean): void {
    let findFile = null;
    if (this.isSearchQuery) {
      const unionSearchFiles = [...this.searchAreaFiles];
      findFile = unionSearchFiles.find((item) => item.id === file.id);
    } else {
      findFile = this.files.find((item) => item.id === file.id);
    }
    if (isSelected) {
      this.selectedFiles.push(findFile);
    } else {
      const findFileIndex = this.selectedFiles.findIndex((item) => item.id === file.id);
      this.selectedFiles.splice(findFileIndex, 1);
    }
    this.onSelected.emit(this.selectedFiles);
  }

  selectViewFile(file: UserFile): void {
    this.onSelectedViewFile.emit(file);
  }

  openSelectFile(file: UserFile): void {
    this.onOpenSelectedFile.emit(file);
  }

  shareFile(file: UserFile): void {
    this.onShareFile.emit(file);
  }

  showUserInfo(file: UserFile): void {
    this.onShowUserInfo.emit(file);
  }

  canSelectFile(file: UserFile) {
    if (!file?.is_file) return false;
    const fileSizeMb = file?.size / Math.pow(1024, 2);

    return this.isFileSizeLimit ? fileSizeMb < MAX_ATTACH_FILES_SIZE : true;
  }
}
