import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { ChatService } from '@app/chat/services/chat.service';
import { MessagesSelectService } from '@app/chat/services/messages-select.service';
import { SocketDataService } from '@app/services/socket-data.service';
import { DestroyService } from '@app/services/destroy.service';
import { TechTypeEnum } from '@app/chat/constants/chat-sections.constants';

@Component({
  selector: 'app-chat-tab',
  templateUrl: './chat-tab.component.html',
  styleUrls: ['./chat-tab.component.scss'],
  providers: [DestroyService],
})
export class ChatTabComponent implements OnChanges {
  @Input() tab: any;
  @Input() active: boolean = false;
  @Input() isDragging: boolean;
  @Output() isDraggingChange = new EventEmitter<boolean>();

  isCollapsed$ = this.chatService.isCollapsed$.asObservable();
  isPinned = false;

  constructor(
    private readonly elRef: ElementRef,
    private readonly chatService: ChatService,
    private readonly chatDataService: SocketDataService,
    private readonly router: Router,
    private messagesSelectService: MessagesSelectService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.active && changes.active.currentValue === true) {
      this.chatService.scrollMeToTab(this.elRef);
    }

    this.isPinned = Boolean(this.chatService.pinnedTabs.find((tab) => this.tab.room_id === tab.room_id));
  }

  onTabSelect() {
    if (this.isDragging) {
      this.isDraggingChange.emit(false);
      return;
    }

    this.chatService.setNewCurrentTechType(this.tab.tech_section_type);
    this.chatService.setRoomSelected(this.tab);

    this.chatService.isCollapsed$.next(false);

    this.chatService.openChat(this.tab.room_id);

    this.messagesSelectService.clearMessageSelected();
  }

  getTabTitle(): string {
    if (this.tab.group_id) {
      return `Тема ${this.tab.title}`;
    }
    if (this.tab.title && !this.tab.group_id) {
      return `Группа ${this.tab.title}`;
    }

    const fullName =
      this.tab.tech_section_type === TechTypeEnum.MY_TECH
        ? 'ОТП'
        : `${this.tab.second_name || ''} ${this.tab.first_name || ''}`.trim();
    return fullName || this.tab.email || 'Без имени';
  }

  onTabRemove($event: Event) {
    $event.stopPropagation();

    if (this.isPinned) {
      this.chatService.toggleTabPinned$.next(this.tab);
    }

    this.chatService.clearContactSelected();
    this.chatService.deleteTab(this.tab);
    this.chatService.goToRoot();

    const messagesIds = this.chatService.getMessagesRoomStore(this.tab.room_id).list.map((message) => message.id) || [];

    if (this.tab.counter > 0) {
      // Если будут баги - возможно сделать задержку перед получением данной переменной
      // Или формировать ее из  this.tab.tech_section_type
      this.chatDataService.setMessageRead(
        this.tab.room_id,
        messagesIds,
        this.chatService.isDutyTsoAction(),
        this.chatService.isTsoAction()
      );
    }
  }

  onTabPin($event: Event) {
    $event.stopPropagation();

    this.chatService.toggleTabPinned$.next(this.tab);
  }
}
