<dc-form-field-wrapper
  [formGroup]="formParent"
  [style]="fieldParams?.style || 'bootstrap'"
  [required]="validate || required"
  [vertically]="fieldParams?.vertically !== false"
  class="datapicker"
>
  <ng-container *ngIf="controlInputNameDate">
    <dc-form-label *ngIf="fieldParams?.dateLabel">{{ fieldParams.dateLabel }}</dc-form-label>
    <input
      formInput
      mask="00.00.0000"
      class="datapicker-date"
      [showMaskTyped]="true"
      [dropSpecialCharacters]="false"
      [formControlName]="controlInputNameDate"
      [attr.min]="minDate ? minDate.format(BACKEND_DATE_FORMAT) : null"
      [attr.max]="maxDate ? maxDate.format(BACKEND_DATE_FORMAT) : null"
    />
    <button type="button" class="icon-button" (click)="onCalendarWidgetOpen()" #buttonDate>
      <app-svg-icon [height]="16" [width]="14" name="calendar"></app-svg-icon>
    </button>
  </ng-container>
  <ng-container *ngIf="controlInputNameTime">
    <dc-form-label *ngIf="fieldParams?.timeLabel" class="datapicker-title-time">{{
      fieldParams.timeLabel
    }}</dc-form-label>
    <input
      #inputTime
      formInput
      mask="00:00"
      class="datapicker-time"
      [showMaskTyped]="true"
      [dropSpecialCharacters]="false"
      [formControlName]="controlInputNameTime"
    />
    <button type="button" class="icon-button" (click)="onTimeWidgetOpen()">
      <app-svg-icon [height]="16" [width]="16" name="clock"></app-svg-icon>
    </button>
  </ng-container>
</dc-form-field-wrapper>
<div [innerHTML]="textError()" *ngIf="showError && showErrorFlag()" class="datapicker-error"></div>
