<div class="tso-options-title-wrapper">
  <h6 class="tso-options-title">Управление статусом ОТП</h6>
  <span class="icon-link">
    <button class="icon-button">
      <app-svg-icon name="question-circle-color" width="16" height="16"></app-svg-icon>
    </button>
  </span>
</div>

<div class="tso-options">
  <div class="tso-option-wrapper" *ngFor="let option of tsoOptionsConfig">
    <p>{{ option.title }}</p>
    <div class="tso-option">
      <div class="dc-radio-input radio-button" [class.disabled]="!isReadyTso">
        <input
          [id]="option.valueName + 'activate'"
          [value]="true"
          [name]="option.valueName"
          type="radio"
          [checked]="option.value"
          (click)="$event.preventDefault(); changeStatusTso(option, true)"
        />
        <label class="radio-label" [for]="option.valueName + 'activate'">Активирован</label>
      </div>
      <div class="dc-radio-input radio-button" [class.disabled]="!isReadyTso">
        <input
          [id]="option.valueName + 'deactivate'"
          [value]="false"
          [name]="option.valueName"
          type="radio"
          [checked]="!option.value"
          (click)="$event.preventDefault(); openConfirmRemove(option)"
        />
        <label class="radio-label" [for]="option.valueName + 'deactivate'">Деактивирован</label>
      </div>
    </div>
  </div>
</div>

<ng-container *ngFor="let option of tsoOptionsConfig">
  <app-modal-window [isShowed]="option.isConfirmModalOpen" (closeEvent)="cancelChange(option)" centered="true">
    <p class="mb-3">
      {{ option.confirmTitle }}
    </p>
    <p class="mb-4">{{ option.confirmText }}</p>
    <div class="d-flex justify-content-between">
      <button class="btn btn-dc-outline" (click)="cancelChange(option)">Нет, отменить</button>
      <button class="btn btn-dc-primary" (click)="changeStatusTso(option, false)">Да, применить</button>
    </div>
  </app-modal-window>
</ng-container>
