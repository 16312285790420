import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
// TODO: обновить socket.io-client до v4
const { io } = require('socket.io-client');

import { environment } from '@app/environments/environment';
import { CHAT_SECTIONS, ChatSectionsEnum } from '../chat/constants/chat-sections.constants';

import { AuthService } from '../shared/services/auth.service';
import { ChatService } from '../chat/services/chat.service';
import { RolesEnum } from '../shared/constants/roles.constants';
import { User } from '../shared/models/user.model';
import { UserTypes } from '../shared/types/user.types';

import {
  ChatGroup,
  ChatGroupUpdate,
  ChatMessage,
  ChatThemeTradeUpdate,
  GroupRemove,
  Theme,
  ThemeRemove,
  ThemeTechUpdate,
  ThemeUpdate,
  TradeTheme,
  ChatUserTree,
  TechGroup,
  ChatRoom,
  TechGroupRemove,
  TechThemeRemove,
  ChatMessageResponse,
} from '../chat/models/chat.model';

import { catchError, map, takeUntil } from 'rxjs/operators';
import { forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { NotificationService } from '@app/notification/services/notification.service';
import {
  NOTIFICATION_CREATE_ZIP,
  NOTIFICATION_NOT_ENOUGH_FREE_SPACE,
  NOTIFICATION_NOT_FREE_SPACE,
} from '@app/shared/constants/file-manager.constants';
import { UserFileInterface } from '@app/file-manager/models/user-file.model';
import { TradeRating, TradeTabsLocked } from '@app/+trades/models/trades.model';
import type { CounterData, SocketResponseRoomData, LoadMessagesResponse } from '@app/chat/models';
import { DEFAULT_MESSAGE_LOAD_COUNT } from '@app/shared/constants/chat.constants';
import { ChatData } from '@app/shared/models/chat-data.models';
import { Company } from '@app/shared/models/company.model';
import type { NotificationCounter } from '@app/shared/models/chat-data.models';
import { NotificationsService } from 'angular2-notifications';
import { convertMessagesAttachToUserFile } from '@app/chat/helpers/convert-messages-attach-to-user-file';
import { MessageDraftParams } from '@app/file-manager/models/message-draft.interface';
import { ChatSectionService } from '@app/chat/services/chat-section.service';

@Injectable({
  providedIn: 'root',
})
export class SocketDataService implements OnDestroy {
  private url = environment.api_url;
  private host = new URL(this.url).host;
  private destroy$ = new Subject();
  private socketVersions = new Map<string, number>();

  private options: SocketIOClient.ConnectOpts = {
    path: '/api/v1/im',
    transports: ['websocket', 'polling'],
    query: {
      auth_token: null,
    },
  };
  private socket: SocketIOClient.Socket;
  private user: User;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private chatService: ChatService,
    private notificationService: NotificationService,
    private notify: NotificationsService,
    private chatSectionService: ChatSectionService
  ) {
    this.authService.userStream.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
      this.chatService.userId = +this.authService.user_id;
      this.chatService.userType = this.authService.user_type;
      this.options.query['auth_token'] = this.authService.getToken();

      if (this.socket) return;
      this.init();
    });
  }

  init() {
    this.socket = io(this.host, this.options);

    this.socket.on('connect', () => {
      this.connected();
      this.getAllChatData();
    });

    this.socket.on('update users', ({ data, version }: { data: User[]; version: number }) => {
      // console.log('USERS', users);
      if (!this.updateSocketVersion('update users', version)) return;
      this.chatService.storeUsers(data);
    });

    this.socket.on('update users tree', ({ data, version }: { data: ChatUserTree; version: number }) => {
      // console.log('USERS tree', usersTree);
      if (!this.updateSocketVersion('update users tree', version)) return;
      this.chatService.storeUserTree(data);
    });

    this.socket.on('update companies', ({ data, version }: { data: Company[]; version: number }) => {
      // console.log('companies', companies);
      if (!this.updateSocketVersion('update companies', version)) return;
      this.chatService.storeCompanies(data);
    });

    this.socket.on('update duty tso', ({ data, version }: { data: User[]; version: number }) => {
      // console.log('tso', tso);
      if (!this.updateSocketVersion('update duty tso', version)) return;
      this.chatService.storeDutyTso(data);
    });

    this.socket.on('tso deleted', ({ data, version }: { data: { removed_tso_id: number }; version: number }) => {
      if (!this.updateSocketVersion('tso deleted', version)) {
        return;
      }
      this.chatService.setRemovedTsoId(data.removed_tso_id);
    });

    for (const key in CHAT_SECTIONS) {
      if (CHAT_SECTIONS.hasOwnProperty(key)) {
        const sectionName = CHAT_SECTIONS[key].name;

        this.socket.on(
          `update ${sectionName} contacts`,
          ({ data, version }: { data: SocketResponseRoomData[]; version: number }) => {
            if (this.isCanShowContacts(sectionName)) {
              // console.log(`update ${sectionName} contacts`, rooms);
              if (!this.updateSocketVersion(`update ${sectionName} contacts`, version)) return;
              this.chatService.storeRooms(data, CHAT_SECTIONS[key]);
            }
          }
        );

        this.socket.on(`update ${sectionName} groups`, ({ data, version }: { data: ChatRoom[]; version: number }) => {
          // console.log(`update ${sectionName} groups`, groups);
          if (!this.updateSocketVersion(`update ${sectionName} groups`, version)) return;
          this.chatService.updateGroups(data, CHAT_SECTIONS[key]);
        });

        this.socket.on(`delete ${sectionName} groups`, ({ data, version }: { data: any; version: number }) => {
          // console.log(`REMOVED GROUPS ${sectionName}`, groups);
          if (!this.updateSocketVersion(`delete ${sectionName} groups`, version)) return;
          this.chatService.removeGroup(data, CHAT_SECTIONS[key]);
        });

        this.socket.on(`update ${sectionName} topics`, ({ data, version }: { data: any; version: number }) => {
          // console.log(`update ${sectionName} topics`, themes);
          if (!this.updateSocketVersion(`update ${sectionName} topics`, version)) return;
          this.chatService.updateThemes(data, CHAT_SECTIONS[key]);
        });

        this.socket.on(`delete ${sectionName} topics`, ({ data, version }: { data: any; version: number }) => {
          // console.log(`REMOVED THEMES ${sectionName}`, themes);
          if (!this.updateSocketVersion(`delete ${sectionName} topics`, version)) return;
          this.chatService.removeTheme(data, CHAT_SECTIONS[key]);
        });
      }
    }

    this.socket.on('chat message', ({ data, version }: { data: ChatMessageResponse; version: number }) => {
      // console.log('chat message', message);
      if (!this.updateSocketVersion('chat message', version)) return;
      this.getMessage(data);
    });

    this.socket.on('delete message', ({ data, version }: { data: ChatMessage; version: number }) => {
      // console.log('delete message', message);
      if (!this.updateSocketVersion('delete message', version)) return;
      this.chatService.deleteMessage(data);
    });

    this.socket.on('update room counter', ({ data, version }: { data: CounterData[]; version: number }) => {
      // console.log('update room counter', data);
      if (!this.updateSocketVersion('update room counter', version)) return;
      this.chatService.updateCounters(data);
    });

    this.socket.on('update admin managers', ({ data, version }: { data: any; version: number }) => {
      // console.log('update admin managers', data);
      if (!this.updateSocketVersion('update admin managers', version)) return;
      this.chatService.updateAdminManager(data);
    });

    this.socket.on('delete admin managers', ({ data, version }: { data: any; version: number }) => {
      // console.log('delete admin managers', data);
      if (!this.updateSocketVersion('delete admin managers', version)) return;
      this.chatService.deleteAdminManager(data);
    });

    this.socket.on('count new notification', ({ data, version }: { data: NotificationCounter; version: number }) => {
      // console.log('count new notification', data);
      if (!this.updateSocketVersion('count new notification', version)) return;
      this.notificationService.updateCounters(data);
    });

    this.socket.on('notification', ({ data, version }: { data: any; version: number }) => {
      // console.log('notification', data);
      if (!this.updateSocketVersion('notification', version)) return;

      if (
        data?.notification?.type === NOTIFICATION_CREATE_ZIP ||
        data?.notification?.type === NOTIFICATION_NOT_ENOUGH_FREE_SPACE ||
        data?.notification?.type === NOTIFICATION_NOT_FREE_SPACE
      ) {
        this.notificationService.addFileNotification(data);
      }
      this.notificationService.addNotification(data);
    });

    this.socket.on('trade rating online', ({ data, version }: { data: TradeRating; version: number }) => {
      // console.log('trade rating online', data);
      if (!this.updateSocketVersion('trade rating online', version)) return;
      this.notificationService.updateCustomerPlayTrades(data);
    });

    this.socket.on('disconnect', () => this.disconnected());
  }

  private updateSocketVersion(eventName: string, newVersion: number): boolean {
    const currentVersion = this.socketVersions.get(eventName) || 0;

    if (newVersion < currentVersion) return false;

    this.socketVersions.set(eventName, newVersion);
    return true;
  }

  getAllChatData() {
    const availableSections = Object.keys(this.chatSectionService.chatSections);
    const endpoints = {
      admin: availableSections.includes('admin')
        ? this.http.get<ChatData>(`${this.url}/services/admin-chat`)
        : of(null),
      tech: availableSections.includes('tech') ? this.http.get<ChatData>(`${this.url}/services/tech-chat`) : of(null),
      trade: availableSections.includes('trade')
        ? this.http.get<ChatData>(`${this.url}/services/trade-chat`)
        : of(null),
      holding: availableSections.includes('holding')
        ? this.http.get<ChatData>(`${this.url}/services/holding-chat`)
        : of(null),
      users: this.http.get<ChatData>(`${this.url}/services/users`),
      notification: this.http.get<ChatData>(`${this.url}/services/notification`),
    };

    forkJoin(endpoints)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.setAllChatData(data);

          this.chatService.chatLoaded.next(true);
          this.chatService.chatLoading.next(false);
        },
        error: () => this.notify.error('Чат временно не доступен, попробуйте позже.'),
      });
  }

  private setAllChatData(data: {
    admin: ChatData;
    tech: ChatData;
    trade: ChatData;
    holding: ChatData;
    users: ChatData;
    notification: ChatData;
  }) {
    // console.log('update ALL chat data', data);
    if (data.users) {
      this.updateSocketVersion('update users', data.users.version);
      this.chatService.storeUsers(data.users.update_users);

      if (data.users?.update_users_tree) {
        this.updateSocketVersion('update users tree', data.users.version);
        this.chatService.storeUserTree(data.users.update_users_tree);
      }
    }

    ['admin', 'tech', 'trade', 'holding'].forEach((section) => {
      const sectionData = data[section as keyof typeof data];
      if (sectionData) {
        this.setChatSectionData(section, sectionData);
      }
    });

    if (data.notification) {
      this.updateSocketVersion('update room counter', data.notification.version);
      this.chatService.updateCounters(data.notification.update_room_counter);
      this.updateSocketVersion('count new notification', data.notification.version);
      this.notificationService.updateCounters(data.notification.count_new_notification);
    }
  }

  setChatSectionData(sectionName: string, sectionData: ChatData) {
    const section = CHAT_SECTIONS[sectionName];

    this.updateSocketVersion('update companies', sectionData.version);
    this.chatService.storeCompanies(sectionData.update_companies);

    this.updateSocketVersion(`update ${sectionName} contacts`, sectionData.version);
    this.chatService.storeRooms(sectionData[`update_${sectionName}_contacts`], section);

    this.updateSocketVersion(`update ${sectionName} groups`, sectionData.version);
    this.chatService.updateGroups(sectionData[`update_${sectionName}_groups`], section);

    this.updateSocketVersion(`update ${sectionName} topics`, sectionData.version);
    this.chatService.updateThemes(sectionData[`update_${sectionName}_topics`], section);

    if (sectionData?.update_duty_tso) {
      this.updateSocketVersion('update duty tso', sectionData.version);
      this.chatService.storeDutyTso(sectionData.update_duty_tso);
    }
  }

  trackTradeTabsLocking(): Observable<TradeTabsLocked> {
    return new Observable<TradeTabsLocked>((observer) => {
      this.socket.emit('join_rooms_by_user', { auth_token: this.authService.getToken() });

      this.socket.on('trade tabs locked', ({ data, version }: { data: TradeTabsLocked; version: number }) => {
        if (!this.updateSocketVersion('trade tabs locked', version)) return;
        observer.next(data);
      });

      return () => {
        this.socket.off('trade tabs locked');
      };
    });
  }

  trackSupplierTradeTabsLocking(): Observable<TradeTabsLocked> {
    return new Observable<TradeTabsLocked>((observer) => {
      this.socket.emit('join_rooms_by_user', { auth_token: this.authService.getToken() });

      this.socket.on('provider trade tabs locked', ({ data, version }: { data: TradeTabsLocked; version: number }) => {
        if (!this.updateSocketVersion('provider trade tabs locked', version)) return;
        observer.next(data);
      });

      return () => {
        this.socket.off('provider trade tabs locked');
      };
    });
  }

  emitEmployeesAccessChanged(): Observable<void> {
    return new Observable<void>((observer) => {
      this.socket.emit('join_rooms_by_user', { auth_token: this.authService.getToken() });

      this.socket.on('system notification', () => {
        observer.next();
      });

      return () => {
        this.socket.off('system notification');
      };
    });
  }

  isCanShowContacts(sectionName: ChatSectionsEnum) {
    return (
      (([RolesEnum.ADMIN_OF_DIRECTION, RolesEnum.ADMIN_OF_USER, RolesEnum.OPERATOR] as UserTypes[]).includes(
        this.user.type
      ) &&
        (sectionName === ChatSectionsEnum.HOLDING || sectionName === ChatSectionsEnum.TRADE)) ||
      (([RolesEnum.SUPERUSER, RolesEnum.ACCOUNTANT, RolesEnum.EXPERT, RolesEnum.PARTNER] as UserTypes[]).includes(
        this.user.type
      ) &&
        sectionName === ChatSectionsEnum.ADMIN) ||
      sectionName === ChatSectionsEnum.TECH
    );
  }

  getUpdateGroupEventName(sectionName: ChatSectionsEnum): string {
    return `update ${sectionName} groups`;
  }

  // themes
  addTopic(topic: Theme) {
    this.http
      .post(`${this.url}/chat/${topic.section}_topics/${topic.group_id}`, {
        title: topic.title,
        users: topic.users.map((user) => +user),
      })
      .subscribe();
  }

  addTechTopic(topic: TechGroup) {
    this.http
      .post(`${this.url}/chat/tech_topics/${topic.group_id}`, {
        title: topic.title,
        users: topic.users.map((user) => +user),
        create_from: topic.create_from,
      })
      .subscribe();
  }

  addTradeTopic(topic: TradeTheme, id: number) {
    this.http
      .post(`${this.url}/chat/trade-topics/${id}`, {
        title: topic.title,
        users: topic.users.map((user) => +user),
        trade_id: topic.trade_id,
      })
      .subscribe();
  }

  updateTradeTopic(topic: ChatThemeTradeUpdate) {
    this.http
      .patch(`${this.url}/chat/trade-topics/${topic.id}`, {
        title: topic.title,
        users: topic.users?.map((user) => +user),
      })
      .subscribe();
  }

  removeTradeTopic(theme_id: string | number) {
    this.http.delete(`${this.url}/chat/trade-topics/${theme_id}`).subscribe();
  }

  updateTechTopic(topic: ThemeTechUpdate) {
    this.http
      .patch(`${this.url}/chat/tech_topic/${topic.id}`, {
        title: topic.title,
        is_duty_tso: topic.is_duty_tso,
        is_tso: topic.is_tso,
      })
      .subscribe();
  }

  updateTopic(topic: ThemeUpdate) {
    this.http
      .patch(`${this.url}/chat/${topic.section}_topic/${topic.id}`, {
        title: topic.title,
        users: topic.users,
        is_duty_tso: topic.is_duty_tso,
        is_tso: topic.is_tso,
      })
      .subscribe();
  }

  removeTopic(topic: ThemeRemove) {
    this.http.delete(`${this.url}/chat/${topic.section.name}_topic/${topic.topic_id}`).subscribe(() => {
      delete this.chatService.themes[topic.section.name][topic.room_id];
      this.chatService.updateThemes([], topic.section);
    });
  }

  removeTechTopic(topic: TechThemeRemove) {
    this.http
      .delete(`${this.url}/chat/tech_topic/${topic.topic_id}`, {
        body: { is_duty_tso: topic.is_duty_tso, is_tso: topic.is_tso },
      })
      .subscribe();
  }

  loadMessages(data: {
    room_id: string;
    offset: number;
    count?: number;
    is_duty_tso?: boolean;
    is_tso?: boolean;
  }): Observable<LoadMessagesResponse> {
    const count = data.count ?? DEFAULT_MESSAGE_LOAD_COUNT;
    let params = new HttpParams().set('count', count).set('offset', data.offset).set('direction', 'desc');
    if (data.is_duty_tso) {
      params = params.append('is_duty_tso', data.is_duty_tso.toString());
    }
    if (data.is_tso) {
      params = params.append('is_tso', data.is_tso.toString());
    }
    /* сортировку desc менять нельзя. Вопреки Solid на бэке это не просто сортировка, но и направление отсчёта для offset
     * нужно либо бэк дорабатывать(т.к. сейчас этим заниматься не будут, не приоритет - оставляю этот комментарий здесь),
     * либо придётся переписывать логику на фронте
     * в частности из-за этого мы получаем перевёрнутый массив сообщений и не можем избавиться от reverse, т.к. нужно вернуть его в норму.
     * */

    return this.http.get<LoadMessagesResponse>(`${this.url}/chat/room/${data.room_id}/messages`, { params });
  }

  loadRangeMessages(room_id: string, rangeMessagesId: [number, number]): Observable<LoadMessagesResponse> {
    const params = new HttpParams()
      .set('filter', `[{"name": "id", "op": "between", "val": [${rangeMessagesId}]}]`)
      .set('direction', 'desc');
    return this.http.get<LoadMessagesResponse>(`${this.url}/chat/room/${room_id}/messages`, { params });
  }

  loadMessage(
    room_id: string,
    messageId: number,
    is_tso?: boolean,
    is_duty_tso?: boolean
  ): Observable<LoadMessagesResponse> {
    let params = new HttpParams()
      .set('filter', `[{"name": "id", "op": "eq", "val": ${messageId}}]`)
      .set('direction', 'desc');

    if (is_tso) {
      params = params.set('is_tso', is_tso);
    }
    if (is_duty_tso) {
      params = params.set('is_duty_tso', is_duty_tso);
    }
    return this.http.get<LoadMessagesResponse>(`${this.url}/chat/room/${room_id}/messages`, { params });
  }

  getMessage(responseMessage: ChatMessageResponse) {
    const [message] = convertMessagesAttachToUserFile([responseMessage]);
    this.chatService.updateMessages(message);
  }

  setMessageRead(room_id: string, ids: number[], is_duty_tso?: boolean, is_tso?: boolean) {
    const body = { room_id, messages_ids: ids, is_duty_tso, is_tso };
    if (!is_duty_tso) {
      delete body.is_duty_tso;
    }
    if (!is_tso) {
      delete body.is_tso;
    }
    this.http.post(`${this.url}/chat/mark_as_read`, body).subscribe();
  }

  sendMessage({
    room_id,
    content = '',
    attached_files_ids = [],
    user_files_ids = [],
    parent_id = null,
    is_duty_tso = false,
    is_tso = false,
  }: MessageDraftParams) {
    const params: MessageDraftParams = { room_id, content, attached_files_ids, user_files_ids, is_duty_tso, is_tso };
    if (parent_id) params.parent_id = parent_id;

    return this.http.post(`${this.url}/chat/messages`, params);
  }

  updateMessageDraft({
    room_id,
    content = '',
    attached_files_ids = [],
    user_files_ids = [],
    parent_id = null,
    is_duty_tso = false,
    is_tso = false,
  }: MessageDraftParams) {
    const params: MessageDraftParams = { room_id, content, attached_files_ids, user_files_ids, is_duty_tso, is_tso };
    if (parent_id) params.parent_id = parent_id;

    return this.http.post(`${this.url}/chat/message-draft`, params).subscribe();
  }

  getMessageDraft(room_id: string) {
    return this.http
      .get<ChatMessageResponse>(`${this.url}/chat/room/${room_id}/message-draft`)
      .pipe(map((responseMessage) => convertMessagesAttachToUserFile([responseMessage]).shift()));
  }

  deleteMessage(messages_ids: Array<number>, room_id: string, is_duty_tso?: boolean, is_tso?: boolean) {
    const body = {
      messages_ids,
      room_id,
      is_duty_tso,
      is_tso,
    };
    if (!is_duty_tso) {
      delete body.is_duty_tso;
    }

    if (!is_tso) {
      delete body.is_tso;
    }

    this.http.delete(`${this.url}/chat/message`, { body }).subscribe();
  }

  forwardMessages(messages_ids: number[], rooms_ids: string[]) {
    return this.http.post(`${this.url}/chat/messages/forward`, { messages_ids, rooms_ids });
  }

  addGroup(group: ChatGroup) {
    this.http
      .post(`${this.url}/chat/${group.section}_groups`, {
        title: group.title,
        users: group.users,
      })
      .subscribe();
  }

  addTechGroup(group: TechGroup) {
    this.http
      .post(`${this.url}/chat/tech_groups`, {
        title: group.title,
        users: group.users,
        create_from: group.create_from,
      })
      .subscribe();
  }

  changeAdminGroupOwner(group: ChatGroupUpdate) {
    this.http
      .patch(`${this.url}/chat/admin_group/${group.group_id}`, {
        owner_id: group.owner_id,
      })
      .subscribe(() => {
        if (group.owner_id === this.user.id) {
          delete group.owner_id;
          this.updateGroup(group);
        } else {
          this.updateAdminGroupOwner(group);
        }
      });
  }

  updateAdminGroupOwner(group) {
    const updatedGroups = this.chatService.getGroups().map((item) => {
      if (item.id === group.id) {
        item.owner_id = group.owner_id;
      }
      return item;
    });
    this.chatService.updateGroups(updatedGroups, CHAT_SECTIONS.admin);
  }

  updateGroup(group: ChatGroupUpdate) {
    this.http
      .patch(`${this.url}/chat/${group.section}_group/${group.group_id}`, {
        title: group.title,
        users: group.users,
        owner_id: group.owner_id,
        is_duty_tso: group.is_duty_tso,
        is_tso: group.is_tso,
      })
      .subscribe();
  }

  removeGroup(group: GroupRemove) {
    this.http.delete(`${this.url}/chat/${group.section}_group/${group.group_id}`).subscribe();
  }

  removeTechGroup(group: TechGroupRemove) {
    this.http
      .delete(`${this.url}/chat/tech_group/${group.group_id}`, {
        body: { is_duty_tso: group.is_duty_tso, is_tso: group.is_tso },
      })
      .subscribe();
  }

  // groups end
  connect() {
    this.socket.connect();
  }

  disconnect() {
    this.socket.disconnect();
    this.chatService.resetChatState();
  }

  private connected() {
    console.log('Connected to chat');
  }

  private disconnected() {
    /* Сбрасываем флаги - нужно для повторного получения данных при реконнекте.
     * chatLoading - true > при дисконекте появится лоадер
     * chatLoaded - false > флаг, что бы при восстановлении соединения данные обновились */
    this.chatService.chatLoading.next(true);
    this.chatService.chatLoaded.next(false);
    console.log('Disconnected from chat');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getRootFolderId(): Observable<number> {
    return this.http.get<UserFileInterface[]>(`${environment.api_url}/user/${this.chatService.userId}/files/root`).pipe(
      map((data) => data[0].id),
      catchError((err) => throwError(err))
    );
  }

  createRoomId(user_id: number) {
    return this.http.post(`${this.url}/chat/contacts/${user_id}`, {});
  }
}
