export type InviteSupplierFilterStatus = 'empty' | 'invite' | 'invited' | 'admitted' | 'declined';

export interface InviteStatusFilter {
  id: InviteSupplierFilterStatus;
  label?: string;
  checked: boolean;
}

export const INVITE_STATUS_FILTERS: InviteStatusFilter[] = [
  {
    id: 'empty',
    label: 'Без статуса',
    checked: false,
  },
  {
    id: 'invite',
    label: 'Пригласить',
    checked: false,
  },
  {
    id: 'invited',
    label: 'Приглашён',
    checked: false,
  },
  {
    id: 'admitted',
    label: 'Допущен',
    checked: false,
  },
  {
    id: 'declined',
    label: 'Отказ поставщика',
    checked: false,
  },
];
