import { ChatGroupOrThemeRemoveComponent } from '@app/chat/chat-groups/chat-group-or-theme-remove/chat-group-or-theme-remove.component';
import { ChatGroupThemeNameFormComponent } from '@app/chat/chat-groups/chat-group-theme-name-form/chat-group-theme-name-form.component';
import { ChatSectionService } from '@app/chat/services/chat-section.service';
import { MessagesSelectService } from '@app/chat/services/messages-select.service';
import { ModalService } from '@app/services/modal.service';
import { takeUntil } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { ROLES, RolesEnum } from '@app/shared/constants/roles.constants';
import { AuthService } from '@app/shared/services/auth.service';
import { ChatService } from '@app/chat/services/chat.service';
import { SocketDataService } from '@app/services/socket-data.service';
import { ChatSectionsEnum, GroupSortingNames, TechTypeEnum } from '@app/chat/constants/chat-sections.constants';
import { ChatRoom, ChatSection } from '@app/chat/models/chat.model';
import { User } from '@app/shared/models/user.model';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-chat-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
})
export class ChatGroupComponent implements OnInit, OnDestroy {
  @Input() isModalView: boolean = false;
  @Input() group: ChatRoom;
  @Input() contactSelected: ChatRoom;
  @Input() techType: TechTypeEnum | null = null;
  @Input() sortType: GroupSortingNames = GroupSortingNames.LAST_MESSAGE_DATE;

  TechTypeEnum = TechTypeEnum;
  roles = ROLES;
  themes: ChatRoom[] = [];
  allThemes: ChatRoom[] = [];
  userId: number;
  user: User;
  filterString: string;
  chatSectionSelected: ChatSection;
  groupSelected: ChatRoom;
  chatSectionsEnum = ChatSectionsEnum;
  listWidth = 310;
  isGroupExpanded = false;

  private isCurrentUserDutyTso = false;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  protected totalUnreadMessagesCounter: number | '10+';

  get isOwner() {
    return !(
      this.userId === this.group.owner_id ||
      (this.user.type === RolesEnum.SUPERUSER && this.chatSectionSelected.name !== ChatSectionsEnum.TECH) ||
      (this.chatSectionSelected.name === ChatSectionsEnum.TECH &&
        this.isCurrentUserDutyTso &&
        this.techType === TechTypeEnum.ALL_USERS) ||
      this.userId === this.group.partner_owner_id
    );
  }

  get isActiveClass() {
    if (this.isModalView) return false;
    return (
      (this.contactSelected?.room_id === this.group.room_id || this.groupSelected?.room_id === this.group.room_id) &&
      // используем не строгое сравнение так как в тех разделе нужно сравнивать со строкой,
      // а в остальных разделах значение могу быть null и undefined
      (this.contactSelected?.tech_section_type == this.techType ||
        this.groupSelected?.tech_section_type == this.techType)
    );
  }

  get disableCreateThemeButtonInTech() {
    return (
      this.chatSectionSelected.name === this.chatSectionsEnum.TECH &&
      this.techType === TechTypeEnum.ALL_USERS &&
      !this.group.flags?.is_owner_duty_tso
    );
  }

  constructor(
    private chatService: ChatService,
    private chatDataService: SocketDataService,
    private authService: AuthService,
    private readonly notify: NotificationsService,
    private chatSectionService: ChatSectionService,
    private messagesSelectService: MessagesSelectService,
    private readonly modalService: ModalService
  ) {}

  ngOnInit() {
    this.chatSectionSelected = this.chatSectionService.chatSectionSelected;

    this.isGroupExpanded = this.chatService.isTradeExpanded(this.group?.room_id);

    this.chatService.contactsFilterChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((filterString) => {
      this.filterString = filterString;
      this.filterThemes(this.chatService.getGroupsThemes(this.chatSectionSelected.name)['themes']);
    });

    this.authService.userStream.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => (this.user = user));
    this.userId = +this.authService.user_id;
    this.filterThemes(this.chatService.getGroupsThemes(this.chatSectionSelected.name)['themes']);

    this.chatService.themesChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((themes) => {
      this.filterThemes(themes);
    });

    this.totalUnreadCounter();
    this.chatService.groupsChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.totalUnreadCounter();
    });

    this.chatSectionService.chatSectionSelectedChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((chatSectionSelected) => (this.chatSectionSelected = chatSectionSelected));

    this.chatService.groupSelectedChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((groupSelected) => {
      this.groupSelected = groupSelected;
    });

    this.chatService.isDutyTso$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => (this.isCurrentUserDutyTso = res));
  }

  filterThemes(themes): void {
    this.themes = [];
    this.allThemes = [];

    if (this.chatSectionSelected.name !== ChatSectionsEnum.TECH && !themes[this.techType]) {
      Object.keys(themes).forEach((room_id) => {
        const theme = themes[room_id];
        if (theme.group_id === this.group.id) {
          this.themes.push(theme);
        }
        this.allThemes.push(theme);
      });
    }

    if (this.chatSectionSelected.name === ChatSectionsEnum.TECH && this.techType && themes[this.techType]) {
      Object.keys(themes[this.techType]).forEach((room_id) => {
        const theme = themes[this.techType][room_id];
        if (theme.group_id === this.group.id) {
          this.themes.push(theme);
        }
        this.allThemes.push(theme);
      });
    }

    // Для тех. чата Удалить
    // if (this.group.topics) {
    //   this.group.topics.forEach((t) => {
    //     t.section = this.chatSectionSelected;
    //     this.themes.push(t);
    //   });
    // }

    this.totalUnreadCounter();
  }

  public toggleRoomSelected(group: ChatRoom) {
    this.messagesSelectService.toggleRoomSelected(group);
  }

  public isRoomSelected(group: ChatRoom) {
    return this.messagesSelectService.isRoomSelected(group);
  }

  onGroupSelect(group: ChatRoom): void {
    if (this.isModalView) return;
    if (group.filterDisabled) {
      this.notify.warn('Внимание', 'Данной группы (темы) нет среди результатов поиска');
      return;
    }

    if (this.chatSectionSelected.name !== this.chatSectionsEnum.TRADE) {
      this.chatService.setRoomSelected(group);
      this.chatService.setNewCurrentTechType(group.tech_section_type);
      this.chatService.focusChanged.next(true);
    }
    this.messagesSelectService.clearMessageSelected();

    const roomId = this.chatSectionSelected.name !== this.chatSectionsEnum.TRADE ? group.room_id : null;
    this.chatService.openChat(roomId);
  }

  onAddTheme($event: Event) {
    $event.stopPropagation();
    this.chatService.setEditingGroupOrThemeObject({
      group_id: this.group.id,
      owner_id: this.group.owner_id,
      trade_id: this.group.trade_id,
      group: this.group,
    } as ChatRoom);
    this.chatService.isEditingGroupOrThemeChanged.next(true);
  }

  onGroupEdit() {
    this.chatService.setEditingGroupOrThemeObject(this.group);
    this.chatService.isEditingGroupOrThemeChanged.next(true);
  }

  onRemoveGroupAsk() {
    const modalInstance = this.modalService.open(
      ChatGroupOrThemeRemoveComponent,
      { centered: true },
      {
        isGroup: true,
      }
    );

    const componentInstance = modalInstance.componentInstance;

    componentInstance.approved.pipe(takeUntil(this.chatService.chatDestroyed$)).subscribe((isApproved: boolean) => {
      if (isApproved) {
        this.onRemoveGroup();
      }

      modalInstance.close();
    });
  }

  onRemoveGroup() {
    switch (this.chatSectionSelected.name) {
      case ChatSectionsEnum.ADMIN:
      case ChatSectionsEnum.HOLDING:
        this.chatDataService.removeGroup({
          group_id: this.group.id,
          section: this.chatSectionService.chatSectionSelected.name,
        });
        break;

      case ChatSectionsEnum.TECH:
        this.chatDataService.removeTechGroup({
          group_id: this.group.id,
          is_duty_tso: this.isCurrentUserDutyTso && this.techType === TechTypeEnum.ALL_USERS,
          is_tso: this.techType === TechTypeEnum.MY_USERS,
        });
        break;
    }

    this.themes.forEach((theme) => this.chatService.removeTheme(theme, this.chatSectionSelected.name));
    this.chatService.removeTheme(this.group, this.chatSectionSelected.name);
  }

  openRenameGroupModal() {
    const modalInstance = this.modalService.open(
      ChatGroupThemeNameFormComponent,
      { centered: true },
      {
        name: this.group.title,
        title: 'Переименовать группу',
        placeholder: 'Введите название группы',
      }
    );

    const componentInstance = modalInstance.componentInstance;

    componentInstance.cancelEdit
      .pipe(takeUntil(this.chatService.chatDestroyed$))
      .subscribe(() => modalInstance.close());

    componentInstance.saveName.pipe(takeUntil(this.chatService.chatDestroyed$)).subscribe((name: string) => {
      this.renameGroup(name);
      modalInstance.close();
    });
  }

  openCreateThemeModal() {
    const modalInstance = this.modalService.open(
      ChatGroupThemeNameFormComponent,
      { centered: true },
      {
        placeholder: 'Введите название темы',
        title: 'Создать тему',
      }
    );

    const componentInstance = modalInstance.componentInstance;

    componentInstance.cancelEdit
      .pipe(takeUntil(this.chatService.chatDestroyed$))
      .subscribe(() => modalInstance.close());

    componentInstance.saveName.pipe(takeUntil(this.chatService.chatDestroyed$)).subscribe((name: string) => {
      this.createTheme(name);
      modalInstance.close();
    });
  }

  renameGroup(title: string) {
    if (this.chatSectionSelected.name === ChatSectionsEnum.TECH) {
      this.chatDataService.updateGroup({
        section: this.chatSectionSelected.name,
        title,
        group_id: this.group.id,
        is_duty_tso: this.isCurrentUserDutyTso && this.techType === TechTypeEnum.ALL_USERS,
        is_tso: this.techType === TechTypeEnum.MY_USERS,
      });
    } else {
      this.chatDataService.updateGroup({
        section: this.chatSectionSelected.name,
        title,
        users: this.group.users,
        group_id: this.group.id,
      });
    }
  }

  createTheme(title: string) {
    const users: number[] = [this.user.id];
    switch (this.chatSectionSelected.name) {
      case ChatSectionsEnum.ADMIN:
      case ChatSectionsEnum.HOLDING:
        this.chatDataService.addTopic({
          section: this.chatSectionSelected.name,
          group_id: this.group.id,
          title,
          users,
        });
        break;

      case ChatSectionsEnum.TECH:
        const create_from = this.group.flags?.is_owner_duty_tso
          ? this.TechTypeEnum.ALL_USERS
          : this.TechTypeEnum.MY_USERS;
        this.chatDataService.addTechTopic({
          group_id: this.group.id,
          title,
          users,
          create_from,
        });
        break;
    }
  }

  onToggleGroupExpanded() {
    if (!this.themes.length) {
      return;
    }
    this.isGroupExpanded = !this.isGroupExpanded;

    this.group.isGroupExpanded = this.isGroupExpanded;
    if (this.group.isGroupExpanded) {
      this.chatService.addTradeExpanded(this.group?.room_id);
    } else {
      this.chatService.removeTradeExpanded(this.group?.room_id);
    }
  }

  // Актуализировать или удалить метод
  canEditGroup() {
    return (
      this.userId === this.group.owner_id ||
      (this.chatSectionSelected.name === this.chatSectionsEnum.TECH && this.userId === this.group.id) ||
      (this.chatSectionSelected.name === this.chatSectionsEnum.ADMIN && this.user.type === RolesEnum.SUPERUSER)
    );
  }

  totalUnreadCounter() {
    const nestedTheme: ChatRoom[] = [...this.themes];

    this.themes.forEach((theme) => {
      const findNestedTheme = this.allThemes.filter((item) => item.group_id === theme.id);

      if (findNestedTheme.length) {
        findNestedTheme.forEach((findTheme) => {
          nestedTheme.push(findTheme);
        });
      }
    });

    if (this.chatSectionSelected.name !== this.chatSectionsEnum.TRADE) {
      this.totalUnreadMessagesCounter = this.chatService.totalUnreadCount([this.group]);
    } else {
      this.totalUnreadMessagesCounter = this.chatService.totalUnreadCount(nestedTheme);
    }
  }

  trackByRoomId(index: number, room: ChatRoom): string {
    return room.room_id;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
