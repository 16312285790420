import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ITreeOptions, TREE_ACTIONS, TreeModel, TreeNode } from '@circlon/angular-tree-component';
import { startWith, takeUntil } from 'rxjs/operators';

import { keepCaretState } from '@app/+competence-map/helpers/competence-map.helpers';
import { TreeSectionsAndFilters } from '@app/+competence-map/models/user-competency.model';
import { DestroyService } from '@app/services/destroy.service';

@Component({
  selector: 'app-tree-checkbox-list',
  templateUrl: './tree-checkbox-list.component.html',
  styleUrls: ['./tree-checkbox-list.component.scss'],
  providers: [DestroyService],
})
export class TreeCheckboxListComponent implements OnInit {
  @Input() placeholder: string;
  @Input() showSubmitButton: boolean = true;
  @Input() showDoubleCarets: boolean = false;
  @Input() readonlyFunction: (item: TreeNode) => boolean;

  readonly keepCaretState = keepCaretState;

  searchControl = new FormControl('');

  allFiltersSelected: boolean = false;
  treeOptions: ITreeOptions = {
    displayField: 'title',
    isExpandedField: 'expanded',
    idField: 'id',
    nodeHeight: 23,
  };
  isCatalogTreeExpandedL1 = false;
  isCatalogTreeExpandedL2 = false;

  private fullList: TreeSectionsAndFilters[];

  private _treeList: TreeSectionsAndFilters[];

  @Output() submitEvent = new EventEmitter<TreeSectionsAndFilters[]>();
  @Output() selectEvent = new EventEmitter<TreeSectionsAndFilters[]>();

  @Input() set treeList(items: TreeSectionsAndFilters[]) {
    this.searchControl.valueChanges.pipe(startWith(''), takeUntil(this.destroy$)).subscribe((searchValue) => {
      if (!searchValue) {
        this._treeList = items;
      } else {
        this._treeList = this.filterTree(items, searchValue.toLowerCase());
      }
    });
  }

  @Input() set filterValue(ids: number[]) {
    if (ids?.length && this._treeList.length) {
      this.allFiltersSelected = true;
      this.setNodeSelectedAndExpandedById(this._treeList, ids);
    }
  }

  constructor(private destroy$: DestroyService) {}

  get treeNodes() {
    return this._treeList;
  }

  get isChangeAllHidden(): boolean {
    return !this._treeList?.length ?? false;
  }

  get partiallySelected(): boolean {
    return this._treeList.some((item) => item.selected) && !this.isAllSelected(this.fullList);
  }

  get isApplyDisable(): boolean {
    if (this.allFiltersSelected) {
      return false;
    }

    return !this.partiallySelected;
  }

  ngOnInit() {
    this.fullList = this._treeList;
    this.searchControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      this.searchControl.setValidators(null);
      this.searchControl.setErrors(null);
    });
  }

  expandItem(tree: TreeModel, node: TreeNode, $event: any): void {
    TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
  }

  checkboxSelectionChanged({ node }: { id: number; node: TreeNode }): void {
    if (node.data.children.length) {
      this.setChildrenSelected(node.data, node.data.selected);
    }

    this.updateParentSelection(node);
    this.updateAllSelectedCheckbox();

    const selectedItems = this._treeList.filter((item) => item.selected);

    this.selectEvent.emit(selectedItems);
  }

  isSelected(id: number): boolean {
    return this._treeList.some((item) => id === item.id && item.selected);
  }

  changeAll(isReset = false): void {
    this.allFiltersSelected = isReset ? false : !this.allFiltersSelected;

    this._treeList.forEach((item) => {
      this.setChildrenSelected(item, this.allFiltersSelected);
    });

    const selectedItems = this._treeList.filter((item) => item.selected);

    this.selectEvent.emit(selectedItems);
  }

  apply(): void {
    const selectedItems = this._treeList.filter((item) => item.selected);
    this.submitEvent.emit(selectedItems);
  }

  toggleByLevel(tc: any, level: number): void {
    const tree: TreeModel = tc.treeModel;
    switch (level) {
      case 1:
        if (this.isCatalogTreeExpandedL1) {
          tree.getVisibleRoots().forEach((node: TreeNode) => node.collapse());
          this.isCatalogTreeExpandedL1 = false;
          this.isCatalogTreeExpandedL2 = false;
        } else {
          tree.getVisibleRoots().forEach((node: TreeNode) => node.expand());
          this.isCatalogTreeExpandedL1 = true;
        }
        break;
      case 2:
        if (!this.isCatalogTreeExpandedL2 && !this.isCatalogTreeExpandedL1) {
          this.toggleByLevel(tc, 1);
        }
        if (this.isCatalogTreeExpandedL2) {
          tree.getVisibleRoots().forEach((node: TreeNode) => {
            node.getVisibleChildren().forEach((n: TreeNode) => n.collapse());
          });
          this.isCatalogTreeExpandedL2 = false;
        } else {
          tree.getVisibleRoots().forEach((node: TreeNode) => {
            node.getVisibleChildren().forEach((n: TreeNode) => n.expand());
          });
          this.isCatalogTreeExpandedL2 = true;
        }
        break;
    }
  }

  private updateParentSelection(node: TreeNode): void {
    const parent = this.findParent(node);

    if (parent) {
      parent.data.selected = parent.data.children.some((child) => child.selected);
      this.updateParentSelection(parent);
    }
  }

  private findParent(node: TreeNode): TreeNode | null {
    if (node.parent) {
      return node.parent;
    }
    return null;
  }

  private updateAllSelectedCheckbox(): void {
    this.allFiltersSelected = this.isAllSelected(this._treeList);
  }

  private isAllSelected(items: TreeSectionsAndFilters[]): boolean {
    for (const item of items) {
      if (!item.selected || (item.children && !this.isAllSelected(item.children))) {
        return false;
      }
    }
    return true;
  }

  private setChildrenSelected(node: TreeSectionsAndFilters, selected: boolean): void {
    if (typeof this.readonlyFunction === 'function' && this.readonlyFunction(node as unknown as TreeNode)) {
      return;
    }

    node.selected = selected;

    if (node.children && node.children.length) {
      node.children.forEach((child) => {
        this.setChildrenSelected(child, selected);
      });
    }
  }

  private filterTree(tree: TreeSectionsAndFilters[], searchValue: string): TreeSectionsAndFilters[] {
    return tree
      .map((node) => {
        const clonedNode = { ...node };
        clonedNode.expanded = true;

        if (
          clonedNode?.section?.title.toLowerCase().includes(searchValue) ||
          clonedNode?.title.toLowerCase().includes(searchValue)
        ) {
          return clonedNode;
        }

        if (clonedNode.children?.length) {
          clonedNode.children = this.filterTree(clonedNode.children, searchValue);
        }

        return clonedNode.children?.length ? clonedNode : null;
      })
      .filter(Boolean);
  }

  private setNodeSelectedAndExpandedById(tree: TreeSectionsAndFilters[], ids: number[]): void {
    if (!tree.length) {
      return;
    }

    tree.forEach((node) => {
      if (ids.includes(+node.section_id)) {
        node.selected = true;
        node.expanded = true;
      }
      if (node.children?.length) {
        this.setNodeSelectedAndExpandedById(node.children, ids);
      }
    });
  }

  resetList($event: Event): void {
    this.changeAll(true);
  }
}
