import { TsoSchedule, TsoScheduleTimeItem } from '../models/duty.model';
import * as moment from 'moment';
import { BACKEND_DATE_TIME_FORMAT } from '../constants/date.constants';

export class DutyHelper {
  static generateSchedule(date: Date): TsoSchedule {
    return {
      date,
      timeline: this.generateTimeline(date),
    };
  }

  static generateTimeline(date: Date): TsoScheduleTimeItem[] {
    const hours = 24;

    const result: TsoScheduleTimeItem[] = [];

    for (let i = 0; i < hours; i++) {
      const fixed = moment(date).set({ hour: i, minute: 0, second: 0 });
      result.push({
        timeFormat: fixed.format('HH:mm'),
        startDate: fixed.startOf('hour').format(BACKEND_DATE_TIME_FORMAT),
        endDate: fixed.endOf('hour').format(BACKEND_DATE_TIME_FORMAT),
        timestamp: fixed.valueOf(),
        duty: [],
        dutyInactive: [],
        activeUsers: [],
        processUsers: [],
        agreedUsers: [],
        processAgentUsers: [],
        processSuperuserUsers: [],
        countProcessUsers: 0,
        countActiveUsers: 0,
      });
    }

    return result;
  }

  static formatStartDateForBackend(date: Date) {
    return moment(date).startOf('day').format(BACKEND_DATE_TIME_FORMAT);
  }

  static formatEndDateForBackend(date: Date) {
    return moment(date).endOf('day').format(BACKEND_DATE_TIME_FORMAT);
  }

  static isTimelineItemLate(item: TsoScheduleTimeItem) {
    return +new Date(item.startDate) < +new Date();
  }

  static isTimelineItemLateInTimezone(item: TsoScheduleTimeItem, timezone: number, isDutyNow?: boolean) {
    // Поравка по времени для согласованных дОТП, чтобы их можно было снять во время дежурства
    const timeCorrection = isDutyNow ? 60 : 0;
    return (
      moment(item.startDate)
        .subtract(timezone - timeCorrection - moment().utcOffset(), 'minutes')
        .valueOf() < moment().utc().valueOf()
    );
  }

  static isTimelineItemMore90(item: TsoScheduleTimeItem) {
    return moment(item.startDate).valueOf() > moment().add(90, 'd').valueOf();
  }
}
