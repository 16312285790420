<table class="customer-company-info">
  <thead>
    <tr>
      <th>ID</th>
      <th>Заказчик</th>
      <th>ИНН заказчика</th>
      <th>Владелец КТ</th>
      <th>Телефон</th>
      <th>Плательщик</th>
      <th>ИНН плательщика</th>
      <th>Тариф за торги</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <!-- После обнуления БД посыпалиь ошибки в этом месте - пока добавил проверку на undefined -->
      <td>{{ owner?.id || '-' }}</td>
      <td>{{ owner?.company?.legal_status | legalStatus }} {{ owner | initials }}</td>
      <td>{{ owner?.company.itn || '-' }}</td>
      <td>{{ owner | initials }}</td>
      <td>
        <div *ngFor="let phone of owner?.phones">{{ phone | textSeparator : '-' : 3 }}</div>
      </td>
      <td>{{ payer?.company?.legal_status | legalStatus }} {{ (payer | initials) || '-' }}</td>
      <td>{{ payer?.company?.itn || '-' }}</td>
      <td>{{ tariff }} &#8381;</td>
    </tr>
  </tbody>
</table>
