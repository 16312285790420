import { ChatSections } from '@app/chat/models/chat.model';
import { SectionSortingOptions, SortOption } from '@app/chat/models/chat.sorting-options.model';

export enum ChatSectionsEnum {
  HOLDING = 'holding',
  TRADE = 'trade',
  ADMIN = 'admin',
  TECH = 'tech',
  // ToDo Убрать этот тип плюс везде где о нем есть  упоминание тоже убрать
  DUTY_TSO = 'tso',
}

export const CHAT_SECTIONS: ChatSections = {
  holding: {
    name: ChatSectionsEnum.HOLDING,
    icon: ['sitemap'],
    title: 'Корпоративный раздел',
    counter: 0,
  },
  trade: {
    name: ChatSectionsEnum.TRADE,
    icon: ['gavel'],
    title: 'Торговый раздел',
    counter: 0,
  },
  admin: {
    name: ChatSectionsEnum.ADMIN,
    icon: ['user-group'],
    title: 'Административный раздел',
    counter: 0,
  },
  tech: {
    name: ChatSectionsEnum.TECH,
    icon: ['tools'],
    title: 'Технический раздел',
    counter: 0,
  },
  // otp: {
  //   name: ChatSectionsEnum.DUTY_TSO,
  //   icon: ['tools'],
  //   isSelected: false,
  //   title: 'Раздел для деж. ОТП',
  //   counter: 0,
  // },
};

export enum TradeRoomsTypes {
  CUSTOMER_OTHER = 'customer_other',
  PROVIDER_OTHER = 'provider_other',
  PROVIDER_CUSTOMER = 'provider_customer',
  CUSTOMER = 'customer',
  PROVIDER = 'provider',
}

export enum GroupSortingNames {
  LAST_MESSAGE_DATE = 'last_message_date',
  CREATED_DATE = 'created_date',
  TITLE = 'title',
  OWNER = 'owner',
}

export enum ContactsSortingNames {
  ROLE = 'role',
  NAME = 'name',
}

export enum TechTypeEnum {
  MY_TECH = 'my_tech_support',
  MY_USERS = 'my_users',
  ALL_USERS = 'all_users',
}

const GROUP_SORTING_OPTIONS: SortOption[] = [
  { name: GroupSortingNames.LAST_MESSAGE_DATE, label: 'Группы с новыми сообщениями - сверху' },
  { name: GroupSortingNames.OWNER, label: 'Мои группы - сверху' },
  { name: GroupSortingNames.TITLE, label: 'По алфавиту' },
];

const ADMIN_CONTACT_SORTING_OPTIONS: SortOption[] = [
  { name: ContactsSortingNames.ROLE, label: 'По роли' },
  { name: ContactsSortingNames.NAME, label: 'По алфавиту' },
];

const HOLDING_CONTACT_SORTING_OPTIONS: SortOption[] = [
  { name: ContactsSortingNames.ROLE, label: 'По подчинённости' },
  { name: ContactsSortingNames.NAME, label: 'По алфавиту' },
];

const TRADE_GROUP_SORTING_OPTIONS: SortOption[] = [
  { name: GroupSortingNames.LAST_MESSAGE_DATE, label: 'Группы с новыми сообщениями - сверху' },
  { name: GroupSortingNames.OWNER, label: 'Мои группы - сверху' },
];

export const SECTION_SORTING_OPTIONS: SectionSortingOptions = {
  admin: {
    groups: GROUP_SORTING_OPTIONS,
    contacts: ADMIN_CONTACT_SORTING_OPTIONS,
  },
  holding: {
    groups: GROUP_SORTING_OPTIONS,
    contacts: HOLDING_CONTACT_SORTING_OPTIONS,
  },
  trade: {
    groups: {
      customer: TRADE_GROUP_SORTING_OPTIONS,
      provider: TRADE_GROUP_SORTING_OPTIONS,
    },
  },
};

export enum MessageActionType {
  DELETE = 'Удалить',
  FORWARD = 'Переслать',
}
