import { Component, Input } from '@angular/core';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { User } from '@app/shared/models/user.model';

@Component({
  selector: 'app-winners-providers-cell',
  templateUrl: './winners-providers.component.html',
  styleUrls: ['./winners-providers.component.scss'],
})
export class WinnersProvidersComponent {
  private _providers: User[];
  get providers(): User[] {
    return this._providers;
  }
  @Input() set providers(value: User[]) {
    this._providers = value.slice(0, 3);
  }

  providerInfoForContactsModal: User;

  isDisableTooltip(companyRef: HTMLElement) {
    return companyRef.scrollWidth <= companyRef.offsetWidth;
  }

  getCompanyName(user: User) {
    if (user.type === RolesEnum.EXPERT || user.type === RolesEnum.SUPERUSER || user.type === RolesEnum.ACCOUNTANT) {
      return 'ООО Прямой Контакт';
    } else {
      const company = user.company.name.split(' ');
      if (company[0] === 'Физическое') {
        return 'Физическое лицо';
      }
      return `${user.company.organization_type} ${user.company.organization_name}`;
    }
  }

  getFullName(user: User): string {
    return `${user.second_name || ''} ${user.first_name || ''} ${user.patronymic || ''}`;
  }
}
