import { ChatSectionService } from '@app/chat/services/chat-section.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { ROLES, RolesEnum } from '@app/shared/constants/roles.constants';
import { AuthService } from '@app/shared/services/auth.service';
import { ChatService } from '../services/chat.service';
import { ChatRoom, ChatSection, ChatUserTree } from '@app/chat/models/chat.model';
import {
  ChatSectionsEnum,
  ContactsSortingNames,
  SECTION_SORTING_OPTIONS,
  TechTypeEnum,
} from '@app/chat/constants/chat-sections.constants';
import { StatusesEnum } from '@app/shared/constants/statuses.constants';
import { UserFilterStatuses } from '@app/+competence-map/constants/user-filters.constants';
import { ChatSortingService } from '@app/chat/services/chat-sorting.service';
import { SectionSortingOptions, SortOption } from '@app/chat/models/chat.sorting-options.model';

@Component({
  selector: 'app-chat-contacts',
  templateUrl: './chat-contacts.component.html',
  styleUrls: ['./chat-contacts.component.scss'],
})
export class ChatContactsComponent implements OnInit, OnDestroy {
  @Input() isModalView: boolean = false;
  // Для загрузки тех групп и контактов
  @Input() techType: TechTypeEnum | null = null;
  techTypeEnum = TechTypeEnum;
  userType: RolesEnum;
  userId: string;

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  roles = ROLES;
  rolesEnum = RolesEnum;
  userFilterStatuses = UserFilterStatuses;

  contacts: ChatRoom[] = [];
  treeContacts: ChatRoom[] = [];
  chatSectionSelected: ChatSection;
  filterString: string;
  isContactsExpanded: boolean = true;
  chatSectionsEnum = ChatSectionsEnum;
  contactsOnlyFilter;
  groupSelected: ChatRoom;
  isSortModalOpen = false;
  statusesEnum = StatusesEnum;
  contactsOnlyFilterActive$ = this.chatService.contactsOnlyFiltersState$.pipe(
    map((state) => state[this.contactsFilterName])
  );

  userTree: ChatUserTree;

  sortOptions: SortOption[];
  sortType: ContactsSortingNames = ContactsSortingNames.ROLE;

  @Input() isTreeView = false;

  private contactsFilterName = '';

  get shouldShowFilterButton() {
    if (this.techType === this.techTypeEnum.MY_TECH) {
      return false;
    }

    if (this.chatSectionSelected.name === this.chatSectionsEnum.ADMIN) {
      const restrictedRoles = [RolesEnum.ACCOUNTANT, RolesEnum.EXPERT, RolesEnum.PARTNER];
      return !restrictedRoles.includes(this.userType);
    }

    return true;
  }

  constructor(
    private chatService: ChatService,
    private authService: AuthService,
    private chatSectionService: ChatSectionService,
    private chatSortingService: ChatSortingService
  ) {}

  ngOnInit() {
    this.userType = this.authService.user_type as RolesEnum;
    this.userId = this.authService.user_id;
    this.chatSectionSelected = this.chatSectionService.chatSectionSelected;
    this.contactsFilterName = this.chatSectionSelected.name;
    this.contactsOnlyFilter = this.chatService.getContactsOnlyFilter(this.contactsFilterName);

    this.chatService.contactsFilterChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((filterString) => {
      this.filterString = filterString;
      this.updateContactList();
      this.sortContacts(this.sortType);
    });

    this.updateContactList();
    this.sortContacts(this.sortType);

    this.chatService.contactsChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.removeSelf(this.chatService.contacts[this.chatSectionSelected.name]);
      this.sortContacts(this.sortType);
    });

    this.chatService.groupSelectedChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((groupSelected) => {
      this.groupSelected = groupSelected;

      this.updateContactList();
      this.sortContacts(this.sortType);
    });

    this.chatService.themeSelectedChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((themeSelected) => {
      this.groupSelected = themeSelected;

      this.updateContactList();
      this.sortContacts(this.sortType);
    });

    this.chatSectionService.chatSectionSelectedChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((chatSection) => {
      this.chatSectionSelected = chatSection;
      this.contactsFilterName = chatSection.name;
      this.removeSelf(this.chatService.contacts[chatSection.name]);
      this.sortContacts(this.sortType);
    });

    this.filterString = this.chatService.contactsFilter;
    this.chatService.contactsOnlyFilterChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.contactsOnlyFilter = this.chatService.getContactsOnlyFilter(this.contactsFilterName);
    });

    this.chatService.userTreeChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      if (this.chatSectionSelected.name === this.chatSectionsEnum.TECH) {
        return;
      }
      this.updateContactList();
      this.sortContacts(this.sortType);
    });

    this.chatService.usersChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.updateContactList();
      this.sortContacts(this.sortType);
    });

    this.chatService.userTreeChanged.next();

    this.chatSortingService.settings$
      .pipe(
        filter((settings) => !!settings),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => {
        if (this.chatSectionSelected.name === this.chatSectionsEnum.TECH) {
        }
        type SectionWithContacts = Extract<keyof SectionSortingOptions, 'admin' | 'holding'>;
        const sectionName = this.chatSectionSelected.name as SectionWithContacts;

        this.sortType = this.chatSortingService.sortSettings[sectionName]?.contacts;
        this.sortOptions = SECTION_SORTING_OPTIONS[sectionName]?.contacts;
        this.sortContacts(this.sortType);
      });
  }

  private updateContactList(): void {
    if (this.chatSectionSelected.name === ChatSectionsEnum.HOLDING) {
      this.filterContractsToList(this.chatService.getContacts(this.chatSectionSelected.name));
      this.userTree = this.chatService.getUserTreeFiltered();
      const tempContacts = this.userTree
        ? this.formatTreeChatRoom([this.userTree])?.filter((item) => item !== undefined)
        : null;
      if (tempContacts?.length) {
        this.treeContacts = [];
        this.treeContacts = tempContacts;
      }
    } else {
      this.removeSelf(this.chatService.getContacts(this.chatSectionSelected.name));
    }

    this.chatService.usersForContactsFilter$.next(this.contacts);
  }

  toggleSortModal(): void {
    this.isSortModalOpen = !this.isSortModalOpen;
  }

  sortPopupClose(): void {
    this.isSortModalOpen = false;
  }

  removeSelf(contacts) {
    if (!this.isTreeView) {
      delete contacts[+this.authService.user_id];
    }

    if (this.chatSectionSelected.name === ChatSectionsEnum.ADMIN) {
      this.filterContractsToList(contacts);
    } else {
      this.filterContacts(contacts);
    }
  }

  filterContractsToList(contacts): void {
    this.contacts = [];

    Object.keys(contacts).forEach((room_id) => {
      this.contacts.push(contacts[room_id]);
    });

    if (
      this.chatSectionSelected.name === ChatSectionsEnum.ADMIN &&
      this.authService.user_type !== this.rolesEnum.SUPERUSER
    ) {
      this.contacts = [];
      Object.keys(contacts).forEach((item) => {
        if (contacts[item].type === this.rolesEnum.SUPERUSER || +contacts[item].id === +this.userId) {
          this.contacts.push(contacts[item]);
        }
      });
    }
  }

  filterContacts(contacts): void {
    if (Object.keys(contacts).length > 1) {
      this.contacts = [];
    }

    if (this.chatSectionSelected.name !== ChatSectionsEnum.TECH && !contacts[this.techType]) {
      Object.keys(contacts).forEach((room_id) => {
        if (this.userFilterStatuses.DELETED !== contacts[room_id].status) {
          this.contacts.push(contacts[room_id]);
        }
      });
    }

    if (this.chatSectionSelected.name === ChatSectionsEnum.TECH && this.techType && contacts[this.techType]) {
      Object.keys(contacts[this.techType]).forEach((room_id) => {
        this.contacts.push(contacts[this.techType][room_id]);
      });
    }

    if (this.chatSectionSelected.name === ChatSectionsEnum.TRADE) {
      this.filterForTrade();
    }
    this.sortContacts(this.sortType);

    if (
      this.chatSectionSelected.name === ChatSectionsEnum.ADMIN &&
      this.authService.user_type !== this.rolesEnum.SUPERUSER
    ) {
      this.contacts = [];
      Object.keys(contacts).forEach((item) => {
        if (contacts[item].type === this.rolesEnum.SUPERUSER) {
          this.contacts.push(contacts[item]);
        }
      });
    }
    this.contacts = this.contacts.filter((value, index, self) => index === self.findIndex((t) => t?.id === value?.id));

    if (Object.keys(contacts).length === 1) {
      Object.keys(contacts).forEach((item) => {
        if (contacts[item].status === this.statusesEnum.IN_ARCHIVE) {
          this.contacts = this.contacts.filter((find) => find.id !== contacts[item].id);
        }
      });
    }
  }

  formatTreeChatRoom(userTree: ChatUserTree[]) {
    return userTree?.map((item) => {
      let children = [];
      if (item.children?.length) {
        children = item.children?.map(() => this.formatTreeChatRoom(item?.children));
      }
      const find = this.contacts.find((contact) => Number(contact?.id) === +item?.id);
      if (find) find.children = children[0];
      return { ...find, filterDisabled: item.filterDisabled };
    });
  }

  changeSortType(sortType: ContactsSortingNames): void {
    this.chatSortingService.updateSortSettings({ contacts: sortType });
    this.sortPopupClose();
  }

  sortContacts(sortType: ContactsSortingNames): void {
    if (!sortType) return;

    const newOrder = this.isModalView ? ContactsSortingNames.NAME : sortType;
    this.contacts = this.chatService.sortContactsRooms({ rooms: this.contacts, newOrder });
    this.isTreeView = sortType === ContactsSortingNames.NAME;
  }

  filterForTrade() {
    if (!this.groupSelected || (this.groupSelected && !this.groupSelected.room_id)) {
      this.contacts = [];
      return;
    }

    if (this.groupSelected.section.name !== ChatSectionsEnum.TRADE) {
      this.contacts = [];
      return;
    }

    this.contacts = this.contacts.filter((contact) => this.groupSelected.users.includes(+contact.id));

    if (this.groupSelected.is_provider_in_trade && this.groupSelected.group_id) {
      this.contacts.push({
        second_name: this.groupSelected.providers.includes(+this.authService.user_id) ? 'Заказчики' : 'Поставщики',
        section: this.groupSelected.section,
        room_id: null,
      } as ChatRoom);
    }
  }

  onContactsExpand() {
    this.isContactsExpanded = !this.isContactsExpanded;
  }

  onFilterToggle() {
    this.chatService.toggleContactsOnlyFilter(true, this.contactsFilterName);
  }

  onClearFilter() {
    this.chatService.toggleContactsOnlyFilter(false, this.contactsFilterName);
    this.chatService.setContactsOnlyFilter(null, this.contactsFilterName);
  }

  get totalUnreadCounter() {
    return this.chatService.totalUnreadCount(this.contacts);
  }

  get chatAdminView() {
    return (
      this.chatSectionSelected.name === this.chatSectionsEnum.ADMIN ||
      (this.chatSectionSelected.name === this.chatSectionsEnum.TECH && !this.isTreeView) ||
      (this.chatSectionSelected.name !== this.chatSectionsEnum.TECH && this.isTreeView)
    );
  }

  isContactTooltipDisabled(name: string, role: string) {
    return name.length + role.length < 30;
  }

  trackByRoomId(index: number, room: ChatRoom): string {
    return room.room_id;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
