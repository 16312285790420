import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[onlyInteger]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: OnlyIntegerDirective,
      multi: true,
    },
  ],
})
export class OnlyIntegerDirective {
  @Input() onlyInteger: boolean = true;
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];
  private isProcessing = false;
  private regex: RegExp = new RegExp(/^-?[0-9]*$/);

  constructor(private el: ElementRef) {}

  validate(c: FormControl): { [key: string]: { valid: boolean } } {
    const v = c.value;
    const invalid = { onlyNumber: { valid: false } };

    if (!!v && !String(v).match(this.regex)) {
      return invalid;
    }

    return null;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.onlyInteger === false) {
      return;
    }
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.el.nativeElement.value;

    if (event.key === '-' && current.length === 0) {
      return;
    }

    if (!/^[0-9]$/.test(event.key)) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;
    const input = event.target as HTMLInputElement;
    let value = input.value;

    value = value.replace(/-/g, '');
    if (input.value.startsWith('-')) {
      value = `-${value}`;
    }

    input.value = value.replace(/[^0-9a-zA-Zа-яА-ЯёЁ\-]/g, '');
    input.dispatchEvent(new Event('input'));
    this.isProcessing = false;
  }
}
