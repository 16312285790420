<ng-container *ngIf="documents.length > 0; else noDocuments">
  <ng-container *ngFor="let document of documents">
    <div class="document" [class.disabled]="isAccesChat">
      <div class="document__icon">
        <button class="icon-button" (click)="download(document)">
          <app-svg-icon name="file-download" width="12" height="16"></app-svg-icon>
        </button>
      </div>
      <div class="document__name">
        <span class="file-name text-overflow" (click)="openPreview(document)">
          {{ document.name || '-' }}
        </span>
      </div>
      <div class="document__comment">
        <ng-container *ngIf="document.comment; else noComment">
          {{ document.comment }}
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #noComment>
  <span class="no-comment">Комментарий к файлу отсутствует</span>
</ng-template>

<ng-template #noDocuments>
  <span class="no-comment">Нет документов</span>
</ng-template>
