import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { competenciesTabs, TradeColumnsKeys } from '@app/+trades/constants/trades-table.constants';
import { CatalogTab } from '@app/+competence-map/models/competence-map.models';
import { MultipleSearchSuggestion } from '@app/shared/models/multiple-search.model';
import { TreeSectionsAndFilters } from '@app/+competence-map/models/user-competency.model';
import { DoubleDatePickerParams } from '@app/shared/components/double-datepicker-filter/double-datepicker-filter.component';
import { TradeCard, TradesRequestConfig } from '@app/+trades/models/trades.model';
import { Column } from '@app/shared/models/table';
import { SliderValue } from '@app/shared/models/slider.model';
import {
  BACKEND_DATE_FORMAT,
  BACKEND_DATE_TIME_FORMAT,
  BACKEND_TIME_FORMAT,
  DATE_FORMAT,
  DATE_FORMAT_WITH_TIME,
} from '@app/shared/constants/date.constants';
import { FlaskQueryFilter } from '@app/shared/models/filters.model';
import { TradesHelper } from '@app/+trades/helpers/trades.helper';
import {
  SUPPLIER_TRADE_STATUS_LABELS,
  TRADE_CANCEL_LABELS,
  TRADE_STATUS_LABELS,
} from '@app/+trades/constants/trades.constants';
import { MultiSelectListItem } from '@app/shared/models/multi-select-list-item';
import { SupplierTradeCard } from '@app/+trades/models/suppliers.model';
import { INVITE_SUPPLIERS_LABELS } from '@app/+trades/constants/suppliers.constants';
import { takeUntil } from 'rxjs/operators';
import { DestroyService } from '@app/services/destroy.service';

@Component({
  selector: 'app-trade-grid-header-cell',
  templateUrl: './trade-grid-header-cell.component.html',
  styleUrls: ['./trade-grid-header-cell.component.scss'],
  providers: [DestroyService],
})
export class TradeGridHeaderCellComponent implements OnChanges, OnInit {
  @Input() column: Column<TradesRequestConfig>;
  @Input() goods: TreeSectionsAndFilters[];
  @Input() services: TreeSectionsAndFilters[];
  @Input() userId: string;

  @Output() changeFilterEvent = new EventEmitter<Column<TradesRequestConfig>>();

  rangeForm = new FormGroup({
    min: new FormControl(0),
    max: new FormControl(0),
  });

  filterValue = null;
  sliderValue: SliderValue = null;
  searchValue: string = '';
  sliderMinMaxValues: number[] = [0, 0];
  currentTab: CatalogTab = competenciesTabs[0];
  currentTabValue: string = this.currentTab.value;
  isMyTrade = new FormControl(false);

  readonly tabs: CatalogTab[] = competenciesTabs;
  readonly tradeColumnKeys = TradeColumnsKeys;

  private _filterSuggestions: MultipleSearchSuggestion[] | MultiSelectListItem[];
  private _rows: TradeCard[] | SupplierTradeCard[];

  constructor(private destroy$: DestroyService) {}

  @Input() set filteredRows(rows: TradeCard[] | SupplierTradeCard[]) {
    this._rows = rows;
    this.initializeFilterSuggestions(rows);
  }

  ngOnInit(): void {
    this.isMyTrade.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((isMyTrade) => {
      this._filterSuggestions = (this._filterSuggestions as Array<MultipleSearchSuggestion | MultiSelectListItem>).map(
        (suggestion: MultipleSearchSuggestion) => ({
          ...suggestion,
          value: suggestion.id === +this.userId ? isMyTrade : false,
        })
      );
    });
  }

  get filterSuggestions(): MultipleSearchSuggestion[] | MultiSelectListItem[] {
    return this._filterSuggestions;
  }

  get doubleDatePickerWithName(): boolean {
    return this.column.id === TradeColumnsKeys.PRETRADE_DATE;
  }

  get rangeFormMinControl() {
    return this.rangeForm.get('min');
  }

  get rangeFormMaxControl() {
    return this.rangeForm.get('max');
  }

  ngOnChanges() {
    if (this.column) {
      if (!this.column.filterConfig) {
        return;
      }

      this.filterValue = this.getFilterValueParams(this.column);

      if (this.column.id === TradeColumnsKeys.COMPETENCIES) {
        this.goods = TradesHelper.getCompetenciesSuggestions(this._rows, this.goods, this.services).goods;
        this.services = TradesHelper.getCompetenciesSuggestions(this._rows, this.goods, this.services).services;
      }
    }
  }

  getFilterValueParams(column: Column<TradesRequestConfig>) {
    const paramsList: FlaskQueryFilter[] = [];

    Object.keys(column.filterConfig).forEach((key) => {
      if (key === 'filter') {
        column.filterConfig.filter.forEach((item) => {
          if (item.val) {
            paramsList.push({ name: item.name, val: item.val, op: item.op });
          }
        });
      } else {
        if (column.filterConfig[key]) {
          paramsList.push({ name: column.id, val: column.filterConfig[key] });
        }
      }
    });

    switch (column.id) {
      case TradeColumnsKeys.ID:
      case TradeColumnsKeys.TITLE:
      case TradeColumnsKeys.PAYER:
      case TradeColumnsKeys.COMPETENCIES:
      case TradeColumnsKeys.STATUS:
      case TradeColumnsKeys.REASON_END_TRADE:
      case TradeColumnsKeys.CUSTOMERS:
      case TradeColumnsKeys.SUPPLIER_TRADE_ID:
      case TradeColumnsKeys.CUSTOMER_NAME:
      case TradeColumnsKeys.SUPPLIERS:
      case TradeColumnsKeys.ACCESS:
      case TradeColumnsKeys.CUSTOMER_COMPANY:
        return paramsList.find((item) => TradesHelper.getColumnNameByColumnData(column, item) === column.id)?.val;
      case TradeColumnsKeys.PROJECT_DATE:
      case TradeColumnsKeys.TRADE_DATE:
      case TradeColumnsKeys.ARCHIVE_DATE:
        return TradesHelper.getDateFilterValue(paramsList, column.id, this.column);
      case TradeColumnsKeys.PRETRADE_DATE:
        return TradesHelper.getDateWithNameFilterValue(paramsList, column.id, this.column);
      case TradeColumnsKeys.REQ_DATA_STATE:
      case TradeColumnsKeys.REQ_DATA:
        const filterValue = TradesHelper.getDateFilterValue(paramsList, column.id, this.column);
        const formattedDateFilter: DoubleDatePickerParams = {
          fromDate: filterValue?.[0] ?? null,
          toDate: filterValue?.[1] ?? null,
        };
        return formattedDateFilter;
    }
  }

  clickFilterIcon(): void {
    this.column.isFilterOpen = true;
  }

  clickFilterClearIcon(): void {
    Object.keys(this.column.filterConfig).forEach((key) => {
      if (key === 'filter') {
        this.column.filterConfig[key] = this.column.filterConfig.filter.filter(
          (item) => TradesHelper.getColumnNameByColumnData(this.column, item) !== this.column.id
        );
      } else {
        this.column.filterConfig[key] = null;
      }
    });

    this.column.isFilterActive = false;
    this.clearSelectedCheckboxes(this.goods);
    this.clearSelectedCheckboxes(this.services);

    this.changeFilterEvent.emit(this.column);
  }

  closeFilter(): void {
    this.column.isFilterOpen = false;
  }

  selectFilterSuggestions(suggestions: { value: MultipleSearchSuggestion[] }) {
    if (suggestions.value.some((suggestion) => suggestion.id !== +this.userId)) {
      this.isMyTrade.setValue(false, { emitEvent: false });
    }
  }

  onSelectTab(tab: CatalogTab): void {
    this.currentTabValue = tab.value;
  }

  getSearchValue({ value }): void {
    this.searchValue = value;
  }

  saveBaseFilter(config: { [key: string]: any }): void {
    const paramsList = Object.keys(config).map((key) => config[key]);

    Object.keys(this.column.filterConfig).forEach((key, i) => {
      if (key !== 'filter') {
        this.column.filterConfig[key] = paramsList[i];
      } else {
        this.column.filterConfig[key] = this.column.defaultFilterConfig.filter.map((item) => ({
          ...item,
          name: item.name,
          val: !item.val ? config.params : item.val,
        }));
      }
    });

    this.closeFilter();

    if (this.column.id === TradeColumnsKeys.SUPPLIERS && !this.isMyTrade.value) {
      this.column.filterConfig.filter = this.column.filterConfig.filter.filter((f) => f.name !== 'owner.id');
    }

    if (this.column.id === TradeColumnsKeys.CUSTOMERS && this.isMyTrade.value) {
      this.column.filterConfig.filter = this.column.filterConfig.filter.map((item) => ({
        ...item,
        name: 'owner.id',
      }));
    }

    this.changeFilterEvent.emit(this.column);
  }

  saveMultiSelectFilter(event: { [p: string]: any[] }): void {
    return this.saveBaseFilter({ params: event.value });
  }

  saveMultipleSearchFilter(event: { value: MultipleSearchSuggestion[] }): void {
    this.saveBaseFilter({
      params: event.value.filter((item) => item.value).map((item) => item.id),
    });
  }

  saveTreeCheckboxesFilter(items: TreeSectionsAndFilters[]): void {
    this.saveBaseFilter({ params: this.collectSelectedIds(items) });
  }

  saveDateRangeFilter(value: DoubleDatePickerParams) {
    const newValue: DoubleDatePickerParams = {
      fromDate: value.fromDate ? moment(value.fromDate, DATE_FORMAT).format(BACKEND_DATE_FORMAT) : null,
      toDate: value.toDate ? moment(value.toDate, DATE_FORMAT).format(BACKEND_DATE_FORMAT) : null,
    };

    if (newValue.fromDate && newValue.toDate) {
      this.column.filterConfig.filter = this.updateDateFilter('between', [newValue.fromDate, newValue.toDate]);
    } else if (!newValue.fromDate) {
      this.column.filterConfig.filter = this.updateDateFilter('le', newValue.toDate);
    } else if (!newValue.toDate) {
      this.column.filterConfig.filter = this.updateDateFilter('ge', newValue.fromDate);
    }

    if (newValue.fromDate === newValue.toDate) {
      this.column.filterConfig.filter = this.column.defaultFilterConfig.filter.reduce(
        (acc: FlaskQueryFilter[], curr) => {
          if (curr.op !== 'in_') {
            acc.push({ name: curr.name, op: 'ge', val: newValue.fromDate });
            acc.push({
              name: curr.name,
              op: 'lt',
              val: moment(newValue.toDate).add(1, 'days').format(BACKEND_DATE_FORMAT),
            });
          } else {
            acc.push(curr);
          }
          return acc;
        },
        []
      );
    }

    this.closeFilter();
    this.changeFilterEvent.emit(this.column);
  }

  saveDateTimeRangeFilter(value: {
    date: DoubleDatePickerParams;
    filteredSuggestions?: MultipleSearchSuggestion[];
  }): void {
    const { fromDate, fromTime, toTime } = value.date;
    let toDate = value.date.toDate;

    if (fromDate && toDate && fromDate === toDate && !fromTime && !toTime) {
      toDate = moment(toDate, DATE_FORMAT).add(23, 'hours').add(59, 'minutes').format(DATE_FORMAT_WITH_TIME);
    }

    const formatDateTime = (date: string | Date, time?: string | Date): string | null => {
      return date
        ? moment(`${date} ${time}`, `${DATE_FORMAT} ${BACKEND_TIME_FORMAT}`).format(BACKEND_DATE_TIME_FORMAT)
        : null;
    };

    const formattedFromDate = formatDateTime(fromDate, fromTime);
    const formattedToDate = formatDateTime(toDate, toTime);

    if (formattedFromDate && formattedToDate) {
      this.column.filterConfig.filter = this.updateDateFilter('between', [formattedFromDate, formattedToDate]);
    } else if (!formattedFromDate) {
      this.column.filterConfig.filter = this.updateDateFilter('le', formattedToDate);
    } else if (!formattedToDate) {
      this.column.filterConfig.filter = this.updateDateFilter('ge', formattedFromDate);
    }

    if (value.filteredSuggestions.length) {
      this.column.filterConfig.filter = this.column.filterConfig.filter.map((item) => ({
        ...item,
        val: item.op === 'in_' ? value.filteredSuggestions.map((withNameValue) => withNameValue.id) : item.val,
      }));
    }

    this.closeFilter();
    this.changeFilterEvent.emit(this.column);
  }

  saveRangeFilter(): void {
    if (this.column.id === TradeColumnsKeys.TARIFF) {
      this.saveBaseFilter({ params: [this.rangeFormMinControl.value, this.rangeFormMaxControl.value] });
    } else {
      this.saveBaseFilter(this.rangeForm.value);
    }
  }

  cancel(): void {
    this.sliderMinMaxValues = [this.sliderValue.min, this.sliderValue.max];
    this.rangeForm.patchValue({
      min: this.sliderValue.min,
      max: this.sliderValue.max,
    });
  }

  changeRange(sliderValue: SliderValue): void {
    this.rangeForm.patchValue({
      min: sliderValue.min,
      max: sliderValue.max,
    });
  }

  private initializeFilterSuggestions(rows: any[]): void {
    switch (this.column.id) {
      case TradeColumnsKeys.ID:
        this._filterSuggestions = TradesHelper.getIdColumnSuggestions(rows);
        break;
      case TradeColumnsKeys.SUPPLIER_TRADE_ID:
        this._filterSuggestions = TradesHelper.getSupplierIdColumnSuggestions(rows);
        break;
      case TradeColumnsKeys.TITLE:
        this._filterSuggestions = TradesHelper.getTitleColumnSuggestions(rows);
        break;
      case TradeColumnsKeys.CUSTOMERS:
        this._filterSuggestions = TradesHelper.getCustomerColumnSuggestions(rows);
        break;
      case TradeColumnsKeys.SUPPLIERS:
        this._filterSuggestions = TradesHelper.getSupplierColumnSuggestions(rows);
        break;
      case TradeColumnsKeys.REASON_END_TRADE:
        this._filterSuggestions = TradesHelper.getColumnSuggestionsFromLabelsConst(TRADE_CANCEL_LABELS);
        break;
      case TradeColumnsKeys.PAYER:
      case TradeColumnsKeys.PRETRADE_DATE:
        this._filterSuggestions = TradesHelper.getUserColumnSuggestions(rows, this.column.id);
        break;
      case TradeColumnsKeys.CUSTOMER_COMPANY:
        this._filterSuggestions = TradesHelper.getCustomerCompanyColumnSuggestions(rows);
        break;
      case TradeColumnsKeys.CUSTOMER_NAME:
        this._filterSuggestions = TradesHelper.getCustomerNames(rows);
        break;
      case TradeColumnsKeys.STATUS:
        if (rows[0] && 'trade' in rows[0]) {
          this._filterSuggestions = TradesHelper.getColumnSuggestionsFromLabelsConst(SUPPLIER_TRADE_STATUS_LABELS);
          return;
        }
        this._filterSuggestions = TradesHelper.getColumnSuggestionsFromLabelsConst(TRADE_STATUS_LABELS);
        break;
      case TradeColumnsKeys.ACCESS:
        this._filterSuggestions = TradesHelper.getColumnSuggestionsFromLabelsConst(INVITE_SUPPLIERS_LABELS);
        break;
      case TradeColumnsKeys.POTENTIAL_SUPPLIERS:
      case TradeColumnsKeys.SENDED_INVITATIONS:
      case TradeColumnsKeys.ACCEPTED_INVITATIONS:
      case TradeColumnsKeys.TRADE_SUPPLIER_PLAYERS:
      case TradeColumnsKeys.TARIFF:
        this.sliderValue = TradesHelper.getSliderColumnValues(rows, this.column.id);
        this.rangeForm.patchValue({
          min: this.sliderValue.min,
          max: this.sliderValue.max,
        });
        break;
      case TradeColumnsKeys.TRADE_RESULTS:
        this.sliderValue = TradesHelper.getTradeResultsColumnValues(rows);
        this.rangeForm.patchValue({
          min: this.sliderValue.min,
          max: this.sliderValue.max,
        });
        break;
    }
  }

  private collectSelectedIds(items: TreeSectionsAndFilters[]): number[] {
    let selectedIds: number[] = [];

    for (const item of items) {
      if (item.selected) {
        selectedIds = [...selectedIds, item.section.id];
      }

      if (item.children && item.children.length > 0) {
        selectedIds = selectedIds.concat(this.collectSelectedIds(item.children));
      }
    }

    return selectedIds;
  }

  private clearSelectedCheckboxes(nodes: TreeSectionsAndFilters[]): void {
    if (nodes) {
      nodes.forEach((node) => {
        node.selected = false;
        node.expanded = false;
        if (node.children) {
          this.clearSelectedCheckboxes(node.children);
        }
      });
    }
  }

  private updateDateFilter(op: string, val: any): FlaskQueryFilter[] {
    return this.column.defaultFilterConfig.filter.map((item) => ({
      ...item,
      name: item.name,
      op: item.op !== 'in_' ? op : item.op,
      val: item.op !== 'in_' ? val : item.val,
    }));
  }
}
