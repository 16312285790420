import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { MultipleSearchSuggestion, MultipleSelectConfig } from '@app/shared/models/multiple-search.model';
import { FilterPipe } from '@app/shared/pipes/filter.pipe';
import { FormControl } from '@angular/forms';
import { distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';
import { DestroyService } from '@app/services/destroy.service';

@Component({
  selector: 'app-multiple-livesearch-select',
  templateUrl: './multiple-livesearch-select.component.html',
  styleUrls: ['./multiple-livesearch-select.component.scss'],
  providers: [FilterPipe, DestroyService],
})
export class MultipleLivesearchSelectComponent implements OnInit, OnDestroy {
  @Input() template: TemplateRef<any>;
  @Input() title: string = 'Все';
  @Input() placeholder: string = '';
  @Input() isNumber: boolean = false; // отвечает за фильтр только по числлу (например только по ИД)
  @Input() disabled: boolean;
  @Input() showSearch: boolean = true;
  @Input() readonlyFunction: (item: MultipleSearchSuggestion) => boolean;

  @Output() searchEvent = new EventEmitter<string>();
  @Output() selectEvent = new EventEmitter<MultipleSearchSuggestion[]>();
  @Output() onDisabledButton = new EventEmitter<boolean>();

  searchValue = '';
  allChecked: boolean;
  isPartialSelected: boolean;
  config: MultipleSelectConfig;
  searchControl = new FormControl('', []);
  isNoSelectedItems: boolean;
  allUsers: MultipleSearchSuggestion[];

  private _list: MultipleSearchSuggestion[] = [];

  @Input() set list(list: MultipleSearchSuggestion[]) {
    if (!list.length) {
      return;
    }

    this.searchControl.valueChanges
      .pipe(startWith(''), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((searchValue) => {
        if (!searchValue) {
          this._list = list;
        } else {
          this._list = this.filterList(list, searchValue.toLowerCase());
          this.searchEvent.emit(searchValue);
        }
      });

    this._list = list;
    this.config = list[0].config;
    this.isPartialSelected = this.isPartialSelectedCheck(list);
    this.allChecked = this.list.length && this.list.every((item) => item.value);
    this.isNoSelectedItems = !this.list.some((i) => i.value);
  }

  constructor(private filterPipe: FilterPipe<MultipleSearchSuggestion>, private destroy$: DestroyService) {}

  get list(): MultipleSearchSuggestion[] {
    this.onDisabledButton.emit(!this._list?.length || this.isNoSelectedItems);
    return this._list;
  }

  get isToggleAllDisable() {
    return !this.filterPipe.transform(this._list, this.searchValue, this.searchedFieldName).length;
  }

  get partiallySelected(): boolean {
    return !this.allChecked && (this.isPartialSelected || this.isPartialSelectedCheck(this.list));
  }

  get searchedFieldName() {
    return this.config?.searchedFieldName ? this.config.searchedFieldName : 'label';
  }

  ngOnInit() {
    this.searchControl.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((val) => {
      this.searchControl.setValidators(null);
      this.searchControl.setErrors(null);
    });

    this.allUsers = this._list;
  }

  selectSuggestion(item: MultipleSearchSuggestion) {
    item.value = !item.value;
    this.allChecked = this.list.every((listItem) => listItem.value);
    this.isPartialSelected = this.isPartialSelectedCheck(this.list);
    this.isNoSelectedItems = !this.list.some((i) => i.value);
    this.selectEvent.emit(this.list);
  }

  changeAll() {
    this.list
      .filter((item) => !item.disabled && !(typeof this.readonlyFunction === 'function' && this.readonlyFunction(item)))
      .forEach((item) => {
        item.value = this.allChecked;
      });
    this.isNoSelectedItems = !this.list.some((i) => i.value);
    this.selectEvent.emit(this.list);
    this.isPartialSelected = this.isPartialSelectedCheck(this.list);
  }

  private isPartialSelectedCheck(list: MultipleSearchSuggestion[]): boolean {
    return list.some((item) => item.value) && !list.every((item) => item.value);
  }

  private filterList(list: MultipleSearchSuggestion[], searchValue: string): MultipleSearchSuggestion[] {
    const lowerCaseSearchValue = searchValue.toLowerCase();

    return list.filter(({ data, label }) => {
      const dataString =
        !this.isNumber &&
        Array.isArray(data) &&
        [data[0]?.toLowerCase(), data[1]?.toLowerCase()].filter(Boolean).join(' ');

      const labelString = typeof label === 'string' ? label.toLowerCase() : null;

      return (
        (dataString && dataString.includes(lowerCaseSearchValue)) ||
        (labelString && labelString.includes(lowerCaseSearchValue))
      );
    });
  }

  ngOnDestroy() {
    this.searchEvent.next('');
  }

  resetList($event: Event): void {
    this.allChecked = this.list.length && this.list.every((item) => item.value);
    this._list = this.allUsers;
  }
}
