import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TradeCardTabConfig } from '@app/+trades/models/trade-card-tabs.model';
import { TradesValidationService } from '@app/+trades/services/trades-validation.service';
import { AccessRight } from '@app/+trades/types/trades.types';
import { AccessRights, TradeCardStatuses } from '@app/+trades/constants/trades.constants';
import { TradeCardTabsRoutes } from '@app/+trades/constants/trade-tabs.constants';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-trade-card-tab-item',
  templateUrl: './trade-card-tab-item.component.html',
  styleUrls: ['./trade-card-tab-item.component.scss'],
})
export class TradeCardTabItemComponent implements OnInit, OnDestroy {
  @Input() tab: TradeCardTabConfig = <TradeCardTabConfig>{};
  @Input() length: number;
  @Input() index: number;
  @Input() accessRight: AccessRight;
  @Input() isCustomerCard: boolean;

  @Output() onTabChange = new EventEmitter<TradeCardTabConfig>();

  accessRights = AccessRights;
  tradeCardStatuses = TradeCardStatuses;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private tradesValidationService: TradesValidationService,
    private router: Router,
    private r: ActivatedRoute
  ) {}

  ngOnInit() {
    this.tradesValidationService.tradeCardTabValidator$
      .pipe(
        filter((value) => value.id === this.tab.id),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((value) => {
        this.tab.invalid = value.invalid;
      });
  }

  isRouteActive() {
    return this.getLastUrlFragment() === this.tab.url;
  }

  ngOnDestroy() {
    this.tab.invalid = false;

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  goTo(event) {
    if (this.tab.disabled) {
      event.preventDefault();
      return;
    }
    this.router.navigate([this.tab.url], { relativeTo: this.r });
    this.onTabChange.emit(this.tab);
  }

  private getLastUrlFragment() {
    const fragments = this.router.url.split('/');
    return fragments[fragments.length - 1];
  }
}
