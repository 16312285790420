<div [formGroup]="formRightGroup">
  <div *ngFor="let item of list" class="radio-list-wrapper">
    <label>
      <input type="radio" [value]="item" [checked]="item.name === selectedValue.name" formControlName="formRadio" />
      <ng-container *ngIf="item.iconSvg">
        <app-svg-icon class="icon" [class]="item.classes" [name]="item.iconSvg" width="16" height="16"></app-svg-icon>
      </ng-container>
      <div *ngIf="item.symbol" class="radio-symbol">{{ item.symbol }}</div>
    </label>
    <span class="status-name">{{ item.name }}</span>
  </div>
</div>

<div class="buttons-action-wrapper">
  <button type="button" class="btn btn-dc-outline btn-sm" (click)="cancel()">Отменить</button>
  <button class="btn btn-dc-primary btn-sm" (click)="submit()">Применить</button>
</div>
