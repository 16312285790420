import { Component, Input } from '@angular/core';
import { DestroyService } from '@app/services/destroy.service';
import { PersonalDataService } from '@app/shared/services/personal-data.service';
import { takeUntil } from 'rxjs/operators';
import { ChangedFlags, TsoModalConfig } from '../models/tso-options.model';

@Component({
  selector: 'app-chat-tso-options',
  templateUrl: './chat-tso-options.component.html',
  styleUrls: ['./chat-tso-options.component.scss'],
})
export class ChatTsoOptionsComponent {
  isReadyTso = true;

  @Input() set flags(flags: ChangedFlags) {
    this.tsoOptionsConfig.forEach((option) => {
      option.value = flags[option.valueName];
      option.initialValue = flags[option.valueName];
      this.isReadyTso = flags.is_ready_tso;
    });
  }

  tsoOptionsConfig: TsoModalConfig[] = [
    {
      title: 'Статус ОТП:',
      value: false,
      initialValue: false,
      valueName: 'is_tso',
      confirmTitle:
        'Внимание, отказ от статуса ОТП повлечет автоматическую и безвозвратную потерю всех пользователей, для которых вы являетесь ОТП, а также передачу прав владения всеми группами и темами, созданными Вами в пользу дОТП.',
      confirmText: 'Вы действительно хотите отказаться от статуса ОТП?',
      isConfirmModalOpen: false,
    },
    {
      title: 'ОТП в списке выбора пользователями в подразделе “Моя тех. поддержка”:',
      value: false,
      initialValue: false,
      valueName: 'is_tso_for_tech_chat',
      confirmTitle:
        'Внимание, отказ от приема новых пользователей через подраздел “Моя тех. поддержка” сделает невозможным для пользователей сервиса ПК выбор Вас в качестве своего ОТП.',
      confirmText: 'Вы действительно хотите отказаться от закрепления за вами новых пользователей через этот канал?',
      isConfirmModalOpen: false,
    },
    {
      title: 'Закрепление за ОТП новых пользователей, зарегистрированных с моим промокодом:',
      value: false,
      initialValue: false,
      valueName: 'is_accepting_as_tso',
      confirmTitle:
        'Внимание, отказ от приема новых пользователей, указавших при регистрации ваш промокод, исключит их автоматический выбор Вас в качестве своего ОТП.',
      confirmText: 'Вы действительно хотите отказаться от закрепления за вами новых пользователей через этот канал',
      isConfirmModalOpen: false,
    },
  ];

  constructor(private personalDataService: PersonalDataService, private destroy: DestroyService) {}

  changeStatusTso(config: TsoModalConfig, value: boolean) {
    const newStatus = {
      [config.valueName]: value,
    };
    this.personalDataService
      .updateUserData(newStatus)
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        if (config.isConfirmModalOpen) {
          config.isConfirmModalOpen = false;
        }
      });
  }

  openConfirmRemove(config: TsoModalConfig) {
    config.initialValue = config.value;
    config.isConfirmModalOpen = true;
  }

  cancelChange(config: TsoModalConfig) {
    config.value = config.initialValue;
    config.isConfirmModalOpen = false;
  }
}
