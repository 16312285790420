import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserFile } from '@app/file-manager/models/user-file.model';
import { NotificationsService } from 'angular2-notifications';
import saveAs from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { FileManagerModalFilePreviewComponent } from '@app/file-manager/components/file-manager-modal-file-preview/file-manager-modal-file-preview.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-report-notes',
  templateUrl: './report-notes.component.html',
  styleUrls: ['./report-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportNotesComponent {
  @Input() documents: UserFile[] = [];
  // Реализовать доступ после чата
  @Input() isAccesChat: boolean = false;

  constructor(
    private readonly notify: NotificationsService,
    private translate: TranslateService,
    private readonly modalService: NgbModal
  ) {}

  download(file: UserFile): void {
    if (file && file.is_file) {
      if (!file.url) {
        this.notify.warn('Внимание!', `Ссылка неактивна`);
        return;
      }
      try {
        saveAs(file.url, file.name);
        this.notify.success('успешно', this.translate.instant('fm_msg_download_success'));
      } catch (e) {
        this.notify.error('внимание', this.translate.instant('fm_msg_download_failure'));
      }
    }
  }

  openPreview(file: UserFile): void {
    if (!file.url) {
      this.notify.warn('Внимание!', `Ссылка неактивна`);
      return;
    }
    const modal = this.modalService.open(FileManagerModalFilePreviewComponent, {
      centered: true,
      animation: true,
      windowClass: 'dc-modal modal-window',
      size: 'xl',
    });

    modal.componentInstance.files = [new UserFile(file)];
  }
}
