import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private readonly localStoragePrefix = 'dircont_';

  setItem<T = any>(key: string, value: T): void {
    localStorage.setItem(
      this.localStoragePrefix + key,
      window.btoa(unescape(encodeURIComponent(JSON.stringify(value))))
    );
  }

  getItem<T = any>(key: string): T {
    const item = localStorage.getItem(this.localStoragePrefix + key);

    if (item) {
      return JSON.parse(decodeURIComponent(escape(window.atob(item))));
    }
  }

  removeItem(key: string): void {
    localStorage.removeItem(this.localStoragePrefix + key);
  }

  clear(): void {
    const keys = Object.keys(localStorage);

    keys.forEach((key) => {
      if (key.startsWith(this.localStoragePrefix)) {
        localStorage.removeItem(key);
      }
    });
  }
}
