<div class="container">
  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4" style="min-width: 385px">
      <section class="auth-form">
        <form role="auth" novalidate [formGroup]="signUpGroup">
          <app-faq-tooltip tooltipName="auth__auth_signup" class="hint-right"></app-faq-tooltip>
          <h3>{{ role === 'admin_of_user' ? 'Зарегистрироваться' : 'Регистрация агента' }}</h3>

          <div class="form-group">
            <select
              [(ngModel)]="role"
              [ngModelOptions]="{ standalone: true }"
              class="form-control"
              id="exampleFormControlSelect1"
            >
              <option ngValue="admin_of_user">Администратор пользователя</option>
              <option ngValue="partner">Агент</option>
            </select>
          </div>

          <div class="form-group">
            <input
              type="email"
              placeholder="Электронная почта"
              name="email"
              class="form-control input-lg"
              appInputTrim
              formControlName="email"
            />
            <ng-container *ngIf="signUpGroup.controls.email.touched">
              <div
                class="form-control-feedback"
                *ngIf="signUpGroup.controls.email.touched && signUpGroup.controls.email.invalid"
              >
                <span *ngIf="signUpGroup.controls.email.errors.required">Поле обязательно для заполнения</span>
                <span *ngIf="signUpGroup.controls.email.errors.pattern">Проверьте введённый E-mail</span>
              </div>
            </ng-container>
          </div>
          <div class="form-group">
            <div class="inline middle group-pass">
              <input
                type="password"
                name="password"
                class="form-control"
                placeholder="Введите пароль"
                appInputTrim
                formControlName="password"
                #passwordInput
              />
              <button class="btn btn-dc-primary show-pass" type="button" (click)="togglePasswordShowing(passwordInput)">
                <app-svg-icon [name]="passwordInput.type === 'text' ? 'eye' : 'eye_slash'" width="22"></app-svg-icon>
              </button>
            </div>
            <ng-container *ngIf="signUpGroup.controls.password.touched">
              <div *ngIf="getErrorByFormControlName('password') as error" class="form-control-feedback">
                {{ error | translate }}
              </div>
            </ng-container>
          </div>

          <div class="form-group">
            <div class="inline middle group-pass">
              <input
                type="password"
                name="password"
                class="form-control"
                placeholder="Подтвердите пароль"
                appInputTrim
                formControlName="password_double"
                #confirmPasswordInput
              />
              <button
                class="btn btn-dc-primary show-pass"
                type="button"
                (click)="togglePasswordShowing(confirmPasswordInput)"
              >
                <app-svg-icon
                  [name]="confirmPasswordInput.type === 'text' ? 'eye' : 'eye_slash'"
                  width="22"
                ></app-svg-icon>
              </button>
            </div>
            <ng-container *ngIf="signUpGroup.controls.password_double.touched">
              <div *ngIf="getErrorByFormControlName('password_double') as error" class="form-control-feedback">
                {{ error | translate }}
              </div>
            </ng-container>
          </div>

          <div class="form-group" *ngIf="role === 'admin_of_user' || role === 'partner'">
            <input
              type="text"
              name="tso_id"
              class="form-control input-lg"
              formControlName="tso_id"
              placeholder="Промо-код агента (опционально)"
              appInputTrim
            />
            <ng-container *ngIf="signUpGroup.controls.tso_id.touched">
              <div
                *ngIf="(errors.tso_id?.[0] || getErrorByFormControlName('tso_id')) as error"
                class="form-control-feedback"
              >
                {{ error | translate }}
              </div>
            </ng-container>
          </div>
          <div class="form-group" [class.ng-invalid]="errors.captcha">
            <re-captcha #captchaRef="reCaptcha" (resolved)="handleCorrectCaptcha($event)"></re-captcha>

            <div class="form-control-feedback" *ngFor="let err of errors.captcha">{{ err | translate }}</div>
          </div>

          <button type="submit" (click)="register()" class="btn btn-lg btn-dc-primary btn-block">
            Зарегистрироваться
          </button>
          <button type="button" [routerLink]="['/signin']" class="btn btn-lg btn-dc-primary btn-block">Вход</button>
        </form>
      </section>
    </div>
    <div class="col-md-4"></div>
  </div>
</div>
