import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Note } from '@app/+trades/models/trades.model';

@Component({
  selector: 'app-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss'],
})
export class NotesListComponent implements OnInit {
  @Input() notes: Note[] = [];
  @Input() isEdit = true;
  @Input() readonly: boolean;

  @Output() onChangeEvent = new EventEmitter<Note[]>();

  constructor() {}

  ngOnInit() {}

  addNote() {
    this.notes.push({
      text: null,
    });

    this.onChangeEvent.emit(this.notes);
  }

  removeNote(index: number) {
    this.notes.splice(index, 1);

    this.onChangeEvent.emit(this.notes);
  }

  onTextChanged() {
    this.onChangeEvent.emit(this.notes);
  }
}
