import { AccessRight } from '../types/trades.types';
import { User } from '@app/shared/models/user.model';
import { Employee } from '@app/shared/models/employee.model';
import { TradeEmployee } from './employes.model';

export interface TradeCustomer {
  access_rights: AccessRight;
  trade: string;
  user: TradeEmployee;
  selected: boolean;
}

export class TradeCustomer implements TradeCustomer {
  constructor(user: TradeEmployee, access_right: AccessRight = 'view', selected: boolean = false) {
    this.user = user;

    this.access_rights = access_right;
    this.trade = '';
    this.selected = selected;
  }
}
