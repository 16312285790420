<app-modal-window [isShowed]="isOpen" (closeEvent)="onClose()" customClass="contacts-filter-modal ">
  <div class="modal-header">
    <div class="modal-title" appTestLocator="catalog-tree__filter-title">{{ title }}</div>
  </div>

  <ul class="list-unstyled">
    <li *ngFor="let type of sortOptions; trackBy: trackByType" class="dc-radio-input">
      <input
        [id]="'order-' + type.name"
        type="radio"
        name="order"
        [value]="type.name"
        [checked]="type.name === selectedSortType"
        (click)="onSelect(type.name)"
      />
      <label class="radio-label" [for]="'order-' + type.name">
        {{ type.label }}
      </label>
    </li>
  </ul>

  <div class="modal-footer">
    <div class="dc-footer-buttons">
      <button
        class="btn btn-dc-outline"
        style="min-width: 150px"
        (click)="onClose()"
        appTestLocator="add-word__cancel-button"
      >
        Отменить
      </button>
      <button
        class="btn btn-dc-primary"
        [disabled]="sortType === selectedSortType"
        (click)="onSave($event)"
        style="min-width: 150px"
        type="submit"
        appTestLocator="add-word__save-button"
      >
        Сменить
      </button>
    </div>
  </div>
</app-modal-window>
