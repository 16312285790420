import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MY_COMPETENCE_TABS } from '@app/+competence-map/constants/competence-map.constants';
import { CMCatalogTypes } from '@app/+competence-map/constants/sections.constants';
import { CatalogTab } from '@app/+competence-map/models/competence-map.models';

import { UserCompetencyStatuses } from '@app/+competence-map/constants/user-competency.constants';
import { CmHelper } from '@app/+competence-map/helpers/cm.helper';
import { ExpertCompetency } from '@app/+competence-map/models/user-competency.model';
import {
  CompetenceDataService,
  GOODS_COMPETENCE_STATE,
  SERVICES_COMPETENCE_STATE,
} from '@app/+competence-map/services/competence-data.service';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { NotificationsService } from 'angular2-notifications';
import { forkJoin } from 'rxjs';
import { AuthService } from '@app/shared/services/auth.service';

@Component({
  selector: 'app-expert-competences',
  templateUrl: './expert-competences.component.html',
  styleUrls: ['./expert-competences.component.scss'],
})
export class ExpertCompetencesComponent implements OnInit, OnDestroy {
  tabs: CatalogTab[] = MY_COMPETENCE_TABS;
  currentTab: CatalogTab;
  currentTabValue: string;
  isHelperOpen: boolean;

  catalogTypes = CMCatalogTypes;
  competencies: ExpertCompetency[] = [];

  constructor(
    private competenceService: CompetenceService,
    @Inject(SERVICES_COMPETENCE_STATE) private servicesStateService: CompetenceDataService,
    @Inject(GOODS_COMPETENCE_STATE) private goodsStateService: CompetenceDataService,
    private notify: NotificationsService,
    private authService: AuthService
  ) {
    this.currentTab = this.tabs[0];
    this.currentTabValue = this.currentTab.value;

    this.loadCompetencies();
  }

  get selectedData() {
    return [
      ...this.goodsStateService.competencySelectionSections,
      ...this.servicesStateService.competencySelectionSections,
    ];
  }

  ngOnInit() {}

  loadCompetencies() {
    this.competenceService
      .getExpertCompetency([
        {
          name: 'status',
          op: 'eq',
          val: UserCompetencyStatuses.ACTIVE,
        },
        {
          name: 'user_id',
          op: 'eq',
          val: this.authService.user_id,
        },
      ])
      .subscribe((competencies) => (this.competencies = competencies));
  }

  onSelectTab(tab: CatalogTab) {
    this.currentTabValue = tab.value;
  }

  closeHelper() {
    this.isHelperOpen = false;
  }

  hasChanges() {
    return (
      CmHelper.getNewExpertCompetencies(this.selectedData, this.competencies).length ||
      CmHelper.getDeletedExpertCompetencies(this.selectedData, this.competencies).length
    );
  }

  save() {
    const newCompetencies = CmHelper.getNewExpertCompetencies(this.selectedData, this.competencies);
    const competenciesForDelete = CmHelper.getDeletedExpertCompetencies(this.selectedData, this.competencies);
    const queries = [];

    if (newCompetencies.length) {
      queries.push(
        this.competenceService.addExpertCompetency(CmHelper.getParamsForCreateExpertCompetencies(newCompetencies))
      );
    }

    if (competenciesForDelete.length) {
      queries.push(
        this.competenceService.changeExpertCompetency(
          CmHelper.getParamsForDeleteExpertCompetencies(competenciesForDelete, this.competencies)
        )
      );
    }

    forkJoin(queries).subscribe(
      () => {
        this.loadCompetencies();
        this.notify.success('Успешно', 'Компетенции обновлены');
      },
      (err) => {
        this.competenceService.error(err);
      }
    );
  }

  cancel() {
    this.servicesStateService.resetSubject.next();
    this.goodsStateService.resetSubject.next();
  }

  ngOnDestroy() {
    this.goodsStateService.competencySelectionSections = [];
    this.servicesStateService.competencySelectionSections = [];
  }
}
