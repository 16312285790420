<div class="dc-modal-x-button dc-fa-button" (click)="close()">
  <app-svg-icon [height]="20" [width]="20" name="close"></app-svg-icon>
</div>

<div class="modal-window-content">
  <div class="modal-header">
    <div class="primary-title text-center">Онлайн сервис Прямой Контакт приветствует Вас!</div>
  </div>

  <div class="modal-body">
    <div class="secondary-title text-center">
      Для полноценной работы с сервисом Вам необходимо завершить процедуру регистрации.
    </div>

    <div class="welcome-message">
      Для этого заполните раздел “Личные данные”, примите условия договора и правила сервиса. После этого все вкладки
      левого и верхнего меню станут активными.
    </div>

    <div class="welcome-message">
      На страницах сервиса Вы можете воспользоваться подсказками, которые вызываются кликом по иконкам со знаками
      вопроса
      <app-svg-icon [width]="20" [height]="20" name="note"></app-svg-icon>. или обратиться к оператору технической
      поддержки через технический раздел чата
      <app-svg-icon [width]="20" [height]="20" name="tools"></app-svg-icon> (вызывается кликом на иконку чата
      <app-svg-icon [width]="20" [height]="20" name="envelope"></app-svg-icon>, расположенную в верхнем меню)
    </div>
  </div>

  <div class="modal-footer">
    <div class="footer-title text-center">Желаем приятной работы в сервисе Прямой контакт!</div>
    <div class="contact-form-link">
      <a (click)="navigateToContactForm()">Перейти к заполнению данных</a>
    </div>
  </div>
</div>
