<div class="container">
  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4" style="min-width: 385px">
      <section class="auth-form">
        <form role="auth" novalidate [formGroup]="formGroup">
          <h3>Введите новый пароль</h3>

          <div class="form-group">
            <div class="inline middle group-pass">
              <input
                type="password"
                name="password"
                class="form-control"
                placeholder="Введите пароль"
                appInputTrim
                formControlName="password"
                #passwordInput
              />
              <button
                class="btn btn btn-dc-primary show-pass"
                type="button"
                (click)="togglePasswordShowing(passwordInput)"
              >
                <app-svg-icon [name]="passwordInput.type === 'text' ? 'eye' : 'eye_slash'" width="22"></app-svg-icon>
              </button>
            </div>

            <ng-container *ngIf="formGroup.controls.password.touched">
              <div *ngIf="getErrorByFormControlName('password') as error" class="form-control-feedback">
                {{ error | translate }}
              </div>
            </ng-container>
          </div>

          <div class="form-group">
            <div class="inline middle group-pass">
              <input
                type="password"
                name="password"
                formControlName="password_double"
                class="form-control"
                placeholder="Подтвердите пароль"
                appInputTrim
                #confirmPasswordInput
              />
              <button
                class="btn btn btn-dc-primary show-pass"
                type="button"
                (click)="togglePasswordShowing(confirmPasswordInput)"
              >
                <app-svg-icon
                  [name]="confirmPasswordInput.type === 'text' ? 'eye' : 'eye_slash'"
                  width="22"
                ></app-svg-icon>
              </button>
            </div>
            <ng-container *ngIf="formGroup.controls.password_double.touched">
              <div *ngIf="getErrorByFormControlName('password_double') as error" class="form-control-feedback">
                {{ error | translate }}
              </div>
            </ng-container>
          </div>

          <div class="form-group" [class.ng-invalid]="errors.password">
            <re-captcha #captchaRef="reCaptcha" (resolved)="handleCorrectCaptcha($event)"></re-captcha>
            <div class="form-control-feedback" *ngFor="let err of errors.captcha">{{ err | translate }}</div>
          </div>

          <button type="submit" (click)="confirm()" class="btn btn-lg btn-dc-primary btn-block">Подтвердить</button>
          <button type="button" [routerLink]="['/signin']" class="btn btn-lg btn-dc-primary btn-block">Вход</button>
        </form>
      </section>
    </div>
    <div class="col-md-4"></div>
  </div>
</div>
