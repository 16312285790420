<div
  *ngIf="collapsedCriteria; else tableTpl"
  (click)="toggleCollapsedCriteria($event, false)"
  class="lots-table-collapsed cursor-pointer"
>
  <app-svg-icon name="d-arrow-down" width="16" height="16"></app-svg-icon>
  <span>Критерий {{ index + 1 }}</span>
</div>

<ng-template #tableTpl>
  <div
    *ngIf="criteria && formArray"
    class="lots-table lots-table-criteria"
    [class.lots-table-criteria--trades]="isTrades"
    [class.collapsed]="collapsedDetails"
  >
    <div class="lots-table-header">
      <div class="lots-table-row criteria-top">
        <div class="lots-table-cell criteria-top-title">
          <span>Критерий {{ index + 1 }}</span>
          <app-svg-icon
            class="margin-l-4 cursor-pointer"
            name="cog"
            width="16"
            height="16"
            (click)="showCriteriaActions($event)"
          ></app-svg-icon>
          <ul
            class="service-menu"
            *ngIf="isCriteriaActionsOpened"
            appClickElsewhere
            (appClickElsewhere)="closeCriteriaActions($event)"
          >
            <li
              class="service-menu-item cursor-pointer"
              [class.disabled]="false"
              (click)="toggleCollapsedCriteria($event, true)"
            >
              <app-svg-icon name="plus-square" width="16" height="16"></app-svg-icon>
              <span>Свернуть полностью</span>
            </li>
          </ul>
          <app-faq-tooltip
            tooltipName="supplier-criteria-help"
            class="faq-tooltip-brand faq-tooltip-md"
          ></app-faq-tooltip>
        </div>
        <div class="lots-table-cell no-expand-cells-icon">
          <app-svg-icon
            class="cursor-pointer"
            width="12"
            height="12"
            [name]="collapsedDetails ? 'plus-square' : 'minus-square'"
            (click)="collapsedDetails = !collapsedDetails"
          ></app-svg-icon>
        </div>
        <div *ngIf="!collapsedDetails" class="lots-table-cell criteria-top-expand padding-0">
          <div class="expand-line"></div>
        </div>
      </div>
      <div class="lots-table-row criteria-description">
        <div class="lots-table-cell">{{ criteria.title }}</div>
      </div>
      <div class="lots-table-row criteria-cells">
        <div class="lots-table-cell criteria-place">Место</div>
        <div *ngIf="!isTrades" class="lots-table-cell criteria-cell-value">Ваше значение ({{ criteriaTypeLabel }})</div>
        <div *ngIf="!isTrades" class="lots-table-cell criteria-lead-value">Значение лидера</div>
        <div *ngIf="isTrades" class="lots-table-cell criteria-lead-value">Значение (лид.)</div>
        <div *ngIf="!collapsedDetails" class="lots-table-cell criteria-lead-score">Балл (лид.)</div>
        <div *ngIf="!collapsedDetails" class="lots-table-cell criteria-weight">Вес критерия</div>
        <div *ngIf="!collapsedDetails" class="lots-table-cell criteria-unit">Ед. измерения</div>
        <div *ngIf="!collapsedDetails" class="lots-table-cell criteria-comment">Комментарий</div>
      </div>
    </div>
    <div class="lots-table-body">
      <div *ngFor="let nomenclature of nomenclatures; index as nIndex" class="lots-table-row criteria-cells">
        <ng-container
          *ngIf="
            nomenclature.expanded && {
              rating: getCriteriaRating(nIndex, nomenclature.criteria[index].id),
              criteria: nomenclature.criteria[index]
            } as nomData
          "
        >
          <div class="lots-table-cell criteria-place">
            {{ nomData.rating?.place_rating_criterion ?? '–' }}
          </div>
          <div
            *ngIf="!isTrades && getCriteriaValueControl(nIndex) as control"
            class="lots-table-cell criteria-cell-value criteria-cell-value-{{ index }}-{{ nIndex }}"
            [class.filled]="!isControlValid(control)"
          >
            <app-supplier-lot-criteria-view
              [readonly]="readonly"
              [type]="criteria.type"
              [control]="control"
              [withCopy]="!nIndex"
              [min]="nomData.criteria.min"
              [max]="nomData.criteria.max"
              (copy)="copyToBottomLines(control.value)"
            ></app-supplier-lot-criteria-view>
            <div
              class="criteria-cell-value__variants-icon"
              container="body"
              placement="right"
              tooltipClass="criteria-cell-value__variants"
              [ngbTooltip]="variantsTpl"
            ></div>
            <ng-template #variantsTpl>
              <div class="variants">
                <div class="variant">
                  <b>Мин.значение</b>
                  <div class="variant-description">
                    <app-svg-icon
                      name="long-arrow-alt-down"
                      height="16"
                      width="8"
                      class="variant-vector"
                      [class.active]="nomData.criteria.best_direction === criteriaVectorEnum.LESS_BETTER"
                    ></app-svg-icon>
                    <span>{{ criteria.type === 'bool' ? 'Нет' : nomData.criteria.min ?? '-' }}</span>
                  </div>
                </div>
                <div class="variant">
                  <b>Макс.значение</b>
                  <div class="variant-description">
                    <app-svg-icon
                      name="long-arrow-alt-up"
                      height="16"
                      width="8"
                      class="variant-vector"
                      [class.active]="nomData.criteria.best_direction === criteriaVectorEnum.MORE_BETTER"
                    ></app-svg-icon>
                    <span>{{ criteria.type === 'bool' ? 'Да' : nomData.criteria.max ?? '-' }}</span>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
          <div class="lots-table-cell criteria-lead-value">
            <ng-container *ngIf="isTrades">
              {{ getBooleanStr(getCriteriaValueControl(nIndex)?.value) ?? '-' }}
            </ng-container>
            {{
              (isTrades ? '(' : '') +
                (hideLeaders ? '-' : formatCriteriaRatingValue(nomData.rating?.value_leader_rating_criterion)) +
                (isTrades ? ')' : '')
            }}
          </div>
          <div *ngIf="!collapsedDetails" class="lots-table-cell criteria-lead-score">
            {{ nomData.rating?.points_rating_criterion | ratingValue }}
            ({{ hideLeaders ? '-' : (nomData.rating?.points_leader_rating_criterion | ratingValue) }})
          </div>
          <div *ngIf="!collapsedDetails" class="lots-table-cell criteria-weight">
            {{ nomData.criteria.weight ?? '–' }}
          </div>
          <div *ngIf="!collapsedDetails" class="lots-table-cell criteria-unit">
            <span
              container="body"
              [ngbTooltipDisabled]="!unitsMap.get(nomData.criteria?.unit_id)?.full_name"
              [ngbTooltip]="unitsMap.get(nomData.criteria?.unit_id)?.full_name"
            >
              {{ unitsMap.get(nomData.criteria?.unit_id)?.name ?? '–' }}
            </span>
          </div>
          <div *ngIf="!collapsedDetails" class="lots-table-cell criteria-comment">
            <span container="body" [ngbTooltip]="nomData.criteria?.comment">
              {{ nomData.criteria?.comment ?? '–' }}
            </span>
          </div>
        </ng-container>
        <ng-container *ngIf="!nomenclature.expanded">
          <div class="lots-table-cell collapsed bg-snow"></div>
          <div class="lots-table-cell collapsed bg-snow"></div>
          <div *ngIf="!isTrades" class="lots-table-cell collapsed bg-snow"></div>
          <div *ngIf="!collapsedDetails" class="lots-table-cell collapsed bg-snow"></div>
          <div *ngIf="!collapsedDetails" class="lots-table-cell collapsed bg-snow"></div>
          <div *ngIf="!collapsedDetails" class="lots-table-cell collapsed bg-snow"></div>
          <div *ngIf="!collapsedDetails" class="lots-table-cell collapsed bg-snow"></div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
