<div class="chat-contacts-filter-container" *ngIf="isOpened$ | async">
  <span class="title">Настроить фильтр контактов</span>

  <div class="user-tso" *ngIf="(isTechSection | async) && isGroupEditing">
    <span>
      <input
        class="cbx-input"
        id="has"
        name="has"
        type="checkbox"
        [checked]="isUserHasTSO"
        (change)="onUserHasTSOChanged(true)"
      />

      <label class="cbx-label" for="has">
        <span class="cbx-label-text">Закрепленные за ОТП</span>
      </label>
    </span>

    <span>
      <input
        class="cbx-input"
        id="noHas"
        name="noHas"
        type="checkbox"
        [checked]="isUserNotHasTSO"
        (change)="onUserHasTSOChanged(false)"
      />

      <label class="cbx-label" for="noHas">
        <span class="cbx-label-text">Незакрепленные за ОТП</span>
      </label>
    </span>
  </div>

  <div class="tabs">
    <ng-container *ngFor="let tab of TABS">
      <div
        *ngIf="!hiddenFilters.includes(tabValuesByKey[tab])"
        class="tab title"
        [class.active]="tab === selectedTab"
        (click)="onTabSelected(tab)"
      >
        {{ tab }}

        <span class="chat-number" [class.chat-number__10]="valuesByTabs[tab] > 10">
          {{ valuesByTabs[tab] > 10 ? '10+' : valuesByTabs[tab] }}
        </span>
      </div>
    </ng-container>
  </div>

  <div class="content-wrapper">
    <app-multiple-livesearch-select
      placeholder="Начните вводить название компании"
      [list]="companies"
      [showSearch]="false"
      [title]="'Выделить все/снять выделение'"
      [readonlyFunction]="readonlyFunction"
      (selectEvent)="onItemsSelected($event, 'companies')"
      *ngIf="selectedTab === ChatContactsOnlyFilterTab.Company && hiddenFilters"
    >
    </app-multiple-livesearch-select>

    <app-multiple-livesearch-select
      placeholder="Начните вводить название роли"
      [showSearch]="false"
      [list]="roles"
      [title]="'Выделить все/снять выделение'"
      [readonlyFunction]="readonlyFunction"
      (selectEvent)="onItemsSelected($event, 'roles')"
      *ngIf="selectedTab === ChatContactsOnlyFilterTab.Roles && hiddenFilters"
    ></app-multiple-livesearch-select>

    <app-tree-checkbox-list
      placeholder="Начните вводить название товара или услуги"
      [showSubmitButton]="false"
      [treeList]="goods"
      (selectEvent)="onItemsSelected($event, 'goods')"
      [showDoubleCarets]="true"
      [readonlyFunction]="readonlyFunction"
      *ngIf="selectedTab === ChatContactsOnlyFilterTab.GoodsCompetence && hiddenFilters"
    ></app-tree-checkbox-list>

    <app-tree-checkbox-list
      placeholder="Начните вводить название товара или услуги"
      [showSubmitButton]="false"
      [treeList]="services"
      (selectEvent)="onItemsSelected($event, 'services')"
      [showDoubleCarets]="true"
      [readonlyFunction]="readonlyFunction"
      *ngIf="selectedTab === ChatContactsOnlyFilterTab.ServicesCompetence && hiddenFilters"
    ></app-tree-checkbox-list>
  </div>

  <div class="buttons mt-2 text-center">
    <button class="btn btn-dc-outline" (click)="close(false)">Отменить</button>
    <button class="btn btn-dc-primary" (click)="close(true)">Применить</button>
  </div>
</div>
