import { Injectable } from '@angular/core';
import { MessageActionType } from '@app/chat/constants/chat-sections.constants';
import { BehaviorSubject, Subject } from 'rxjs';
import { ChatMessage, ChatRoom } from '../models/chat.model';

@Injectable({ providedIn: 'root' })
export class MessagesSelectService {
  private messageSelected = new BehaviorSubject<ChatMessage[]>([]);
  public messageSelectedChanged = this.messageSelected.asObservable();

  private messageActionType = new Subject<MessageActionType>();
  public selectMessagesActionType = this.messageActionType.asObservable();

  private roomsSelected = new BehaviorSubject<ChatRoom[]>([]);
  public roomsSelectedChanged = this.roomsSelected.asObservable();

  setMessageActionType(actionType: MessageActionType) {
    this.messageActionType.next(actionType);
  }

  toggleMessageSelected(chatMessage: ChatMessage) {
    const selectedMessages = [...this.messageSelected.value];
    const selectedIndex = selectedMessages.findIndex((message) => message.id === chatMessage.id);

    // костыль для того что юы меню удаления пропадало только по кнопкам отмены
    // (решение спорное но как будто лучше так чем заводить еще флаг и подписки )
    if (!selectedMessages.length) {
      selectedMessages.push({ ...chatMessage, id: 0 });
    }

    if (selectedIndex === -1) {
      selectedMessages.push(chatMessage);
    } else {
      selectedMessages.splice(selectedIndex, 1);
    }

    this.messageSelected.next(selectedMessages);
  }

  toggleRoomSelected(chatRoom: ChatRoom) {
    const selectedRooms = [...this.roomsSelected.value];
    const selectedIndex = selectedRooms.findIndex((room) => room.id === chatRoom.id);

    if (selectedIndex === -1) {
      selectedRooms.push(chatRoom);
    } else {
      selectedRooms.splice(selectedIndex, 1);
    }

    this.roomsSelected.next(selectedRooms);
  }

  isRoomSelected(chatRoom: ChatRoom) {
    return this.roomsSelected.value.some((room) => room.room_id === chatRoom.room_id);
  }

  isMessageSelected(chatMessage: ChatMessage) {
    return this.messageSelected.value.some((message) => message.id === chatMessage.id);
  }

  clearMessageSelected() {
    this.messageSelected.next([]);
  }

  clearSelectedRoom() {
    this.roomsSelected.next([]);
  }
}
