import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StatusesEnum } from '@app/shared/constants/statuses.constants';
import { User } from '@app/shared/models/user.model';
import * as moment from 'moment';

@Component({
  selector: 'app-tso-list-table',
  templateUrl: './tso-list-table.component.html',
  styleUrls: ['./tso-list-table.component.scss'],
})
export class TsoListTableComponent {
  @Input() tsoList: User[] = [];
  @Input() myTsoId: number | null = null;
  @Input() tsoValue: string = '';
  @Input() isChangeTso: boolean = false;
  @Input() dateOffSet: number = 0;

  @Output() changedTsoEvent = new EventEmitter<User>();
  @Output() openChangedModalEvent = new EventEmitter();
  @Output() openConfirmationModalEvent = new EventEmitter();

  selectedTso: User | null = null;

  statusIconClass(user: User): string {
    if (user?.status === StatusesEnum.ACTIVE) {
      return 'status-green';
    } else if (user?.status === StatusesEnum.VIEW) {
      return 'status-yellow';
    } else {
      return 'status-red';
    }
  }

  openChangedModal() {
    this.openChangedModalEvent.emit();
  }

  changeTso(tso: User) {
    this.selectedTso = tso;
    this.changedTsoEvent.emit(tso);
  }

  openConfirmationModal() {
    this.openConfirmationModalEvent.emit();
  }

  getCurrentTimeZoneData(dateUtc: string | Date | null) {
    const date = moment(dateUtc);

    if (!date.isValid()) {
      return '-';
    }
    return date.add(this.dateOffSet, 'minutes').format('DD.MM.YYYY');
  }
}
