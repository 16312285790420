import { Component, EventEmitter, Input, Output } from '@angular/core';

import { UserFile } from '@app/file-manager/models/user-file.model';
import { FileManagerAreaPlaceCallType } from '@app/file-manager/models/file-manager-area.enum';

@Component({
  template: '',
})
export abstract class FileManagerAreaFileComponent {
  fileSelectedValue: boolean = false;
  fileCurrentSelectedValue: boolean = false;
  fileCutValue: boolean = false;

  @Input() file: UserFile;
  @Input() folderCheck: boolean;
  @Input() searchQuery: string = '';
  @Input() placeCall: FileManagerAreaPlaceCallType;
  @Input() openDelayTooltip?: number;
  @Input() canSelect?: boolean = true;

  @Output() readonly onSelected = new EventEmitter<boolean>();
  @Output() readonly onShareFile = new EventEmitter<unknown>();
  @Output() readonly onShowUserInfo = new EventEmitter<unknown>();

  @Input() set selectCheckStatus(value: boolean) {
    this.fileSelectedValue = value;
  }

  @Input() set selectCurrentSelected(value: boolean) {
    this.fileCurrentSelectedValue = value;
  }

  @Input() set isCutFileStatus(value: boolean) {
    this.fileCutValue = value;
  }

  checkFile(event: Event): void {
    event.stopPropagation();
    this.onSelected.emit(this.fileSelectedValue);
  }

  shareFile(event: Event): void {
    event.stopPropagation();
    this.onShareFile.emit();
  }

  showUserInfo(event: Event): void {
    event.stopPropagation();
    this.onShowUserInfo.emit();
  }
}
