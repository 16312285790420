import { FaqQuestionStatus } from '../enums/faq.common.enum';
import { RolesEnum } from '../constants/roles.constants';
import { FaqApiModel, FaqHintApiParams } from './faq.common.interfaces';
import { Initials } from './base-user.model';

export class FaqCategory {
  public questions: FaqQuestion[];
  public sort: number;
  public title: string;
  public status: FaqQuestionStatus;
  public user_types: RolesEnum[];
  public id: number;
  public expanded?: boolean;

  constructor(category: FaqApiModel.FaqCategory) {
    this.questions = category.questions
      .map((q) => new FaqQuestion(q, category.id, category.user_types))
      .sort((q1, q2) => q2.sort - q1.sort);
    this.sort = category.sort || 0;
    this.id = category.id;
    this.status = category.status;
    this.title = category.title;
    this.user_types = category.user_types;
  }
}

export class FaqQuestion {
  public user_types: RolesEnum[];
  public answer: string;
  public text: string;
  public sort: number;
  public id: number;
  public status: FaqQuestionStatus;
  public categoryId: number;
  public availableUserTypes: RolesEnum[];
  public expanded?: boolean;

  public static getEmptyQuestion(categoryId: number, categoryUserTypes: RolesEnum[], sort: number): FaqQuestion {
    return new FaqQuestion(
      {
        answer: '',
        status: FaqQuestionStatus.InDevelop,
        text: '',
        user_types: [],
        sort,
      },
      categoryId,
      categoryUserTypes
    );
  }

  constructor(question: FaqApiModel.FaqQuestion, categoryId: number, categoryUserTypes: RolesEnum[]) {
    this.user_types = question.user_types;
    this.answer = question.answer;
    this.text = question.text;
    this.sort = question.sort || 0;
    this.id = question.id;
    this.status = question.status;
    this.categoryId = categoryId;
    this.availableUserTypes = categoryUserTypes;
  }

  get title(): string {
    return `ID:${this.id} ${this.answer}`;
  }
}

export class FaqModule {
  public hints: FaqHint[];
  public name: string;
  public sort: number;
  public id: number;
  public status: FaqQuestionStatus;
  public expanded?: boolean;

  constructor(faqModule: FaqApiModel.FaqModule) {
    this.id = faqModule.id;
    this.name = faqModule.name;
    this.status = faqModule.status;
    this.sort = faqModule.sort || 0;
    this.hints = faqModule.hints.map((h) => new FaqHint(h, faqModule.id)).sort((h1, h2) => h2.sort - h1.sort);
  }
}

export class FaqHint {
  public value: string;
  public name: string;
  public status: FaqQuestionStatus;
  public sort: number;
  public moduleId: number;
  public id: number;
  public expanded?: boolean;

  public static getEmptyHint(moduleId: number, sort: number): FaqHint {
    return new FaqHint(
      {
        name: '',
        value: '',
        status: FaqQuestionStatus.InDevelop,
        sort,
      },
      moduleId
    );
  }

  constructor(hint: FaqApiModel.FaqHint, moduleId: number) {
    this.value = hint.value;
    this.name = hint.name;
    this.status = hint.status;
    this.sort = hint.sort || 0;
    this.moduleId = moduleId;
    this.id = hint.id;
  }

  get hintForApi(): FaqHintApiParams {
    return {
      module_id: this.moduleId,
      value: this.value,
      name: this.name,
      status: this.status,
      sort: this.sort,
      id: this.id,
    };
  }

  get title(): string {
    return `ID:${this.id} ${this.name}`;
  }
}

export class FaqPermission implements Initials {
  public hasAccess: boolean;
  public userId: number;
  public first_name: string;
  public second_name: string;
  public patronymic: string;
  public checked: boolean;

  constructor(permission: FaqApiModel.FaqPermission) {
    this.userId = permission.user.id;
    this.first_name = permission.user.first_name;
    this.second_name = permission.user.second_name;
    this.patronymic = permission.user.patronymic;
    this.hasAccess = permission.has_permission;
    this.checked = false;
  }
}

export class FaqFilesData {
  constructor(public filesForSave: any[], public filesForRemove: string[], public itemType: 'faq' | 'hint') {}

  get areFilesExist(): boolean {
    return !!(this.filesForSave.length || this.filesForRemove.length);
  }
}

export class FaqExistedImage {
  public src: string;
  public blob: Blob;
  constructor(public name: string) {}
}
