import { Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SupplierLotGroup } from '@app/+trades/form-types/lots';
import { ExtraNomenclature, Lot } from '@app/+trades/models/lots.model';
import { RatingProviderLot } from '@app/+trades/models/trades-rating.model';
import { Unit } from '@app/shared/models/user-settings.model';
import { StateLotService } from '@app/+trades/services/states/state-lot.service';
import { DestroyService } from '@app/services/destroy.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: '[formGroup] app-supplier-lot, [formGroupName] app-supplier-lot',
  templateUrl: './supplier-lot.component.html',
  styleUrls: ['./supplier-lot.component.scss'],
})
export class SupplierLotComponent implements OnInit, OnDestroy {
  form: FormGroup<SupplierLotGroup>;
  collapsedNomenclatures: boolean = false;
  criteriaCollapsed: boolean = false;
  isNomenclaturesActionsOpened: boolean = false;
  selectedNomenclaturesSet = new Set();
  unitsMap: Map<number, Unit> = new Map();

  @Input() lot: Lot;
  @Input() rating: RatingProviderLot;
  @Input() readonly = false;
  private _isTrades = false;
  get isTrades() {
    return this._isTrades;
  }
  @Input() set isTrades(val: boolean) {
    this._isTrades = val;
    this.collapsedNomenclatures = val;
    this.criteriaCollapsed = val;
  }
  @Input() hideLeaders = false;

  @Input() cardId: string;

  @Output() reset = new EventEmitter<void>();

  get canExpand() {
    return this.lot.extra.nomenclatures.some((n) => this.selectedNomenclaturesSet.has(n.id) && !n.expanded);
  }

  get canCollapse() {
    return this.lot.extra.nomenclatures.some((n) => this.selectedNomenclaturesSet.has(n.id) && n.expanded);
  }

  constructor(
    private controlContainer: ControlContainer,
    private route: ActivatedRoute,
    @Optional() private stateLotService: StateLotService,
    private readonly destroy$: DestroyService
  ) {
    this.unitsMap = this.route.snapshot.data.unitsMap;
  }

  ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup<SupplierLotGroup>;

    //При создании компоненты смотрим есть ли в сервисе со стейтом нужные данные, если да то берем их оттуда
    const key = `${this.lot.id}-${this.cardId}`;

    this.stateLotService?.statePublic$.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      const lotState = state.get(key);
      if (lotState) {
        this.collapsedNomenclatures = lotState.collapsedNomenclatures;
        this.isNomenclaturesActionsOpened = lotState.isNomenclaturesActionsOpened;
        this.criteriaCollapsed = lotState.criteriaCollapsed;
        this.lot.extra.expanded = lotState.expanded;
        this.lot.extra.nomenclatures.forEach((nom) => (nom.expanded = lotState.nomenclatures.has(nom.id)));
      }
    });
  }

  // При пересборке компоненты записываем данные сосотяния в сервис - чтобы раскрытие всх окон осталось таким же как и было
  // Возможно нужно будет посмотреть на уникальность ключа
  ngOnDestroy(): void {
    const key = `${this.lot.id}-${this.cardId}`;
    this.stateLotService?.updateState(key, {
      collapsedNomenclatures: this.collapsedNomenclatures,
      isNomenclaturesActionsOpened: this.isNomenclaturesActionsOpened,
      criteriaCollapsed: this.criteriaCollapsed,
      expanded: this.lot.extra.expanded,
      nomenclatures: new Set(this.lot.extra.nomenclatures.filter((nom) => nom.expanded).map((nom) => nom.id)),
    });
  }

  changeDisplayNomenclaturesActions(event: MouseEvent, isShow: boolean): void {
    event.stopPropagation();
    this.isNomenclaturesActionsOpened = isShow;
  }

  toggleExpandNomenclatures(event: MouseEvent, state: boolean): void {
    this.lot.extra.nomenclatures.forEach((nom) => {
      if (nom.selected) {
        nom.expanded = state;
      }
    });

    this.isNomenclaturesActionsOpened = false;
  }

  selectAllNomenclatures(checked: boolean) {
    this.lot.extra.nomenclatures.forEach((nom) => this.selectNomenclature(nom, checked));
  }

  selectNomenclature(nomenclature: ExtraNomenclature, checked: boolean) {
    nomenclature.selected = checked;
    checked
      ? this.selectedNomenclaturesSet.add(nomenclature.id)
      : this.selectedNomenclaturesSet.delete(nomenclature.id);
  }

  getNomenclatureRating(nomId: number) {
    return this.rating?.nomenclatures?.find((nomRating) => nomRating.id_nomenclature === nomId);
  }
}
