import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import { ContactsFilterPipe } from '@app/shared/pipes/contacts-filter.pipe';

import { ChatComponent } from './chat.component';
import { ChatTabComponent } from './chat-tabs/chat-tab/chat-tab.component';
import { ChatTabsComponent } from './chat-tabs/chat-tabs.component';
import { ChatSectionSelectComponent } from './chat-section-select/chat-section-select.component';
import { ChatMessagesComponent } from './chat-messages/chat-messages.component';
import { ChatInputComponent } from './chat-input/chat-input.component';
import { ChatGroupsComponent } from './chat-groups/chat-groups.component';
import { ChatContactsComponent } from './chat-contacts/chat-contacts.component';
import { ChatContactComponent } from './chat-contacts/chat-contact/chat-contact.component';
import { ChatContactsFilterComponent } from './chat-contacts-filter/chat-contacts-filter.component';
import { ChatGroupOrThemeEditComponent } from './chat-groups/chat-group-or-theme-edit/chat-group-or-theme-edit.component';
import { ChatGroupComponent } from './chat-groups/group/group.component';
import { ChatThemeComponent } from './chat-groups/group/chat-theme/chat-theme.component';
import { ChatContactsOnlyFilterComponent } from './chat-contacts-only-filter/chat-contacts-only-filter.component';
import { SharedModule } from '@app/shared/shared.module';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { GroupSortPipe } from './pipes/group-sort.pipe';
import { RouterModule } from '@angular/router';
import { ChatRolesFilterComponent } from './chat-roles-filter/chat-roles-filter.component';
import { GroupsRolesFilterPipe } from './pipes/groups-roles-filter.pipe';
import { AccessUsersSortPipe } from './pipes/access-users-sort.pipe';
import { SVGSpriteModule } from '@app/svg-sprite/svg-sprite.module';
import { DestroyService } from '@app/services/destroy.service';
import { TreeModule } from '@circlon/angular-tree-component';
import { ChatUserItemComponent } from './chat-groups/chat-group-or-theme-edit/chat-user-item/chat-user-item.component';
import { ToLowerCaseUserRolePipe } from './pipes/to-lower-case-user-role.pipe';
import { TechChatComponent } from './tech-chat/tech-chat.component';
import { TechChatTitleComponent } from './tech-chat/tech-chat-title/tech-chat-title.component';
import { TradeChatComponent } from './trade-chat/trade-chat.component';
import { HighlightSearchModule } from '@app/shared/modules/highlight/highlight-search.module';
import { TechChatUsersSortPipe } from './pipes/tech-chat-users-sort.pipe';
import { ChatGroupsSortingComponent } from './chat-groups-sorting/chat-groups-sorting.component';
import { ChatMessageSelectedPanelComponent } from './chat-message-selected-panel/chat-message-selected-panel.component';
import { ContactsSortPipe } from './pipes/contacts-sort.pipe';
import { ChatAttachedFileComponent } from '@app/chat/chat-attached-file/chat-attached-file.component';
import { ChatDutyTsoOptionsComponent } from './chat-duty-tso-options/chat-duty-tso-options.component';
import { ScheduleComponent } from '@app/shared/components/schedule/schedule.component';
import { MessageInitialsPipe } from '@app/chat/pipes/message-initials.pipe';
import { ChatContactsOnlyFilterModule } from './chat-contacts-only-filter/chat-contacts-only-filter.module';
import { MyTsoModalComponent } from './change-tso/my-tso-modal/my-tso-modal.component';
import { ChooseTsoModalComponent } from './change-tso/choose-tso-modal/choose-tso-modal.component';
import { TsoListTableComponent } from './change-tso/tso-list-table/tso-list-table.component';
import { ChangeTsoTitleComponent } from './change-tso/change-tso-title/change-tso-title.component';
import { TechChatTsoSortPipe } from './pipes/tech-chat-tso-sort.pipe';
import { ChatTsoOptionsComponent } from './chat-tso-options/chat-tso-options.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    SharedModule,
    NgbModule,
    NgbPopoverModule,
    RouterModule,
    SVGSpriteModule,
    TreeModule,
    HighlightSearchModule,
    ContactsSortPipe,
    ScheduleComponent,
    MessageInitialsPipe,
    TechChatTsoSortPipe,
    ChatContactsOnlyFilterModule,
  ],
  declarations: [
    ChatGroupsSortingComponent,
    ChatComponent,
    ChatContactComponent,
    ChatContactsComponent,
    ChatGroupsComponent,
    ChatInputComponent,
    ChatMessagesComponent,
    ChatSectionSelectComponent,
    ChatTabsComponent,
    ChatTabComponent,
    ChatContactsFilterComponent,
    ChatGroupComponent,
    ChatGroupOrThemeEditComponent,
    ChatThemeComponent,
    ContactsFilterPipe,
    GroupSortPipe,
    ChatRolesFilterComponent,
    GroupsRolesFilterPipe,
    AccessUsersSortPipe,
    ChatUserItemComponent,
    ToLowerCaseUserRolePipe,
    TechChatComponent,
    TechChatTitleComponent,
    TradeChatComponent,
    TechChatUsersSortPipe,
    ChatMessageSelectedPanelComponent,
    ChatAttachedFileComponent,
    ChatDutyTsoOptionsComponent,
    MyTsoModalComponent,
    ChooseTsoModalComponent,
    TsoListTableComponent,
    ChangeTsoTitleComponent,
    ChatTsoOptionsComponent,
  ],
  exports: [ChatComponent, AccessUsersSortPipe],
  providers: [DestroyService],
})
export class ChatModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far);
  }
}
