<div>
  <div class="mb-2">
    <div class="radio">
      <input
        class="hidden-xs-up"
        type="radio"
        id="yes"
        name="yes"
        [value]="value"
        [checked]="value === true"
        (change)="value = true"
      />
      <label for="yes" class="radio-label">Да</label>
    </div>
  </div>
  <div class="mb-4">
    <div class="radio">
      <input
        class="hidden-xs-up"
        type="radio"
        id="no"
        name="sorting"
        [value]="value"
        [checked]="value === false"
        (change)="value = false"
      />
      <label for="no" class="radio-label">Нет данных</label>
    </div>
  </div>

  <div class="d-flex justify-content-center text-center">
    <button class="btn btn-dc-primary" (click)="apply()">Фильтровать</button>
  </div>
</div>
