<div>
  <div>
    <div class="kt-files-item kt-files-features">
      <div class="kt-files-item-head">Дополнительные условия</div>
      <div>
        <a (click)="addNote()" *ngIf="isEdit && !readonly">
          <fa-icon [icon]="['fas', 'plus']"></fa-icon>
        </a>
      </div>
    </div>
    <div class="kt-note kt-files-item" *ngFor="let note of notes; index as i">
      <div>
        <input
          type="text"
          *ngIf="isEdit"
          placeholder="Дополнительное условие"
          class="kt-input"
          [readonly]="readonly"
          name="name{{ note.id }}"
          [(ngModel)]="note.text"
          (change)="onTextChanged()"
        />
        <div *ngIf="!isEdit">{{ note.text || '-' }}</div>
      </div>
      <div>
        <a (click)="removeNote(i)" *ngIf="isEdit && !readonly">
          <fa-icon [icon]="['fas', 'times']"></fa-icon>
        </a>
      </div>
    </div>
    <div class="kt-note kt-files-item mb-5" *ngIf="!notes.length">
      <div>Нет данных</div>
    </div>
  </div>
</div>
