import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChatGroupThemeNameFormComponent } from '@app/chat/chat-groups/chat-group-theme-name-form/chat-group-theme-name-form.component';
import { ChatSectionService } from '@app/chat/services/chat-section.service';

import { ChatService } from '@app/chat/services/chat.service';
import { ModalService } from '@app/services/modal.service';
import { AuthService } from '@app/shared/services/auth.service';
import {
  ChatSectionsEnum,
  GroupSortingNames,
  SECTION_SORTING_OPTIONS,
  TechTypeEnum,
} from '@app/chat/constants/chat-sections.constants';
import { User } from '@app/shared/models/user.model';
import { ChatRoom, ChatSection } from '@app/chat/models/chat.model';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { filter, takeUntil } from 'rxjs/operators';
import { SocketDataService } from '@app/services/socket-data.service';
import { DestroyService } from '@app/services/destroy.service';
import { SectionSortingOptions, SortOption } from '@app/chat/models/chat.sorting-options.model';
import { ChatSortingService } from '@app/chat/services/chat-sorting.service';

@Component({
  selector: 'app-chat-groups',
  templateUrl: './chat-groups.component.html',
  styleUrls: ['./chat-groups.component.scss'],
  providers: [DestroyService],
})
export class ChatGroupsComponent implements OnInit {
  @Input() isModalView: boolean = false;
  // Для загрузки тех групп и контактов
  @Input() techType: TechTypeEnum | null = null;
  techTypeEnum = TechTypeEnum;
  broadcastForm: FormGroup;
  groups: ChatRoom[] = [];
  themes: ChatRoom[] = [];
  contactSelected: ChatRoom | null = null;
  filterString: string;
  chatSectionSelected: ChatSection;
  user: User;
  isChoosingSortDirection = false;
  rolesEnum = RolesEnum;
  isGroupsExpanded = true;
  isDutyFilterMenuOpened = false;
  isDutyFilterModalOpened = false;
  rolesSelected: string[] = [];

  protected totalUnreadMessagesCounter: number | '10+';

  chatSectionsEnum = ChatSectionsEnum;

  sortOptions: SortOption[];
  sortType: GroupSortingNames = GroupSortingNames.LAST_MESSAGE_DATE;

  constructor(
    private chatService: ChatService,
    private authService: AuthService,
    private destroy$: DestroyService,
    private chatDataService: SocketDataService,
    private chatSectionService: ChatSectionService,
    private modalService: ModalService,
    private chatSortingService: ChatSortingService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.filterString = this.chatService.contactsFilter;
    this.chatSectionSelected = this.chatSectionService.chatSectionSelected;

    this.authService.userStream.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });

    this.chatSectionService.chatSectionSelectedChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe((chatSectionSelected) => {
        this.chatSectionSelected = chatSectionSelected;

        this.filterGroups(this.chatService.getGroupsThemes(chatSectionSelected.name)['groups']);
      });

    this.filterGroups(this.chatService.getGroupsThemes(this.chatSectionSelected.name)['groups']);
    this.chatService.contactsFilterChanged.pipe(takeUntil(this.destroy$)).subscribe((filterString) => {
      this.filterString = filterString;
      this.filterGroups(this.chatService.getGroupsThemes(this.chatSectionSelected.name)['groups']);
    });

    this.themes =
      this.techType && this.chatService.getGroupsThemes(this.chatSectionSelected.name)['themes'][this.techType]
        ? (Object.values(
            this.chatService.getGroupsThemes(this.chatSectionSelected.name)['themes'][this.techType]
          ) as ChatRoom[])
        : (Object.values(this.chatService.getGroupsThemes(this.chatSectionSelected.name)['themes']) as ChatRoom[]);

    this.chatService.themesChanged.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const themes = this.chatService.getGroupsThemes(this.chatSectionSelected.name)['themes'];
      this.themes =
        this.techType && themes[this.techType]
          ? (Object.values(themes[this.techType]) as ChatRoom[])
          : (Object.values(themes) as ChatRoom[]);

      this.sortGroupsOnThemeChanged(this.sortType);
      this.totalUnreadMessagesCounter = this.chatService.totalUnreadCount([...this.groups, ...this.themes]);
    });

    this.chatService.groupsChanged.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.filterGroups(this.chatService.getGroupsThemes(this.chatSectionSelected.name)['groups']);

      this.totalUnreadMessagesCounter = this.chatService.totalUnreadCount([...this.groups, ...this.themes]);
      this.cdr.detectChanges();
    });

    this.contactSelected = this.chatService.getContactSelected();

    this.chatService.contactSelectedChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe((contactSelected) => (this.contactSelected = contactSelected));

    this.initForm();

    this.totalUnreadMessagesCounter = this.chatService.totalUnreadCount([...this.groups, ...this.themes]);

    this.chatSortingService.settings$
      .pipe(
        filter((settings) => !!settings),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (this.techType) return; // в Tech секции одна сортировка по умолчанию.

        type SectionWithContacts = Extract<keyof SectionSortingOptions, 'admin' | 'holding'>;
        const sectionName = this.chatSectionSelected.name as SectionWithContacts;

        this.sortType = this.chatSortingService.sortSettings[sectionName]?.groups;
        this.sortOptions = SECTION_SORTING_OPTIONS[sectionName]?.groups;
        this.sortGroupsOnThemeChanged(this.sortType);
      });
  }

  initForm() {
    this.broadcastForm = new FormGroup({
      content: new FormControl(null, Validators.required),
      superuser: new FormControl(null),
      accountant: new FormControl(null),
      expert: new FormControl(null),
      partner: new FormControl(null),
      admin_of_user: new FormControl(null),
      admin_of_direction: new FormControl(null),
      operator: new FormControl(null),
    });
  }

  filterGroups(groups) {
    this.groups = [];

    if (this.chatSectionSelected.name !== ChatSectionsEnum.TECH && !groups[this.techType]) {
      Object.keys(groups).forEach((room_id) => {
        const group: ChatRoom = groups[room_id];

        this.groups.push(group);

        // if (!!this.filterString) {
        //   if (group.title.includes(this.filterString)) {
        //     this.groups.push(group);
        //   }
        // } else {
        //   this.groups.push(group);
        // }
      });
    }

    if (this.chatSectionSelected.name === ChatSectionsEnum.TECH && this.techType && groups[this.techType]) {
      Object.keys(groups[this.techType]).forEach((room_id) => {
        const group: ChatRoom = groups[this.techType][room_id];

        this.groups.push(group);

        // if (!!this.filterString) {
        //   if (group.title.includes(this.filterString)) {
        //     this.groups.push(group);
        //   }
        // } else {
        //   this.groups.push(group);
        // }
      });
    }

    this.sortGroupsOnThemeChanged(this.sortType);
  }

  sortGroupsOnThemeChanged(sortType: GroupSortingNames): void {
    if (!sortType) return;

    this.groups = this.chatService.sortGroupRooms({
      rooms: this.groups,
      newOrder: sortType,
      userId: this.user?.id,
      childrenRooms: this.themes,
    });
  }

  openGroupNameModal() {
    const modalInstance = this.modalService.open(
      ChatGroupThemeNameFormComponent,
      { centered: true },
      {
        title: 'Создать группу',
      }
    );

    const componentInstance = modalInstance.componentInstance;

    componentInstance.cancelEdit
      .pipe(takeUntil(this.chatService.chatDestroyed$))
      .subscribe(() => modalInstance.close());

    componentInstance.saveName.pipe(takeUntil(this.chatService.chatDestroyed$)).subscribe((name: string) => {
      this.createGroup(name);
      modalInstance.close();
    });
  }

  createGroup(event: string) {
    const users: number[] = [this.user.id];

    switch (this.chatSectionSelected.name) {
      case ChatSectionsEnum.ADMIN:
      case ChatSectionsEnum.HOLDING:
        this.chatDataService.addGroup({
          section: this.chatSectionSelected.name,
          title: event,
          users: users,
        });
        break;
      case ChatSectionsEnum.TECH:
        this.chatDataService.addTechGroup({
          create_from: this.techType,
          title: event,
          users: users,
        });
    }
  }

  sortDirectionChange(sortType: GroupSortingNames): void {
    this.chatSortingService.updateSortSettings({ groups: sortType });
    this.sortPopupClose();
  }

  onSortDirectionNext() {
    this.isChoosingSortDirection = !this.isChoosingSortDirection;
  }

  sortPopupClose(): void {
    this.isChoosingSortDirection = false;
  }

  onToggleGroupsExpanded() {
    this.isGroupsExpanded = !this.isGroupsExpanded;
    if (!this.isGroupsExpanded) {
      this.chatService.hideAllGroup();
    }
  }

  trackByRoomId(index: number, room: ChatRoom): string {
    return room.room_id;
  }

  // private toggleThemes(): void {
  //   this.themes.forEach()
  // }

  // openDutyFilterMenu() {
  //   this.isDutyFilterMenuOpened = true;
  // }

  // closeDutyFilterMenu() {
  //   this.isDutyFilterMenuOpened = false;
  // }

  // openDutyTsoFilter() {
  //   event.stopPropagation();
  //   this.isDutyFilterModalOpened = true;
  //   this.closeDutyFilterMenu();
  // }

  // closeDutyTsoFilter() {
  //   this.isDutyFilterModalOpened = false;
  //   this.closeDutyFilterMenu();
  // }

  // applyDutyTsoFilter(event) {
  //   this.rolesSelected = event;
  //   this.closeDutyTsoFilter();
  // }

  // resetDutyTsoFilter() {
  //   this.rolesSelected = [];
  //   this.closeDutyTsoFilter();
  // }
}
