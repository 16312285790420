import { LotsHelper } from '@app/+trades/helpers/lots.helper';
import { Directive, ElementRef, HostListener } from '@angular/core';
import { FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[weightInput]',
  providers: [{ provide: NG_VALIDATORS, useExisting: WeightInputDirective, multi: true }],
})
export class WeightInputDirective implements Validator {
  constructor(private elRef: ElementRef) {}

  validate(control: FormControl<string>): ValidationErrors {
    if (control.value && !LotsHelper.isWeightValueValid(control.value)) {
      control.setValue(control.value.slice(0, -1));
      // clean min/max errors
      setTimeout(() => control.updateValueAndValidity(), 0);
    }

    return null;
  }

  @HostListener('paste', ['$event']) onPast(event: ClipboardEvent) {
    const newVal = this.getNewValue(event.clipboardData.getData('Text'));

    if (!LotsHelper.isWeightValueValid(newVal)) {
      event.preventDefault();
    }
  }

  private getNewValue(value: string): string {
    const prev = this.elRef.nativeElement.value;
    const selected = this.getSelectedText(this.elRef.nativeElement);
    return selected ? prev.replace(selected, value) : prev.concat(value);
  }

  private getSelectedText(elem: HTMLInputElement) {
    return elem.value.substring(elem.selectionStart, elem.selectionEnd);
  }
}
