import { Employee } from '@app/shared/models/employee.model';

export interface TradeEmployee extends Employee {
  selected?: boolean;
  disabled?: boolean;
  children: TradeEmployee[];
  provider_trades: number[];
  trades: number[];
}

export class TradeEmployee implements TradeEmployee {
  constructor(item: Employee) {
    for (const prop in item) {
      if (item.hasOwnProperty(prop)) {
        this[prop] = item[prop];
      }
    }
    this.disabled = false;
    this.selected = false;
  }
}
