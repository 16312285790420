import { DutyStatus } from '@app/shared/constants/duty.constants';
import { User } from '@app/shared/models/user.model';
import { DaySlot, PersonForApproval } from '@app/shared/enums/employee-duty';
import { DutyTab } from '@app/+employees/shared/enums/employee-duty.enum';

export interface DutyData {
  day_slots_info?: DaySlot;
  duty_schedule_list?: DutyListItem[];
}

export interface DutyListItem {
  end_date: string;
  id: number;
  status: DutyStatus;
  start_date: string;
  user: User;
  user_id: number;
  who_confirms_duty: PersonForApproval;
}

export interface DutyOffers extends DutyListItem {
  acceptCheck: string | null;
  removeCheck: string | null;
}

export interface DutyQueryParams {
  status?: DutyStatus;
  to_time: string;
  user_id?: number;
  from_time: string;
  id?: number;
}

export interface DutyFormData {
  start_date: string;
  user_id: number;
  end_date: string;
}

export interface TsoScheduleTimeItem {
  timeFormat: string;
  startDate: string;
  endDate: string;
  timestamp: number;
  timeChecked?: boolean;
  timeCheckedAtp?: {
    [DutyTab.AGREED]: boolean;
    [DutyTab.IN_PROCESS_WHIT_AGENT]: boolean;
    [DutyTab.IN_PROCESS_WHIT_SUPERUSER]: boolean;
  };
  processTimeChecked?: boolean;
  duty?: DutyListItem[];
  dutyInactive?: DutyListItem[]; // Возможно стоит сделать обект
  agreedUsers: User[];
  processAgentUsers: User[];
  activeUsers: User[]; // remove when the duty logic is complete
  processUsers: User[]; // remove when the duty logic is complete
  processSuperuserUsers: User[];
  allUsers?: User[];
  selectedUsersList?: User[];
  who_confirms_duty?: PersonForApproval;
  cssClass?: string;
  // Поля для покраски часовых слотов у агента
  countProcessUsers?: number;
  countActiveUsers?: number;
}

export interface TsoSchedule {
  date: Date;
  isLoading?: boolean;
  timeline: TsoScheduleTimeItem[];
}

export interface DutyUser extends User {
  acceptCheck: boolean;
  removeCheck: boolean;
}

export interface AgentsCounter {
  type: DutyTab;
  count: number;
}

export interface AgentsListCounter {
  agreedCount: number;
  inProcessSuperuser: number;
  inProcessAgent: number;
}

export interface DutyAgentsFilter {
  isFilterOpen: boolean;
  isFilterActive: boolean;
}
