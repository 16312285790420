import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject, zip, of } from 'rxjs';
import { FilesHelper } from '@app/shared/helpers/files.helper';
import { FaqTabs } from '@app/shared/enums/faq.common.enum';
import { FaqExistedImage, FaqFilesData } from '@app/shared/models/faq.api.model';
import { FaqApiService } from '../../services/faq.api.service';
import { FaqModalBaseOptions } from '@app/shared/constants/faq.common.constants';
import { FaqImageModalComponent } from '@app/+faq/faq-edit/faq-image-modal/faq-image-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';

const MAX_UPLOAD_FILES_SIZE = 10; // 10мб

@Component({
  selector: 'app-faq-files-field-component',
  templateUrl: './faq-files-field.component.html',
  styleUrls: ['./faq-files-field.component.scss'],
})
export class FaqFilesFieldComponent implements OnInit, OnDestroy {
  @Input() readonly = false;
  @Input() editMode = true;
  @Input() field: FaqTabs;
  @Input() itemId: number | string;
  @Output() updatedFiles = new EventEmitter<FaqFilesData>();

  @ViewChild('file') fileInput;

  isLoading = false;
  oldImages: FaqExistedImage[] = [];
  newImages: string[] = [];

  selectedImage: FaqExistedImage;

  private filesForUpdate: FaqFilesData;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private faqApiService: FaqApiService,
    private modalService: NgbModal,
    private notify: NotificationsService
  ) {}

  ngOnInit() {
    this.filesForUpdate = new FaqFilesData([], [], this.field === FaqTabs.Modules ? 'hint' : 'faq');
    this.filesChanges();
    if (this.itemId) {
      this.fetchImageNames();
    }
  }

  private fetchImageNames() {
    this.faqApiService
      .getImageNames(this.field, `${this.itemId}`)
      .subscribe(this.getImageNamesSuccess.bind(this), this.onApiError.bind(this));
  }

  private fetchImages(names: string[]) {
    this.isLoading = true;
    this.faqApiService
      .getImagesByNames(this.field, `${this.itemId}`, names)
      .subscribe(this.getImagesSuccess.bind(this), this.onApiError.bind(this));
  }

  private getImageNamesSuccess(names: string[] | null) {
    if (!names) {
      return;
    }
    this.fetchImages(names);
  }

  private getImagesSuccess(images: FaqExistedImage[]) {
    zip(
      ...images.map((image) => {
        if (image.blob.size !== 0) {
          return FilesHelper.createImageFromBlob(image.blob);
        }
        return of('#');
      })
    ).subscribe(
      (links) => {
        links.forEach((src, index) => {
          images[index].src = src;
        });
        this.oldImages = images;
        if (images.length) {
          this.selectedImage = images[0];
        }
        this.isLoading = false;
      },
      () => (this.isLoading = false)
    );
  }

  handleFileClick(event) {
    event.stopPropagation();
    this.fileInput.nativeElement.click();
  }

  async addImage(event: Event) {
    const fileList = event?.target as HTMLInputElement;
    const files = Array.from(fileList.files);
    if (!fileList) return;

    if (!this.areFilesValid(files)) return;

    this.isLoading = true;
    const promises = files.map((file) => {
      FilesHelper.readFileAsDataURL(file).then((value) => this.newImages.push(value));
    });

    this.filesForUpdate.filesForSave.push(...files);
    await Promise.all(promises);
    this.filesChanges();
    this.isLoading = false;
  }

  areFilesValid(files: File[]) {
    const validExtensions = ['png', 'jpg'];
    const maxSizeBytes = MAX_UPLOAD_FILES_SIZE * 1024 * 1024;

    const invalidFiles = files.filter((file) => {
      const extension = file.name.split('.').pop()?.toLowerCase();
      return file.size > maxSizeBytes || !validExtensions.includes(extension || '');
    });

    const areFilesValid = !invalidFiles.length;

    if (!areFilesValid) {
      this.notify.warn('Внимание!', 'Файлы должны быть менее 10 МБ и иметь расширения: png, jpg');
      return false;
    }

    if (this.filesForUpdate.filesForSave.length + files.length > 10) {
      this.notify.warn('Внимание!', 'Можно добавить не более 10 файлов.');
      return false;
    }

    return areFilesValid;
  }

  removeNewImage(index: number) {
    this.filesForUpdate.filesForSave.splice(index, 1);
    this.newImages.splice(index, 1);
    this.filesChanges();
  }

  removeOldImage(index: number) {
    this.filesForUpdate.filesForRemove.push(this.oldImages[index].name);
    this.oldImages.splice(index, 1);
  }

  private onApiError(error) {
    console.warn(error);
    this.isLoading = false;
  }

  private filesChanges() {
    this.updatedFiles.emit(this.filesForUpdate);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  selectImageFile(oldFile: FaqExistedImage) {
    this.selectedImage = oldFile;
  }

  previewImage(image: string) {
    const modal = this.modalService.open(FaqImageModalComponent, {
      ...FaqModalBaseOptions,
      backdrop: true,
      windowClass: 'dc-modal modal-window',
    });
    (<FaqImageModalComponent>modal.componentInstance).image = image;
    // modal.result.then();
  }
}
