import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from './../../environments/environment.local';

import { SharedModule } from '@app/shared/shared.module';
import { SVGSpriteModule } from '@app/svg-sprite/svg-sprite.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { InputTrimModule } from '../shared/directives/trim.module';
import { ActivateComponent } from './activate/activate.component';
import { ConfirmChangeEmailComponent } from './confirm-change-email/confirm-change-email.component';
import { ConfirmResetPassComponent } from './confirm_resetpass/confirm_resetpass.component';
import { InviteComponent } from './invite/invite.component';
import { ResetPassComponent } from './resetpass/resetpass.component';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';

library.add(faEye, faEyeSlash, faChevronLeft);

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    RecaptchaModule,
    RouterModule,
    SharedModule,
    InputTrimModule,
    FontAwesomeModule,
    TranslateModule,
    SVGSpriteModule,
    ReactiveFormsModule,
  ],
  declarations: [
    SigninComponent,
    SignupComponent,
    ActivateComponent,
    InviteComponent,
    ResetPassComponent,
    ConfirmResetPassComponent,
    ConfirmChangeEmailComponent,
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.google_captcha_key } as RecaptchaSettings,
    },
  ],
})
export class AuthModule {}
