import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { SharedModule } from '@app/shared/shared.module';

@Component({
  selector: 'app-chat-group-theme-name-form',
  standalone: true,
  imports: [SharedModule, ReactiveFormsModule],
  templateUrl: './chat-group-theme-name-form.component.html',
  styleUrls: ['./chat-group-theme-name-form.component.scss'],
})
export class ChatGroupThemeNameFormComponent implements OnInit {
  @Input() isShowed = true;
  @Input() name: string;
  @Input() title = '';
  @Input() placeholder = 'Введите название группы';

  @Output() saveName = new EventEmitter<string>();
  @Output() cancelEdit = new EventEmitter();

  chatGroupNameForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.chatGroupNameForm = this.fb.group({
      name: [this.name || '', [Validators.maxLength(50)]],
    });
  }

  closeModal() {
    this.cancelEdit.emit(false);
  }

  saveChanges() {
    this.saveName.emit(this.chatGroupNameForm.controls.name.value);
    this.chatGroupNameForm.reset();
  }
}
