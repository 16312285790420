<app-table-header-cell
  [column]="column"
  (filterEvent)="clickFilterIcon()"
  (clearEvent)="clickFilterClearIcon()"
></app-table-header-cell>

<app-modal-window [isShowed]="column.isFilterOpen" (closeEvent)="closeFilter()" customClass="trades-filter-modal">
  <div class="modal-header" appTestLocator="filter-templates__filter-title">
    <div class="modal-title" appTestLocator="filter-templates__filter-title">{{ column.label }}</div>
  </div>

  <ng-container *ngIf="column.id === tradeColumnKeys.ID || column.id === tradeColumnKeys.SUPPLIER_TRADE_ID">
    <app-multiple-search-text
      *ngIf="column.isFilterOpen"
      appTestLocator="catalog-tree__multiple-search-text"
      title="Выделить всё/снять выделение"
      [filterSuggestions]="filterSuggestions"
      [placeholder]="column.searchPlaceholder"
      [filteredValue]="filterValue"
      [template]="extraName"
      (submitEvent)="saveMultipleSearchFilter($event)"
      (searchEvent)="getSearchValue($event)"
    >
    </app-multiple-search-text>

    <ng-template #extraName let-item>
      <label
        class="cbx-label w-100"
        [for]="'multiple-livesearch-select' + item.id"
        [ngbTooltip]="item.label"
        [disableTooltip]="item.label?.toString()?.length < 24"
        appTestLocator="'multiple-livesearch-select__item-' + item.id"
      >
        <div class="column-id">
          <span class="cbx-label-text" [innerHTML]="item.label | highlight : searchValue"></span>
        </div>
        <div class="text-overflow">
          <span class="cbx-label-text" [innerHTML]="item.data[0] | highlight : searchValue"></span>
        </div>
      </label>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="column.id === tradeColumnKeys.COMPETENCIES">
    <div class="dc-tabs mb-0">
      <div
        class="dc-tab dc-tab--secondary competencies-tabs"
        *ngFor="let tab of tabs"
        (click)="onSelectTab(tab)"
        [class.active]="currentTabValue === tab.value"
      >
        <span class="padding-r-6">{{ tab.label }}</span>
      </div>
    </div>
    <div class="tree" *ngIf="column.isFilterOpen">
      <ng-container *ngIf="currentTabValue === 'products'">
        <app-tree-checkbox-list
          [placeholder]="column.searchPlaceholder"
          [treeList]="goods"
          [filterValue]="filterValue"
          (submitEvent)="saveTreeCheckboxesFilter($event)"
        ></app-tree-checkbox-list>
      </ng-container>
      <ng-container *ngIf="currentTabValue === 'services'">
        <app-tree-checkbox-list
          [placeholder]="column.searchPlaceholder"
          [treeList]="services"
          [filterValue]="filterValue"
          (submitEvent)="saveTreeCheckboxesFilter($event)"
        ></app-tree-checkbox-list>
      </ng-container>
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      column.id === tradeColumnKeys.PROJECT_DATE ||
      column.id === tradeColumnKeys.TRADE_DATE ||
      column.id === tradeColumnKeys.ARCHIVE_DATE ||
      column.id === tradeColumnKeys.PRETRADE_DATE
    "
  >
    <app-double-date-time-picker-filter
      *ngIf="column.isFilterOpen"
      [withName]="doubleDatePickerWithName"
      [column]="column"
      [filterSuggestions]="filterSuggestions"
      [filteredValue]="filterValue"
      (submitEvent)="saveDateTimeRangeFilter($event)"
    ></app-double-date-time-picker-filter>
  </ng-container>

  <ng-container *ngIf="column.id === tradeColumnKeys.CUSTOMERS || column.id === tradeColumnKeys.SUPPLIERS">
    <div class="only-mine">
      <div>
        <a>
          <fa-icon icon="crown" class="only-mine__icon"></fa-icon>
        </a>
      </div>
      <div>
        <input id="my_trade" class="cbx-input" [formControl]="isMyTrade" type="checkbox" />
        <label class="cbx-label" for="my_trade">
          <span class="cbx-label-text">Только мои</span>
        </label>
      </div>
    </div>
    <app-multiple-search-text
      *ngIf="column.isFilterOpen"
      appTestLocator="catalog-tree__multiple-search-text"
      title="Выделить всё/снять выделение"
      [filterSuggestions]="filterSuggestions"
      [placeholder]="column.searchPlaceholder"
      [filteredValue]="filterValue"
      (submitEvent)="saveMultipleSearchFilter($event)"
      (filterSuggestionsEmit)="selectFilterSuggestions($event)"
    ></app-multiple-search-text>
  </ng-container>

  <ng-container
    *ngIf="
      column.id === tradeColumnKeys.STATUS ||
      column.id === tradeColumnKeys.REASON_END_TRADE ||
      column.id === tradeColumnKeys.ACCESS
    "
  >
    <app-multiselect-filter
      *ngIf="column.isFilterOpen"
      [list]="filterSuggestions"
      [selected]="filterValue"
      (submitEvent)="saveMultiSelectFilter($event)"
    ></app-multiselect-filter>
  </ng-container>

  <ng-container *ngIf="column.id === tradeColumnKeys.WINNERS_PROVIDERS">
    <!-- TODO implement later -->
    <ng-template #collapsed let-item></ng-template>
  </ng-container>

  <ng-container
    *ngIf="
      column.id === tradeColumnKeys.POTENTIAL_SUPPLIERS ||
      column.id === tradeColumnKeys.SENDED_INVITATIONS ||
      column.id === tradeColumnKeys.ACCEPTED_INVITATIONS ||
      column.id === tradeColumnKeys.TRADE_SUPPLIER_PLAYERS ||
      column.id === tradeColumnKeys.TARIFF ||
      column.id === tradeColumnKeys.TRADE_RESULTS
    "
  >
    <app-form-range-slider
      *ngIf="column.isFilterOpen"
      [setValueFrom]="rangeFormMinControl.value"
      [setValueTo]="rangeFormMaxControl.value"
      [minRange]="rangeFormMinControl.value"
      [maxRange]="rangeFormMaxControl.value"
      [setSliderValue]="sliderMinMaxValues"
      [step]="1"
      (changeRange)="changeRange($event)"
      class="create-filter-slider"
    ></app-form-range-slider>
    <div class="d-flex justify-content-between action-btns">
      <button
        type="button"
        class="btn btn-dc-outline margin-r-30"
        (click)="cancel()"
        appTestLocator="double-datepicker-filter__cancel-button"
      >
        Отменить
      </button>
      <button
        type="button"
        class="btn btn-dc-primary"
        (click)="saveRangeFilter()"
        appTestLocator="double-datepicker-filter__confirm-button"
      >
        Применить
      </button>
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      column.id === tradeColumnKeys.PAYER ||
      column.id === tradeColumnKeys.TITLE ||
      column.id === tradeColumnKeys.CUSTOMER_COMPANY ||
      column.id === tradeColumnKeys.CUSTOMER_NAME
    "
  >
    <app-multiple-search-text
      *ngIf="column.isFilterOpen"
      appTestLocator="catalog-tree__multiple-search-text"
      title="Выделить всё/снять выделение"
      [filterSuggestions]="filterSuggestions"
      [placeholder]="column.searchPlaceholder"
      [filteredValue]="filterValue"
      (submitEvent)="saveMultipleSearchFilter($event)"
    >
    </app-multiple-search-text>
  </ng-container>

  <ng-container *ngIf="column.id === tradeColumnKeys.REQ_DATA_STATE || column.id === tradeColumnKeys.REQ_DATA">
    <app-double-datepicker-filter
      *ngIf="column.isFilterOpen"
      [value]="filterValue"
      [placeholder]="column.searchPlaceholder"
      (submitEvent)="saveDateRangeFilter($event)"
      (closeEvent)="closeFilter()"
    >
    </app-double-datepicker-filter>
  </ng-container>
</app-modal-window>
