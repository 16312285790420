import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationsService } from 'angular2-notifications';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import printJS from 'print-js';
import saveAs from 'file-saver';
import { AuthService } from '@app/shared/services/auth.service';
import { PaymentRegistryService } from './payment-registry.service';
import { paymentRegistryColumns, paymentRegistryColumnsByUserType } from '../constants/payment-registry.constants';
import {
  AggregationDataGeneral,
  BaseAggregationDataPayer,
  PaymentRegistryReportOptions,
} from '../models/payment-registry.model';
import { UserTypes } from '@app/shared/types/user.types';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { PAYMENT_FILTER_NAMES, PaymentTabEnum } from '../constants/payment.constants';
import { PersonalDataService } from '@app/shared/services/personal-data.service';
import { PaymentRegisterSettings } from '@app/shared/models/user-settings.model';
import { GeneralWalletAggregation } from '../models/wallet-balance.model';
import { FilterPayment, FilterPaymentRegister, PaymentState } from '../models/payment-state.model';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { paymentAccrualsColumns } from '../constants/payment-accruals.constants';
import { GeneralAccrualAggregation } from '../models/payment-accrual.model';
import { paymentWalletColumns } from '../constants/payment-wallet.constants';
import { InitialsPipe } from '@app/shared/pipes/users/initials.pipe';
import * as moment from 'moment';
import { PaymentPdfService } from './payment-pdf.service';
import { RegistersHelper } from '@app/+tariffs/helpers/registers.helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerComponent } from '@app/shared/components/spinner/spinner.component';
import { DestroyService } from '@app/services/destroy.service';
import isEqual from 'lodash/isEqual';

@Injectable()
export class PaymentRegistryDataService {
  state$: Observable<PaymentState>;
  private stateSubject = new BehaviorSubject<PaymentState>({
    data: {
      registry: PaymentRegistryDataService.emptyData(),
      wallet: PaymentRegistryDataService.emptyDataWallet(),
    },
    activeTab: null,
    isActiveFilters: false,
    loading: false,
    active: false,
    editable: false,
    modeAccrual: '',
    type: null,
    nameUser: '',
    userId: null,
    filter: {
      registry: { filters: [], extra: {} },
      wallet: { filters: [], extra: {} },
      accruals: { filters: [], extra: {} },
    },
    columns: {
      registry: [],
      wallet: [],
      accruals: [],
    },
    displayedColumns: {},
    actions: {
      balanceUp: false,
      editToggle: false,
    },
  });
  private actionsSubject = new Subject<{ type: string; payload?: any }>();
  isPdfLoading = false;
  MAXIMUM_TABLE_SIZE = 1485;
  paymentRegistryColumns = paymentRegistryColumns;

  private static emptyData(): AggregationDataGeneral {
    return {} as AggregationDataGeneral;
  }

  private static emptyDataWallet(): GeneralWalletAggregation {
    return {} as GeneralWalletAggregation;
  }

  private static emptyDataAccruals() {
    return [] as GeneralAccrualAggregation[];
  }

  constructor(
    private paymentRegistry: PaymentRegistryService,
    private notify: NotificationsService,
    private personalDataService: PersonalDataService,
    private auth: AuthService,
    private paymentPdfService: PaymentPdfService,
    private ngUnsubscribe: DestroyService,
    private modalService: NgbModal
  ) {
    this.state$ = this.stateSubject.asObservable();
    this.getData();
  }

  getData() {
    this.actionsSubject
      .asObservable()
      .pipe(
        filter((action) => ['init', 'filter', 'resetFilters', 'displayedColumns'].includes(action.type)),
        switchMap(() => this.personalDataService.getUserSettings()),
        tap((setting) => this.dispatch({ type: 'setSetting', payload: setting })),
        tap((setting) => this.dispatch({ type: 'hasActiveFilters', payload: setting })),
        withLatestFrom(this.state$),
        switchMap(([_, state]) => {
          let request$: Observable<any>;

          switch (state.activeTab) {
            case PaymentTabEnum.ACCRUALS:
              request$ = this.requestAccrual({ filter: state.filter.accruals }).pipe(
                map((payload) => ({ type: 'updateAccrual', payload }))
              );
              break;

            case PaymentTabEnum.REGISTRY:
              request$ = this.requestRegistry({ filter: state.filter.registry, type: state.type }).pipe(
                map((payload) => ({ type: 'update', payload: payload ?? {} }))
              );
              break;

            case PaymentTabEnum.WALLET:
              request$ = this.requestWallet({ filter: state.filter.wallet, type: state.type }).pipe(
                map((payload) => ({ type: 'updateWallet', payload }))
              );
              break;

            default:
              return EMPTY;
          }

          return request$.pipe(
            catchError((e) => {
              state.loading = false;
              this.handleError(e);
              return EMPTY;
            })
          );
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((action) => this.dispatch(action));

    this.userChangedInit();
  }

  init() {
    this.dispatch({ type: 'init' });
  }

  userChangedInit() {
    this.auth.userStream
      .pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged(isEqual), withLatestFrom(this.auth.isTimeZoneChanged))
      .subscribe(([payload, isTimezoneChanged]) => {
        if (!isTimezoneChanged) {
          this.stateSubject.next({
            ...this.stateSubject.value,
            filter: {
              registry: { filters: [], extra: {} },
              wallet: { filters: [], extra: {} },
              accruals: { filters: [], extra: {} },
            },
          });
        }
        this.dispatch({ type: 'changeUser', payload, isTimezoneChanged });
      });
  }

  onToggleEditable() {
    this.dispatch({ type: 'toggleEditable' });
  }

  onFilterChange(payload: {
    registry?: FilterPaymentRegister;
    accruals?: FilterPaymentRegister;
    wallet?: FilterPaymentRegister;
  }) {
    this.dispatch({ type: 'filter', payload });
  }

  onDisplayedColumnsChange(payload) {
    this.dispatch({ type: 'displayedColumns', payload });
  }

  onColumnsChange(payload) {
    this.dispatch({ type: 'columns', payload });
  }

  onChangeTab(payload) {
    this.dispatch({ type: 'changeTab', payload });
  }

  onResetFilters(payload: string) {
    this.dispatch({ type: 'resetFilters', payload });
  }

  onRemovingRestriction({ byPayer, checked }: { byPayer: BaseAggregationDataPayer; checked: HTMLInputElement }) {
    const status = checked ? 'manual_active' : 'manual_blocked';
    this.paymentRegistry
      .updateRestrictionsTrade({
        status,
        user_payer_id: byPayer.payer.id,
      })
      .subscribe(
        () => {
          this.dispatch({ type: 'updateByPayer', payload: { byPayer, changes: { removing_restrictions: status } } });
        },
        (error) => this.handleError(error)
      );
  }

  pdfReport(options: PaymentRegistryReportOptions) {
    if (this.isPdfLoading) return;

    const modalRef = this.modalService.open(SpinnerComponent, {
      centered: true,
      animation: true,
      backdrop: 'static',
      keyboard: false,
    });

    this.isPdfLoading = true;
    this.paymentRegistry
      .pdfReport(options)
      .pipe(switchMap(({ url }) => this.paymentRegistry.downloadPdf(url)))
      .subscribe(
        (response) => {
          const fileName = options.nameFile ? options.nameFile + '.pdf' : 'document.pdf';
          saveAs(response.body, fileName);
        },
        (error) => this.handleError(error),
        () => {
          this.isPdfLoading = false;
          modalRef.close();
        }
      );
  }

  printTable(options: PaymentRegistryReportOptions) {
    if (this.isPdfLoading) return;

    const modalRef = this.modalService.open(SpinnerComponent, {
      centered: true,
      animation: true,
      backdrop: 'static',
      keyboard: false,
    });

    this.isPdfLoading = true;
    this.paymentRegistry
      .pdfReport(options)
      .pipe(switchMap(({ url }) => this.paymentRegistry.downloadPdf(url)))
      .subscribe(
        (response) => {
          printJS(URL.createObjectURL(response.body));
        },
        (error) => this.handleError(error),
        () => {
          this.isPdfLoading = false;
          modalRef.close();
        }
      );
  }

  private handleError(error: HttpErrorResponse) {
    this.notify.error('Ошибка!', error.message || error.status, {
      clickToClose: true,
    });
  }

  private dispatch(action: { type: string; payload?: any; isTimezoneChanged?: boolean }) {
    const prev = this.stateSubject.value;
    let state = this.reduce(prev, action);
    if (!state) {
      return;
    }

    switch (action.type) {
      case 'changeTab':
      case 'resetFilters':
      case 'filter': {
        this.updateUserSetting(state, action).subscribe((res) => {
          this.actionsSubject.next(action);
        });
        break;
      }
      case 'displayedColumns': {
        state = this.resetHiddenColumnsFilter(action.payload, state);
        this.setTableWidth(action.payload);
        this.updateUserSetting(state, action).subscribe((res) => {
          this.actionsSubject.next(action);
        });
        break;
      }
      default:
        this.actionsSubject.next(action);
    }

    this.stateSubject.next(state);
  }

  private reduce(
    state: PaymentState,
    { type, payload, isTimezoneChanged }: { type: string; payload?: any; isTimezoneChanged?: boolean }
  ): PaymentState {
    switch (type) {
      case 'init': {
        return {
          ...state,
          data: {
            registry: PaymentRegistryDataService.emptyData(),
            wallet: PaymentRegistryDataService.emptyDataWallet(),
            accruals: PaymentRegistryDataService.emptyDataAccruals(),
          },
          editable: true,
          loading: true,
          filter: {
            registry: { ...state.filter.registry },
            wallet: { ...state.filter.wallet },
            accruals: { ...state.filter.accruals },
          },
        };
      }
      case 'setSetting': {
        const filtersPaymentRegister = RegistersHelper.getNewFilter(
          state.filter.registry,
          payload.payment_register_settings.payment_register
        );
        const filtersWallet = RegistersHelper.getNewFilter(
          state.filter.wallet,
          payload.payment_register_settings.wallet_balance_report
        );
        const filtersTransactions = RegistersHelper.getNewFilter(
          state.filter.accruals,
          payload.payment_register_settings.transactions
        );

        const hiddenColumns =
          payload.payment_register_settings.payment_register?.hidden_columns?.reduce((acc, column) => {
            acc[column] = false;
            return acc;
          }, {}) || {};
        const registryColumns = state.columns.registry.map((column) => {
          return {
            ...column,
            filterConfig: RegistersHelper.writeColumnFilterValue(column, filtersPaymentRegister),
            isFilterActive: RegistersHelper.hasColumnFilterValue(column, filtersPaymentRegister),
          };
        });
        const allColumns = { ...state.columns, ...{ registry: registryColumns } };
        const walletColumns = state.columns.wallet.map((column) => {
          return {
            ...column,
            filterConfig: RegistersHelper.writeColumnFilterValue(column, filtersWallet),
            isFilterActive: RegistersHelper.hasColumnFilterValue(column, filtersWallet),
          };
        });
        const accrualsColumns = allColumns.accruals.map((column) => {
          return {
            ...column,
            filterConfig: RegistersHelper.writeColumnFilterValue(column, filtersTransactions),
            isFilterActive: RegistersHelper.hasColumnFilterValue(column, filtersTransactions),
          };
        });

        return {
          ...state,
          loading: true,
          activeTab:
            !state.activeTab && payload.payment_register_settings?.active_tab
              ? payload.payment_register_settings.active_tab
              : state.activeTab || PaymentTabEnum.REGISTRY,
          displayedColumns: { ...state.displayedColumns, ...hiddenColumns },
          filter: {
            registry: { ...filtersPaymentRegister },
            wallet: { ...state.filter.accruals, ...filtersWallet },
            accruals: { ...state.filter.accruals, ...filtersTransactions },
          },
          columns: {
            accruals: [...accrualsColumns],
            wallet: [...walletColumns],
            registry: [...allColumns.registry],
          },
        };
      }
      case 'changeTab': {
        return {
          ...state,
          activeTab: payload,
        };
      }
      case 'toggleEditable':
        return {
          ...state,
          editable: !state.editable,
        };
      case 'filter':
        return {
          ...state,
          filter: { ...state.filter, ...payload },
          loading: true,
        };
      case 'changeUser':
        const initialsPipe = new InitialsPipe();
        if (!payload) {
          return {
            ...state,
            type: null,
            columns: {
              registry: [],
              accruals: [],
              wallet: [],
            },
            displayedColumns: {},
            actions: {
              balanceUp: false,
              editToggle: false,
            },
          };
        }
        return {
          ...state,
          ...(isTimezoneChanged === null
            ? this.getColumns(payload.type)
            : { columns: state.columns, displayedColumns: state.displayedColumns }),
          type: payload.type,
          nameUser: initialsPipe.transform(payload),
          userId: payload.id,
          actions: {
            balanceUp: payload.type === RolesEnum.ADMIN_OF_USER || payload.type === RolesEnum.ADMIN_OF_DIRECTION,
            editToggle: payload.type === RolesEnum.SUPERUSER || payload.type === RolesEnum.ACCOUNTANT,
          },
        };
      case 'update':
        return {
          ...state,
          data: { registry: payload },
          loading: false,
        };
      case 'updateAccrual':
        return {
          ...state,
          data: { accruals: payload },
          loading: false,
        };
      case 'updateWallet':
        return {
          ...state,
          data: { wallet: payload },
          loading: false,
        };
      case 'columns':
        return {
          ...state,
          columns: {
            ...state.columns,
            registry: payload,
            wallet: payload.wallet,
          },
        };
      case 'displayedColumns':
        return {
          ...state,
          displayedColumns: payload,
        };
      case 'updateByPayer':
        return {
          ...state,
          ...this.updateByPayer(state, payload),
        };
      case 'resetFilters':
        return {
          ...state,
          isActiveFilters: false,
          filter: { ...state.filter, [payload]: { extra: {}, filters: [] } },
        };
      case 'hasActiveFilters':
        return {
          ...state,
          isActiveFilters: this.checkActiveFilters(state),
        };
    }
  }

  private updateByPayer(state: PaymentState, { byPayer, changes }): Partial<PaymentState> {
    let newState = {};
    for (let j = 0; j < state.data.registry.aggregation_of_data_by_month?.length; j++) {
      const byMonth = state.data.registry.aggregation_of_data_by_month[j];
      for (let k = 0; k < byMonth.aggregation_of_data_by_structure_ap?.length; k++) {
        const byStructure = byMonth.aggregation_of_data_by_structure_ap[k];
        const byPayerIndex: number = byStructure.aggregation_of_data_by_payer.indexOf(byPayer);
        newState = byStructure.aggregation_of_data_by_payer.map((payer) => {
          if (byPayer.payer.id === payer.payer.id) {
            payer.removing_restrictions = changes.removing_restrictions;
          }
        });
      }
    }
    return newState;
  }

  checkActiveFilters(state) {
    const currenFilterName = PAYMENT_FILTER_NAMES[state.activeTab];
    const currentFilter = state.filter[currenFilterName];

    return Object.keys(currentFilter.extra).length || currentFilter.filters.length;
  }

  private getColumns(type): any {
    const columnIds = paymentRegistryColumnsByUserType[type];
    if (!columnIds) {
      return { columns: [], displayedColumns: [] };
    }
    const columns = paymentRegistryColumns.filter((c) => columnIds.some((id) => c.id === id));
    const displayedColumns = paymentRegistryColumns.reduce((p, c) => {
      p[c.id] = columnIds.some((id) => c.id === id);
      return p;
    }, {});
    return {
      columns: { registry: columns, accruals: paymentAccrualsColumns, wallet: paymentWalletColumns },
      displayedColumns,
    };
  }

  private updateUserSetting(state: PaymentState, action) {
    const paymentRegisterSettings: PaymentRegisterSettings = {
      active_tab: state.activeTab,
      payment_register: {
        hidden_columns: Object.keys(state.displayedColumns).filter((item) => !state.displayedColumns[item]),
        filters: state.filter?.registry.filters || [],
        extra: state.filter?.registry.extra || {},
      },
      wallet_balance_report: {
        hidden_columns: Object.keys(state.displayedColumns).filter((item) => !state.displayedColumns[item]),
        filters: state.filter?.wallet.filters || [],
        extra: state.filter?.wallet.extra || {},
      },
      transactions: {
        filters: state.filter?.accruals.filters || [],
        extra: state.filter?.accruals.extra || {},
      },
    };

    return this.personalDataService.getUserSettings().pipe(
      switchMap((setting) => {
        return this.personalDataService.updateUserSettings({
          ...setting,
          payment_register_settings: paymentRegisterSettings,
        });
      })
    );
  }

  private resetHiddenColumnsFilter(columns: Record<string, boolean>, state: PaymentState): PaymentState {
    const hiddenColumns = Object.keys(columns)?.filter((key) => !columns[key]) || [];

    if (!hiddenColumns.length) {
      return state;
    }

    const filters = state.filter.registry;

    hiddenColumns.forEach((column) => {
      delete filters.extra[column];
    });

    state.filter.registry = {
      ...filters,
      filters: filters.filters.filter((item) => !hiddenColumns.includes(item.name)),
    };

    return state;
  }

  public onDownloadDocument(options: any) {
    const { type, payerId, month, year } = options;
    this.paymentRegistry
      .getFileByPayerId(payerId, month, year, type)
      .pipe(
        map((fileBlob) => {
          const fileName = `${type === 'aps' ? 'Акт' : 'СФ'}_${year}_${month}_${payerId}.pdf`;
          const link = URL.createObjectURL(new Blob([fileBlob], { type: 'application/pdf' }));
          return { fileName, link };
        }),
        switchMap(({ fileName, link }) => {
          return this.paymentPdfService.viewDoc(link, fileName);
        })
      )
      .subscribe(
        () => {},
        (error) => {
          console.log(error);
        }
      );
  }

  private requestRegistry(params: {
    filter: FilterPaymentRegister;
    type: UserTypes;
  }): Observable<AggregationDataGeneral> {
    const queryParams = this.getQueryParams(params.filter.filters);
    const currMonth = moment().month();
    if (params.type === RolesEnum.SUPERUSER || params.type === RolesEnum.ACCOUNTANT) {
      return this.paymentRegistry.getPaymentRegistry(queryParams, params.filter.extra).pipe(
        map((item) => {
          if (item && item.aggregation_of_data_by_month && item.aggregation_of_data_by_month.length) {
            item.aggregation_of_data_by_month.forEach((value) => (value.isEditable = true));
            return item;
          } else {
            return {} as AggregationDataGeneral;
          }
        })
      );
    }
    if (params.type === RolesEnum.PARTNER) {
      return this.paymentRegistry.getAgentWallet(queryParams, params.filter.extra).pipe(
        map((item) => {
          if (item && item.aggregation_of_data_by_month && item.aggregation_of_data_by_month.length) {
            item.aggregation_of_data_by_month.forEach((value) => {
              value.isEditable = value.month === currMonth;
            });
            return item;
          } else {
            return {} as AggregationDataGeneral;
          }
        })
      );
    }
    return this.paymentRegistry.getCustomerWallet(queryParams, params.filter.extra).pipe(
      map((item) => {
        if (item && item.aggregation_of_data_by_month && item.aggregation_of_data_by_month.length) {
          item.aggregation_of_data_by_month.forEach((value) => {
            value.isEditable = value.month === currMonth;
          });
          return item;
        } else {
          return {} as AggregationDataGeneral;
        }
      })
    );
  }

  private requestWallet(params: {
    filter: FilterPaymentRegister;
    type: UserTypes;
  }): Observable<GeneralWalletAggregation> {
    if (params.type === RolesEnum.SUPERUSER || params.type === RolesEnum.ACCOUNTANT) {
      return this.paymentRegistry.getWalletFull(params.filter.extra);
    }
    if (params.type === RolesEnum.PARTNER) {
      return this.paymentRegistry.getWalletAgent(params.filter.extra);
    }
    return this.paymentRegistry.getWalletCustomer(params.filter.extra);
  }

  requestAccrual(params: { filter: FilterPaymentRegister }): Observable<any> {
    const queryParams = this.getQueryParams(params.filter.filters);
    return this.paymentRegistry.getAccrual(queryParams, params.filter.extra);
  }

  private getQueryParams(data: FilterPayment[]) {
    if (!data) return;
    const params = [];
    data.forEach((item) => {
      if (params.find((filterObj) => filterObj.name === item.name)) return;
      params.push(item.filter);
    });
    return params || [];
  }

  setTableWidth(columns) {
    const pdfWidth = this.paymentRegistryColumns.reduce((sum, col) => {
      if (columns[col.id]) {
        return sum + Number(col.width);
      }
      return sum;
    }, 0);

    localStorage.setItem('isExceededPdfWidth', JSON.stringify(pdfWidth > this.MAXIMUM_TABLE_SIZE));
  }
}
