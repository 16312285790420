import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TreeSectionsAndFilters } from '@app/+competence-map/models/user-competency.model';
import { TreeNode } from '@circlon/angular-tree-component';

interface TTreeNode<T> extends TreeNode {
  data: T;
}

@Component({
  selector: 'app-my-catalog-tree-item',
  templateUrl: './my-catalog-tree-item.component.html',
  styleUrls: ['./my-catalog-tree-item.component.scss'],
})
export class MyCatalogTreeItemComponent {
  @Input() selected: boolean;
  @Input() readonly: boolean;
  @Input() searchValue: string;
  @Input() isShowDoubleArrow: boolean = true;

  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCheckboxChanges: EventEmitter<any> = new EventEmitter<any>();
  @Output() onToggle: EventEmitter<any> = new EventEmitter();

  @Output() onDoubleArrowClick: EventEmitter<TreeSectionsAndFilters> = new EventEmitter();

  private _node: TTreeNode<TreeSectionsAndFilters>;

  uniquePrefix = '';

  @Input() set checked(value: boolean | null) {
    this.node.data.selected = value;
  }

  @Input() set node(item: TTreeNode<TreeSectionsAndFilters>) {
    this.uniquePrefix = String(item?.data?.created_at || item?.data?.title?.split(' ')?.[0] || '');
    this._node = item;
    this._node.setIsExpanded(item.data.expanded ?? false);
  }

  get node() {
    return this._node;
  }

  get item() {
    return this.node.data;
  }

  get title() {
    return this.item.section?.title || this.item.title;
  }

  toggleNodeClicked(event: MouseEvent): void {
    event.stopPropagation();
    this.onToggle.next();
  }

  onSelectClicked(): void {
    this.onSelect.next({
      id: this.item.id,
      node: this.node,
    });
  }

  onCheckboxChange(newValue: boolean): void {
    if (this.readonly) {
      return;
    }

    this.node.data.selected = newValue;

    this.onCheckboxChanges.emit({
      id: this.item.id,
      node: this.node,
    });
  }

  doubleArrowClicked(): void {
    this.onDoubleArrowClick.next();
  }
}
