import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { LotState } from '@app/+trades/models/lot-state.model';

// Provide в CustomerPlayTradesComponent
@Injectable()
export class StateLotService {
  private state = new ReplaySubject<Map<string, LotState>>(1);
  private currentState = new Map<string, LotState>();

  statePublic$ = this.state.asObservable();

  constructor() {
    this.state.next(this.currentState);
  }

  updateState(key: string, newState: LotState) {
    this.currentState.set(key, newState);
    this.state.next(this.currentState);
  }
}
