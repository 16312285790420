import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-reputation-filters',
  templateUrl: './report-reputation-filters.component.html',
  styleUrls: ['./report-reputation-filters.component.scss']
})
export class ReportReputationFiltersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
