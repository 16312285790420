<div class="dc-modal-x-button dc-fa-button" (click)="close()">
  <app-svg-icon [height]="20" [width]="20" name="close"></app-svg-icon>
</div>
<div class="modal-window-content">
  <div class="modal-header">
    <div class="modal-title text-left">Изменить пароль</div>
  </div>

  <div class="modal-body">
    <form [formGroup]="form" class="profile-password-form">
      <div class="form-body">
        <dc-form-field-wrapper [style]="'bootstrap'" id="current_password">
          <dc-form-label>Введите текущий пароль:</dc-form-label>
          <app-password-form-input
            [ngClass]="{ 'invalid-password': !isCorrectPassword }"
            formInput
            formControlName="current_password"
            (input)="inputPassword()"
          ></app-password-form-input>
          <div
            class="form-field-error"
            *ngIf="form.get('current_password').touched && form.get('current_password').invalid"
          >
            <span *ngIf="form.get('current_password').errors.required">{{ messages.required }}</span>
          </div>
          <div *ngIf="!isCorrectPassword" class="form-field-error">Неверный пароль</div>
        </dc-form-field-wrapper>

        <dc-form-field-wrapper [style]="'bootstrap'" id="new_password">
          <dc-form-label>Введите новый пароль:</dc-form-label>
          <app-password-form-input
            [ngClass]="{ 'invalid-password': newPasswordInvalid }"
            formInput
            formControlName="new_password"
          ></app-password-form-input>
        </dc-form-field-wrapper>

        <dc-form-field-wrapper [style]="'bootstrap'" id="confirm_password">
          <dc-form-label>Подтвердите новый пароль:</dc-form-label>
          <app-password-form-input
            [ngClass]="{ 'invalid-password': notEquivalentPasswords }"
            formInput
            formControlName="confirm_password"
          ></app-password-form-input>
        </dc-form-field-wrapper>
      </div>
    </form>

    <div class="password-requirements" [ngClass]="{ success: passwordUpdated }">
      <p class="password-requirements-title">Требования к паролю:</p>

      <div
        class="password-requirement"
        [ngClass]="{
          invalid: newPasswordInvalid,
          valid: !newPasswordInvalid
        }"
      >
        <div class="password-requirement-icon">
          <app-svg-icon [height]="9" [width]="12" name="check"></app-svg-icon>
        </div>
        <div class="password-requirement-text">
          должен содержать минимум 6 символов, в том числе одну заглавную букву и одну цифру
        </div>
      </div>
      <div
        class="password-requirement"
        [ngClass]="{
          invalid: newPasswordInvalid,
          valid: !newPasswordInvalid
        }"
      >
        <div class="password-requirement-icon">
          <app-svg-icon [height]="9" [width]="12" name="check"></app-svg-icon>
        </div>
        <div class="password-requirement-text">
          пароль может содержать латинские буквы, буквы кириллицы, цифры, специальные знаки
        </div>
      </div>
      <div
        class="password-requirement"
        [ngClass]="{
          invalid: notEquivalentPasswords,
          valid: !notEquivalentPasswords
        }"
      >
        <div class="password-requirement-icon">
          <app-svg-icon [height]="9" [width]="12" name="check"></app-svg-icon>
        </div>
        <div class="password-requirement-text">новый пароль должен совпадать в обоих полях</div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-dc-outline" [disabled]="passwordUpdated" (click)="close()" #closeButton>
      Отменить
    </button>
    <button type="button" class="btn btn-dc-primary" [disabled]="form.invalid" (click)="success()">Сохранить</button>
  </div>

  <div class="reset-password">
    <a class="cursor-pointer" (click)="resetPassword()">Забыли пароль?</a>
  </div>
</div>
