import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, map, pluck, switchMap } from 'rxjs/operators';

import { environment } from '@app/environments/environment';

import { createParamString } from './internals';

import { AuthorFile } from '@app/file-manager/models/author-file.model';
import { ShareUserQueryInterface } from '@app/file-manager/models/share-user-query.interface';
import { UserFileZipInterface } from '@app/file-manager/models/user-file-zip.interface';
import { FileManagerSpaceInterface } from '@app/file-manager/models/file-manager-space.interface';
import { UserFile, UserFileDestinationEnum, UserFileInterface } from '@app/file-manager/models/user-file.model';

import { FileManagerShareUserEnum } from '@app/file-manager/constants/file-manager-share.constants';
import { FileExtensionInterface } from '@app/file-manager/models/file-extension.interface';
import { MessageDraftParams } from '@app/file-manager/models/message-draft.interface';

@Injectable({
  providedIn: 'root',
})
export class FileManagerService {
  constructor(private readonly httpClient: HttpClient) {}

  /**
   * Получить информацию о доступных разрешениях
   */
  getFileManagerFileExtensions(): Observable<FileExtensionInterface> {
    return this.httpClient.get<FileExtensionInterface>(`${environment.api_url}/file/extensions`).pipe(
      map((data) => data),
      catchError((err) => throwError(err))
    );
  }

  /**
   * Получить информацию о доступном пространстве
   * @param userId
   */
  getFileManagerSpace(userId: number): Observable<FileManagerSpaceInterface> {
    return this.httpClient.get<FileManagerSpaceInterface>(`${environment.api_url}/user/${userId}/files/space`).pipe(
      map((data) => data),
      catchError((err) => throwError(err))
    );
  }

  /**
   * Получить список файлов и папок уровень 1
   * @param userId
   * @param destination
   */
  getFilesRoot(userId: number, destination?: UserFileDestinationEnum): Observable<UserFile[]> {
    const paramString = createParamString(['destination', destination]);
    const params = new HttpParams({ fromString: paramString });
    return this.httpClient
      .get<UserFileInterface[]>(`${environment.api_url}/user/${userId}/files/root`, { params })
      .pipe(
        map((userFiles: UserFileInterface[]) => {
          return userFiles.map(
            (file: UserFileInterface) =>
              new UserFile({
                ...file,
                virtualRootType: file.id < 0 ? UserFileDestinationEnum.SHARED : UserFileDestinationEnum.OWN,
              })
          );
        }),
        catchError((err) => throwError(err))
      );
  }

  /**
   * Получить список файлов папок по переданному Id
   * @param fileId
   * @param sharedDir
   */
  getChildrenFiles(fileId: number, sharedDir?: boolean): Observable<UserFile[]> {
    return this.httpClient.get<UserFileInterface[]>(`${environment.api_url}/file/${fileId}/children`).pipe(
      map((userFiles: UserFileInterface[]) => {
        return userFiles.map((file: UserFileInterface) => {
          const result = new UserFile({ ...file, virtualParentLink: fileId }, sharedDir);

          // Для системных папок создаем файлы сразу при загрузке первой системной папки которая имеет id
          if (file.system && file.children?.length) {
            result.virtualChildren = file.children.map(
              (systemFile: UserFileInterface) => new UserFile({ ...systemFile, virtualParentLink: file.id }, sharedDir)
            );
          }

          return result;
        });
      }),
      catchError((err) => throwError(err))
    );
  }

  /**
   * Для использования expand рекурсивного прохождения по папкам нужна выдача по одному
   * @param fileId
   * @param sharedDir
   */
  getChildrenFilesStream(fileId: number, sharedDir?: boolean): Observable<UserFile> {
    return this.getChildrenFiles(fileId, sharedDir).pipe(switchMap((list) => of(...list)));
  }

  /**
   * Создать вложенную директорию
   * @param fileId
   * @param name
   */
  createDir(fileId: number, name: string): Observable<UserFile> {
    return this.httpClient
      .post<UserFile>(`${environment.api_url}/file/${fileId}`, {
        name: name,
      })
      .pipe(
        map(
          (file: UserFileInterface) =>
            new UserFile({
              ...file,
              virtualParentLink: fileId,
            })
        ),
        catchError((err) => throwError(err))
      );
  }

  /**
   * Переименовать файл/папку
   * @param fileId
   * @param name
   */
  renameDir(fileId: number, name: string): Observable<UserFile> {
    return this.httpClient
      .put<UserFile>(`${environment.api_url}/file/${fileId}`, {
        new_name: name,
      })
      .pipe(
        map(
          (file: UserFileInterface) =>
            new UserFile({
              ...file,
              virtualParentLink: fileId,
            })
        ),
        catchError((err) => throwError(err))
      );
  }

  /**
   * Удалить папку файл
   * @param userId
   */
  deleteFile(userId: number): Observable<unknown> {
    return this.httpClient
      .delete(`${environment.api_url}/file/${userId}`, {})
      .pipe(catchError((err) => throwError(err)));
  }

  /**
   * Загрузка файлов в переданную папку
   * @param fileId
   * @param data
   */
  uploadFile(fileId: number, data: FormData): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders()
      .set('Enctype', 'multipart/form-data')
      .set('Accept', 'application/json');

    return this.httpClient
      .post(`${environment.api_url}/file/${fileId}/add_file`, data, {
        headers: headers,
        reportProgress: true,
        observe: 'events',
      })
      .pipe(catchError((err) => throwError(err)));
  }

  /**
   * Загрузка файлов в черновик сообщения.
   * @param room_id
   * @param content
   * @param files
   * @param user_files_ids
   * @param attached_files_ids
   * @param is_duty_tso
   * @param is_tso
   * @param parent_id
   */
  uploadMessageDraft({
    room_id,
    content = '',
    files = [],
    user_files_ids = [],
    attached_files_ids = [],
    is_duty_tso = false,
    is_tso = false,
    parent_id = null,
  }: MessageDraftParams) {
    const headers: HttpHeaders = new HttpHeaders()
      .set('Enctype', 'multipart/form-data')
      .set('Accept', 'application/json');

    const params: FormData = new FormData();
    files.forEach((file) => params.append('files', file, file.name));
    params.append('room_id', room_id);
    params.append('content', content);
    params.append('is_duty_tso', String(is_duty_tso));
    params.append('is_tso', String(is_tso));
    params.append('user_files_ids', JSON.stringify(user_files_ids));
    params.append('attached_files_ids', JSON.stringify(attached_files_ids));
    if (parent_id) params.append('parent_id', String(parent_id));

    return this.httpClient.post(`${environment.api_url}/chat/message-draft`, params, {
      headers,
      reportProgress: true,
      observe: 'events',
    });
  }

  /**
   * Список файлов загруженных за день
   * @param userId
   */
  getDownloadFilesByDay(userId: number): Observable<UserFile[]> {
    return this.httpClient.get<UserFileInterface[]>(`${environment.api_url}/user/${userId}/files/days_download`).pipe(
      map((files: UserFileInterface[]) => files.map((file) => new UserFile(file))),
      catchError(() => throwError([]))
    );
  }

  /**
   * Копировать файл
   * @param fileId
   * @param destinationId
   */
  copy(fileId: number, destinationId?: number): Observable<UserFileInterface> {
    let body = {};
    if (destinationId && destinationId > 0) {
      body = { destination_id: destinationId };
    }
    return this.httpClient.post<UserFileInterface>(`${environment.api_url}/file/${fileId}/copy`, body).pipe(
      map(
        (file: UserFileInterface) =>
          new UserFile({
            ...file,
            virtualParentLink: destinationId,
          })
      ),
      catchError((err) => throwError(err))
    );
  }

  /**
   * Переместить файл
   * @param fileId
   * @param destinationId
   */
  move(fileId: number, destinationId?: number): Observable<UserFileInterface> {
    let body = {};
    if (destinationId && destinationId > 0) {
      body = { destination_id: destinationId };
    }
    return this.httpClient.post<UserFileInterface>(`${environment.api_url}/file/${fileId}/move`, body).pipe(
      map(
        (file: UserFileInterface) =>
          new UserFile({
            ...file,
            virtualParentLink: destinationId,
          })
      ),
      catchError((err) => throwError(err))
    );
  }

  /**
   * Формирование архива из выделенных файлов/папок
   * @param fileIds
   * @param userId
   */
  downloadFiles(fileIds: number[], userId: number): Observable<any> {
    return this.httpClient
      .post(`${environment.api_url}/user/${userId}/files/download`, {
        file_ids: fileIds,
      })
      .pipe(pluck('name'));
  }

  /**
   * Просмотр сформированных архивов
   * @param userId
   */
  getZips(userId: number): Observable<UserFileZipInterface[]> {
    return this.httpClient.get(`${environment.api_url}/user/${userId}/files/zips`).pipe(
      map((zipList: UserFileZipInterface[]) => zipList),
      catchError(() => of([]))
    );
  }

  /**
   * Поиск файлов
   * @param userId
   * @param query
   * @param extList
   */
  searchFiles(userId: number, query: string, extList?: string[]): Observable<UserFile[]> {
    const paramString = createParamString(['ext_list', extList], ['q', query]);
    const params = new HttpParams({ fromString: paramString });
    return this.httpClient
      .get<UserFileInterface[]>(`${environment.api_url}/user/${userId}/files/search`, { params })
      .pipe(
        map((userFiles: UserFileInterface[]) => {
          return this.parseSearchResult(userFiles);
        }),
        catchError((err) => throwError(err))
      );
  }

  /**
   * Распарсить результаты поиска и преобразовать его в структуру UserFile[]
   * @param userFiles
   * @param rootFile
   * @private
   */
  private parseSearchResult(userFiles: UserFileInterface[], rootFile?: UserFile): UserFile[] {
    const result: UserFile[] = [];
    for (let i = 0; i < userFiles.length; i++) {
      const current = new UserFile({ ...userFiles[i], virtualOpened: true });
      if (userFiles[i]?.children?.length) {
        current.virtualChildren = this.parseSearchResult(userFiles[i]?.children, current);
      }
      result.push(current);
    }
    return result;
  }

  /**
   * Получить пользователей
   * @param fileId
   * @param type
   * @param queryParams
   */
  getShareUsers(
    fileId: number,
    type: FileManagerShareUserEnum,
    queryParams?: ShareUserQueryInterface
  ): Observable<any> {
    const paramString = createParamString(
      ['allowance', type],
      ['user_role', queryParams?.userRoles],
      ['query', queryParams?.query],
      ['page', queryParams?.page],
      ['per_page', queryParams?.perPage],
      ['section_ids', queryParams?.sectionIds]
    );
    const params = new HttpParams({ fromString: paramString });
    return this.httpClient.get(`${environment.api_url}/file/${fileId}/share`, { params }).pipe(
      map((data: any) => ({
        ...data,
        items: data.items.map((item) => new AuthorFile(item)),
      })),
      catchError(() => throwError([]))
    );
  }

  /**
   * Расшарить файл для пользователей
   * @param fileId
   * @param type
   * @param userIds
   */
  shareFile(fileId: number, type: 'all' | 'some', userIds?: number[]) {
    return this.httpClient
      .post(
        `${environment.api_url}/file/${fileId}/share`,
        {
          type,
          user_ids: userIds,
        },
        { responseType: 'text' }
      )
      .pipe(catchError((err) => throwError(err)));
  }

  /**
   * Отменить шаринг файла для пользователей
   * @param fileId
   * @param type
   * @param userIds
   */
  unShareFile(fileId: number, type: 'all' | 'some', userIds?: number[]) {
    return this.httpClient
      .post(
        `${environment.api_url}/file/${fileId}/unshare`,
        {
          type,
          user_ids: userIds,
        },
        { responseType: 'text' }
      )
      .pipe(catchError((err) => throwError(err)));
  }

  sort(a: UserFile, b: UserFile): number {
    if (!a.hasFile() && b.hasFile()) {
      return -1;
    } else if (a.hasFile() && !b.hasFile()) {
      return 1;
    } else if (!a.hasFile() && !b.hasFile()) {
      return this.sortNumberString(a, b);
    } else if (a.hasFile() && b.hasFile()) {
      return this.sortNumberString(a, b);
    }
    return 0;
  }

  /**
   * @deprecated
   * Загрузка файлов в корневую папку - метод упразнен в связи с обновлением структуры корневых папок
   * @param userId
   * @param file
   */
  uploadFileRoot(file: any, userId: number): Observable<any> {
    const data: FormData = new FormData();
    data.append('file', file, file.name);

    const headers: HttpHeaders = new HttpHeaders()
      .set('Enctype', 'multipart/form-data')
      .set('Accept', 'application/json');

    return this.httpClient
      .post(`${environment.api_url}/user/${userId}/files/root/add_file`, data, {
        headers: headers,
        reportProgress: true,
        observe: 'events',
      })
      .pipe(catchError((err) => throwError(err)));
  }

  /**
   * @deprecated
   * Создать корневую директорию
   * @param userId
   * @param name
   */
  createRootDir(userId: number, name: string): Observable<UserFile> {
    return this.httpClient
      .post<UserFile>(`${environment.api_url}/user/${userId}/files/root`, {
        name: name,
      })
      .pipe(
        map(
          (file: UserFileInterface) =>
            new UserFile({
              ...file,
              // virtualParentLink: DEFAULT_TREE_ROOT[UserFileDestinationEnum.OWN].id,
            })
        ),
        catchError((err) => throwError(err))
      );
  }

  private sortNumberString(a: UserFile, b: UserFile): number {
    if (a.basename().search(/^[0-9]+$/i) > -1 && b.basename().search(/^[0-9]+$/i) === -1) {
      return -1;
    } else if (a.basename().search(/^[0-9]+$/i) === -1 && b.basename().search(/^[0-9]+$/i) > -1) {
      return 1;
    } else if (a.basename().search(/^[0-9]+$/i) > -1 && b.basename().search(/^[0-9]+$/i) > -1) {
      const a_number = parseInt(a.basename(), 10);
      const b_number = parseInt(b.basename(), 10);
      if (a_number > b_number) {
        return 1;
      } else if (a_number < b_number) {
        return -1;
      }
    } else {
      if (a.basename() > b.basename()) {
        return 1;
      } else if (a.basename() < b.basename()) {
        return -1;
      }
    }
    return 0;
  }
}
