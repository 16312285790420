import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { SortOption } from '@app/chat/models/chat.sorting-options.model';
import { ContactsSortingNames, GroupSortingNames } from '@app/chat/constants/chat-sections.constants';

@Component({
  selector: 'app-chat-groups-sorting',
  templateUrl: './chat-groups-sorting.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatGroupsSortingComponent implements OnChanges {
  @Input() isOpen: boolean;
  @Input() title: string;
  @Input() sortOptions: SortOption[];
  @Input() sortType: ContactsSortingNames | GroupSortingNames;

  @Output() save = new EventEmitter<ContactsSortingNames | GroupSortingNames>();
  @Output() close = new EventEmitter<void>();

  selectedSortType: ContactsSortingNames | GroupSortingNames;

  ngOnChanges() {
    this.selectedSortType = this.sortType;
  }

  onSelect(sortType: string): void {
    this.selectedSortType = sortType as ContactsSortingNames | GroupSortingNames;
  }

  onClose(): void {
    this.close.emit();
  }

  onSave(event: Event): void {
    event.stopPropagation();
    this.save.emit(this.selectedSortType);
  }

  trackByType(_index: number, sortType: SortOption): string {
    return sortType.name;
  }
}
