import { Component, OnInit, OnDestroy } from '@angular/core';
import { SocketDataService } from '@app/services/socket-data.service';
import { NotificationService } from '@app/notification/services/notification.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import {
  NotificationDataSourceModel,
  NotificationEventEnum,
  NotificationTabEnum,
} from '@app/notification/const/notification.const';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  listTab = NotificationTabEnum;
  activeSelect = NotificationTabEnum.LIST;
  countNotification = 0;
  dataSource: Partial<NotificationDataSourceModel> = {
    noti: {
      notifications: [],
      total: 0,
    },
    settings: [],
    loading: false,
  };
  subscribeData: Subscription[] = [];

  constructor(private notificationService: NotificationService) {}

  onNotificationClose() {
    this.notificationService.toggleNotificationOpen();
  }

  ngOnInit() {
    this.subscribeData.push(
      this.notificationService.messagesChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((messages) => {
        this.dataSource = messages;
      })
    );
    this.subscribeData.push(
      this.notificationService.messagesUpdated.pipe(takeUntil(this.ngUnsubscribe)).subscribe((messages) => {
        if (messages.event === NotificationEventEnum.NEW_NOTIFICATION) {
          this.dataSource.noti.notifications.splice(0, 0, messages.data);
          this.dataSource.noti.total++;
        } else if (messages.event === NotificationEventEnum.SHOW_MORE_NOTIFICATION) {
          this.dataSource.noti.notifications.push(...messages.data.notifications);
          this.dataSource.noti.total = messages.data.total;
        }
      })
    );
    this.countNotification = this.notificationService.widgetMessageCounter;
    this.subscribeData.push(
      this.notificationService.widgetMessageCounterChanged
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((widgetMessageCounter) => (this.countNotification = widgetMessageCounter))
    );

    this.notificationService.isNotificationOpen$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isOpen) => {});
  }

  ngOnDestroy() {
    this.subscribeData.forEach((item) => {
      if (item) {
        item.unsubscribe();
      }
    });
  }
}
