<div class="kt-proj">
  <ng-container *ngIf="isTemplate || card.status === tradeCardStatuses.TEMPLATE || card.is_template">
    <div class="kt-item kt-proj-now">
      <p class="kt-proj-start">Шаблон</p>
      <div class="kt-proj-circle"></div>
      <!-- [ngClass]="{ hide: card.status !== tradeCardStatuses.TEMPLATE }" -->
      <div class="kt-proj-dt">
        <p>{{ card.project_date | dateFormat : timezoneOffset }}</p>
        <p>{{ card.project_date | timeFormat : timezoneOffset }}</p>
      </div>
    </div>
    <div
      class="kt-item"
      [ngClass]="{
        'kt-proj-now': card.status === tradeCardStatuses.ARCHIVE,
        'kt-proj-future': card.status !== tradeCardStatuses.ARCHIVE
      }"
    >
      <p class="kt-proj-start">Архив</p>
      <div class="kt-proj-circle"></div>

      <!-- [ngClass]="{ hide: !card.archive_date }" -->
      <!-- *ngIf="card.archive_date" -->
      <div class="kt-proj-dt">
        <ng-container>
          <p class="kt-proj-dt">{{ card.archive_date || card.project_date | dateFormat : timezoneOffset }}</p>
          <p class="kt-proj-dt">{{ card.archive_date || card.project_date | timeFormat : timezoneOffset }}</p>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!isTemplate && card.status !== tradeCardStatuses.TEMPLATE && !card.is_template">
    <ng-container *ngIf="!isSupplier">
      <div
        class="kt-item"
        [ngClass]="{ 'kt-proj-now': card.status === tradeCardStatuses.PROJECT, 'kt-proj-future': !card.status }"
      >
        <p class="kt-proj-start">Проект</p>
        <div class="kt-proj-circle"></div>
        <div class="kt-proj-dt" [ngClass]="{ hide: !isCardCreated() || !card.project_date }">
          <p>{{ card.project_date | dateFormat : timezoneOffset }}</p>
          <p>{{ card.project_date | timeFormat : timezoneOffset }}</p>
        </div>
      </div>
    </ng-container>

    <div
      class="kt-item"
      [ngClass]="{
        'kt-proj-now': card.status === tradeCardStatuses.PRE_TRADE,
        'kt-proj-future': card.status === tradeCardStatuses.PROJECT || !card.status
      }"
    >
      <p class="kt-proj-start">Предторги</p>
      <div class="kt-proj-circle"></div>
      <div class="kt-proj-dt">
        <ng-container *ngIf="card.pre_trade_date">
          <p>{{ card.pre_trade_date | dateFormat : timezoneOffset }}</p>
          <p>{{ card.pre_trade_date | timeFormat : timezoneOffset }}</p>
        </ng-container>

        <ng-container *ngIf="!card.pre_trade_date && card.archive_date">
          <ng-container *ngTemplateOutlet="emptyDate"></ng-container>
        </ng-container>
      </div>
    </div>

    <div class="kt-item-diff" *ngIf="card.trade_date && card.archive_date">
      <span>{{ currentDateUtc | diffDates : card.trade_date : card.status }}</span>
    </div>

    <div
      class="kt-item"
      [ngClass]="{
        'kt-proj-now': card.status === tradeCardStatuses.TRADE,
        'kt-proj-future': card.status !== tradeCardStatuses.TRADE && card.status !== tradeCardStatuses.ARCHIVE
      }"
    >
      <p class="kt-proj-start">Торги</p>
      <div class="kt-proj-circle"></div>
      <div class="kt-proj-dt">
        <ng-container
          *ngIf="
            (card.trade_date && card.status === tradeCardStatuses.ARCHIVE && card.pre_trade_date) ||
            (card.trade_date && card.status !== tradeCardStatuses.ARCHIVE)
          "
        >
          <p>{{ card.trade_date | dateFormat : timezoneOffset }}</p>
          <p>{{ card.trade_date | timeFormat : timezoneOffset }}</p>
        </ng-container>
        <ng-container
          *ngIf="
            (card.trade_date && card.status === tradeCardStatuses.ARCHIVE && !card.pre_trade_date) ||
            (!card.trade_date && !card.pre_trade_date && card.status === tradeCardStatuses.ARCHIVE)
          "
        >
          <p>&mdash;</p>
        </ng-container>
      </div>
    </div>

    <div
      class="kt-item-diff"
      *ngIf="
        (card.trade_date && card.archive_date && card.status !== tradeCardStatuses.ARCHIVE) ||
        (card.trade_date && card.archive_date && card.status === tradeCardStatuses.ARCHIVE && card.pre_trade_date)
      "
    >
      <span>{{ currentDateUtc | diffDates : card.archive_date : card.status }}</span>
    </div>

    <div
      class="kt-item"
      [ngClass]="{
        'kt-proj-now': card.status === tradeCardStatuses.ARCHIVE,
        'kt-proj-future': card.status !== tradeCardStatuses.ARCHIVE
      }"
    >
      <p class="kt-proj-start">Архив</p>
      <div class="kt-proj-circle"></div>
      <div class="kt-proj-dt" [ngClass]="{ hide: !card.archive_date }">
        <ng-container *ngIf="card.archive_date">
          <p class="kt-proj-dt">{{ card.archive_date | dateFormat : timezoneOffset }}</p>
          <p class="kt-proj-dt">{{ card.archive_date | timeFormat : timezoneOffset }}</p>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #emptyDate>
  <p>&mdash;</p>
</ng-template>
