<div class="file-item-container-title">
  <div
    class="file-item-container-check"
    [class.system-folder]="file.system || (!file.hasFile() && !folderCheck)"
    [class.active]="fileSelectedValue"
    (click)="$event.stopPropagation()"
  >
    <input
      class="cbx-input"
      [id]="file.id + '_' + placeCall"
      [(ngModel)]="fileSelectedValue"
      type="checkbox"
      [disabled]="file?.virtualDisabled || !canSelect"
      (change)="checkFile($event)"
    />
    <label
      class="cbx-label"
      [for]="file.id + '_' + placeCall"
      ngbTooltip="*Файл более 5мб."
      [disableTooltip]="canSelect || !file.is_file"
    ></label>
  </div>

  <div class="file-item-container-icon">
    <app-svg-icon
      [width]="file.hasFile() ? 12 : 16"
      [height]="file.hasFile() ? 16 : 12"
      [name]="file.hasFile() ? file.fileExtType().icon : 'folder'"
    ></app-svg-icon>
  </div>

  <div class="file-item-container-text">
    <span [innerHTML]="file.name | highlight : searchQuery"></span>
  </div>
</div>

<div class="file-item-container-actions">
  <div *ngIf="file?.in_shared_dir" class="file-item-container-actions__item">
    <app-svg-icon (click)="showUserInfo($event)" [width]="16" [height]="12.8" name="crown"></app-svg-icon>
  </div>
  <div
    *ngIf="!file?.in_shared_dir && (file?.is_shared || file?.inner_sharing)"
    class="file-item-container-actions__item"
    [class.disabled]="file?.inner_sharing"
  >
    <app-svg-icon (click)="shareFile($event)" [width]="18" [height]="12.6" name="users"></app-svg-icon>
  </div>
</div>
