import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CHAT_SECTIONS } from '../constants/chat-sections.constants';
import { ChatSection, ChatSections } from '../models/chat.model';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { UserTypes } from '@app/shared/types/user.types';
import { User } from '@app/shared/models/user.model';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '@app/shared/services/auth.service';
import { DestroyService } from '@app/services/destroy.service';

@Injectable({ providedIn: 'root' })
export class ChatSectionService {
  private _chatSections: ChatSections = { ...CHAT_SECTIONS };
  private _chatSectionSelected = new BehaviorSubject<ChatSection>(CHAT_SECTIONS.holding);
  public chatSectionSelectedChanged = this._chatSectionSelected.asObservable();

  constructor(private authService: AuthService, private readonly destroy$: DestroyService) {
    this.authService.userStream.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.createSections(user);
      this.setChatSectionSelected(this.chatSections.holding || this.chatSections.admin);
    });
  }

  get chatSectionSelected(): ChatSection {
    return this._chatSectionSelected.value;
  }

  get chatSections(): ChatSections {
    return this._chatSections;
  }

  set chatSections(chatSections) {
    this._chatSections = chatSections;
  }

  setChatSectionSelected(section: ChatSection) {
    if (this._chatSectionSelected.value.name !== section.name) {
      this._chatSectionSelected.next(section);
    }
  }

  createSections(user: User) {
    let chatSections: ChatSections = {};

    if (
      ([RolesEnum.ADMIN_OF_USER, RolesEnum.ADMIN_OF_DIRECTION, RolesEnum.OPERATOR] as UserTypes[]).includes(user.type)
    ) {
      chatSections = {
        holding: CHAT_SECTIONS.holding,
        trade: CHAT_SECTIONS.trade,
        tech: CHAT_SECTIONS.tech,
      };
    }

    if (
      ([RolesEnum.SUPERUSER, RolesEnum.ACCOUNTANT, RolesEnum.EXPERT, RolesEnum.PARTNER] as UserTypes[]).includes(
        user.type
      )
    ) {
      chatSections = {
        admin: CHAT_SECTIONS.admin,
        tech: CHAT_SECTIONS.tech,
      };
    }

    if (RolesEnum.PARTNER === user.type && user.flags.is_tso) {
      chatSections.tech.title = 'Раздел ОТП';
    }

    // if (RolesEnum.PARTNER === user.type && user.flags.is_duty_tso) {
    //   this.chatSections.push(this.chatService.chatSections.otp);
    // }

    this.chatSections = chatSections;
  }
}
