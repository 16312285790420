import { Pipe, PipeTransform } from '@angular/core';
import { TradeCustomer } from '../../models/customers.model';
import { AccessRights } from '../../constants/trades.constants';

@Pipe({
  name: 'tradeCustomersSort',
  pure: false,
})
export class TradeCustomersSortPipe implements PipeTransform {
  transform(array: TradeCustomer[]): TradeCustomer[] {
    const order = Object.keys(AccessRights);
    return array.sort(this.sortBySecondName).sort((a, b) => {
      const index1 = order.findIndex((key) => AccessRights[key] === a.access_rights);
      const index2 = order.findIndex((key) => AccessRights[key] === b.access_rights);
      return index1 - index2;
    });
  }

  private sortBySecondName(a: TradeCustomer, b: TradeCustomer): number {
    if (a.user.second_name < b.user.second_name) {
      return -1;
    }
    if (a.user.second_name > b.user.second_name) {
      return 1;
    }

    return 0;
  }
}
