import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DoubleRangeNumberFilterParams } from '@app/shared/models/filters.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { DestroyService } from '@app/services/destroy.service';

@Component({
  selector: 'app-range-number-filter',
  templateUrl: './range-number-filter.component.html',
  styleUrls: ['./range-number-filter.component.scss'],
  providers: [DestroyService],
})
export class RangeNumberFilterComponent implements OnInit, OnChanges {
  localValue: DoubleRangeNumberFilterParams;
  form: FormGroup;
  isInvalidRange: boolean;
  MAX = 999999999;
  MIN = -999999999;

  @Input() label: string;
  @Input() minValue: number = null;
  @Input() maxValue: number = null;
  @Input() operator: boolean;

  @Output() submitEvent = new EventEmitter<DoubleRangeNumberFilterParams>();

  @Input() set value(value: string[]) {
    if (!!value?.length) {
      if (typeof value !== 'string') {
        this.form.setValue({
          from: value[0] ? value[0] : null,
          to: value[1] ? value[1] : null,
        });
      } else {
        this.form.setValue({
          from: this.operator ? value : '',
          to: !this.operator ? value : '',
        });
      }
    }
  }

  get isActiveButton() {
    return (
      this.form.get('from').value ||
      (this.form.get('from').value && this.isMinValueValidator) ||
      (this.form.get('to').value && this.isMaxValueValidator)
    );
  }

  get isFormInvalid() {
    return this.form.get('from').invalid || this.form.get('to').invalid;
  }

  get isMinValueValidator(): boolean {
    return !!this.minValue ? !this.form.get('from').invalid : true;
  }

  get isMaxValueValidator(): boolean {
    return !!this.maxValue ? !this.form.get('to').invalid : true;
  }

  constructor(private fb: FormBuilder, private destroyed: DestroyService) {
    this.form = this.fb.group({
      from: [null],
      to: [null],
    });
  }

  ngOnInit() {
    this.localValue = Object.assign({}, this.localValue);

    if (this.minValue !== null && this.minValue !== undefined) {
      this.form.get('from').addValidators([Validators.min(this.minValue)]);
      this.form.get('to').addValidators([Validators.min(this.minValue)]);
    }

    if (this.maxValue !== null && this.maxValue !== undefined) {
      this.form.get('from').addValidators([Validators.max(this.maxValue)]);
      this.form.get('to').addValidators([Validators.max(this.maxValue)]);
    }

    this.form.valueChanges.pipe(takeUntil(this.destroyed)).subscribe((form) => {
      this.isInvalidRange =
        (form.from && form.to && +form.from > +form.to) ||
        form.from > this.MAX ||
        form.to > this.MAX ||
        form.from < this.MIN ||
        form.to < this.MIN ||
        form.from === '-' ||
        form.to === '-';
    });

    this.cleanEmojiOnValueChange('from');
    this.cleanEmojiOnValueChange('to');
  }

  ngOnChanges(changes: SimpleChanges) {
    const { value } = changes;

    if (value && value.currentValue) {
      this.localValue = Object.assign({}, this.localValue);
    }
  }

  private cleanEmojiOnValueChange(controlName: string) {
    this.form
      .get(controlName)
      ?.valueChanges.pipe(takeUntil(this.destroyed))
      .subscribe((value) => {
        const cleanedValue = this.removeEmojis(value);
        if (value !== cleanedValue) {
          this.form.get(controlName)?.setValue(cleanedValue, { emitEvent: false });
        }
      });
  }

  apply() {
    this.submitEvent.emit({
      from: this.form.get('from').value,
      to: this.form.get('to').value,
    });
  }

  removeEmojis(value: string): string {
    const emojiPattern =
      /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/gu;
    return value.replace(emojiPattern, '');
  }
}
