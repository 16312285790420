export enum PersonForApproval {
  AGENT = 'agent',
  SUPERUSER = 'atp',
}

export enum DaySlot {
  FILLED = 'filled',
  PROCESS = 'process',
  BLANK = 'blank',
}
