import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { tap } from 'rxjs/internal/operators';

import { ChatService } from '@app/chat/services/chat.service';
import { SocketDataService } from '@app/services/socket-data.service';
import { NotificationsService } from 'angular2-notifications';

import { UserFile } from '@app/file-manager/models/user-file.model';
import {
  ExportFileManagerType,
  FileManagerExportComponent,
} from '@app/file-manager/file-manager-export/file-manager-export.component';
import { DestroyService } from '@app/services/destroy.service';
import { ChatMessage, ChatRoom } from '@app/chat/models/chat.model';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { DEFAULT_MESSAGE_LOAD_COUNT } from '@app/shared/constants/chat.constants';
import { UploadMediaFilesInterface } from '@app/file-manager/models/file-upload.interface';
import { Subject } from 'rxjs';
import { FileManageUploadService } from '@app/file-manager/services/file-manage-upload.service';
import { FileManagerModalUploadComponent } from '@app/file-manager/components/file-manager-modal-upload/file-manager-modal-upload.component';
import { MAX_ATTACH_FILES_SIZE } from '@app/file-manager/constants/file-manager-base.constants';
import { convertMessagesAttachToUserFile } from '@app/chat/helpers/convert-messages-attach-to-user-file';
import { FILE_MESSAGE_LIMIT } from '@app/chat/constants';
import { MessageDraftParams } from '@app/file-manager/models/message-draft.interface';

@Component({
  selector: 'app-chat-input',
  templateUrl: './chat-input.component.html',
  styleUrls: ['./chat-input.component.scss'],
  providers: [DestroyService],
})
export class ChatInputComponent implements OnInit {
  @ViewChild('messageInput') private textArea: ElementRef;
  contactSelected: ChatRoom;
  isInputFocused: boolean;
  userFiles: UserFile[] = [];
  attachedFiles: UserFile[] = [];
  protected quotedMessage: ChatMessage;
  protected placeholder: string = 'Введите сообщение';
  private isDraftReceived = false;

  private updateDraft$ = new Subject<MessageDraftParams>();

  private modal: NgbModalRef;
  protected messageForm = new FormGroup({
    message: new FormControl(''),
  });

  constructor(
    private chatDataService: SocketDataService,
    private chatService: ChatService,
    private notify: NotificationsService,
    private readonly modalService: NgbModal,
    private readonly destroy$: DestroyService,
    private readonly fileManageUploadService: FileManageUploadService
  ) {}

  ngOnInit() {
    this.contactSelected = this.chatService.getContactSelected();
    this.chatService.contactSelectedChanged
      .pipe(
        filter((contact) => this.contactSelected.room_id !== contact.room_id),
        tap((contact) => (this.contactSelected = contact)),
        switchMap((contact) => this.chatDataService.getMessageDraft(contact.room_id)),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (draftMessage) => {
          this.isDraftReceived = true;
          this.setDraftDataResponse(draftMessage);
        },
        error: (err) => {
          this.isDraftReceived = true;
          console.log(err.message);
        },
      });

    this.chatService.focusChanged.pipe(takeUntil(this.destroy$)).subscribe((focus) => {
      this.isInputFocused = focus;
      this.textArea.nativeElement.focus();
    });

    this.fileManageUploadService.uploadedDraft$.pipe(takeUntil(this.destroy$)).subscribe((draftMessage) => {
      if (!draftMessage) return;

      this.setDraftDataResponse(draftMessage);
      this.modal?.close();
    });

    this.chatService.quotedMessage$.pipe(takeUntil(this.destroy$)).subscribe((quotedMessage) => {
      this.quotedMessage = quotedMessage;
      this.updateDraft();
    });

    this.messageForm.controls.message.valueChanges
      .pipe(
        debounceTime(300),
        filter((value) => !!value && this.isDraftReceived),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.updateDraft());

    this.updateDraft$
      .pipe(
        debounceTime(300),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        takeUntil(this.destroy$)
      )
      .subscribe((draftParams) => this.chatDataService.updateMessageDraft(draftParams));
  }

  private updateDraft() {
    const { content, parent_id, user_files_ids, attached_files_ids } = this.getMessageParams();
    if (!content && !attached_files_ids.length && !user_files_ids.length && !parent_id) return;

    this.updateDraft$.next(this.getMessageParams());
  }

  private getMessageParams() {
    const room_id = this.contactSelected.room_id;
    const content = this.messageForm.controls.message.value || '';
    const parent_id = this.quotedMessage?.id;
    const user_files_ids = this.getFilesIds(this.userFiles);
    const attached_files_ids = this.getFilesIds(this.attachedFiles);
    const is_duty_tso = this.chatService.isDutyTsoAction();
    const is_tso = this.chatService.isTsoAction();

    return { room_id, content, parent_id, user_files_ids, attached_files_ids, is_duty_tso, is_tso };
  }

  private setDraftDataResponse(draftMessage: ChatMessage) {
    this.messageForm.controls.message.setValue(draftMessage.content || '');
    this.quotedMessage = draftMessage.parent;
    this.userFiles = [];
    this.attachedFiles = draftMessage.attached_files;
  }

  onMessageFormSubmit() {
    const roomId = this.contactSelected.room_id;
    const formValue = this.messageForm.controls.message.value?.trim();

    if (!this.contactSelected.id || !(formValue || this.userFiles.length || this.attachedFiles.length)) return;

    this.chatDataService
      .sendMessage(this.getMessageParams())
      .pipe(
        tap(() => {
          this.messageForm.reset();
          this.chatService.focusChanged.next(true);
          this.userFiles = [];
          this.attachedFiles = [];
          this.removeQuotedMessage();
          this.resetTextareaHeight();
        }),
        filter(() => {
          const currentMessages = this.chatService.getMessagesRoomStore(roomId);
          return !!currentMessages?.offsets?.bottom;
        }),
        switchMap(() =>
          this.chatDataService.loadMessages({
            room_id: roomId,
            offset: 0,
            count: 2 * DEFAULT_MESSAGE_LOAD_COUNT,
            // Если будут баги - возможно сделать задержку перед получением данной переменной
            // Или формировать ее из this.contactSelected.tech_section_type
            is_duty_tso: this.chatService.isDutyTsoAction(),
            is_tso: this.chatService.isTsoAction(),
          })
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        const { items, bottom_count, top_count } = data;
        const messages = convertMessagesAttachToUserFile(items);
        this.chatService.clearMessagesByRoomId(roomId);
        this.chatService.setMessages(messages, roomId || '', { top: top_count, bottom: bottom_count });
      });
  }

  openFileManager() {
    const modal: NgbModalRef = this.modalService.open(FileManagerExportComponent, {
      centered: true,
      animation: true,
      windowClass: 'dc-modal export-file-manager-modal modal-window',
      size: 'xl',
    });

    modal.componentInstance.title = 'Добавить файлы в чат';
    modal.componentInstance.subTitle = this.contactSelected.title;
    modal.componentInstance.searchPlaceholder = 'Введите имя файла или папки';
    modal.componentInstance.exportType = ExportFileManagerType.CHAT;
    modal.componentInstance.selectFolders = false;

    modal.result.then((result) => {
      if (result && result.length) {
        this.handleSelectedExport(result);
        this.updateDraft();
      }
    });
  }

  openUploadManager() {
    this.modal = this.modalService.open(FileManagerModalUploadComponent, {
      centered: true,
      animation: true,
      windowClass: 'fileman-modal fileman-modal-upload modal-window',
    });
    this.modal.componentInstance.isChatUpload = true;
    this.fileManageUploadService.updateUploadedMediaList([]);

    this.modal.componentInstance.onUpload.pipe(takeUntil(this.destroy$)).subscribe((event: Event) => {
      const inputElement = event?.target as HTMLInputElement;
      const fileList = inputElement?.files;
      if (!fileList?.length) return;

      const files = Array.from(fileList);
      if (this.isFilesSizeExceeded(files)) return;
      if (this.isFileLimitExceeded(files)) return;

      this.uploadDraftFiles(files);
    });
  }

  private isFilesSizeExceeded(files: File[]) {
    const totalBytes = files.reduce((acc, file) => acc + file.size, 0);
    const totalFilesSizeMB = totalBytes / Math.pow(1024, 2);

    if (totalFilesSizeMB > MAX_ATTACH_FILES_SIZE) {
      this.notify.warn('Внимание!', `Размер выбранных файлов превышает ${MAX_ATTACH_FILES_SIZE} Мб`);
      return true;
    }
    return false;
  }

  handleSelectedExport(files: UserFile[]) {
    const exists = this.userFiles.filter((f) => files.some((file) => f.id === file.id));

    if (exists.length) {
      this.notify.warn('Внимание!', `Файлы уже добавлены к сообщению: ${exists.map((file) => file.name)}`);
      return;
    }
    if (this.isFileLimitExceeded(files)) return;

    files.forEach((file) => {
      this.userFiles.push(file);
    });
  }

  private isFileLimitExceeded(newFiles: FileList | File[] | UserFile[]): boolean {
    if (newFiles.length + this.userFiles.length + this.attachedFiles.length <= FILE_MESSAGE_LIMIT) return false;

    this.notify.warn('Внимание!', `К сообщению можно прикрепить не более 10 файлов.`);
    return true;
  }

  private uploadDraftFiles(files: File[] = []): void {
    const draft: MessageDraftParams = this.getMessageParams();

    if (files.length) draft.files = files;

    const uploadDraft: UploadMediaFilesInterface = {
      draft,
      error: null,
      ready: false,
      fileProgress: 0,
      ngUnsubscribe: new Subject<void>(),
    };
    this.fileManageUploadService.uploadedMedia.push(uploadDraft);
    this.fileManageUploadService.messageDraftProgress(draft);
  }

  removeUserFile(file: UserFile) {
    this.userFiles = this.userFiles.filter((f) => f.id !== file.id);
    this.updateDraft();
  }

  removeAttachedFile(file: UserFile) {
    this.attachedFiles = this.attachedFiles.filter((f) => f.id !== file.id);
    this.updateDraft();
  }

  changeTextareaHeight() {
    this.resetTextareaHeight();
    this.textArea.nativeElement.style.height = this.textArea.nativeElement.scrollHeight + 'px';
  }

  resetTextareaHeight() {
    this.textArea.nativeElement.style.height = 'auto';
  }

  removeQuotedMessage() {
    this.quotedMessage = null;
    this.updateDraft();
  }

  private getFilesIds(files: UserFile[]): number[] {
    return files.map((file) => +file.id);
  }

  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;

    if (!clipboardData.items) return;

    const files = Array.from(clipboardData.items)
      .filter((item) => item.kind === 'file')
      .map((item) => item.getAsFile());

    if (this.isFilesSizeExceeded(files)) return;
    if (this.isFileLimitExceeded(files)) return;
    if (files.length) {
      this.uploadDraftFiles(files);
    }
  }
}
