export const NON_ARCHVE_USERS = {
  name: 'status',
  op: 'ne',
  val: 'in_archive',
};

export const MY_USERS: Params = {
  name: 'tso_id',
  op: 'eq',
};

interface Params {
  name: string;
  op: string;
  val?: number | null;
}
