import { Component, Input, OnInit } from '@angular/core';
import { DestroyService } from '@app/services/destroy.service';
import { User } from '@app/shared/models/user.model';
import { AuthService } from '@app/shared/services/auth.service';
import { takeUntil } from 'rxjs/operators';
import { ChatService } from '../services/chat.service';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { TechTypeEnum } from '../constants/chat-sections.constants';
import { ChangedFlags } from '../models/tso-options.model';
import { PersonalDataService } from '@app/shared/services/personal-data.service';
import { StatusesEnum } from '@app/shared/constants/statuses.constants';

type TechSectionType = 'isMyTechExpanded' | 'isMyUsersExpanded' | 'isAllUsersExpanded';

@Component({
  selector: 'app-tech-chat',
  templateUrl: './tech-chat.component.html',
  styleUrls: ['./tech-chat.component.scss'],
})
export class TechChatComponent implements OnInit {
  @Input() isModalView: boolean = false;
  user: User;
  rolesEnum = RolesEnum;
  myTso: User | null = null;
  techTypeEnum = TechTypeEnum;

  isMyTechExpanded = true;
  isMyUsersExpanded = false;
  isAllUsersExpanded = false;

  isShowedTsoEditor = false;
  isShowDutyTsoEditor = false;
  isShowedTsoOptionsEditor = false;

  isDutyTSO = false;
  isTSO = false;
  isReadyTso = false;

  flags: ChangedFlags;

  totalUnreadyCounts$ = this.chatService.techCounter$;

  get isDisabledChangeTsoButton() {
    return (
      this.user.type !== this.rolesEnum.ADMIN_OF_DIRECTION &&
      this.user.type !== this.rolesEnum.ADMIN_OF_USER &&
      this.user.type !== this.rolesEnum.OPERATOR
    );
  }

  get isStatusActive() {
    return this.user.status === StatusesEnum.ACTIVE;
  }

  constructor(
    private authService: AuthService,
    private destroy: DestroyService,
    private chatService: ChatService,
    private personalDataService: PersonalDataService
  ) {}

  ngOnInit(): void {
    this.authService.userStream.pipe(takeUntil(this.destroy)).subscribe((user) => {
      this.isTSO = this.chatService.users[user.id].flags.is_tso;
      this.isDutyTSO = this.chatService.users[user.id].flags.is_duty_tso;
      this.isReadyTso = this.chatService.users[user.id].flags.is_ready_tso;
      this.user = this.chatService.users[user.id];

      if (this.user.type === this.rolesEnum.PARTNER) {
        this.flags = {
          is_tso: this.isTSO,
          is_ready_tso: this.isReadyTso,
          is_tso_for_tech_chat: this.chatService.users[user.id].flags.is_tso_for_tech_chat,
          is_accepting_as_tso: this.chatService.users[user.id].flags.is_accepting_as_tso,
        };
      }
    });

    this.chatService.usersChanged.pipe(takeUntil(this.destroy)).subscribe((users) => {
      if (!users[this.user.id]) {
        return;
      }
      this.isTSO = users[this.user.id].flags.is_tso;
      //this.isDutyTSO = users[this.user.id].flags.is_duty_tso;
      this.isReadyTso = users[this.user.id].flags.is_ready_tso;
      this.user = users[this.user.id];

      if (this.user.type === this.rolesEnum.PARTNER) {
        this.flags = {
          is_tso: this.isTSO,
          is_ready_tso: this.isReadyTso,
          is_tso_for_tech_chat: users[this.user.id].flags.is_tso_for_tech_chat,
          is_accepting_as_tso: users[this.user.id].flags.is_accepting_as_tso,
        };
      }

      if (!this.isTSO) {
        this.isMyUsersExpanded = false;
      }

      if (!this.isDutyTSO) {
        this.isAllUsersExpanded = false;
      }
    });

    this.personalDataService
      .getSelfTso()
      .pipe(takeUntil(this.destroy))
      .subscribe((tso) => {
        this.myTso = tso;
      });

    this.chatService.removedTsoId$.pipe(takeUntil(this.destroy)).subscribe((removedTsoId) => {
      if (removedTsoId === this.myTso.id) {
        this.myTso = null;
      }
    });

    this.chatService.isDutyTso$.pipe(takeUntil(this.destroy)).subscribe((isDutyTso) => {
      this.isDutyTSO = isDutyTso;
      if (!isDutyTso) {
        this.isAllUsersExpanded = false;
      }
    });
  }

  onToggleExpanded(property: TechSectionType): void {
    this[property] = !this[property];
  }

  openTsoEdit() {
    this.isShowedTsoEditor = true;
  }

  openActivatedTso() {
    this.isShowedTsoOptionsEditor = true;
  }

  closeActivatedTso() {
    this.isShowedTsoOptionsEditor = false;
  }

  openDutyEdit() {
    this.isShowDutyTsoEditor = true;
  }

  closeTsoEdit() {
    this.isShowedTsoEditor = false;
  }

  closeDutyTsoEdit() {
    this.isShowDutyTsoEditor = false;
  }

  changeSelection(tso: User | null) {
    this.myTso = tso;
  }
}
