import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

export interface CustomersFilterParams {
  [key: string]: any;
}

@Component({
  selector: 'app-customers-filter',
  templateUrl: './customers-filter.component.html',
  styleUrls: ['./customers-filter.component.sass']
})
export class CustomersFilterComponent implements OnInit, OnChanges {
  @Input() value: CustomersFilterParams = {
    name: null,
    isMyTrade: false
  };

  @Input() filterSuggestions: string[];

  localValue: CustomersFilterParams = <CustomersFilterParams>{};

  @Output() submitEvent = new EventEmitter<CustomersFilterParams>();
  @Output() searchEvent = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const {value} = changes;

    if (value && value.currentValue) {
      this.localValue = Object.assign({}, this.value);
    }
  }

  apply() {
    this.submitEvent.emit(this.localValue);
  }

  search(value: string) {
    this.localValue.name = value;

    this.searchEvent.emit(value);
  }

  select(value: string) {
    this.localValue.name = value;
  }

}
