<input
  type="text"
  *ngIf="type === criteriaTypes.YES_NO"
  [hidden]="true"
  [ngModel]="value"
  (keyup)="change($event)"
  [name]="name"
  #valueControl="ngModel"
  required
>

<input
  type="text"
  class="kt-input kt-small-input2 criteria-value"
  *ngIf="type === criteriaTypes.NUMBERS"
  criteriaNumber
  [minValue]="min"
  [maxValue]="max"
  [ngModel]="value"
  (keyup)="change($event)"
  [name]="name"
  #valueControl="ngModel"
  required
>
<input
  type="text"
  class="kt-input kt-small-input2 criteria-date-value"
  *ngIf="type === criteriaTypes.DATES"
  [minDateValue]="min"
  [maxDateValue]="max"
  [ngModel]="value"
  (keyup)="change($event)"
  [name]="name"
  #valueControl="ngModel"
  [textMask]="dateMask(value)"
  required
>
