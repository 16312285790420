import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { Lot, TradeProviderLot } from '@app/+trades/models/lots.model';
import { DestroyService } from '@app/services/destroy.service';
import { SupplierTradeCard } from '@app/+trades/models/suppliers.model';
import { SupplierLotGroup } from '@app/+trades/form-types/lots';
import { SuppliersLotsService } from '@app/+trades/services/suppliers-lots.service';

@Component({
  selector: 'app-supplier-lots',
  templateUrl: './supplier-lots.component.html',
  styleUrls: ['./supplier-lots.component.scss'],
  providers: [DestroyService],
})
export class SupplierLotsComponent {
  private _lots: Lot[] = [];
  get lots() {
    return this._lots;
  }
  @Input() set lots(values: Lot[]) {
    if (values) {
      this._lots = values;
      this.initForm();
    }
  }
  @Input() card: SupplierTradeCard;

  @Input() isTrades = false;
  @Input() readonly = false;

  @Output() changed = new EventEmitter<{
    data: TradeProviderLot[];
    valid: boolean;
  }>();

  @Output() cleared = new EventEmitter<void>();

  form: FormArray<FormGroup<SupplierLotGroup>>;
  resetedLotIndex: number | null = null;

  constructor(private suppliersLotsService: SuppliersLotsService, private destroy$: DestroyService) {}

  resetLotValues() {
    this.form.at(this.resetedLotIndex).reset();
    this.resetedLotIndex = null;

    this.emitChanges();
    this.cleared.emit();
  }

  getLotRating(lotId: number) {
    return this.card.rating?.lots?.find((lotRating) => lotRating.id_lot === lotId);
  }

  private initForm() {
    this.form = this.suppliersLotsService.getLotsForm(this._lots);

    this.form.valueChanges
      .pipe(
        debounceTime(500),
        filter(() => !this.readonly && !this.form.pristine),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.emitChanges());
  }

  private emitChanges() {
    this.changed.emit({
      valid: this.form.valid,
      data: this.form.getRawValue() as TradeProviderLot[],
    });
  }
}
