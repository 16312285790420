import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { UserShortInfoNoCompetenceSections } from '@app/shared/models/user-short-info.model';
import { ChatService } from '@app/chat/services/chat.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from '@app/shared/services/auth.service';

@Component({
  selector: 'app-company-tooltip',
  templateUrl: './company-tooltip.component.html',
  styleUrls: ['./company-tooltip.component.scss'],
})
export class CompanyTooltipComponent {
  @Input() userInfo: UserShortInfoNoCompetenceSections;
  @Input() overlayRef: OverlayRef;

  get isShowChatIcon(): boolean {
    return this.userInfo.id !== +this.authService.user_id;
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private chatService: ChatService,
    private notify: NotificationsService
  ) {}

  navigateToChat(): void {
    const roomId = this.chatService.getRoomIdByUserId(this.userInfo.id);

    if (roomId) {
      this.chatService.openChat(roomId);
      this.overlayRef.detach();
    } else {
      this.notify.warn('Чат', 'Не удалось определить раздел чата, пожалуйста обратитесь к администратору');
    }
  }
}
