<div class="d-flex justify-content-between" [class.contact-info__selected]="isActiveClass" *ngIf="contact">
  <div class="room-select-checkbox">
    <ng-container *ngIf="isModalView">
      <input
        class="cbx-input"
        type="checkbox"
        id="contact_select_{{ contact.room_id }}"
        (change)="toggleRoomSelected(contact)"
        [checked]="isRoomSelected(contact)"
      />
      <label class="cbx-label" [class.cbx-label-interminate]="false" for="contact_select_{{ contact.room_id }}"></label>
    </ng-container>
  </div>

  <div
    [style.padding-left]="
      userType === rolesEnum.SUPERUSER || (userType !== rolesEnum.SUPERUSER && !isTree)
        ? treeLevel * 10 + 12 + 'px'
        : treeLevel * 10 + 'px'
    "
    [class.disabled]="!contact?.room_id"
    (click)="onContactSelect(contact)"
    class="contact"
    [class._filter-disabled]="contact.filterDisabled"
  >
    <button
      *ngIf="userType !== rolesEnum.SUPERUSER && isTree"
      [style.visibility]="
        isTree && (contact.type === rolesEnum.ADMIN_OF_DIRECTION || contact.type === rolesEnum.ADMIN_OF_USER)
          ? 'visible'
          : 'hidden'
      "
      class="icon-button chat-expansion__caret"
      [disabled]="!contact?.children?.length"
      (click)="$event.stopPropagation(); onContactsExpand()"
    >
      <app-svg-icon [name]="isExpanded ? 'caret_up' : 'caret_down'" width="12" height="12"></app-svg-icon>
    </button>
    <div class="d-flex align-items-center" *ngIf="techType !== techTypeEnum.MY_TECH">
      <div
        class="contact-item"
        [disableTooltip]="nameElement?.offsetWidth >= nameElement?.scrollWidth || isTree"
        ngbTooltip="
          {{ chatSectionName && chatSectionName === ChatSectionsEnum.TECH ? 'ID' + ' ' + contact.id + ' ' : '' }}
          {{ contact | initials }}, {{ contact.type | titleRole }}"
      >
        <span
          class="contact__name"
          [className]="!isTree ? 'short-name' : 'long-name'"
          #nameElement
          [ngClass]="{ tech: chatSectionName === ChatSectionsEnum.TECH }"
          [innerHTML]="
            (chatSectionName && chatSectionName === ChatSectionsEnum.TECH ? 'ID' + ' ' + contact.id + ' ' : '') +
            '' +
            (contact | initials | highlight : filterString) +
            ', ' +
            (contact.type | titleRole : { isShort: true } | toLowerCaseUserRole)
          "
        >
        </span>
      </div>
    </div>
    <div class="d-flex align-items-center" *ngIf="techType === techTypeEnum.MY_TECH">ОТП</div>
  </div>

  <div
    *ngIf="!isModalView"
    [style.visibility]="techType !== techTypeEnum.MY_TECH ? 'visible' : 'hidden'"
    class="contact-info"
    [class.contact-info__selected]="isActiveClass"
  >
    <button class="icon-button" (click)="showCard(contact)">
      <app-svg-icon [width]="18" [height]="14" name="id-card"></app-svg-icon>
    </button>

    <button
      class="icon-button"
      (click)="showCompetence(contact)"
      [disabled]="contact.type === rolesEnum.SUPERUSER || contact.type === rolesEnum.ACCOUNTANT"
    >
      <app-svg-icon [width]="18" [height]="14" name="competence-card"></app-svg-icon>
    </button>

    <span
      [style.visibility]="contact.counter ? 'visible' : 'hidden'"
      class="chat-number"
      [class.chat-number__10]="contact.counter > 10"
    >
      {{ contact.counter <= 10 ? contact.counter : '10+' }}
    </span>
  </div>
</div>

<ng-container *ngIf="isExpanded">
  <ng-container
    class="contact-children"
    *ngFor="let item of contact?.children | sortContacts : sortType; trackBy: trackByRoomId"
  >
    <app-chat-contact
      *ngIf="item.status !== 'in_archive' && item.id"
      [sortType]="sortType"
      [isTree]="true"
      [contact]="item"
      [treeLevel]="treeLevel + 1"
      [isModalView]="isModalView"
    ></app-chat-contact>
  </ng-container>
</ng-container>
