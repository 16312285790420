import { MessageActionType } from '@app/chat/constants/chat-sections.constants';
import { MessagesSelectService } from '@app/chat/services/messages-select.service';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { catchError, debounceTime, filter, switchMap, takeUntil, throttleTime } from 'rxjs/operators';

import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { AuthService } from '@app/shared/services/auth.service';
import { ChatService } from '@app/chat/services/chat.service';
import { SocketDataService } from '@app/services/socket-data.service';
import type { ChatMessage, ChatRoom } from '@app/chat/models/chat.model';

import { DestroyService } from '@app/services/destroy.service';
import { combineLatest, fromEvent, merge, Subject, throwError } from 'rxjs';
import { map, tap } from 'rxjs/internal/operators';
import { DEFAULT_MESSAGE_LOAD_COUNT } from '@app/shared/constants/chat.constants';
import type { LoadedMessagesOffset } from '@app/chat/models';
import { DirectionLoadMessages } from '@app/chat/constants';
import { StatusesEnum } from '@app/shared/constants/statuses.constants';
import { convertMessagesAttachToUserFile } from '@app/chat/helpers/convert-messages-attach-to-user-file';

enum BlockView {
  start = 'start',
  end = 'end',
}

enum Behavior {
  instant = 'instant',
  smooth = 'smooth',
}

interface RecoverMessagePosition {
  id: number;
  block?: BlockView;
  behavior?: Behavior;
  scrollRatio?: number;
}

@Component({
  selector: 'app-chat-messages',
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss'],
  providers: [DestroyService],
})
export class ChatMessagesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('scrollMe') private scrollMe: ElementRef;
  @ViewChildren('messageElement') messageElements!: QueryList<ElementRef>;
  private intersectionObserver: IntersectionObserver;

  statusEnum = StatusesEnum;
  rolesEnum = RolesEnum;

  private _messages: ReadonlyArray<ChatMessage> = [];
  private room_id: string;
  private contact_id: number;
  roomSelected: ChatRoom;
  userId = Number(this.authService.user_id);
  protected isMessagesLoading: boolean = false;
  scrollHeight = 0;
  isArrowShown: boolean = false;
  private activeContextMenu: NgbPopover | null = null;

  private recoverMessagePosition: RecoverMessagePosition | null = null;
  protected messagesOffset: LoadedMessagesOffset | null = null;

  protected chatLoading = this.chatService.chatLoading.getValue();
  isTradeGroup = this.chatService.isTradeGroup;
  protected timezoneOffset = this.authService.getTimezoneOffset();

  private readonly expandedMessages = new Set<number>();

  private unreadMessageIds: number[] = [];
  private messageReadSubject = new Subject();
  private loadMessagesSubject = new Subject<DirectionLoadMessages>();

  messageSelectedList: ChatMessage[] = ([] = []);
  protected tradeGroup: ChatRoom;

  get messages(): ReadonlyArray<ChatMessage> {
    return this._messages;
  }

  get isCurrentTechTypeAllUsers() {
    return this.chatService.isDutyTsoAction();
  }

  get isTsoChat() {
    return this.chatService.isTsoAction();
  }

  get isTechTso() {
    return this.chatService.isTsoAction() || this.chatService.isDutyTsoAction();
  }

  isCurrentUserDutyTso = false;

  private retryCount = 0;
  private readonly MAX_RETRIES = 3;

  constructor(
    private chatService: ChatService,
    private chatDataService: SocketDataService,
    private authService: AuthService,
    private readonly destroy$: DestroyService,
    private messagesSelectService: MessagesSelectService
  ) {
    this.loadMessagesSubject
      .pipe(
        debounceTime(300),
        filter((direction: DirectionLoadMessages | null) => !!direction),
        switchMap((direction) =>
          this.chatDataService
            .loadMessages({
              room_id: this.room_id,
              offset: this.calculateHttpOffsets(direction),
              count: !this.messages.length ? 2 * DEFAULT_MESSAGE_LOAD_COUNT : DEFAULT_MESSAGE_LOAD_COUNT,
              // Если будут баги - возможно сделать задержку перед получением данной переменной
              is_duty_tso: this.chatService.isDutyTsoAction(),
              is_tso: this.chatService.isTsoAction(),
            })
            .pipe(
              map((res) => ({ ...res, roomId: this.room_id })),
              catchError((error) => {
                if (error.status === 403) {
                  if (this.retryCount >= this.MAX_RETRIES) {
                    this.retryCount = 0;
                  }
                  this.retryCount++;

                  return this.chatDataService.createRoomId(this.contact_id).pipe(
                    switchMap(() =>
                      this.chatDataService
                        .loadMessages({
                          room_id: this.room_id,
                          offset: this.calculateHttpOffsets(direction),
                          count: !this.messages.length ? 2 * DEFAULT_MESSAGE_LOAD_COUNT : DEFAULT_MESSAGE_LOAD_COUNT,
                          // Если будут баги - возможно сделать задержку перед получением данной переменной
                          is_duty_tso: this.chatService.isDutyTsoAction(),
                          is_tso: this.chatService.isTsoAction(),
                        })
                        .pipe(
                          tap(() => (this.retryCount = 0)),
                          map((res) => ({ ...res, roomId: this.room_id }))
                        )
                    ),
                    catchError((err) => {
                      this.retryCount = 0;
                      return throwError(err);
                    })
                  );
                }
                return throwError(error);
              })
            )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        const { items, roomId, bottom_count, top_count } = data;
        const messages = convertMessagesAttachToUserFile(items);
        const messagesOffset = this.messages.length
          ? {
              top: top_count < this.messagesOffset.top ? top_count : this.messagesOffset.top,
              bottom: bottom_count < this.messagesOffset.bottom ? bottom_count : this.messagesOffset.bottom,
            }
          : {
              top: top_count,
              bottom: bottom_count,
            };
        this.chatService.setMessages(messages, roomId || '', messagesOffset);
      });

    this.messageReadSubject
      .pipe(
        debounceTime(100),
        tap(() => {
          const idsToMarkRead = new Set(this.unreadMessageIds);
          const maxId = Math.max(...idsToMarkRead);

          if (maxId > this.roomSelected.last_read_message_id) {
            this.chatDataService.setMessageRead(
              this.roomSelected.room_id,
              [...idsToMarkRead],
              this.chatService.isDutyTsoAction(),
              this.chatService.isTsoAction()
            );
          }

          this.unreadMessageIds = [];
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnInit() {
    this.chatService.isDutyTso$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isDutyTso) => (this.isCurrentUserDutyTso = isDutyTso));
    combineLatest([this.chatService.contactSelectedChanged, this.chatService.chatLoaded])
      .pipe(
        // todo: нужно разобраться с множественной отработкой 'contactSelectedChanged'
        filter(
          ([selectedRoom, chatLoaded]) =>
            (selectedRoom?.room_id !== this.roomSelected?.room_id ||
              selectedRoom?.tech_section_type !== this.roomSelected?.tech_section_type) &&
            chatLoaded
        ),
        tap(([chatRoom]) => {
          const roomId = chatRoom.room_id;
          this.room_id = roomId;
          this.contact_id = chatRoom.id;
          this.roomSelected = this.chatService.findChatRoomById(roomId);
          this.isMessagesLoading = true;
          this._messages = [];
          this.unreadMessageIds = [];
          this.messagesOffset = null;
        }),
        map(([chatRoom]) => {
          const roomId = chatRoom.room_id;
          const currentMessages = this.chatService.getMessagesRoomStore(roomId);

          if (currentMessages?.list.length) {
            if (this.roomSelected?.last_read_message_id > 0) {
              const lastReadIndex = this.messages.findIndex(
                (message) => message.id === this.roomSelected.last_read_message_id
              );

              if (lastReadIndex === -1) {
                this.loadMessagesAroundId({
                  id: this.roomSelected.last_read_message_id,
                  behavior: Behavior.instant,
                  block: BlockView.end,
                });
                return null;
              }
            }
            this.messagesOffset = currentMessages.offsets;
            this.updateMessages(currentMessages?.list || []);
            this.updateScroll();

            return null;
          } else {
            return roomId;
          }
        }),
        filter((roomId: string | null) => !!roomId),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.loadMessagesSubject.next(DirectionLoadMessages.NEXT));

    merge(this.chatService.contactsChanged, this.chatService.themesChanged, this.chatService.groupsChanged)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.roomSelected = this.chatService.findChatRoomById(this.room_id);
      });

    this.chatService.messagesChanged.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.roomSelected = this.chatService.findChatRoomById(this.room_id);
      this.tradeGroup = this.chatService.getGroups()[`dg-${this.roomSelected?.group_id}`];
      if (!this.roomSelected) return;

      const messages = this.chatService.getMessagesRoomStore(this.room_id);
      const isISentLastMessage = messages.list.at(-1)?.author_id === this.userId;

      this.messagesOffset = messages.offsets;

      this.updateMessages(messages.list);
      this.updateScroll(isISentLastMessage);
    });

    this.messagesSelectService.messageSelectedChanged.pipe(takeUntil(this.destroy$)).subscribe((messageSelected) => {
      this.messageSelectedList = messageSelected;
    });
  }

  ngAfterViewInit(): void {
    const scrollContainer = this.scrollMe.nativeElement;
    let lastScrollTop = scrollContainer.scrollTop;

    this.initializeIntersectionObserver();

    fromEvent(scrollContainer, 'scroll')
      .pipe(
        debounceTime(100),
        map(() => this.getScrollDirection(scrollContainer, lastScrollTop)),
        tap(() => {
          lastScrollTop = scrollContainer.scrollTop;
        }),
        filter((direction) => !!direction),
        takeUntil(this.destroy$)
      )
      .subscribe((direction) => this.loadMessagesSubject.next(direction));

    fromEvent(scrollContainer, 'scroll')
      .pipe(
        throttleTime(100),
        filter(() => !!this.activeContextMenu),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.closeContextMenu());
  }

  ngOnDestroy() {
    this.intersectionObserver.disconnect();
    this.messagesSelectService.clearMessageSelected();
  }

  private updateScroll(isMyMessageLast: boolean = false) {
    if (this.isMessagesLoading) {
      this.isMessagesLoading = false;

      if (!this.roomSelected) return;
      if (this.roomSelected.last_read_message_id == null) requestAnimationFrame(() => this.scrollToBottom());
      if (this.roomSelected.last_read_message_id === 0) requestAnimationFrame(() => this.scrollToTop());
      if (this.roomSelected.last_read_message_id > 0) {
        const firstUnreadIndex = this.messages.findIndex(
          (message) => message.created_at === this.roomSelected?.first_not_read_message_creation_date_in_chat
        );
        const firstUnreadMessage = this.messages[firstUnreadIndex];

        this.recoverMessagePosition = {
          id: firstUnreadMessage ? firstUnreadMessage.id : this.messages.at(-1).id,
          block: firstUnreadMessage ? BlockView.start : BlockView.end,
          behavior: Behavior.instant,
        };

        requestAnimationFrame(() => this.scrollToMessageById(this.recoverMessagePosition));
      }
    } else {
      if ((this.isScrolledToBottom() || isMyMessageLast) && !this.recoverMessagePosition) {
        requestAnimationFrame(() => this.scrollToBottom());
      } else {
        requestAnimationFrame(() => this.scrollToMessageById(this.recoverMessagePosition));
      }
    }
  }

  private scrollToTop() {
    this.scrollTo({ target: 0, behavior: Behavior.instant });
  }

  private scrollToBottom(behavior: Behavior = Behavior.instant) {
    const container = this.scrollMe.nativeElement;
    this.scrollTo({ target: container.scrollHeight, behavior });
  }

  private scrollTo({ target, behavior }: { target: number; behavior: Behavior }) {
    this.scrollMe.nativeElement.scrollTo({ top: this.correctScrollTarget(target), behavior });
  }

  private smoothScrollToTarget(recoveredScroll: number, scrollTarget: number) {
    this.scrollTo({ target: recoveredScroll, behavior: Behavior.instant });
    this.scrollTo({ target: this.correctScrollTarget(scrollTarget), behavior: Behavior.smooth });
  }

  protected scrollToMessageById({
    id,
    block = BlockView.start,
    behavior = Behavior.instant,
    scrollRatio,
  }: RecoverMessagePosition): void {
    const messageIndex = this.messages.findIndex((message) => message.id === id);

    if (messageIndex === -1) {
      this.loadMessagesAroundId({ id, block: BlockView.start, behavior: Behavior.smooth });
      return;
    }

    const container = this.scrollMe.nativeElement;
    const targetMessage = this.messages[messageIndex];

    if (!targetMessage) return;
    const targetElement = container.querySelector(`[data-id="${targetMessage.id}"]`);

    if (!targetElement) return;
    const elementRect = targetElement.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    if (block === BlockView.end && behavior === Behavior.instant) {
      const scrollTarget = elementRect.bottom - containerRect.bottom + container.scrollTop;
      this.scrollTo({ target: scrollTarget, behavior: Behavior.instant });
    }

    if (block === BlockView.end && behavior === Behavior.smooth) {
      const ratio = scrollRatio || this.recoverMessagePosition?.scrollRatio;
      const recoveredScroll = (container.scrollHeight - container.clientHeight) * ratio;
      const scrollTarget = elementRect.bottom - containerRect.bottom + container.scrollTop;
      this.smoothScrollToTarget(recoveredScroll, scrollTarget);
    }

    if (block === BlockView.start && behavior === Behavior.instant) {
      const scrollTarget = elementRect.top - containerRect.top + container.scrollTop;
      this.scrollTo({ target: scrollTarget, behavior: Behavior.instant });
    }

    if (block === BlockView.start && behavior === Behavior.smooth) {
      const ratio = scrollRatio || this.recoverMessagePosition?.scrollRatio;
      const recoveredScroll = (container.scrollHeight - container.clientHeight) * ratio;
      const scrollTarget = elementRect.top - containerRect.top + container.scrollTop;
      this.smoothScrollToTarget(recoveredScroll, scrollTarget);
    }

    this.recoverMessagePosition = null;
  }

  private correctScrollTarget(scrollTop: number): number {
    return Number.isInteger(scrollTop) ? scrollTop : Math.floor(scrollTop) + 1;
  }

  private isScrolledToBottom(): boolean {
    const { scrollTop, scrollHeight, clientHeight } = this.scrollMe.nativeElement;
    return scrollTop + clientHeight >= scrollHeight - 10; // 10px погрешность от низа контейнера, с которой можно считать что скролл внизу.
  }

  isMyMessage(message: ChatMessage) {
    return (
      (message.author_id === this.userId && !this.isCurrentTechTypeAllUsers && !this.isTechTso) ||
      (message.author.type === this.rolesEnum.DUTY_TSO &&
        this.isCurrentUserDutyTso &&
        this.isCurrentTechTypeAllUsers) ||
      (message.author.type === this.rolesEnum.TSO &&
        message.extra &&
        message.extra.id === this.userId &&
        !this.isCurrentTechTypeAllUsers &&
        this.isTechTso)
    );
  }

  updateMessages(messages: ChatMessage[]) {
    this._messages = messages;
  }

  onRemoveMessage(message: ChatMessage) {
    this.toggleMessageSelected(message);
    this.messagesSelectService.setMessageActionType(MessageActionType.DELETE);
  }

  onReplyMessage(message: ChatMessage) {
    this.chatService.setQuotedMessage(message);
  }

  onForwardMessage(message: ChatMessage) {
    this.toggleMessageSelected(message);
    this.messagesSelectService.setMessageActionType(MessageActionType.FORWARD);
  }

  protected isOverflowing(messageContent: HTMLElement) {
    return messageContent.scrollHeight > messageContent.clientHeight;
  }

  protected toggleExpand(message: ChatMessage) {
    if (this.expandedMessages.has(message.id)) {
      this.expandedMessages.delete(message.id);
    } else {
      this.expandedMessages.add(message.id);
    }
  }

  protected isExpanded(message: ChatMessage) {
    return this.expandedMessages.has(message.id);
  }

  protected trackById(_: number, message: ChatMessage) {
    return message.id;
  }

  private initializeIntersectionObserver() {
    this.intersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const messageId = Number(entry.target.getAttribute('data-id'));
          // console.log(`Message ${messageId} is visible`);
          if (entry.isIntersecting) {
            const lastReadMessageId = this.roomSelected.last_read_message_id;

            if (lastReadMessageId == null || lastReadMessageId > messageId) return;
            this.markMessageAsRead(messageId);
          } else this.setVisibilityArrow(messageId);
        });
      },
      {
        root: this.scrollMe.nativeElement,
        threshold: 0.9,
      }
      /* threshold - % размеров объекта, который попал в область видимости контейнера (от 0 до 1)
       * в нашем случае должно быть 1 - сообщение видимое полностью считается прочитанным
       * 0.9 - с учётом погрешности, из-за округления браузером дробных размеров.
       * */
    );

    this.messageElements.forEach((element) => {
      this.intersectionObserver.observe(element.nativeElement);
    });

    this.messageElements.changes.pipe(takeUntil(this.destroy$)).subscribe((elements: QueryList<ElementRef>) => {
      this.intersectionObserver.disconnect();

      elements.forEach((element) => {
        this.intersectionObserver.observe(element.nativeElement);
      });
    });
  }

  private markMessageAsRead(messageId: number) {
    this.unreadMessageIds.push(messageId);

    if (this.unreadMessageIds.length !== 1) return;
    this.messageReadSubject.next();
  }

  private setVisibilityArrow(id: number) {
    const container = this.scrollMe.nativeElement;
    const messageIndex = this.messages.findIndex((message) => message.id === id);
    const isMessageFarFromEnd: boolean = messageIndex !== -1 && messageIndex <= this.messages.length - 15;
    const isScrolledFarFromEnd: boolean = container.scrollHeight - container.clientHeight * 3 > container.scrollTop;

    this.isArrowShown = isMessageFarFromEnd || isScrolledFarFromEnd;
  }

  private calculateHttpOffsets(direction: DirectionLoadMessages) {
    const currentMessages = this.chatService.getMessagesRoomStore(this.room_id);

    if (currentMessages?.offsets) {
      const offsets = Object.assign({}, currentMessages.offsets);
      if (direction === DirectionLoadMessages.PREVIOUS) {
        return offsets.bottom + currentMessages.list.length;
      } else {
        const bottomOffset = offsets.bottom - DEFAULT_MESSAGE_LOAD_COUNT;
        return bottomOffset && bottomOffset >= 0 ? bottomOffset : 0;
      } // offsets не могут быть меньше 0 - это приведет к ошибкам.
    }

    if (this.messagesOffset == null) {
      const offset =
        this.roomSelected?.counter && this.roomSelected?.counter > DEFAULT_MESSAGE_LOAD_COUNT
          ? this.roomSelected?.counter - DEFAULT_MESSAGE_LOAD_COUNT
          : 0;
      return offset;
    }
  }

  private getScrollDirection(scrollContainer: HTMLElement, lastScrollTop: number): DirectionLoadMessages | null {
    if (!this.messagesOffset) return null;
    const currentScrollTop = scrollContainer.scrollTop;

    // Определяем направление скроллинга
    const isScrollingDown = currentScrollTop > lastScrollTop;
    const isScrollingUp = currentScrollTop < lastScrollTop;

    // Проверяем, упёрлись ли в верх с погрешностью 10px (направление снизу вверх)
    if (isScrollingUp && currentScrollTop <= 10 && this.messagesOffset.top > 0) {
      this.recoverMessagePosition = {
        id: this.messages.at(0).id,
        block: BlockView.start,
        behavior: Behavior.instant,
      };
      return DirectionLoadMessages.PREVIOUS;
    }

    // Проверяем, упёрлись ли в низ с погрешностью 10px (направление сверху вниз)
    const isBottom = currentScrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight - 10;
    if (isScrollingDown && isBottom && this.messagesOffset.bottom > 0) {
      this.recoverMessagePosition = {
        id: this.messages.at(-1).id,
        block: BlockView.end,
        behavior: Behavior.instant,
      };
      return DirectionLoadMessages.NEXT;
    }

    return null;
  }

  toggleMessageSelected(chatMessage: ChatMessage) {
    this.messagesSelectService.toggleMessageSelected(chatMessage);
  }

  isMessageSelected(chatMessage: ChatMessage) {
    return this.messagesSelectService.isMessageSelected(chatMessage);
  }

  private loadMessagesAroundId({ id, block = BlockView.start, behavior = Behavior.smooth }: RecoverMessagePosition) {
    this.chatDataService
      .loadMessage(this.room_id, id, this.chatService.isTsoAction(), this.chatService.isDutyTsoAction())
      .pipe(
        switchMap((data) => {
          const offset = data.bottom_count - DEFAULT_MESSAGE_LOAD_COUNT;
          const count = 2 * DEFAULT_MESSAGE_LOAD_COUNT;

          return this.chatDataService.loadMessages({
            room_id: this.room_id,
            offset: offset >= 0 ? offset : 0,
            count: count,
            // Если будут баги - возможно сделать задержку перед получением данной переменной
            is_duty_tso: this.chatService.isDutyTsoAction(),
            is_tso: this.chatService.isTsoAction(),
          });
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        const { items, bottom_count, top_count } = data;
        const messages = convertMessagesAttachToUserFile(items);
        this.chatService.clearMessagesByRoomId(this.room_id);
        this._messages = [];
        const messagesOffset = { top: top_count, bottom: bottom_count };
        const container = this.scrollMe.nativeElement;
        const scrollRatio = container.scrollTop / (container.scrollHeight - container.clientHeight);
        this.recoverMessagePosition = { id, block, behavior, scrollRatio };

        this.chatService.setMessages(messages, this.room_id || '', messagesOffset);
      });
  }

  protected moveToLastMessage() {
    if (!this.messagesOffset?.bottom) {
      this.scrollToBottom(Behavior.smooth);
    } else {
      this.chatDataService
        .loadMessages({
          room_id: this.room_id,
          offset: 0,
          count: 2 * DEFAULT_MESSAGE_LOAD_COUNT,
          // Если будут баги - возможно сделать задержку перед получением данной переменной
          is_duty_tso: this.chatService.isDutyTsoAction(),
          is_tso: this.chatService.isTsoAction(),
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          const { items, bottom_count, top_count } = data;
          const messages = convertMessagesAttachToUserFile(items);
          this.chatService.clearMessagesByRoomId(this.room_id);
          this._messages = [];
          const messagesOffset = { top: top_count, bottom: bottom_count };

          const container = this.scrollMe.nativeElement;
          const scrollRatio = container.scrollTop / (container.scrollHeight - container.clientHeight);
          this.recoverMessagePosition = {
            id: messages.at(0).id,
            block: BlockView.end,
            behavior: Behavior.smooth,
            scrollRatio,
          };

          this.chatService.setMessages(messages, this.room_id || '', messagesOffset);
        });
    }
  }

  protected scrollToQuote(id: number) {
    const container = this.scrollMe.nativeElement;
    const scrollRatio = container.scrollTop / (container.scrollHeight - container.clientHeight);

    this.recoverMessagePosition = { id, behavior: Behavior.smooth, block: BlockView.start, scrollRatio };
    requestAnimationFrame(() => this.scrollToMessageById(this.recoverMessagePosition));
  }

  protected openContextMenu(event: MouseEvent, popover: NgbPopover) {
    event.preventDefault();
    if (this.messageSelectedList.length) return;

    if (this.activeContextMenu && this.activeContextMenu !== popover) {
      this.closeContextMenu();
    }

    popover.open();
    this.activeContextMenu = popover;
  }

  private closeContextMenu() {
    this.activeContextMenu.close();
    this.activeContextMenu = null;
  }
}
