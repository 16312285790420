<ng-container *ngIf="customers.length === 0">'-'</ng-container>
<ng-container *ngIf="customers.length > 0">
  <ng-container
    *ngIf="customers[0]"
    [ngTemplateOutlet]="customerTpl"
    [ngTemplateOutletContext]="{ $implicit: customers[0] }"
  ></ng-container>
  <ng-container
    *ngIf="customers[1]"
    [ngTemplateOutlet]="customerTpl"
    [ngTemplateOutletContext]="{ $implicit: customers[1] }"
  ></ng-container>

  <ng-container *ngIf="customers.length > 2">
    <a class="trade-cell-arrow" (click)="showMoreDetails($event)">
      <app-svg-icon width="20" height="10" name="down"></app-svg-icon>
    </a>
  </ng-container>
</ng-container>

<ng-template #customerTpl let-data let-className="className">
  <div class="customer" [ngClass]="className">
    <div class="customer-line">
      <div class="customer-info">
        <app-svg-icon
          class="blue-links status"
          [name]="getAccessIconByKey(data.access_rights)"
          width="16"
          height="16"
        ></app-svg-icon>
        <app-line-clamp [content]="data.user | initials"></app-line-clamp>
      </div>
      <div class="customer-buttons">
        <app-svg-icon
          class="blue-links"
          name="id-card"
          width="18"
          height="14"
          (click)="showUserContacts($event, data.user)"
        ></app-svg-icon>
        <app-svg-icon
          class="blue-links"
          name="competence-card"
          width="18"
          height="14"
          (click)="showUserCompetencies($event, data.user)"
        ></app-svg-icon>
      </div>
    </div>

    <span *ngIf="data.user.company as company" class="customer-company-info">
      <div class="user-info-status">
        <div [ngClass]="data.user.status"></div>
      </div>

      <app-line-clamp [content]="company | companyName"></app-line-clamp>
    </span>
  </div>
</ng-template>

<app-modal-window [isShowed]="isShowMoreDetails" (closeEvent)="isShowMoreDetails = false">
  <div class="modal-header"><h6>Доступ к КТ</h6></div>

  <ng-container
    *ngFor="let item of customers"
    [ngTemplateOutlet]="customerTpl"
    [ngTemplateOutletContext]="{ $implicit: item, className: 'customer-modal' }"
  ></ng-container>
</app-modal-window>
