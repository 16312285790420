<div class="calendar" [ngClass]="forPlanner ? 'for_planner' : ''">
  <table>
    <colgroup>
      <col width="22px" />
      <col width="34px" />
      <col width="34px" />
      <col width="34px" />
      <col width="34px" />
      <col width="34px" />
      <col width="34px" />
      <col width="34px" />
    </colgroup>
    <tbody>
      <tr
        #calendarMain
        class="calendar-heading"
        [ngClass]="fillTitle ? 'fill_title' : ''"
        (click)="forPlanner && openMonthPlanner()"
      >
        <td colspan="2" class="calendar-heading-left">
          <app-svg-icon
            [height]="12"
            [width]="8"
            [class.disabled]="isMinRangeInvalid"
            name="left"
            (click)="changeMonth(-1)"
            *ngIf="!forPlanner"
          ></app-svg-icon>
        </td>
        <td class="calendar-heading-date" colspan="4">
          <span (click)="!forPlanner && showSelect(typeSelect.MONTH, $event)">{{ currentMonth }}</span
          ><ng-container *ngIf="!forPlanner">
            &nbsp;<span (click)="showSelect(typeSelect.YEAR, $event)">{{ currentYear }}</span>
          </ng-container>
        </td>
        <td class="calendar-heading-right" colspan="2">
          <app-svg-icon
            [height]="12"
            [width]="8"
            [class.disabled]="isMaxRangeInvalid"
            name="right"
            (click)="changeMonth(1)"
            *ngIf="!forPlanner"
          ></app-svg-icon>
        </td>
      </tr>
      <tr class="calendar-days-name">
        <td *ngFor="let weekDay of WEEK">
          <div [ngClass]="{ 'day-of-week-current': nowDayWeekNumber === weekDay.number && currentDayOnCalendar }">
            {{ weekDay.day }}
          </div>
        </td>
      </tr>
      <ng-container *ngFor="let week of calendarData">
        <tr class="calendar-days">
          <td class="week-number" [ngClass]="{ 'week-current': nowWeek == week.number && currentDayOnCalendar }">
            {{ week.number }}
          </td>
          <td
            style="position: relative"
            *ngFor="let day of week.days; let i = index"
            [ngClass]="{
              'week-current': nowWeek == week.number && currentDayOnCalendar,
              'day-of-week-current': i === nowDayWeekNumber - 1 && currentDayOnCalendar
            }"
          >
            <div
              [class]="day.class"
              [class.selected-day-wrapper]="isSelectedDay(day)"
              [ngClass]="{
                disabled: day.minDateDisabled || day.maxDateDisabled,
                'day-off': i > 4,
                'day-event-end': eventForDay[day.day] == 1,
                'day-event': eventForDay[day.day] == 2,
                'pale-day-duty': getIsOpacity(day)
              }"
            >
              <div
                class="day-wrapper"
                [class]="day.currentMonth ? getColor(day.status) : ''"
                [class.selected-day]="isSelectedDay(day)"
                (click)="selectDay(day)"
                [ngClass]="{
                  'current-month-day':
                    (nowWeek == week.number && currentDayOnCalendar) ||
                    (i === nowDayWeekNumber - 1 && currentDayOnCalendar)
                }"
              >
                {{ day.dayNumber }}
              </div>
              <span *ngIf="day.isToday" class="current-day-mark"></span>
              <span
                *ngIf="isAgent && !day.isToday && day.isMyProcess && +day.date > +date"
                class="process-day-mark"
              ></span>
              <span *ngIf="isSelectedDay(day)"></span>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <div class="duty-timezone">Часовой пояс: {{ timeZoneName }}</div>
</div>
