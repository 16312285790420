<div class="kt-flow">
  <div class="kt-flow-item">
    <div class="kt-flow-item__title">Все сотрудники</div>
    <app-file-manager-search
      placeholder="Начните вводить ФИО сотрудника"
      (onSearch)="filterEmployeesByInput($event)"
    ></app-file-manager-search>
  </div>
  <div class="kt-flow-item"></div>
  <div class="kt-flow-item">
    <div class="kt-flow-item__title">
      Сотрудники с доступом к данной КТ
      <a (click)="navigateToChat()">
        <app-svg-icon name="comment" width="16" height="14"></app-svg-icon>
      </a>
    </div>
    <app-file-manager-search
      placeholder="Начните вводить ФИО сотрудника"
      (onSearch)="customerInputValue = $event"
    ></app-file-manager-search>
  </div>

  <div class="kt-flow-item">
    <input
      class="cbx-input"
      id="allEmployees"
      type="checkbox"
      [disabled]="!haveSelectableEmployees"
      [(ngModel)]="allEmployeesChechbox"
      (ngModelChange)="selectAllEmployees($event)"
    />
    <label class="cbx-label" for="allEmployees" [class.cbx-label-interminate]="employeesPartiallySelected">
      <span> Выделить всё/снять выделение </span>
    </label>
  </div>
  <div class="kt-flow-item"></div>
  <div class="kt-flow-item">
    <input
      class="cbx-input"
      id="allCustomers"
      type="checkbox"
      [disabled]="customers.length <= 1"
      [(ngModel)]="allCustomersChechbox"
      (ngModelChange)="selectAllCustomers($event)"
    />
    <label class="cbx-label" for="allCustomers" [class.cbx-label-interminate]="allCustomersPartiallySelected">
      <span> Выделить всё/снять выделение </span>
    </label>
  </div>

  <div class="kt-flow-item kt-dual-list-without-level-padding overflow">
    <tree-root #tree [nodes]="localEmployees" [options]="customTemplateStringOptions">
      <ng-template #treeNodeTemplate let-node>
        <app-trade-user-item
          *ngIf="node?.data?.status !== statuses.IN_ARCHIVE"
          [item]="node.data"
          [node]="node"
          [isAccessChangeOptions]="!readonly && isAccessChangeOptions"
          [isAccessChangeOptionsShowed]="false"
          [userId]="userId"
          [roomId]="node.data.room_id"
          [searchString]="employeesInputValue"
          (onUserDbClick)="move($event)"
          (onToggle)="expandItem(tree, node, $event)"
          (onCheckboxChanges)="checkboxEmployeeSelectionChanged($event)"
        >
        </app-trade-user-item>
      </ng-template>
    </tree-root>
  </div>
  <div class="kt-flow-item flow-buttons">
    <button [class.disabled]="!selectedEmployees.length" (click)="move()" class="icon-button">
      <app-svg-icon [width]="20" [height]="20" name="caret-square-right"></app-svg-icon>
    </button>

    <button [class.disabled]="!selectedCustomers.length" (click)="remove()" class="icon-button">
      <app-svg-icon [width]="20" [height]="20" name="caret-square-left"></app-svg-icon>
    </button>
  </div>
  <div class="kt-flow-item overflow">
    <ng-container *ngFor="let customer of customers | customerFilterList : customerInputValue | tradeCustomersSort">
      <app-trade-user-item
        *ngIf="customer.user.status !== statuses.IN_ARCHIVE"
        [item]="customer.user"
        (onUserDbClick)="remove($event)"
        [accessRight]="customer.access_rights"
        [isAccessChangeOptions]="!readonly && isAccessChangeOptions"
        [userId]="userId"
        [ownerId]="newOwnerId || card?.owner.id"
        [roomId]="customer.user.group_room_id"
        [userType]="userType"
        [isCustomer]="true"
        [searchString]="customerInputValue"
        (onSelectMenuItem)="changeCustomerAccess.call(this, $event, customer)"
        (onCheckboxChanges)="checkboxCustomerSelectionChanged($event)"
      >
      </app-trade-user-item>
    </ng-container>
  </div>
</div>
