<app-change-tso-title title="Мой оператор технической поддержки"></app-change-tso-title>

<app-tso-list-table
  [tsoList]="[myCurrentTso]"
  [dateOffSet]="dateOffSet"
  (openChangedModalEvent)="openChangedTso()"
  (openConfirmationModalEvent)="openConfirmationModal()"
></app-tso-list-table>

<app-modal-window
  [isShowed]="isShowedChangedTso"
  (closeEvent)="closeChangedTso()"
  [centered]="true"
  customClass="change-tso-modal"
>
  <app-choose-tso-modal
    [myTsoId]="myCurrentTso?.id"
    [dateOffSet]="dateOffSet"
    *ngIf="isShowedChangedTso"
    (closeModal)="closeChangedTso()"
    (newTso)="changeSelection($event)"
  ></app-choose-tso-modal>
</app-modal-window>

<app-modal-window [isShowed]="isReallyRemoving" (closeEvent)="isReallyRemoving = false" centered="true">
  <p class="mb-3">Вы действительно хотите отказаться от оператора технической поддержки?</p>
  <p class="mb-4 text-overflow">В случае отказа, Вы сможете его выбрать позже.</p>
  <div class="d-flex justify-content-between">
    <button class="btn btn-dc-outline" (click)="isReallyRemoving = false">Нет, отменить</button>
    <button class="btn btn-dc-primary" (click)="removeTso()">Да, отказаться</button>
  </div>
</app-modal-window>
