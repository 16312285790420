<app-rich-modal (close)="onNotificationClose()" modalType="notification" [movable]="true" class="notification">
  <div class="height-100-percent">
    <div class="row">
      <div
        (click)="activeSelect = listTab.LIST"
        [class.active]="activeSelect === listTab.LIST"
        class="col-6 text-center notification__section-select"
      >
        Просмотр уведомлений
      </div>
      <div
        (click)="activeSelect = listTab.SETTING"
        [class.active]="activeSelect === listTab.SETTING"
        class="col-6 text-center notification__section-select"
      >
        Настройка уведомлений
      </div>
    </div>
    <app-notification-list
      [dataSource]="dataSource.noti"
      [countNotification]="countNotification"
      *ngIf="activeSelect === listTab.LIST"
    ></app-notification-list>
    <app-notification-setting
      [dataSource]="dataSource.settings"
      [loading]="dataSource.loading"
      *ngIf="activeSelect === listTab.SETTING"
    ></app-notification-setting>
  </div>
</app-rich-modal>
