import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadMediaFilesInterface } from '../../models/file-upload.interface';
import { OPEN_DELAY_TOOLTIP } from '../../constants/file-manager-base.constants';
import { FileManageUploadService } from '../../services/file-manage-upload.service';
import { FileLogAccrualModel } from '@app/+tariffs/models/payment-accrual.model';
import { PaymentRegistryService } from '@app/+tariffs/services/payment-registry.service';
import { NotificationsService } from 'angular2-notifications';
import moment from 'moment';
import { FileLogColumnsKeys } from '@app/+tariffs/constants/payment-accruals.constants';
import { OPERATOR } from '@app/shared/enums/flask-operators';
import { BACKEND_DATE_TIME_FORMAT } from '@app/shared/constants/date.constants';

@Component({
  selector: 'app-file-manager-modal-upload',
  templateUrl: './file-manager-modal-upload.component.html',
  styleUrls: ['./file-manager-modal-upload.component.scss'],
})
export class FileManagerModalUploadComponent implements OnInit {
  isLogOpen = true;

  @Input() isChatUpload: boolean = false;
  @Input() folderName: string = '';
  @Input() tableName: string = '';
  @Input() isAccrualsTable = false;
  @Output() readonly onUpload = new EventEmitter<any>();

  get uploadedMedia(): UploadMediaFilesInterface[] {
    return this.fileManageUploadService.uploadedMedia;
  }

  get openDelayTooltip(): number {
    return OPEN_DELAY_TOOLTIP;
  }

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly fileManageUploadService: FileManageUploadService,
    private paymentRegistry: PaymentRegistryService,
    private notify: NotificationsService
  ) {}

  ngOnInit() {
    if (this.isAccrualsTable) {
      this.getUploadedFiles();
    }
  }

  getUploadedFiles() {
    const startOfDay = moment.utc().startOf('day');
    const endOfDay = moment.utc().endOf('day');

    const date = {
      name: FileLogColumnsKeys.CREATED_AT,
      op: OPERATOR.BETWEEN,
      val: [startOfDay.format(BACKEND_DATE_TIME_FORMAT), endOfDay.format(BACKEND_DATE_TIME_FORMAT)],
    };

    this.paymentRegistry.getFileLogAccrual([date]).subscribe(
      (res: FileLogAccrualModel[]) => {
        this.fileManageUploadService.uploadedMedia = this.getMappedFilesData(res);
      },
      (err) => {
        this.notify.error('Ошибка!', err.error?.message || err.message || err.status, {
          clickToClose: true,
        });
      }
    );
  }

  getMappedFilesData(res: FileLogAccrualModel[]): UploadMediaFilesInterface[] {
    return res.map((file) => ({
      file: null,
      fileName: file.file_name,
      fileType: '',
      fileUrl: '',
      fileProgress: 100,
      error: null,
      ready: true,
      ngUnsubscribe: null,
    }));
  }

  upload(event: Event): void {
    this.onUpload.emit(event);
  }

  clearFile(event: Event): void {
    const target = event.target as HTMLInputElement;
    target.value = '';
  }

  cancel(): void {
    this.activeModal.dismiss({ dismiss: 'dismiss' });
  }

  abort(event: MouseEvent, item: UploadMediaFilesInterface): void {
    event.stopPropagation();
    event.preventDefault();
    item.ngUnsubscribe.next();
    this.fileManageUploadService.uploadedMedia.splice(this.fileManageUploadService.uploadedMedia.indexOf(item), 1);
  }

  toggleLog(): void {
    this.isLogOpen = !this.isLogOpen;
  }
}
