<div #scrollMe [scrollTop]="scrollHeight" class="scroll-me">
  <ng-container *ngIf="!isTradeGroup(roomSelected) && !isMessagesLoading">
    <ng-container *ngIf="messages.length; else noMessages">
      <div
        *ngFor="let message of messages; trackBy: trackById"
        class="chat-messages-row"
        [ngClass]="{
          unread: message.id > roomSelected.last_read_message_id && !isMyMessage(message),
          'selected-message': isMessageSelected(message)
        }"
        [attr.data-id]="message.id"
        #messageElement
      >
        <div
          class="checkbox {{ !messageSelectedList.length }}"
          [ngClass]="{
            'visibility-hidden': !messageSelectedList.length || message.status === statusEnum.IN_ARCHIVE
          }"
        >
          <input
            class="cbx-input"
            type="checkbox"
            id="check_{{ message.id }}"
            (change)="toggleMessageSelected(message)"
            [checked]="isMessageSelected(message)"
          />
          <label class="cbx-label" [class.cbx-label-interminate]="1 === 'any'" for="check_{{ message.id }}"></label>
        </div>
        <div
          class="chat__message"
          [ngbPopover]="contextMenu"
          popoverClass="message-context-popover"
          placement="{{ isMyMessage(message) ? 'top-left' : 'top-right' }}"
          triggers="manual"
          #popover="ngbPopover"
          (contextmenu)="openContextMenu($event, popover)"
          [ngClass]="{
            my: isMyMessage(message),
            response: !isMyMessage(message)
          }"
        >
          <div class="chat__message-info">
            <div class="chat__message-info_contact">
              <span
                *ngIf="message.author_id === userId && !isCurrentTechTypeAllUsers && !isTsoChat"
                class="chat__message-info_bold"
                >Я</span
              >
              <span
                *ngIf="
                  (message.author_id !== userId && message.author.type !== rolesEnum.DUTY_TSO) ||
                  (message.author_id === userId && (isCurrentTechTypeAllUsers || isTsoChat))
                "
                class="chat__message-info_bold"
              >
                {{ message | messageInitials : tradeGroup : userId : isTechTso }}, {{ message.author.type | roleName }}
              </span>
              <span
                *ngIf="message.author_id !== userId && message.author.type === rolesEnum.DUTY_TSO"
                class="chat__message-info_bold"
              >
                <ng-container *ngIf="message.extra && isTechTso"> {{ message.extra.id }}, </ng-container>
                <ng-container *ngIf="message.extra"> {{ message.extra | initials }}, </ng-container>
                дОТП
              </span>
              <span class="chat__message-info_light-text">
                {{ message.created_at | dateTimeFormat : timezoneOffset }}
              </span>
            </div>
            <div
              *ngIf="message.initial_author"
              class="chat__message-forward-icon chat__message-forward-icon_opacity"
              ngbTooltip="Пересланное сообщение. Автор сообщения: {{ message.initial_author | initials }}, {{
                message.initial_author.type | titleRole
              }}"
            >
              <app-svg-icon name="forward" width="16" height="16"></app-svg-icon>
            </div>
          </div>
          <div
            class="chat__message-content"
            [ngClass]="{
              'removed-message': message.status === statusEnum.IN_ARCHIVE,
              person: message.author_id === userId && message.status === statusEnum.IN_ARCHIVE,
              'removed-active': messageSelectedList.length
            }"
          >
            <div class="quote" *ngIf="message?.parent as quoteMessage">
              <div class="quote__message" (click)="scrollToQuote(message.parent_id)">
                <span
                  class="quote__message--limited"
                  title="{{
                    'Автор: ' +
                      quoteMessage?.author.first_name +
                      ' ' +
                      quoteMessage?.author.second_name +
                      '. ' +
                      (quoteMessage?.created_at | dateTimeFormat : timezoneOffset)
                  }}"
                >
                  {{ quoteMessage?.content || 'Прикреплены файлы.' }}
                </span>
              </div>
            </div>

            <div
              #messageContent
              class="content-text"
              [ngClass]="{ expanded: isExpanded(message) }"
              [innerHTML]="message.content | linkify"
            ></div>

            <button
              *ngIf="isExpanded(message) || isOverflowing(messageContent)"
              (click)="toggleExpand(message)"
              class="chat__message-action content-button"
            >
              {{ isExpanded(message) ? 'Скрыть' : 'Читать далее' }}
            </button>

            <div *ngIf="message.attached_files.length" class="chat__message-attach">
              <app-chat-attached-file
                *ngFor="let file of message.attached_files; let i = index"
                [attachedFiles]="message.attached_files"
                [clickedIndex]="i"
              ></app-chat-attached-file>
            </div>
          </div>
          <ng-template #contextMenu>
            <div
              class="chat__message-context-menu"
              [ngClass]="{
                my: isMyMessage(message),
                response: !isMyMessage(message)
              }"
            >
              <button (click)="onReplyMessage(message)" class="chat__message-action">Ответить</button>
              <button (click)="onForwardMessage(message)" class="chat__message-action">Переслать</button>
              <button (click)="onRemoveMessage(message)" class="chat__message-action">Удалить</button>
            </div>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div *ngIf="isMessagesLoading && !chatLoading" class="loader-overlay">
    <app-svg-icon [width]="64" [height]="64" name="loader-animate"></app-svg-icon>
  </div>
</div>

<button
  *ngIf="messagesOffset?.bottom || isArrowShown"
  type="button"
  class="last-message-arrow blue-links-button"
  title="К последнему сообщению."
  (click)="moveToLastMessage()"
>
  <app-svg-icon [width]="24" [height]="24" name="arrow-right"></app-svg-icon>
</button>

<ng-template #noMessages>
  <div class="chat-messages-row">Нет сообщений</div>
</ng-template>
