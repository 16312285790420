import { Pipe, PipeTransform } from '@angular/core';
import { DatesHelper } from '../helpers/dates.helper';

@Pipe({
  name: 'dateFormat',
  standalone: true,
})
export class DateFormatPipe implements PipeTransform {
  transform(value: any, offset?: any): any {
    if (!value) {
      return '-';
    }
    return DatesHelper.formatDate(value, offset);
  }
}
