import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { TradeCardStatus } from '../../types/trades.types';
import { TradeCardStatuses } from '../../constants/trades.constants';

@Pipe({
  name: 'diffDates',
})
export class DiffDatesPipe implements PipeTransform {
  transform(start: string, end: string, status: TradeCardStatus): string {
    const startDate = moment(start);
    const endDate = moment(end);
    const diffMs = startDate.diff(endDate);
    const duration = moment.duration(diffMs);

    const days = this.getCounter(startDate, endDate, duration.days(), status);
    const hours = this.getCounter(startDate, endDate, duration.hours(), status);
    const minutes = this.getCounter(startDate, endDate, duration.minutes(), status);

    return `${days} дн. ${hours}ч. ${minutes} м.`;
  }

  getCounter(start: moment.Moment, end: moment.Moment, value: number, status: TradeCardStatus) {
    if (status === TradeCardStatuses.ARCHIVE || start.valueOf() > end.valueOf()) {
      return 0;
    }
    return Math.abs(value);
  }
}
