import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@app/shared/models/user.model';

@Pipe({
  name: 'techChatTsoSort',
  standalone: true,
  pure: false,
})
export class TechChatTsoSortPipe implements PipeTransform {
  transform(value: User[], currentUserId: number): User[] {
    const currentTso = value.filter((tso) => tso?.id === currentUserId);
    const otherTsos = value
      .filter((tso) => tso?.id !== currentUserId)
      .sort((a, b) => {
        const diff = b.number_of_assigned_user - a.number_of_assigned_user;
        if (diff !== 0) {
          return diff;
        } else {
          return a.id - b.id;
        }
      });
    return [...currentTso, ...otherTsos];
  }
}
