import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-select-provider-filter',
  templateUrl: './select-provider-filter.component.html',
  styleUrls: ['./select-provider-filter.component.scss']
})
export class SelectProviderFilterComponent implements OnInit {
  @Input() value: boolean;

  @Output() submitEvent = new EventEmitter<{value: boolean}>();


  constructor() { }

  ngOnInit() {
  }

  apply() {
    this.submitEvent.emit({value: this.value});
  }

}
