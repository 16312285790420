import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { REDIRECT_AFTER_LOGIN } from '@app/auth/redirect-after-login.constants';
import { MENU_ITEMS } from '@app/shared/constants/menu-items.constants';
import { UserSettings } from '@app/shared/models/user-settings.model';
import { AuthService } from '@app/shared/services/auth.service';
import { PersonalDataService } from '@app/shared/services/personal-data.service';
import { Validation } from '@app/shared/services/validation.service';
import { NotificationsService } from 'angular2-notifications';
import { RecaptchaComponent } from 'ng-recaptcha';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  templateUrl: './confirm_resetpass.component.html',
  styleUrls: ['../auth.component.sass'],
})
export class ConfirmResetPassComponent {
  @ViewChild('captchaRef') captchaRef: RecaptchaComponent;

  errors = <any>{};

  readonly formGroup = new FormGroup(
    {
      password: new FormControl('', [Validation.reactivePasswordValidator()]),
      password_double: new FormControl(''),
    },
    {
      validators: [Validation.reactiveEqualsPasswords('password', 'password_double')],
    }
  );

  private captcha_token;

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private notify: NotificationsService,
    private personalDataService: PersonalDataService
  ) {}

  handleCorrectCaptcha(res): void {
    this.captcha_token = res;
  }

  resetCaptcha(): void {
    if (this.captcha_token) {
      this.captchaRef.reset();
      this.captcha_token = null;
    }
  }

  confirm() {
    this.errors = {};

    const password = this.formGroup.controls.password.value;
    const captcha_error = Validation.validateCaptcha(this.captcha_token);

    if (captcha_error.length) {
      this.errors.captcha = captcha_error;
    }

    if (this.formGroup.invalid || this.errors.captcha) {
      Object.values(this.formGroup.controls).forEach((control) => control.markAsTouched());
      return;
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      const email = params['email'];
      const key = params['key'];
      if (email && key)
        this.authService.resetPassConform(email, key, password).subscribe(
          () => {
            this.notify.success('Успешно!', 'Добро пожаловать!');
            this.authService.updateUser().subscribe((user) => {
              this.personalDataService.getUserSettings().subscribe((settings: UserSettings) => {
                const menuItems = MENU_ITEMS[user.type].tuning as any;
                const found = menuItems.find((item) => item.link === settings.start_page);
                if (found && found.link) {
                  this.router.navigate([found.link]);
                } else {
                  this.router.navigate([REDIRECT_AFTER_LOGIN]);
                }
              });
            });
          },
          () => this.router.navigate(['signin'])
        );
    });
  }

  getErrorByFormControlName(name: string) {
    const formErrors = this.formGroup.get(name).errors;

    return formErrors ? Object.values(formErrors).flat()[0] : null;
  }

  togglePasswordShowing(input: HTMLInputElement) {
    input.type = input.type === 'password' ? 'text' : 'password';
  }
}
