import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TradeCard } from '@app/+trades/models/trades.model';
import { TradesService } from '@app/+trades/services/trades.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-template-create-modal',
  templateUrl: './template-create-modal.component.html',
  styleUrls: ['./template-create-modal.component.scss'],
})
export class TemplateCreateModalComponent {
  private _card: TradeCard;
  @Input() set card(value: TradeCard) {
    this._card = value;
    if (value) {
      this.newCardTemplateTitleControl.setValue(value.title, { emitEvent: false });
    }
  }

  readonly MAX_VALUE = 100;
  isSaveTemplateLoading = false;
  newCardTemplateTitleControl = new FormControl('', [Validators.required, Validators.maxLength(this.MAX_VALUE)]);

  private closed$ = new Subject<void>();

  constructor(
    private tradesService: TradesService,
    private notify: NotificationsService,
    private readonly activeModal: NgbActiveModal
  ) {}

  ngOnDestroy() {
    this.closed$.next();
    this.closed$.complete();
  }

  saveTemplate() {
    this.isSaveTemplateLoading = true;

    this._card.title = this.newCardTemplateTitleControl.value;

    this.tradesService
      .createTemplateByTrade(this._card.id, this._card.title)
      .pipe(
        takeUntil(this.closed$),
        finalize(() => {
          this.isSaveTemplateLoading = false;
          this.closeTemplateModal();
        })
      )
      .subscribe(
        (card) => {
          this.tradesService.navigateToCustomerCard(card.id);
          this.notify.success('Успешно!', 'Шаблон карточки торгов создан');
        },
        (err) => console.log(err)
      );
  }

  cancelSaveTemplate() {
    this.activeModal.close();
    this.newCardTemplateTitleControl.reset();
    this.closed$.next();
  }

  closeTemplateModal() {
    this._card = null;
    this.newCardTemplateTitleControl.setValue('', { emitEvent: false });
    this.cancelSaveTemplate();
  }
}
