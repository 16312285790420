import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// Custom Modules
import { AuthModule } from './auth/auth.module';
import { ProfileModule } from './profile/profile.module';
import { SharedModule } from 'app/shared/shared.module';
import { CalendarModule } from './calendar/calendar.module';
import { appRoutes } from './app.routes';

import { AppComponent } from './app.component';
import { DashboardLayoutComponent } from './shared/layout_components/dashboard_layout/dashboard_layout.component';
import { AuthLayoutComponent } from './shared/layout_components/auth-layout/auth-layout.component';

import { IsLoggedIn, IsLoggedOut, IsNotBlocked } from './shared/services/guard.service';
import { FaqTooltipService } from './shared/services/faq-tooltip.service';
import { AccessService } from './shared/services/access.service';
import { CalendarDataService } from './shared/services/calendar/calendar-data.service';
import { CalendarService } from './shared/services/calendar/calendar.service';

import { EmployeesService } from './shared/services/employers.service';

import { AuthInterceptor } from './shared/services/auth-interceptor.service';
import { ChatModule } from '@app/chat/chat.module';
import { NotificationModule } from '@app/notification/notification.module';
import { NotificationService } from '@app/notification/services/notification.service';
import { PaymentRegistryDataService } from '@app/+tariffs/services/payment-registry-data.service';
import { PaymentRegistryService } from '@app/+tariffs/services/payment-registry.service';
import { PaymentPdfService } from '@app/+tariffs/services/payment-pdf.service';
import { CngHtmlCompilerModule, JitCompilerModule } from '@codehint-ng/html-compiler';
import { ModalInjectorModule } from '@app/modal-injector/modal-injector.module';
import { NgxMaskModule } from 'ngx-mask';
import { CookieService } from 'ngx-cookie-service';
import { PortalModule } from '@angular/cdk/portal';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  // Классы представлений(views): компоненты(components), директивы(directives), каналы(pipes)
  declarations: [AppComponent, DashboardLayoutComponent, AuthLayoutComponent],
  // Набор классов представлений, которые должны использоваться в шаблонах компонентов из других модулей
  exports: [],
  // Другие модули, классы которых необходимы для шаблонов компонентов из текущего модуля
  imports: [
    BrowserModule,
    PortalModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AuthModule,
    ProfileModule,
    SharedModule,
    FontAwesomeModule,
    CalendarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    SimpleNotificationsModule.forRoot(),
    NgbModule,
    ChatModule,
    NotificationModule,
    CngHtmlCompilerModule,
    JitCompilerModule,
    ModalInjectorModule,
    NgxMaskModule.forRoot(),
    RouterModule.forRoot(appRoutes, { paramsInheritanceStrategy: 'always' }),
  ],
  // Классы, создающие сервисы, используемые модулем
  providers: [
    IsLoggedIn,
    IsLoggedOut,
    IsNotBlocked,
    // HasAccess,
    EmployeesService,
    FaqTooltipService,
    AccessService,
    CalendarService,
    CalendarDataService,
    CookieService,
    NotificationService,
    NgbActiveModal,
    PaymentRegistryDataService,
    PaymentRegistryService,
    PaymentPdfService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  // Корневой компонент, который вызывается по умолчанию при загрузке приложения
  bootstrap: [AppComponent],
})
export class AppModule {}
