import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MultiSelectListItem } from '../../models/multi-select-list-item';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { RadioSelectListItem } from '@app/shared/models/radio-select-list-item';

@Component({
  selector: 'app-radio-filter',
  templateUrl: './radio-filter.component.html',
  styleUrls: ['./radio-filter.component.scss'],
})
export class RadioFilterComponent implements OnInit, OnChanges {
  public form: FormGroup;
  public formRightGroup: FormGroup;
  private selectedRight: MultiSelectListItem;
  private _list: MultiSelectListItem[] = [];
  public selectedValue: RadioSelectListItem;

  @Input() selected: any = null;
  @Output() submitEvent = new EventEmitter<{ [key: string]: any[] }>();
  @Output() cancelEvent = new EventEmitter();

  @Input() set list(list: MultiSelectListItem[]) {
    if (!list.length) {
      return;
    }

    this.selectedValue = list.find((item) => item.checked) || list[0];

    this.formRightGroup = this.fb.group({
      formRadio: new FormControl(this.selectedValue),
    });

    this._list = list;
    [this.selectedRight] = list.filter((item: MultiSelectListItem) => item.checked);
    this.form.get('formRadio').setValue(this.selectedRight);
  }

  get list(): MultiSelectListItem[] {
    return this._list;
  }

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      formRadio: new FormControl(null),
    });
  }

  ngOnInit() {
    this.formRightGroup.controls.formRadio.valueChanges.subscribe((val) => {
      this.selectedValue = val;
    });
  }

  ngOnChanges(): void {
    // Так как это radio и выбранно должно быть только одно значение, забираем первое значение
    if (this.selected) {
      const paramsList = Object.values(this.selected);
      this.form.controls['formRadio'].setValue(paramsList[0] ? paramsList[0] : null);
    }
  }

  apply() {
    const { formRadio } = this.form.value;
    this.submitEvent.emit({ value: formRadio });
  }

  submit() {
    this.submitEvent.emit({ value: [this.selectedValue.checked, this.selectedValue.id] });
  }

  cancel() {
    this.cancelEvent.emit();
  }
}
