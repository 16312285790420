import { Component, Input, OnInit } from '@angular/core';
import { CriteriaType } from '@app/+trades/types/trades.types';
import { CriteriaTypesEnum } from '@app/+trades/constants/lots.constants';

@Component({
  selector: 'app-criteria-value',
  templateUrl: './criteria-value.component.html',
  styleUrls: ['./criteria-value.component.scss'],
})
export class CriteriaValueComponent implements OnInit {
  @Input() value: string;
  @Input() type: CriteriaType;

  criteriaTypes = CriteriaTypesEnum;

  constructor() {}

  ngOnInit() {}
}
