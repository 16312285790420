import { Injectable } from '@angular/core';
import { HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { NotificationsService } from 'angular2-notifications';
import { BehaviorSubject, EMPTY, Observable, Subject, throwError } from 'rxjs';
import { catchError, filter, finalize, map, takeUntil, tap } from 'rxjs/operators';
import { FileManagerService } from './file-manager.service';
import { UploadMediaFilesInterface } from '../models/file-upload.interface';
import { PaymentAccrualsUploadPaymentsService } from '@app/+tariffs/services/payment-accruals-upload-payments.service';
import { TransactionsFromFile, UploadFileResponse } from '@app/+tariffs/models/payment-accrual.model';
import { UserFile } from '@app/file-manager/models/user-file.model';
import type { MessageDraftParams } from '@app/file-manager/models/message-draft.interface';
import type { ChatMessage, ChatMessageResponse } from '@app/chat/models/chat.model';
import { convertMessagesAttachToUserFile } from '@app/chat/helpers/convert-messages-attach-to-user-file';

@Injectable({
  providedIn: 'root',
})
export class FileManageUploadService {
  uploadedMedia: Array<UploadMediaFilesInterface> = [];

  private isUploadFileCompleteSubject = new Subject<boolean>();
  isUploadFileComplete$ = this.isUploadFileCompleteSubject.asObservable();

  private isUploadFileProcessSubject = new BehaviorSubject<boolean>(false);
  isUploadFileProcess$ = this.isUploadFileProcessSubject.asObservable();

  private uploadTransactions = new Subject<TransactionsFromFile[]>();
  uploadTransactions$ = this.uploadTransactions.asObservable();

  private uploadedDraft = new Subject<ChatMessage>();
  uploadedDraft$ = this.uploadedDraft.asObservable();

  constructor(
    private readonly fileManagerService: FileManagerService,
    private readonly notify: NotificationsService,
    private readonly paymentAccrualsUploadPaymentsService: PaymentAccrualsUploadPaymentsService
  ) {}

  startProgress(folderId: number, file: File, uploadedMediaIndex: number): void {
    const filteredFile = this.uploadedMedia.filter((_, index) => index === uploadedMediaIndex).pop();
    const uploadedInfo = this.uploadedMedia[uploadedMediaIndex];

    if (filteredFile) {
      this.changeUploadProcess(true);

      const data: FormData = new FormData();
      data.append('file', file, filteredFile.fileName);

      filteredFile.ngUnsubscribe.subscribe(() => {
        this.changeUploadProcess(false);
        this.notify.warn('Внимание!', `Загрузка файла ${uploadedInfo?.fileName} отменена`);
      });

      this.fileManagerService
        .uploadFile(folderId, data)
        .pipe(takeUntil(filteredFile.ngUnsubscribe))
        .subscribe(
          (event) => {
            switch (event.type) {
              case HttpEventType.Sent: {
                uploadedInfo.fileProgress = 0;
                break;
              }
              case HttpEventType.UploadProgress: {
                uploadedInfo.fileProgress = this.calculateLoadProgress(event);
                break;
              }
              case HttpEventType.Response: {
                uploadedInfo.fileProgress = 100;
                uploadedInfo.ready = true;
                this.changeUploadProcess(false);
                this.notify.success('Успешно!', 'Загрузка файлов завершена');
                break;
              }
            }
          },
          ({ error }) => {
            uploadedInfo.fileProgress = 100;
            uploadedInfo.ready = true;
            uploadedInfo.error = `Не удалось загрузить. ${error.message}`;
            this.notify.error('Внимание!', `Не удалось загрузить ${uploadedInfo?.fileName}. ${error.message}.`);
            this.changeUploadProcess(false);
          },
          () => {
            this.changeUploadComplete(true);
          }
        );
    }
  }

  messageDraftProgress(draftParams: MessageDraftParams): void {
    const draft = this.uploadedMedia[0];

    if (draft) {
      this.changeUploadProcess(true);

      draft.ngUnsubscribe.subscribe(() => {
        this.changeUploadProcess(false);
        this.notify.warn('Внимание!', `Загрузка файла отменена`);
      });

      this.fileManagerService
        .uploadMessageDraft(draftParams)
        .pipe(takeUntil(draft.ngUnsubscribe))
        .subscribe({
          next: (event) => {
            switch (event.type) {
              case HttpEventType.Sent: {
                draft.fileProgress = 0;
                break;
              }
              case HttpEventType.UploadProgress: {
                draft.fileProgress = this.calculateLoadProgress(event);
                break;
              }
              case HttpEventType.Response: {
                draft.fileProgress = 100;
                draft.ready = true;
                this.setUploadedDraft(event.body as ChatMessageResponse);
                this.updateUploadedMediaList([]);
                this.changeUploadProcess(false);
                break;
              }
            }
          },
          error: ({ error }) => {
            draft.fileProgress = 100;
            draft.ready = true;
            draft.error = `Не удалось загрузить. ${error.message}`;
            this.notify.error('Внимание!', `Не удалось загрузить. ${error.message}.`);
            this.changeUploadProcess(false);
          },
          complete: () => this.changeUploadComplete(true),
        });
    }
  }

  startUploading(folderId: number, file: File, uploadedMediaIndex: number): Observable<UploadFileResponse> {
    const filteredFile = this.uploadedMedia.filter((_, index) => index === uploadedMediaIndex).pop();
    const uploadedInfo = this.uploadedMedia[uploadedMediaIndex];

    if (filteredFile) {
      this.changeUploadProcess(true);

      const data: FormData = new FormData();
      data.append('file', file, filteredFile.fileName);

      filteredFile.ngUnsubscribe.subscribe(() => {
        this.changeUploadProcess(false);
        this.notify.warn('Внимание!', `Загрузка файла ${uploadedInfo?.fileName} отменена`);
      });

      return this.paymentAccrualsUploadPaymentsService.loadTransactionFile(data).pipe(
        takeUntil(filteredFile.ngUnsubscribe),
        tap((event) => {
          switch (event.type) {
            case HttpEventType.Sent: {
              uploadedInfo.fileProgress = 0;
              break;
            }
            case HttpEventType.UploadProgress: {
              uploadedInfo.fileProgress = this.calculateLoadProgress(event);
              break;
            }
            case HttpEventType.Response: {
              uploadedInfo.fileProgress = 100;
              uploadedInfo.ready = true;
              this.changeUploadProcess(false);
              this.notify.success('Успешно!', 'Загрузка файлов завершена');
              break;
            }
          }
        }),
        catchError(({ error }) => {
          uploadedInfo.fileProgress = 100;
          uploadedInfo.ready = true;
          uploadedInfo.error = `Не удалось загрузить. ${error.message}`;
          this.notify.error('Внимание!', `${uploadedInfo.error}`);
          this.changeUploadProcess(false);
          return throwError(() => error);
        }),
        finalize(() => this.changeUploadComplete(true)),
        filter((event) => event.type === HttpEventType.Response),
        map((event) => (event as HttpResponse<UploadFileResponse>).body)
      );
    }

    return EMPTY;
  }

  changeUploadProcess(status: boolean): void {
    this.isUploadFileProcessSubject.next(status);
  }

  changeUploadComplete(status: boolean): void {
    this.isUploadFileCompleteSubject.next(status);
  }

  setUploadTransactions(data: UploadFileResponse | null): void {
    this.uploadTransactions.next(data.result);
  }

  private setUploadedDraft(draftResponse: ChatMessageResponse | null): void {
    const [message] = convertMessagesAttachToUserFile([draftResponse]);
    this.uploadedDraft.next(message);
  }

  updateUploadedMediaList(items) {
    // так список в сервисе нужно убрать ранее добавленный список загрузок
    this.uploadedMedia = this.uploadedMedia.filter((item) => item.fileProgress !== 100);

    const list = this.uploadedMedia.concat(
      items.map((file: UserFile) => {
        return {
          file,
          fileName: file.name,
          fileType: file.mimetype,
          fileUrl: file.url,
          fileProgress: 100,
          error: null,
          ngUnsubscribe: null,
          ready: true,
        };
      })
    );

    this.uploadedMedia = [...list];
  }

  private calculateLoadProgress(event: HttpProgressEvent) {
    return Math.round((100 * event.loaded) / event.total);
  }
}
