<div class="kt-files-item kt-files-features">
  <div class="controls" *ngIf="documents.length > 0">
    <div class="count">
      Добавлено файлов
      <span class="count">{{ documentsCount }}</span>
    </div>

    <div class="all-radios" *ngIf="isEdit">
      <div class="dc-radio-input">
        <input
          id="forAll"
          type="radio"
          name="forAll"
          [value]="0"
          (change)="onAllRadioChange(true)"
          [disabled]="!canChangeAccess"
          [(ngModel)]="allRadios"
        />
        <label class="radio-label" for="forAll">Для всех</label>
      </div>

      <div class="dc-radio-input">
        <input
          id="forCustomers"
          type="radio"
          name="forAll"
          [value]="1"
          (change)="onAllRadioChange(false)"
          [(ngModel)]="allRadios"
        />
        <label class="radio-label" for="forCustomers">Для заказчиков</label>
      </div>
    </div>

    <a class="delete-all" (click)="deleteAll()" *ngIf="isEdit" [class.disabled]="isDisabledDeleteAll">
      <app-svg-icon name="trash" width="16" height="16"></app-svg-icon>
      <span> Удалить все файлы </span>
    </a>
  </div>

  <div class="file" *ngFor="let document of documents; index as i" [class.supplier]="readonly">
    <div class="d-flex">
      <div class="buttons">
        <button class="icon-button user-info" (click)="showUserInfo(document.link_user)">
          <app-svg-icon name="user" width="14" height="16"></app-svg-icon>
        </button>
        <button class="icon-button" (click)="download(document)">
          <app-svg-icon name="file-download" width="12" height="16"></app-svg-icon>
        </button>
      </div>

      <a class="file-name text-overflow" (click)="openPreview(document)" [class.inactive]="!document.url">
        {{ document.name }}
      </a>
    </div>

    <div class="radios" [class.mt-0]="isEdit">
      <ng-container *ngIf="isEdit">
        <div class="dc-radio-input">
          <input
            id="forAll-{{ document.id }}"
            type="radio"
            name="for-{{ document.id }}"
            [checked]="document.for_providers"
            (change)="onRadioChange(document)"
            [disabled]="!canChangeAccess"
          />
          <label class="radio-label" for="forAll-{{ document.id }}">Для всех</label>
        </div>

        <div class="dc-radio-input">
          <input
            id="forCustomers-{{ document.id }}"
            type="radio"
            name="for-{{ document.id }}"
            [checked]="!document.for_providers"
            (change)="onRadioChange(document)"
          />
          <label class="radio-label" for="forCustomers-{{ document.id }}">Для заказчиков</label>
        </div>
      </ng-container>

      <ng-container *ngIf="!isEdit">
        <span *ngIf="document.for_providers"> Для всех </span>
        <span *ngIf="!document.for_providers"> Для заказчиков </span>
      </ng-container>
    </div>

    <div class="date">Файл добавлен {{ document.created_at | dateTimeFormat : offset }}</div>

    <button
      class="icon-button delete"
      (click)="handleDelete(i)"
      *ngIf="isEdit"
      [disabled]="document.for_providers && !canChangeAccess"
    >
      <app-svg-icon name="trash" width="16" height="16"></app-svg-icon>
    </button>

    <dc-form-field-wrapper [style]="'bootstrap'" *ngIf="isEdit">
      <dc-form-input [isShowLength]="true" [maxLength]="300">
        <textarea
          formInput
          class="form-control-question w-100"
          placeholder="Введите комментарий к документу"
          [(ngModel)]="document.comment"
          [disabled]="!document.url"
          (keyup)="documentChanged()"
          rows="3"
        ></textarea>
      </dc-form-input>
    </dc-form-field-wrapper>

    <div class="comment" *ngIf="!isEdit">
      <span *ngIf="document.comment">
        {{ document.comment }}
      </span>

      <span *ngIf="!document.comment" class="grey-text"> Комментарий к файлу отсутствует </span>
    </div>
  </div>
</div>

<a class="add" (click)="openFileManager()" *ngIf="isEdit">
  <app-svg-icon name="plus" width="16" height="16"></app-svg-icon>
  <span> Документ</span>
</a>
