import { takeUntil } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '../../services/auth.service';
import { CalendarService } from '../../services/calendar/calendar.service';
import { ChatService } from '@app/chat/services/chat.service';
import { SocketDataService } from '@app/services/socket-data.service';

import * as moment from 'moment';
import { ROLES, RolesEnum } from '../../constants/roles.constants';
import { User } from '../../models/user.model';
import { InitialsPipe } from '@app/shared/pipes/users/initials.pipe';
import { NotificationService } from '@app/notification/services/notification.service';
import { ModalInjectorService } from '@app/modal-injector/services/modal-injector.service';
import { ModalInjectorConstants } from '@app/modal-injector/constants/modal-injector.constants';
import { UserStatusesTypes } from '@app/shared/types/user-statuses.types';
import { LegalStatusesEnum } from '@app/shared/types/legal-statuses.types';
import { DestroyService } from '@app/services/destroy.service';
import { TIMEZONES } from '@app/shared/constants/date.constants';

@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss'],
  providers: [DestroyService],
})
export class StatusBarComponent implements OnInit {
  user: User;
  userStatus$: Observable<UserStatusesTypes | null>;
  ROLES = ROLES;
  rolesEnum = RolesEnum;
  legalStatusesEnum = LegalStatusesEnum;
  todayEventsCount = 0;
  unreadMessages = 0;
  supportMessages = 0;
  need_set_password = false;
  day: string;
  dayOfWeek: string;
  month: string;
  timezoneOffset: number;

  constructor(
    private authService: AuthService,
    private calendarService: CalendarService,
    private chatService: ChatService,
    private notificationService: NotificationService,
    private chatDataService: SocketDataService,
    private initialsPipe: InitialsPipe,
    private modalInjectorService: ModalInjectorService,
    private destroy$: DestroyService
  ) {}

  get isCanBePayer(): boolean {
    if (this.user && this.user.type === RolesEnum.ADMIN_OF_DIRECTION) {
      return this.user?.flags.is_can_be_payer;
    }
    return false;
  }

  get parentUser(): User {
    return this.user.parent;
  }

  get shortName() {
    if (!this.user || (this.user && !this.user.second_name)) return 'unknown';
    return this.initialsPipe.transform(this.user);
  }

  get isSelfPayer() {
    return this.user?.flags.is_payer;
  }

  ngOnInit() {
    this.setDate();
    this.userStatus$ = this.authService.userStatus;

    this.authService.userStream.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.chatDataService.connect();
      this.user = user;

      this.need_set_password = !user.has_password;
    });

    this.unreadMessages = this.chatService.widgetMessageCounter;
    // this.todayEventsCount = this.notificationService.widgetMessageCounter;
    this.chatService.widgetMessageCounterChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe((widgetMessageCounter) => (this.unreadMessages = widgetMessageCounter));

    this.notificationService.widgetMessageCounterChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe((widgetMessageCounter) => (this.todayEventsCount = widgetMessageCounter));
  }

  getTodayEventsCount() {
    this.calendarService.todayEventsChanged.pipe(takeUntil(this.destroy$)).subscribe((eventsCount: number) => {
      this.todayEventsCount = eventsCount;
    });
  }

  setDate() {
    this.authService.userStream.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.timezoneOffset = +user.timezone_offset;

      this.day = moment().utc().add(this.timezoneOffset, 'minutes').format('DD');
      this.dayOfWeek = moment().utc().add(this.timezoneOffset, 'minutes').locale('ru').format('dddd');
      this.month = moment().utc().add(this.timezoneOffset, 'minutes').locale('ru').format('MMMM');

      if (['ь', 'й'].includes(this.month[this.month.length - 1])) {
        this.month = this.month.substring(0, this.month.length - 1) + 'я';
      } else {
        this.month += 'а';
      }
    });
  }

  onLogout() {
    this.chatDataService.disconnect();
    this.authService.logout();
  }

  onNotificationOpen() {
    this.notificationService.toggleNotificationOpen();
  }

  onCalendarWidgetOpen() {
    this.modalInjectorService.addModal(ModalInjectorConstants.CALENDAR_WIDGET);
  }

  onChatOpen() {
    this.chatService.openChat();
  }
}
