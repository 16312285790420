import { cloneDeep } from 'lodash-es';
import { Component, Input } from '@angular/core';
import { CMCatalogTypes } from '@app/+competence-map/constants/sections.constants';
import { TradeCompetency, TreeSectionsAndFiltersItem } from '@app/+competence-map/models/user-competency.model';
import { ActivatedRoute } from '@angular/router';
import { Section } from '@app/+competence-map/models/sections.model';
import { UserCompetencyTypes } from '@app/+competence-map/constants/user-competency.constants';
import { TreeHelper } from '@app/shared/helpers/tree.helper';

@Component({
  selector: 'app-competencies-cell',
  templateUrl: './competencies.component.html',
  styleUrls: ['./competencies.component.scss'],
})
export class CompetenciesComponent {
  @Input() visibleCount = 5;
  @Input() isStaticData: boolean;

  competenciesNames: string[] = [];
  isShowDetails = false;
  loaded = false;

  private _goodsList: TreeSectionsAndFiltersItem[];
  private _servicesList: TreeSectionsAndFiltersItem[];
  private _competencies: TradeCompetency[] = [];

  get competencies() {
    return this._competencies;
  }

  @Input() set childCompetencies(competencies: TradeCompetency[]) {
    this._competencies = competencies;
    // Возможно нужно будет для статуса архив - вызывать метод getParentCompetencyNames - проверить можно по полю isStaticData
    this.getCurrentCompetencyNames(competencies);
  }

  @Input() set parentCompetencies(competencies: TradeCompetency[]) {
    this._competencies = competencies;
    this.getParentCompetencyNames(competencies);
  }

  constructor(private route: ActivatedRoute) {
    this._goodsList = this.flatSectionsTreeList(cloneDeep(this.route.snapshot.data.goods));
    this._servicesList = this.flatSectionsTreeList(cloneDeep(this.route.snapshot.data.services));
  }

  showMoreDetails(event: MouseEvent): void {
    event.stopPropagation();
    this.isShowDetails = true;

    if (!this.loaded) {
      this.loaded = true;
    }
  }

  private getCurrentCompetencyNames(competencies: TradeCompetency[]): void {
    competencies.forEach((competency) => {
      if (competency.type === UserCompetencyTypes.FILTER) {
        return;
      }

      const section = (competency.section || competency) as Section;
      const catalog = section.catalog === CMCatalogTypes.GOODS ? this._goodsList : this._servicesList;
      const tree = catalog.find((item) => item.section.id === section.id);

      if (!tree) {
        return;
      }

      TreeHelper.flatTree(tree).forEach((el) => {
        const name = el.section.title.toLowerCase();
        if (!el.children?.length && !this.competenciesNames.includes(name)) {
          this.competenciesNames.push(name);
        }
      });
    });

    this.competenciesNames = this.competenciesNames.slice(0, this.visibleCount);
  }

  private getParentCompetencyNames(competencies: TradeCompetency[]): void {
    const parentCompetencies = competencies.filter((competence) => !competence.parent_id);
    parentCompetencies.forEach((competence) => {
      this.competenciesNames.push(competence.section.title);
    });

    this.competenciesNames = this.competenciesNames.slice(0, this.visibleCount);
  }

  private flatSectionsTreeList(list: TreeSectionsAndFiltersItem[]): TreeSectionsAndFiltersItem[] {
    return list.reduce(
      (acc: TreeSectionsAndFiltersItem[], current: TreeSectionsAndFiltersItem) =>
        acc.concat(TreeHelper.flatTree(current)),
      []
    );
  }
}
