import { Component, Input, OnInit } from '@angular/core';
import { ChatSectionService } from '@app/chat/services/chat-section.service';
import {
  ChatSectionsEnum,
  GroupSortingNames,
  SECTION_SORTING_OPTIONS,
  TradeRoomsTypes,
} from '../constants/chat-sections.constants';
import { ChatRoom, ChatSection } from '../models/chat.model';
import { ChatService } from '../services/chat.service';
import { AuthService } from '@app/shared/services/auth.service';
import { filter, takeUntil } from 'rxjs/operators';
import { DestroyService } from '@app/services/destroy.service';
import { User } from '@app/shared/models/user.model';
import { SectionSortingOptions, SortOption } from '@app/chat/models/chat.sorting-options.model';
import { ChatSortingService } from '@app/chat/services/chat-sorting.service';

@Component({
  selector: 'app-trade-chat',
  templateUrl: './trade-chat.component.html',
  styleUrls: ['./trade-chat.component.scss'],
})
export class TradeChatComponent implements OnInit {
  @Input() isModalView: boolean = false;
  tradeRoomsTypes = TradeRoomsTypes;
  isCustomerExpanded: boolean = true;
  isProviderExpanded: boolean = true;
  isChoosingCustomerSortDirection = false;
  isChoosingProviderSortDirection = false;
  contactSelected: ChatRoom;

  user: User;

  totalUnreadMessagesCounterCustomer: number | '10+';
  totalUnreadMessagesCounterProvider: number | '10+';

  chatSectionsEnum = ChatSectionsEnum;
  private chatSectionSelected: ChatSection;

  themes: ChatRoom[];
  groups: ChatRoom[] = [];
  customerGroups: ChatRoom[] = [];
  providerGroups: ChatRoom[] = [];

  sortOptions: SortOption[];
  customerSortType: GroupSortingNames = GroupSortingNames.LAST_MESSAGE_DATE;
  providerSortType: GroupSortingNames = GroupSortingNames.LAST_MESSAGE_DATE;

  constructor(
    private chatService: ChatService,
    private authService: AuthService,
    private ngUnsubscribe: DestroyService,
    private chatSectionService: ChatSectionService,
    private chatSortingService: ChatSortingService
  ) {
    this.authService.userStream.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
      this.user = user;
    });
  }

  ngOnInit(): void {
    this.chatSectionSelected = this.chatSectionService.chatSectionSelected;

    this.chatSectionService.chatSectionSelectedChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((chatSectionSelected) => {
        this.chatSectionSelected = chatSectionSelected;
        this.filterGroups(this.chatService.getGroupsThemes(this.chatSectionSelected.name)['groups']);
        this.filterThemes(this.chatService.getGroupsThemes(this.chatSectionSelected.name)['themes']);
      });

    this.filterGroups(this.chatService.getGroupsThemes(this.chatSectionSelected.name)['groups']);
    this.filterThemes(this.chatService.getGroupsThemes(this.chatSectionSelected.name)['themes']);

    this.chatService.contactsFilterChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.filterGroups(this.chatService.getGroupsThemes(this.chatSectionSelected.name)['groups']);
      this.filterThemes(this.chatService.getGroupsThemes(this.chatSectionSelected.name)['themes']);
    });

    this.chatService.groupsChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.filterGroups(this.chatService.getGroups(this.chatSectionSelected.name)));

    this.chatService.themesChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.filterThemes(this.chatService.getThemes(this.chatSectionSelected.name));
    });

    this.contactSelected = this.chatService.getContactSelected();

    this.chatService.contactSelectedChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((contactSelected) => (this.contactSelected = contactSelected));

    this.chatSortingService.settings$
      .pipe(
        filter((settings) => !!settings),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => this.setSortOptions());
  }

  private setSortOptions() {
    type SectionWithContacts = Extract<keyof SectionSortingOptions, 'trade'>;
    const sectionName = this.chatSectionSelected.name as SectionWithContacts;

    const { customer: customerSortType, provider: providerSortType } =
      this.chatSortingService.sortSettings[sectionName]?.groups;
    const sortOptions = SECTION_SORTING_OPTIONS[sectionName].groups;

    this.customerSortType = customerSortType;
    this.providerSortType = providerSortType;
    this.sortOptions = sortOptions.customer || sortOptions.provider;
  }

  onToggleExpanded(group: string) {
    if (group === this.tradeRoomsTypes.CUSTOMER) {
      this.isCustomerExpanded = !this.isCustomerExpanded;
    }

    if (group === this.tradeRoomsTypes.PROVIDER) {
      this.isProviderExpanded = !this.isProviderExpanded;
    }
  }

  sortDirectionChange(sortType: GroupSortingNames, group: TradeRoomsTypes): void {
    switch (group) {
      case TradeRoomsTypes.CUSTOMER: {
        this.chatSortingService.updateSortSettings({ groups: { customer: sortType } });
        break;
      }
      case TradeRoomsTypes.PROVIDER: {
        this.chatSortingService.updateSortSettings({ groups: { provider: sortType } });
        break;
      }
    }
    this.sortPopupClose(group);
  }

  onSortDirectionNext(group: TradeRoomsTypes): void {
    switch (group) {
      case TradeRoomsTypes.CUSTOMER: {
        this.isChoosingCustomerSortDirection = !this.isChoosingCustomerSortDirection;
        break;
      }
      case TradeRoomsTypes.PROVIDER: {
        this.isChoosingProviderSortDirection = !this.isChoosingProviderSortDirection;
        break;
      }
    }
  }

  sortPopupClose(group: TradeRoomsTypes): void {
    switch (group) {
      case TradeRoomsTypes.CUSTOMER: {
        this.isChoosingCustomerSortDirection = false;
        break;
      }
      case TradeRoomsTypes.PROVIDER: {
        this.isChoosingProviderSortDirection = false;
        break;
      }
    }
  }

  filterGroups(groups: ChatRoom) {
    this.groups = [];
    Object.keys(groups).forEach((room_id) => {
      const group: ChatRoom = groups[room_id];
      this.groups.push(group);
    });

    this.splitGroups();
  }

  filterThemes(themes: ChatRoom) {
    const customerThemes: ChatRoom[] = [];
    const providerThemes: ChatRoom[] = [];

    Object.keys(themes).forEach((room_id) => {
      const theme = themes[room_id];
      if (this.customerGroups.find((item) => item.id === theme.group_id)) {
        customerThemes.push(theme);
      }
      if (this.providerGroups.find((item) => item.id === theme.group_id)) {
        providerThemes.push(theme);
      }
    });
    // вложенные темы
    Object.keys(themes).forEach((room_id) => {
      const theme = themes[room_id];
      if (customerThemes.find((item) => item.id === theme.group_id)) {
        customerThemes.push(theme);
      }
      if (providerThemes.find((item) => item.id === theme.group_id)) {
        providerThemes.push(theme);
      }
    });

    this.themes = Object.values(themes);
    this.totalUnreadMessagesCounterCustomer = this.chatService.totalUnreadCount([...customerThemes]);
    this.totalUnreadMessagesCounterProvider = this.chatService.totalUnreadCount([...providerThemes]);
  }

  splitGroups() {
    const selfId = Number(this.authService.user_id);
    this.customerGroups = [];
    this.providerGroups = [];

    this.groups.map((item) => {
      if (item.customers?.find((id) => id === selfId)) {
        this.customerGroups.push(item);
      }
      if (item.providers?.find((id) => id === selfId)) {
        this.providerGroups.push(item);
      }
    });
  }

  trackByRoomId(index: number, room: ChatRoom): string {
    return room.room_id;
  }
}
