<div
  class="kt-logo"
  [ngClass]="{
    disabled: disabled,
    'kt-logo--without-image': !url,
    readonly: readonly
  }"
  (click)="handleFileClick($event)"
>
  <input type="file" hidden #file (change)="addFile($event)" accept=".jpg, .jpeg, .png" />
  <div class="kt-logo-image-preview" *ngIf="url">
    <img [src]="url" alt="" />
    <app-svg-icon
      *ngIf="!readonly"
      class="clear"
      name="times"
      height="16"
      width="16"
      (click)="clear($event)"
    ></app-svg-icon>
  </div>
  <div class="kt-logo-text" *ngIf="!url">
    <app-svg-icon class="kt-upload-img" height="23" width="30" name="image"></app-svg-icon>
    <div class="text-center">Загрузить изображение</div>
  </div>
</div>

<div *ngIf="!disabled && !readonly" class="kt-logo-help">
  Файл должен быть менее 10 МБ. Доступные расширения: png, jpg.
</div>
