import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RolesEnum } from '@app/shared/constants/roles.constants';

@Component({
  selector: 'app-tech-chat-title',
  templateUrl: './tech-chat-title.component.html',
  styleUrls: ['./tech-chat-title.component.scss'],
})
export class TechChatTitleComponent {
  @Input() isModalView: boolean = false;
  @Input() disabled = false;
  @Input() title = '';
  @Input() myTso = false;
  @Input() iconName = '';
  @Input() totalUnreadCounter: number | string;
  @Input() tooltipText: string = '';
  @Input() disabledTechButton: boolean = false;

  @Output() toggled = new EventEmitter();
  @Output() edit = new EventEmitter();

  @Input() isExpanded = false;

  onToggleExpanded() {
    this.isExpanded = !this.isExpanded;
    this.toggled.emit();
  }

  openEditor() {
    this.edit.emit();
  }
}
