import { Pipe, PipeTransform } from '@angular/core';
import { UserTypes } from '../types/user.types';
import { getFullTitleRole, getTitleRole } from '@app/shared/utils';
import { RolesEnum } from '../constants/roles.constants';

@Pipe({
  name: 'fullTitleRole',
})
export class FullTitleRolePipe implements PipeTransform {
  transform(type: UserTypes): string {
    if (type === RolesEnum.TSO) {
      return 'ОТП';
    }

    if (type === RolesEnum.DUTY_TSO) {
      return 'дОТП';
    }

    return getFullTitleRole(type);
  }
}
