<app-change-tso-title title="Выбор оператора технической поддержки"></app-change-tso-title>

<div class="tso-filter">
  <div class="tso-filter-block">
    <p class="mb-0">Все ОТП</p>
    <button class="icon-button" id="filter" [class.active]="isFilterApplied" type="button" (click)="openFilter()">
      <app-svg-icon [name]="'filter-table'" width="15" height="15"></app-svg-icon>
    </button>
    <button *ngIf="isFilterApplied" class="icon-button" type="button" (click)="onClearFilter()">
      <app-svg-icon [name]="'times'" width="15" height="15"></app-svg-icon>
    </button>
  </div>
  <div class="tso-filter-input">
    <dc-form-field-wrapper [style]="'material'" [vertically]="false" class="search-contacts">
      <dc-form-input isShowClear="true">
        <input
          formInput
          placeholder="Начните вводить ФИО сотрудника"
          (mousedown)="$event.stopPropagation()"
          [(ngModel)]="tsoNameValue"
          class="form-control"
          type="text"
        />
      </dc-form-input>
    </dc-form-field-wrapper>
  </div>
</div>

<app-tso-list-table
  (changedTsoEvent)="setTsoForChange($event)"
  [tsoList]="
    availableTso
      | usersInitialsFilter : tsoNameValue.toLowerCase()
      | contactsFilter : isFilterApplied
      | techChatTsoSort : myTsoId
  "
  [myTsoId]="myTsoId"
  [dateOffSet]="dateOffSet"
  [tsoValue]="tsoNameValue"
  [isChangeTso]="true"
></app-tso-list-table>

<div class="text-center tso-buttons">
  <button class="btn btn-dc-outline mr-3" (click)="closeChangedTso()">Отменить</button>&nbsp;
  <button [class.disabled]="disabledChangeButton" class="btn btn-dc-primary" (click)="changeTso()">Сохранить</button
  >&nbsp;
</div>

<app-modal-window
  [isShowed]="isEditingContactsOnlyFilter | async"
  (closeEvent)="onCloseFilter()"
  centered="true"
  customClass="contacts-only-filter-modal"
>
  <app-chat-contacts-only-filter
    [hiddenFilters]="['companies', 'roles']"
    [filterKey]="filterName"
  ></app-chat-contacts-only-filter>
</app-modal-window>
