import { AttachedFile, ChatMessage, ChatMessageResponse } from '../models/chat.model';
import { UserFile } from '@app/file-manager/models/user-file.model';

export function convertMessagesAttachToUserFile(messages: ChatMessageResponse[]): ChatMessage[] {
  return messages.map(convertMessage);
}

function convertMessage(message: ChatMessageResponse): ChatMessage {
  return {
    ...message,
    attached_files: convertFiles(message.attached_files),
    parent: message.parent ? convertMessage(message.parent) : null,
  };
}

function convertFiles(files: AttachedFile[]): UserFile[] {
  return files?.map((file) => new UserFile({ ...file, is_file: true })) ?? [];
}
