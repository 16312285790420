<a
  (click)="goTo($event)"
  [ngbTooltip]="tab.name"
  class="tab-item"
  [class.tab-item--active]="isRouteActive()"
  [ngClass]="{ active: isRouteActive(), disabled: tab.disabled }"
>
  <div class="tab-item__icon">
    <app-svg-icon [name]="tab.icon.name" [width]="tab.icon.width" [height]="tab.icon.height"></app-svg-icon>
  </div>
  <div class="tab-item__name">
    {{ tab.name }}
  </div>
  <span *ngIf="tab.invalid" class="tab-item__invalid" [class.warning]="!isCustomerCard">
    <app-svg-icon name="exclamation-point" width="5" height="16"></app-svg-icon>
  </span>
</a>
