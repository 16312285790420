import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { NotificationsService } from 'angular2-notifications';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UserFile } from '@app/file-manager/models/user-file.model';
import {
  ExportFileManagerType,
  FileManagerExportComponent,
} from '@app/file-manager/file-manager-export/file-manager-export.component';
import { ConfirmationModalComponent } from '@app/shared/components/confirmation-modal/confirmation-modal.component';
import { take } from 'rxjs/operators';
import { FileManagerModalUserInfoComponent } from '@app/file-manager/components/file-manager-modal-user-info/file-manager-modal-user-info.component';
import saveAs from 'file-saver';
import { AuthorFile } from '@app/file-manager/models/author-file.model';
import { TranslateService } from '@ngx-translate/core';
import { FileManagerModalFilePreviewComponent } from '@app/file-manager/components/file-manager-modal-file-preview/file-manager-modal-file-preview.component';
import { AuthService } from '@app/shared/services/auth.service';
import { User } from '@app/shared/models/user.model';
import { TradeCard } from '@app/+trades/models/trades.model';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
})
export class DocumentListComponent implements OnInit, OnChanges {
  @Input() documents: UserFile[] = [];
  @Input() isEdit = false;
  @Input() canChangeAccess = false;
  @Input() readonly = true;
  @Input() canDownload = false;
  @Input() card: TradeCard;

  @Output() onChangeEvent = new EventEmitter<UserFile[]>();
  @Output() onChangeFileEvent = new EventEmitter();

  allRadios = 1;
  user: User;
  offset: number = 0;

  constructor(
    private readonly notify: NotificationsService,
    private readonly modalService: NgbModal,
    private translate: TranslateService,
    private authService: AuthService
  ) {
    this.authService.userStream.pipe(take(1)).subscribe((user) => (this.user = user));
    this.offset = this.authService.getTimezoneOffset();
  }

  ngOnInit(): void {
    this.updateAllRadios();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateAllRadios();
  }

  get documentsCount(): number {
    return this.documents.filter((doc) => doc.url).length;
  }

  get isDisabledDeleteAll(): boolean {
    return this.documents.some((doc) => doc.for_providers) && this.user.id !== this.card.owner.id;
  }

  documentChanged() {
    if (this.readonly) {
      return;
    }
    this.onChangeEvent.emit(this.documents);
  }

  openFileManager() {
    if (!this.isEdit || this.readonly) {
      return;
    }

    const modal = this.modalService.open(FileManagerExportComponent, {
      centered: true,
      animation: true,
      windowClass: 'dc-modal export-file-manager-modal modal-window',
      size: 'xl',
    });

    modal.componentInstance.title = 'Добавить файлы в карточку торгов';
    modal.componentInstance.exportType = ExportFileManagerType.TRADES;
    modal.componentInstance.selectFolders = true;
    modal.componentInstance.addedFiles = this.documents.filter((doc) => doc.url);
    modal.componentInstance.selectMultiple = true;
    modal.componentInstance.subTitle = this.card?.title;
    modal.componentInstance.alwaysShowCancel = true;

    modal.result.then((result) => {
      if (result && result.length) {
        this.handleSelectedExport(result);
      }
    });
  }

  handleSelectedExport(files: UserFile[]) {
    const exists = this.documents.filter((doc) => files.some((file) => doc.url && doc.id === file.id));

    if (exists.length) {
      this.notify.error('Ошибка!', `Данные файлы уже добавлены в КТ: ${exists.map((file) => file.name)}`);
      return;
    }

    files.forEach((file) => {
      file.for_providers = false;
      file.for_customers = true;
      file.created_at = new Date().toString();
      file.link_user = this.user;
    });
    const res = this.documents.concat([...files]);
    if (res.length > 50) {
      this.notify.error(
        'Ошибка!',
        `Вы превысили максимальное количество добавляемых файлов. Максимальное количество - 50 файлов`
      );
      return;
    }
    this.documents = res;
    this.updateAllRadios();
    this.onChangeEvent.emit(this.documents);
  }

  handleDelete(index: number): void {
    const modal = this.modalService.open(ConfirmationModalComponent, {
      centered: true,
      animation: true,
      windowClass: 'dc-modal fileman-modal modal-window',
    });
    modal.componentInstance.title = '';
    modal.componentInstance.text = 'Вы действительно хотите удалить файл из документов к данным торгам?';
    modal.componentInstance.onClose.pipe(take(1)).subscribe((value) => {
      if (value) {
        this.documents.splice(index, 1);
        this.onChangeEvent.emit(this.documents);
      }
    });
  }

  deleteAll(): void {
    const modal = this.modalService.open(ConfirmationModalComponent, {
      centered: true,
      animation: true,
      windowClass: 'dc-modal fileman-modal modal-window',
    });
    modal.componentInstance.title = '';
    modal.componentInstance.text =
      'Вы действительно хотите удалить ВСЕ файлы из документов к данным торгам? <br><br>Отменить это действие будет невозможно!';
    modal.componentInstance.onClose.pipe(take(1)).subscribe((value) => {
      if (value) {
        this.documents = [];
        this.onChangeEvent.emit(this.documents);
      }
    });
  }

  showUserInfo(user: AuthorFile): void {
    const modal = this.modalService.open(FileManagerModalUserInfoComponent, {
      centered: true,
      windowClass: 'dc-modal modal-window dc-modal-user-card',
      animation: true,
    });
    modal.componentInstance.user = user;
    modal.componentInstance.title = 'Контакты';
  }

  download(file: UserFile): void {
    if (file && file.is_file) {
      if (!file.url) {
        this.notify.warn('Внимание!', `Ссылка неактивна`);
        return;
      }
      try {
        saveAs(file.url, file.name);
        this.notify.success('успешно', this.translate.instant('fm_msg_download_success'));
      } catch (e) {
        this.notify.error('внимание', this.translate.instant('fm_msg_download_failure'));
      }
    }
  }

  updateAllRadios(): void {
    if (this.documents.every((document) => document.for_providers)) {
      this.allRadios = 0;
      return;
    }
    if (this.documents.every((document) => document.for_customers)) {
      this.allRadios = 1;
      return;
    }
    this.allRadios = 2;
  }

  onRadioChange(file: UserFile): void {
    file.for_providers = !file.for_providers;
    file.for_customers = !file.for_customers;
    this.updateAllRadios();
    this.documentChanged();
  }

  onAllRadioChange(value: boolean): void {
    if (value) {
      this.documents.forEach((document) => {
        document.for_providers = true;
        document.for_customers = false;
      });
    } else {
      this.documents.forEach((document) => {
        document.for_providers = false;
        document.for_customers = true;
      });
    }
    this.documentChanged();
  }

  openPreview(file: UserFile): void {
    if (!file.url) {
      this.notify.warn('Внимание!', `Ссылка неактивна`);
      return;
    }
    const modal = this.modalService.open(FileManagerModalFilePreviewComponent, {
      centered: true,
      animation: true,
      windowClass: 'dc-modal modal-window',
      size: 'xl',
    });

    modal.componentInstance.files = [new UserFile(file)];
  }
}
