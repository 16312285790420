import { Injectable } from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {ComponentCanDeactivate} from '../classes/component-can-deactivate.class';
import {TradeDataProtectService} from '../../services/trade-data-protect.service';
import {Observable} from 'rxjs';

@Injectable()
export class TradeCardDataProtectorGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(private tradeDataProtectService: TradeDataProtectService) {

  }
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {

    if (component && !component.canDeactivate()) {
      if (this.tradeDataProtectService.goNext) {
        return true;
      } else {
        this.tradeDataProtectService.modalTrigger$.next(true);
        return false;
      }
    }

    return true;
  }
}
