import { Pipe, PipeTransform } from '@angular/core';
import { ROLES, RolesEnum } from '../constants/roles.constants';

@Pipe({
  name: 'roleName',
})
export class RoleNamePipe implements PipeTransform {
  transform(value: RolesEnum, type: 'short' | 'full' | 'small' = 'small'): string {
    if (value === RolesEnum.TSO) {
      return 'ОТП';
    }

    if (value === RolesEnum.DUTY_TSO) {
      return 'дОТП';
    }

    if (value) {
      const role = ROLES.find((r) => r.value === value);

      if (!role) {
        return '';
      }

      switch (type) {
        case 'full': {
          return role.fullTitle;
        }
        case 'small': {
          return role.shortTitle;
        }
        case 'short':
        default: {
          return role.title;
        }
      }
    }
    return '';
  }
}
