<div class="container-fluid pt-4" [class.disabled]="hasSomeLoading() || isLoading">
  <div class="">
    <div class="duty-title-wrapper">
      <h6 class="duty-title">Управление дежурcтвами</h6>
      <span class="icon-link">
        <button class="icon-button">
          <app-svg-icon name="question-circle-color" width="16" height="16"></app-svg-icon>
        </button>
      </span>
    </div>

    <div class="duty-window-wrapper">
      <div class="d-flex">
        <div class="mr-3 calendar-wrapper">
          <span>
            <input id="cbx-showMyDuty" class="cbx-input" [(ngModel)]="showMyDuty" type="checkbox" />
            <label class="cbx-label" for="cbx-showMyDuty">
              <span class="cbx-label-text show-my-duty">Показать мои дежурства</span>
            </label>
          </span>
          <app-schedule
            [viewDate]="viewDate"
            [selectedMonthViewDay]="selectedMonthViewDay"
            [showMyDuty]="showMyDuty"
            [isAgent]="true"
            (onDayClicked)="dayClicked($event)"
            (onBeforeMonthViewRender)="beforeMonthViewRender($event)"
          ></app-schedule>
        </div>
        <div class="table-wrapper">
          <table class="duty-list-table sticky-table">
            <thead>
              <tr>
                <td width="70">
                  <div>Время</div>
                </td>
                <td width="120">
                  <div>Мои дежурства</div>
                </td>
                <td>
                  <span [class.disabled]="isAllTimelineItemsLate() || isAllTimelineItemsMore90()">
                    <input
                      id="cbx-proccessingAll"
                      class="cbx-input"
                      [(ngModel)]="proccessingAll"
                      (change)="toggleAllProccessing()"
                      type="checkbox"
                    />
                    <label
                      class="cbx-label"
                      for="cbx-proccessingAll"
                      [class.cbx-label-interminate]="activePartiallySelected"
                    >
                      <span class="cbx-label-text">Согласования</span>
                    </label>
                  </span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let timeItem of schedule?.timeline; index as i">
                <td width="70">
                  <div
                    class="schedule-timeline-time"
                    [class.process]="hasTimelineProcess(timeItem)"
                    [class.active]="hasTimelineAgreed(timeItem)"
                  >
                    {{ timeItem.timeFormat }}
                  </div>
                </td>
                <td width="120">
                  <div class="d-flex">
                    <span [class.disabled]="true">
                      <input
                        [id]="timeItem.timestamp"
                        class="cbx-input"
                        [(ngModel)]="timeItem.timeChecked"
                        type="checkbox"
                      />
                      <label class="cbx-label text-overflow" [for]="timeItem.timestamp"> </label>
                    </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex">
                    <span
                      *ngIf="!isCheckboxAgent(timeItem)"
                      [class.disabled]="
                        timeItem.timeChecked ||
                        isTimelineItemLate(timeItem, dateOffSet) ||
                        isTimelineItemMore90(timeItem) ||
                        isDisabled(timeItem)
                      "
                    >
                      <input
                        [id]="timeItem.timestamp + dutyStatus.PROCESS + i"
                        class="cbx-input"
                        [(ngModel)]="timeItem.processTimeChecked"
                        (change)="updateAllTimeline()"
                        type="checkbox"
                      />
                      <label class="cbx-label" [for]="timeItem.timestamp + dutyStatus.PROCESS + i">
                        <span class="cbx-label-text">{{ getDutyText(timeItem) }}</span>
                      </label>
                    </span>
                    <span *ngIf="isCheckboxAgent(timeItem)" [class.disabled]="true">
                      <input
                        [id]="timeItem.timestamp + dutyStatus.PROCESS + i + 'agent'"
                        class="cbx-input"
                        [checked]="true"
                        type="checkbox"
                      />
                      <label class="cbx-label" [for]="timeItem.timestamp + dutyStatus.PROCESS + i + 'agent'">
                        <span class="cbx-label-text">
                          {{ getDutyText(timeItem) }}
                        </span>
                      </label>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="accept-duty-list">
          <table class="duty-list-table sticky-table">
            <thead>
              <tr class="duty-list-header">
                <td width="120">
                  <div>Запрашиваемые дежурства</div>
                </td>
                <td width="220">
                  <div>Запрос АТП на дежурство</div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="!dutyOffersFromAtp.length">
                <td colspan="2">Нет запрашиваемых дежурств</td>
              </tr>
              <tr class="duty-list-row" *ngFor="let dutyOffer of dutyOffersFromAtp; index as i">
                <td width="120">
                  <span
                    >{{ dutyOffer.start_date | date : 'dd.MM.yyyy' }} в
                    {{ dutyOffer.start_date | date : 'HH:mm' }}</span
                  >
                </td>
                <td width="220">
                  <div class="duty-input-list">
                    <div class="dc-radio-input radio-button">
                      <input
                        [id]="'duty-atp-accept' + i"
                        [value]="'accept'"
                        [name]="'control' + i"
                        type="radio"
                        [(ngModel)]="dutyOffer.acceptCheck"
                      />
                      <label class="radio-label" [for]="'duty-atp-accept' + i">Потдвердить</label>
                    </div>
                    <div class="dc-radio-input radio-button">
                      <input
                        [id]="'duty-atp-remove' + i"
                        [value]="'remove'"
                        [name]="'control' + i"
                        type="radio"
                        [(ngModel)]="dutyOffer.removeCheck"
                      />
                      <label class="radio-label" [for]="'duty-atp-remove' + i">Отклонить</label>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="d-flex duty-buttons">
        <button class="btn btn-dc-outline" (click)="close()">Отменить</button>&nbsp;
        <button class="btn btn-dc-primary" [disabled]="!hasProcessChecked()" (click)="sendProcess()">Подтвердить</button
        >&nbsp;
      </div>
    </div>
  </div>
</div>
