import { Pipe, PipeTransform } from '@angular/core';
import { ChatUserTreeWithNode } from '../models/chat.model';
import { RolesEnum } from '@app/shared/constants/roles.constants';

@Pipe({
  name: 'techChatUsersSort',
  pure: false,
})
export class TechChatUsersSortPipe implements PipeTransform {
  transform(value: ChatUserTreeWithNode[], ownerId: number): ChatUserTreeWithNode[] {
    const owner = value.filter((item) => item.id === ownerId);
    const dutyTsos = value
      .filter((item) => item.type === RolesEnum.DUTY_TSO && item.id !== ownerId)
      .sort((a, b) => a.second_name.localeCompare(b.second_name));
    const tsos = value
      .filter((item) => item.type === RolesEnum.TSO && item.id !== ownerId)
      .sort((a, b) => a.second_name.localeCompare(b.second_name));
    const othersUser = value
      .filter((item) => item.type !== RolesEnum.DUTY_TSO && item.type !== RolesEnum.TSO)
      .sort((a, b) => a.second_name.localeCompare(b.second_name));

    return [...owner, ...dutyTsos, ...tsos, ...othersUser];
  }
}
