import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CriteriaTypesEnum } from '@app/+trades/constants/lots.constants';
import { dateValidator, maxDateValidator, minDateValidator } from '@app/+trades/shared/validators/criteria.validators';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-supplier-lot-criteria-view',
  templateUrl: './supplier-lot-criteria-view.component.html',
  styleUrls: ['./supplier-lot-criteria-view.component.scss'],
})
export class SupplierLotCriteriaViewComponent implements OnInit {
  @Input() control: FormControl<string | boolean | null>;
  @Input() type: CriteriaTypesEnum;
  @Input() readonly: boolean;
  @Input() withCopy: boolean;
  @Input() min: string;
  @Input() max: string;

  @Output() copy = new EventEmitter<void>();

  criteriaTypesEnum = CriteriaTypesEnum;
  showCopyMenu: boolean = false;
  oldValue: string | boolean;

  get isDisableCopy() {
    return (
      this.control?.invalid ||
      this.control?.value === null ||
      this.control?.value === undefined ||
      this.control?.value === ''
    );
  }

  constructor(private notify: NotificationsService) {}

  ngOnInit() {
    if (this.type === CriteriaTypesEnum.DATES) {
      this.control.setValidators([dateValidator(), minDateValidator(this.min), maxDateValidator(this.max)]);
      this.control.updateValueAndValidity();
    }
  }

  onCopyClick() {
    this.copy.emit();
    this.showCopyMenu = false;
  }

  setValue(value: boolean) {
    this.control.setValue(value);
    this.control.markAsDirty();
  }

  change() {
    if (
      this.control.invalid &&
      (this.control.errors?.maxValue || this.control.errors?.minValue || this.control.errors?.invalidDate)
    ) {
      this.control.setValue(this.oldValue);
      this.notify.error(
        'Ошибка!',
        'Введенное значение выходит за рамки заданного диапазона, указанного в примечании. Введите пожалуйста корректное значение'
      );
    }
  }

  showCopy(event: MouseEvent) {
    event.stopPropagation();
    this.showCopyMenu = true;
  }

  isControlFilled() {
    return !(this.control.value === null || this.control.value === undefined);
  }
}
