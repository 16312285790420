import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AdditionalFilters } from '@app/+competence-map/models/suppliers-selection.model';

@Component({
  selector: 'app-additional-filters-template',
  templateUrl: './additional-filters-template.component.html',
  styleUrls: ['./additional-filters-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionalFiltersTemplateComponent {
  @Input() additionalFilters: AdditionalFilters;
}
