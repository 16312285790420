import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@app/shared/shared.module';
import { SVGSpriteModule } from '@app/svg-sprite/svg-sprite.module';

import { FileManagerSearchComponent } from './file-manager-search.component';

@NgModule({
  declarations: [FileManagerSearchComponent],
  exports: [FileManagerSearchComponent],
  imports: [CommonModule, ReactiveFormsModule, SharedModule, SVGSpriteModule],
})
export class FileManagerSearchModule {}
