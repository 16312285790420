import { Pipe, PipeTransform } from '@angular/core';
import { UserTypes } from '@app/shared/types/user.types';
import { getShortTitleRole, getTitleRole } from '@app/shared/utils';
import { RolesEnum } from '../constants/roles.constants';

@Pipe({
  name: 'titleRole',
})
export class TitleRolePipe implements PipeTransform {
  transform(type: UserTypes, isShort: boolean = false): string {
    if (type === RolesEnum.TSO) {
      return 'ОТП';
    }
    if (type === RolesEnum.DUTY_TSO) {
      return 'дОТП';
    }
    return isShort ? getShortTitleRole(type) : getTitleRole(type);
  }
}
