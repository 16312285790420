import { Component, ElementRef, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TradeCard, TradesRequestConfig } from '@app/+trades/models/trades.model';
import { TradeColumnsKeys } from '@app/+trades/constants/trades-table.constants';
import { DatesHelper } from '@app/shared/helpers/dates.helper';
import {
  AccessRights,
  ReasonEndTradeEnum,
  TRADE_CANCEL_LABELS,
  TRADE_STATUS_LABELS,
  TradeCardStatuses,
} from '@app/+trades/constants/trades.constants';
import { INVITE_SUPPLIERS_LABELS, InviteSupplierStatuses } from '@app/+trades/constants/suppliers.constants';
import { SupplierTradeCard } from '@app/+trades/models/suppliers.model';
import { Column } from '@app/shared/models/table';
import { TradesService } from '@app/+trades/services/trades.service';
import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from '@app/shared/constants/date.constants';

@Component({
  selector: 'app-trade-grid-cell',
  templateUrl: './trade-grid-cell.component.html',
  styleUrls: ['./trade-grid-cell.component.scss'],
})
export class TradeGridCellComponent implements OnChanges {
  @Input() row: TradeCard | SupplierTradeCard;
  @Input() value: any;
  @Input() column: Column<TradesRequestConfig> = <Column<TradesRequestConfig>>{};
  @Input() timeZoneOffset: number;
  @Input() timeZoneName: string;
  @Input() container: ElementRef;
  @Input() body: ElementRef;

  @Output() onClickCell = new EventEmitter<{
    column: Column<TradesRequestConfig>;
    row: TradeCard | SupplierTradeCard;
  }>();
  @Output() onSaveIconClick = new EventEmitter<TradeCard | SupplierTradeCard>();

  tradeColumnKeys = TradeColumnsKeys;
  tradeStatusLabels = TRADE_STATUS_LABELS;
  inviteSuppliersLabels = INVITE_SUPPLIERS_LABELS;
  tradeCancelLabels = TRADE_CANCEL_LABELS;
  inviteSupplierStatuses = InviteSupplierStatuses;
  reasonsEndTrade = ReasonEndTradeEnum;

  isArray = Array.isArray;
  tradeCardStatuses = TradeCardStatuses;

  hasSuggestion = this.tradesService.hasSuggestion;
  isAcceptedTradeInvitation = this.tradesService.isAcceptedTradeInvitation;

  protected readonly TradeCardStatuses = TradeCardStatuses;

  constructor(private tradesService: TradesService) {}

  ngOnChanges() {
    if (this.column instanceof Object) {
      if (
        (this.column.id === TradeColumnsKeys.PROJECT_DATE ||
          this.column.id === TradeColumnsKeys.PRETRADE_DATE ||
          this.column.id === TradeColumnsKeys.REQ_DATA_STATE) &&
        this.value
      ) {
        this.value = DatesHelper.formatDate(this.value);
      }

      if (
        this.value &&
        (this.column.id === TradeColumnsKeys.TRADE_DATE || this.column.id === TradeColumnsKeys.ARCHIVE_DATE)
      ) {
        this.value = DatesHelper.formatDateTime(this.value);
      }
    }
  }

  clickSaveIcon(event: MouseEvent) {
    event.stopPropagation();
    this.onSaveIconClick.emit(this.row);
  }

  clickCell() {
    this.onClickCell.emit({
      column: this.column,
      row: this.row,
    });
  }

  isHighlightStartDate(value: string) {
    if (!value) {
      return false;
    }

    const startDateUtc = moment.utc(value, `${DATE_FORMAT} ${TIME_FORMAT}`);
    return (
      !this.isArhive() &&
      DatesHelper.isBetweenPeriod(moment.utc(), moment(startDateUtc).subtract(24, 'h'), startDateUtc, 'minutes', '[]')
    );
  }

  isHighlightDateCell(value: string) {
    if (!value) {
      return false;
    }

    const periodEndDate = moment.utc(value, `${DATE_FORMAT} ${TIME_FORMAT}`);
    const periodStartDate = moment(periodEndDate).subtract(24 * 60, 'minutes');

    return (
      !this.isArhive() && DatesHelper.isBetweenPeriod(moment.utc(), periodStartDate, periodEndDate, 'minutes', '[]')
    );
  }

  isArhive() {
    return this.row.status === TradeCardStatuses.ARCHIVE || this.row.status_trade === TradeCardStatuses.ARCHIVE;
  }
}
