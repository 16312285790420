<div class="container-fluid padding-r-20 padding-l-20">
  <div class="row align-items-center margin-b-16">
    <div class="col-8">
      <div class="header1">{{ isSupplier ? 'Торги поставщика' : 'Торги заказчика' }}</div>
    </div>
    <div class="col-4 d-flex justify-content-end">
      <button
        class="dc-toolbar-btn mr-3"
        (click)="showFilterModal()"
        ngbTooltip="Настройки визуализации"
        placement="top"
        container="body"
      >
        <app-svg-icon name="cog" height="18" width="18"></app-svg-icon>
      </button>
      <button
        class="dc-toolbar-btn mr-3"
        (click)="isTableHelperOpen = true"
        ngbTooltip="Справка"
        container="body"
        placement="top"
      >
        <app-svg-icon name="question" height="18" width="18"></app-svg-icon>
      </button>
      <button class="dc-toolbar-btn mr-3" (click)="print()" ngbTooltip="Печать" container="body" placement="top">
        <app-svg-icon name="print" height="18" width="18"></app-svg-icon>
      </button>
      <button
        class="dc-toolbar-btn"
        (click)="clickDownloadPdf()"
        ngbTooltip="Сохранить в файл"
        container="body"
        placement="left"
      >
        <app-svg-icon name="save" height="18" width="18"></app-svg-icon>
      </button>
    </div>
  </div>
  <div *ngIf="!isSupplier" class="row margin-b-20">
    <div class="col d-flex align-items-center">
      <a class="blue-links create-card-btn margin-r-30" [routerLink]="['create-card']">
        <app-svg-icon name="plus" height="16" width="16"></app-svg-icon>
        <span>Карточку торгов</span>
      </a>
      <a
        class="blue-links create-card-btn"
        [routerLink]="['create-card']"
        [queryParams]="{ status: tradeCardStatusesEnum.TEMPLATE }"
      >
        <app-svg-icon name="plus" height="16" width="16"></app-svg-icon>
        <span>Шаблон карточки торгов</span>
      </a>
    </div>
  </div>
</div>

<app-modal-window
  [isShowed]="isTradeGridOptionsModalShowed"
  (closeEvent)="closeOptionsModal()"
  customClass="grid-options-modal"
>
  <div class="modal-header">
    <h5>Настройка визуализации</h5>
    <app-svg-icon
      class="blue-links"
      container="body"
      placement="top"
      ngbTooltip="Справка"
      name="question-circle-color"
      height="16"
      width="16"
      (click)="isSettingsHelperOpen = true"
    ></app-svg-icon>
  </div>
  <div class="grid-options-modal-layout">
    <div class="modals-item">
      <div class="modals-item__header">
        <h6>Столбцы</h6>
        <ng-container
          [ngTemplateOutlet]="resetLink"
          [ngTemplateOutletContext]="{ action: resetColumns.bind(this), disabled: isAllColumnsChecked }"
        ></ng-container>
      </div>
      <div class="modals-item__list">
        <div class="all-checkbox">
          <input
            class="cbx-input"
            id="checkedAllColumns"
            name="checkedAllColumns"
            type="checkbox"
            (change)="toggleAllColumns(!isAllColumnsChecked)"
            [ngModel]="isAllColumnsChecked"
          />
          <label class="cbx-label" for="checkedAllColumns">
            <span class="cbx-label-text">Выделить всё/снять выделение</span>
          </label>
        </div>
        <ul class="list-unstyled">
          <li *ngFor="let column of columns">
            <input
              class="cbx-input"
              [id]="column.id"
              name="{{ column.id }}"
              type="checkbox"
              [checked]="!column.hidden"
              (change)="columnStateChange(column)"
              [disabled]="isColumnDisabled(column.id)"
            />
            <label class="cbx-label" [for]="column.id">
              <span class="cbx-label-text">{{ column.name | brToSpace }} </span>
            </label>
          </li>
        </ul>
      </div>
    </div>

    <div class="modals-item">
      <div class="modals-item-header"><h6>Сортировка</h6></div>

      <div class="options">
        <ng-container *ngIf="tradeStatuses.length">
          <div class="options-item">
            <div class="options-item__header">
              <div class="options-item__title">
                <span>По статусам торгов:</span>
                <app-form-checkbox-switch
                  [formControl]="isTradeStatusSortingEnabled"
                  [withLabels]="false"
                ></app-form-checkbox-switch>
              </div>
              <ng-container
                [ngTemplateOutlet]="resetLink"
                [ngTemplateOutletContext]="{
                  action: resetTradeStatuses.bind(this),
                  disabled: !isTradeStatusSortingEnabled.value || isTradeStatusesDefault
                }"
              ></ng-container>
            </div>

            <ul
              class="list-unstyled list-paddings statuses-sorting"
              [class.disabled]="!isTradeStatusSortingEnabled.value"
            >
              <li *ngFor="let status of tradeStatuses; let i = index">
                <div class="statuses-sorting__arrows">
                  <app-svg-icon
                    class="blue-links small"
                    [class.disabled]="i === tradeStatuses.length - 1"
                    name="caret-square-down"
                    height="16"
                    width="16"
                    (click)="moveTradeStatus($event, i, 'down', ChangeKey.TradeStatusesSort)"
                  ></app-svg-icon>
                  <app-svg-icon
                    class="blue-links small"
                    [class.disabled]="i === 0"
                    name="caret-square-up"
                    height="16"
                    width="16"
                    (click)="moveTradeStatus($event, i, 'up', ChangeKey.TradeStatusesSort)"
                  ></app-svg-icon>
                </div>
                <span class="statuses-sorting__label">{{ status.name }}</span>
              </li>
            </ul>
          </div>
        </ng-container>

        <ng-container *ngIf="inviteStatuses.length">
          <div class="options-item">
            <div class="options-item__header">
              <div class="options-item__title">
                <span>По статусу поставщика:</span>
                <app-form-checkbox-switch
                  [formControl]="isInviteStatusSortingEnabled"
                  [withLabels]="false"
                ></app-form-checkbox-switch>
              </div>
              <ng-container
                [ngTemplateOutlet]="resetLink"
                [ngTemplateOutletContext]="{
                  action: resetInviteStatuses.bind(this),
                  disabled: !isInviteStatusSortingEnabled.value || isInviteStatusesDefault
                }"
              ></ng-container>
            </div>

            <ul
              class="list-unstyled list-paddings statuses-sorting"
              [class.disabled]="!isInviteStatusSortingEnabled.value"
            >
              <li *ngFor="let status of inviteStatuses; let i = index">
                <div class="statuses-sorting__arrows">
                  <app-svg-icon
                    class="blue-links small"
                    [class.disabled]="i === inviteStatuses.length - 1"
                    name="caret-square-down"
                    height="16"
                    width="16"
                    (click)="moveInvitedStatus($event, i, 'down', ChangeKey.InvitedStatusesSort)"
                  ></app-svg-icon>
                  <app-svg-icon
                    class="blue-links small"
                    [class.disabled]="i === 0"
                    name="caret-square-up"
                    height="16"
                    width="16"
                    (click)="moveInvitedStatus($event, i, 'up', ChangeKey.InvitedStatusesSort)"
                  ></app-svg-icon>
                </div>
                <span class="statuses-sorting__label no-pointer">
                  {{ status.name }}
                </span>
              </li>
            </ul>
          </div>
        </ng-container>

        <div class="options-item">
          <div class="options-item__header">
            <div class="options-item__title">По дате:</div>
            <ng-container
              [ngTemplateOutlet]="resetLink"
              [ngTemplateOutletContext]="{
                action: resetTradeCardDate.bind(this),
                disabled: selectedTradeCardDate.date === tradeCardDates[0].date
              }"
            ></ng-container>
          </div>

          <ul class="list-unstyled">
            <li *ngFor="let item of tradeCardDates" class="radio">
              <input
                class="hidden-xs-up"
                type="radio"
                id="sorting{{ item.date }}"
                name="sorting{{ item.date }}"
                [value]="item.date"
                [checked]="selectedTradeCardDate.date === item.date"
                (change)="changeTradeDate(item)"
              />
              <label for="sorting{{ item.date }}" class="radio-label">{{ item.name }}</label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="dc-footer-buttons">
      <button class="btn btn-dc-outline" [disabled]="!isFormChanged" (click)="cancelChanges()">Отменить</button>
      <button class="btn btn-dc-primary" [disabled]="!isFormChanged" (click)="saveOptions()">Сохранить</button>
    </div>
  </div>
</app-modal-window>

<ng-template #resetLink let-action="action" let-disabled="disabled">
  <div class="reset-link blue-links" [class.disabled]="disabled" (click)="action()">
    <app-svg-icon name="times" height="16" width="16"></app-svg-icon>
    <span>Сбросить</span>
  </div>
</ng-template>

<app-modal-window
  [isShowed]="isTableHelperOpen || isSettingsHelperOpen"
  backdrop="static"
  (closeEvent)="isTableHelperOpen = isSettingsHelperOpen = false"
>
  <div class="modal-header modal-reference-header">
    <div class="modal-title">Справка</div>
  </div>
  <div class="modal-body modal-reference-body">
    <ng-container *ngIf="isTableHelperOpen">
      <!-- TODO: Add table help content -->
    </ng-container>
    <ng-container *ngIf="isSettingsHelperOpen">
      <!-- TODO: Add settings help content -->
    </ng-container>
  </div>
  <div class="modal-footer modal-reference-footer">
    <div class="dc-footer-buttons">
      <button class="btn btn-dc-outline" (click)="isTableHelperOpen = isSettingsHelperOpen = false">Закрыть</button>
    </div>
  </div>
</app-modal-window>
