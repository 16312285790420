<div (click)="cancel()" class="dc-fileman-modal-x-button">
  <app-svg-icon [width]="20" [height]="20" name="close"></app-svg-icon>
</div>
<div class="modal-header file-manager-modal-upload__header">
  <div class="file-manager-modal-upload__title">{{ isChatUpload ? 'Прикрепить' : 'Загрузить' }} файлы</div>
  <div *ngIf="folderName" class="file-manager-modal-upload__subtitle">в папку "{{ folderName }}"</div>
  <div *ngIf="tableName" class="file-manager-modal-upload__subtitle">в отчет "{{ tableName }}"</div>
</div>
<div class="modal-body file-manager-modal-upload__body">
  <div class="file-manager-modal-upload__file-container">
    <label for="file-upload-input" class="file-manager-modal-upload__file-container_label">
      Перетащите сюда файлы для загрузки
      <input
        #inputUpload
        id="file-upload-input"
        class="file-manager-modal-upload__file-container_input"
        type="file"
        (change)="upload($event)"
        (click)="clearFile($event)"
        multiple
      />
    </label>
  </div>
  <div class="file-manager-modal-upload__hint">
    <div class="file-manager-modal-upload__hint_size">
      Размер одновременно закачиваемых файлов не более {{ isChatUpload ? '5' : '100' }} Mb.
    </div>
    <div *ngIf="!isAccrualsTable" class="file-manager-modal-upload__hint_types">
      Доступные расширения: txt, doc, docx, xls, xlsx, pdf, png, jpg и
      <span
        container="body"
        triggers="mouseenter:mouseleave"
        placement="bottom"
        ngbTooltip="csv, rtf, bmp, odt, ods, odp, jpeg, gif"
        animation="true"
        [openDelay]="openDelayTooltip"
      >
        ещё 8
      </span>
    </div>
    <div *ngIf="isAccrualsTable" class="file-manager-modal-upload__hint_types">Доступные расширения: xls, xlsx</div>
  </div>

  <div class="file-manager-modal-upload__days-log">
    <div class="file-manager-modal-upload__days-log_title">
      {{ isChatUpload ? 'Журнал загрузки' : 'Журнал загрузок за последние сутки' }}
      <span *ngIf="!isChatUpload" class="file-manager-modal-upload__days-log_arrow" (click)="toggleLog()">
        <app-svg-icon [width]="12" [height]="14" [name]="isLogOpen ? 'd-arrow-up' : 'd-arrow-down'"></app-svg-icon>
      </span>
    </div>
    <div *ngIf="isLogOpen" class="file-manager-modal-upload__days-log_content">
      <div class="days-log-item" *ngFor="let legend of uploadedMedia">
        <div class="days-log-item__title">
          {{ legend?.fileName }}
        </div>

        <div class="days-log-item__upload">
          <div class="days-log-item__upload-container" [class.days-log-item__upload-container_error]="legend.error">
            <div
              class="days-log-item__upload-progress"
              [class.days-log-item__upload-progress-error]="legend.error"
              [style.width.%]="legend.error ? 100 : legend?.fileProgress"
            ></div>
          </div>
          <app-svg-icon
            *ngIf="!legend.ready"
            name="close-search"
            [width]="13"
            [height]="13"
            class="days-log-item__upload-cancel"
            (click)="abort($event, legend)"
          ></app-svg-icon>
          <div class="days-log-item__upload-label" [class.days-log-item__upload-label-error]="legend.error">
            <span *ngIf="legend.error">{{ legend.error }}</span>
            <span *ngIf="!legend.error">{{ legend?.fileProgress }}%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer file-manager-modal-upload__footer">
  <div class="dc-footer-buttons">
    <button class="btn btn-dc-outline" (click)="cancel()">Отменить</button>
    <button class="btn btn-dc-primary" (click)="inputUpload.click()">Выбрать файлы</button>
  </div>
</div>
