import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TradesRequestConfig } from '@app/+trades/models/trades.model';
import { Column } from '@app/shared/models/table';
import { DatesHelper } from '@app/shared/helpers/dates.helper';
import { TradeColumnsKeys } from '@app/+trades/constants/trades-table.constants';
import moment from 'moment';
import { map, takeUntil } from 'rxjs/operators';
import { AuthService } from '@app/shared/services/auth.service';
import { DestroyService } from '@app/services/destroy.service';
import { TIMEZONES } from '@app/shared/constants/date.constants';
import { TradeCompetency, TreeSectionsAndFilters } from '@app/+competence-map/models/user-competency.model';
import { User } from '@app/shared/models/user.model';
import { ActivatedRoute } from '@angular/router';
import { TradeCardStatuses } from '@app/+trades/constants/trades.constants';

interface TradeCardGridRow {
  competenciesNames?: string[];
  competenceIds?: number[];
  competence_sections?: TradeCompetency[];
}

@Component({
  selector: 'app-trade-card-grid',
  templateUrl: './trade-card-grid.component.html',
  styleUrls: ['./trade-card-grid.component.scss'],
  providers: [DestroyService],
})
export class TradeCardGridComponent<T extends TradeCardGridRow> {
  @Input() columns: Column<TradesRequestConfig>[];
  @Input() filteredRows: T[];
  @Input() isLoading = false;

  public goods: TreeSectionsAndFilters[];
  public services: TreeSectionsAndFilters[];
  tradeColumnKeys = TradeColumnsKeys;
  timeZoneOffset: number;
  timeZoneName: string;
  userId: string;

  @Output() onClickCell = new EventEmitter<{
    column: Column<TradesRequestConfig>;
    row: T;
  }>();
  @Output() onSaveIconClick = new EventEmitter<T>();
  @Output() onSaveFilter = new EventEmitter<Column<TradesRequestConfig>>();

  constructor(private authService: AuthService, private destroy$: DestroyService, private route: ActivatedRoute) {
    this.goods = this.route.snapshot.data.goods;
    this.services = this.route.snapshot.data.services;
    this.userId = this.authService.user_id;

    this.authService.userStream
      .pipe(
        map((user: User) => user.timezone_offset),
        takeUntil(this.destroy$)
      )
      .subscribe((offset) => {
        this.timeZoneOffset = +offset;
        this.timeZoneName = TIMEZONES.find((item) => item.value === +offset).name;
      });
  }

  clickCell(event) {
    this.onClickCell.emit({
      column: event.column,
      row: event.row,
    });
  }

  saveIconClick(value) {
    this.onSaveIconClick.emit(value);
  }

  saveFilter(column: Column<TradesRequestConfig>) {
    this.onSaveFilter.emit(column);
  }

  isRowAccent(start: string, end: string, status: string): boolean {
    if (!start || !end) {
      return false;
    }

    return (
      status !== TradeCardStatuses.ARCHIVE &&
      DatesHelper.isBetweenPeriod(
        moment.utc(),
        moment.utc(start).subtract(24 * 60, 'minutes'),
        moment.utc(end),
        'minutes',
        '[]'
      )
    );
  }
}
