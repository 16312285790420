import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { environment } from 'environments/environment';

const email_regex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const USER_TYPES = ['partner', 'admin_of_user', 'admin_of_direction', 'operator', 'accountant', 'expert', 'superuser'];
const num_reg = /^(\s*|\d+)$/;

export class Validation {
  static validateEmail(email = ''): Array<string> {
    const errors = [];
    if (!email_regex.test(email)) errors.push('Невалидный email');
    return errors;
  }

  static validatePassword(password = '', checkRequirements = false): Array<string> {
    const errors = [];
    if (password.length < 1) errors.push('Пароль не должен быть пустым.');
    else if (password.length < 6) errors.push('Пароль должен быть не меньше 6 символов');
    else if (password.length > 255) errors.push('Пароль не должно превышать 255 симполов.');
    else if (checkRequirements && !password.match(/^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]/))
      errors.push('Пароль должен содержать хотя бы одну заглавную букву и хотя бы одну цифру.');
    return errors;
  }

  static ValidateField(password = '', name = ''): Array<string> {
    const errors = [];
    if (password.length < 1) errors.push(`Поле ${name} не должно быть пустое.`);
    if (password.length > 255) errors.push(`Поле ${name} не должно превышать 255 символов.`);
    return errors;
  }

  static equalsPasswords(password = '', password_double = ''): Array<string> {
    return password !== password_double ? ['Пароли не совпадают.'] : [];
  }

  static ValidateEmail(email: string): Object {
    const errors = <any>{};
    const email_errors = Validation.validateEmail(email);
    if (email_errors.length) errors.email = email_errors;
    return errors;
  }

  static ValidatePhones(phones: Array<string>): Array<string> {
    const errors = [];
    const noPhones = phones.filter((phone) => phone.length).length;
    if (noPhones === 0) errors.push('Задайте телефон');
    return errors;
  }

  static ValidateLogin(email: string, password: string, captcha_enabled?: boolean, captcha_token?: string): Object {
    const errors = <any>{};
    const email_errors = Validation.validateEmail(email);
    const pass_errors = [];
    if (password.length < 1) pass_errors.push('Пароль не должен быть пустым.');

    if (email_errors.length) errors.email = email_errors;
    if (pass_errors.length) errors.password = pass_errors;
    if (captcha_enabled && !environment.skip_captcha && !captcha_token) errors.captcha = ['Необходимо разгадать капчу'];

    return errors;
  }

  static ValidateRegister(
    email: string,
    password: string,
    password_double: string,
    captcha_token: string,
    tso_id: string
  ): Object {
    const errors = <any>{};
    const email_errors = Validation.validateEmail(email);
    const pass_errors = Validation.validatePassword(password, true);
    const pass_equals_errors = Validation.equalsPasswords(password, password_double);
    const tso_id_errors = Validation.validateTsoId(tso_id);

    if (email_errors.length) errors.email = email_errors;
    if (pass_errors.length) errors.password = pass_errors;
    if (pass_equals_errors.length) errors.password_double = pass_equals_errors;
    if (!environment.skip_captcha && !captcha_token) errors.captcha = ['Необходимо разгадать капчу'];
    if (tso_id_errors.length) errors.tso_id = tso_id_errors;

    return errors;
  }

  static validateTsoId(id = ''): Array<string> {
    const errors = [];
    if (!num_reg.test(id)) errors.push('Введите числовое значение');
    if (+id < 0) errors.push('Значение должно быть положительным');
    if (id.length > 255) errors.push('Поле не должно превышать 255 символов.');
    return errors;
  }

  static validateTariff(tariff): Object {
    const errors = <any>{};
    const name_errors = Validation.ValidateField(tariff.name, 'название');
    const payment_errors = Validation.ValidateField(tariff.payment, 'оплата');
    const discount_errors = Validation.ValidateField(tariff.discount.value, 'скидка');
    let bonus_bargain_errors = Validation.ValidateField(tariff.bonus_bargain.one_year.value);
    bonus_bargain_errors.push(...Validation.ValidateField(tariff.bonus_bargain.two_year.value));
    bonus_bargain_errors.push(...Validation.ValidateField(tariff.bonus_bargain.three_year.value));
    bonus_bargain_errors.push(...Validation.ValidateField(tariff.bonus_bargain.other_years.value));
    bonus_bargain_errors = bonus_bargain_errors.filter((error) => error.length).slice(0, 1);
    let bonus_bargain_partner_errors = Validation.ValidateField(tariff.bonus_bargain_partner.one_year.value);
    bonus_bargain_partner_errors.push(...Validation.ValidateField(tariff.bonus_bargain_partner.two_year.value));
    bonus_bargain_partner_errors.push(...Validation.ValidateField(tariff.bonus_bargain_partner.three_year.value));
    bonus_bargain_partner_errors.push(...Validation.ValidateField(tariff.bonus_bargain_partner.other_years.value));
    bonus_bargain_partner_errors = bonus_bargain_partner_errors.filter((error) => error.length).slice(0, 1);
    const bonus_bargain_client_errors = Validation.ValidateField(tariff.bonus_bargain_client.value);
    const tax_errors = Validation.ValidateField(tariff.tax, 'налог');

    if (name_errors.length) errors.name = name_errors;
    if (payment_errors.length) errors.payment = payment_errors;
    if (discount_errors.length) errors.discount = discount_errors;
    if (bonus_bargain_errors.length) errors.bonus_bargain = bonus_bargain_errors;
    if (bonus_bargain_partner_errors.length) errors.bonus_bargain_partner = bonus_bargain_partner_errors;
    if (bonus_bargain_client_errors.length) errors.bonus_bargain_client = bonus_bargain_client_errors;
    if (tax_errors.length) errors.tax = tax_errors;
    return errors;
  }

  static validateCaptcha(captcha_token: string) {
    const errors: string[] = [];

    if (!environment.skip_captcha && !captcha_token) errors.push('Необходимо разгадать капчу');

    return errors;
  }

  static reactiveEqualsPasswords(passwordControlName: string, passwordDoubleControlName: string): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const password = formGroup.get(passwordControlName)?.value;
      const passwordDouble = formGroup.get(passwordDoubleControlName)?.value;

      if (password !== passwordDouble) {
        const errors = { passwordsMismatch: ['Пароли не совпадают.'] };
        formGroup.get(passwordDoubleControlName).setErrors(errors);
        return errors;
      }

      return null;
    };
  }

  static reactivePasswordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const errors = Validation.validatePassword(control.value, true);
      return errors.length ? { errors } : null;
    };
  }

  static reactiveTsoIdValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const errors = Validation.validateTsoId(control.value);
      return errors.length ? { errors } : null;
    };
  }
}
