import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';

import moment from 'moment';

import { of, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { User } from '@app/shared/models/user.model';
import { CompanyCreate, CompanyUpdate } from '@app/shared/models/company.model';
import { RESIDENCES } from '@app/shared/constants/residences.constants';
import { LEGAL_STATUSES } from '@app/shared/constants/legal-statuses.constants';
import { ResidencesEnum, ResidencesTypes } from '@app/shared/types/residences.types';
import { LegalStatusesEnum, LegalStatusesTypes } from '@app/shared/types/legal-statuses.types';
import { UserTypes } from '@app/shared/types/user.types';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { ProfileModalBaseOptions } from '@app/profile/constants/profile.modal.constants';
import { Address } from '@app/shared/models/location.model';

import { FormHelper } from '@app/shared/helpers/form.helper';
import { DatesHelper } from '@app/shared/helpers/dates.helper';

import { AuthService } from '@app/shared/services/auth.service';
import { FilesService } from '@app/shared/services/files.service';
import { PersonalDataService } from '@app/shared/services/personal-data.service';

import { PreviewContentModalComponent } from '@app/profile/shared/components/preview-content-modal/preview-content-modal.component';
import { TextUpperCasePipe } from '@app/shared/pipes/text-upper-case.pipe';
import { FORM_VALIDATOR_MESSAGES } from '@app/shared/constants/form.constants';

const BANK_TABS = [
  { title: 'Банковский счёт', value: 'bank_account' },
  { title: 'Банковская карта', value: 'bank_card' },
];

const ADDRESS_FIELDS = [
  'registration_address_postcode',
  'registration_address',
  'postal_address_postcode',
  'postal_address',
];

const PASSPORT_FIELDS = [
  'passport_series',
  'passport_number',
  'passport_date',
  'passport_division_code',
  'passport_issued_by',
];

const CHECK_RULES_FIELDS = ['checkRules'];

const CHECK_CONTRACT_FIELDS = ['checkContract'];

const AUTHORIZED_PERSON_FIELDS = [
  'authorized_person_second_name',
  'authorized_person_first_name',
  'authorized_person_patronymic',
  'position',
  'authority_basis',
];

const BANKCARD_FIELDS = ['bankcard_number', 'bankcard_bank_name'];

const BANK_ACCOUNT_FIELDS = ['bank_name', 'bank_bic', 'bank_corresponding_account', 'bank_current_account'];

const BANK_ACCOUNT_FIELDS_OTHER = ['bank_name', 'swift', 'iban', 'bank_current_account'];

const CERTIFICATE_FIELDS = ['certificate_number', 'certificate_date'];

const ITN_FIELDS = ['itn'];

const SNILS_FIELDS = ['snils'];

const PSRNSP_FIELDS = ['psrnsp'];

const IEC_FIELDS = ['iec'];

const PSRN_FIELDS = ['psrn'];

const BIRTHDAY_FIELDS = ['birthday'];

const ORGANIZATION_FIELDS = ['organization_type', 'name'];

@Component({
  selector: 'app-profile-contract',
  templateUrl: './profile-contract.component.html',
  styleUrls: ['./profile-contract.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [style({ opacity: '0' }), animate('300ms 100ms', style({ opacity: '1' }))]),
    ]),
  ],
})
export class ProfileContractComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public user: User = {} as User;
  public RESIDENCES = RESIDENCES;
  public LEGAL_STATUSES = LEGAL_STATUSES;
  public roles = RolesEnum;
  public isLoading = true;
  public userType: UserTypes;
  public currentResidency: ResidencesTypes;
  public currentLegalStatus: LegalStatusesTypes;
  public bankTabs = [...BANK_TABS];
  public activeBankTab: string = this.bankTabs[0].value;
  public maxDate = moment();
  public legalStatusesEnum = LegalStatusesEnum;
  public residencesEnum = ResidencesEnum;
  public cardMask =
    '0000 - 0000 - 0000 - 0 || 0000 - 0000 - 0000 - 0000 || 0000 - 0000 - 0000 - 0000 00 || 0000 - 0000 - 0000 - 0000 000';

  private copyAddressStatus: boolean = false;
  private copyPersonalDataStatus: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  messages = FORM_VALIDATOR_MESSAGES;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private personalDataService: PersonalDataService,
    private router: Router,
    private notify: NotificationsService,
    private modalService: NgbModal,
    private filesService: FilesService
  ) {
    this.initializeForm();
  }

  get isValidForm(): boolean {
    const invalidFields = FormHelper.findInvalidControlsRecursive(this.form);
    switch (this.userType) {
      case RolesEnum.SUPERUSER:
      case RolesEnum.ACCOUNTANT: {
        return this.isInvalidForm(
          [
            ...BIRTHDAY_FIELDS,
            ...ITN_FIELDS,
            ...SNILS_FIELDS,
            ...PASSPORT_FIELDS,
            ...BANKCARD_FIELDS,
            ...ADDRESS_FIELDS,
          ],
          invalidFields
        );
      }
      case RolesEnum.PARTNER:
      case RolesEnum.EXPERT: {
        return this.isInvalidForm(
          [
            ...BIRTHDAY_FIELDS,
            ...ITN_FIELDS,
            ...SNILS_FIELDS,
            ...PASSPORT_FIELDS,
            ...BANKCARD_FIELDS,
            ...ADDRESS_FIELDS,
          ],
          invalidFields
        );
      }
      case RolesEnum.ADMIN_OF_DIRECTION:
      case RolesEnum.ADMIN_OF_USER: {
        switch (this.currentResidency) {
          case ResidencesEnum.RUSSIAN_FEDERATION: {
            switch (this.currentLegalStatus) {
              case LegalStatusesEnum.INDIVIDUAL_ENTREPRENEUR: {
                return this.isInvalidForm(
                  [
                    ...PASSPORT_FIELDS,
                    ...CERTIFICATE_FIELDS,
                    ...ITN_FIELDS,
                    ...PSRNSP_FIELDS,
                    ...this.getBankSelectedFields(),
                    ...ADDRESS_FIELDS,
                  ],
                  invalidFields
                );
              }
              case LegalStatusesEnum.NATURAL_PERSON: {
                return this.isInvalidForm([], invalidFields);
              }
              case LegalStatusesEnum.LEGAL_ENTITY: {
                return this.isInvalidForm(
                  [
                    ...AUTHORIZED_PERSON_FIELDS,
                    ...CERTIFICATE_FIELDS,
                    ...ITN_FIELDS,
                    ...IEC_FIELDS,
                    ...PSRN_FIELDS,
                    ...ORGANIZATION_FIELDS,
                    ...BANK_ACCOUNT_FIELDS,
                    ...ADDRESS_FIELDS,
                  ],
                  invalidFields
                );
              }
            }
            break;
          }
          case ResidencesEnum.OTHER: {
            switch (this.currentLegalStatus) {
              case LegalStatusesEnum.NATURAL_PERSON: {
                return this.isInvalidForm([], invalidFields);
              }
              case LegalStatusesEnum.LEGAL_ENTITY: {
                return this.isInvalidForm(
                  [
                    ...AUTHORIZED_PERSON_FIELDS,
                    ...CERTIFICATE_FIELDS,
                    ...ITN_FIELDS,
                    ...ORGANIZATION_FIELDS,
                    ...BANK_ACCOUNT_FIELDS_OTHER,
                    ...ADDRESS_FIELDS,
                  ],
                  invalidFields
                );
              }
            }
            break;
          }
        }
        break;
      }
    }
    return false;
  }

  get isValidCheck(): boolean {
    const invalidFields = FormHelper.findInvalidControlsRecursive(this.form);
    switch (this.userType) {
      case RolesEnum.SUPERUSER:
      case RolesEnum.ACCOUNTANT: {
        return this.isInvalidForm([...CHECK_RULES_FIELDS], invalidFields);
      }
      case RolesEnum.PARTNER:
      case RolesEnum.EXPERT: {
        return this.isInvalidForm([...CHECK_RULES_FIELDS, ...CHECK_CONTRACT_FIELDS], invalidFields);
      }
      case RolesEnum.ADMIN_OF_DIRECTION:
      case RolesEnum.ADMIN_OF_USER: {
        switch (this.currentResidency) {
          case ResidencesEnum.RUSSIAN_FEDERATION: {
            switch (this.currentLegalStatus) {
              case LegalStatusesEnum.INDIVIDUAL_ENTREPRENEUR: {
                return this.isInvalidForm([...CHECK_RULES_FIELDS, ...CHECK_CONTRACT_FIELDS], invalidFields);
              }
              case LegalStatusesEnum.NATURAL_PERSON: {
                return this.isInvalidForm([...CHECK_RULES_FIELDS, ...CHECK_CONTRACT_FIELDS], invalidFields);
              }
              case LegalStatusesEnum.LEGAL_ENTITY: {
                return this.isInvalidForm([...CHECK_RULES_FIELDS, ...CHECK_CONTRACT_FIELDS], invalidFields);
              }
            }
            break;
          }
          case ResidencesEnum.OTHER: {
            switch (this.currentLegalStatus) {
              case LegalStatusesEnum.NATURAL_PERSON: {
                return this.isInvalidForm([...CHECK_RULES_FIELDS, ...CHECK_CONTRACT_FIELDS], invalidFields);
              }
              case LegalStatusesEnum.LEGAL_ENTITY: {
                return this.isInvalidForm([...CHECK_RULES_FIELDS, ...CHECK_CONTRACT_FIELDS], invalidFields);
              }
            }
            break;
          }
        }
        break;
      }
    }
    return false;
  }

  get isAuthorizedPerson(): boolean {
    switch (this.userType) {
      case RolesEnum.ADMIN_OF_DIRECTION:
      case RolesEnum.ADMIN_OF_USER: {
        switch (this.currentResidency) {
          case ResidencesEnum.RUSSIAN_FEDERATION: {
            switch (this.currentLegalStatus) {
              case LegalStatusesEnum.LEGAL_ENTITY: {
                return true;
              }
            }
            break;
          }
          case ResidencesEnum.OTHER: {
            switch (this.currentLegalStatus) {
              case LegalStatusesEnum.LEGAL_ENTITY: {
                return true;
              }
            }
            break;
          }
        }
        break;
      }
    }
    return false;
  }

  get isCanBePayer(): boolean {
    if (this.user && this.userType === this.roles.ADMIN_OF_DIRECTION) {
      return this.user?.flags.is_can_be_payer;
    }
    return false;
  }

  get isPayerSelfCompany(): boolean {
    return this.user?.company?.owner_id === this.user.id;
  }

  ngOnInit(): void {
    let company: any;
    this.authService.userStream.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
      const isPayerSelfCompany = () => user?.company?.owner_id === user.id;
      const isCanBePayer = () => user.type === RolesEnum.ADMIN_OF_DIRECTION && user?.is_can_be_payer;
      if (!user.company?.id || (isCanBePayer() && !isPayerSelfCompany())) {
        company = new CompanyCreate();
      } else {
        let postal_address = '';
        let registration_address = '';
        if (user.company.postal_address) {
          postal_address = this.getAddressString(user.company.postal_address);
        }
        if (user.company.registration_address) {
          registration_address = this.getAddressString(user.company.registration_address);
        }
        company = new CompanyUpdate({ ...user.company, registration_address, postal_address });
      }

      this.currentResidency = user?.company?.residency
        ? user?.company?.residency
        : (RESIDENCES[0].value as ResidencesTypes);
      this.currentLegalStatus = user?.company?.legal_status
        ? user?.company?.legal_status
        : (LEGAL_STATUSES[0].value as LegalStatusesTypes);

      this.user = { ...user };
      this.isLoading = true;
      this.userType = this.user.type;
      this.isLoading = false;
      FormHelper.updateForm(this.form, { ...user, company });

      this.changeResidence();
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe = null;
  }

  public updateUser(): void {
    const textUpperCase = new TextUpperCasePipe();
    this.form.markAllAsTouched();
    if (this.isValidForm && this.isValidCheck) {
      const { birthday, company } = this.form.value;
      const companyId = this.user.company.id;
      const data = this.clearEmptyFields(company);

      (birthday
        ? this.personalDataService.updateUserData({ birthday: DatesHelper.fromDateToServerDate(birthday) })
        : of({})
      )
        .pipe(
          takeUntil(this.ngUnsubscribe),
          switchMap(() => {
            const passport_date = company?.passport_date
              ? DatesHelper.fromDateToServerDate(company?.passport_date)
              : null;

            const certificate_date = company?.certificate_date
              ? DatesHelper.fromDateToServerDate(company?.certificate_date)
              : null;

            let authorized_person_fields = {};
            if (this.isAuthorizedPerson) {
              const authorized_person_second_name = company?.authorized_person_second_name
                ? textUpperCase.transform(company.authorized_person_second_name)
                : company.authorized_person_second_name;
              const authorized_person_first_name = company?.authorized_person_first_name
                ? textUpperCase.transform(company.authorized_person_first_name)
                : company.authorized_person_first_name;
              const authorized_person_patronymic = company?.authorized_person_patronymic
                ? textUpperCase.transform(company.authorized_person_patronymic)
                : company.authorized_person_patronymic;

              authorized_person_fields = {
                authorized_person_second_name,
                authorized_person_first_name,
                authorized_person_patronymic: authorized_person_patronymic || '',
              };
            }
            if (!companyId || (this.isCanBePayer && !this.isPayerSelfCompany)) {
              return this.personalDataService.createCompany({
                ...company,
                passport_date,
                certificate_date,
                ...authorized_person_fields,
              });
            } else {
              return this.personalDataService.updateCompany({
                ...data,
                id: companyId,
                passport_date,
                certificate_date,
                ...authorized_person_fields,
              });
            }
          }),
          switchMap(() => {
            return this.authService.updateUser();
          }),
          map((user: User) => {
            this.authService.nextUserData(user);
            this.notify.success('Успешно!', 'Данные успешно сохранены');
          })
        )
        .subscribe(
          () => {
            this.router.navigate(['profile']);
          },
          () => {
            this.notify.error('Внимание!', 'Не удалось сохранить данные');
          }
        );
    } else if (this.isValidForm && !this.isValidCheck) {
      this.notify.error('Внимание!', 'Данные не могут быть сохранены, пока Вы не примите правила и условия договора');
    } else {
      this.notify.error(
        'Внимание!',
        'Пожалуйста, заполните обязательные поля, отмеченные (*), и согласитесь с Правилами'
      );
    }
  }

  // Перемещает каретку в конец (фикс для маски)
  moveCaretToEnd(card: HTMLInputElement): void {
    const valueLength = card.value?.length;
    if (valueLength === 24) {
      card.setSelectionRange(valueLength, valueLength);
    }
  }

  public cancel(): void {
    this.router.navigate(['profile']);
  }

  public changeResidence(): void {
    switch (this.currentResidency) {
      case ResidencesEnum.RUSSIAN_FEDERATION: {
        this.LEGAL_STATUSES = LEGAL_STATUSES;
        break;
      }
      case ResidencesEnum.OTHER: {
        if (this.userType === RolesEnum.ADMIN_OF_USER || this.userType === RolesEnum.ADMIN_OF_DIRECTION) {
          if (this.currentLegalStatus === LegalStatusesEnum.INDIVIDUAL_ENTREPRENEUR) {
            this.currentLegalStatus = LegalStatusesEnum.NATURAL_PERSON;
          }
          this.LEGAL_STATUSES = LEGAL_STATUSES.map((n) =>
            n.value === LegalStatusesEnum.INDIVIDUAL_ENTREPRENEUR ? { ...n, roles: [] } : n
          );
        }
        break;
      }
    }
    this.form.get('company.residency').setValue(this.currentResidency);
    this.changeLegalStatus();
  }

  public changeLegalStatus(): void {
    this.form.get('company.legal_status').setValue(this.currentLegalStatus);
  }

  public changeBankTab(value: string) {
    this.activeBankTab = value;
  }

  public getUserRules(): void {
    this.filesService
      .getUserRules()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map((fileBlob: Blob) => {
          return URL.createObjectURL(new Blob([fileBlob], { type: 'application/pdf' }));
        })
      )
      .subscribe((link) => {
        const modal = this.modalService.open(PreviewContentModalComponent, {
          ...ProfileModalBaseOptions,
          windowClass: 'dc-modal profile-modal modal-window',
          size: 'xl',
          backdrop: true,
        });

        (<PreviewContentModalComponent>modal.componentInstance).link = link;
      });
  }

  public getContractRules(): void {
    this.filesService
      .getAgreement()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map((fileBlob: Blob) => {
          return URL.createObjectURL(new Blob([fileBlob], { type: 'application/pdf' }));
        })
      )
      .subscribe((link) => {
        const modal = this.modalService.open(PreviewContentModalComponent, {
          ...ProfileModalBaseOptions,
          windowClass: 'dc-modal profile-modal modal-window',
          size: 'xl',
          backdrop: true,
        });

        (<PreviewContentModalComponent>modal.componentInstance).link = link;
      });
  }

  public copyAddresses(checked: boolean): void {
    if (checked) {
      this.form.get('company.postal_address').setValue(this.form.get('company.registration_address').value);
      this.form
        .get('company.postal_address_postcode')
        .setValue(this.form.get('company.registration_address_postcode').value);
    } else {
      this.form.get('company.postal_address').setValue('');
      this.form.get('company.postal_address_postcode').setValue('');
    }
  }

  public copyPersonData(checked: boolean): void {
    if (checked) {
      this.form.get('company.authorized_person_second_name').setValue(this.user.second_name);
      this.form.get('company.authorized_person_first_name').setValue(this.user.first_name);
      this.form.get('company.authorized_person_patronymic').setValue(this.user.patronymic);
    } else {
      this.form.get('company.authorized_person_second_name').setValue('');
      this.form.get('company.authorized_person_first_name').setValue('');
      this.form.get('company.authorized_person_patronymic').setValue('');
    }
  }

  private getBankSelectedFields(): string[] {
    return this.activeBankTab === 'bank_account' ? BANK_ACCOUNT_FIELDS : BANKCARD_FIELDS;
  }

  private initializeForm(): void {
    this.form = this.fb.group({
      checkRules: [false, Validators.requiredTrue], // Согласие с правилами
      checkContract: [false, Validators.requiredTrue], // Согласие с договором-офертой

      birthday: ['', Validators.required], // Дата рождения
      company: this.fb.group({
        residency: ['', Validators.required], // Резидент РФ не резидент РФ
        legal_status: ['', Validators.required], // Юридический статус

        passport_series: ['', Validators.required], // Серия паспорта ИП
        passport_number: ['', Validators.required], // Номер паспорта ИП
        passport_date: ['', Validators.required], // Дата выдачи ИП
        passport_division_code: ['', Validators.required], // Код подразделения ИП
        passport_issued_by: ['', Validators.required], // Кем выдан ИП

        certificate_number: ['', Validators.required], // Номер свидетельства гос. регистрации - ИП ЮЛ
        certificate_date: ['', Validators.required], // Дата гос. регистрации- ИП ЮЛ
        itn: ['', Validators.required], // ИНН - ИП ЮЛ
        snils: ['', Validators.required], // СНИЛС
        psrnsp: ['', Validators.required], // ОГРНИП - ИП

        organization_type: ['', Validators.required], // ОПФ - ЮЛ
        organization_name: ['', Validators.required], // ОПФ - ЮЛ
        name: ['', Validators.required], // Название организации - ЮЛ
        iec: ['', Validators.required], // КПП: - ЮЛ
        psrn: ['', Validators.required], // ОГРН - ЮЛ

        // Лицо, уполномоченное на подписание договора
        authorized_person_second_name: ['', Validators.required], // Лицо, уполномоченное на подписание договора фамилия - ЮЛ
        authorized_person_first_name: ['', Validators.required], // Лицо, уполномоченное на подписание договора имя - ЮЛ
        authorized_person_patronymic: [''], // Лицо, уполномоченное на подписание договора отчество - ЮЛ
        position: ['', Validators.required], // Лицо, уполномоченное на подписание договора должность - ЮЛ
        authority_basis: ['', Validators.required], // Лицо, уполномоченное на подписание договора основание полномочий - ЮЛ

        // Адрес регистрации или Юридический адрес
        registration_address_postcode: ['', Validators.required], // адрес регистрации - индекс - ВСЕ РОЛИ
        registration_address: ['', Validators.required], // адрес регистрации - населенный пункт - ВСЕ РОЛИ

        // Почтовый адрес
        postal_address_postcode: ['', Validators.required], // Почтовый адрес - индекс - ВСЕ РОЛИ
        postal_address: ['', Validators.required], // Почтовый адрес - населенный пункт - ВСЕ РОЛИ

        // Банковская карта
        bankcard_number: ['', Validators.required], // Номер банковской карты
        bankcard_bank_name: ['', Validators.required], // Банк-эмитент

        swift: ['', Validators.required],
        iban: ['', Validators.required],

        // Банковский счет
        bank_name: ['', Validators.required], // Название банка
        bank_bic: ['', Validators.required], // БИК банка
        bank_corresponding_account: ['', Validators.required], // Корреспондентский счёт
        bank_current_account: ['', Validators.required], // Расчётный счёт
      }),
    });
  }

  private isInvalidForm(fields: string[], invalidFields: string[]): boolean {
    let result = true;
    fields.forEach((value) => {
      if (invalidFields.includes(value)) {
        result = false;
      }
    });
    return result;
  }

  private getAddressString(object: Address): string {
    const { country, province, locality, address } = object;
    return `${country ? country + ', ' : ''}${province ? province + ', ' : ''}${locality ? locality + ', ' : ''}${
      address ? address : ''
    }`;
  }

  private clearEmptyFields(object: any): any {
    const data = { ...object };
    Object.keys(data).forEach((key) => data[key] == null && delete data[key]);
    return data;
  }
}
