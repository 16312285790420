import { Component, OnInit } from '@angular/core';
import { ChatSectionsEnum, MessageActionType } from '@app/chat/constants/chat-sections.constants';
import { ChatSectionService } from '@app/chat/services/chat-section.service';
import { MessagesSelectService } from '@app/chat/services/messages-select.service';
import { NotificationsService } from 'angular2-notifications';
import { ChatService } from '../services/chat.service';
import { takeUntil } from 'rxjs/operators';
import { DestroyService } from '@app/services/destroy.service';
import { ChatMessage, ChatRoom, ChatSection } from '../models/chat.model';
import { AuthService } from '@app/shared/services/auth.service';
import { SocketDataService } from '@app/services/socket-data.service';

interface ModalConfig {
  isShowed: boolean;
  actionType?: MessageActionType;
  title?: string;
  cancelLabel?: string;
  confirmLabel?: string;
  isDisabled?: boolean;
  onConfirm?: () => void;
}

@Component({
  selector: 'app-chat-message-selected-panel',
  templateUrl: './chat-message-selected-panel.component.html',
  styleUrls: ['./chat-message-selected-panel.component.scss'],
  providers: [ChatSectionService],
})
export class ChatMessageSelectedPanelComponent implements OnInit {
  messageSelectedList: ChatMessage[] = [];
  roomSelectedList: ChatRoom[] = [];
  userId = Number(this.authService.user_id);
  chatSectionSelected: ChatSection = {} as ChatSection;
  chatSectionsEnum = ChatSectionsEnum;
  messagesActionType: MessageActionType;

  actions = [
    { type: MessageActionType.FORWARD, label: 'Переслать', handler: () => this.showForwardModal() },
    { type: MessageActionType.DELETE, label: 'Удалить', handler: () => this.showDeleteModal() },
  ];
  modalConfig: ModalConfig = { isShowed: false };

  protected readonly MessageActionType = MessageActionType;

  constructor(
    private chatService: ChatService,
    private readonly destroy$: DestroyService,
    private authService: AuthService,
    private chatDataService: SocketDataService,
    private chatSectionService: ChatSectionService,
    private messagesSelectService: MessagesSelectService,
    private notify: NotificationsService
  ) {}

  ngOnInit(): void {
    this.messagesSelectService.selectMessagesActionType
      .pipe(takeUntil(this.destroy$))
      .subscribe((type) => (this.messagesActionType = type));

    this.messagesSelectService.messageSelectedChanged.pipe(takeUntil(this.destroy$)).subscribe((messageSelected) => {
      this.messageSelectedList = messageSelected;
    });

    this.messagesSelectService.roomsSelectedChanged.pipe(takeUntil(this.destroy$)).subscribe((roomsSelected) => {
      this.roomSelectedList = roomsSelected;
    });

    this.chatSectionService.chatSectionSelectedChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe((chatSectionSelected) => {
        this.chatSectionSelected = chatSectionSelected;
      });
  }

  showDeleteModal() {
    const hasPersonal = this.messageSelectedList.some((message) => message.author_id === this.userId);

    if (hasPersonal) {
      this.modalConfig = {
        isShowed: true,
        actionType: MessageActionType.DELETE,
        title: 'Вы действительно хотите удалить сообщения из своего чата и чата получателя?',
        cancelLabel: 'Нет, отменить',
        confirmLabel: 'Да, удалить',
        isDisabled: false,
        onConfirm: () => this.deleteMessages(),
      };
    } else {
      this.deleteMessages();
    }
  }

  showForwardModal() {
    this.modalConfig = {
      isShowed: true,
      actionType: MessageActionType.FORWARD,
      title: 'Переслать...',
      cancelLabel: 'Отменить',
      confirmLabel: 'Переслать',
      isDisabled: false,
      onConfirm: () => this.forwardMessages(),
    };
  }

  deleteMessages() {
    this.closeModal();

    this.chatDataService.deleteMessage(
      this.messageSelectedList.map((item) => item.id),
      this.messageSelectedList[0].room_id,
      this.chatService.isDutyTsoAction(),
      this.chatService.isTsoAction()
    );

    this.messagesSelectService.clearMessageSelected();
  }

  panelClose() {
    this.closeModal();
    this.messagesSelectService.clearMessageSelected();
  }

  private forwardMessages() {
    // this.disabledSend = true;
    const messages_ids = this.messageSelectedList.filter((message) => message.id !== 0).map((message) => message.id);

    const rooms_ids = this.roomSelectedList.map((room) => room.room_id);

    this.chatDataService.forwardMessages(messages_ids, rooms_ids).subscribe({
      next: () => {
        this.notify.success('Успешно!', this.getConfirmNotification(messages_ids.length));
        this.closeModal();
        this.messagesSelectService.clearSelectedRoom();
        this.messagesSelectService.clearMessageSelected();
      },
      error: (err) => this.notify.error('Ошибка!', err.message),
    });
  }

  private getConfirmNotification(messagesCount: number) {
    const nameRooms = this.roomSelectedList.map((room) => room.title || this.getRoomName(room));
    return `Вы переслали ${messagesCount} ${this.declination(messagesCount)} в каналы: <br> ${nameRooms.join('<br>')}`;
  }

  private getRoomName(room: ChatRoom) {
    return `${room.second_name || ''} ${room.first_name || ''}`.trim();
  }

  private declination(messagesCount: number) {
    const wordMsg = [' сообщение', ' сообщения', ' сообщений'];
    const cases = [2, 0, 1, 1, 1, 2];
    return wordMsg[
      messagesCount % 100 > 4 && messagesCount % 100 < 20 ? 2 : cases[messagesCount % 10 < 5 ? messagesCount % 10 : 5]
    ];
  }

  closeModal() {
    this.messagesSelectService.clearSelectedRoom();
    this.modalConfig = { isShowed: false };
  }
}
