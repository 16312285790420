import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { CriteriaTypesEnum } from '@app/+trades/constants/lots.constants';
import { getCriteriaDateMask } from '@app/shared/constants/masks.constants';

@Component({
  selector: 'app-criteria-view-value-edit',
  templateUrl: './criteria-view-value-edit.component.html',
  styleUrls: ['./criteria-view-value-edit.component.scss'],
})
export class CriteriaViewValueEditComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() form: NgForm;
  @Input() value: string;
  @Input() min: string;
  @Input() max: string;
  @Input() name: string;
  @Input() type: CriteriaTypesEnum;

  @ViewChild('valueControl') valueControl: NgModel;

  @Output() changeEvent = new EventEmitter<string>();
  criteriaTypes = CriteriaTypesEnum;
  dateMask = getCriteriaDateMask;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    const newFormState = Object.assign(this.form.value);
    newFormState[this.name] = this.value;
    this.form.resetForm(newFormState);

    setTimeout(() => {
      this.form.addControl(this.valueControl);

      this.valueControl.control.markAsTouched();
    }, 0);
  }

  change(event) {
    const { value } = event.target;
    this.changeEvent.emit(value);
  }

  ngOnDestroy() {}
}
