import moment from 'moment';
import { Component, Input, OnInit } from '@angular/core';
import { CriteriaShort, Nomenclature } from '@app/+trades/models/lots.model';
import { ControlContainer, FormArray, FormControl, FormGroup } from '@angular/forms';
import { RatingProviderLot, RatingProviderNomenclature } from '@app/+trades/models/trades-rating.model';
import { SupplierNomenclatureGroup } from '@app/+trades/form-types/lots';
import { CriteriaVectorEnum } from '@app/+trades/constants/lots.constants';
import { BACKEND_DATE_FORMAT, DATE_FORMAT } from '@app/shared/constants/date.constants';
import { ActivatedRoute } from '@angular/router';
import { Unit } from '@app/shared/models/user-settings.model';

@Component({
  selector: '[formGroup] app-supplier-lot-criteria, [formGroupName] app-supplier-lot-criteria',
  templateUrl: './supplier-lot-criteria.component.html',
  styleUrls: ['./supplier-lot-criteria.component.scss'],
})
export class SupplierLotCriteriaComponent implements OnInit {
  formArray: FormArray<FormGroup<SupplierNomenclatureGroup>>;
  collapsedCriteria: boolean = false;
  collapsedDetails: boolean = false;
  isCriteriaActionsOpened: boolean = false;
  criteriaVectorEnum = CriteriaVectorEnum;
  unitsMap: Map<number, Unit> = new Map();

  private criteriaLabelsMap = {
    bool: 'нет/да',
    date: 'дата',
    float: 'числа',
  };
  private _isTrades = false;

  @Input() index: number;
  @Input() criteria: CriteriaShort;
  @Input() nomenclatures: Nomenclature[];
  @Input() rating: RatingProviderLot;
  @Input() hideLeaders = false;
  @Input() readonly = false;
  @Input() set isTrades(value: boolean) {
    this._isTrades = value;
    this.collapsedDetails = value;
  }

  get isTrades(): boolean {
    return this._isTrades;
  }

  get criteriaTypeLabel(): string {
    return this.criteriaLabelsMap[this.criteria.type];
  }

  constructor(private controlContainer: ControlContainer, private route: ActivatedRoute) {
    this.unitsMap = this.route.snapshot.data.unitsMap;
  }

  ngOnInit(): void {
    this.formArray = this.controlContainer.control as FormArray<FormGroup<SupplierNomenclatureGroup>>;
  }

  toggleCollapsedCriteria(event: MouseEvent, state: boolean): void {
    event.stopPropagation();
    this.collapsedCriteria = state;
    if (state) {
      this.isCriteriaActionsOpened = false;
    }
  }

  showCriteriaActions(event: MouseEvent): void {
    event.stopPropagation();
    this.isCriteriaActionsOpened = true;
  }

  closeCriteriaActions(event: MouseEvent): void {
    event.stopPropagation();
    this.isCriteriaActionsOpened = false;
  }

  getCriteriaRating(nIndex: number, criteriaId: number) {
    return this.rating?.nomenclatures?.[nIndex]?.criteria?.find((c) => c.id_criterion === criteriaId);
  }

  formatCriteriaRatingValue(value: string) {
    if (value === undefined || value === null) {
      return '-';
    }

    if (value === 'True' || value === 'true' || value === 'False' || value === 'false') {
      return this.getBooleanStr(value);
    }

    if (moment(value).isValid()) {
      return moment(value, BACKEND_DATE_FORMAT).format(DATE_FORMAT);
    }

    if (!isNaN(+value)) {
      return (+value).toFixed(0);
    }

    return value;
  }

  getBooleanStr(value: string | boolean) {
    if (value === 'True' || value === 'true' || value === true) {
      return 'Да';
    }

    if (value === 'False' || value === 'true' || value === false) {
      return 'Нет';
    }

    return value;
  }

  getCriteriaValueControl(nIndex: number) {
    return this.formArray.at(nIndex).controls.criteria_provider.at(this.index).controls.value;
  }

  isControlValid(control: FormControl) {
    return control.value === null || control.value === undefined || control.value === '' || control.value.invalid;
  }

  copyToBottomLines(value: boolean | string) {
    this.nomenclatures.forEach((_, i) => this.getCriteriaValueControl(i).patchValue(value, { emitEvent: false }));

    this.formArray.updateValueAndValidity();
  }
}
