<div class="d-flex align-items-center trade-item" (dblclick)="userDbClick()">
  <div class="mr-2 checkbox" *ngIf="!withoutSelection">
    <input
      [disabled]="item.disabled || accessRight === accessRights.OWNER"
      class="cbx-input"
      [id]="isCustomer ? 'c' + item.id : item.id"
      type="checkbox"
      (change)="onCheckboxChange($event.target.checked)"
      [checked]="item.selected"
    />
    <label class="cbx-label" [for]="isCustomer ? 'c' + item.id : item.id"></label>
  </div>

  <div class="dc-competence-tree-item" [ngStyle]="{ 'padding-left': getContentOffset() + 'px' }">
    <div class="dc-competence-tree-item-inner">
      <div class="dc-competence-tree-item-heading">
        <app-svg-icon
          *ngIf="node?.children?.length > 0"
          [name]="!node.isExpanded ? 'caret_down' : 'caret_up'"
          [class.disabled]="node.level === 3"
          (click)="toggleNodeClicked()"
          width="10"
        ></app-svg-icon>

        <div
          class="dc-competence-tree-item-title trade-item__name text-overflow"
          [class.inactive]="node?.data?.searchedInactive"
          [class.disabled]="item.disabled"
          [innerHTML]="item | initials | highlight : searchString"
          [ngbTooltip]="item | initials"
        ></div>
      </div>
    </div>
  </div>
</div>
<div class="dc-competence-tree-item-buttons" #controls>
  <div class="dc-competence-tree-item-status"></div>

  <div
    class="icons d-flex justify-content-end align-items-center p-2 trade-item__controls"
    *ngIf="placementIcons === 'right'"
  >
    <span class="service-menu-wrapper" *ngIf="isCustomer">
      <button
        class="access-select color-brand icon-button"
        (click)="openSelect($event)"
        [class.access-select-disabled]="item?.id === ownerId"
      >
        <app-svg-icon [name]="accessRightIcon" width="18"></app-svg-icon>
      </button>

      <div class="service-menu" *ngIf="isAccessSelectOpen" appClickElsewhere (appClickElsewhere)="closeSelect($event)">
        <button
          class="icon-button"
          [class.no-hover]="withoutSelection"
          [disabled]="accessRight === accessRights.VIEW"
          (click)="selectMenuItem($event, accessRights.VIEW)"
        >
          <app-svg-icon name="eye" width="16"></app-svg-icon>
        </button>
        <button
          class="icon-button"
          [class.no-hover]="withoutSelection"
          [disabled]="accessRight === accessRights.EDIT"
          (click)="selectMenuItem($event, accessRights.EDIT)"
        >
          <app-svg-icon name="faq_edit" width="18"></app-svg-icon>
        </button>
        <!-- На флаг is_can_start_trade смотрим только для торгов заказчика -->
        <button
          *ngIf="item?.flags?.is_can_start_trade ?? true"
          class="icon-button"
          [disabled]="accessRight === accessRights.OWNER"
          (click)="selectMenuItem($event, accessRights.OWNER)"
        >
          <app-svg-icon name="crown" width="16"></app-svg-icon>
        </button>
      </div>
    </span>

    <app-svg-icon [name]="accessRightIcon" width="18" *ngIf="withoutSelection" class="no-hover"></app-svg-icon>
    <app-svg-icon name="key" width="14" *ngIf="isCustomer || withoutSelection" class="no-hover"></app-svg-icon>

    <app-svg-icon name="circle" width="16" [class]="statusIconClass" class="no-hover"></app-svg-icon>

    <button class="icon-button" (click)="showUserInfo()">
      <app-svg-icon name="id-card" width="18"></app-svg-icon>
    </button>

    <button *ngIf="item?.id !== userId && !isCustomer" [chatLink]="item.id" class="color-brand icon-button">
      <app-svg-icon name="comment" width="16"></app-svg-icon>
    </button>
  </div>
</div>
