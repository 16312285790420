import { Component, OnInit } from '@angular/core';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { of } from 'rxjs';

import { PortalService } from '@app/shared/services/portal.service';
import { ProjectBase, ProjectBaseComponent } from '@app/+competence-map/models/project.class';
import { AuthService } from '@app/shared/services/auth.service';

import { Project } from '@app/+competence-map/models/projects.models';
import { CMActions, CMProjectStatuses } from '@app/+competence-map/constants/projects.constants';
import { switchMap } from 'rxjs/operators';
import { FORM_VALIDATOR_MESSAGES } from '@app/shared/constants/form.constants';
import { UnitsStatuses } from '@app/+competence-map/constants/units.constants';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationsService } from 'angular2-notifications';

const notMatchItems: ValidatorFn = (control: FormArray): ValidationErrors | null => {
  const stringResults = control.value.map((item) => item.fullName + item.name);

  for (let i = 0; i < stringResults.length; i++) {
    const group = control.at(i) as FormGroup;
    if (!group.value.fullName) {
      group.controls.fullName.setErrors({ required: true });
    } else {
      group.controls.fullName.setErrors(null);
      group.controls.name.setErrors(null);
    }
  }

  if (stringResults.length === 1) {
    return null;
  }

  for (let i = 0; i < stringResults.length; i++) {
    const group = control.at(i) as FormGroup;

    for (let j = i + 1; j < stringResults.length; j++) {
      if (stringResults[j] === stringResults[i]) {
        if (!group.controls.fullName.hasError('required')) {
          group.controls.fullName.setErrors({ hasMatches: true });
        }
        group.controls.name.setErrors({ hasMatches: true });

        return { hasMatches: true };
      }
    }
  }

  return null;
};

@Component({
  selector: 'app-add-unit',
  templateUrl: './add-unit.component.html',
  styleUrls: ['./add-unit.component.scss'],
  providers: [PortalService],
})
export class AddUnitComponent extends ProjectBaseComponent implements OnInit, ProjectBase {
  form: FormGroup;
  sections: FormArray;
  messages = FORM_VALIDATOR_MESSAGES;

  constructor(
    private competenceService: CompetenceService,
    private fb: FormBuilder,
    protected portalService: PortalService,
    protected authService: AuthService,
    private notify: NotificationsService
  ) {
    super(portalService, authService);
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  addName(): void {
    this.sections.push(this.getUnitGroup());
  }

  addProject(status?: CMProjectStatuses): void {
    const params = this.sections.value.map(({ name, fullName }) => {
      if (name) {
        return {
          name,
          full_name: fullName,
        };
      } else {
        return {
          full_name: fullName,
        };
      }
    });

    this.competenceService
      .addUnit(params)
      .pipe(
        switchMap((project) => {
          if (!status) {
            return of(project);
          } else {
            return this.competenceService.updateProject({
              id: project.id,
              status,
            });
          }
        })
      )
      .subscribe(
        (project) => {
          this.project = project;

          this.reloadEvent.emit();

          this.competenceService.projectSuccess(status);
          this.form.markAsPristine();
        },
        (err) => {
          this.showInvalid(err);
        }
      );
  }

  updateProject(status?: CMProjectStatuses): void {
    this.competenceService
      .updateProject({
        id: this.project.id,
        status,
        spec: {
          [CMActions.CREATE_UNITS_MEASURE]: this.sections.value.map((item, i) => {
            return {
              full_name: item.fullName,
              name: item.name,
              status: UnitsStatuses.ACTIVE,
            };
          }),
        },
      } as Project)
      .subscribe(
        (project) => {
          this.project = project;

          this.reloadEvent.emit();
          this.competenceService.projectSuccess(status);
          this.form.markAsPristine();
        },
        (err) => {
          this.showInvalid(err);
        }
      );
  }

  showInvalid(err: HttpErrorResponse) {
    if (Array.isArray(err.error?.errors[''])) {
      err.error?.errors[''].forEach(
        (error: { line: number; message: string; field_name: string; unit_measure: string }) => {
          const errorIndex = error.line;
          const errorMessage = error.message;
          const unitMeasure = error.unit_measure;

          const sectionGroup = (this.sections as FormArray).at(errorIndex) as FormGroup;

          sectionGroup.controls.fullName.setErrors({ required: true });
          sectionGroup.controls.name.setErrors({ required: true });

          this.notify.error('Ошибка', `${errorMessage}: ${unitMeasure}`);
        }
      );
    }
  }

  cancel() {
    this.initializeForm();
  }

  save(status: CMProjectStatuses) {
    if (this.isNew) {
      this.addProject(status);
    } else {
      this.updateProject(status);
    }
  }

  agree(status: CMProjectStatuses) {
    if (this.isNew) {
      this.addProject(status);
    } else {
      this.updateProject(status);
    }
  }

  archive() {
    this.warnChangeArchiveStatus(() => {
      this.updateProject(CMProjectStatuses.ARCHIVE);
    });
  }

  remove(event: Event, i: number) {
    event.stopPropagation();
    this.sections.removeAt(i);
  }

  private initializeForm(): void {
    if (this.isNew) {
      this.sections = this.fb.array([this.getUnitGroup()], { validators: notMatchItems });
    } else {
      this.sections = this.fb.array(
        this.project.spec[this.project.action].map(({ name, full_name }) => this.getUnitGroup(name, full_name)),
        { validators: notMatchItems }
      );
    }

    this.form = this.fb.group({
      sections: this.sections,
    });
  }

  private getUnitGroup(name: string = '', fullName: string = ''): FormGroup {
    return this.fb.group({
      name: [name],
      fullName: [fullName, Validators.required],
    });
  }
}
