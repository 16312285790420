import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { WordsStorageService } from '@app/+competence-map/services/words-storage.service';
import { NotificationsService } from 'angular2-notifications';
import { DestroyService } from '@app/services/destroy.service';
import { takeUntil } from 'rxjs/operators';
import { Lexicon } from '@app/+competence-map/models/words.model';
import { CMStatusLabels } from '@app/+competence-map/constants/competence-map.constants';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/shared/services/auth.service';
import { PortalService } from '@app/shared/services/portal.service';
import { notMatchPrimitiveItems } from '@app/+competence-map/validators/validators';

@Component({
  selector: 'app-add-word',
  templateUrl: './add-word.component.html',
  styleUrls: ['./add-word.component.scss'],
  providers: [DestroyService, PortalService],
})
export class AddWordComponent implements OnInit {
  form: FormGroup;

  word: Lexicon;

  @Output() saveEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();

  constructor(
    private wordsStorageService: WordsStorageService,
    private competenceService: CompetenceService,
    private destroy$: DestroyService,
    private notify: NotificationsService,
    private fb: FormBuilder,
    private authService: AuthService
  ) {}

  get userAuthor() {
    if (this.word?.author?.type) {
      return this.word?.author?.type;
    } else {
      return this.authService.user_type;
    }
  }

  get date() {
    if (this.word?.created_at) {
      return this.word?.created_at;
    }
    return new Date();
  }

  get status() {
    return CMStatusLabels[this.word?.status];
  }

  get wordName() {
    return this.form.controls.wordName as FormArray;
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  private initializeForm(): void {
    this.form = this.fb.group({
      wordName: this.fb.array([this.fb.control('', [Validators.required, notMatchPrimitiveItems])]),
    });
  }

  addWord() {
    (this.form.controls.wordName as FormArray).push(this.fb.control('', [Validators.required, notMatchPrimitiveItems]));
  }

  save() {
    this.wordsStorageService
      .addWord({
        value: this.form.value.wordName,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (word) => {
          if (!word.errors.length) {
            this.word = word.new_lexicons[0];

            this.notify.success('Успешно', 'Слово успешно создано');
            this.saveEvent.emit();
            this.form.markAsPristine();
          } else {
            word.errors.forEach((error) => {
              const errorIndex = +error[0];
              const errorMessage = error[1];

              const wordControl = (this.form.controls.wordName as FormArray).at(errorIndex);
              wordControl.setErrors({ required: true });

              this.notify.error('Ошибка', errorMessage);
            });
          }
        },
        (err) => {
          this.competenceService.error(err);
        }
      );
  }

  remove(i: number) {
    (this.form.controls.wordName as FormArray).removeAt(i);
  }

  cancel() {
    (this.form.controls.wordName as FormArray).reset();
  }

  isButtonsDisabled() {
    return this.form.invalid || !this.form.dirty;
  }
}
