import { Pipe, PipeTransform } from '@angular/core';
import { GroupSortingNames } from '@app/chat/constants/chat-sections.constants';
import { ChatRoom } from '@app/chat/models/chat.model';
import { AuthService } from '@app/shared/services/auth.service';
import { ChatService } from '@app/chat/services/chat.service';

@Pipe({
  name: 'groupSort',
})
export class GroupSortPipe implements PipeTransform {
  userId: number;

  constructor(private authService: AuthService, private chatService: ChatService) {
    this.userId = +this.authService.user_id;
  }
  transform(groups: ChatRoom[], sortType: GroupSortingNames, themes: ChatRoom[] = [], frm?: string): ChatRoom[] {
    return this.chatService.sortGroupRooms({
      rooms: groups,
      newOrder: sortType,
      userId: this.userId,
      childrenRooms: themes,
    });
  }
}
