import { Component, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@app/environments/environment';
import { MENU_ITEMS } from '@app/shared/constants/menu-items.constants';
import { UserSettings } from '@app/shared/models/user-settings.model';
import { AuthService } from '@app/shared/services/auth.service';
import { PersonalDataService } from '@app/shared/services/personal-data.service';
import { Validation } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import isEmpty from 'lodash-es/isEmpty';
import { RecaptchaComponent } from 'ng-recaptcha';
import { REDIRECT_AFTER_LOGIN } from '../redirect-after-login.constants';

@Component({
  templateUrl: `./signin.component.html`,
  styleUrls: ['../auth.component.sass'],
})
export class SigninComponent {
  @ViewChild('captchaRef') captchaRef: RecaptchaComponent;
  env = environment;
  email = '';
  password = '';
  errors: any = {};
  countTries = 0;
  totalTries = 3;
  private captcha_token;

  constructor(
    private authService: AuthService,
    private notify: NotificationsService,
    private translate: TranslateService,
    private router: Router,
    private personalDataService: PersonalDataService
  ) {
    this.translate.setDefaultLang('ru');
  }

  @HostListener('document:keyup.enter', ['$event'])
  onEnter() {
    this.login();
  }

  handleCorrectCaptcha(res): void {
    this.captcha_token = res;
  }

  resetCaptcha(): void {
    if (this.captcha_token) {
      this.captchaRef.reset();
      this.captcha_token = null;
    }
  }

  login(): void {
    this.errors = Validation.ValidateLogin(
      this.email,
      this.password,
      this.countTries >= this.totalTries,
      this.captcha_token
    );
    if (!isEmpty(this.errors)) return;

    this.authService.login(this.email, this.password, this.captcha_token).subscribe(
      () => {
        this.notify.success('Успешно!', 'Добро пожаловать!');
        this.authService.updateUser().subscribe((user) => {
          this.personalDataService.getUserSettings().subscribe((settings: UserSettings) => {
            const menuItems = MENU_ITEMS[user.type].tuning as any;
            const found = menuItems.find((item) => item.link === settings.start_page);
            if (found && found.link) {
              this.router.navigate([found.link]);
            } else {
              this.router.navigate([REDIRECT_AFTER_LOGIN]);
            }
          });
        });
      },
      (err) => {
        console.log(err);
        this.countTries++;
        const error = err.error;

        if (error && error.errors) {
          this.errors = error.errors;
        }

        if (error && error.message === 'User is inactive') {
          this.authService
            .activateUser(this.email)
            .subscribe(() => this.notify.error('Ошибка!', this.translate.instant(error.message)));
        }
        if (error && error.message === 'Your account has been') {
          this.authService
            .activateUser(this.email)
            .subscribe(() => this.notify.error('Ошибка!', 'Ваш аккаунт был перевен в статус "Архив"'));
        }
        if (error && error.message[0] === 'Authentication failed') {
          this.notify.error(
            'Ошибка!',
            'Ошибка! Логин или пароль указаны не правильно. пожалуйста, укажите верные данные или восстановите их'
          );
        }

        this.resetCaptcha();
      }
    );
  }

  togglePasswordShowing(input: HTMLInputElement) {
    input.type = input.type === 'password' ? 'text' : 'password';
  }
}
