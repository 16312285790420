<div class="tech">
  <div class="d-flex align-items-center tech-left">
    <button [disabled]="disabled" class="icon-button chat-expansion__caret" (click)="onToggleExpanded()">
      <app-svg-icon [name]="isExpanded ? 'caret_up' : 'caret_down'" width="12" height="12"></app-svg-icon>
    </button>
    <span class="tech__title">{{ title }}</span>
  </div>

  <div class="d-flex align-items-center tech-right" *ngIf="!isModalView">
    <button class="icon-button">
      <app-svg-icon [name]="'question-circle-color'" width="16" height="16"></app-svg-icon>
    </button>
    <span [ngbTooltip]="tooltipText" class="tech-tooltip">
      <button
        class="icon-button"
        [class.disabled]="disabledTechButton"
        (click)="openEditor()"
        [ngClass]="{ my_tso: myTso }"
      >
        <app-svg-icon *ngIf="myTso" [name]="'check'" width="8" height="8" class="chosen-icon"></app-svg-icon>
        <app-svg-icon *ngIf="iconName" [name]="iconName" width="16" height="16"></app-svg-icon>
      </button>
    </span>

    <span
      class="chat-number"
      [style.visibility]="totalUnreadCounter ? 'visible' : 'hidden'"
      [class.chat-number__10]="totalUnreadCounter === '10+'"
    >
      {{ totalUnreadCounter }}
    </span>
  </div>
</div>
