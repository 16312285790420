<div class="panel">
  <div class="panel-left">
    <div class="panel-counter">
      Выделено сообщений:
      <span> {{ messageSelectedList.length - 1 }}</span>
    </div>
    <ng-container [ngSwitch]="messagesActionType">
      <ng-container *ngFor="let action of actions">
        <ng-container *ngSwitchCase="action.type">
          <div class="message-action-button">
            <button [disabled]="messageSelectedList.length <= 1" (click)="action.handler()">
              {{ action.label }}
            </button>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="exit">
    <button (click)="panelClose()">Отменить</button>
  </div>
</div>

<app-modal-window [isShowed]="modalConfig.isShowed" (closeEvent)="closeModal()" centered="true">
  <p *ngIf="modalConfig.actionType === MessageActionType.DELETE">{{ modalConfig.title }}</p>
  <h2 *ngIf="modalConfig.actionType === MessageActionType.FORWARD" class="modal-title">{{ modalConfig.title }}</h2>
  <div class="message-action-modal">
    <ng-container *ngIf="modalConfig.actionType === MessageActionType.FORWARD">
      <p>Выбрано чатов: {{ roomSelectedList.length }}</p>
      <ng-container *ngTemplateOutlet="roomList"></ng-container>
    </ng-container>
  </div>
  <div class="d-flex justify-content-between">
    <button class="btn btn-dc-outline" (click)="closeModal()">{{ modalConfig.cancelLabel }}</button>
    <button class="btn btn-dc-primary" (click)="modalConfig.onConfirm()" [disabled]="modalConfig.isDisabled">
      {{ modalConfig.confirmLabel }}
    </button>
  </div>
</app-modal-window>

<ng-template #roomList>
  <app-chat-section-select [isModalView]="true"></app-chat-section-select>
  <div class="room-list">
    <div
      *ngIf="chatSectionSelected.name !== chatSectionsEnum.TECH && chatSectionSelected.name !== chatSectionsEnum.TRADE"
      class="groups-content"
    >
      <app-chat-groups [isModalView]="true"></app-chat-groups>
      <app-chat-contacts
        [isModalView]="true"
        *ngIf="
          chatSectionSelected.name !== chatSectionsEnum.TECH &&
          chatSectionSelected.name !== chatSectionsEnum.DUTY_TSO &&
          chatSectionSelected.name !== chatSectionsEnum.TRADE
        "
      ></app-chat-contacts>
    </div>

    <div class="chat__tech" *ngIf="chatSectionSelected.name === chatSectionsEnum.TECH">
      <app-tech-chat [isModalView]="true"></app-tech-chat>
    </div>

    <div *ngIf="chatSectionSelected.name === chatSectionsEnum.TRADE">
      <app-trade-chat [isModalView]="true"></app-trade-chat>
    </div>
  </div>
</ng-template>
