import { Pipe, PipeTransform } from '@angular/core';
import { ChatRoom } from '@app/chat/models/chat.model';
import { ChatService } from '@app/chat/services/chat.service';
import { ContactsSortingNames } from '@app/chat/constants/chat-sections.constants';

@Pipe({
  name: 'sortContacts',
  standalone: true,
})
export class ContactsSortPipe implements PipeTransform {
  constructor(private chatService: ChatService) {}

  transform(contacts: ChatRoom[], sortType: ContactsSortingNames): ChatRoom[] {
    if (contacts?.length > 1) {
      return this.chatService.sortContactsRooms({ rooms: contacts, newOrder: sortType });
    }

    return contacts;
  }
}
