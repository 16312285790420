import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { CalendarService } from '../../services/calendar/calendar.service';
import { CalendarDataService } from '../../services/calendar/calendar-data.service';
import { ModalInjectorService } from '@app/modal-injector/services/modal-injector.service';
import { ModalInjectorConstants } from '@app/modal-injector/constants/modal-injector.constants';
import { EventFilterModel, EventModel, STATE, WEEK } from '../calendar-widget/constants/calendar.constants';
import {
  BACKEND_DATE_FORMAT,
  BACKEND_DATE_TIME_FORMAT,
  BACKEND_TIME_FORMAT,
  DATE_FORMAT,
  TIMEZONES,
} from '../../constants/date.constants';
import { TYPE_SELECT } from '../calendar-widget/constants/select-date.constants';
import { POSITION } from '../move-pin/constants/move-pin.constants';
import { Day, Week } from '../../models/calendar-widget-data';
import { AuthService } from '../../services/auth.service';
import { DutyData } from '../../models/duty.model';
import { EmployeesService } from '../../services/employers.service';
import { DaySlot } from '../../enums/employee-duty';
import { SVGSpriteModule } from '@app/svg-sprite/svg-sprite.module';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { DutyHelper } from '@app/shared/helpers/duty.helper';
import { DutyStatus } from '@app/shared/constants/duty.constants';

@Component({
  selector: 'app-calendar-duty',
  templateUrl: './calendar-duty.component.html',
  styleUrls: ['./calendar-duty.component.scss'],
  standalone: true,
  imports: [SVGSpriteModule, NgClass, NgForOf, NgIf],
})
export class CalendarDutyComponent implements OnInit {
  timeOffset = (this.authService.getTimezoneOffset() ?? moment().utcOffset()) - moment().utcOffset();
  date = moment().add(this.timeOffset, 'minutes').locale('ru');
  todayYearNumber: number = +moment().add(this.timeOffset, 'minutes').format('Y');
  todayMonthNumber: number = +moment().add(this.timeOffset, 'minutes').format('M');
  todayDayNumber: number = +moment().add(this.timeOffset, 'minutes').format('D');
  year: number = +moment().add(this.timeOffset, 'minutes').format('YYYY');
  currentYear: string = moment().add(this.timeOffset, 'minutes').locale('ru').format('YYYY');
  currentMonth: string = moment().add(this.timeOffset, 'minutes').locale('ru').format('MMMM');
  nowWeek: number = moment().add(this.timeOffset, 'minutes').locale('ru').isoWeek();
  nowDayWeekNumber: number = moment().add(this.timeOffset, 'minutes').locale('ru').day();
  currentMonthOnCalender = +moment().add(this.timeOffset, 'minutes').format('M');
  currentDayOnCalendar = false;
  private events: Object[] = [];
  eventForDay = {};
  calendarData: Week[] = [];
  typeSelect = TYPE_SELECT;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  selectedDay: Day;
  timezoneOffset: number = 0;
  timeZoneName: string;
  isMinRangeInvalid = false;
  isMaxRangeInvalid = false;
  WEEK = WEEK;

  private userId: number;

  @Input() id;
  @Input() dpID: string;
  @Input() controlName = '';
  @Input() startValue = '';
  @Input() forPlanner = false;
  @Input() fillTitle = false;
  @Input() showMyDuty = false;
  @Input() isAgent = false;

  @Input() minDate: moment.Moment | null = null;
  @Input() maxDate: moment.Moment | null = null;

  @Output() onBeforeMonthViewRender = new EventEmitter();
  @Output() onSelectedDay = new EventEmitter();

  @ViewChild('calendarMain') private calendarMain: ElementRef;

  constructor(
    private calendarService: CalendarService,
    private calendarDataService: CalendarDataService,
    private modalInjectorService: ModalInjectorService,
    private authService: AuthService,
    private employeesService: EmployeesService
  ) {
    this.timezoneOffset = this.authService.getTimezoneOffset();
    this.userId = +this.authService.user_id;
  }

  ngOnInit(): void {
    this.selectedDay = {
      dayNumber: this.todayDayNumber,
      monthNumber: this.todayMonthNumber,
    };

    this.startValue = this.startValue.substr(0, 10);
    if (this.startValue && moment(this.startValue, DATE_FORMAT).isValid()) {
      const startDate = moment(this.startValue, DATE_FORMAT);
      this.currentYear = startDate.format('YYYY');
      this.currentMonth = startDate.format('MMMM');
    }
    this.updateCalendar();
    this.calendarDataService.updateDataEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
      if (!event.id) {
        this.updateCalendar();
      }
      if (event.id === this.id) {
        switch (event.type) {
          case TYPE_SELECT.YEAR:
            this.currentYear = event.value;
            break;
          case TYPE_SELECT.MONTH:
            this.currentMonth = event.value;
            break;
        }
        this.updateCalendar();
      }
    });
    this.calendarDataService.updateDataAllEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.updateCalendar();
    });

    this.onBeforeMonthViewRender.emit(this.calendarData);
    this.timeZoneName = TIMEZONES.find((item) => item.value === this.timezoneOffset).name;
  }

  openMonthPlanner(): void {
    this.calendarDataService.openMonthPlanner({ month: this.currentMonth, year: this.currentYear });
  }

  changeMonth(difference): void {
    const nextMonth = moment(`${this.currentMonth}${this.currentYear}`, 'MMMYYYY').add(difference, 'month');
    this.currentYear = nextMonth.format('YYYY');
    this.currentMonth = nextMonth.format('MMMM');
    this.currentMonthOnCalender = +nextMonth.format('M');
    this.checkCalendarRange(nextMonth);
    this.updateCalendar();
  }

  checkCalendarRange(nextMonth: moment.Moment): void {
    const future = moment().add(60, 'days');
    const past = moment().subtract(365, 'days');

    this.isMinRangeInvalid = nextMonth.valueOf() <= past.valueOf();
    this.isMaxRangeInvalid = nextMonth.valueOf() >= future.valueOf();
  }

  showSelect(type: TYPE_SELECT, event): void {
    const elemWidget = this.calendarMain.nativeElement.offsetParent,
      elem = event.target.getBoundingClientRect(),
      parent = {
        top: elem.top + elem.height - 10,
        left: elem.left,
        height: elem.height,
        width: elem.width,
      },
      countWeek = moment(this.currentYear, 'YYYY').endOf('year').isoWeek();
    this.modalInjectorService.addModal(ModalInjectorConstants.SELECT_DATE, {
      type,
      parent,
      countWeek,
      parentId: this.id,
    });
  }

  public updateCalendar(): void {
    this.currentDayOnCalendar = false;
    const startDate = moment(`${this.currentMonth}${this.currentYear}`, 'MMMYYYY').startOf('week');
    const startDateEvent = startDate.format(BACKEND_DATE_FORMAT);
    this.calendarData = [];
    let week: Day[] = [],
      numberWeek = 0;

    for (let i = 0; i < 6; i++) {
      numberWeek = startDate.isoWeek();
      for (let j = 0; j < 7; j++) {
        const maxDateDisabled = this.maxDate ? startDate.isAfter(this.maxDate, 'day') : false;
        const minDateDisabled = this.minDate ? this.minDate.isAfter(startDate, 'day') : false;

        const currentDate = startDate.toDate();

        const start = DutyHelper.formatStartDateForBackend(currentDate);
        const end = DutyHelper.formatEndDateForBackend(currentDate);

        const currentStart = moment(start).subtract(this.timezoneOffset, 'minutes').format(BACKEND_DATE_TIME_FORMAT);
        const currentEnd = moment(end).subtract(this.timezoneOffset, 'minutes').format(BACKEND_DATE_TIME_FORMAT);

        const dayNumber = +moment(startDate.toDate()).format('D');
        const monthNumber = +moment(startDate.toDate()).format('M');

        const day: Day = {
          dayNumber: dayNumber,
          monthNumber: monthNumber,
          currentMonth: monthNumber === this.currentMonthOnCalender,
          date: startDate.toDate(),
          dayForDatepicker: moment(startDate.toDate()).format(DATE_FORMAT),
          maxDateDisabled,
          minDateDisabled,
          class: 'calendar-day',
          hasEvent: false,
          events: [],
          isToday: false,
          status: '',
        };
        this.getDutyList(currentStart, currentEnd).subscribe((dutyData: DutyData) => {
          day.status = dutyData.day_slots_info;
          day.isMyDuty = dutyData.duty_schedule_list.some(
            (dutyItem) => dutyItem.user_id === this.userId && dutyItem.status === DutyStatus.ACTIVE
          );
          day.isMyProcess = dutyData.duty_schedule_list.some(
            (dutyItem) => dutyItem.user_id === this.userId && dutyItem.status === DutyStatus.PROCESS
          );
        });
        if (this.dpID && !this.forPlanner && day.dayForDatepicker === this.startValue) {
          day.class += ' calendar-day-select';
        }

        if (+moment().add(this.timeOffset, 'minutes').startOf('day') === +startDate.startOf('day')) {
          day.isToday = true;
          this.currentDayOnCalendar = true;
        }

        if (+startDate.format('Y') < +this.todayYearNumber) {
          day.class += ' pale-day';
        } else {
          if (+this.currentMonthOnCalender < this.todayMonthNumber) {
            if (+startDate.format('Y') <= this.todayYearNumber) {
              day.class += ' pale-day';
            }
            if (+startDate.format('Y') > this.todayYearNumber) {
              if (day.monthNumber !== +this.currentMonthOnCalender) {
                day.class += ' pale-day';
              }
            }
          }

          if (+this.currentMonthOnCalender === this.todayMonthNumber) {
            if (day.monthNumber !== +this.currentMonthOnCalender || day.dayNumber < +this.todayDayNumber) {
              day.class += ' pale-day';
            }
          }

          if (+this.currentMonthOnCalender > this.todayMonthNumber) {
            if (day.monthNumber !== +this.currentMonthOnCalender) {
              day.class += ' pale-day';
            }
          }

          if (startDate.valueOf() > moment().add(90, 'd').valueOf()) {
            day.class += ' pale-day';
          }
        }

        if (this.currentMonth !== moment(startDate.toDate()).format('MMMM')) {
          day.class += ' calendar-day-old_month';
        }
        startDate.add(1, 'day');
        week.push(day);
      }
      this.calendarData.push({ number: numberWeek, days: week });
      week = [];
    }
    const endDateEvent = startDate.format(BACKEND_DATE_FORMAT);

    if (!this.dpID) {
      const filterData: EventFilterModel = {
        startDate: startDateEvent,
        endDate: endDateEvent,
      };
      this.calendarDataService.getEventsByPeriod(filterData).subscribe((res: EventModel[]) => {
        this.events = res;
        this.updateEvent();
      });
    }
  }

  private getDutyList(startDate: string, endDate: string): Observable<DutyData> {
    return this.employeesService.getDuty(
      Object.assign({
        from_time: startDate,
        to_time: endDate,
        daily_schedule: true,
      })
    );
  }

  isSelectedDay(day: Day): boolean {
    return day.dayNumber === this.selectedDay?.dayNumber && day.monthNumber === this.selectedDay?.monthNumber;
  }

  selectDate(day): void {
    this.calendarDataService.updateDatepicker({ id: this.dpID, value: day.dayForDatepicker });
    this.modalInjectorService.closeModal(this.id);
  }

  private updateEvent(): void {
    this.eventForDay = {};
    if (Array.isArray(this.events)) {
      this.events.forEach((item) => {
        if (this.eventForDay[item['start_date']] !== 1) {
          // tslint:disable-next-line:no-bitwise
          if (~[STATE.CANCELLED, STATE.EXECUTED].indexOf(item['status'])) {
            this.eventForDay[item['start_date']] = 2;
          } else {
            this.eventForDay[item['start_date']] = 1;
          }
        }
      });
    }
  }

  infoForDay(day): void {
    const parent = this.forPlanner
      ? this.calendarMain.nativeElement.getBoundingClientRect()
      : this.calendarMain.nativeElement.offsetParent.getBoundingClientRect();
    this.modalInjectorService.addModal(ModalInjectorConstants.INFO_FOR_DAY, {
      day: day.day,
      dayForDatepicker: day.dayForDatepicker,
      parent,
      position: this.forPlanner ? POSITION.CENTER : null,
    });
  }

  addEvent(day): void {
    this.modalInjectorService.addModal(ModalInjectorConstants.ADD_EVENT_CALENDAR, { show: true, addEvent: true, day });
  }

  getIsOpacity(day: Day) {
    if (!this.showMyDuty) {
      return false;
    }
    return this.showMyDuty && (day.isMyDuty || day.isMyProcess) ? false : true;
  }

  public selectDay(day: Day): void {
    this.onSelectedDay.emit(day);
    this.onBeforeMonthViewRender.emit(this.calendarData);
    this.selectedDay = day;
  }

  public getColor(status: DaySlot): string {
    switch (status) {
      case DaySlot.FILLED:
        return ' green';
      case DaySlot.PROCESS:
        return ' yellow';
      case DaySlot.BLANK:
        return ' red';
    }
  }
}
