<div class="trade-chat">
  <div class="customer">
    <div class="d-flex align-items-center group-left">
      <button
        class="icon-button chat-expansion__caret"
        [class.disabled]="!customerGroups.length"
        (click)="onToggleExpanded(tradeRoomsTypes.CUSTOMER)"
      >
        <app-svg-icon [name]="isCustomerExpanded ? 'caret_up' : 'caret_down'" width="12" height="12"></app-svg-icon>
      </button>
      <span class="group-title">Торги заказчика</span>
      <div class="group__filters" *ngIf="!isModalView">
        <div class="sort__button">
          <button class="icon-button" (click)="onSortDirectionNext(tradeRoomsTypes.CUSTOMER)">
            <app-svg-icon [name]="'sort-amount-down'" width="12" height="12"></app-svg-icon>
          </button>
        </div>

        <app-chat-groups-sorting
          [isOpen]="isChoosingCustomerSortDirection"
          title="Сортировка групп 'Торги заказчика'"
          [sortOptions]="sortOptions"
          [sortType]="customerSortType"
          (close)="sortPopupClose(tradeRoomsTypes.CUSTOMER)"
          (save)="sortDirectionChange($event, tradeRoomsTypes.CUSTOMER)"
        ></app-chat-groups-sorting>
      </div>
    </div>
    <div class="d-flex align-items-center group-right" *ngIf="!isModalView">
      <button class="icon-button">
        <app-svg-icon [name]="'question-circle-color'" width="16" height="16"></app-svg-icon>
      </button>

      <span
        class="chat-number"
        [style.visibility]="totalUnreadMessagesCounterCustomer ? 'visible' : 'hidden'"
        [class.chat-number__10]="totalUnreadMessagesCounterCustomer === '10+'"
        >{{ totalUnreadMessagesCounterCustomer }}</span
      >
    </div>
  </div>

  <ng-container *ngIf="isCustomerExpanded">
    <ng-container *ngFor="let group of customerGroups | groupSort : customerSortType : themes; trackBy: trackByRoomId">
      <app-chat-group
        [group]="group"
        [sortType]="customerSortType"
        [contactSelected]="contactSelected"
        [isModalView]="isModalView"
      ></app-chat-group>
    </ng-container>
  </ng-container>

  <hr />
  <div class="provider">
    <div class="d-flex align-items-center group-left">
      <button
        class="icon-button chat-expansion__caret"
        [class.disabled]="!providerGroups.length"
        (click)="onToggleExpanded(tradeRoomsTypes.PROVIDER)"
      >
        <app-svg-icon [name]="isProviderExpanded ? 'caret_up' : 'caret_down'" width="12" height="12"></app-svg-icon>
      </button>
      <span class="group-title">Торги поставщика</span>
      <div class="group__filters" *ngIf="!isModalView">
        <div class="sort__button">
          <button class="icon-button" (click)="onSortDirectionNext(tradeRoomsTypes.PROVIDER)">
            <app-svg-icon [name]="'sort-amount-down'" width="12" height="12"></app-svg-icon>
          </button>
        </div>

        <app-chat-groups-sorting
          [isOpen]="isChoosingProviderSortDirection"
          title="Сортировка групп 'Торги поставщика'"
          [sortOptions]="sortOptions"
          [sortType]="providerSortType"
          (close)="sortPopupClose(tradeRoomsTypes.PROVIDER)"
          (save)="sortDirectionChange($event, tradeRoomsTypes.PROVIDER)"
        ></app-chat-groups-sorting>
      </div>
    </div>
    <div class="d-flex align-items-center group-right" *ngIf="!isModalView">
      <button class="icon-button">
        <app-svg-icon [name]="'question-circle-color'" width="16" height="16"></app-svg-icon>
      </button>

      <span
        class="chat-number"
        [style.visibility]="totalUnreadMessagesCounterProvider ? 'visible' : 'hidden'"
        [class.chat-number__10]="totalUnreadMessagesCounterProvider === '10+'"
        >{{ totalUnreadMessagesCounterProvider }}</span
      >
    </div>
  </div>

  <ng-container *ngIf="isProviderExpanded">
    <ng-container *ngFor="let group of providerGroups | groupSort : providerSortType : themes; trackBy: trackByRoomId">
      <app-chat-group
        [group]="group"
        [sortType]="providerSortType"
        [contactSelected]="contactSelected"
        [isModalView]="isModalView"
      ></app-chat-group>
    </ng-container>
  </ng-container>
</div>
