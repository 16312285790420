<div
  (click)="onGroupSelect(group)"
  class="group"
  [class._filter-disabled]="group.filterDisabled"
  [class.info__selected]="isActiveClass"
>
  <div class="d-flex group-left">
    <div class="room-select-checkbox">
      <ng-container *ngIf="isModalView && chatSectionSelected.name !== chatSectionsEnum.TRADE">
        <input
          class="cbx-input"
          type="checkbox"
          id="group_select_{{ group.room_id }}"
          (change)="toggleRoomSelected(group)"
          [checked]="isRoomSelected(group)"
        />
        <label class="cbx-label" [class.cbx-label-interminate]="false" for="group_select_{{ group.room_id }}"></label>
      </ng-container>
    </div>
    <button
      (click)="$event.stopPropagation(); onToggleGroupExpanded()"
      class="icon-button chat-expansion__caret"
      [class.disabled]="!themes.length"
    >
      <app-svg-icon [name]="isGroupExpanded ? 'caret_up' : 'caret_down'" width="12" height="12"></app-svg-icon>
    </button>
    <div class="group-title">
      <div
        [class.text-overflow]="
          chatSectionSelected.name === chatSectionsEnum.ADMIN ||
          chatSectionSelected.name === chatSectionsEnum.TRADE ||
          chatSectionSelected.name === chatSectionsEnum.TECH
        "
        class="group-title-text"
        [class.group-title--long]="
          chatSectionSelected.name !== chatSectionsEnum.ADMIN &&
          chatSectionSelected.name !== chatSectionsEnum.TRADE &&
          chatSectionSelected.name !== chatSectionsEnum.TECH
        "
        [disableTooltip]="group?.title?.length < 20 || nameElement?.offsetWidth >= nameElement?.scrollWidth"
        [ngbTooltip]="group?.title"
      >
        <p
          [class.text-overflow]="
            chatSectionSelected.name === chatSectionsEnum.ADMIN ||
            chatSectionSelected.name === chatSectionsEnum.TRADE ||
            chatSectionSelected.name === chatSectionsEnum.TECH
          "
          #nameElement
          [innerHTML]="group?.filterDisabled ? group?.title : (group?.title | highlight : filterString)"
        ></p>
      </div>
    </div>
  </div>

  <div
    *ngIf="!isModalView"
    class="d-flex align-items-center group-right justify-content-end"
    [class.info__selected]="isActiveClass"
  >
    <button
      class="icon-button cog-button"
      *ngIf="chatSectionSelected.name !== chatSectionsEnum.TRADE"
      [style.visibility]="techType !== TechTypeEnum.MY_TECH ? 'visible' : 'hidden'"
      (click)="$event.stopPropagation()"
      placement="left"
      [ngbPopover]="settingsContent"
      container="body"
      [class.disabled]="isOwner"
    >
      <app-svg-icon [name]="'cog'" width="16" height="16"></app-svg-icon>
    </button>
    <span
      class="chat-number"
      [style.visibility]="totalUnreadMessagesCounter ? 'visible' : 'hidden'"
      [class.chat-number__10]="totalUnreadMessagesCounter === '10+'"
      [class.trade-chat-number]="chatSectionSelected.name === chatSectionsEnum.TRADE"
    >
      {{ totalUnreadMessagesCounter }}
    </span>
  </div>
</div>

<ng-container *ngFor="let theme of themes | groupSort : sortType : themes; trackBy: trackByRoomId">
  <app-chat-theme
    *ngIf="isGroupExpanded"
    [sortType]="sortType"
    [theme]="theme"
    [group]="group"
    [userId]="userId"
    [userType]="user?.type"
    [contactSelected]="contactSelected"
    [techType]="techType"
    [isModalView]="isModalView"
    [isGroupExpanded]="isGroupExpanded"
  ></app-chat-theme>
</ng-container>

<ng-template #settingsContent>
  <button class="settings-buttons" (click)="$event.stopPropagation(); openRenameGroupModal()">
    <app-svg-icon [name]="'edit_list'" width="15" height="15"></app-svg-icon>
    Переименовать группу
  </button>
  <button class="settings-buttons" (click)="$event.stopPropagation(); onGroupEdit()">
    <app-svg-icon [name]="'users'" width="15" height="15"></app-svg-icon>
    Редактировать группу
  </button>
  <button class="settings-buttons" (click)="$event.stopPropagation(); onRemoveGroupAsk()">
    <app-svg-icon [name]="'delete'" width="15" height="15"></app-svg-icon>
    Удалить группу
  </button>
  <button
    class="settings-buttons"
    (click)="$event.stopPropagation(); openCreateThemeModal()"
    [disabled]="disableCreateThemeButtonInTech"
  >
    <app-svg-icon [name]="'plus'" width="15" height="15"></app-svg-icon>
    Создать тему
  </button>
</ng-template>
