import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FilesHelper } from '@app/shared/helpers/files.helper';

@Component({
  selector: 'app-logo-upload',
  templateUrl: './logo-upload.component.html',
  styleUrls: ['./logo-upload.component.scss'],
})
export class LogoUploadComponent {
  @Input() disabled: boolean;
  @Input() readonly = false;
  @Input() url: string;

  @ViewChild('file') fileInput;

  @Output() fileAdded = new EventEmitter<File>();
  @Output() fileSelect = new EventEmitter<void>();
  @Output() fileCleared = new EventEmitter<void>();

  handleFileClick(event) {
    event.stopPropagation();
    this.fileSelect.emit();
  }

  addFile(event) {
    const file = event.target.files[0];
    this.fileAdded.emit(file);

    FilesHelper.readFileAsDataURL(file).then((value) => {
      this.url = value;
    });
  }

  clear(event: MouseEvent) {
    event.stopPropagation();
    this.fileCleared.emit();
    this.fileInput.nativeElement.value = null;
  }
}
