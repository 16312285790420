import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { ChatService } from '@app/chat/services/chat.service';
import { DestroyService } from '@app/services/destroy.service';

@Component({
  selector: 'app-chat-contacts-filter',
  templateUrl: './chat-contacts-filter.component.html',
  styleUrls: ['./chat-contacts-filter.component.scss'],
  providers: [DestroyService],
})
export class ChatContactsFilterComponent implements OnInit {
  searchQuery: FormControl = new FormControl('');

  get isSearchQuery(): boolean {
    return this.searchQuery?.dirty && this.searchQuery.value.length;
  }

  constructor(private chatService: ChatService, private readonly destroy$: DestroyService) {}

  ngOnInit() {
    this.searchQuery.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((searchText) => {
        this.chatService.filterContactsGroupsThemes(searchText);
      });
  }

  resetSearch(): void {
    this.searchQuery.reset();
  }
}
