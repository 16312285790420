import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SupplierLotGroup, SupplierNomenclatureGroup, SupplierCriteriaGroup } from '../form-types/lots';
import { Lot, ExtraCriteria, TradeProviderLot, ExtraNomenclature, TradeProviderLotsMap } from '../models/lots.model';
import { TradesHelper } from '../helpers/trades.helper';
import { SupplierTradeCard } from '../models/suppliers.model';
import { cloneDeep, isNull, isUndefined } from 'lodash-es';

@Injectable()
export class SuppliersLotsService {
  private _lots: Lot[] = [];
  get lots() {
    return this._lots;
  }

  private _providerLots: TradeProviderLot[] = [];
  get providerLots() {
    return this._providerLots;
  }

  private _hasChanges: boolean;
  get hasChanges() {
    return this._hasChanges;
  }

  private _initProviderLotsMap: TradeProviderLotsMap;

  constructor(private fb: FormBuilder) {}

  initLots(card: SupplierTradeCard) {
    this._hasChanges = false;
    this._providerLots = cloneDeep(card?.temp_data?.lots.length ? card.temp_data.lots : card.lots);
    this._initProviderLotsMap = TradesHelper.getProviderLotsMap(card.lots);
    this._lots = TradesHelper.getInitalLots(card, TradesHelper.getProviderLotsMap(this._providerLots));
  }

  getUpdatedProviderLots() {
    if (!this._initProviderLotsMap) {
      return this._providerLots;
    }

    return cloneDeep(this._providerLots).map((lot) => {
      const mapLotId = `${lot.trade_lot_id}`;

      lot.nomenclatures_provider.map((nom) => {
        const mapNomId = `${mapLotId}.${nom.trade_nomenclature_id}`;

        nom.criteria_provider.map((criteria) => {
          const initCriteria = this._initProviderLotsMap.criteria.get(`${mapNomId}.${criteria.trade_criterion_id}`);

          if (initCriteria?.id && initCriteria?.value?.toString() !== criteria.value?.toString()) {
            criteria.id = initCriteria.id;
          }
        });

        if (this._initProviderLotsMap.nomenclatures.has(mapNomId)) {
          nom.id = this._initProviderLotsMap.nomenclatures.get(mapNomId).id;
        }
      });

      if (this._initProviderLotsMap.lots.has(mapLotId)) {
        lot.id = this._initProviderLotsMap.lots.get(mapLotId).id;
      }

      return lot;
    });
  }

  getLotsForm(lots: Lot[]) {
    return this.fb.array<FormGroup<SupplierLotGroup>>(lots.map((lot) => this.getLotGroup(lot)));
  }

  formChanged(providerLots: TradeProviderLot[]) {
    this._hasChanges = true;
    this._providerLots = providerLots;
  }

  private getLotGroup(lot: Lot): FormGroup<SupplierLotGroup> {
    const nomFormArray = this.fb.nonNullable.array<FormGroup<SupplierNomenclatureGroup>>(
      lot.extra.nomenclatures.map((nomenclature) => this.getNomenclatureGroup(nomenclature))
    );
    return this.fb.group<SupplierLotGroup>({
      deleted: this.fb.nonNullable.control(false),
      nomenclatures_provider: nomFormArray,
      trade_lot_id: this.fb.nonNullable.control(lot.id),
    });
  }

  private getNomenclatureGroup(nomenclature: ExtraNomenclature): FormGroup<SupplierNomenclatureGroup> {
    nomenclature.expanded = true;
    const criteriaFormArray = this.fb.nonNullable.array<FormGroup<SupplierCriteriaGroup>>(
      nomenclature.criteria.map((criteria) => this.getCriteriaGroup(criteria))
    );
    return this.fb.group<SupplierNomenclatureGroup>({
      trade_nomenclature_id: this.fb.nonNullable.control(nomenclature.id),
      criteria_provider: criteriaFormArray,
    });
  }

  private getCriteriaGroup(criteria: ExtraCriteria): FormGroup<SupplierCriteriaGroup> {
    let value = !isNull(criteria.value) && !isUndefined(criteria.value) ? criteria.value : null;

    if (value === 'true') {
      value = true;
    }

    if (value === 'false') {
      value = false;
    }

    return this.fb.group<SupplierCriteriaGroup>({
      trade_criterion_id: this.fb.nonNullable.control(criteria.id),
      value: this.fb.control<string | boolean>(value, [Validators.required]),
    });
  }
}
