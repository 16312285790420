<div class="group-edit">
  <h4 class="text-center">Редактировать {{ editingObject.group_id ? 'тему' : 'группу' }}</h4>
  <p class="text-center">{{ editingObject.title }}</p>
  <div class="kt-flow">
    <div class="kt-flow-item">
      <div class="text-left filter-chat-contacts">
        <p class="mb-0">{{ editingObject.group_id ? 'Участники группы' : 'Все пользователи' }}</p>
        <button
          class="icon-button filter"
          [class.active]="leftContactsOnlyFilter"
          type="button"
          (click)="$event.stopPropagation(); onFilterToggle(true, 'leftEditGroup')"
        >
          <app-svg-icon [name]="'filter-table'" width="15" height="15"></app-svg-icon>
        </button>
        <button
          *ngIf="leftContactsOnlyFilter"
          class="icon-button"
          type="button"
          (click)="$event.stopPropagation(); onClearFilter('leftEditGroup')"
        >
          <app-svg-icon [name]="'times'" width="15" height="15"></app-svg-icon>
        </button>
      </div>
    </div>
    <div class="kt-flow-item"></div>
    <div class="kt-flow-item">
      <div class="text-left filter-chat-contacts">
        <p class="mb-0">
          {{ editingObject.group_id ? 'Участники темы' : 'Участники группы' }}
          <span class="chat-number" [class.chat-number__10]="groupContacts?.length > 10">
            {{ groupContacts?.length > 10 ? '10+' : groupContacts?.length }}
          </span>
        </p>

        <button
          class="icon-button filter"
          [class.active]="rightContactsOnlyFilter"
          type="button"
          (click)="$event.stopPropagation(); onFilterToggle(true, 'rightEditGroup')"
        >
          <app-svg-icon [name]="'filter-table'" width="15" height="15"></app-svg-icon>
        </button>
        <button
          *ngIf="rightContactsOnlyFilter"
          class="icon-button"
          type="button"
          (click)="$event.stopPropagation(); onClearFilter('rightEditGroup')"
        >
          <app-svg-icon [name]="'times'" width="15" height="15"></app-svg-icon>
        </button>
      </div>
    </div>
    <div class="kt-flow-item">
      <dc-form-field-wrapper [style]="'material'" [vertically]="false" class="search-contacts">
        <dc-form-input isShowClear="true">
          <input
            formInput
            placeholder="Начните вводить ФИО сотрудника"
            (mousedown)="$event.stopPropagation()"
            [(ngModel)]="contactsValue"
            (input)="onFilterEdit($event)"
            class="form-control"
            type="text"
          />
        </dc-form-input>
      </dc-form-field-wrapper>
    </div>
    <div class="kt-flow-item"></div>
    <div class="kt-flow-item">
      <dc-form-field-wrapper [style]="'material'" [vertically]="false" class="search-contacts">
        <dc-form-input isShowClear="true">
          <input
            formInput
            [(ngModel)]="membersValue"
            class="form-control"
            placeholder="Начните вводить ФИО сотрудника"
            type="text"
          />
        </dc-form-input>
      </dc-form-field-wrapper>
    </div>

    <div class="kt-flow-item">
      <input
        class="cbx-input"
        id="allContacts"
        type="checkbox"
        [disabled]="!haveSelectableContacts"
        [(ngModel)]="allContactsCheckbox"
        (ngModelChange)="selectAllContacts($event)"
      />
      <label class="cbx-label select-all" for="allContacts" [class.cbx-label-interminate]="contactsPartiallySelected">
        <span> Выделить всё/снять выделение </span>
      </label>
    </div>
    <div class="kt-flow-item"></div>
    <div class="kt-flow-item">
      <input
        class="cbx-input"
        id="allMembers"
        type="checkbox"
        [disabled]="disableRightAllCheckBox"
        [(ngModel)]="allMembersCheckbox"
        (ngModelChange)="selectAllMembers($event)"
      />
      <label class="cbx-label select-all" for="allMembers" [class.cbx-label-interminate]="membersPartiallySelected">
        <span> Выделить всё/снять выделение </span>
      </label>
    </div>

    <div class="kt-flow-item kt-dual-list-without-level-padding overflow" [ngClass]="{ 'overflow-x': !isHoldingGroup }">
      <ng-container *ngIf="isHoldingGroup && !isTechChat">
        <tree-root
          #tree
          [nodes]="userTreeList | contactsFilter : leftContactsOnlyFilter"
          [options]="customTemplateStringOptions"
        >
          <ng-template #treeNodeTemplate let-node>
            <app-chat-user-item
              *ngIf="node?.data?.status !== statuses.IN_ARCHIVE"
              [item]="node.data"
              [node]="node"
              (onUserDbClick)="onContactClick(node.data)"
              [isAccessChangeOptionsShowed]="false"
              [userId]="userId"
              [searchString]="contactsValue"
              (onToggle)="expandItem(tree, node, $event)"
              (onCheckboxChanges)="selectContact($event)"
              (itemVisibleChanged)="onNodeVisibleChanged($event, node.data, 'left')"
            >
            </app-chat-user-item>
          </ng-template>
        </tree-root>
      </ng-container>
      <ng-container *ngIf="!isHoldingGroup">
        <ng-container
          *ngFor="
            let contact of leftUserList
              | usersInitialsFilter : contactsValue.toLowerCase()
              | contactsFilter : leftContactsOnlyFilter
              | accessUsersSort : editingObject.owner_id
          "
        >
          <app-chat-user-item
            *ngIf="contact?.status !== statuses.IN_ARCHIVE"
            [item]="contact"
            (onUserDbClick)="onContactClick(contact)"
            [isAccessChangeOptionsShowed]="false"
            [userId]="userId"
            [searchString]="contactsValue"
            (onCheckboxChanges)="selectContact($event)"
            (itemVisibleChanged)="onNodeVisibleChanged($event, contact, 'left')"
          >
          </app-chat-user-item>
        </ng-container>
      </ng-container>
    </div>
    <div class="kt-flow-item flow-buttons">
      <button [class.disabled]="!selectedContacts.length" (click)="moveToGroup()" class="icon-button">
        <app-svg-icon [width]="25" [height]="25" name="caret-square-right"></app-svg-icon>
      </button>

      <button [class.disabled]="!selectedMembers.length" (click)="moveFromGroup()" class="icon-button">
        <app-svg-icon [width]="25" [height]="25" name="caret-square-left"></app-svg-icon>
      </button>
    </div>
    <div class="kt-flow-item overflow overflow-x">
      <ng-container *ngIf="!isTechChat">
        <ng-container
          *ngFor="
            let contact of groupContacts
              | usersInitialsFilter : membersValue.toLowerCase()
              | contactsFilter : rightContactsOnlyFilter
              | accessUsersSort : editingObject.owner_id
          "
        >
          <app-chat-user-item
            *ngIf="contact.status !== statuses.IN_ARCHIVE"
            [item]="contact"
            [ownerId]="editingObject.owner_id"
            (onUserDbClick)="onGroupContactClick(contact)"
            [userId]="userId"
            [userType]="contact.type"
            [isGroup]="isGroup"
            [isRightPart]="true"
            [searchString]="membersValue"
            (onSelectOwnerGroup)="changeOwner($event)"
            (onCheckboxChanges)="selectMember($event)"
            (itemVisibleChanged)="onNodeVisibleChanged($event, contact, 'right')"
          >
          </app-chat-user-item>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isTechChat">
        <ng-container
          *ngFor="
            let contact of groupContacts
              | usersInitialsFilter : membersValue.toLowerCase()
              | techChatUsersSort : editingObject.owner_id
          "
        >
          <app-chat-user-item
            *ngIf="contact.status !== statuses.IN_ARCHIVE"
            [item]="contact"
            [ownerId]="editingObject.owner_id"
            (onUserDbClick)="onGroupContactClick(contact)"
            [isOwnerDutyTso]="editingObject.flags?.is_owner_duty_tso"
            [userId]="userId"
            [userType]="contact.type"
            [isGroup]="isGroup"
            [techType]="editingObject.tech_section_type"
            [isRightPart]="true"
            [isCurrentUserDutyTso]="isCurrentUserDutyTso"
            [searchString]="membersValue"
            (onSelectOwnerGroup)="changeOwner($event)"
            (onCheckboxChanges)="selectMember($event)"
            (itemVisibleChanged)="onNodeVisibleChanged($event, contact, 'right')"
          >
          </app-chat-user-item>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="buttons-block">
    <button (click)="onCancelGroupEdit()" class="btn btn-dc-outline" type="button">Отменить</button>
    <button [disabled]="isChanged" type="button" (click)="edit()" class="btn btn-dc-primary">Сохранить</button>
  </div>
</div>

<app-modal-window
  [isShowed]="isFilterForLeftSideOpen$ | async"
  (closeEvent)="onFilterToggle(false, 'leftEditGroup')"
  centered="true"
  customClass="contacts-only-filter-modal"
>
  <app-chat-contacts-only-filter [filterKey]="'leftEditGroup'" [isGroupEditing]="true"></app-chat-contacts-only-filter>
</app-modal-window>

<app-modal-window
  [isShowed]="isFilterForRightSideOpen$ | async"
  (closeEvent)="onFilterToggle(false, 'rightEditGroup')"
  centered="true"
  customClass="contacts-only-filter-modal"
>
  <app-chat-contacts-only-filter [filterKey]="'rightEditGroup'" [isGroupEditing]="true"></app-chat-contacts-only-filter>
</app-modal-window>
