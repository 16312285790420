<app-chat *ngIf="isChatOpen$ | async"></app-chat>
<app-notification *ngIf="isNotificationOpen$ | async"></app-notification>
<router-outlet></router-outlet>
<simple-notifications [options]="options"></simple-notifications>
<app-modal-injector></app-modal-injector>

<ng-container #projectModalContainer></ng-container>
<div class="portals-outlet">
  <ng-container #portalContainer cdkPortalOutlet></ng-container>
</div>
