<div class="d-flex align-items-center">
  <div class="mr-2">
    <input
      class="cbx-input"
      [id]="uniquePrefix + item.id"
      type="checkbox"
      [disabled]="readonly"
      (change)="onCheckboxChange($event.target.checked)"
      [checked]="item.selected"
    />
    <label
      class="cbx-label"
      [for]="uniquePrefix + item.id"
      [class.cbx-label-interminate]="item.selected === null"
    ></label>
  </div>

  <div
    class="dc-competence-tree-item dc-competence-tree-item--competency"
    [class.selected]="selected"
    [class.checked]="item.selected"
    (click)="onSelectClicked()"
  >
    <div class="dc-competence-tree-item-inner">
      <div class="dc-competence-tree-item-heading">
        <app-svg-icon
          [name]="!node.isExpanded ? 'caret_down' : 'caret_up'"
          [style.opacity]="node.hasChildren ? 1 : 0.3"
          [class.disabled]="node.level === 3"
          (click)="toggleNodeClicked($event)"
          width="10"
        ></app-svg-icon>

        <div class="dc-competence-tree-item-title" [class.inactive]="node.data.searchedInactive">
          <span [innerHTML]="title | highlight : searchValue"></span>
        </div>
      </div>
      <div
        *ngIf="!!selected && node.level !== 3 && isShowDoubleArrow"
        class="dc-competence-tree-item-d-arrow"
        [class.disabled]="!node.hasChildren"
      >
        <app-svg-icon
          [name]="!node.isExpanded ? 'd-arrow-down' : 'd-arrow-up'"
          (click)="doubleArrowClicked()"
          width="10"
        ></app-svg-icon>
      </div>
    </div>
  </div>
</div>
