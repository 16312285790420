<div
  *ngIf="lot"
  class="lot-item lot-item-{{ lot.number }}"
  [class.trades-view]="isTrades"
  [class.expanded]="lot.extra?.expanded"
>
  <div class="lot-item-header-wrap">
    <div class="lot-item-header">
      <app-svg-icon
        class="lot-item__icon"
        width="12"
        height="12"
        [name]="lot.extra?.expanded ? 'caret_up' : 'caret_down'"
        (click)="lot.extra.expanded = !lot.extra.expanded"
      ></app-svg-icon>
      <div class="lot-item__number">Лот №{{ lot.number }}</div>
      <div class="lot-item__title">{{ lot.title }}</div>
    </div>

    <div class="lot-item-info margin-b-20">
      <b class="margin-r-80">
        Место:
        <span class="lot-item-info__value">{{ rating?.place_lot | number : '1.0-0' }}</span>
      </b>
      <b class="margin-r-50">
        Балл (лид.):
        <span class="lot-item-info__value">
          {{ rating?.points_lot | ratingValue }}
          ({{ hideLeaders ? '-' : (rating?.points_leader_lot | ratingValue) }})
        </span>
      </b>
      <b
        >Вес лота: <span class="lot-item-info__value">{{ lot.weight }}</span></b
      >
    </div>

    <div
      *ngIf="isTrades && (lot.extra?.expanded || !collapsedNomenclatures || !criteriaCollapsed) && lot.extra?.expanded"
      class="expand-criteria"
      [class.expand-criteria--large]="collapsedNomenclatures"
    >
      <app-svg-icon
        width="12"
        height="12"
        class="cursor-pointer"
        [name]="criteriaCollapsed ? 'plus-square' : 'minus-square'"
        (click)="criteriaCollapsed = !criteriaCollapsed"
      ></app-svg-icon>
      <div *ngIf="!criteriaCollapsed" class="expand-line"></div>
    </div>
  </div>

  <a *ngIf="lot?.extra?.expanded && !readonly && !isTrades" class="lot-item-clear blue-links" (click)="reset.emit()">
    <app-svg-icon name="trash" width="16" height="16"></app-svg-icon>
    Очистить данные
  </a>

  <div *ngIf="lot?.extra?.expanded" class="lots-nomenclature-details">
    <div
      class="lots-table lots-table-nomenclatures"
      [class.edit]="!readonly"
      [class.collapsed]="collapsedNomenclatures"
    >
      <div class="lots-table-header">
        <div class="lots-table-row nomenclature-top">
          <div *ngIf="!readonly" class="lots-table-header-actions">
            <app-svg-icon
              class="cursor-pointer"
              name="cog"
              width="16"
              height="16"
              (click)="changeDisplayNomenclaturesActions($event, true)"
            ></app-svg-icon>
            <ul
              class="service-menu"
              *ngIf="isNomenclaturesActionsOpened"
              appClickElsewhere
              (appClickElsewhere)="changeDisplayNomenclaturesActions($event, false)"
            >
              <li
                class="service-menu-item cursor-pointer"
                [class.disabled]="!selectedNomenclaturesSet.size || !canCollapse"
                (click)="toggleExpandNomenclatures($event, false)"
              >
                <app-svg-icon name="plus-square" width="16" height="16"></app-svg-icon>
                <span>Свернуть выделенные строки</span>
              </li>
              <li
                class="service-menu-item cursor-pointer"
                [class.disabled]="!selectedNomenclaturesSet.size || !canExpand"
                (click)="toggleExpandNomenclatures($event, true)"
              >
                <app-svg-icon name="minus-square" width="16" height="16"></app-svg-icon>
                <span>Развернуть выделенные строки</span>
              </li>
            </ul>
          </div>

          <div class="lots-table-cell nomenclature-top-title">
            <span class="margin-r-4">Номенклатура</span>
            <app-faq-tooltip
              tooltipName="supplier-nomenclature-help"
              class="faq-tooltip-brand faq-tooltip-md"
            ></app-faq-tooltip>
          </div>

          <div class="lots-table-cell no-expand-cells-icon">
            <app-svg-icon
              width="12"
              height="12"
              class="cursor-pointer"
              [name]="collapsedNomenclatures ? 'plus-square' : 'minus-square'"
              (click)="collapsedNomenclatures = !collapsedNomenclatures"
            ></app-svg-icon>
          </div>

          <div *ngIf="!collapsedNomenclatures" class="lots-table-cell nomenclature-top-expand padding-0">
            <div class="expand-line"></div>
          </div>
        </div>
        <div class="lots-table-row nomenclature-cells">
          <div *ngIf="!readonly" class="nomenclature-selected">
            <input
              #all
              id="nomenclature-selected-all"
              class="cbx-input"
              type="checkbox"
              [checked]="selectedNomenclaturesSet.size === lot.nomenclatures.length"
              (change)="selectAllNomenclatures(all.checked)"
            />
            <label class="cbx-label" for="nomenclature-selected-all"></label>
          </div>
          <div class="lots-table-cell nomenclature-place">Ваше место</div>
          <div class="lots-table-cell nomenclature-title">Наименование номенклатуры</div>
          <ng-container *ngIf="!collapsedNomenclatures">
            <div class="lots-table-cell nomenclature-unit">Ед. измерения</div>
            <div class="lots-table-cell nomenclature-lid">Балл (лид.)</div>
            <div class="lots-table-cell nomenclature-weight">Вес строки</div>
          </ng-container>
        </div>
      </div>
      <div class="lots-table-body">
        <ng-container *ngFor="let nomenclature of lot.extra.nomenclatures; index as nIndex">
          <div class="d-flex align-items-center">
            <div class="lots-table-row nomenclature-cells">
              <div *ngIf="!readonly" class="nomenclature-selected">
                <input
                  #select
                  id="nomenclature-selected-{{ nIndex }}"
                  class="cbx-input"
                  type="checkbox"
                  [checked]="nomenclature.selected"
                  (change)="selectNomenclature(nomenclature, select.checked)"
                />
                <label class="cbx-label" for="nomenclature-selected-{{ nIndex }}"></label>
              </div>
              <ng-container
                *ngIf="nomenclature.expanded && { value: getNomenclatureRating(nomenclature.id) } as nomRating"
              >
                <div class="lots-table-cell nomenclature-place">
                  <ng-container *ngIf="nomenclature.expanded">
                    {{ nomRating.value?.place_rating_nomenclature | ratingValue }}
                  </ng-container>
                </div>
                <div class="lots-table-cell nomenclature-title">{{ nomenclature.title }}</div>
                <ng-container *ngIf="!collapsedNomenclatures">
                  <div
                    class="lots-table-cell nomenclature-unit"
                    [ngbTooltipDisabled]="!unitsMap.get(nomenclature.unit_id)?.name"
                    [ngbTooltip]="unitsMap.get(nomenclature.unit_id)?.full_name"
                  >
                    {{ unitsMap.get(nomenclature.unit_id)?.name || '–' }}
                  </div>
                  <div class="lots-table-cell nomenclature-lid" [class.collapsed]="!nomenclature.expanded">
                    {{ nomRating.value?.points_rating_nomenclature | ratingValue }}
                    ({{ !hideLeaders ? (nomRating.value?.points_leader_rating_nomenclature | ratingValue) : '–' }})
                  </div>
                  <div class="lots-table-cell nomenclature-weight">{{ nomenclature.weight }}</div>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="!nomenclature.expanded">
                <div class="lots-table-cell nomenclature-place collapsed blue-links">
                  <span class="stroke-name">Строка {{ nIndex + 1 }}</span>
                  <app-svg-icon
                    name="d-arrow-down"
                    width="16"
                    height="16"
                    (click)="nomenclature.expanded = true"
                  ></app-svg-icon>
                </div>
                <div class="lots-table-cell collapsed bg-snow"></div>
                <ng-container *ngIf="!collapsedNomenclatures">
                  <div class="lots-table-cell collapsed bg-snow"></div>
                  <div class="lots-table-cell collapsed bg-snow"></div>
                  <div class="lots-table-cell collapsed bg-snow"></div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="form" [formGroup]="form">
      <app-supplier-lot-criteria
        *ngFor="let criteria of lot.extra.criteria; index as cIndex"
        formGroupName="nomenclatures_provider"
        [index]="cIndex"
        [nomenclatures]="lot.extra.nomenclatures"
        [criteria]="criteria"
        [rating]="rating"
        [hideLeaders]="hideLeaders"
        [readonly]="readonly"
        [hidden]="criteriaCollapsed"
        [isTrades]="isTrades"
      ></app-supplier-lot-criteria>
    </ng-container>
  </div>
</div>
