import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

interface ModalWindowOptions {
  windowClass?: string;
  backdrop?: NgbModalOptions['backdrop'];
  keyboard?: NgbModalOptions['keyboard'];
  disableClose?: boolean;
  beforeDismiss?: () => boolean | Promise<boolean>;
  centered?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private modalService: NgbModal) {}

  open<T>(
    component: T,
    data?: ModalWindowOptions,
    componentInputs?: Record<string | number, any>
  ): NgbModalRef & { componentInstance: T } {
    const modalInstance = this.modalService.open(component, {
      windowClass: `dc-modal modal-window ${data?.windowClass || ''}`,
      backdrop: data?.disableClose ? 'static' : data?.backdrop || true,
      keyboard: data?.keyboard || true,
      beforeDismiss: data?.beforeDismiss,
      centered: data?.centered || false,
    });

    const componentInstance: T = modalInstance.componentInstance;

    if (componentInputs) {
      for (const key in componentInputs) {
        componentInstance[key] = componentInputs[key];
      }
    }

    this.addCloseButton(modalInstance, Boolean(data?.disableClose));

    return modalInstance as NgbModalRef & { componentInstance: T };
  }

  private addCloseButton(modalInstance: NgbModalRef, disableClose: boolean) {
    const closeButton = document.createElement('div');
    closeButton.classList.add('move-pin__close-button');

    if (disableClose) {
      closeButton.classList.add('disabled_icon');
    }

    closeButton.setAttribute('appTestLocator', 'modal__close-button');

    const modalContent = modalInstance['_windowCmptRef']?.location?.nativeElement?.querySelector('.modal-content');
    modalContent?.appendChild(closeButton);

    closeButton.addEventListener('click', () => {
      if (!disableClose) {
        modalInstance.close();
      }
    });

    if (disableClose) {
      closeButton.classList.add('disabled_icon');
    }
  }
}
