<p class="mb-4">
  {{
    isGroup ? 'Вы действительно хотите удалить группу и входящие в нее темы?' : 'Вы действительно хотите удалить тему?'
  }}
</p>

<p class="mb-5 text-overflow">Восстановить удаленные данные будет невозможно!</p>

<div class="d-flex justify-content-between">
  <button class="btn btn-dc-outline" (click)="approve(false)">Нет, отменить</button>

  <button class="btn btn-dc-primary" (click)="approve(true)">Да, применить</button>
</div>
