<app-rich-modal
  [resizable]="!(isCollapsed$ | async)"
  [movable]="true"
  [pinable]="true"
  (close)="onChatClose()"
  class="chat"
  modalType="chat"
  [closable]="!(isCollapsed$ | async)"
  [class.chat__collapsed]="isCollapsed$ | async"
>
  <ng-container>
    <div class="chat__loader" *ngIf="chatLoading$ | async">
      <app-svg-icon [height]="64" [width]="64" name="loader-animate" class="chat__loader-icon"></app-svg-icon>
    </div>
    <div class="content">
      <div class="left" [style.width]="isContactsHidden ? '0' : ''" *ngIf="!(isCollapsed$ | async)">
        <div [ngClass]="isContactsHidden ? 'hidden' : ''">
          <app-chat-section-select></app-chat-section-select>
        </div>
        <div class="filter">
          <div [ngClass]="isContactsHidden ? 'hidden' : ''">
            <app-chat-contacts-filter></app-chat-contacts-filter>
          </div>
        </div>
        <div
          class="groups"
          [style.overflow-x]="
            chatSectionSelected.name !== chatSectionsEnum.ADMIN && chatSectionSelected.name !== chatSectionsEnum.TRADE
              ? 'auto'
              : 'hidden'
          "
          [style.max-width]="isContactsHidden ? '0' : ''"
        >
          <div
            [ngClass]="isContactsHidden ? 'hidden' : ''"
            *ngIf="
              chatSectionSelected.name !== chatSectionsEnum.TECH && chatSectionSelected.name !== chatSectionsEnum.TRADE
            "
            class="groups-content"
          >
            <app-chat-groups></app-chat-groups>
            <app-chat-contacts
              *ngIf="
                chatSectionSelected.name !== chatSectionsEnum.TECH &&
                chatSectionSelected.name !== chatSectionsEnum.DUTY_TSO &&
                chatSectionSelected.name !== chatSectionsEnum.TRADE
              "
            ></app-chat-contacts>
          </div>
          <div
            class="chat__tech"
            [ngClass]="isContactsHidden ? 'hidden' : ''"
            *ngIf="chatSectionSelected.name === chatSectionsEnum.TECH"
          >
            <app-tech-chat></app-tech-chat>
          </div>

          <div [ngClass]="isContactsHidden ? 'hidden' : ''" *ngIf="chatSectionSelected.name === chatSectionsEnum.TRADE">
            <app-trade-chat></app-trade-chat>
          </div>
        </div>
      </div>
      <div class="center" *ngIf="!(isCollapsed$ | async)">
        <button
          class="icon-button"
          [class]="!isContactsHidden ? 'icon-button-left' : ''"
          (click)="$event.stopPropagation(); toggleContactsHidden()"
        >
          <app-svg-icon [name]="isContactsHidden ? 'right' : 'left'" width="6" height="10"></app-svg-icon>
        </button>
      </div>
      <div class="right" [class.flex-row]="isCollapsed$ | async">
        <span
          *ngIf="totalUnreadMessages && (isCollapsed$ | async)"
          class="chat-number"
          [class.chat-number__10]="totalUnreadMessages > 10"
        >
          {{ totalUnreadMessages <= 10 ? totalUnreadMessages : '10+' }}
        </span>
        <app-chat-tabs></app-chat-tabs>
        <app-chat-message-selected-panel
          *ngIf="!(isCollapsed$ | async)"
          [ngClass]="{ 'visibility-hidden': !isMessageSelected }"
        ></app-chat-message-selected-panel>
        <ng-container *ngIf="contactSelected.room_id && !(isCollapsed$ | async); else noGroupSelected">
          <div class="chat-messages">
            <app-chat-messages></app-chat-messages>
          </div>
          <div>
            <app-chat-input *ngIf="!isTradeGroup(contactSelected)"></app-chat-input>
          </div>
        </ng-container>
      </div>
    </div>

    <!-- popup for editing group -->
    <app-modal-window
      [isShowed]="isEditingGroupOrTheme"
      (closeEvent)="closeEditingGroupOrTheme()"
      centered="true"
      customClass="edit-group-modal"
    >
      <app-chat-group-or-theme-edit *ngIf="isEditingGroupOrTheme"></app-chat-group-or-theme-edit>
    </app-modal-window>
    <!-- popup for editing group end -->
    <!-- popup for editing group -->
    <!-- ФИЛЬТРЫ ТОЖЕ ОБОРАЧИВАЕМ В МОДАЛКУ! -->

    <app-modal-window
      [isShowed]="isEditingContactsOnlyFilter | async"
      (closeEvent)="closeEditingGroupOrTheme()"
      centered="true"
      customClass="contacts-only-filter-modal"
    >
      <app-chat-contacts-only-filter
        [hiddenFilters]="chatSectionSelected.name === chatSectionsEnum.ADMIN ? ['companies'] : []"
        [filterKey]="contactsFilterName"
      ></app-chat-contacts-only-filter>
    </app-modal-window>
  </ng-container>
</app-rich-modal>

<ng-template #collapsedChatTemplate>
  <span *ngIf="totalUnreadMessages" class="chat-number" [class.chat-number__10]="totalUnreadMessages > 10">
    {{ totalUnreadMessages <= 10 ? totalUnreadMessages : '10+' }}
  </span>

  <app-chat-tabs></app-chat-tabs>
</ng-template>

<ng-template #noGroupSelected>
  <div *ngIf="!(isCollapsed$ | async)" class="no-group-select">Нет открытых каналов</div>
</ng-template>
