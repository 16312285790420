<div class="progress-container">
  <h6 class="title">{{ title }}</h6>
  <div class="progress-circle">
    <svg viewBox="0 0 36 36" class="circular-chart">
      <path
        d="M18 18
        m -17.5, 0
        a 17.5 17.5 0 1 0 35, 0
        a 17.5 17.5 0 1 0 -35, 0"
        stroke="#6D99A2"
        stroke-width="0.5"
        fill="none"
      />
      <path
        d="M18 18
        m -14.5, 0
        a 14.5 14.5 0 1 0 29, 0
        a 14.5 14.5 0 1 0 -29, 0"
        stroke="#6D99A2"
        stroke-width="0.5"
        fill="none"
      />
      <path
        d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831"
        fill="none"
        stroke="#6D99A2"
        stroke-width="2.8"
        [attr.stroke-dasharray]="circumference"
        [attr.stroke-dashoffset]="-dashoffset"
      />
    </svg>
    <span class="count">{{ count }}</span>
    <span class="progress-count" *ngIf="isPercent"> {{ progress }} %</span>
  </div>
</div>
