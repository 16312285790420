import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Employee } from '@app/shared/models/employee.model';
import { TradeCustomer } from '@app/+trades/models/customers.model';
import { AccessRights } from '@app/+trades/constants/trades.constants';
import { AccessRight } from '@app/+trades/types/trades.types';
import { getTitleRole } from '@app/shared/utils';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { UserTypes } from '@app/shared/types/user.types';
import * as _ from 'lodash';
import { formatSectionsStringByOneLevel } from '@app/+competence-map/helpers/competence-map.helpers';
import { StatusesEnum } from '@app/shared/constants/statuses.constants';
import { TradeEmployee } from '@app/+trades/models/employes.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '@app/shared/components/confirmation-modal/confirmation-modal.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TreeNode } from '@circlon/angular-tree-component';
import { FileManagerModalUserInfoComponent } from '@app/file-manager/components/file-manager-modal-user-info/file-manager-modal-user-info.component';
import { ChatService } from '@app/chat/services/chat.service';

@Component({
  selector: 'app-trade-user-item',
  templateUrl: './trade-user-item.component.html',
  styleUrls: ['./trade-user-item.component.scss'],
})
export class TradeUserItemComponent implements OnChanges, OnDestroy {
  @Input() item: TradeEmployee = <TradeEmployee>{};
  @Input() node: TreeNode;

  @Input() accessRight: AccessRight;
  @Input() isAccessChangeOptions: boolean;
  @Input() userId: number;
  @Input() ownerId: number;
  @Input() userType: UserTypes;
  @Input() iAmOwner: boolean;
  @Input() isAccessChangeOptionsShowed = true;
  @Input() roomId: string;
  @Input() isCustomer: boolean = false;
  @Input() withoutSelection: boolean = false;
  @Input() searchString: string;

  @Input() placementIcons: 'top' | 'right' = 'right';

  rolesEnum = RolesEnum;

  isAccessSelectOpen = false;
  accessRights = AccessRights;

  private onDestroy$: Subject<boolean> = new Subject();

  @Output() onSelectMenuItem = new EventEmitter<AccessRight>();
  @Output() onUserDbClick = new EventEmitter<Employee | TradeCustomer>();

  @Output() onToggle: EventEmitter<void> = new EventEmitter();

  @Output() onCheckboxChanges: EventEmitter<TradeEmployee | TradeCustomer> = new EventEmitter<
    TradeEmployee | TradeCustomer
  >();

  constructor(private chatService: ChatService, private modalService: NgbModal) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.item && changes.item.currentValue) {
      this.item.typeLabel = getTitleRole(changes.item.currentValue.type);
      this.item.competenciesString = formatSectionsStringByOneLevel(
        changes.item.currentValue.competence_sections,
        ' / ',
        ''
      );
    }
  }

  get statusIconClass(): string {
    if (this.item.status === StatusesEnum.ACTIVE) {
      return 'status-green';
    } else if (this.item.status === StatusesEnum.VIEW) {
      return 'status-yellow';
    } else {
      return 'status-red';
    }
  }

  get accessRightIcon(): string {
    if (this.accessRight === AccessRights.OWNER) {
      return 'crown';
    } else if (this.accessRight === AccessRights.VIEW) {
      return 'eye';
    } else {
      return 'faq_edit';
    }
  }

  toggleNodeClicked(): void {
    this.onToggle.next();
  }

  onCheckboxChange(newValue: boolean): void {
    this.item.selected = newValue;
    if (this.accessRight) {
      const tradeCustomer = _.merge({}, new TradeCustomer(this.item));
      tradeCustomer.selected = newValue;
      this.onCheckboxChanges.emit(tradeCustomer);
    } else {
      this.onCheckboxChanges.emit(this.item);
    }
  }

  getContentOffset(): number {
    if (this.node?.level > 1) {
      return this.node?.level ? (this.node.level - 1) * 36 : 0;
    }
    return 0;
  }

  /**
   * Открытие меню выборов доступов юзера
   */
  openSelect(event: MouseEvent) {
    event.stopPropagation();
    if (!this.isAccessChangeOptions) {
      return;
    }
    this.isAccessSelectOpen = true;
  }

  closeSelect(event?: MouseEvent) {
    event?.stopPropagation();
    this.isAccessSelectOpen = false;
  }

  /**
   * Выбор опции из меню доступов юзера
   */
  selectMenuItem($event, access: AccessRight) {
    if (!this.isAccessChangeOptions) {
      return;
    }

    if (access === AccessRights.OWNER) {
      const modal = this.modalService.open(ConfirmationModalComponent, {
        centered: true,
        animation: true,
        windowClass: 'dc-modal fileman-modal modal-window',
      });
      modal.componentInstance.title = 'Передача статуса';
      modal.componentInstance.text =
        'Вы действительно хотите передать статус владельца карточки торгов? После передачи статуса Ваши права по отношению к данной карточке торгов будут ограничены и самостоятельно Вы не сможете вернуть их обратно.';
      modal.componentInstance.onClose.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
        if (value) {
          this.onSelectMenuItem.emit(access);
          this.closeSelect();
        }
      });

      return;
    }

    this.onSelectMenuItem.emit(access);
    this.closeSelect($event);
  }

  userDbClick() {
    console.log('dbl click');
    if (!this.isAccessChangeOptions || this.item.disabled || this.accessRight === AccessRights.OWNER) {
      return;
    }
    this.onUserDbClick.emit(this.item);
  }

  navigateToChat() {
    this.chatService.openChat(this.roomId);
  }

  /**
   * Открыть диалоговое окно с информцией о пользователе
   * @param file
   */
  showUserInfo(): void {
    const modal = this.modalService.open(FileManagerModalUserInfoComponent, {
      centered: true,
      windowClass: 'dc-modal modal-window dc-modal-user-card',
      animation: true,
    });
    modal.componentInstance.user = this.item;
    modal.componentInstance.title = 'Контакты';
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
