import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { User } from '@app/shared/models/user.model';

@Component({
  selector: 'app-customer-company-info',
  templateUrl: './customer-company-info.component.html',
  styleUrls: ['./customer-company-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerCompanyInfoComponent {
  @Input() owner: User;
  @Input() payer: User;
  @Input() tariff: number;
}
