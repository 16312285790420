import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { User } from '@app/shared/models/user.model';

@Component({
  selector: 'app-supplier-company-info',
  templateUrl: './supplier-company-info.component.html',
  styleUrls: ['./supplier-company-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplierCompanyInfoComponent {
  @Input() owner: User;
  @Input() supplier: User;
}
