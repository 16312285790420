import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { User } from '@app/shared/models/user.model';
import { AccessRights } from '@app/+trades/constants/trades.constants';
import { TradeCustomer } from '@app/+trades/models/customers.model';
import { ITreeOptions, TREE_ACTIONS, TreeModel, TreeNode } from '@circlon/angular-tree-component';
import { TreeHelper } from '@app/shared/helpers/tree.helper';
import { AccessRight } from '@app/+trades/types/trades.types';
import { UserTypes } from '@app/shared/types/user.types';
import * as _ from 'lodash';
import { TradeEmployee } from '@app/+trades/models/employes.model';
import { StatusesEnum } from '@app/shared/constants/statuses.constants';
import { Employee } from '@app/shared/models/employee.model';
import { SupplierTradeCard } from '@app/+trades/models/suppliers.model';
import { TradeCard } from '@app/+trades/models/trades.model';
import { ChatService } from '@app/chat/services/chat.service';

@Component({
  selector: 'app-dual-list-users',
  templateUrl: './dual-list-users.component.html',
  styleUrls: ['./dual-list-users.component.scss'],
})
export class DualListUsersComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() employees: TradeEmployee[];
  @Input() customers: TradeCustomer[] = [];

  @Input() isAccessChangeOptions: boolean;
  @Input() userId: number;
  @Input() hasOwner: boolean;
  @Input() userType: UserTypes;
  @Input() readonly: boolean;
  @Input() roomId: string;
  @Input() card: TradeCard | SupplierTradeCard;

  @ViewChild('tree') private tree;

  selectedEmployees: TradeEmployee[] = <TradeEmployee[]>[];
  allEmployeesChechbox: boolean = false;
  employeesPartiallySelected: boolean = false;
  haveSelectableEmployees: boolean = true;
  selectedCustomers: TradeCustomer[] = <TradeCustomer[]>[];
  allCustomersChechbox: boolean;

  statuses = StatusesEnum;

  employeesInputValue = '';
  customerInputValue = '';
  newOwnerId: number;

  customTemplateStringOptions: ITreeOptions = {};

  accessRights = AccessRights;

  @Output() onChangeEvent = new EventEmitter<TradeCustomer[]>();

  private _employees: TradeEmployee[] = [];

  constructor(private chatService: ChatService) {}

  get localEmployees() {
    return this._employees;
  }

  set localEmployees(employees: TradeEmployee[]) {
    this._employees = employees;
  }

  get allCustomersPartiallySelected() {
    const customers = this.customers.filter((customer) => customer.access_rights !== AccessRights.OWNER);
    return customers.length && customers.some((item) => item.selected) && !customers.every((item) => item.selected);
  }

  ngOnInit() {
    this.filterEmployeesByCustomers(this.employees);
  }

  ngAfterViewInit(): void {
    this.tree.treeModel.expandAll();
  }

  ngOnChanges() {
    if (this.employees) {
      this.filterEmployeesByCustomers(this.employees);
    }
  }

  /**
   * Фильтрация сотрудников (левый список) по кастомерам (правый список)
   * @param employees
   */
  filterEmployeesByCustomers(employees: TradeEmployee[]) {
    if (!employees) {
      return;
    }
    const owner = this.customers.find((customer) => customer.access_rights === AccessRights.OWNER);
    const flattedEmployees: TradeEmployee[] = TreeHelper.treeToArray(employees).filter(
      (employee) => employee.id !== owner.user.id
    );

    flattedEmployees.map((employee) => {
      if (
        employee.provider_trades?.includes(this.card?.trade_id) ||
        employee.trades?.includes(this.card?.trade_id) ||
        employee.id === this.card?.owner?.id
      ) {
        employee.disabled = true;
      }

      this.customers.map((customer) => {
        if (customer.user.id === employee.id) {
          employee.disabled = true;
        }
      });
    });

    // console.log(employees);

    this.localEmployees = TreeHelper.arrayToTree(flattedEmployees)
      .sort(this.compareEmployee)
      .map((item: any) => {
        const recursiveSort = (list: TradeEmployee[]) => {
          list.sort(this.compareEmployee);
          list.forEach(({ children = [] }) => recursiveSort(children));
        };

        recursiveSort([item]);

        return item;
      });

    setTimeout(() => {
      this.tree?.treeModel?.expandAll();
    }, 0);
    this.updateAllEmployeesCheckbox();
  }

  selectEmployee(item: TradeEmployee): void {
    this.selectedEmployees = _.unionBy(this.selectedEmployees, [item], 'id');
  }

  removeEmployee(item: TradeEmployee): void {
    this.selectedEmployees = this.selectedEmployees.filter((employee) => employee.id !== item.id);
  }

  checkboxEmployeeSelectionChanged(item: TradeEmployee): void {
    this.updateAllEmployeesCheckbox();

    if (item.selected) {
      this.selectEmployee(item);
    } else {
      this.removeEmployee(item);
    }
  }

  checkboxCustomerSelectionChanged(item: TradeCustomer): void {
    const customer = this.customers.find((customer) => customer.user.id === item.user.id);
    customer.selected = item.user.selected;
    if (item.user.selected) {
      this.selectedCustomers = _.unionBy(this.selectedCustomers, [item], 'user.id');
    }
    this.updateAllCustomersCheckbox();
  }

  move(item?: TradeEmployee) {
    let customers: TradeCustomer[] = [];
    if (item) {
      item.selected = false;
      customers = [new TradeCustomer(item)];
    } else {
      customers = this.selectedEmployees.map((employee) => {
        employee.selected = false;
        return new TradeCustomer(employee);
      });
      this.selectedEmployees = [];
      this.updateAllEmployeesCheckbox();
    }

    this.customers = this.customers.concat(customers);
    this.filterEmployeesByCustomers(this.employees);
    this.updateAllEmployeesCheckbox();
    this.onChangeEvent.emit(this.customers);
  }

  selectAllEmployees(event: boolean): void {
    const flattedEmployees = TreeHelper.treeToArray(this.localEmployees).filter(
      (employee) => employee.status !== this.statuses.IN_ARCHIVE
    );

    flattedEmployees.map((employee) => {
      if (!employee.disabled) {
        employee.selected = event;
        this.checkboxEmployeeSelectionChanged(employee);
      }
    });

    this.localEmployees = TreeHelper.arrayToTree(flattedEmployees).sort(this.compareEmployee);

    this.updateAllEmployeesCheckbox();
  }

  selectAllCustomers(event: boolean): void {
    if (!event) {
      this.selectedCustomers = [];
    }
    this.customers.map((customer) => {
      if (customer.access_rights !== AccessRights.OWNER) {
        customer.selected = event;
        customer.user.selected = event;
        this.checkboxCustomerSelectionChanged(customer);
      }
    });

    this.updateAllCustomersCheckbox();
  }

  private updateAllEmployeesCheckbox(): void {
    const employees = TreeHelper.treeToArray(this.localEmployees).filter((employee) => !employee.disabled);
    this.employeesPartiallySelected =
      employees.length && employees.some((item) => item.selected) && !employees.every((item) => item.selected);
    this.allEmployeesChechbox = employees.length && employees.every((item) => item.selected);
    this.haveSelectableEmployees =
      TreeHelper.treeToArray(this.localEmployees).filter(
        (employee) => !employee.disabled && employee.status !== this.statuses.IN_ARCHIVE
      ).length > 0;
  }

  private updateAllCustomersCheckbox(): void {
    const customers = this.customers.filter((customer) => customer.access_rights !== AccessRights.OWNER);
    this.allCustomersChechbox = customers.length && customers.every((item) => item.selected);
  }

  /**
   * Удаление выбранных кастомеров из правого списка
   */
  remove(item?: User) {
    const flattedEmployees = TreeHelper.treeToArray(this.localEmployees);

    if (item) {
      // tslint:disable-next-line:no-shadowed-variable
      const employee = flattedEmployees.find((employee: any) => employee.id === item.id);
      if (employee) {
        employee.disabled = false;
        this.customers = this.customers.filter((customer) => customer.user.id !== item.id);
      }
    } else {
      this.customers = this.customers.filter((customer) => {
        return (
          // tslint:disable-next-line:no-shadowed-variable
          this.selectedCustomers.filter((item) => {
            return customer.user.id === item.user.id && item.selected;
          }).length === 0
        );
      });
    }

    this.selectedCustomers.map((customer) => {
      // tslint:disable-next-line:no-shadowed-variable
      const employee = flattedEmployees.find((employee: any) => employee.id === customer.user.id);
      if (employee) {
        employee.disabled = false;
      }
    });

    this.localEmployees = TreeHelper.arrayToTree(flattedEmployees);
    this.onChangeEvent.emit(this.customers);
    this.selectedCustomers = [];
    this.updateAllCustomersCheckbox();
    this.updateAllEmployeesCheckbox();
    this.filterEmployeesByCustomers(this.localEmployees);
  }

  /**
   * Фильтрация сотрудников по тексту
   * @param {string} text
   */
  filterEmployeesByInput(text: string) {
    const searchKeys = ['second_name', 'first_name', 'patronymic'];
    this.employeesInputValue = text;
    this.tree.treeModel.filterNodes((node: any) => {
      if (!text) {
        node.data.searchedInactive = false;
        return true;
      }
      const value = text.toLowerCase();
      node.data.searchedInactive = true;
      return searchKeys.some((a) => {
        if (node.data[a] && node.data[a]?.toLowerCase().startsWith(value)) {
          node.data.searchedInactive = false;
          return true;
        }
      });
    });
  }

  /**
   * Изменение доступов у кастомера
   * @param {AccessRight} access
   * @param {TradeCustomer} currentCustomer
   */
  changeCustomerAccess(access: AccessRight, currentCustomer: TradeCustomer) {
    if (access === AccessRights.OWNER) {
      const currentOwner = this.customers.find((customer) => customer.access_rights === AccessRights.OWNER);
      currentOwner.access_rights = AccessRights.EDIT;
      this.newOwnerId = currentCustomer.user.id;
    }

    currentCustomer.access_rights = access;
    this.onChangeEvent.emit(this.customers);
    this.filterEmployeesByCustomers(this.employees);
  }

  expandItem(tree: TreeModel, node: TreeNode, $event: any): void {
    TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
  }

  navigateToChat() {
    this.chatService.openChat(this.roomId);
  }

  private compareEmployee(a: Employee, b: Employee) {
    if (a.type > b.type) return -1;
    if (a.type < b.type) return 1;
    if (a.second_name > b.second_name) return 1;
    if (a.second_name < b.second_name) return -1;
    if (a.first_name > b.first_name) return 1;
    if (a.first_name < b.first_name) return -1;
    return 0;
  }
}
