import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@app/environments/environment';
import { AuthService } from '@app/shared/services/auth.service';
import { PersonalDataService } from '@app/shared/services/personal-data.service';
import { Validation } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { RecaptchaComponent } from 'ng-recaptcha';

@Component({
  templateUrl: `./signup.component.html`,
  styleUrls: ['../auth.component.sass'],
})
export class SignupComponent {
  @ViewChild('captchaRef') captchaRef: RecaptchaComponent;

  role = 'admin_of_user';
  errors: any = {};
  private captcha_token;
  env = environment;

  readonly signUpGroup = new FormGroup(
    {
      email: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]+')]),
      password: new FormControl('', [Validation.reactivePasswordValidator()]),
      password_double: new FormControl(''),
      tso_id: new FormControl('', [Validation.reactiveTsoIdValidator()]),
    },
    {
      validators: [Validation.reactiveEqualsPasswords('password', 'password_double')],
    }
  );

  constructor(
    private authService: AuthService,
    private notify: NotificationsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private personalDataService: PersonalDataService,
    private translate: TranslateService
  ) {
    this.translate.setDefaultLang('ru');
    this.role = this.activatedRoute.snapshot.data['role'];
  }

  handleCorrectCaptcha(res): void {
    this.captcha_token = res;
  }

  resetCaptcha(): void {
    if (this.captcha_token) {
      this.captchaRef.reset();
      this.captcha_token = null;
    }
  }

  register(): void {
    const { email, password, tso_id } = this.signUpGroup.value;

    this.errors = {};

    const captchaErrors = Validation.validateCaptcha(this.captcha_token);

    if (captchaErrors.length) {
      this.errors.captcha = captchaErrors;
    }

    if (this.signUpGroup.invalid || this.errors.captcha) {
      Object.values(this.signUpGroup.controls).forEach((control) => control.markAsTouched());
      return;
    }

    this.authService.register(email, password, this.role, this.captcha_token, tso_id || undefined).subscribe(
      () => {
        this.notify.success(
          'Успешно!',
          'Вы успешно зарегестрировались. Вам на почту отправлено письмо с подтверждением!'
        );
        this.personalDataService.showCurrentDatesNoti();

        this.router.navigate(['signin']);
      },
      (err) => {
        const error = err.error;

        const { errors, message } = error;

        if (errors) this.errors = errors;

        if (message) {
          const messages: string[] = Array.isArray(message)
            ? message
            : typeof message === 'string'
            ? [message]
            : Object.values(message)?.flat();

          const firstErrorMessage = messages[0];

          if (firstErrorMessage !== 'Validation failed') {
            this.notify.error('Ошибка!', this.translate.instant(firstErrorMessage));
          }

          if (firstErrorMessage === 'Данный e-mail уже используется в системе') {
            this.errors.email = [firstErrorMessage];
          }

          this.resetCaptcha();
        }
      }
    );
  }

  getErrorByFormControlName(name: string) {
    const formErrors = this.signUpGroup.get(name).errors;

    return formErrors ? Object.values(formErrors).flat()[0] : null;
  }

  togglePasswordShowing(input: HTMLInputElement) {
    input.type = input.type === 'password' ? 'text' : 'password';
  }
}
