import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChatContactsOnlyFilterComponent } from './chat-contacts-only-filter.component';
import { DestroyService } from '@app/services/destroy.service';
import { SharedModule } from '@app/shared/shared.module';
import { SearchModule } from '@app/shared/modules/search/search.module';
import { TradesSharedModule } from '@app/+trades/shared/trades-shared.module';

@NgModule({
  imports: [CommonModule, SharedModule, SearchModule, TradesSharedModule],
  declarations: [ChatContactsOnlyFilterComponent],
  exports: [ChatContactsOnlyFilterComponent],
  providers: [DestroyService],
})
export class ChatContactsOnlyFilterModule {}
