import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { User } from '@app/shared/models/user.model';

@Component({
  selector: 'app-report-accessed-users',
  templateUrl: './report-accessed-users.component.html',
  styleUrls: ['./report-accessed-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportAccessedUsersComponent {
  @Input() users: User[];
  @Input() isStaticData: boolean;
}
