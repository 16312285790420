<div class="additional-filters">
  <h6>Дополнительные фильтры</h6>

  <ul class="additional-filters-list">
    <li>
      <b>Дата государственной регистрации компании поставщика:</b>
      <div
        *ngIf="
          additionalFilters?.from_date_state_registration || additionalFilters?.to_date_state_registration;
          else empty
        "
      >
        <span *ngIf="additionalFilters?.from_date_state_registration as value">C {{ value | dateFormat }} </span>
        <span *ngIf="additionalFilters?.to_date_state_registration as value">По {{ value | dateFormat }}</span>
      </div>
    </li>
    <li>
      <b>Дата регистрации поставщика в сервисе ПК:</b>
      <div
        *ngIf="
          additionalFilters?.from_date_registration_payer || additionalFilters?.to_date_registration_payer;
          else empty
        "
      >
        <span *ngIf="additionalFilters?.from_date_registration_payer as value">C {{ value | dateFormat }} </span>
        <span *ngIf="additionalFilters?.to_date_registration_payer as value">По {{ value | dateFormat }}</span>
      </div>
    </li>
  </ul>
</div>

<ng-template #empty>
  <div>-</div>
</ng-template>
