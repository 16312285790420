import { Inject, Injectable } from '@angular/core';
import { Subject, Observable, throwError as observableThrowError } from 'rxjs';
import { LockUserTabTrade, TradeTabsLocked } from '../models/trades.model';
import { environment } from '@app/environments/environment';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators';
import { AbstractTradeService } from './abstract-trades.service';
import { DATA_PROTECT_TRADE_URL } from '../tokens/trades.tokens';

export function resolveTradeDataProtectService(http: HttpClient, service: AbstractTradeService, url: string) {
  return new TradeDataProtectService(http, service, url);
}

@Injectable()
export class TradeDataProtectService {
  private _modalTrigger: Subject<boolean>;
  private _isGoNext: boolean;
  private _nextRoute: string;

  constructor(
    private http: HttpClient,
    private tradesService: AbstractTradeService,
    @Inject(DATA_PROTECT_TRADE_URL) private readonly url: string
  ) {
    this._modalTrigger = new Subject<boolean>();
  }

  /**
   * Надо подписаться чтобы вывести модальное окно о сообщение о несохранённых данных юзера
   * @returns {boolean}
   */
  get modalTrigger$() {
    return this._modalTrigger;
  }

  /**
   * Получение Флага для доступа к переходу на роут
   * @returns {boolean}
   */
  get goNext() {
    return this._isGoNext;
  }

  /**
   * Изменение Флага для доступа к переходу на роут
   * @returns {boolean}
   */
  set goNext(value) {
    this._isGoNext = value;
  }

  /**
   * Сохранение роута на который собирается перейти юзер
   * @param value
   */
  set nextRoute(value) {
    this._nextRoute = value;
  }

  /**
   * Сохранение роута на который собирается перейти юзер
   * @returns {string}
   */
  get nextRoute() {
    return this._nextRoute;
  }

  /**
   * Получение список активных блокировок табов
   * @param cardId
   */
  getLocking(cardId: number): Observable<LockUserTabTrade[]> {
    return this.http.get<LockUserTabTrade[]>(`${environment.api_url}/${this.url}/${cardId}/locking`).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  /**
   * Установить блокировку таба
   * @param cardId
   * @param body
   */
  setLocking(cardId: number, body: LockUserTabTrade): Observable<LockUserTabTrade[]> {
    return this.http.post<LockUserTabTrade[]>(`${environment.api_url}/${this.url}/${cardId}/locking`, body).pipe(
      map((locking) => {
        this.tradesService.updateTabsLocking(this.tradesService.tradeTabs, locking);
        this.tradesService.storeTab(this.tradesService.tradeTabs.find((item) => item.lockId === body.tab_trade));
        return locking;
      }),
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  /**
   * Удалить блокировку таба
   * @param cardId
   * @param body
   */
  deleteLocking(cardId: number, body: LockUserTabTrade): Observable<LockUserTabTrade[]> {
    return this.http
      .request<LockUserTabTrade[]>('DELETE', `${environment.api_url}/${this.url}/${cardId}/locking`, {
        body: body,
      })
      .pipe(
        map((locking) => {
          this.tradesService.storeTab(this.tradesService.tradeTabs.find((item) => item.lockId === body.tab_trade));
          return locking;
        }),
        catchError((e) => {
          return observableThrowError(e);
        })
      );
  }

  changeTabsLocking(lockedTabs: TradeTabsLocked): void {
    this.tradesService.updateTabsLocking(this.tradesService.tradeTabs, lockedTabs.trade_tabs_locked);
    lockedTabs.trade_tabs_locked.forEach((lockedTab) => {
      this.tradesService.storeTab(this.tradesService.tradeTabs.find((item) => item.lockId === lockedTab.tab_trade));
    });
  }
}
