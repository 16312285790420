<div class="group">
  <div class="d-flex align-items-center group-left">
    <button
      class="icon-button chat-expansion__caret"
      [class.disabled]="!groups.length"
      (click)="onToggleGroupsExpanded()"
    >
      <app-svg-icon [name]="isGroupsExpanded ? 'caret_up' : 'caret_down'" width="12" height="12"></app-svg-icon>
    </button>
    <span class="group-title">Группы и темы</span>
    <div
      *ngIf="
        (chatSectionSelected.name === chatSectionsEnum.ADMIN ||
          chatSectionSelected.name === chatSectionsEnum.HOLDING ||
          chatSectionSelected.name === chatSectionsEnum.TRADE) &&
        !isModalView
      "
      [class.invisible]="chatSectionSelected.name === chatSectionsEnum.TRADE"
    >
      <button (click)="onSortDirectionNext()" class="icon-button">
        <app-svg-icon name="sort-amount-down" width="12" height="12"></app-svg-icon>
      </button>

      <app-chat-groups-sorting
        [isOpen]="isChoosingSortDirection"
        title="Сортировка групп"
        [sortOptions]="sortOptions"
        [sortType]="sortType"
        (close)="sortPopupClose()"
        (save)="sortDirectionChange($event)"
      ></app-chat-groups-sorting>
    </div>
  </div>

  <div class="d-flex align-items-center group-right" *ngIf="!isModalView">
    <button class="icon-button" [style.visibility]="techType ? 'hidden' : 'visible'">
      <app-svg-icon name="question-circle-color" width="16" height="16"></app-svg-icon>
    </button>
    <button
      class="icon-button"
      (click)="openGroupNameModal()"
      *ngIf="chatSectionSelected.name !== chatSectionsEnum.TRADE"
      [style.visibility]="
        (chatSectionSelected.name !== chatSectionsEnum.TECH &&
          user?.type !== rolesEnum.PARTNER &&
          user?.type !== rolesEnum.ACCOUNTANT &&
          user?.type !== rolesEnum.EXPERT) ||
        (chatSectionSelected.name === chatSectionsEnum.TECH &&
          user?.type === rolesEnum.PARTNER &&
          techType &&
          techType !== techTypeEnum.MY_TECH)
          ? 'visible'
          : 'hidden'
      "
    >
      <app-svg-icon name="plus" width="16" height="16"></app-svg-icon>
    </button>

    <div
      class="chat-number"
      [style.visibility]="totalUnreadMessagesCounter ? 'visible' : 'hidden'"
      [class.chat-number__10]="totalUnreadMessagesCounter === '10+'"
    >
      {{ totalUnreadMessagesCounter }}
    </div>
  </div>
</div>

<ng-container *ngIf="isGroupsExpanded">
  <ng-container *ngIf="chatSectionSelected.name !== chatSectionsEnum.TECH">
    <ng-container *ngFor="let group of groups; trackBy: trackByRoomId">
      <app-chat-group
        [group]="group"
        [sortType]="sortType"
        [contactSelected]="contactSelected"
        [isModalView]="isModalView"
      ></app-chat-group>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="chatSectionSelected.name === chatSectionsEnum.TECH">
    <ng-container *ngFor="let group of groups; trackBy: trackByRoomId">
      <app-chat-group
        [group]="group"
        [sortType]="sortType"
        [contactSelected]="contactSelected"
        [techType]="techType"
        [isModalView]="isModalView"
      ></app-chat-group>
    </ng-container>
  </ng-container>
</ng-container>
