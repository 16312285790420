<div class="modal-footer">
  <div class="dc-footer-buttons">
    <button
      class="btn btn-dc-outline"
      (click)="cancel()"
      [disabled]="isCancelDisabled() || !form?.dirty"
      appTestLocator="operation-footer__cancel"
    >
      Отменить
    </button>
    <button
      class="btn btn-dc-primary"
      (click)="save()"
      type="submit"
      [disabled]="isSaveDisabled() || form?.invalid || !form?.dirty"
      appTestLocator="operation-footer__save"
    >
      Сохранить
    </button>
    <button
      class="btn btn-dc-primary"
      (click)="agree()"
      type="submit"
      [disabled]="isAgreeDisabled() || form?.invalid || !form?.dirty"
      appTestLocator="operation-footer__confirm"
    >
      {{ agreeButtonName }}
    </button>
  </div>
</div>
