import { Pipe, PipeTransform } from '@angular/core';
import { TradeCustomer } from '@app/+trades/models/customers.model';

@Pipe({
  name: 'customerFilterList',
})
export class CustomerFilterListPipe implements PipeTransform {
  transform(items: TradeCustomer[], term: string): any {
    if (!term) {
      return items;
    }

    const lowerCaseTerm = term.toLowerCase();

    return items.filter((item) => {
      const searchKeys = ['second_name', 'first_name', 'patronymic'];
      return searchKeys.some((a) => {
        if (item?.user[a] && item?.user[a]?.toLowerCase().indexOf(lowerCaseTerm) !== -1) {
          return true;
        }
      });
    });
  }
}
