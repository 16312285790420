export enum StatusesEnum {
  ACTIVE = 'active',
  BLOCKED = 'blocked',
  IN_ARCHIVE = 'in_archive',
  VIEW = 'browsing',
}

export enum StatusesValueEnum {
  TRUE = 'true',
  FALSE = 'false',
}

export const STATUSES = [
  { title: 'активный', shortTitle: 'актив', value: StatusesEnum.ACTIVE },
  { title: 'заблокирован', shortTitle: 'заблокирован', value: StatusesEnum.BLOCKED },
  { title: 'в архиве', shortTitle: 'архив', value: StatusesEnum.IN_ARCHIVE },
  { title: 'просмотр', shortTitle: 'просмотр', value: StatusesEnum.VIEW },
];
