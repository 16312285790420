import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserSettings } from '@app/shared/models/user-settings.model';
import { ChatSectionService } from '@app/chat/services/chat-section.service';
import { ChatSectionsEnum, ContactsSortingNames, GroupSortingNames } from '@app/chat/constants/chat-sections.constants';
import { SectionSortingSettings } from '@app/chat/models/chat.sorting-options.model';
import { take } from 'rxjs/operators';
import { PersonalDataService } from '@app/shared/services/personal-data.service';

@Injectable({
  providedIn: 'root',
})
export class ChatSortingService {
  private _settings = new BehaviorSubject<UserSettings>(null);
  public settings$ = this._settings.asObservable();

  constructor(private chatSectionService: ChatSectionService, private personalDataService: PersonalDataService) {}

  get sortSettings(): SectionSortingSettings {
    return this._settings.value.chat_settings.sort_settings;
  }

  setSettings(settings: UserSettings): void {
    if (!settings.chat_settings?.sort_settings) {
      settings.chat_settings.sort_settings = {
        admin: {
          groups: GroupSortingNames.LAST_MESSAGE_DATE,
          contacts: ContactsSortingNames.NAME,
        },
        holding: {
          groups: GroupSortingNames.LAST_MESSAGE_DATE,
          contacts: ContactsSortingNames.ROLE,
        },
        trade: {
          groups: {
            customer: GroupSortingNames.LAST_MESSAGE_DATE,
            provider: GroupSortingNames.LAST_MESSAGE_DATE,
          },
        },
      };
    }
    this._settings.next(settings);
  }

  updateSortSettings(changedOption: {
    groups?: GroupSortingNames | { customer?: GroupSortingNames; provider?: GroupSortingNames };
    contacts?: ContactsSortingNames;
  }): void {
    const sectionName = this.chatSectionService.chatSectionSelected.name;
    const settings = structuredClone(this._settings.value);

    if (changedOption.contacts) {
      settings.chat_settings.sort_settings[sectionName].contacts = changedOption.contacts;
    }

    if (sectionName === ChatSectionsEnum.TRADE && typeof changedOption.groups === 'object') {
      settings.chat_settings.sort_settings[sectionName].groups = {
        ...settings.chat_settings.sort_settings[sectionName].groups,
        ...changedOption.groups,
      };
    } else {
      settings.chat_settings.sort_settings[sectionName] = {
        ...settings.chat_settings.sort_settings[sectionName],
        ...changedOption,
      };
    }

    this._settings.next(settings);
    this.personalDataService.updateUserSettings(settings).pipe(take(1)).subscribe();
  }
}
