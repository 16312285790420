import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { MultipleLivesearchSelectComponent } from '@app/shared/modules/search/multiple-livesearch-select/multiple-livesearch-select.component';
import { DoubleDatePickerParams } from '@app/shared/components/double-datepicker-filter/double-datepicker-filter.component';
import { Column } from '@app/shared/models/table';
import { TradesRequestConfig } from '@app/+trades/models/trades.model';
import { MultipleSearchSuggestion } from '@app/shared/models/multiple-search.model';
import { DATE_FORMAT, TIME_FORMAT } from '@app/shared/constants/date.constants';
import { isArray } from 'lodash-es';
import { EmployeeTreeFilter } from '@app/+employees/shared';
import { PaymentRegistryRequestConfig } from '@app/+tariffs/models/payment-registry-column.model';

@Component({
  selector: 'app-double-date-time-picker-filter',
  templateUrl: './double-date-time-picker-filter.component.html',
  styleUrls: ['./double-date-time-picker-filter.component.scss'],
})
export class DoubleDateTimePickerFilterComponent {
  @ViewChild('livesearchSelect') livesearchSelect: MultipleLivesearchSelectComponent;

  @Input() column: Column<TradesRequestConfig | EmployeeTreeFilter | PaymentRegistryRequestConfig>;
  @Input() withName: boolean = false;
  @Input() filterSuggestions: MultipleSearchSuggestion[];
  @Input() isOnlyDate = false;

  @Output() cancelEvent = new EventEmitter();
  @Output() submitEvent = new EventEmitter<{
    date: DoubleDatePickerParams;
    filteredSuggestions: MultipleSearchSuggestion[];
  }>();

  form: FormGroup = new FormGroup({
    fromDate: new FormControl(null),
    toDate: new FormControl(null),
    fromTime: new FormControl(null),
    toTime: new FormControl(null),
  });

  @Input() set filteredValue(data: string[]) {
    if (!data?.length) {
      return;
    }

    const fromDateTime = data[0];
    const toDateTime = data[1];
    const filteredSuggestionIds = data[2];

    const formData = (dateStr: string) => {
      return moment(+dateStr * 1000).format(`${DATE_FORMAT} ${TIME_FORMAT}`);
    };

    const from = formData(fromDateTime);
    const to = formData(toDateTime);

    const [fromDate, fromTime] = from.split(' ');
    const [toDate, toTime] = to.split(' ');

    const patchValues = {
      fromDate: fromDate,
      toDate: toDate,
      fromTime: fromTime || null,
      toTime: toTime || null,
    };

    this.form.patchValue(patchValues);

    if (filteredSuggestionIds) {
      this.filterSuggestions = this.filterSuggestions.map((suggestion) => ({
        ...suggestion,
        value: isArray(filteredSuggestionIds) ? filteredSuggestionIds.find((id) => id === suggestion.id) : false,
      }));
    }
  }

  get isApplyDisable(): boolean {
    const fromDateValue = this.form.get('fromDate').value;
    const toDateValue = this.form.get('toDate').value;

    if (this.withName) {
      if (this.livesearchSelect?.allChecked) {
        return false;
      }
      if (this.livesearchSelect?.partiallySelected) {
        return false;
      }
    }

    if (toDateValue < fromDateValue) {
      return true;
    }

    return !fromDateValue || !toDateValue;
  }

  apply(): void {
    const filteredSuggestions = this.filterSuggestions?.filter((item) => item.value) || [];

    const { fromDate, toDate, fromTime, toTime } = this.form.value;
    const date = {
      fromDate: fromDate || null,
      toDate: toDate || null,
      fromTime: fromDate ? fromTime : null,
      toTime: toDate ? toTime : null,
    };

    this.submitEvent.emit({ date, filteredSuggestions });
  }

  cancel(): void {
    this.form.reset();
    this.cancelEvent.emit();
  }

  select(value: any[]) {
    this.filterSuggestions = value;
  }
}
