import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SVGSpriteModule } from '@app/svg-sprite/svg-sprite.module';
import { SharedModule } from '@app/shared/shared.module';

import { FileManagerModalUserInfoComponent } from './file-manager-modal-user-info.component';

@NgModule({
  declarations: [FileManagerModalUserInfoComponent],
  exports: [FileManagerModalUserInfoComponent],
  imports: [CommonModule, SVGSpriteModule, SharedModule],
})
export class FileManagerModalUserInfoModule {}
