import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserFile } from '@app/file-manager/models/user-file.model';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import {
  ExportFileManagerType,
  FileManagerExportComponent,
} from '@app/file-manager/file-manager-export/file-manager-export.component';
import { switchMap, takeUntil } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { NotificationsService } from 'angular2-notifications';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DestroyService } from '@app/services/destroy.service';
import { FileManagerDataService } from '@app/file-manager/services/file-manager-data.service';
import { FileManagerService } from '@app/file-manager/services/file-manager.service';
import { saveAs } from 'file-saver';
import { FileManagerModalFilePreviewComponent } from '@app/file-manager/components/file-manager-modal-file-preview/file-manager-modal-file-preview.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-chat-attached-file',
  templateUrl: './chat-attached-file.component.html',
  styleUrls: ['./chat-attached-file.component.scss'],
})
export class ChatAttachedFileComponent implements OnInit {
  @Input() attachedFiles: UserFile[] = [];
  @Input() clickedIndex?: number = 0;
  @Input() enableRemoveButton?: boolean = false;
  @Output() removeFile = new EventEmitter<UserFile>();

  protected file: UserFile;
  protected safeUrl: SafeResourceUrl;

  get isPdfFile(): boolean {
    return this.file?.fileExtType()?.hasPdf;
  }

  get isImageFile(): boolean {
    return this.file?.fileExtType()?.hasImage;
  }

  constructor(
    private readonly notify: NotificationsService,
    private readonly modalService: NgbModal,
    private readonly destroy$: DestroyService,
    private readonly fileManagerDataService: FileManagerDataService,
    private readonly fileManagerService: FileManagerService,
    private readonly sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.file = this.attachedFiles[this.clickedIndex];
    const secureUrl = this.file.url.startsWith('http://')
      ? this.file.url.replace('http://', 'https://')
      : this.file.url;
    this.file.url = encodeURI(secureUrl);

    if (this.isPdfFile) this.setSafeURL(this.file.url);
  }

  protected setSafeURL(url: string) {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  onPreview() {
    const modal = this.modalService.open(FileManagerModalFilePreviewComponent, {
      centered: true,
      animation: true,
      windowClass: 'dc-modal modal-window custom-modal-transparent-no-shadow',
      size: 'xl',
    });

    modal.componentInstance.files = this.attachedFiles;
    modal.componentInstance.selectedFileIndex = this.clickedIndex;
    modal.componentInstance.exportType = ExportFileManagerType.CHAT;
  }

  onSave() {
    const file = this.attachedFiles[this.clickedIndex];
    try {
      saveAs(file?.url, file?.name);
    } catch (error) {
      this.notify.error('Внимание', 'Не удалось загрузить файл');
    }
  }

  onSaveToFM() {
    const modal: NgbModalRef = this.modalService.open(FileManagerExportComponent, {
      centered: true,
      animation: true,
      windowClass: 'dc-modal export-file-manager-modal modal-window',
      size: 'xl',
    });

    modal.componentInstance.title = 'Сохранение файла';
    modal.componentInstance.subTitle = this.file.name;
    modal.componentInstance.searchPlaceholder = 'Введите имя файла';
    modal.componentInstance.exportType = ExportFileManagerType.CHAT;
    modal.componentInstance.isFolderExport = true;
    modal.componentInstance.selectFolders = false;

    modal.result.then((folderSelected) => {
      if (!folderSelected) return;

      this.fileManagerDataService
        .downloadFileByLink(this.file.url)
        .pipe(
          switchMap((data) => {
            const formData: FormData = new FormData();
            formData.append('file', data, this.file.name);
            return this.fileManagerService.uploadFile(folderSelected.id, formData);
          }),
          takeUntil(this.destroy$)
        )
        .subscribe({
          next: (event) => {
            if (event.type !== HttpEventType.Response) return;
            this.notify.success('Успешно!', `Сохранение файла ${event?.body?.name} завершено.`);
          },
          error: () => this.notify.error('Внимание!', `Не удалось сохранить ${this.file?.name}.`),
        });
    });
  }
}
