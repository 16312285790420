<div class="tso-wrapper" [ngStyle]="{ height: isChangeTso ? '175px' : 'auto' }">
  <table class="table-tso">
    <thead class="tso-thead">
      <tr class="table-tso-header">
        <td *ngIf="isChangeTso" class="tso-ceil" width="50"></td>
        <td class="tso-ceil" width="70"><b>ID</b></td>

        <td class="tso-ceil" width="138"><b>ФИО</b></td>

        <td class="tso-ceil tso-status" width="110"><b>Статус в ПК</b></td>

        <td class="tso-ceil" width="168"><b>Начало работы в качестве ОТП</b></td>

        <td class="tso-ceil" width="208"><b> Кол-во обслуживаемых пользователей </b></td>

        <td *ngIf="!isChangeTso" class="tso-ceil"></td>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="tsoList.length; else noData">
        <tr
          class="tso-row"
          *ngFor="let tso of tsoList"
          [ngClass]="{
            'current-tso': tso?.id === myTsoId && isChangeTso,
            'selected-tso': tso?.id === selectedTso?.id && tso?.id !== myTsoId
          }"
        >
          <td *ngIf="isChangeTso" width="50">
            <div class="dc-radio-input radio-button">
              <input
                [id]="tso.id"
                [value]="tso.id"
                [name]="'control'"
                type="radio"
                [checked]="tso.id === myTsoId"
                (change)="changeTso(tso)"
              />
              <label class="radio-label tso-label" [for]="tso.id"></label>
            </div>
          </td>
          <td width="70">{{ tso?.id ?? '-' }}</td>

          <td width="138">
            <div
              [disableTooltip]="textElement?.offsetWidth >= textElement?.scrollWidth"
              ngbTooltip="{{ tso | initials }}"
            >
              <div
                #textElement
                class="tso-name"
                [innerHtml]="tso ? (tso | initials | highlight : tsoValue) : '-'"
              ></div>
            </div>
          </td>

          <td width="110" class="tso-status">
            <app-svg-icon name="circle" width="16" [class]="statusIconClass(tso)" class="no-hover"></app-svg-icon>
          </td>

          <td width="168">{{ getCurrentTimeZoneData(tso?.start_date_as_tso) }}</td>

          <td width="208">{{ tso?.number_of_assigned_user ?? '-' }}</td>

          <td *ngIf="!isChangeTso">
            <div class="tso-edit-block">
              <button class="icon-button" (click)="openChangedModal()">
                <app-svg-icon name="edit" width="18" height="18"></app-svg-icon>
              </button>
              <button class="icon-button" (click)="openConfirmationModal()" [class.disabled]="!tso">
                <app-svg-icon name="close-search" width="18" height="18"></app-svg-icon>
              </button>
            </div>
          </td>
        </tr>
      </ng-container>
      <ng-template #noData>
        <tr class="tso-empty-row">
          <td colspan="6" width="744">Не найдено ОТП</td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>
