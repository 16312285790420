<div
  (click)="onThemeSelect(theme)"
  [class.info__selected]="isActiveClass"
  class="theme"
  [class.trade-theme]="chatSectionSelected.name === chatSectionsEnum.TRADE"
  [class.trade-theme-last]="isTradeChatEndLevel"
  [class._filter-disabled]="theme.filterDisabled"
>
  <div class="theme-left">
    <div class="room-select-checkbox">
      <ng-container *ngIf="isModalView">
        <input
          class="cbx-input"
          type="checkbox"
          id="theme_select_{{ theme.room_id }}"
          (change)="toggleRoomSelected(theme)"
          [checked]="isRoomSelected(theme)"
        />
        <label class="cbx-label" [class.cbx-label-interminate]="false" for="theme_select_{{ theme.room_id }}"></label>
      </ng-container>
    </div>
    <button
      *ngIf="isTradeChatFirstLevel"
      (click)="$event.stopPropagation(); onToggleExpanded()"
      class="icon-button chat-expansion__caret"
      [class.disabled]="!themes.length"
    >
      <app-svg-icon [name]="isExpanded ? 'caret_up' : 'caret_down'" width="12" height="12"></app-svg-icon>
    </button>
    <div
      class="theme-title"
      [class.theme-title--long]="
        chatSectionSelected.name !== chatSectionsEnum.ADMIN &&
        chatSectionSelected.name !== chatSectionsEnum.TRADE &&
        chatSectionSelected.name !== chatSectionsEnum.TECH
      "
      [disableTooltip]="theme.title.length < 18 || nameElement?.offsetWidth >= nameElement?.scrollWidth"
      [ngbTooltip]="theme.title"
    >
      <p
        [class.text-overflow]="
          chatSectionSelected.name === chatSectionsEnum.ADMIN ||
          chatSectionSelected.name === chatSectionsEnum.TRADE ||
          chatSectionSelected.name === chatSectionsEnum.TECH
        "
        [class.trade-nested-title]="isNested"
        #nameElement
        [innerHTML]="theme?.filterDisabled ? theme.title : (theme.title | highlight : filterString)"
      ></p>
    </div>
  </div>

  <div
    *ngIf="!isModalView"
    class="d-flex align-items-center theme-right justify-content-end"
    [class.info__selected]="isActiveClass"
  >
    <button
      class="icon-button"
      [class.disabled]="isOwner || (isProvider && group?.type === tradeRoomsTypes.PROVIDER_CUSTOMER)"
      type="button"
      (click)="$event.stopPropagation()"
      placement="left"
      [ngbPopover]="settingsContent"
      container="body"
      *ngIf="isTradeChatEndLevel || chatSectionSelected.name !== chatSectionsEnum.TRADE"
      [style.visibility]="techType !== TechTypeEnum.MY_TECH ? 'visible' : 'hidden'"
    >
      <app-svg-icon [name]="'cog'" width="16" height="16"></app-svg-icon>
    </button>
    <button
      class="icon-button"
      [disabled]="
        (isProvider && theme?.type === tradeRoomsTypes.PROVIDER_CUSTOMER) ||
        (userStatus !== statusesEnum.ACTIVE && +userId !== theme.owner_id) ||
        +userId !== theme.owner_id
      "
      (click)="openCreateModal()"
      *ngIf="isTradeChatFirstLevel"
    >
      <app-svg-icon [name]="'plus'" width="16" height="16"></app-svg-icon>
    </button>

    <span
      [style.visibility]="totalUnreadMessagesCounter ? 'visible' : 'hidden'"
      [class.chat-number__10]="totalUnreadMessagesCounter === '10+'"
      class="chat-number"
    >
      {{ totalUnreadMessagesCounter }}
    </span>
  </div>
</div>

<ng-container *ngIf="isExpanded">
  <ng-container *ngFor="let item of themes | groupSort : sortType; trackBy: trackByRoomId">
    <app-chat-theme
      *ngIf="isGroupExpanded"
      [theme]="item"
      [group]="theme"
      [userId]="userId"
      [userType]="user?.type"
      [contactSelected]="contactSelected"
      [isNested]="true"
      [isModalView]="isModalView"
    ></app-chat-theme>
  </ng-container>
</ng-container>

<ng-template #settingsContent>
  <button class="settings-buttons" (click)="$event.stopPropagation(); openRenameThemeModal()">
    <app-svg-icon [name]="'edit_list'" width="15" height="15"></app-svg-icon>
    Переименовать тему
  </button>
  <button
    class="settings-buttons"
    [disabled]="group?.type === tradeRoomsTypes.PROVIDER_CUSTOMER"
    (click)="$event.stopPropagation(); onThemeEdit()"
  >
    <app-svg-icon [name]="'users'" width="15" height="15"></app-svg-icon>
    Редактировать тему
  </button>
  <button class="settings-buttons" (click)="$event.stopPropagation(); onRemoveThemeAsk()">
    <app-svg-icon [name]="'delete'" width="15" height="15"></app-svg-icon>
    Удалить тему
  </button>
</ng-template>
