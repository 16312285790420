import { Column, PaymentRegistryRequestConfig } from '../models/payment-registry-column.model';
import { MultiSelectListItem } from '@app/shared/models/multi-select-list-item';
import { StatusesEnum } from '@app/shared/constants/statuses.constants';

export enum PaymentAccrualsColumnsKeys {
  CHECKBOX = 'checkbox',
  ID = 'id',
  TYPE = 'type',
  USER_ITN = 'user_itn',
  NAME = 'name_user_or_company',
  STATUS = 'user_status',
  TRADE_ID = 'trade_id',
  AMOUNT = 'amount',
  APPROVAL_DATE = 'approval_date',
  PAYMENT_ORDER_ID = 'payment_order_id',
  OPERATOR = 'user_created',
  META = 'meta',
  COMMENT = 'comment',
  BIN = 'bin',
  ITN = 'itn',
}

export enum PaymentAccrualsFilterKeys {
  ID = 'id',
  TYPE = 'type',
  USER_ITN = 'user.company.id',
  NAME = 'user_id',
  STATUS = 'user.type',
  TRADE_ID = 'extra.trade_id',
  AMOUNT = 'amount',
  APPROVAL_DATE = 'approval_date',
  PAYMENT_ORDER_ID = 'extra.payment_order_id',
}

export const paymentAccrualsColumns: Column<PaymentRegistryRequestConfig>[] = [
  {
    id: PaymentAccrualsColumnsKeys.CHECKBOX,
    width: 44,
    checkbox: true,
  },
  {
    id: PaymentAccrualsColumnsKeys.ID,
    label: 'ID операции',
    isEnabledSearch: true,
    width: 150,
    placeholder: 'Начните вводить ID операции',
    filterKey: PaymentAccrualsFilterKeys.ID,
    filterConfig: {
      filter: {
        name: PaymentAccrualsFilterKeys.ID,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentAccrualsColumnsKeys.TYPE,
    label: 'Тип операции',
    isEnabledFilter: true,
    width: 170,
    filterKey: PaymentAccrualsFilterKeys.TYPE,
    filterConfig: {
      filter: {
        name: PaymentAccrualsFilterKeys.TYPE,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentAccrualsColumnsKeys.USER_ITN,
    label: 'ID пользователя, ИНН пользователя',
    isEnabledSearch: true,
    width: 200,
    placeholder: 'Начните вводить ID пользователя',
    filterKey: PaymentAccrualsFilterKeys.USER_ITN,
    filterConfig: {
      filter: {
        name: PaymentAccrualsFilterKeys.USER_ITN,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentAccrualsColumnsKeys.NAME,
    label: 'Пользователь',
    isEnabledSearch: true,
    width: 156,
    placeholder: 'Начните вводить ФИО пользователя',
    filterKey: PaymentAccrualsFilterKeys.NAME,
    filterConfig: {
      filter: {
        name: PaymentAccrualsFilterKeys.NAME,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentAccrualsColumnsKeys.STATUS,
    label: 'Статус',
    isEnabledFilter: true,
    width: 110,
    filterKey: PaymentAccrualsFilterKeys.STATUS,
    filterConfig: {
      filter: {
        name: PaymentAccrualsFilterKeys.STATUS,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentAccrualsColumnsKeys.TRADE_ID,
    label: 'ID торгов',
    isEnabledSearch: true,
    width: 130,
    placeholder: 'Начните вводить ID торгов',
    filterKey: PaymentAccrualsFilterKeys.TRADE_ID,
    filterConfig: {
      filter: {
        name: PaymentAccrualsFilterKeys.TRADE_ID,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentAccrualsColumnsKeys.AMOUNT,
    label: 'Сумма операции (₽)',
    isEnabledFilter: true,
    width: 160,
    filterKey: PaymentAccrualsFilterKeys.AMOUNT,
    filterConfig: {
      filter: {
        name: PaymentAccrualsFilterKeys.AMOUNT,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentAccrualsColumnsKeys.APPROVAL_DATE,
    label: 'Дата операции',
    isEnabledFilter: true,
    width: 120,
    filterKeys: ['from_approval_date', 'to_approval_date'],
    filterConfig: {
      from_approval_date: null,
      to_approval_date: null,
    },
  },
  {
    id: PaymentAccrualsColumnsKeys.PAYMENT_ORDER_ID,
    label: '№ п/п',
    isEnabledSearch: true,
    width: 110,
    placeholder: 'Начните вводить № п/п',
    filterKey: PaymentAccrualsFilterKeys.PAYMENT_ORDER_ID,
    filterConfig: {
      filter: {
        name: PaymentAccrualsFilterKeys.PAYMENT_ORDER_ID,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentAccrualsColumnsKeys.OPERATOR,
    label: 'Оператор',
    isEnabledFilter: true,
    width: 120,
    filterKeys: ['created_by'],
    filterConfig: {
      created_by: null,
    },
  },
  {
    id: PaymentAccrualsColumnsKeys.META,
    label: 'Метаданные',
    isEnabledFilter: true,
    width: 140,
    filterKeys: ['metadata'],
    filterConfig: {
      metadata: null,
    },
  },
  {
    id: PaymentAccrualsColumnsKeys.COMMENT,
    label: 'Комментарии',
    width: 150,
  },
].map((column) => {
  return {
    ...column,
    defaultFilterConfig: {
      filter: column.filterConfig?.filter,
    },
  };
});

export const ManualModeColumns: Column<PaymentRegistryRequestConfig>[] = [
  {
    id: PaymentAccrualsColumnsKeys.TYPE,
    label: 'Тип операции',
    width: 140,
    placeholder: 'Выберите тип',
  },
  {
    id: PaymentAccrualsColumnsKeys.USER_ITN,
    label: 'ID пользователя,</br> ИНН пользователя',
    width: 160,
    placeholder: 'Введите ID или ИНН',
  },
  {
    id: PaymentAccrualsColumnsKeys.NAME,
    label: 'Пользователь',
    width: 175,
    placeholder: 'Введите пользователя',
  },
  {
    id: PaymentAccrualsColumnsKeys.STATUS,
    label: 'Статус',
    width: 125,
    placeholder: 'Введите статус',
  },
  {
    id: PaymentAccrualsColumnsKeys.TRADE_ID,
    label: 'ID торгов',
    width: 100,
    placeholder: 'Введите ID',
  },
  {
    id: PaymentAccrualsColumnsKeys.AMOUNT,
    label: 'Сумма </br> операции (₽)',
    width: 125,
    placeholder: 'Введите сумму',
  },
  {
    id: PaymentAccrualsColumnsKeys.PAYMENT_ORDER_ID,
    label: '№ п/п',
    width: 100,
    placeholder: 'Введите №',
  },
  {
    id: PaymentAccrualsColumnsKeys.COMMENT,
    label: 'Комментарии',
    width: 150,
  },
  {
    id: PaymentAccrualsColumnsKeys.BIN,
    label: 'bin',
    width: 60,
  },
];

export const paymentAccrualsFilterKey = {
  id: ['id'],
  type: ['type'],
  user_id: ['user_id', 'user_inn'],
  name_user_or_company: ['name_user_or_company'],
  user_status: ['user_status'],
  trade_id: ['trade_id'],
  amount: ['from_amount', 'to_amount'],
  approval_date: ['from_approval_date', 'to_approval_date'],
  payment_order_id: ['payment_order_id'],
  user_created: ['user_created'],
  meta: ['is_manual', 'is_reverse'],
  comment: ['comment'],
};

export enum FileLogColumnsKeys {
  CREATED_AT = 'created_at',
  TRANSACTION_ID = 'transaction_id',
  FILE_NAME = 'file_name',
  USER_NAME = 'user_id',
}

export const fileLogAccrualsColumns: Column<PaymentRegistryRequestConfig>[] = [
  {
    id: FileLogColumnsKeys.CREATED_AT,
    label: 'Дата загрузки',
    width: 110,
    isEnabledFilter: true,
    filterKey: FileLogColumnsKeys.CREATED_AT,
    filterConfig: {
      filter: {
        name: FileLogColumnsKeys.CREATED_AT,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: FileLogColumnsKeys.TRANSACTION_ID,
    label: 'ID операции',
    width: 110,
  },
  {
    id: FileLogColumnsKeys.FILE_NAME,
    label: 'Название файла',
    placeholder: 'Начните вводить название файла',
    width: 170,
    isEnabledSearch: true,
    filterKey: FileLogColumnsKeys.FILE_NAME,
    filterConfig: {
      filter: {
        name: FileLogColumnsKeys.FILE_NAME,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: FileLogColumnsKeys.USER_NAME,
    label: 'ФИО оператора',
    placeholder: 'Начните вводить ФИО опертатора',
    width: 190,
    isEnabledSearch: true,
    filterKey: FileLogColumnsKeys.USER_NAME,
    filterConfig: {
      filter: {
        name: FileLogColumnsKeys.USER_NAME,
        op: 'in_',
        val: '',
      },
    },
  },
].map((column) => {
  return {
    ...column,
    defaultFilterConfig: {
      filter: column.filterConfig?.filter,
    },
  };
});

export const fileLogAccrualsFilterKey = {
  transaction_id: ['transaction_id'],
  created_at: ['from_created_at', 'to_created_at'],
  file_name: ['file_name'],
  user_full_name: ['user_full_name'],
};

export enum UploadPaymentOpenEnum {
  FILE_MANAGER = 'FileManager',
  FILE_RESULT = 'FileResult',
  SEND_TRANSACTION = 'SendTransaction',
  SEND_TRANSACTION_FORCE = 'SendTransactionForce',
}

export enum UploadPaymentTypeFileEnum {
  CHECKING_ACCOUNT = 'xls_checking_account',
  ACQUIRING = 'xls_acquiring',
}

export interface UploadFileType {
  title: string;
  value: UploadPaymentTypeFileEnum;
}

export const UPLOAD_FILE_TYPE: UploadFileType[] = [
  { title: 'Excel-файл с оплатами через расчетный счет', value: UploadPaymentTypeFileEnum.CHECKING_ACCOUNT },
  { title: 'Excel-файл с оплатами через эквайринг', value: UploadPaymentTypeFileEnum.ACQUIRING },
];

export enum UploadPaymentTypeEnum {
  CHECKING_ACCOUNT = 'checking_account',
  ACQUIRING = 'acquiring',
}

export interface UploadPaymentType {
  title: string;
  value: UploadPaymentTypeEnum;
}

export const UPLOAD_PAYMENT_TYPE: UploadPaymentType[] = [
  { title: 'Рачетный счет', value: UploadPaymentTypeEnum.CHECKING_ACCOUNT },
  { title: 'Эквайринг', value: UploadPaymentTypeEnum.ACQUIRING },
];

export const paymentsFromFileColumns: Column<PaymentRegistryRequestConfig>[] = [
  {
    id: 'checkbox',
    label: '',
    width: 50,
    checkbox: true,
    error: false,
  },
  {
    id: 'approval_date',
    label: 'Дата операции',
    width: 110,
    error: null,
  },
  {
    id: 'payment_type',
    label: 'Тип транзакции',
    width: 170,
    error: null,
  },
  {
    id: 'itn',
    label: '№ ПП',
    width: 170,
    error: null,
  },
  {
    id: 'itn',
    label: 'ИНН',
    width: 190,
    error: null,
  },
  {
    id: 'amount',
    label: 'Сумма операции',
    width: 190,
    error: null,
  },
  {
    id: 'comment',
    label: 'Комментарий',
    width: 190,
    error: null,
  },
  {
    id: 'error',
    label: 'Ошибка',
    width: 250,
    error: true,
  },
];

export const META_STATUSES: MultiSelectListItem[] = [
  {
    id: StatusesEnum.ACTIVE,
    name: 'Актив',
    iconSvg: 'circle',
    classes: ['status-green'],
    checked: false,
  },
  {
    id: StatusesEnum.IN_ARCHIVE,
    name: 'Архив',
    iconSvg: 'circle',
    classes: ['status-red'],
    checked: false,
  },
];

export const UploadColumns: Column<PaymentRegistryRequestConfig>[] = [
  {
    id: PaymentAccrualsColumnsKeys.AMOUNT,
    label: 'Сумма операции (₽)',
    width: 125,
  },
  {
    id: PaymentAccrualsColumnsKeys.ITN,
    label: 'ИНН пользователя',
    width: 140,
  },
  {
    id: PaymentAccrualsColumnsKeys.APPROVAL_DATE,
    label: 'Дата операции',
    width: 125,
  },
  {
    id: PaymentAccrualsColumnsKeys.PAYMENT_ORDER_ID,
    label: '№ п/п',
    width: 100,
  },
  {
    id: PaymentAccrualsColumnsKeys.BIN,
    label: 'bin',
    width: 60,
  },
];
