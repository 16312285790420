<small
  [ngClass]="{'color-grey': direction !== vector}"
  class="criteria-range kt-sbets-cell">

  <ng-container *ngIf="vector === criteriaVectors.LESS_BETTER">
    <fa-icon [icon]="['fas', 'arrow-down']"></fa-icon>
  </ng-container>
  <ng-container *ngIf="vector === criteriaVectors.MORE_BETTER">
    <fa-icon [icon]="['fas', 'arrow-up']"></fa-icon>
  </ng-container>

  <ng-container *ngIf="type === criteriaTypes.NUMBERS">
    <span class="criteria-range-value" [ngbTooltip]="value">{{value}}</span>
  </ng-container>
  <ng-container *ngIf="type === criteriaTypes.DATES">
    <span class="criteria-range-value" [ngbTooltip]="value | criteriaDateFormat">{{value | criteriaDateFormat}}</span>
  </ng-container>
</small>
