import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PDFProgressData } from 'ng2-pdf-viewer';
import print from 'print-js';
import { saveAs } from 'file-saver';

import { UserFile } from '@app/file-manager/models/user-file.model';
import { NotificationsService } from 'angular2-notifications';
import { viewerType } from 'ngx-doc-viewer/document-viewer.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import {
  ExportFileManagerType,
  FileManagerExportComponent,
} from '@app/file-manager/file-manager-export/file-manager-export.component';
import { switchMap, takeUntil } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { DestroyService } from '@app/services/destroy.service';
import { FileManagerDataService } from '@app/file-manager/services/file-manager-data.service';
import { FileManagerService } from '@app/file-manager/services/file-manager.service';

@Component({
  selector: 'app-file-manager-modal-file-preview',
  templateUrl: './file-manager-modal-file-preview.component.html',
  styleUrls: ['./file-manager-modal-file-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileManagerModalFilePreviewComponent implements OnInit {
  @Input() files: UserFile[];
  @Input() selectedFileIndex?: number = 0;
  @Input() exportType?: ExportFileManagerType;
  loadingProgress = 0;
  isLoading = true;
  file: UserFile;

  protected readonly ExportFileManagerType = ExportFileManagerType;

  get viewer(): viewerType {
    if (
      this.file?.fileExtType()?.hasOdt ||
      this.file?.fileExtType()?.hasDoc ||
      this.file?.fileExtType()?.hasTable ||
      this.file?.fileExtType()?.hasPpt ||
      this.file?.fileExtType()?.hasOdt ||
      this.file?.fileExtType()?.hasOds ||
      this.file?.fileExtType()?.hasOdp
    ) {
      return 'office';
    }
    return 'google';
  }

  get isPdfFile(): boolean {
    return this.file?.fileExtType()?.hasPdf;
  }

  get isImageFile(): boolean {
    return this.file?.fileExtType()?.hasImage;
  }

  get isDocFile(): boolean {
    return (
      this.file?.fileExtType()?.hasDoc ||
      this.file?.fileExtType()?.hasRtf ||
      this.file?.fileExtType()?.hasPdf ||
      this.file?.fileExtType()?.hasTxt ||
      this.file?.fileExtType()?.hasTable ||
      this.file?.fileExtType()?.hasCsv ||
      this.file?.fileExtType()?.hasPpt ||
      this.file?.fileExtType()?.hasOdt ||
      this.file?.fileExtType()?.hasOds ||
      this.file?.fileExtType()?.hasOdp
    );
  }

  get isShowIconFile(): boolean {
    return !this.isDocFile && !this.isImageFile && !this.isPdfFile;
  }

  get fileUrl(): string {
    return decodeURI(this.file?.url);
  }

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly notify: NotificationsService,
    private readonly modalService: NgbModal,
    private readonly destroy$: DestroyService,
    private readonly fileManagerDataService: FileManagerDataService,
    private readonly fileManagerService: FileManagerService
  ) {}

  ngOnInit(): void {
    if (this.files?.length) {
      this.file = this.files[this.selectedFileIndex];
    }
  }

  cancel(): void {
    this.activeModal.close();
  }

  loadingPreview(event): void {
    console.log(event);
  }

  uploadProgress(event: PDFProgressData) {
    this.loadingProgress = Math.round((100 * event.loaded) / event.total);
    if (event.loaded === event.total) {
      this.isLoading = false;
    }
  }

  printFile(): void {
    print({
      printable: [this.file.url],
      type: this.isPdfFile ? 'pdf' : 'image',
    });
  }

  downloadFile(): void {
    try {
      saveAs(this.file?.url, this.file?.name);
      this.notify.success('Успешно', 'Файл успешно загружен');
    } catch (error) {
      this.notify.error('Внимание', 'Не удалось загрузить файл');
    }
  }

  onLeft() {
    this.isLoading = true;
    this.selectedFileIndex = this.selectedFileIndex - 1 >= 0 ? this.selectedFileIndex - 1 : this.files.length - 1;
    this.file = this.files[this.selectedFileIndex];
  }

  onRight() {
    this.isLoading = true;
    this.selectedFileIndex = this.selectedFileIndex + 1 < this.files.length ? this.selectedFileIndex + 1 : 0;
    this.file = this.files[this.selectedFileIndex];
  }

  onFileLoaded() {
    this.isLoading = false;
  }

  onSave() {
    this.cancel();
    try {
      saveAs(this.file?.url, this.file?.name);
    } catch (error) {
      this.notify.error('Внимание', 'Не удалось загрузить файл');
    }
  }

  onSaveToFM() {
    this.cancel();
    const modal: NgbModalRef = this.modalService.open(FileManagerExportComponent, {
      centered: true,
      animation: true,
      windowClass: 'dc-modal export-file-manager-modal modal-window',
      size: 'xl',
    });

    modal.componentInstance.title = 'Сохранение файла';
    modal.componentInstance.searchPlaceholder = 'Введите имя файла';
    modal.componentInstance.subTitle = this.file.name;
    modal.componentInstance.exportType = ExportFileManagerType.CHAT;
    modal.componentInstance.selectFolders = false;
    modal.componentInstance.isFolderExport = true;

    modal.result.then((folderSelected) => {
      if (!folderSelected) return;

      this.fileManagerDataService
        .downloadFileByLink(this.file.url)
        .pipe(
          switchMap((data) => {
            const formData: FormData = new FormData();
            formData.append('file', data, this.file.name);
            return this.fileManagerService.uploadFile(folderSelected.id, formData);
          }),
          takeUntil(this.destroy$)
        )
        .subscribe({
          next: (event) => {
            if (event.type !== HttpEventType.Response) return;
            this.notify.success('Успешно!', `Сохранение файла ${event?.body?.name} завершено`);
          },
          error: () => this.notify.error('Внимание!', `Не удалось сохранить ${this.file?.name}`),
        });
    });
  }
}
