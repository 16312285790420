import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-circular-progress',
  templateUrl: './circular-progress.component.html',
  styleUrls: ['./circular-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircularProgressComponent {
  @Input() title: string;
  @Input() count: number;
  @Input() maxCount: number;
  @Input() isPercent: boolean;

  get progress(): number {
    if (!this.maxCount) {
      this.count = 0;
      this.maxCount = 1;
    }

    return Math.round((this.count / this.maxCount) * 100);
  }

  get circumference(): number {
    return 2 * Math.PI * 15.9155;
  }

  get dashoffset(): number {
    return this.circumference - (this.progress / 100) * this.circumference;
  }
}
