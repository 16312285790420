import { Pipe, PipeTransform } from '@angular/core';
import { Employee } from '@app/shared/models/employee.model';
import { UserShortInfo } from '@app/shared/models/user-short-info.model';
import { BaseWalletAggregationData } from '@app/+tariffs/models/wallet-balance.model';
import { Initials } from '@app/shared/models/base-user.model';
import { FileLogAccrualModel } from '@app/+tariffs/models/payment-accrual.model';

@Pipe({
  name: 'shortName',
})
export class ShortNamePipe implements PipeTransform {
  transform(value: Employee | UserShortInfo | BaseWalletAggregationData | Initials | FileLogAccrualModel): string {
    if (!value.second_name) return '-';
    return `${value.second_name} ${value.first_name ? value.first_name[0] : ''}.${
      value.patronymic ? value.patronymic[0] + '.' : ''
    }`;
  }
}
