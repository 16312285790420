import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-change-tso-title',
  templateUrl: './change-tso-title.component.html',
  styleUrls: ['./change-tso-title.component.scss'],
})
export class ChangeTsoTitleComponent {
  @Input() title = '';
}
