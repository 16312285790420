import { Pipe, PipeTransform } from '@angular/core';
import { DCTreeItem } from '@app/+competence-map/models/competence-map.models';
import { ChatRoom } from '@app/chat/models/chat.model';
import { Role, User } from '@app/shared/models/user.model';
import { Company } from '../models/company.model';
import { RolesEnum } from '@app/shared/constants/roles.constants';

@Pipe({
  name: 'contactsFilter',
})
export class ContactsFilterPipe implements PipeTransform {
  transform(
    users: User[] | ChatRoom[],
    filterObject: {
      roles: Role[];
      services: DCTreeItem[];
      goods: DCTreeItem[];
      companies: Company[];
      isUserNotHasTSO?: boolean;
      isUserHasTSO?: boolean;
    },
    sortField = 'second_name'
  ): any {
    if (!users) {
      return;
    }

    let items = users as (User & { children: [] })[];

    const withChildren = items && items.some((user) => user?.children?.length > 0);
    if (!filterObject) {
      return items;
    }

    if (filterObject.companies?.length) {
      items = this.filterByCompanies(items, filterObject.companies);
    }

    if (filterObject.roles?.length) {
      items = this.filterByRoles(items, filterObject.roles);
    }

    if (filterObject.goods?.length) {
      items = this.filterByGoods(items, filterObject.goods);
    }

    if (filterObject.services?.length) {
      items = this.filterByServices(items, filterObject.services);
    }

    if (filterObject?.isUserHasTSO !== undefined || filterObject?.isUserNotHasTSO !== undefined) {
      items = items.filter((item) => {
        const hasTSO = item?.tso_id !== null;

        if (filterObject?.isUserHasTSO !== undefined && filterObject?.isUserNotHasTSO !== undefined) {
          // Оба фильтра заданы, то учитываем оба
          return (filterObject.isUserHasTSO && hasTSO) || (filterObject.isUserNotHasTSO && !hasTSO);
        } else if (filterObject?.isUserHasTSO !== undefined) {
          // Фильтруем только по isUserHasTSO
          return filterObject.isUserHasTSO === hasTSO;
        } else if (filterObject?.isUserNotHasTSO !== undefined) {
          // Фильтруем только по isUserNotHasTSO
          return filterObject.isUserNotHasTSO === !hasTSO;
        }

        return true; // Если нет фильтров по TSO, оставляем всё как есть
      });
    }

    if (withChildren) {
      return items;
    } else {
      return items.map((item) => {
        return (users as User[]).find((user) => user.id === item.id);
      });
    }
  }

  private filterByCompanies(users: (User & { children: [] })[], companies: Company[]) {
    return users
      .map((user) => {
        const filteredChildren = user.children ? this.filterByCompanies(user.children, companies) : [];
        return { ...user, children: filteredChildren };
      })
      .filter(
        (user) =>
          companies.some((company) => company.id === user.company_id || company.id === user?.company?.id) &&
          user.children.length > 0
      );
  }

  private filterByRoles(users: (User & { children: [] })[], roles: Role[]) {
    return users.flatMap((user) => {
      const filteredChildren = user.children ? this.filterByRoles(user.children, roles) : [];

      const isParentMatching = roles.some(
        (role) => user.type === role.value || (role.value === RolesEnum.TSO && user?.flags?.is_tso)
      );

      return isParentMatching ? [{ ...user, children: filteredChildren }] : filteredChildren;
    });
  }

  private filterByGoods(users: (User & { children: [] })[], goods: DCTreeItem[]) {
    return users.flatMap((user) => {
      const filteredChildren = user.children ? this.filterByGoods(user.children, goods) : [];

      const isParentMatching = goods.some((good) =>
        user.competence_sections.some((comp) => comp.id === good.id && comp.catalog === 'goods')
      );

      return isParentMatching ? [{ ...user, children: filteredChildren }] : filteredChildren;
    });
  }

  private filterByServices(users: (User & { children: [] })[], services: DCTreeItem[]) {
    return users.flatMap((user) => {
      const filteredChildren = user.children ? this.filterByServices(user.children, services) : [];

      const isParentMatching = services.some((service) =>
        user.competence_sections.some((comp) => comp.id === service.id && comp.catalog === 'services')
      );

      return isParentMatching ? [{ ...user, children: filteredChildren }] : filteredChildren;
    });
  }
}
