import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TradeCard } from '@app/+trades/models/trades.model';
import { TradeCardStatuses } from '@app/+trades/constants/trades.constants';
import moment from 'moment';
import { BACKEND_DATE_TIME_FORMAT } from '@app/shared/constants/date.constants';

@Component({
  selector: 'app-trades-status-line',
  templateUrl: './trades-status-line.component.html',
  styleUrls: ['./trades-status-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TradesStatusLineLineComponent {
  @Input() card: TradeCard = <TradeCard>{};
  @Input() timezoneOffset: number;
  @Input() isSupplier = false;
  @Input() isTemplate = false;

  currentDateUtc: string = moment.utc().format(BACKEND_DATE_TIME_FORMAT);
  tradeCardStatuses = TradeCardStatuses;
  counter: string;

  isCardCreated() {
    return [
      TradeCardStatuses.PROJECT,
      TradeCardStatuses.PRE_TRADE,
      TradeCardStatuses.TRADE,
      TradeCardStatuses.ARCHIVE,
    ].includes(<TradeCardStatuses>this.card.status);
  }
}
